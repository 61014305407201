import React, { Component } from 'react';
import './KtbstInvestmentInfoList.css'
import ButtonCustom from '../../components/button/ButtonCustom'

class KtbstInvestmentInfoList extends Component {

    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this); 
    }

    goBack(){
        this.props.history.goBack();
    }

    render() {
        return (
            <div>
            <div className='content'>
                <div className='top_main_back'>
                    <div className="main_back" onClick={this.goBack}>
                        <img src='/images/angle-arrow-left.svg' style={{marginLeft:'3px'}} className='arrow_back'/>
                    </div>
                    <div className='text_title'>ภาระการเงินต่อปี (บาท)</div>
                </div>
                <div className='btn_content_white'>น้อยกว่า 1 ล้านบาท</div>
                <div className='btn_content_white'>1 - 5 ล้านบาท</div>
                <div className='btn_content_white'>5 - 10 ล้านบาท</div>
                <div className='btn_content_white'>10 ล้านบาทขึ้นไป</div>
            </div>
        </div>
        );
    }
}

export default KtbstInvestmentInfoList;