import React, { Component } from "react";
import "./ATSForm.css";
import ButtonCustom from "../../components/button/ButtonCustom";
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { fas } from '@fortawesome/free-solid-svg-icons';
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert2";
import axios from "axios";
import Select2 from "react-select";
import { config } from "./../../constants";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
const _url = config.baseAPIURL03;
class ATSForm extends Component {
  constructor(props) {
    super(props);

    let atsDataUser = JSON.parse(localStorage.getItem("atsDataUser"));
    let atsData = JSON.parse(localStorage.getItem("atsData"));
    if (!atsData.ats_bank || !atsData.ats_account_no) {
      this.props.history.push("/ats_choice");
    }
    let bank_id = atsData.ats_bank.toLowerCase() === "scb" ? 10 : 3;
    this.state = {
      bank: atsData.ats_bank.toLowerCase(),
      bank_id: atsData.ats_bank_id,
      ats_bank_name: atsData.ats_bank_name,
      ats_account_no: atsData.ats_account_no,
      ats_account_name: atsData.ats_account_name,
      bank_branch:
        atsDataUser && atsDataUser.bankCode === atsData.ats_bank.toLowerCase()
          ? atsDataUser.bank_branch
          : [],
      bank_branch_list: [],
      status_button: true,
      bankBranchError: "",
    };

    this.handleChangeOption = this.handleChangeOption.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  goback = () => {
    this.props.history.push("/ats_choice");
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await this.validate();
    if (isValid) {
      // console.log(this.state);
      let f0 = JSON.parse(localStorage.getItem("f0"));
      let atsDataUser = {
        bankCode: this.state.bank,
        bank_id: this.state.bank_id,
        bank_name: this.state.ats_bank_name,
        bank_branch: this.state.bank_branch,
        bank_account_no: this.state.ats_account_no,
        bank_account_name: this.state.ats_account_name
          ? this.state.ats_account_name
          : f0.f0_firstname + " " + f0.f0_lastname,
      };
      await localStorage.setItem("atsDataUser", JSON.stringify(atsDataUser));
      this.props.history.push("/ats");
    }
  };

  componentDidMount() {
    // kbank 3
    // scb 10
    axios
      .get(_url + `/constant/bank_branch/${this.state.bank_id}`)
      .then((res) => {
        const bank_branch_list = res.data.Data.map((item, i) => {
          return { value: item.Id, label: item.THName };
        });
        this.setState({ bank_branch_list: bank_branch_list });
      })
      .catch((error) => {
        let title_mss = "";
        let mss = "";
        if (error.response) {
          title_mss =
            error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
        const MySwal = withReactContent(swal);
        MySwal.fire({
          imageUrl: "/images/ico_alert.svg",
          imageWidth: 100,
          imageHeight: 100,
          customClass: {
            confirmButton: "long_btn_fix",
          },
          confirmButtonColor: "#003b66",
          confirmButtonText: "กลับ",
          title: '<div class="title_popup">' + title_mss + "</div>",
          html: '<div class="content_popup">' + mss + "</div>",
        });
        return;
      });
  }

  handleChangeOption = async (selectedOption, e) => {
    await this.setState({ [e.name]: selectedOption });
    await this.validate();
  };

  validate = async () => {
    let bankBranchError = "";
    // console.log(this.state.bank_branch);
    if (!this.state.bank_branch.value) {
      bankBranchError = "กรุณาระบุสาขา";
    }
    this.setState({ bankBranchError: bankBranchError });
    return bankBranchError ? false : true;
  };

  render() {
    return (
      <div
        className={isMobile ? "" : "body"}
        style={{
          backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
        }}
      >
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section className={isMobile ? "" : "desktop_content section-gap-full"}>
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goback}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">
                        สมัครบริการหักบัญชีอัตโนมัติ
                      </div>
                    </div>

                    <div className="bg_content_white over_f_hide">
                      <div
                        className="in_bg_white autoscroll"
                        style={{ top: 0, marginTop: 24 }}
                      >
                        <div
                          style={{
                            color: "#000",
                            fontSize: 24,
                            marginBottom: 5,
                            fontWeight: "bold",
                          }}
                        >
                          เลือกสาขาการหักบัญชีอัตโนมัติ
                        </div>
                        <div>
                          <div className={"row_idcard active"}>
                            <div style={{ fontSize: 18 }}>เลขที่บัญชี *</div>
                            <input
                              className="input_idcard"
                              name="ats_account_no"
                              value={
                                this.state.ats_account_no.substring(
                                  0,
                                  this.state.ats_account_no.length - 4
                                ) + "xxxx"
                              }
                              disabled
                            ></input>
                          </div>

                          <div className={"row_idcard "}>
                            <div style={{ fontSize: 18 }}>สาขา *</div>

                            <div
                              className="main_select"
                              style={{ border: "none" }}
                            >
                              <Select2
                                name="bank_branch"
                                options={this.state.bank_branch_list}
                                value={this.state.bank_branch}
                                onChange={this.handleChangeOption}
                                placeholder="กรุณาระบุ"
                                className="select_two"
                              />
                            </div>
                          </div>
                          <div className="errorMessage">
                            {this.state.bankBranchError}
                          </div>
                        </div>
                      </div>

                      <div className="under_fix_btn">
                        <ButtonCustom
                          handle={(e) => this.handleSubmit(e)}
                          label={"ถัดไป"}
                          color={"blue"}
                          btnClass="long_btn"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ATSForm;
