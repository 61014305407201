/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import './KtbstSignedAttatchedOK.css';
import ButtonCustom from '../../components/button/ButtonCustom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class KtbstSignedAttatchedOK extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trimmedDataURL: null,
    };
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.history.push('/investment_info_income');
  };

  componentDidMount() {
    const trimmedDataURL = localStorage.getItem('trimmedDataURL');
    this.setState({ trimmedDataURL: trimmedDataURL });
  }

  render() {
    library.add(fas);
    const trimmedDataURL = this.state.trimmedDataURL;
    // console.log(trimmedDataURL);
    return (
      <div>
        <div className="content">
          <div className="top_main_back">
            <div className="main_back" onClick={this.goBack}>
              <img src="/images/angle-arrow-left.svg" style={{ marginLeft: '3px' }} className="arrow_back" />
            </div>
            <div className="text_title">หลักฐานลายเซ็น</div>
          </div>

          <div className="bg_content_white" style={{ padding: 0 }}>
            <div className="in_bg_white" style={{ top: 24, left: 0, right: 0 }}>
              <div className="title_row_bg_white" style={{ color: '#000', marginBottom: 24, margin: '0 21px' }}>
                กรุณาเพิ่มรูปหลักฐานลายเซ็นของคุณ
              </div>
              <div className="btn_photo" style={{ margin: 21 }}>
                <img className="DivFrameWaterMask" src="/images/signed.png" style={{ width: '120%' }}></img>

                <img src="/images/btn_edit.png" alt="edit" className="BtnEditphoto" />

                {trimmedDataURL !== 'null' ? <img className="sigCanvas" src={trimmedDataURL} /> : null}
              </div>

              <div className="text_under_photo">ข้อมูลนี้ใช้สำหรับในการสมัครเพื่อเปิดพอร์ตกับ DAOL เท่านั้น</div>
            </div>
            <div className="under_fix_btn">
              <ButtonCustom
                handle={this.handleSubmit}
                label="ถัดไป"
                color="blue"
                btnClass="btn_investment"
                iconDirection="R"
                icon="angle-right"
              ></ButtonCustom>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default KtbstSignedAttatchedOK;
