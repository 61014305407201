import React, { Component } from 'react';
import './KtbstInvestmentInfoList2.css'
import ButtonCustom from '../../components/button/ButtonCustom'

class KtbstInvestmentInfoList2 extends Component {

    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this); 
    }

    goBack(){
        this.props.history.goBack();
    }

    render() {
        return (
            <div>
            <div className='content'>
                <div className='top_main_back'>
                    <div className="main_back" onClick={this.goBack}>
                        <img src='/images/angle-arrow-left.svg' style={{marginLeft:'3px'}} className='arrow_back'/>
                    </div>
                    <div className='text_title' style={{marginBottom:32}}>จำนวนเงินที่คาดว่าจะนำมาลงทุนแต่ละครั้ง </div>
                </div>
                
                <div className='btn_content_white'>น้อยกว่า 2 ล้านบาท</div>
                <div className='btn_content_white'>2 - 10 ล้านบาท</div>
                <div className='btn_content_white'>10 ล้านบาทขึ้นไป</div>
            </div>
        </div>
        );
    }
}

export default KtbstInvestmentInfoList2;