import React, { Component } from "react";
import "./KtbstIDCAddressForm.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import axios from "axios";
import queryString from "query-string";
import {
  setThaiAddressText,
  setNonThaiAddressText,
} from "./../../components/helpers/address";
import { config } from "./../../constants";
import { popupErrorApi } from "../../components/helpers/Helpers";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
const _url = config.baseAPIURL03;

class KtbstIDCAddressForm extends Component {
  countries;
  provinces;
  districts;
  subdistricts;
  postcodes;
  callback;
  isEditMode = false;

  constructor(props) {
    super(props);
    const url = this.props.location.search;
    const params = queryString.parse(url);
    const type = params.address;
    this.isEditMode = params.mode ? true : false;

    this.callback = params.cb;
    this.goBack = this.goBack.bind(this);
    this.state = {
      NewAddress: {
        country_name: "",
        province_name: "",
        district_name: "",
        sub_district_name: "",
        new_address: "",
        new_country: "",
        new_province: "",
        new_district: "",
        new_subdistrict: "",
        new_zipcode: "",
        new_no: "",
        new_moo: "",
        new_building: "",
        new_floor: "",
        new_road: "",
      },
      type: type,
      country: [],
      province: [],
      Province: [],
      district: [],
      District: [],
      subdistrict: [],
      SubDistrict: [],
      postCode: [],
      postcode: [],
      country_id: "",
      province_id: "",
      district_id: "",
      sub_district_id: "",
      code_postal_id: "",
      country_array: [],
      province_array: [],
      district_array: [],
      sub_district_array: [],
      code_postal_array: [],
      f2_current_no: "",
      f2_current_floor: "",
      f2_current_building: "",
      f2_current_moo: "",
      f2_current_road: "",
      full_address: "",
    };
  }

  goBack() {
    this.props.history.goBack();
  }
  handleSubmit = async (e) => {
    const type = this.state.type;
    e.preventDefault();
    const new_address = this.state.NewAddress;
    var items = document.querySelectorAll("[class*=span_f2]");
    for (var i = 0; i < items.length; i++) {
      if (items[i].style.display === "block") {
        return items[i].parentNode.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (this.state.NewAddress.new_country != 212) {
      if (new_address.new_address == "") {
        return false;
      }
    } else {
      if (
        new_address.new_country == "" ||
        new_address.new_province == "" ||
        new_address.new_district == "" ||
        new_address.new_subdistrict == "" ||
        new_address.new_no == ""
      ) {
        return false;
      }
    }
    let destinationPageURL =
      type === "new"
        ? "/idc_address_choose2"
        : "/idc_address_choose";

    var str = window.location.href;
    if ((type !== "new" && this.isEditMode) || str.includes("/edit")) {
      destinationPageURL += `/edit`;
    }

    if (type === "new" && this.callback) {
      destinationPageURL += `?address=${this.callback}`;
    }

    if (type === "new" || type === "editHomeAddress") {
      // console.log(this.state.NewAddress);
      if (this.state.NewAddress.new_country != 212) {
        // console.log(this.state.NewAddress.new_address);
        const NewHomeAddress = {
          country_name: this.state.NewAddress.country_name,
          province_name: "",
          district_name: "",
          sub_district_name: "",
          //floor+building+number+moo+road
          f2_home_address: setNonThaiAddressText(
            this.state.NewAddress.new_address,
            this.state.NewAddress.country_name
          ),
          f2_home_country: this.state.NewAddress.new_country,
          f2_home_province: "",
          f2_home_district: "",
          f2_home_subdistrict: "",
          f2_home_zipcode: "",
          f2_home_no: "",
          f2_home_moo: "",
          f2_home_building: "",
          f2_home_floor: "",
          f2_home_road: "",
        };
        localStorage.setItem("HomeAddress", JSON.stringify(NewHomeAddress));
        this.props.history.push(destinationPageURL);
      } else {
        const NewHomeAddress = {
          country_name: this.state.NewAddress.country_name,
          province_name: this.state.NewAddress.province_name,
          district_name: this.state.NewAddress.district_name,
          sub_district_name: this.state.NewAddress.sub_district_name,
          //floor+building+number+moo+road
          f2_home_address: setThaiAddressText({
            houseNo: this.state.NewAddress.new_no,
            moo: this.state.NewAddress.new_moo,
            building: this.state.NewAddress.new_building,
            floor: this.state.NewAddress.new_floor,
            road: this.state.NewAddress.new_road,
            province: this.state.NewAddress.province_name,
            district: this.state.NewAddress.district_name,
            subDistrict: this.state.NewAddress.sub_district_name,
            zipcode: this.state.NewAddress.new_zipcode,
            country: this.state.NewAddress.country_name,
          }),
          f2_home_country: this.state.NewAddress.new_country,
          f2_home_province: String(this.state.NewAddress.new_province),
          f2_home_district: this.state.NewAddress.new_district,
          f2_home_subdistrict: this.state.NewAddress.new_subdistrict,
          f2_home_zipcode: this.state.NewAddress.new_zipcode,
          f2_home_no: this.state.NewAddress.new_no,
          f2_home_moo: this.state.NewAddress.new_moo,
          f2_home_building: this.state.NewAddress.new_building,
          f2_home_floor: this.state.NewAddress.new_floor,
          f2_home_road: this.state.NewAddress.new_road,
        };
        localStorage.setItem("HomeAddress", JSON.stringify(NewHomeAddress));
        this.props.history.push(destinationPageURL);
      }
    }
    if (type === "editIdAddress") {
      if (this.state.NewAddress.new_country != 212) {
        const NewHomeAddress = {
          country_name: this.state.NewAddress.country_name,
          province_name: "",
          district_name: "",
          sub_district_name: "",
          //floor+building+number+moo+road
          f2_nric_address: setNonThaiAddressText(
            this.state.NewAddress.new_address,
            this.state.NewAddress.country_name
          ),
          f2_nric_country: this.state.NewAddress.new_country,
          f2_nric_province: "",
          f2_nric_district: "",
          f2_nric_subdistrict: "",
          f2_nric_zipcode: "",
          f2_nric_no: "",
          f2_nric_moo: "",
          f2_nric_building: "",
          f2_nric_floor: "",
          f2_nric_road: "",
        };
        localStorage.setItem("IDAddress", JSON.stringify(NewHomeAddress));
        this.props.history.push(destinationPageURL);
      } else {
        const NewHomeAddress = {
          country_name: this.state.NewAddress.country_name,
          province_name: this.state.NewAddress.province_name,
          district_name: this.state.NewAddress.district_name,
          sub_district_name: this.state.NewAddress.sub_district_name,
          //floor+building+number+moo+road
          f2_nric_address: setThaiAddressText({
            houseNo: this.state.NewAddress.new_no,
            moo: this.state.NewAddress.new_moo,
            building: this.state.NewAddress.new_building,
            floor: this.state.NewAddress.new_floor,
            road: this.state.NewAddress.new_road,
            province: this.state.NewAddress.province_name,
            district: this.state.NewAddress.district_name,
            subDistrict: this.state.NewAddress.sub_district_name,
            zipcode: this.state.NewAddress.new_zipcode,
            country: this.state.NewAddress.country_name,
          }),
          f2_nric_country: this.state.NewAddress.new_country,
          f2_nric_province: this.state.NewAddress.new_province,
          f2_nric_district: this.state.NewAddress.new_district,
          f2_nric_subdistrict: this.state.NewAddress.new_subdistrict,
          f2_nric_zipcode: this.state.NewAddress.new_zipcode,
          f2_nric_no: this.state.NewAddress.new_no,
          f2_nric_moo: this.state.NewAddress.new_moo,
          f2_nric_building: this.state.NewAddress.new_building,
          f2_nric_floor: this.state.NewAddress.new_floor,
          f2_nric_road: this.state.NewAddress.new_road,
        };
        localStorage.setItem("IDAddress", JSON.stringify(NewHomeAddress));
        this.props.history.push(destinationPageURL);
      }
    }
    if (type === "editContactAddress") {
      if (this.state.NewAddress.new_country != 212) {
        const NewHomeAddress = {
          country_name: this.state.NewAddress.country_name,
          province_name: "",
          district_name: "",
          sub_district_name: "",
          //floor+building+number+moo+road
          f2_current_address: setNonThaiAddressText(
            this.state.NewAddress.new_address,
            this.state.NewAddress.country_name
          ),
          f2_current_country: this.state.NewAddress.new_country,
          f2_current_province: "",
          f2_current_district: "",
          f2_current_subdistrict: "",
          f2_current_zipcode: "",
          f2_current_no: "",
          f2_current_moo: "",
          f2_current_building: "",
          f2_current_floor: "",
          f2_current_road: "",
        };
        localStorage.setItem("ContactAddress", JSON.stringify(NewHomeAddress));
        this.props.history.push(destinationPageURL);
      } else {
        const NewHomeAddress = {
          country_name: this.state.NewAddress.country_name,
          province_name: this.state.NewAddress.province_name,
          district_name: this.state.NewAddress.district_name,
          sub_district_name: this.state.NewAddress.sub_district_name,
          //floor+building+number+moo+road
          f2_current_address: setThaiAddressText({
            houseNo: this.state.NewAddress.new_no,
            moo: this.state.NewAddress.new_moo,
            building: this.state.NewAddress.new_building,
            floor: this.state.NewAddress.new_floor,
            road: this.state.NewAddress.new_road,
            province: this.state.NewAddress.province_name,
            district: this.state.NewAddress.district_name,
            subDistrict: this.state.NewAddress.sub_district_name,
            zipcode: this.state.NewAddress.new_zipcode,
            country: this.state.NewAddress.country_name,
          }),
          f2_current_country: this.state.NewAddress.new_country,
          f2_current_province: this.state.NewAddress.new_province,
          f2_current_district: this.state.NewAddress.new_district,
          f2_current_subdistrict: this.state.NewAddress.new_subdistrict,
          f2_current_zipcode: this.state.NewAddress.new_zipcode,
          f2_current_no: this.state.NewAddress.new_no,
          f2_current_moo: this.state.NewAddress.new_moo,
          f2_current_building: this.state.NewAddress.new_building,
          f2_current_floor: this.state.NewAddress.new_floor,
          f2_current_road: this.state.NewAddress.new_road,
        };

        localStorage.setItem("ContactAddress", JSON.stringify(NewHomeAddress));
        this.props.history.push(destinationPageURL);
      }
    }
    if (type === "editOfficeAddress") {
      if (this.state.NewAddress.new_country != 212) {
        const NewHomeAddress = {
          country_name: this.state.NewAddress.country_name,
          province_name: "",
          district_name: "",
          sub_district_name: "",
          //floor+building+number+moo+road
          f2_workplace_address: setNonThaiAddressText(
            this.state.NewAddress.new_address,
            this.state.NewAddress.country_name
          ),
          f2_workplace_country: this.state.NewAddress.new_country,
          f2_workplace_province: "",
          f2_workplace_district: "",
          f2_workplace_subdistrict: "",
          f2_workplace_zipcode: "",
          f2_workplace_no: "",
          f2_workplace_moo: "",
          f2_workplace_building: "",
          f2_workplace_floor: "",
          f2_workplace_road: "",
        };
        localStorage.setItem("WorkAddress", JSON.stringify(NewHomeAddress));
        this.props.history.push(destinationPageURL);
      } else {
        const NewHomeAddress = {
          country_name: this.state.NewAddress.country_name,
          province_name: this.state.NewAddress.province_name,
          district_name: this.state.NewAddress.district_name,
          sub_district_name: this.state.NewAddress.sub_district_name,
          //floor+building+number+moo+road
          f2_workplace_address: setThaiAddressText({
            houseNo: this.state.NewAddress.new_no,
            moo: this.state.NewAddress.new_moo,
            building: this.state.NewAddress.new_building,
            floor: this.state.NewAddress.new_floor,
            road: this.state.NewAddress.new_road,
            province: this.state.NewAddress.province_name,
            district: this.state.NewAddress.district_name,
            subDistrict: this.state.NewAddress.sub_district_name,
            zipcode: this.state.NewAddress.new_zipcode,
            country: this.state.NewAddress.country_name,
          }),
          f2_workplace_country: this.state.NewAddress.new_country,
          f2_workplace_province: this.state.NewAddress.new_province,
          f2_workplace_district: this.state.NewAddress.new_district,
          f2_workplace_subdistrict: this.state.NewAddress.new_subdistrict,
          f2_workplace_zipcode: this.state.NewAddress.new_zipcode,
          f2_workplace_no: this.state.NewAddress.new_no,
          f2_workplace_moo: this.state.NewAddress.new_moo,
          f2_workplace_building: this.state.NewAddress.new_building,
          f2_workplace_floor: this.state.NewAddress.new_floor,
          f2_workplace_road: this.state.NewAddress.new_road,
        };
        localStorage.setItem("WorkAddress", JSON.stringify(NewHomeAddress));
        this.props.history.push(destinationPageURL);
      }
    }
  };
  componentDidMount() {
    const type = this.state.type;
    // console.log(this.state.type);
    if (type === undefined) {
      this.props.history.goBack();
      return false;
    }
    this.initData();
  }

  initData() {
    const { countries, provinces, districts, subdistricts, postcodes } =
      this.props.address;

    const { type } = this.state;
    this.countries = countries;
    this.provinces = provinces;
    this.districts = districts;
    this.subdistricts = subdistricts;
    this.postcodes = postcodes;
    switch (type) {
      case "editIdAddress":
        const IDAddress = JSON.parse(localStorage.getItem("IDAddress"));
        if (!!IDAddress) {
          this.setState({
            NewAddress: {
              country_name: IDAddress ? IDAddress.country_name : "",
              province_name: IDAddress ? IDAddress.province_name : "",
              district_name: IDAddress ? IDAddress.district_name : "",
              sub_district_name: IDAddress ? IDAddress.sub_district_name : "",
              new_address: IDAddress ? IDAddress.f2_nric_address : "",
              new_country: IDAddress ? IDAddress.f2_nric_country : 212,
              new_province: IDAddress ? IDAddress.f2_nric_province : "",
              new_district: IDAddress ? IDAddress.f2_nric_district : "",
              new_subdistrict: IDAddress ? IDAddress.f2_nric_subdistrict : "",
              new_zipcode:
                IDAddress?.f2_nric_zipcode &&
                  IDAddress?.f2_nric_zipcode.trim() !== "-"
                  ? IDAddress.f2_nric_zipcode
                  : "",
              new_no: IDAddress ? IDAddress.f2_nric_no : "",
              new_moo: IDAddress ? IDAddress.f2_nric_moo : "",
              new_building: IDAddress ? IDAddress.f2_nric_building : "",
              new_floor: IDAddress ? IDAddress.f2_nric_floor : "",
              new_road: IDAddress ? IDAddress.f2_nric_road : "",
            },
            full_address: IDAddress ? IDAddress.f2_nric_address : "",
          });
        } else {
          this.setCountry(212);
        }
        break;
      case "editHomeAddress":
        const HomeAddress = JSON.parse(localStorage.getItem("HomeAddress"));
        if (!!HomeAddress) {
          this.setState({
            NewAddress: {
              country_name: HomeAddress ? HomeAddress.country_name : "",
              province_name: HomeAddress ? HomeAddress.province_name : "",
              district_name: HomeAddress ? HomeAddress.district_name : "",
              sub_district_name: HomeAddress
                ? HomeAddress.sub_district_name
                : "",
              new_address: HomeAddress ? HomeAddress.f2_home_address : "",
              new_country: HomeAddress ? HomeAddress.f2_home_country : 212,
              new_province: HomeAddress ? HomeAddress.f2_home_province : "",
              new_district: HomeAddress ? HomeAddress.f2_home_district : "",
              new_subdistrict: HomeAddress
                ? HomeAddress.f2_home_subdistrict
                : "",
              new_zipcode:
                HomeAddress?.f2_home_zipcode &&
                  HomeAddress.f2_home_zipcode.trim() !== "-"
                  ? HomeAddress.f2_home_zipcode
                  : "",
              new_no: HomeAddress ? HomeAddress.f2_home_no : "",
              new_moo: HomeAddress ? HomeAddress.f2_home_moo : "",
              new_building: HomeAddress ? HomeAddress.f2_home_building : "",
              new_floor: HomeAddress ? HomeAddress.f2_home_floor : "",
              new_road: HomeAddress ? HomeAddress.f2_home_road : "",
            },
            fullAddress: HomeAddress ? HomeAddress.f2_nric_address : "",
          });
        } else {
          this.setCountry(212);
        }
        break;
      case "editContactAddress":
        const ContactAddress = JSON.parse(
          localStorage.getItem("ContactAddress")
        );
        if (!!ContactAddress) {
          this.setState({
            NewAddress: {
              country_name: ContactAddress ? ContactAddress.country_name : "",
              province_name: ContactAddress ? ContactAddress.province_name : "",
              district_name: ContactAddress ? ContactAddress.district_name : "",
              sub_district_name: ContactAddress
                ? ContactAddress.sub_district_name
                : "",
              new_address: ContactAddress
                ? ContactAddress.f2_current_address
                : "",
              new_country: ContactAddress
                ? ContactAddress.f2_current_country
                : 212,
              new_province: ContactAddress
                ? ContactAddress.f2_current_province
                : "",
              new_district: ContactAddress
                ? ContactAddress.f2_current_district
                : "",
              new_subdistrict: ContactAddress
                ? ContactAddress.f2_current_subdistrict
                : "",
              new_zipcode:
                ContactAddress?.f2_current_zipcode &&
                  ContactAddress.f2_current_zipcode.trim() !== "-"
                  ? ContactAddress.f2_current_zipcode
                  : "",
              new_no: ContactAddress ? ContactAddress.f2_current_no : "",
              new_moo: ContactAddress ? ContactAddress.f2_current_moo : "",
              new_building: ContactAddress
                ? ContactAddress.f2_current_building
                : "",
              new_floor: ContactAddress ? ContactAddress.f2_current_floor : "",
              new_road: ContactAddress ? ContactAddress.f2_current_road : "",
            },
            full_address: ContactAddress
              ? ContactAddress.f2_current_address
              : "",
          });
        } else {
          this.setCountry(212);
        }
        break;
      case "editOfficeAddress":
        const WorkAddress = JSON.parse(localStorage.getItem("WorkAddress"));
        if (!!WorkAddress) {
          this.setState({
            NewAddress: {
              country_name: WorkAddress ? WorkAddress.country_name : "",
              province_name: WorkAddress ? WorkAddress.province_name : "",
              district_name: WorkAddress ? WorkAddress.district_name : "",
              sub_district_name: WorkAddress
                ? WorkAddress.sub_district_name
                : "",
              new_address: WorkAddress ? WorkAddress.f2_workplace_address : "",
              new_country: WorkAddress ? WorkAddress.f2_workplace_country : 212,
              new_province: WorkAddress
                ? WorkAddress.f2_workplace_province
                : "",
              new_district: WorkAddress
                ? WorkAddress.f2_workplace_district
                : "",
              new_subdistrict: WorkAddress
                ? WorkAddress.f2_workplace_subdistrict
                : "",
              new_zipcode:
                WorkAddress?.f2_workplace_zipcode &&
                  WorkAddress?.f2_workplace_zipcode?.trim() !== "-"
                  ? WorkAddress.f2_workplace_zipcode
                  : "",
              new_no: WorkAddress ? WorkAddress.f2_workplace_no : "",
              new_moo: WorkAddress ? WorkAddress.f2_workplace_moo : "",
              new_building: WorkAddress
                ? WorkAddress.f2_workplace_building
                : "",
              new_floor: WorkAddress ? WorkAddress.f2_workplace_floor : "",
              new_road: WorkAddress ? WorkAddress.f2_workplace_road : "",
            },
            full_address: WorkAddress ? WorkAddress.f2_workplace_address : "",
          });
        } else {
          this.setCountry(212);
        }
        break;
      default:
        this.setState({
          NewAddress: {
            country_name: "ไทย",
            province_name: "",
            district_name: "",
            sub_district_name: "",
            new_address: "",
            new_country: 212,
            new_province: "",
            new_district: "",
            new_subdistrict: "",
            new_zipcode: "",
            new_no: "",
            new_moo: "",
            new_building: "",
            new_floor: "",
            new_road: "",
          },
        });
        break;
    }
  }
  setCountry = async (id) => {
    const findCountry = this.countries.find((item) => String(item.Id) === id);
    const country_name = findCountry ? findCountry.ThName : "";
    if (id != 212) {
      await this.setState({
        NewAddress: {
          country_name: country_name,
          province_name: this.state.NewAddress.province_name,
          district_name: this.state.NewAddress.district_name,
          sub_district_name: this.state.NewAddress.sub_district_name,
          new_address: "",
          new_country: id,
          new_province: this.state.NewAddress.new_province,
          new_district: this.state.NewAddress.new_district,
          new_subdistrict: this.state.NewAddress.new_subdistrict,
          new_zipcode: this.state.NewAddress.new_zipcode,
          new_no: this.state.NewAddress.new_no,
          new_moo: this.state.NewAddress.new_moo,
          new_building: this.state.NewAddress.new_building,
          new_floor: this.state.NewAddress.new_floor,
          new_road: this.state.NewAddress.new_road,
        },
      });
    } else {
      await this.setState({
        NewAddress: {
          country_name: country_name,
          province_name: this.state.NewAddress.province_name,
          district_name: this.state.NewAddress.district_name,
          sub_district_name: this.state.NewAddress.sub_district_name,
          new_address: this.state.full_address,
          new_country: id,
          new_province: this.state.NewAddress.new_province,
          new_district: this.state.NewAddress.new_district,
          new_subdistrict: this.state.NewAddress.new_subdistrict,
          new_zipcode: this.state.NewAddress.new_zipcode,
          new_no: this.state.NewAddress.new_no,
          new_moo: this.state.NewAddress.new_moo,
          new_building: this.state.NewAddress.new_building,
          new_floor: this.state.NewAddress.new_floor,
          new_road: this.state.NewAddress.new_road,
        },
      });
    }
  };

  changeCountry = async (e) => {
    const id = e.target.value;
    this.setCountry(id);
  };
  changeProvince = async (e) => {
    const id = e.target.value;
    if (!id || id === "") {
      const { NewAddress } = this.state;
      let updateAddress = {
        ...NewAddress,
        new_province: "",
        province_name: "",
        new_district: "",
        new_subdistrict: "",
        district_name: "",
        sub_district_name: "",
        new_zipcode: "",
      };
      this.setState({
        NewAddress: updateAddress,
        district: "",
        sub_district: "",
        post_code: "",
        district_array: [],
        sub_district_array: [],
        code_postal_array: [],
      });
      return;
    }

    const findProvince = this.provinces.find((item) => String(item.Id) === id);
    const province_name = findProvince ? findProvince.ThName : "";
    await this.setState({
      NewAddress: {
        country_name: this.state.NewAddress.country_name,
        province_name: province_name,
        district_name: "",
        sub_district_name: "",
        new_address: "",
        new_country: this.state.NewAddress.new_country,
        new_province: id,
        new_district: "",
        new_subdistrict: "",
        new_zipcode: "",
        new_no: this.state.NewAddress.new_no,
        new_moo: this.state.NewAddress.new_moo,
        new_building: this.state.NewAddress.new_building,
        new_floor: this.state.NewAddress.new_floor,
        new_road: this.state.NewAddress.new_road,
      },
    });
  };

  changeDistrict = async (e) => {
    const id = e.target.value;
    if (!id || id === "") {
      const { NewAddress } = this.state;
      let updateAddress = {
        ...NewAddress,
        new_district: "",
        new_subdistrict: "",
        district_name: "",
        sub_district_name: "",
        new_zipcode: "",
      };
      this.setState({
        NewAddress: updateAddress,
        sub_district: "",
        post_code: "",
        sub_district_array: [],
        code_postal_array: [],
      });
      return;
    }

    const findDistrict = this.districts.find((item) => String(item.Id) === id);
    const district_name = findDistrict ? findDistrict.ThName : "";
    await this.setState({
      NewAddress: {
        country_name: this.state.NewAddress.country_name,
        province_name: this.state.NewAddress.province_name,
        district_name: district_name,
        sub_district_name: "",
        new_address: "",
        new_country: this.state.NewAddress.new_country,
        new_province: this.state.NewAddress.new_province,
        new_district: id,
        new_subdistrict: "",
        new_zipcode: "",
        new_no: this.state.NewAddress.new_no,
        new_moo: this.state.NewAddress.new_moo,
        new_building: this.state.NewAddress.new_building,
        new_floor: this.state.NewAddress.new_floor,
        new_road: this.state.NewAddress.new_road,
      },
    });
  };

  changeSubDistrict = async (e) => {
    const id = e.target.value;
    if (!id || id === "") {
      const { NewAddress } = this.state;
      let updateAddress = {
        ...NewAddress,
        new_subdistrict: "",
        sub_district_name: "",
        new_zipcode: "",
      };
      this.setState({
        NewAddress: updateAddress,
        post_code: "",
        code_postal_array: [],
      });
      return;
    }

    const findSubDistrict = this.subdistricts.find(
      (item) => String(item.Id) === id
    );
    const sub_district_name = findSubDistrict ? findSubDistrict.ThName : "";
    const { new_province, new_district } = this.state.NewAddress;
    const findPostcode = this.postcodes.find(
      (item) =>
        String(item.cnt_Id) === "212" &&
        String(item.pvn_Id) === new_province &&
        String(item.dst_Id) === new_district &&
        String(item.sdst_Id) === id
    );
    const post_code = findPostcode ? findPostcode.PostCode : "";
    await this.setState({
      NewAddress: {
        country_name: this.state.NewAddress.country_name,
        province_name: this.state.NewAddress.province_name,
        district_name: this.state.NewAddress.district_name,
        sub_district_name: sub_district_name,
        new_address: "",
        new_country: this.state.NewAddress.new_country,
        new_province: this.state.NewAddress.new_province,
        new_district: this.state.NewAddress.new_district,
        new_subdistrict: id,
        new_zipcode: post_code,
        new_no: this.state.NewAddress.new_no,
        new_moo: this.state.NewAddress.new_moo,
        new_building: this.state.NewAddress.new_building,
        new_floor: this.state.NewAddress.new_floor,
        new_road: this.state.NewAddress.new_road,
      },
    });
  };
  changePostCode = async (e) => {
    await this.setState({
      NewAddress: {
        country_name: this.state.NewAddress.country_name,
        province_name: this.state.NewAddress.province_name,
        district_name: this.state.NewAddress.district_name,
        sub_district_name: this.state.NewAddress.sub_district_name,
        new_address: "",
        new_country: this.state.NewAddress.new_country,
        new_province: this.state.NewAddress.new_province,
        new_district: this.state.NewAddress.new_district,
        new_subdistrict: this.state.NewAddress.new_subdistrict,
        new_zipcode: e.target.value,
        new_no: this.state.NewAddress.new_no,
        new_moo: this.state.NewAddress.new_moo,
        new_building: this.state.NewAddress.new_building,
        new_floor: this.state.NewAddress.new_floor,
        new_road: this.state.NewAddress.new_road,
      },
    });
  };

  handleHouseNumber = async (e) => {
    await this.setState({
      NewAddress: {
        country_name: this.state.NewAddress.country_name,
        province_name: this.state.NewAddress.province_name,
        district_name: this.state.NewAddress.district_name,
        sub_district_name: this.state.NewAddress.sub_district_name,
        new_address: "",
        new_country: this.state.NewAddress.new_country,
        new_province: this.state.NewAddress.new_province,
        new_district: this.state.NewAddress.new_district,
        new_subdistrict: this.state.NewAddress.new_subdistrict,
        new_zipcode: this.state.NewAddress.new_zipcode,
        new_no: e.target.value,
        new_moo: this.state.NewAddress.new_moo,
        new_building: this.state.NewAddress.new_building,
        new_floor: this.state.NewAddress.new_floor,
        new_road: this.state.NewAddress.new_road,
      },
    });
  };

  handleCurrentRoad = async (e) => {
    await this.setState({
      NewAddress: {
        country_name: this.state.NewAddress.country_name,
        province_name: this.state.NewAddress.province_name,
        district_name: this.state.NewAddress.district_name,
        sub_district_name: this.state.NewAddress.sub_district_name,
        new_address: "",
        new_country: this.state.NewAddress.new_country,
        new_province: this.state.NewAddress.new_province,
        new_district: this.state.NewAddress.new_district,
        new_subdistrict: this.state.NewAddress.new_subdistrict,
        new_zipcode: this.state.NewAddress.new_zipcode,
        new_no: this.state.NewAddress.new_no,
        new_moo: this.state.NewAddress.new_moo,
        new_building: this.state.NewAddress.new_building,
        new_floor: this.state.NewAddress.new_floor,
        new_road: e.target.value,
      },
    });
  };

  handleCurrentMoo = async (e) => {
    await this.setState({
      NewAddress: {
        country_name: this.state.NewAddress.country_name,
        province_name: this.state.NewAddress.province_name,
        district_name: this.state.NewAddress.district_name,
        sub_district_name: this.state.NewAddress.sub_district_name,
        new_address: "",
        new_country: this.state.NewAddress.new_country,
        new_province: this.state.NewAddress.new_province,
        new_district: this.state.NewAddress.new_district,
        new_subdistrict: this.state.NewAddress.new_subdistrict,
        new_zipcode: this.state.NewAddress.new_zipcode,
        new_no: this.state.NewAddress.new_no,
        new_moo: e.target.value,
        new_building: this.state.NewAddress.new_building,
        new_floor: this.state.NewAddress.new_floor,
        new_road: this.state.NewAddress.new_road,
      },
    });
  };

  handleCurrentBuilding = async (e) => {
    await this.setState({
      NewAddress: {
        country_name: this.state.NewAddress.country_name,
        province_name: this.state.NewAddress.province_name,
        district_name: this.state.NewAddress.district_name,
        sub_district_name: this.state.NewAddress.sub_district_name,
        new_address: "",
        new_country: this.state.NewAddress.new_country,
        new_province: this.state.NewAddress.new_province,
        new_district: this.state.NewAddress.new_district,
        new_subdistrict: this.state.NewAddress.new_subdistrict,
        new_zipcode: this.state.NewAddress.new_zipcode,
        new_no: this.state.NewAddress.new_no,
        new_moo: this.state.NewAddress.new_moo,
        new_building: e.target.value,
        new_floor: this.state.NewAddress.new_floor,
        new_road: this.state.NewAddress.new_road,
      },
    });
  };

  handleCurrentFloor = async (e) => {
    await this.setState({
      NewAddress: {
        country_name: this.state.NewAddress.country_name,
        province_name: this.state.NewAddress.province_name,
        district_name: this.state.NewAddress.district_name,
        sub_district_name: this.state.NewAddress.sub_district_name,
        new_address: "",
        new_country: this.state.NewAddress.new_country,
        new_province: this.state.NewAddress.new_province,
        new_district: this.state.NewAddress.new_district,
        new_subdistrict: this.state.NewAddress.new_subdistrict,
        new_zipcode: this.state.NewAddress.new_zipcode,
        new_no: this.state.NewAddress.new_no,
        new_moo: this.state.NewAddress.new_moo,
        new_building: this.state.NewAddress.new_building,
        new_floor: e.target.value,
        new_road: this.state.NewAddress.new_road,
      },
    });
  };
  handleAddressAnotherCountry = async (e) => {
    await this.setState({
      NewAddress: {
        country_name: this.state.NewAddress.country_name,
        province_name: this.state.NewAddress.province_name,
        district_name: this.state.NewAddress.district_name,
        sub_district_name: this.state.NewAddress.sub_district_name,
        new_address: e.target.value,
        new_country: this.state.NewAddress.new_country,
        new_province: this.state.NewAddress.new_province,
        new_district: this.state.NewAddress.new_district,
        new_subdistrict: this.state.NewAddress.new_subdistrict,
        new_zipcode: this.state.NewAddress.new_zipcode,
        new_no: this.state.NewAddress.new_no,
        new_moo: this.state.NewAddress.new_moo,
        new_building: this.state.NewAddress.new_building,
        new_floor: this.state.NewAddress.new_floor,
        new_road: this.state.NewAddress.new_road,
      },
    });
  };

  selectDistrict(id) {
    if (id === "") {
      this.setState({
        district: "",
        sub_district: "",
        post_code: "",
        district_array: [],
        sub_district_array: [],
        code_postal_array: [],
      });
      return;
    }

    var url = _url + "/constant/district/";
    var fullUrl = url + id;
    axios
      .get(fullUrl)
      .then((res) => {
        const district_array = [];
        res.data.Data.forEach((value, i) => {
          district_array[value.Id] = value;
        });
        const district = res.data.Data.map((item, i) => {
          return (
            <option value={item.Id} key={i} style={{ color: "#003b66" }}>
              {item.ThName}
            </option>
          );
        });
        this.setState({ district: district });
        this.setState({ district_array: district_array });
      })
      .catch((error) => {
        var mss = "";
        var title_mss = "";
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss =
            error.response.data.Data !== undefined &&
              error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data !== undefined &&
              error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
        popupErrorApi(mss, title_mss, true);
      });
  }

  selectSubDistrict(id) {
    if (id === "") {
      this.setState({
        sub_district: "",
        post_code: "",
        sub_district_array: [],
        code_postal_array: [],
      });
      return;
    }

    var url = _url + "/constant/subdistrict/";
    var fullUrl = url + id;
    axios
      .get(fullUrl)
      .then((res) => {
        const sub_district_array = [];
        res.data.Data.forEach((value, i) => {
          sub_district_array[value.Id] = value;
        });
        const sub_district = res.data.Data.map((item, i) => {
          return (
            <option value={item.Id} key={i} style={{ color: "#003b66" }}>
              {item.ThName}
            </option>
          );
        });
        this.setState({ sub_district: sub_district });
        this.setState({ sub_district_array: sub_district_array });
      })
      .catch((error) => {
        var mss = "";
        var title_mss = "";
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss =
            error.response.data.Data !== undefined &&
              error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data !== undefined &&
              error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
        popupErrorApi(mss, title_mss, true);
      });
  }

  selectPostCode(province_id, district_id, sub_district_id) {
    if (
      !province_id ||
      province_id === "" ||
      !district_id ||
      district_id === "" ||
      !sub_district_id ||
      sub_district_id === ""
    ) {
      this.setState({ post_code: "", code_postal_array: [] });
      return;
    }

    var url = _url + "/constant/postcode/212/";
    var fullUrl = url + province_id + "/" + district_id + "/" + sub_district_id;
    axios
      .get(fullUrl)
      .then((res) => {
        const code_postal_array = [];
        res.data.Data.forEach((value, i) => {
          code_postal_array[value.postCode] = value;
        });
        const post_code = res.data.Data.map((item, i) => {
          return (
            <option value={item.postCode} key={i}>
              {item.PostCode}
            </option>
          );
        });
        this.setState({ post_code: post_code });
        this.setState({ code_postal_array: code_postal_array });
      })
      .catch((error) => {
        var mss = "";
        var title_mss = "";
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss =
            error.response.data.Data !== undefined &&
              error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data !== undefined &&
              error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
        popupErrorApi(mss, title_mss, true);
      });
  }
  async postcode_material(province_id, district_id, sub_district_id) {
    try {
      if (sub_district_id !== "" && sub_district_id !== null) {
        var url = _url + "/constant/postcode/212/";
        var fullUrl =
          url + province_id + "/" + district_id + "/" + sub_district_id;
        return await axios
          .get(fullUrl)
          .then(function (response) {
            // console.log(response);
            return response.data["Data"];
          })
          .catch((error) => {
            var mss = "";
            var title_mss = "";
            if (!!error.isAxiosError && !error.response) {
              title_mss = "เกิดข้อผิดพลาด";
              mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            } else {
              title_mss =
                error.response.data.Data !== undefined &&
                  error.response.data.Data[0][0].MsgDescTitle !== ""
                  ? error.response.data.Data[0][0].MsgDescTitle
                  : "เกิดข้อผิดพลาด";
              mss =
                error.response.data.Data !== undefined &&
                  error.response.data.Data[0][0].MsgDesc !== ""
                  ? error.response.data.Data[0][0].MsgDesc
                  : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            }
            popupErrorApi(mss, title_mss, true);
          });
      } else return [];
    } catch (error) {
      // console.log(error);
      return [];
    }
  }
  render() {
    const new_address = this.state.NewAddress;
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">แก้ไขที่อยู่</div>
                    </div>

                    <form
                      id="myForm"
                      name="myForm"
                      onSubmit={this.handleSubmit}
                    >
                      <div className="bg_content_white over_f_hide">
                        <div
                          className="in_bg_white autoscroll"
                          style={{
                            top: 0,
                            left: 0,
                            right: 0,
                            padding: "0 21px",
                          }}
                        >
                          <div className="row_idcard">
                            <div className="label_idcard">
                              ประเทศ <span>*</span>
                            </div>
                            <div className="main_select">
                              <select
                                className="select_style"
                                name="new_country"
                                value={new_address.new_country}
                                onChange={this.changeCountry}
                                style={{
                                  borderColor:
                                    new_address.new_country !== ""
                                      ? "#003b66"
                                      : "#ccc",
                                  color:
                                    new_address.new_countrye == ""
                                      ? "#003b6694"
                                      : "#003b66",
                                }}
                              >
                                <option
                                  style={{ color: "#003b6694" }}
                                  disabled
                                  hidden
                                  value=""
                                >
                                  โปรดเลือก
                                </option>
                                {this.countries &&
                                  this.countries.map((item) => (
                                    <option value={item.Id} key={item.Id}>
                                      {item.ThName}
                                    </option>
                                  ))}
                              </select>
                              <img src="images/angle-arrow-down.svg" />
                            </div>
                            <span
                              className="span_f2_new_country"
                              style={{
                                display:
                                  new_address.new_country !== ""
                                    ? "none"
                                    : "block",
                                textAlign: "right",
                              }}
                            >
                              กรุณาเลือกประเทศ
                            </span>
                          </div>

                          {new_address.new_country != 212 ? (
                            <div className="row_idcard">
                              <div>ที่อยู่ *</div>
                              <textarea
                                id="new_address"
                                name="new_address"
                                rows="6"
                                value={new_address.new_address}
                                onChange={this.handleAddressAnotherCountry}
                                style={{
                                  borderColor:
                                    new_address.new_address !== ""
                                      ? "#003b66"
                                      : "#ccc",
                                }}
                              ></textarea>
                              <span
                                className="span_f2_new_address"
                                style={{
                                  display:
                                    new_address.new_country != 212 &&
                                      new_address.new_address !== ""
                                      ? "none"
                                      : "block",
                                  textAlign: "right",
                                }}
                              >
                                กรุณาระบุที่อยู่
                              </span>
                            </div>
                          ) : (
                            <div>
                              <div className="row_idcard">
                                <div className="label_idcard">
                                  จังหวัด <span>*</span>
                                </div>

                                <div className="main_select">
                                  <select
                                    className="select_style"
                                    name="new_province"
                                    value={new_address.new_province}
                                    onChange={this.changeProvince}
                                    style={{
                                      borderColor:
                                        new_address.new_province !== ""
                                          ? "#003b66"
                                          : "#ccc",
                                      color:
                                        new_address.new_province == ""
                                          ? "#003b6694"
                                          : "#003b66",
                                    }}
                                  >
                                    <option
                                      style={{ color: "#003b6694" }}
                                      disabled
                                      hidden
                                      value=""
                                    >
                                      โปรดเลือก
                                    </option>
                                    {this.provinces &&
                                      this.provinces.map((item) => (
                                        <option value={item.Id} key={item.Id}>
                                          {item.ThName}
                                        </option>
                                      ))}
                                  </select>
                                  <img src="images/angle-arrow-down.svg" />
                                </div>
                                <span
                                  className="span_f2_new_province"
                                  style={{
                                    display:
                                      new_address.new_province !== ""
                                        ? "none"
                                        : "block",
                                    textAlign: "right",
                                  }}
                                >
                                  กรุณาระบุจังหวัด
                                </span>
                              </div>
                              <div className="row_idcard">
                                <div>
                                  เขต / อำเภอ <span>*</span>
                                </div>
                                <div className="main_select">
                                  <select
                                    className="select_style"
                                    name="new_district"
                                    value={new_address.new_district}
                                    onChange={this.changeDistrict}
                                    style={{
                                      borderColor:
                                        new_address.new_district !== ""
                                          ? "#003b66"
                                          : "#ccc",
                                      color:
                                        new_address.new_district == ""
                                          ? "#003b6694"
                                          : "#003b66",
                                    }}
                                  >
                                    <option
                                      style={{ color: "#003b6694" }}
                                      disabled
                                      hidden
                                      value=""
                                    >
                                      โปรดเลือก
                                    </option>
                                    {this.districts &&
                                      this.districts
                                        .filter(
                                          (item) =>
                                            String(item.pvn_Id) ===
                                            new_address.new_province
                                        )
                                        .map((item) => (
                                          <option value={item.Id} key={item.Id}>
                                            {item.ThName}
                                          </option>
                                        ))}
                                  </select>
                                  <img src="images/angle-arrow-down.svg" />
                                </div>
                                <span
                                  className="span_f2_new_district"
                                  style={{
                                    display:
                                      new_address.new_district !== ""
                                        ? "none"
                                        : "block",
                                    textAlign: "right",
                                  }}
                                >
                                  กรุณาระบุเขต / อำเภอ
                                </span>
                              </div>
                              <div className="row_idcard">
                                <div>
                                  แขวง / ตำบล <span>*</span>
                                </div>
                                <div className="main_select">
                                  <select
                                    className="select_style"
                                    name="new_subdistrict"
                                    value={new_address.new_subdistrict}
                                    onChange={this.changeSubDistrict}
                                    style={{
                                      borderColor:
                                        new_address.new_subdistrict !== ""
                                          ? "#003b66"
                                          : "#ccc",
                                      color:
                                        new_address.new_subdistrict == ""
                                          ? "#003b6694"
                                          : "#003b66",
                                    }}
                                  >
                                    <option
                                      style={{ color: "#003b6694" }}
                                      disabled
                                      hidden
                                      value=""
                                    >
                                      โปรดเลือก
                                    </option>
                                    {this.subdistricts &&
                                      this.subdistricts
                                        .filter(
                                          (item) =>
                                            String(item.dst_Id) ===
                                            new_address.new_district
                                        )
                                        .map((item) => (
                                          <option value={item.Id} key={item.Id}>
                                            {item.ThName}
                                          </option>
                                        ))}
                                  </select>
                                  <img src="images/angle-arrow-down.svg" />
                                </div>
                                <span
                                  className="span_f2_new_subdistrict"
                                  style={{
                                    display:
                                      new_address.new_subdistrict !== ""
                                        ? "none"
                                        : "block",
                                    textAlign: "right",
                                  }}
                                >
                                  กรุณาระบุแขวง / ตำบล
                                </span>
                              </div>
                              <div className="row_idcard">
                                <div>
                                  รหัสไปรษณีย์ <span>*</span>
                                </div>
                                <div className="main_select">
                                  <select
                                    className="select_style"
                                    name="new_zipcode"
                                    value={new_address.new_zipcode}
                                    onChange={this.changePostCode}
                                    style={{
                                      borderColor:
                                        new_address.new_zipcode !== ""
                                          ? "#003b66"
                                          : "#ccc",
                                      color:
                                        new_address.new_zipcode == ""
                                          ? "#003b6694"
                                          : "#003b66",
                                    }}
                                  >
                                    <option
                                      style={{ color: "#003b6694" }}
                                      disabled
                                      hidden
                                      value=""
                                    >
                                      โปรดเลือก
                                    </option>
                                    {this.postcodes &&
                                      this.postcodes
                                        .filter(
                                          (item) =>
                                            String(item.cnt_Id) === "212" &&
                                            String(item.pvn_Id) ===
                                            new_address.new_province &&
                                            String(item.dst_Id) ===
                                            new_address.new_district &&
                                            String(item.sdst_Id) ===
                                            new_address.new_subdistrict
                                        )
                                        .map((item) => (
                                          <option
                                            value={item.PostCode}
                                            key={item.PostCode}
                                          >
                                            {item.PostCode}
                                          </option>
                                        ))}
                                  </select>
                                  <img src="images/angle-arrow-down.svg" />
                                </div>
                                <span
                                  className="span_f2_new_zipcode"
                                  style={{
                                    display:
                                      new_address.new_zipcode !== ""
                                        ? "none"
                                        : "block",
                                    textAlign: "right",
                                  }}
                                >
                                  กรุณาระบุรหัสไปรษณีย์
                                </span>
                              </div>
                              <div className="row_idcard">
                                <div>
                                  เลขที่ <span>*</span>
                                </div>
                                <input
                                  className="input_idcard"
                                  name="new_no"
                                  value={new_address.new_no}
                                  onChange={this.handleHouseNumber}
                                  style={{
                                    borderColor:
                                      new_address.new_no !== ""
                                        ? "#003b66"
                                        : "#ccc",
                                  }}
                                />
                                <span
                                  className="span_f2_new_no"
                                  style={{
                                    display:
                                      new_address.new_no === "" ||
                                        new_address.new_no === undefined
                                        ? "block"
                                        : "none",
                                    textAlign: "right",
                                  }}
                                >
                                  กรุณาระบุเลขที่
                                </span>
                              </div>
                              <div className="row_idcard">
                                <div>ถนน</div>
                                <input
                                  className="input_idcard"
                                  name="new_road"
                                  value={new_address.new_road}
                                  onChange={this.handleCurrentRoad}
                                  style={{
                                    borderColor:
                                      new_address.new_road !== ""
                                        ? "#003b66"
                                        : "#ccc",
                                  }}
                                />
                              </div>
                              <div className="row_idcard">
                                <div>หมู่ที่</div>
                                <input
                                  className="input_idcard"
                                  name="new_moo"
                                  value={new_address.new_moo}
                                  onChange={this.handleCurrentMoo}
                                  style={{
                                    borderColor:
                                      new_address.new_moo !== ""
                                        ? "#003b66"
                                        : "#ccc",
                                  }}
                                />
                              </div>
                              <div className="row_idcard">
                                <div>หมู่บ้าน / อาคาร</div>
                                <input
                                  className="input_idcard"
                                  name="new_building"
                                  value={new_address.new_building}
                                  onChange={this.handleCurrentBuilding}
                                  style={{
                                    borderColor:
                                      new_address.new_building !== ""
                                        ? "#003b66"
                                        : "#ccc",
                                  }}
                                />
                              </div>
                              <div className="row_idcard">
                                <div>ชั้นที่</div>
                                <input
                                  className="input_idcard"
                                  name="new_floor"
                                  value={new_address.new_floor}
                                  onChange={this.handleCurrentFloor}
                                  style={{
                                    borderColor:
                                      new_address.new_floor !== ""
                                        ? "#003b66"
                                        : "#ccc",
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="under_fix_btn">
                          <ButtonCustom
                            type="submit"
                            label="บันทึกที่อยู่นี้"
                            color="blue"
                            btnClass="long_btn"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default KtbstIDCAddressForm;
