import React, { Component } from 'react';
import ButtonCustom from '../../components/button/ButtonCustom';
import './PoliticianRelation.css';
import {
  checkEditPage,
  checkInternet,
  popupNetworkLost,
  download_ico_alert,
} from '../../components/helpers/Helpers';
import axios from 'axios';
import { el } from 'date-fns/locale';
import validator from 'validator';
import { IMaskInput } from 'react-imask';
import Loading from '../../components/loading/loading';
import to from 'await-to-js';
import ktbsApi from './../../api';
import swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { isMobile } from 'react-device-detect';
import BannerContentLeft from '../../components/BannerContentLeft/BannerContentLeft';
class PoliticianRelation extends Component {
  constructor(props) {
    super(props);
    const f5_rel = JSON.parse(localStorage.getItem('f5_relationship'));
    let isCheck1 = f5_rel
      ? f5_rel.f5_relationship_have_a_position &&
        (f5_rel.f5_relationship_have_a_position === '0' ||
          f5_rel.f5_relationship_have_a_position === 0)
        ? true
        : false
      : true;
    let isCheck2 = f5_rel
      ? f5_rel.f5_relationship_have_a_position &&
        (f5_rel.f5_relationship_have_a_position === '1' ||
          f5_rel.f5_relationship_have_a_position === 1)
        ? true
        : false
      : false;
    this.state = {
      f5_relationship_have_a_position: f5_rel
        ? f5_rel.f5_relationship_have_a_position ?? ''
        : '0',
      f5_relationship_have_a_position_text: f5_rel
        ? f5_rel.f5_relationship_have_a_position_text ?? ''
        : '',
      set_show: isCheck2,
      isDisconnected: true,
      loading: false,
      btns: [
        {
          id: '1',
          text: 'ไม่ใช่ ฉันไม่เกี่ยวข้องกับนักการเมือง',
          isCheck: isCheck1,
        },
        { id: '2', text: 'ใช่ ฉันเกี่ยวข้องกับนักการเมือง', isCheck: isCheck2 },
      ],
    };
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    if (checkEditPage()) {
      return;
    }
    var path = window.location.href.indexOf('edit');
    if (path < 0) {
      this.props.history.push('/statusChildren');
    } else {
      this.props.history.push('/summaryshow');
      localStorage.setItem('editPage', 'PoliticianRelation');
    }
  }
  handleToogleBTN = async (i) => {
    let btns = this.state.btns;
    if (i === 0) {
      this.setState({
        set_show: false,
        f5_relationship_have_a_position_text: '',
      });
    } else {
      this.setState({ set_show: true });
    }
    btns.forEach((chkbox, val) => {
      if (val === i) {
        chkbox.isCheck = true;
      } else {
        chkbox.isCheck = false;
      }
    });
    // console.log(i);
    await this.setState({
      f5_relationship_have_a_position: i,
    });
    await this.setState({ btns: btns });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    var a = document.getElementsByClassName('input_idcard');
    var b = document.getElementsByClassName('span_' + a[0].name);
    var f5_relationship = [];
    f5_relationship = {
      f5_relationship_have_a_position:
        this.state.f5_relationship_have_a_position,
      f5_relationship_have_a_position_text: validator.trim(
        this.state.f5_relationship_have_a_position_text
      ),
    };
    await localStorage.setItem(
      'f5_relationship',
      JSON.stringify(f5_relationship)
    );
    // this.props.history.push('/financialdetail');
    if (a[0].value === '' && this.state.set_show) {
      b[0].style.display = 'flex';
      return false;
    } else {
      b[0].style.display = 'none';
      var str = window.location.href;
      let re = /edit/;
      const back_to_edit = re.test(str);
      // return
      // if (checkInternet()) {
      if (back_to_edit) {
        let error;
        let result;
        let mss = '';
        const MySwal = withReactContent(swal);
        const HeaderManp = JSON.parse(localStorage.getItem('HeaderManp'));
        const head_token = HeaderManp.head_token;
        const f5_relationship = JSON.parse(
          localStorage.getItem('f5_relationship')
        )
          ? JSON.parse(localStorage.getItem('f5_relationship'))
          : [];
        const {
          f5_relationship_have_a_position,
          f5_relationship_have_a_position_text,
        } = f5_relationship;
        const otherInformation = {
          head_token,
          f5_relationship_have_a_position: f5_relationship_have_a_position,
          f5_relationship_have_a_position_text:
            f5_relationship_have_a_position_text,
        };
        await this.setState({ loading: true });
        [error, result] = await to(
          ktbsApi.post('/form/other_information/manp', otherInformation)
        );
        if (
          error ||
          !result ||
          !result.Data ||
          !result.Data[0] ||
          !result.Data[0][0] ||
          result.Data[0][0].IsSuccess !== 1
        ) {
          if (result) {
            mss =
              result.Data[0][0].MsgDesc !== ''
                ? result.Data[0][0].MsgDesc
                : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
          } else if (error) {
            if (!!error.isAxiosError && !error.response) {
              mss = 'network error';
            } else {
              mss =
                error.response.data.Data[0][0].MsgDesc !== ''
                  ? error.response.data.Data[0][0].MsgDesc
                  : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
            }
          } else {
            mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
          }
          MySwal.fire({
            imageUrl: '/images/ico_alert.svg',
            imageWidth: 100,
            imageHeight: 100,
            customClass: {
              confirmButton: 'long_btn_fix',
            },
            confirmButtonColor: '#003b66',
            confirmButtonText: 'กลับ',
            title: '<div class="title_popup">เกิดข้อผิดพลาด</div>',
            html: '<div class="content_popup">' + mss + '</div>',
          });
          await this.setState({ loading: false });
          return;
        } else {
          // console.log(result.Data[0][0].MsgDesc !== "");
          await this.setState({ loading: false });
        }

        const BackToSummaryRisk = JSON.parse(
          localStorage.getItem('BackToSummaryRisk')
        );
        if (BackToSummaryRisk) {
          localStorage.removeItem('BackToSummaryRisk');
          window.location = 'summary_risk';
          return;
        }

        await this.props.history.push('/summaryshow');
        await localStorage.setItem('editPage', 'PoliticianRelation');
      } else {
        const BackToSummaryRisk = JSON.parse(
          localStorage.getItem('BackToSummaryRisk')
        );
        if (BackToSummaryRisk) {
          localStorage.removeItem('BackToSummaryRisk');
          window.location = 'summary_risk';
          return;
        }

        await this.props.history.push('/signed_attatched_ex');
      }
      // } else {
      //   this.setState({
      //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
      //   })
      // }
    }
  };
  handleChange = async (e) => {
    e.preventDefault();
    const isCheckbox = e.target.type === 'checkbox';
    const value = e.target.value;
    await this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : value,
    });
  };

  handleIChange = async (v, ref) => {
    await this.setState({
      [ref.el.input.name]: v,
    });
    // if (this.state.isSubmit) {
    //   await this.validate();
    // }
  };

  async componentDidMount() {
    // await download_ico_alert();
  }

  render() {
    return (
      <div className={isMobile ? '' : 'body'}>
        <header
          className={isMobile ? '' : 'header-area'}
          style={{ display: isMobile ? 'none' : 'block' }}
        >
          <div className={'container everest-nav-container'}>
            <div className={'row'}>
              <div className={'col-md-6'}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={'col-md-6 flex_end'}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? '' : 'desktop_content section-gap-full'}
          style={{
            backgroundImage: isMobile ? 'none' : 'url(/images/bg.jpg)',
          }}
        >
          <div className={isMobile ? '' : 'container'}>
            <div className={isMobile ? '' : 'row'}>
              <div
                className={'col-md-6'}
                style={{ display: isMobile ? 'none' : 'block' }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? '' : 'col-md-6'}>
                {/* content mobile */}
                <div className="Page">
                  <Loading status={this.state.loading} />
                  <div className="top_main_back">
                    <div className="main_back" onClick={this.goBack}>
                      <img
                        src={
                          isMobile
                            ? '/images/angle-arrow-left.svg'
                            : '/images/angle-arrow-left-blue.svg'
                        }
                        alt="forward"
                        style={{
                          display: checkEditPage() ? 'none' : 'block',
                          marginLeft: '3px',
                        }}
                        className="arrow_back"
                      />
                    </div>
                    <div className="text_title">สัมพันธ์กับนักการเมือง</div>
                  </div>

                  <div className="bg_content_white over_f_hide">
                    <div
                      className="in_bg_white autoscroll"
                      style={{ top: 0, left: 0, right: 0, padding: '0 21px' }}
                    >
                      <div className="title_row" style={{ marginBottom: 24 }}>
                        ท่านเป็นผู้มีสถานภาพทางการเมืองหรือเป็นสมาชิกในครอบครัวหรือเป็นผู้ใกล้ชิดกับบุคคลผู้มีสถานภาพทางการเมืองหรือไม่
                        ?
                      </div>
                      {this.state.btns.map((item, i) => {
                        return (
                          <div
                            className={
                              item.isCheck
                                ? 'BtnChoice long_btn_status_user active'
                                : 'BtnChoice long_btn_status_user'
                            }
                            key={i}
                            onClick={() => this.handleToogleBTN(i)}
                          >
                            {item.text}
                          </div>
                        );
                      })}

                      <div
                        className="row_idcard "
                        style={{
                          display: this.state.set_show ? 'block' : 'none',
                        }}
                      >
                        <div style={{ marginBottom: '15px' }}>
                          ขอข้อมูลเพิ่มเติม *
                        </div>
                        <IMaskInput
                          mask={/^[A-Za-zก-๙.\s\-]+$/}
                          placeholder="กรณีตอบ “ใช่” โปรดระบุตำแหน่ง"
                          value={
                            this.state.f5_relationship_have_a_position_text
                          }
                          unmask={true}
                          className="input_idcard"
                          name="f5_relationship_have_a_position_text"
                          style={{
                            textDecorationLine: 'none',
                            borderBottomColor:
                              this.state
                                .f5_relationship_have_a_position_text === ''
                                ? '#ccc'
                                : '#003b66',
                          }}
                          onAccept={this.handleIChange}
                        />
                        <span className="span_f5_relationship_have_a_position_text">
                          กรุณาระบุตำแหน่ง
                        </span>
                      </div>
                    </div>

                    <div className="under_fix_btn">
                      <ButtonCustom
                        handle={this.handleSubmit}
                        label={checkEditPage() ? 'บันทึกการแก้ไข' : 'ถัดไป'}
                        color="blue"
                        btnClass="long_btn"
                      ></ButtonCustom>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PoliticianRelation;
