import React, { Component } from "react";
import "./ATS.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import to from "await-to-js";
import ktbsApi from "./../../api";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert2";
import Loading from "../../components/loading/loading";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
class ATS extends Component {
  constructor(props) {
    super(props);
    let atsDataUser = JSON.parse(localStorage.getItem("atsDataUser"));
    let f0 = JSON.parse(localStorage.getItem("f0"));
    if (!atsDataUser) {
      this.props.history.push("/ats_form");
    }
    this.state = {
      bankCode: atsDataUser.bankCode,
      bank_id: atsDataUser.bank_id,
      bank_name: atsDataUser.bank_name,
      bank_branch: atsDataUser.bank_branch,
      bank_account_no: atsDataUser.bank_account_no,
      bank_account_name: atsDataUser.bank_account_name
        ? atsDataUser.bank_account_name
        : f0.f0_firstname + " " + f0.f0_lastname,
      status_button: true,
      loading: false,
    };
    this.goback = this.goback.bind(this);
    this.handlesubmit = this.handlesubmit.bind(this);
  }

  goback = () => {
    this.props.history.push("/ats_form");
  };
  handlesubmit = async () => {
    const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
    const { head_token } = HeaderManp;
    const bankAccountParam = {
      head_token,
      f7_isother: "0",
      add_account_json: [
        {
          SAfba_Id: "0",
          SAfba_SAmbk_Id: this.state.bank_id,
          SAfba_SAmbkb_Id: this.state.bank_branch.value,
          SAfba_SAmbkt_Id: "",
          SAfba_AccountNo: this.state.bank_account_no,
          SAfba_AccountName: this.state.bank_account_name,
          SAfba_UseATS: "1",
          SAfba_UseDividend: "0",
        },
      ],
    };
    let error;
    let result;
    this.setState({ loading: true });
    [error, result] = await to(
      ktbsApi.post("form/bank_account/manp", bankAccountParam)
    );
    if (
      error ||
      !result ||
      !result.Data ||
      !result.Data[0] ||
      !result.Data[0][0] ||
      result.Data[0][0].IsSuccess !== 1
    ) {
      const mss = result
        ? result.Data[0].MsgDesc
        : error.response.data.Data[0][0].MsgDesc;
      const MySwal = withReactContent(swal);
      MySwal.fire({
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        customClass: {
          confirmButton: "long_btn_fix",
        },
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title: '<div class="title_popup">เกิดข้อผิดพลาด</div>',
        html: mss
          ? `<div class="content_popup">${mss}</div>`
          : '<div class="content_popup">ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง</div>',
      });
      this.setState({ loading: false });
      return;
    }
    this.setState({ loading: false });
    localStorage.setItem("currentStep", "summaryshow");
    this.props.history.push("/summaryshow");
  };

  async componentDidMount() {
    // await axios.get('https://ws03.ktbst.co.th/ktbst-life/single-application/constant/bank').then((res) => {
    //   const bank = res.data.Data.map((item, i) => {
    //     if (item.Id == this.state.bank_id) {
    //       this.setState({ bank_name: item.ThName });
    //     }
    //   });
    // });
  }

  render() {
    library.add(fas);
    return (
      <div
        className={isMobile ? "" : "body"}
        style={{
          backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
        }}
      >
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section className={isMobile ? "" : "desktop_content section-gap-full"}>
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goback}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">
                        สมัครบริการหักบัญชีอัตโนมัติ
                      </div>
                    </div>

                    <div className="bg_content_white over_f_hide">
                      <div
                        className="in_bg_white autoscroll"
                        style={{ top: 0 }}
                      >
                        <div className="content_icon_check">
                          <div className="circle_check">
                            <FontAwesomeIcon icon="check"></FontAwesomeIcon>
                          </div>
                          <div className="text_under_check">
                            สมัครบริการหักบัญชีอัตโนมัติ <br /> (ATS) สำเร็จ
                          </div>
                        </div>

                        <div style={{ marginTop: 24 }}>
                          <div className={"row_idcard active"}>
                            <div style={{ fontSize: 18 }}>ธนาคาร</div>
                            <input
                              type="text"
                              className="input_idcard"
                              name="bank_name"
                              value={this.state.bank_name}
                              disabled
                            ></input>
                          </div>
                          <div className={"row_idcard active"}>
                            <div style={{ fontSize: 18 }}>สาขา</div>
                            <input
                              type="text"
                              className="input_idcard"
                              name="bank_branch"
                              value={this.state.bank_branch.label}
                              disabled
                            ></input>
                          </div>
                          <div className={"row_idcard active"}>
                            <div style={{ fontSize: 18 }}>ชื่อบัญชี</div>
                            <input
                              type="text"
                              className="input_idcard"
                              name="bank_account_name"
                              value={this.state.bank_account_name}
                              disabled
                            ></input>
                          </div>
                          <div className={"row_idcard active"}>
                            <div style={{ fontSize: 18 }}>เลขที่บัญชี</div>
                            <input
                              type="text"
                              className="input_idcard"
                              name="bank_account_no"
                              value={
                                this.state.bank_account_no.substring(
                                  0,
                                  this.state.bank_account_no.length - 4
                                ) + "xxxx"
                              }
                              disabled
                            ></input>
                          </div>
                        </div>
                      </div>

                      <div className="under_fix_btn">
                        <ButtonCustom
                          handle={this.handlesubmit}
                          label={"ถัดไป"}
                          color={"blue"}
                          btnClass="long_btn"
                        />
                      </div>
                    </div>
                  </div>
                  <Loading status={this.state.loading} />
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ATS;
