import React, { Component } from "react";
import { config } from "./../../constants";
class BannerContentLeft extends Component {
  render() {
    return (
      <div>
        <div className={"head-left"}>
          เปิดบัญชีออนไลน์ ยืนยันตัวตนด้วย
          <br />
          National Digital ID (NDID)
        </div>
        <p className={"banner-left-p"}>
          เปิดบัญชีเพื่อใช้บริการของ {config.WEB_NAME}
          <br />
          ผ่านช่องทางออนไลน์ ยืนยันตัวตนผ่านระบบ NDID
          <br />
          สะดวก รวดเร็ว ปลอดภัย ไม่ต้องจัดส่งเอกสาร
          <br />
          เปิดบัญชีและเทรดได้ทันที
        </p>
        <div className={"banner-left-tel"}>
          DAOL Contact Center 0 2351 1800
          <br />
        </div>
      </div>
    );
  }
}

export default BannerContentLeft;
