/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import "./KtbstIDCAddressChoose.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import axios from "axios";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faIgloo, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../components/loading/loading";
import {
  contactAddress,
  idAddress,
  workAddress,
  homeAddress,
  config,
} from "./../../constants";
import {
  findCountryByCode,
  setNonThaiAddressText,
  findProvinceByName,
  findDistrictByName,
  findSubDistrictByName,
  findPostcode,
  setThaiAddressText,
} from "./../../components/helpers/address";

import to from "await-to-js";
import ktbsApi from "./../../api";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert2";
import { checkEditPage, popupErrorApi } from "../../components/helpers/Helpers";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
const _url = config.baseAPIURL03;

class KtbstIDCAddressChoose extends Component {
  countries;
  provinces;
  districts;
  subdistricts;
  postcodes;
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      item: [],
      id_address: null,
      contact_address: null,
      office_address: null,
      home_address: null,
      loading: true,
      currentStep: localStorage.getItem("currentStep")
        ? localStorage.getItem("currentStep")
        : "",
      isDisconnected: false,
    };
  }

  goBack() {
    if (checkEditPage() || this.state.currentStep === "summaryshow") {
      // this.props.history.push('/summaryshow');
      const passNDID = localStorage.getItem("passNDID");
      if (passNDID) {
        this.props.history.push("/tc_ndid");
      } else {
        this.props.history.push("/ndid");
      }
      return;
    } else {
      const passNDID = localStorage.getItem("passNDID");
      if (passNDID) {
        this.props.history.push("/tc_ndid");
      } else {
        this.props.history.push("/ndid");
      }
    }
  }

  async componentDidMount() {
    await this.setState({ loading: false });
    localStorage.removeItem("back_from_productchoice");
    // await this.handleSubmit();
    // await download_ico_alert();
    await this.initData();
  }

  async initData() {
    const { countries, provinces, districts, subdistricts, postcodes } =
      this.props.address;

    this.countries = countries;
    this.provinces = provinces;
    this.districts = districts;
    this.subdistricts = subdistricts;
    this.postcodes = postcodes;

    const ReferenceId = (await localStorage.getItem("ReferenceId")) || null;
    // TODO: FOR TEST CHANGE TO GET REFERENCE ID FROM SOMEWHERE
    // const ReferenceId = '1253c1d8-34ff-49a7-b6b7-2175fe77be11'; // Dummy 1
    // const ReferenceId = '4036866a-6a49-49f2-8789-cf6383688ccf'; // Dummy 2
    // const ReferenceId = '2a681c34-eb16-4395-8271-a89da01d21c2'; // Dummy 3

    let RetrieveData =
      (await JSON.parse(localStorage.getItem("RetrieveData"))) || null;
    let IDAddress =
      (await JSON.parse(localStorage.getItem("IDAddress"))) || null;
    let HomeAddress =
      (await JSON.parse(localStorage.getItem("HomeAddress"))) || null;
    let ContactAddress =
      (await JSON.parse(localStorage.getItem("ContactAddress"))) || null;
    let WorkAddress =
      (await JSON.parse(localStorage.getItem("WorkAddress"))) || null;

    if (!RetrieveData) {
      const {
        returnData,
        newIDCardAddress,
        newOfficeAddress,
        newContactAddress,
      } = []; //await this.getRetrieveData(ReferenceId);

      // RetrieveData = returnData;
      // IDAddress = newIDCardAddress;
      //  WorkAddress = newOfficeAddress;
      // ContactAddress = newContactAddress;
      HomeAddress = null;
    }

    /*if (!RetrieveData || RetrieveData == null) {
      return;
    }*/

    const RetrieveDataKTBSC = RetrieveData;
    const HeaderManp = await JSON.parse(localStorage.getItem("HeaderManp"));
    // const IDAddress = JSON.parse(localStorage.getItem('IDAddress'));
    // const HomeAddress = JSON.parse(localStorage.getItem('HomeAddress'));
    // const ContactAddress = JSON.parse(localStorage.getItem('ContactAddress'));
    // const WorkAddress = JSON.parse(localStorage.getItem('WorkAddress'));
    await localStorage.setItem("RetrieveData", JSON.stringify(RetrieveData));
    await localStorage.setItem(
      "RetrieveDataKTBSC",
      JSON.stringify(RetrieveDataKTBSC)
    );
    const [id_address, contact_address, office_address] = await Promise.all([
      this.function_IDAddress(RetrieveData, IDAddress),
      this.function_ContactAddress(RetrieveData, ContactAddress),
      this.function_WorkAddress(RetrieveData, WorkAddress),
    ]);

    const home_address = await this.function_HomeAddress(HomeAddress);

    await this.setState({
      id_address: id_address,
      contact_address: contact_address,
      office_address: office_address,
      home_address: home_address,
    });

    if (RetrieveDataKTBSC !== null) {
      await this.setState({ item: RetrieveDataKTBSC });
    } else {/*
      axios
        .post(
          `${config.baseAPIURL06}/ndid/RetrieveData`,
          JSON.stringify({ ReferenceId: ReferenceId }),
          {
            headers: { "content-type": "application/json" },
          }
        )
        .then(async (res) => {
          await this.setState({ item: res.data.data[0] });
          await localStorage.setItem(
            "RetrieveDataKTBSC",
            JSON.stringify(res.data.data[0])
          );
          await localStorage.setItem(
            "RetrieveData",
            JSON.stringify(res.data.data[0])
          );
        })
        .catch((error) => {
          var mss = "";
          var title_mss = "";
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            title_mss =
              error.response.data.Data !== undefined &&
              error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              error.response.data.Data !== undefined &&
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
          popupErrorApi(mss, title_mss, true);
        });*/
    }

    if (HeaderManp === null) {
      const f0 = await JSON.parse(localStorage.getItem("f0"));
      if (f0 !== null) {
        let birthdayYear = Number(f0.f0_date_birth.substring(4));
        let birthday =
          birthdayYear +
          f0.f0_date_birth.substring(2, 4) +
          f0.f0_date_birth.substring(0, 2);
        const ops2 = {
          method: "POST",
          headers: { "content-type": "application/json" },
          data: JSON.stringify({
            f0_title: f0.f0_title,
            f0_firstname: f0.f0_firstname,
            f0_lastname: f0.f0_lastname,
            f0_firstname_eng: f0.f0_firstname_eng,
            f0_lastname_eng: f0.f0_lastname_eng,
            f0_national_id: f0.f0_national_id,
            f0_lazer_card: f0.f0_lazer_card,
            f0_date_birth: f0.f0_date_birth,
            f0_expire_date: f0.f0_expired_date,
            f0_email: f0.f0_email,
            f0_mobile: f0.f0_mobile,
            f0_investment_consultant_id: "",
            f0_reference_id: ReferenceId,
          }),
          url: _url + `/form/header/manp`,
        };

        axios(ops2)
          .then(async (res) => {
            await localStorage.setItem(
              "HeaderManp",
              JSON.stringify(res.data.Data[0])
            );
          })
          .catch((error) => {
            var mss = "";
            var title_mss = "";
            if (!!error.isAxiosError && !error.response) {
              title_mss = "เกิดข้อผิดพลาด";
              mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            } else {
              title_mss =
                error.response.data.Data !== undefined &&
                  error.response.data.Data[0][0].MsgDescTitle !== ""
                  ? error.response.data.Data[0][0].MsgDescTitle
                  : "เกิดข้อผิดพลาด";
              mss =
                error.response.data.Data !== undefined &&
                  error.response.data.Data[0][0].MsgDesc !== ""
                  ? error.response.data.Data[0][0].MsgDesc
                  : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            }
            popupErrorApi(mss, title_mss, true);
          });
      }
    }

    this.setState({ loading: false });
  }

  async getRetrieveData(ReferenceId) {
    const ops = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
      },
      data: { ReferenceId: ReferenceId },
      url: `${config.baseAPIURL06}/ndid/RetrieveData`,
    };

    const [error, result] = await to(axios(ops));
    if (error) {
      var mss = "";
      var title_mss = "";
      if (!!error.isAxiosError && !error.response) {
        title_mss = "เกิดข้อผิดพลาด";
        mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
      } else {
        title_mss =
          error.response.data.Data !== undefined &&
            error.response.data.Data[0][0].MsgDescTitle !== ""
            ? error.response.data.Data[0][0].MsgDescTitle
            : "เกิดข้อผิดพลาด";
        mss =
          error.response.data.Data !== undefined &&
            error.response.data.Data[0][0].MsgDesc !== ""
            ? error.response.data.Data[0][0].MsgDesc
            : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
      }
      popupErrorApi(mss, title_mss, true);
      return {
        returnData: null,
        newIDCardAddress: null,
        newOfficeAddress: null,
        newContactAddress: null,
      };
    }

    const returnData = result.data.data[0];
    localStorage.setItem("RetrieveData", JSON.stringify(returnData));

    const {
      id_card_address_country,
      id_card_address_province,
      id_card_address_district,
      id_card_address_subdistrict,
      id_card_address_zipcode,
      id_card_street_address1,
      id_card_address_full,
      contact_address_country,
      contact_address_province,
      contact_address_district,
      contact_address_subdistrict,
      contact_address_zipcode,
      contact_street_address1,
      contact_address_full,
      office_address_country,
      office_address_province,
      office_address_district,
      office_address_subdistrict,
      office_address_zipcode,
      office_street_address1,
      office_full_address,
    } = returnData;

    const baseIDCardAddress = await this.setBaseAddressData({
      country: id_card_address_country,
      province: id_card_address_province,
      district: id_card_address_district,
      subDistrict: id_card_address_subdistrict,
      zipcode: id_card_address_zipcode,
      streetAddress: id_card_street_address1,
      addressFull: id_card_address_full,
    });
    const newIDCardAddress = {
      ...idAddress,
      country_name: baseIDCardAddress.countryName,
      f2_nric_country: baseIDCardAddress.countryID,
      province_name: baseIDCardAddress.provinceName,
      f2_nric_province: baseIDCardAddress.provinceID,
      district_name: baseIDCardAddress.districtName,
      f2_nric_district: baseIDCardAddress.districtID,
      sub_district_name: baseIDCardAddress.subDistrictName,
      f2_nric_subdistrict: baseIDCardAddress.subDistrictID,
      f2_nric_zipcode: baseIDCardAddress.zipcode,
      f2_nric_no: baseIDCardAddress.homeNo,
      f2_nric_address: baseIDCardAddress.addressFull,
    };

    const baseContactAddress = await this.setBaseAddressData({
      country: contact_address_country,
      province: contact_address_province,
      district: contact_address_district,
      subDistrict: contact_address_subdistrict,
      zipcode: contact_address_zipcode,
      streetAddress: contact_street_address1,
      addressFull: contact_address_full,
    });
    const newContactAddress = {
      ...contactAddress,
      country_name: baseContactAddress.countryName,
      f2_current_country: baseContactAddress.countryID,
      province_name: baseContactAddress.provinceName,
      f2_current_province: baseContactAddress.provinceID,
      district_name: baseContactAddress.districtName,
      f2_current_district: baseContactAddress.districtID,
      sub_district_name: baseContactAddress.subDistrictName,
      f2_current_subdistrict: baseContactAddress.subDistrictID,
      f2_current_zipcode: baseContactAddress.zipcode,
      f2_current_no: baseContactAddress.homeNo,
      f2_current_address: baseContactAddress.addressFull,
    };

    const baseOfficeAddress = await this.setBaseAddressData({
      country: office_address_country,
      province: office_address_province,
      district: office_address_district,
      subDistrict: office_address_subdistrict,
      zipcode: office_address_zipcode,
      streetAddress: office_street_address1,
      addressFull: office_full_address,
    });
    const newOfficeAddress = {
      ...workAddress,
      country_name: baseOfficeAddress.countryName,
      f2_workplace_country: baseOfficeAddress.countryID,
      province_name: baseOfficeAddress.provinceName,
      f2_workplace_province: baseOfficeAddress.provinceID,
      district_name: baseOfficeAddress.districtName,
      f2_workplace_district: baseOfficeAddress.districtID,
      sub_district_name: baseOfficeAddress.subDistrictName,
      f2_workplace_subdistrict: baseOfficeAddress.subDistrictID,
      f2_workplace_zipcode: baseOfficeAddress.zipcode,
      f2_workplace_no: baseOfficeAddress.homeNo,
      f2_workplace_address: baseOfficeAddress.addressFull,
    };

    // Set Base Address Data
    localStorage.setItem("IDAddress", JSON.stringify(newIDCardAddress));
    localStorage.setItem("WorkAddress", JSON.stringify(newOfficeAddress));
    localStorage.setItem("ContactAddress", JSON.stringify(newContactAddress));
    localStorage.setItem("HomeAddress", JSON.stringify([]));
    return {
      returnData,
      newIDCardAddress,
      newOfficeAddress,
      newContactAddress,
    };
  }

  async setBaseAddressData({
    country,
    province,
    district,
    subDistrict,
    zipcode,
    streetAddress,
    addressFull,
  }) {
    let result = addressFull;
    if (country === "") {
      return result;
    }

    const { countries, provinces, districts, subdistricts } =
      this.props.address;

    const findCountry = findCountryByCode(countries, country);
    if (country !== "TH") {
      result = {
        ...result,
        countryName: findCountry ? findCountry.ThName : "",
        countryID: findCountry ? findCountry.Id : "",
        addressFull: findCountry ? addressFull : "",
      };
    } else {
      const findProvince = findProvinceByName(provinces, province);
      let findDistrict;
      let findSubDistrict;

      if (findProvince) {
        findDistrict = findDistrictByName(districts, district, findProvince.Id);
      }

      if (findDistrict) {
        findSubDistrict = findSubDistrictByName(
          subdistricts,
          subDistrict,
          findDistrict.Id
        );
      }

      result = {
        ...result,
        countryName: findCountry ? findCountry.ThName : "",
        countryID: findCountry ? findCountry.Id : "",
        provinceName: findProvince ? findProvince.ThName : "",
        provinceID: findProvince ? findProvince.Id : "",
        districtName: findDistrict ? findDistrict.ThName : "",
        districtID: findDistrict ? findDistrict.Id : "",
        subDistrictName: findSubDistrict ? findSubDistrict.ThName : "",
        subDistrictID: findSubDistrict ? findSubDistrict.Id : "",
        homeNo: streetAddress,
        zipcode: String(zipcode).trim() !== "-" ? zipcode : "",
      };

      result.addressFull = setThaiAddressText({
        houseNo: result.homeNo,
        moo: "",
        building: "",
        floor: "",
        road: "",
        province: result.provinceName,
        district: result.districtName,
        subDistrict: result.subDistrictName,
        zipcode: String(zipcode).trim() !== "-" ? zipcode : "",
        country: result.countryName,
      });
    }
    return result;
  }

  async function_IDAddress(RetrieveData, IDAddress) {
    //console.log(IDAddress);
    // console.log(RetrieveData['id_card_address_province'].includes('กทม'));
    if (
      RetrieveData?.["id_card_address_province"] &&
      RetrieveData?.["id_card_address_province"].includes("กทม")
    ) {
      RetrieveData["id_card_address_province"] = "กรุงเทพมหานคร";
    }
    if (IDAddress != null) {
      if (Object.keys(IDAddress).length > 0) {
        // console.log(IDAddress);
        return IDAddress;
      } else {
        localStorage.setItem("IDAddress", JSON.stringify(IDAddress));
        return "";
      }
    } else {
      const countryData = findCountryByCode(
        this.countries,
        RetrieveData?.[`id_card_address_country`]
      );

      if (!countryData) {
        return idAddress;
      }

      let result;
      if (countryData.Id !== "212") {
        result = {
          ...idAddress,
          f2_nric_address: setNonThaiAddressText(
            RetrieveData.id_card_address_full,
            countryData.ThName
          ),
          f2_nric_country: countryData.Id,
          country_name: countryData.ThName,
        };
      } else {
        const provinceData = findProvinceByName(
          this.provinces,
          RetrieveData?.["id_card_address_province"]
        );
        let districtData;
        let subDistrictData;
        let postcode = "";
        if (provinceData) {
          districtData = findDistrictByName(
            this.districts,
            RetrieveData?.["id_card_address_district"],
            provinceData.Id
          );
        }

        if (districtData) {
          subDistrictData = findSubDistrictByName(
            this.subdistricts,
            RetrieveData?.["id_card_address_subdistrict"],
            districtData.Id
          );
        }

        if (provinceData && districtData && subDistrictData) {
          postcode = findPostcode(this.postcodes, {
            provinceID: provinceData.Id,
            districtID: districtData.Id,
            subdistrictID: subDistrictData.Id,
          });
        }

        result = {
          ...idAddress,
          country_name: countryData.ThName,
          province_name: provinceData ? provinceData.ThName : "",
          district_name: districtData ? districtData.ThName : "",
          sub_district_name: subDistrictData ? subDistrictData.ThName : "",
          f2_nric_country: countryData.Id,
          f2_nric_province: provinceData ? provinceData.Id : "",
          f2_nric_district: districtData ? districtData.Id : "",
          f2_nric_subdistrict: subDistrictData ? subDistrictData.Id : "",
          f2_nric_zipcode: postcode,
          f2_nric_no: RetrieveData.id_card_street_address1,
        };

        result.f2_nric_address = setThaiAddressText({
          houseNo: result.f2_nric_no,
          moo: result.f2_nric_moo,
          building: result.f2_nric_building,
          floor: result.f2_nric_floor,
          road: result.f2_nric_road,
          province: result.province_name,
          district: result.district_name,
          subDistrict: result.sub_district_name,
          zipcode: result.f2_nric_zipcode,
          country: result.country_name,
        });
      }
      localStorage.setItem("IDAddress", JSON.stringify(result));
      return result;
    }
  }

  async function_HomeAddress(HomeAddress) {
    if (HomeAddress != null) {
      if (Object.keys(HomeAddress).length > 0) {
        return HomeAddress;
      } else {
        return homeAddress;
      }
    } else {
      localStorage.setItem("HomeAddress", JSON.stringify(homeAddress));
      return homeAddress;
    }
  }

  async function_ContactAddress(RetrieveData, ContactAddress) {
    if (ContactAddress != null) {
      if (Object.keys(ContactAddress).length > 0) {
        return ContactAddress;
      } else {
        localStorage.setItem("ContactAddress", JSON.stringify(ContactAddress));
        return "";
      }
    } else {
      const countryData = findCountryByCode(
        this.countries,
        RetrieveData?.[`contact_address_country`]
      );

      if (!countryData) {
        return contactAddress;
      }

      let result;
      if (countryData.Id !== "212") {
        result = {
          ...contactAddress,
          f2_current_address: setNonThaiAddressText(
            RetrieveData?.contact_address_full,
            countryData.ThName
          ),
          f2_current_country: countryData.Id,
          country_name: countryData.ThName,
        };
      } else {
        const provinceData = findProvinceByName(
          this.provinces,
          RetrieveData?.["contact_address_province"]
        );
        let districtData;
        let subDistrictData;
        let postcode = "";
        if (provinceData) {
          districtData = findDistrictByName(
            this.districts,
            RetrieveData?.["contact_address_district"],
            provinceData.Id
          );
        }

        if (districtData) {
          subDistrictData = findSubDistrictByName(
            this.subdistricts,
            RetrieveData["contact_address_subdistrict"],
            districtData.Id
          );
        }

        if (provinceData && districtData && subDistrictData) {
          postcode = findPostcode(this.postcodes, {
            provinceID: provinceData.Id,
            districtID: districtData.Id,
            subdistrictID: subDistrictData.Id,
          });
        }

        result = {
          ...contactAddress,
          country_name: countryData.ThName,
          province_name: provinceData ? provinceData.ThName : "",
          district_name: districtData ? districtData.ThName : "",
          sub_district_name: subDistrictData ? subDistrictData.ThName : "",
          f2_current_country: countryData.Id,
          f2_current_province: provinceData ? provinceData.Id : "",
          f2_current_district: districtData ? districtData.Id : "",
          f2_current_subdistrict: subDistrictData ? subDistrictData.Id : "",
          f2_current_zipcode: postcode,
          f2_current_no: RetrieveData.contact_street_address1,
        };

        result.f2_current_address = setThaiAddressText({
          houseNo: result.f2_current_no,
          moo: result.f2_current_moo,
          building: result.f2_current_building,
          floor: result.f2_current_floor,
          road: result.f2_current_road,
          province: result.province_name,
          district: result.district_name,
          subDistrict: result.sub_district_name,
          zipcode: result.f2_current_zipcode,
          country: result.country_name,
        });
      }
      localStorage.setItem("ContactAddress", JSON.stringify(result));
      return result;
    }
  }

  async function_WorkAddress(RetrieveData, WorkAddress) {
    if (WorkAddress != null) {
      return WorkAddress;
    } else {
      const countryData = findCountryByCode(
        this.countries,
        RetrieveData?.[`office_address_country`]
      );

      if (!countryData) {
        return workAddress;
      }

      let result;
      if (countryData.Id !== "212") {
        result = {
          ...workAddress,
          f2_workplace_address: setNonThaiAddressText(
            RetrieveData.office_address_full,
            countryData.ThName
          ),
          f2_workplace_country: countryData.Id,
          country_name: countryData.ThName,
        };
      } else {
        const provinceData = findProvinceByName(
          this.provinces,
          RetrieveData?.["office_address_province"]
        );

        let districtData;
        let subDistrictData;
        let postcode = "";
        if (provinceData) {
          districtData = findDistrictByName(
            this.districts,
            RetrieveData?.["office_address_district"],
            provinceData.Id
          );
        }

        if (districtData) {
          subDistrictData = findSubDistrictByName(
            this.subdistricts,
            RetrieveData?.["office_address_subdistrict"],
            districtData.Id
          );
        }

        if (provinceData && districtData && subDistrictData) {
          postcode = findPostcode(this.postcodes, {
            provinceID: provinceData.Id,
            districtID: districtData.Id,
            subdistrictID: subDistrictData.Id,
          });
        }

        result = {
          ...workAddress,
          country_name: countryData.ThName,
          province_name: provinceData ? provinceData.ThName : "",
          district_name: districtData ? districtData.ThName : "",
          sub_district_name: subDistrictData ? subDistrictData.ThName : "",
          f2_workplace_country: countryData.Id,
          f2_workplace_province: provinceData ? provinceData.Id : "",
          f2_workplace_district: districtData ? districtData.Id : "",
          f2_workplace_subdistrict: subDistrictData ? subDistrictData.Id : "",
          f2_workplace_zipcode: postcode,
          f2_workplace_no: RetrieveData?.office_street_address1,
        };

        result.f2_workplace_address = setThaiAddressText({
          houseNo: result.f2_workplace_no,
          moo: result.f2_workplace_moo,
          building: result.f2_workplace_building,
          floor: result.f2_workplace_floor,
          road: result.f2_workplace_road,
          province: result.province_name,
          district: result.district_name,
          subDistrict: result.sub_district_name,
          zipcode: result.f2_workplace_zipcode,
          country: result.country_name,
        });
      }
      localStorage.setItem("WorkAddress", JSON.stringify(result));
      return result;
    }
  }

  choose2 = (e, selectedAddress) => {
    e.preventDefault();
    let re = /edit/;
    var str = window.location.href;
    const back_to_edit = re.test(str);
    // if (checkInternet()) {
    if (back_to_edit) {
      // this.props.history.push('/summaryshow');
      this.props.history.push(
        `/idc_address_choose2/edit?address=${selectedAddress}`
      );
      return;
    }
    this.props.history.push(
      `/idc_address_choose2?address=${selectedAddress}`
    );
    // } else {
    //   this.setState({
    //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
    //   });
    // }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await this.setState({ loading: true });
    // async handleSubmit() {
    var str = window.location.href;
    let re = /edit/;
    const back_to_edit = re.test(str);
    // if (checkInternet()) {
    let error;
    let result;
    let mss = "";
    let title_mss = "";
    const MySwal = withReactContent(swal);
    const HeaderManp = await JSON.parse(localStorage.getItem("HeaderManp"));
    const WorkAddress = await JSON.parse(localStorage.getItem("WorkAddress"));
    const HomeAddress = await JSON.parse(localStorage.getItem("HomeAddress"));
    const IDAddress = await JSON.parse(localStorage.getItem("IDAddress"));
    const ContactAddress = await JSON.parse(
      localStorage.getItem("ContactAddress")
    );
    const head_token = HeaderManp.head_token;
    const arr_jobs_data = await JSON.parse(
      localStorage.getItem("arr_jobs_data")
    );
    // const stamp_page = arr_jobs_data ? "JOBS" : "NDID";
    // return
    const addressChoose = {
      head_token,
      f2_workplace_country: WorkAddress ? WorkAddress.f2_workplace_country : "",
      f2_workplace_no: WorkAddress ? WorkAddress.f2_workplace_no : "",
      f2_workplace_province: WorkAddress
        ? WorkAddress.f2_workplace_province
        : "",
      f2_workplace_district: WorkAddress
        ? WorkAddress.f2_workplace_district
        : "",
      f2_workplace_subdistrict: WorkAddress
        ? WorkAddress.f2_workplace_subdistrict
        : "",
      f2_workplace_zipcode: WorkAddress ? WorkAddress.f2_workplace_zipcode : "",
      f2_workplace_office_phone: WorkAddress
        ? WorkAddress.f2_workplace_office_phone
          ? WorkAddress.f2_workplace_office_phone
          : ""
        : "",
      f2_workplace_address:
        WorkAddress && WorkAddress.f2_workplace_country !== "212"
          ? WorkAddress.f2_workplace_address
          : "",
      f2_workplace_building: WorkAddress
        ? WorkAddress.f2_workplace_building
        : "",
      f2_workplace_floor: WorkAddress ? WorkAddress.f2_workplace_floor : "",
      f2_workplace_moo: WorkAddress ? WorkAddress.f2_workplace_moo : "",
      f2_workplace_road: WorkAddress ? WorkAddress.f2_workplace_road : "",
      f2_home_country: HomeAddress ? HomeAddress.f2_home_country : "",
      f2_home_address:
        HomeAddress && HomeAddress.f2_home_country !== "212"
          ? HomeAddress.f2_home_address
          : "",
      f2_home_no: HomeAddress ? HomeAddress.f2_home_no : "",
      f2_home_moo: HomeAddress ? HomeAddress.f2_home_moo : "",
      f2_home_building: HomeAddress ? HomeAddress.f2_home_building : "",
      f2_home_floor: HomeAddress ? HomeAddress.f2_home_floor : "",
      // f2_home_alley: HomeAddress ? HomeAddress.f2_home_alley : "",
      f2_home_road: HomeAddress ? HomeAddress.f2_home_road : "",
      f2_home_province: HomeAddress ? HomeAddress.f2_home_province : "",
      f2_home_district: HomeAddress ? HomeAddress.f2_home_district : "",
      f2_home_subdistrict: HomeAddress ? HomeAddress.f2_home_subdistrict : "",
      f2_home_zipcode: HomeAddress ? HomeAddress.f2_home_zipcode : "",
      f2_current_country: ContactAddress
        ? ContactAddress.f2_current_country
        : "",
      f2_current_no: ContactAddress ? ContactAddress.f2_current_no : "",
      f2_current_moo: ContactAddress ? ContactAddress.f2_current_moo : "",
      f2_current_building: ContactAddress
        ? ContactAddress.f2_current_building
        : "",
      f2_current_floor: ContactAddress ? ContactAddress.f2_current_floor : "",
      // f2_current_alley: ContactAddress ? ContactAddress.f2_current_alley : '',
      f2_current_alley: "",
      f2_current_road: ContactAddress ? ContactAddress.f2_current_road : "",
      f2_current_province: ContactAddress
        ? ContactAddress.f2_current_province
        : "",
      f2_current_district: ContactAddress
        ? ContactAddress.f2_current_district
        : "",
      f2_current_subdistrict: ContactAddress
        ? ContactAddress.f2_current_subdistrict
        : "",
      f2_current_address:
        ContactAddress && ContactAddress.f2_current_country !== "212"
          ? ContactAddress.f2_current_address
          : "",
      f2_current_zipcode: ContactAddress
        ? ContactAddress.f2_current_zipcode
        : "",
      f2_nric_no: IDAddress ? IDAddress.f2_nric_no : "",
      f2_nric_moo: IDAddress ? IDAddress.f2_nric_moo : "",
      f2_nric_building: IDAddress ? IDAddress.f2_nric_building : "",
      f2_nric_floor: IDAddress ? IDAddress.f2_nric_floor : "",
      f2_nric_alley: IDAddress ? IDAddress.f2_nric_alley : "",
      f2_nric_road: IDAddress ? IDAddress.f2_nric_road : "",
      f2_nric_subdistrict: IDAddress ? IDAddress.f2_nric_subdistrict : "",
      f2_nric_district: IDAddress ? IDAddress.f2_nric_district : "",
      f2_nric_province: IDAddress ? IDAddress.f2_nric_province : "",
      f2_nric_country: IDAddress ? IDAddress.f2_nric_country : "",
      f2_nric_zipcode: IDAddress ? IDAddress.f2_nric_zipcode : "",
      f2_nric_address:
        IDAddress && IDAddress.f2_nric_country !== "212"
          ? IDAddress.f2_nric_address
          : "",
      f2_officename: IDAddress
        ? IDAddress.f2_officename
          ? IDAddress.f2_officename
          : ""
        : "",
      // f2_position: IDAddress ? IDAddress.f2_position : '',
      f2_position: arr_jobs_data
        ? arr_jobs_data.f1_career_position
          ? arr_jobs_data.f1_career_position
          : IDAddress.f2_position
            ? IDAddress.f2_position
            : ""
        : IDAddress.f2_position
          ? IDAddress.f2_position
          : "",
      f2_department: IDAddress
        ? IDAddress.f2_department
          ? IDAddress.f2_department
          : ""
        : "",
    };
    [error, result] = await to(
      ktbsApi.post("form/address/manp", addressChoose)
    );
    if (
      error ||
      !result ||
      !result.Data ||
      !result.Data[0] ||
      !result.Data[0][0] ||
      result.Data[0][0].IsSuccess !== 1
    ) {
      this.setState({ loading: false });
      if (result) {
        title_mss =
          result.Data[0][0].MsgDescTitle !== ""
            ? result.Data[0][0].MsgDescTitle
            : "เกิดข้อผิดพลาด";
        mss =
          result.Data[0][0].MsgDesc !== ""
            ? result.Data[0][0].MsgDesc
            : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
      } else if (error) {
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss =
            error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
      } else {
        mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        title_mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
      }
      MySwal.fire({
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        customClass: {
          confirmButton: "long_btn_fix",
        },
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title:
          '<div class="title_popup">' + mss
            ? "เกิดข้อผิดพลาด"
            : "ขออภัยข้อมูลของท่านไม่ถูกต้อง" + "</div>",
        html: '<div class="content_popup">' + mss + "</div>",
      });
      return;
    }
    if (back_to_edit || this.state.currentStep === "summaryshow") {
      // await localStorage.setItem('currentStep', 'jobs');
      await this.props.history.push("/summaryshow");
      await localStorage.setItem("editPage", "idc_address_choose");
      return;
    }
    await localStorage.setItem("currentStep", "jobs");
    var last_stamp = JSON.stringify(this.allStorage());
    const stamp_page = "JOBS";
    const ops = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
      },
      data: {
        last_stamp: last_stamp,
        head_token: head_token,
        stamp_page: stamp_page,
      },
      url: _url + "/form/comeback/manp",
    };
    axios(ops)
      .then(async (res) => {
        this.setState({ loading: false });
        var keys = Object.keys(localStorage),
          i = keys.length;
        while (i--) {
          // console.log(i, keys[i]);
        }
        await this.props.history.push("/jobs");
      })
      .catch((error) => {
        if (result) {
          title_mss =
            result.Data[0][0].MsgDescTitle !== ""
              ? result.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            result.Data[0][0].MsgDesc !== ""
              ? result.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else if (error) {
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            title_mss =
              error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
        } else {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }

        this.setState({ loading: false });
        popupErrorApi(mss, title_mss, true);
        return;
      });

    // } else {
    //   this.setState({
    //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
    //   });
    // }
  };

  allStorage() {
    var values = [],
      keys = Object.keys(localStorage),
      i = keys.length;
    while (i--) {
      if (
        keys[i].indexOf("oldData_") !== -1 ||
        keys[i].indexOf("flag_") !== -1 ||
        keys[i].indexOf("debug") !== -1 ||
        keys[i].indexOf("comebackWithOtp") !== -1
      ) {
        if (keys[i].indexOf("debug") !== -1) {
        } else {
          console.log("remove ", keys[i]);
          localStorage.removeItem(keys[i]);
        }
      } else {
        values.push({
          key: keys[i],
          value: localStorage.getItem(keys[i]),
        });
      }
    }
    return values;
  }

  editAddress = (e, addressType) => {
    e.preventDefault();
    const addresses = {
      idAddress: "editIdAddress",
      homeAddress: "editHomeAddress",
      contactAddress: "editContactAddress",
      officeAddress: "editOfficeAddress",
    };

    const isEditLink = window.location.href.includes("edit");
    let url = `/idc_address_form?address=${addresses[addressType]}`;
    if (isEditLink) {
      url += "&mode=edit";
    }

    this.props.history.push(url);
  };

  isValidAddress({
    country,
    province,
    district,
    subDistrict,
    zipcode,
    houseNo,
    address,
  }) {
    if (!country) {
      return false;
    }

    if (String(country) === "212") {
      if (
        province &&
        province.trim() !== "" &&
        district &&
        district.trim() !== "" &&
        subDistrict &&
        subDistrict.trim() !== "" &&
        zipcode &&
        zipcode.trim() !== "" &&
        zipcode.trim() !== "-" &&
        houseNo &&
        houseNo.trim() !== ""
      ) {
        return true;
      }
    } else {
      if (address && address.trim() !== "") {
        return true;
      }
    }

    return false;
  }

  render() {
    library.add(fas);
    const { id_address, contact_address, office_address } = this.state;
    let idAddressText = "";
    let isValidIDAddress = false;
    let contactAddressText = "";
    let isValidContactAddress = false;
    let officeAddressText = "";
    let isValidOfficeAddress = false;
    // let homeAddressText = "";

    if (id_address) {
      const {
        f2_nric_country,
        f2_nric_province,
        f2_nric_district,
        f2_nric_subdistrict,
        f2_nric_zipcode,
        f2_nric_no,
        f2_nric_address,
      } = id_address;

      isValidIDAddress = this.isValidAddress({
        country: f2_nric_country,
        province: f2_nric_province,
        district: f2_nric_district,
        subDistrict: f2_nric_subdistrict,
        zipcode: f2_nric_zipcode,
        houseNo: f2_nric_no,
        address: f2_nric_address,
      });

      idAddressText = f2_nric_address ? f2_nric_address : "";
    }

    if (contact_address) {
      const {
        f2_current_country,
        f2_current_province,
        f2_current_district,
        f2_current_subdistrict,
        f2_current_zipcode,
        f2_current_no,
        f2_current_address,
      } = contact_address;

      isValidContactAddress = this.isValidAddress({
        country: f2_current_country,
        province: f2_current_province,
        district: f2_current_district,
        subDistrict: f2_current_subdistrict,
        zipcode: f2_current_zipcode,
        houseNo: f2_current_no,
        address: f2_current_address,
      });

      contactAddressText = f2_current_address ? f2_current_address : "";
    }

    if (office_address) {
      const {
        f2_workplace_country,
        f2_workplace_province,
        f2_workplace_district,
        f2_workplace_subdistrict,
        f2_workplace_zipcode,
        f2_workplace_no,
        f2_workplace_address,
      } = office_address;

      isValidOfficeAddress = this.isValidAddress({
        country: f2_workplace_country,
        province: f2_workplace_province,
        district: f2_workplace_district,
        subDistrict: f2_workplace_subdistrict,
        zipcode: f2_workplace_zipcode,
        houseNo: f2_workplace_no,
        address: f2_workplace_address,
      });

      officeAddressText = f2_workplace_address ? f2_workplace_address : "";
    }

    const isEnableNextButton =
      isValidIDAddress && isValidContactAddress && isValidOfficeAddress;

    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <Loading status={this.state.loading} />
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          style={{
                            display:
                              checkEditPage() ||
                                this.state.currentStep === "summaryshow"
                                ? "block"
                                : "block",
                            marginLeft: "3px",
                          }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">รายละเอียดที่อยู่ทั้งหมด</div>
                    </div>

                    <div
                      className="bg_content_white over_f_hide"
                      style={{ padding: "0" }}
                    >
                      <div
                        className="in_bg_white autoscroll"
                        style={{
                          top: 0,
                          left: 0,
                          right: 0,
                        }}
                      >
                        <div className="title_color_blue">
                          ที่อยู่ตามบัตรประจำตัวประชาชน *
                        </div>

                        <div
                          className={
                            idAddressText === ""
                              ? "content_address"
                              : "content_address add"
                          }
                          onClick={(e) => this.editAddress(e, "idAddress")}
                        >
                          <FontAwesomeIcon
                            style={{
                              display: this.state.loading ? "none" : "block",
                            }}
                            icon={
                              !isValidIDAddress ? "exclamation-circle" : "edit"
                            }
                          ></FontAwesomeIcon>
                          <div className="text_top_address">
                            ใช้ที่อยู่ตามบัตรประชาชน
                          </div>
                          <div className="text_bottom_address">
                            {idAddressText}
                          </div>
                        </div>
                        <div
                          className="btn_address"
                          onClick={(e) => this.choose2(e, "id_address")}
                        >
                          <FontAwesomeIcon icon="clone"></FontAwesomeIcon>{" "}
                          เรียกใช้ข้อมูลที่อยู่
                        </div>

                        {/* <div className="title_color_blue">ที่อยู่ตามทะเบียนบ้าน*</div>
              {home_address === '' ? (
                <div
                  className={
                    homeAddressText === ''
                      ? 'content_address'
                      : 'content_address add'
                  }
                  onClick={this.choose2}
                >
                  <FontAwesomeIcon icon="angle-right"></FontAwesomeIcon>
                  <div className="text_top_address">เรียกใช้ที่อยู่</div>
                </div>
              ) : (
                <div>
                  <div
                    className={
                      homeAddressText === ''
                        ? 'content_address'
                        : 'content_address add'
                    }
                    onClick={(e) => this.editAddress(e, 'homeAddress')}
                  >
                    <FontAwesomeIcon
                      icon={
                        homeAddressText === ''
                          ? 'angle-right'
                          : 'edit'
                      }
                    ></FontAwesomeIcon>
                    <div className="text_top_address">
                      ใช้ที่อยู่ตามทะเบียนบ้าน
                    </div>
                    <div className="text_bottom_address">{homeAddressText}</div>
                  </div>
                  <div
                    className="btn_address"
                    style={{
                      display:
                        homeAddressText === ''
                          ? 'none'
                          : 'block',
                    }}
                    onClick={this.choose2}
                  >
                    <FontAwesomeIcon icon="clone"></FontAwesomeIcon>{' '}
                    เรียกใช้ข้อมูลที่อยู่
                  </div>
                </div>
              )} */}
                        <div className="title_color_blue">
                          ที่อยู่ปัจจุบัน/ที่อยู่สำหรับจัดส่งเอกสาร *
                        </div>
                        <div
                          className={
                            contactAddressText === ""
                              ? "content_address"
                              : "content_address add"
                          }
                          onClick={(e) => this.editAddress(e, "contactAddress")}
                        >
                          <FontAwesomeIcon
                            style={{
                              display: this.state.loading ? "none" : "block",
                            }}
                            icon={
                              !isValidContactAddress
                                ? "exclamation-circle"
                                : "edit"
                            }
                          ></FontAwesomeIcon>
                          <div className="text_top_address">
                            ใช้ที่อยู่ปัจจุบัน
                          </div>
                          <div className="text_bottom_address">
                            {contactAddressText}
                          </div>
                        </div>
                        <div
                          className="btn_address"
                          onClick={(e) => this.choose2(e, "contact_address")}
                        >
                          <FontAwesomeIcon icon="clone"></FontAwesomeIcon>{" "}
                          เรียกใช้ข้อมูลที่อยู่
                        </div>
                        <div className="title_color_blue">
                          ที่อยู่ที่ทำงาน/ที่อยู่ที่ติดต่อได้ *
                        </div>

                        <div
                          className={
                            officeAddressText === ""
                              ? "content_address"
                              : "content_address add "
                          }
                          onClick={(e) => this.editAddress(e, "officeAddress")}
                        >
                          <FontAwesomeIcon
                            icon={
                              !isValidOfficeAddress
                                ? "exclamation-circle"
                                : "edit"
                            }
                          ></FontAwesomeIcon>
                          <div className="text_top_address">
                            ใช้ที่อยู่ที่ทำงาน
                          </div>
                          <div className="text_bottom_address">
                            {officeAddressText}
                          </div>
                        </div>
                        <div
                          className="btn_address"
                          style={{
                            marginBottom: 6,
                          }}
                          onClick={(e) => this.choose2(e, "office_address")}
                        >
                          <FontAwesomeIcon icon="clone"></FontAwesomeIcon>{" "}
                          เรียกใช้ข้อมูลที่อยู่
                        </div>
                      </div>

                      <div className="under_fix_btn">
                        <ButtonCustom
                          handle={
                            !isEnableNextButton ? null : this.handleSubmit
                          }
                          label={
                            checkEditPage() ||
                              this.state.currentStep === "summaryshow"
                              ? "บันทึกการแก้ไข"
                              : "ถัดไป"
                          }
                          color={!isEnableNextButton ? "gray" : "blue"}
                          btnClass="long_btn_fix"
                        ></ButtonCustom>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default KtbstIDCAddressChoose;
