/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import "./KtbstSignedAttatched.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import SignaturePad from "react-signature-canvas";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import $ from "jquery";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popup from "../../components/popup_photo/PopupPhoto";
import Loading from "../../components/loading/loading";
// import { fireEvent } from '@testing-library/react';
import Swal from "sweetalert2";
import Webcam from "react-webcam";
import to from "await-to-js";
import kbstApi from "./../../api";
import { createCanvas, loadImage } from "canvas";
import {
  checkEditPage,
  checkInternet,
  popupNetworkLost,
  download_ico_alert,
} from "../../components/helpers/Helpers";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";

const videoConstraints = {
  // width: 400,
  // height: 700,
  facingMode: "environment",
};

class KtbstSigneEx extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trimmedDataURL: null,
      status_popup: false,
      loading: false,
      showCameraPopup: false,
      isDisconnected: false,
      isMobilePortrait: false,
    };

    this.handleSubmitNext = this.handleSubmitNext.bind(this);
    this.goBack = this.goBack.bind(this);
    this.fileuploadRef = React.createRef();
    this.webcamRef = React.createRef();
  }

  sigPad = {};

  clear = () => {
    this.sigPad.clear();
  };

  trim = async () => {
    const imageSrc = await this.sigPad.toDataURL("image/png");
    const imageSrc2 = await this.sigPad
      .getTrimmedCanvas()
      .toDataURL("image/png");
    await loadImage(imageSrc2).then(async (image) => {
      if (image.width * image.height === 1) {
        // console.log(image.width, image.height);
      } else {
        await this.setState({ trimmedDataURL: imageSrc });
        await localStorage.setItem("trimmedDataURL", imageSrc);
      }
    });
  };

  goBack() {
    if (checkEditPage()) {
      return;
    }
    this.props.history.push("/PoliticianRelation");
  }
  status_hide = (e) => {
    if (e.target.className === "background_opacity") {
      this.setState({ status_popup: false });
    }
  };

  async componentDidMount() {
    // this.setState({ loading: true });
    this.setState({ loading: false });
  }

  handleSubmitNext = async (e) => {
    e.preventDefault();
    this.props.history.push("/signed_attatched");
  };

  render() {
    library.add(fas);
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          style={{
                            display: checkEditPage() ? "none" : "block",
                            marginLeft: "3px",
                          }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">หลักฐานลายเซ็น</div>
                    </div>

                    <div className="bg_content_white" style={{ padding: 0 }}>
                      <div
                        className="in_bg_white photo"
                        style={{ top: 0, left: 0, right: 0 }}
                      >
                        <div
                          className="DivDetailTitleSnapId"
                          style={{ textAlign: "center", marginBottom: "20px" }}
                        >
                          {/* <span style={{ textDecoration: "Underline" }}>
                            หลักฐานลายเซ็น
                          </span>{" "}
                          * */}
                          <div className={"singned_title_1"}>
                            กรุณาลงลายมือชื่อ 2 ครั้ง <br />
                            บนกระดาษเปล่าสีขาว เพื่อเก็บภาพถ่าย
                          </div>
                          <div className={"singned_title_2"}>
                            (กรณีใช้งานเว็บบราวเซอร์บน PC ให้ทำการอัปโหลด
                            ไฟล์ภาพ JPG, PNG)
                          </div>
                          <div>ตัวอย่าง</div>
                        </div>

                        <div
                          className="btn_add_photo"
                          id="SignaturePhoto"
                          style={{ margin: "0 21px" }}
                        >
                          <img
                            style={{
                              width: "100%",
                              borderRadius: "15px",
                              display: "block",
                            }}
                            src="/images/signEx3.jpg"
                          />
                        </div>
                        <div style={{ height: "10px" }}>&nbsp;</div>
                      </div>
                      <div className="under_fix_btn">
                        <ButtonCustom
                          handle={this.handleSubmitNext}
                          label={"ถัดไป"}
                          btnClass={`long_btn_fix blue`}
                        ></ButtonCustom>
                      </div>
                    </div>
                  </div>

                  <Loading status={this.state.loading}></Loading>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default KtbstSigneEx;
