import React, { Component } from "react";
import "./KtbstRisk.css";
import { set } from "date-fns";
import moment from "moment";
import axios from "axios";
import Loading from "../../components/loading/loading";
import { config } from "./../../constants";
import { popupErrorApi } from "../../components/helpers/Helpers";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
const _url = config.baseAPIURL03;

class KtbstRisk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: [],
      loading: false,
    };
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.push("/value_of_invester");
  }
  async componentDidMount() {
    this.setState({ loading: true });
    var arr = JSON.parse(localStorage.getItem("f0"));
    var age_user = arr.f0_date_birth;
    var today = moment();
    var birthday = moment(age_user, "YYYYMMDD").format("DD-MM-YYYY");
    birthday = moment(birthday, "DD-MM-YYYY");
    var age = moment.duration(today.diff(birthday)).years();
    if ((await age) > 59) {
      this.setState({ score_age: 1 });
    } else if (age <= 59 && age >= 45) {
      this.setState({ score_age: 2 });
    } else if (age <= 44 && age >= 35) {
      this.setState({ score_age: 3 });
    } else if (age < 35) {
      this.setState({ score_age: 4 });
    }

    const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
    const head_token = HeaderManp.head_token;
    // var head_token = "IQcw6lmY5M+q9dnt9GzOjMBv9ejg5rP0xD3dm911Y/U=";
    axios
      .get(_url + `/form/suitability/selbyhead?head_token=${head_token}`)
      .then((res) => {
        this.setState({ chooce: res.data.Data[1] });
        this.setState({ loading: false });
        // console.log(this.state.chooce);
      })
      .catch((error) => {
        this.setState({ loading: false });
        var mss = "";
        var title_mss = "";
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss =
            error.response.data.Data !== undefined &&
            error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data !== undefined &&
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
        this.setState({ loading: false });
        popupErrorApi(mss, title_mss, true);
      });
  }
  handleSubmit1 = async (e) => {
    var question = [];
    await this.state.chooce.forEach((ele, i) => {
      if (i <= 11) {
        if (i === 0) {
          question.push({
            SAmsa_Id: ele.SAmsa_Id,
            SAfsac_Answer: this.state.score_age,
          });
        }
        if (i === 1) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 4 });
        }
        if (i === 2) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 4 });
        }
        if (i === 3) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: "4_" });
        }
        if (i === 4) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 4 });
        }
        if (i === 5) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 4 });
        }
        if (i === 6) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 4 });
        }
        if (i === 7) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 4 });
        }
        if (i === 8) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 4 });
        }
        if (i === 9) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 4 });
        }
        // if (i === 10) {
        //   question.push({SAmsa_Id:ele.SAmsa_Id,SAfsac_Answer:3})
        // }
        // if (i === 11) {
        //   question.push({SAmsa_Id:ele.SAmsa_Id,SAfsac_Answer:3})
        // }
      } else {
        // question.push({SAmsa_Id:ele.SAmsa_Id,SAfsac_Answer:2})
      }
    });

    localStorage.setItem("question", JSON.stringify(question));
    this.props.history.push("/risk_question");
    // console.log(JSON.parse(localStorage.getItem('question')));
  };
  handleSubmit2 = async (e) => {
    var question = [];
    await this.state.chooce.forEach((ele, i) => {
      if (i <= 11) {
        if (i === 0) {
          question.push({
            SAmsa_Id: ele.SAmsa_Id,
            SAfsac_Answer: this.state.score_age,
          });
        }
        if (i === 1) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 3 });
        }
        if (i === 2) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 4 });
        }
        if (i === 3) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: "3_" });
        }
        if (i === 4) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 4 });
        }
        if (i === 5) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 4 });
        }
        if (i === 6) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 3 });
        }
        if (i === 7) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 4 });
        }
        if (i === 8) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 4 });
        }
        if (i === 9) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 3 });
        } else {
          // question.push({SAmsa_Id:ele.SAmsa_Id,SAfsac_Answer:2})
        }
      }
    });

    localStorage.setItem("question", JSON.stringify(question));
    this.props.history.push("/risk_question");
  };
  handleSubmit3 = async (e) => {
    var question = [];
    await this.state.chooce.forEach((ele, i) => {
      if (i <= 11) {
        if (i === 0) {
          question.push({
            SAmsa_Id: ele.SAmsa_Id,
            SAfsac_Answer: this.state.score_age,
          });
        }
        if (i === 1) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 3 });
        }
        if (i === 2) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 4 });
        }
        if (i === 3) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: "3_" });
        }
        if (i === 4) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 3 });
        }
        if (i === 5) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 3 });
        }
        if (i === 6) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 2 });
        }
        if (i === 7) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 3 });
        }
        if (i === 8) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 2 });
        }
        if (i === 9) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 2 });
        }
      } else {
        // question.push({SAmsa_Id:ele.SAmsa_Id,SAfsac_Answer:2})
      }
    });
    localStorage.setItem("question", JSON.stringify(question));
    this.props.history.push("/risk_question");
  };
  handleSubmit4 = async (e) => {
    var question = [];
    await this.state.chooce.forEach((ele, i) => {
      if (i <= 11) {
        if (i === 0) {
          question.push({
            SAmsa_Id: ele.SAmsa_Id,
            SAfsac_Answer: this.state.score_age,
          });
        }
        if (i === 1) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 2 });
        }
        if (i === 2) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 2 });
        }
        if (i === 3) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: "2_" });
        }
        if (i === 4) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 2 });
        }
        if (i === 5) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 2 });
        }
        if (i === 6) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 1 });
        }
        if (i === 7) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 2 });
        }
        if (i === 8) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 2 });
        }
        if (i === 9) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 2 });
        } else {
          // question.push({SAmsa_Id:ele.SAmsa_Id,SAfsac_Answer:2})
        }
      }
    });
    localStorage.setItem("question", JSON.stringify(question));
    this.props.history.push("/risk_question");
  };
  handleSubmit5 = async (e) => {
    var question = [];
    await this.state.chooce.forEach((ele, i) => {
      if (i <= 11) {
        if (i === 0) {
          question.push({
            SAmsa_Id: ele.SAmsa_Id,
            SAfsac_Answer: this.state.score_age,
          });
        }
        if (i === 1) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 1 });
        }
        if (i === 2) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 1 });
        }
        if (i === 3) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: "1_" });
        }
        if (i === 4) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 1 });
        }
        if (i === 5) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 2 });
        }
        if (i === 6) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 1 });
        }
        if (i === 7) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 1 });
        }
        if (i === 8) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 1 });
        }
        if (i === 9) {
          question.push({ SAmsa_Id: ele.SAmsa_Id, SAfsac_Answer: 1 });
        } else {
          // question.push({SAmsa_Id:ele.SAmsa_Id,SAfsac_Answer:2})
        }
      }
    });
    localStorage.setItem("question", JSON.stringify(question));
    this.props.history.push("/risk_question");
  };

  render() {
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          alt="forward"
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">
                        แบบประเมินความเหมาะสมในการลงทุน
                      </div>
                    </div>

                    <div className="bg_content_white">
                      <div
                        style={{
                          color: "#003b66",
                          fontSize: 24,
                          marginBottom: 0,
                          fontWeight: "bold",
                        }}
                      >
                        แบบประเมินความเหมาะสมในการลงทุน
                      </div>
                      <div style={{ color: "#58595b", marginBottom: 24 }}>
                        เลือกความเสี่ยงของคุณ
                      </div>

                      <div
                        className="content_address active icon_risk1"
                        id="risk_1"
                        onClick={this.handleSubmit1}
                      >
                        <div
                          className="text_top_address"
                          style={{ cursor: "pointer" }}
                        >
                          เสี่ยงสูงมาก / เสี่ยงสูงมากอย่างมีนัยสำคัญ
                        </div>
                      </div>

                      <div
                        className="content_address active icon_risk2"
                        id="risk2"
                        onClick={this.handleSubmit2}
                      >
                        <div
                          className="text_top_address"
                          style={{ cursor: "pointer" }}
                        >
                          เสี่ยงสูง
                        </div>
                      </div>

                      <div
                        className="content_address active icon_risk3"
                        id="risk3"
                        onClick={this.handleSubmit3}
                      >
                        <div
                          className="text_top_address"
                          style={{ cursor: "pointer" }}
                        >
                          เสี่ยงปานกลางค่อนข้างสูง
                        </div>
                      </div>

                      <div
                        className="content_address active icon_risk4"
                        id="risk4"
                        onClick={this.handleSubmit4}
                      >
                        <div
                          className="text_top_address"
                          style={{ cursor: "pointer" }}
                        >
                          เสี่ยงปานกลางค่อนข้างต่ำ
                        </div>
                      </div>

                      <div
                        className="content_address active icon_risk5"
                        id="risk5"
                        onClick={this.handleSubmit5}
                      >
                        <div
                          className="text_top_address"
                          style={{ cursor: "pointer" }}
                        >
                          เสี่ยงต่ำ
                        </div>
                      </div>
                    </div>
                  </div>
                  <Loading status={this.state.loading} />
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default KtbstRisk;
