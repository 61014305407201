import React, { Component } from "react";
import "./crs_declare_location_form.css";
import Select2 from "react-select";
import { isMobile } from "react-device-detect";
import { IMaskInput } from "react-imask";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
import Loading from "../../components/loading/loading";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { popupCrs } from "../../components/helpers/Helpers"

import {
  FormControlLabel,
  Typography,
  Radio,
  RadioGroup,
} from "@mui/material"

class CrsDeclareLocationForm extends Component {
  constructor(props) {
    super(props)
    const { is_edit, select_country, country_list } = props.location.state
    let is_from_summary = props.location?.state?.is_from_summary || false
    let prev_crs_declare_location = props.location?.state?.prev_crs_declare_location

    this.state = {
      is_edit: is_edit,
      prop_select_country: select_country,
      prop_country_list: country_list,
      prop_is_from_summary: is_from_summary,
      prev_data: {},
      loading: false,
      user_nid: null,
      country_list: [],
      select_country: null,
      input_tax_no: null,
      input_tax_no_disable: false,
      input_no_tax_checkbox: false,
      input_no_tax_reason_radio: null,
      input_reason_choice_2: null,
      is_select_thai_country: false,
      prev_crs_declare_location: prev_crs_declare_location,
    }
  }

  componentDidMount = async () => {
    this.loadDataFromLocalStorage()
  }

  loadDataFromLocalStorage = async () => {
    let user_info = localStorage.getItem('checkuserinfo')
    if (user_info) {
      user_info = JSON.parse(user_info)
      await this.setState({user_nid: user_info.cardId})
    }

    let declare_location_data = localStorage.getItem('crs_declare_location')
    if (declare_location_data) {
      declare_location_data = JSON.parse(declare_location_data)
      let already_selected_country = declare_location_data.tax_country_list.map(x => x.country.country)

      let exclude_list = ["สหรัฐอเมริกา"]
      if (this.state.is_edit) {
        already_selected_country = already_selected_country.filter(x => x != this.state.prop_select_country.country)
        exclude_list = exclude_list.concat(already_selected_country)
        await this.setState({select_country: {value: this.state.prop_select_country.countryId, label: this.state.prop_select_country.country}})

        if (this.state.select_country.label == 'ไทย') {
          await this.setState({
            input_tax_no: this.state.user_nid,
            input_tax_no_disable: true,
            is_select_thai_country: true,
          })
        }

        let current_tax_country = declare_location_data.tax_country_list.find(x => x.country.country == this.state.prop_select_country.country)
        if (current_tax_country) {
          await this.setState({prev_data: current_tax_country})

          if (!current_tax_country.tax_no) {
            await this.setState({
              input_no_tax_checkbox: true,
              input_tax_no_disable: true,
              input_no_tax_reason_radio: current_tax_country.no_tax_choice.toString(),
              input_reason_choice_2: current_tax_country.reason_choice_2
            })
          } else {
            await this.setState({
              input_tax_no: current_tax_country.tax_no
            })
          }
        }
      } else {
        exclude_list = exclude_list.concat(already_selected_country)
      }
      
      let selectable_country = this.state.prop_country_list.filter(x => exclude_list.indexOf(x.ThName) == -1)
      selectable_country = selectable_country.map(x => {
        return {
          value: x.Id,
          label: x.ThName
        }
      })

      await this.setState({country_list: selectable_country})
    }
  }

  goBack = () => {
    if (this.is_edit_valid()){
      popupCrs(
        "ยกเลิกการแก้ไข",
        "การแก้ไขยังไม่ได้ถูกบันทึก\nท่านต้องการออกจากหน้านี้หรือไม่",
        "ออกจากหน้านี้",
        "อยู่หน้านี้ต่อ",
        () => { 
          this.props.history.push("crs_declare_location", {
            is_from_summary: this.state.prop_is_from_summary, 
            prev_crs_declare_location: this.state.prev_crs_declare_location,
          })
        }
      )
    }
    else{
      this.props.history.push("crs_declare_location", {
        is_from_summary: this.state.prop_is_from_summary, 
        prev_crs_declare_location: this.state.prev_crs_declare_location,
      })
    }
  }

  handleChange = async (selectedOption, e) => {
    await this.setState({ [e.name]: selectedOption })
    
    if (e.name == 'select_country') {
      if (selectedOption.label == 'ไทย') {
        await this.setState({
          input_tax_no: this.state.user_nid,
          input_tax_no_disable: true,
          is_select_thai_country: true,
        })
      } else {
        await this.setState({
          input_tax_no: null,
          input_tax_no_disable: false,
          is_select_thai_country: false,
        })
      }
    }
  }

  handleChangeTaxNo = async (value) => {
    await this.setState({input_tax_no: value})
  }

  handleChangeCheckbox = async (e) => {
    await this.setState({input_no_tax_checkbox: e.target.checked})

    if (e.target.checked) {
      await this.setState({
        input_tax_no: null,
        input_tax_no_disable: true,
        input_no_tax_reason_radio: null,
        input_reason_choice_2: null,
      })
    } else {
      await this.setState({
        input_tax_no: null,
        input_tax_no_disable: false,
        input_no_tax_reason_radio: null,
        input_reason_choice_2: null,
      })
    }
  }

  handleRadioChange = async (e) => {
    let choice = parseInt(e.target.value)
    await this.setState({input_no_tax_reason_radio: choice})

    if ([1,3].indexOf(choice) > -1) {
      await this.setState({input_reason_choice_2: null})
    }
  }

  handleChangeReasonChoice2 = async (value) => {
    await this.setState({input_reason_choice_2: value})
  }

  handleAddSubmit = async () => {
    let data = {
      country: {countryId: this.state.select_country.value, country: this.state.select_country.label},
      is_thai: this.state.is_select_thai_country,
      tax_no: this.state.input_tax_no ? this.state.input_tax_no : null,
      no_tax_choice: this.state.input_no_tax_reason_radio,
      reason_choice_2: this.state.input_reason_choice_2
    }

    let declare_location_data = localStorage.getItem('crs_declare_location')
    if (declare_location_data) {
      declare_location_data = JSON.parse(declare_location_data)
      declare_location_data.tax_country_list.push(data)
    } else {
      declare_location_data = {
        is_non_tax_from_usa: true,
        tax_country_list: [data]
      }
    }

    localStorage.setItem('crs_declare_location', JSON.stringify(declare_location_data))
    this.props.history.push("crs_declare_location", {
      form_action: 'add', 
      is_from_summary: this.state.prop_is_from_summary,
      prev_crs_declare_location: this.state.prev_crs_declare_location,
    })
  }

  handleEditSubmit = async () => {
    let declare_location_data = localStorage.getItem('crs_declare_location')
    declare_location_data = JSON.parse(declare_location_data)

    let index = declare_location_data.tax_country_list.findIndex(x => x.country.country == this.state.prop_select_country.country)
    let data = declare_location_data.tax_country_list[index]
    data.country = {countryId: this.state.select_country.value, country: this.state.select_country.label}
    data.is_thai = this.state.is_select_thai_country
    data.tax_no = this.state.input_tax_no ? this.state.input_tax_no : null
    data.no_tax_choice = this.state.input_no_tax_reason_radio
    data.reason_choice_2 = this.state.input_reason_choice_2

    localStorage.setItem('crs_declare_location', JSON.stringify(declare_location_data))
    this.props.history.push("crs_declare_location", {
      form_action: 'edit', 
      is_from_summary: this.state.prop_is_from_summary,
      prev_crs_declare_location: this.state.prev_crs_declare_location,
    })
  }

  handleEditDelete = async () => {
    popupCrs(
      "ลบข้อมูล",
      "ท่านยืนยันการลบข้อมูลส่วนนี้หรือไม่",
      "ยืนยัน",
      "อยู่หน้านี้ต่อ",
      () => { 
        let declare_location_data = localStorage.getItem('crs_declare_location')
        declare_location_data = JSON.parse(declare_location_data)

        let index = declare_location_data.tax_country_list.findIndex(x => x.country.country == this.state.prop_select_country.country)
        declare_location_data.tax_country_list.splice(index, 1)
        localStorage.setItem('crs_declare_location', JSON.stringify(declare_location_data))
        this.props.history.push("crs_declare_location", {
          form_action: 'delete', 
          is_from_summary: this.state.prop_is_from_summary,
          prev_crs_declare_location: this.state.prev_crs_declare_location,
        })
      }
    )
  }

  is_edit_valid = () => {
    return ( 
      (this.state.select_country?.label != this.state.prev_data.country?.country) || 
      (this.state.input_tax_no != this.state.prev_data.tax_no) || 
      (this.state.input_no_tax_reason_radio != this.state.prev_data.no_tax_choice) || 
      (this.state.input_reason_choice_2 != this.state.prev_data.reason_choice_2)
    )
  }

  is_form_valid = () => {
    return this.state.select_country && (
      this.state.input_tax_no ||
      (
        !(this.state.input_tax_no) &&
        this.state.input_no_tax_reason_radio &&
        this.state.input_no_tax_reason_radio == 2 &&
        this.state.input_reason_choice_2
      ) ||
      (
        !(this.state.input_tax_no) &&
        this.state.input_no_tax_reason_radio &&
        this.state.input_no_tax_reason_radio != 2
      )
    )
  }

  render() {
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          alt="forward"
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">ประเทศถิ่นที่อยู่ทางภาษี</div>
                    </div>
                    <div className="bg_content_white" style={{ paddingTop: 0 }}>
                      <div
                        className="in_bg_white"
                        style={this.state.is_edit ? { top: 0, bottom: 160, right: 0, paddingRight: '21px', overflow: 'auto' } : { top: 0, bottom: 100, right: 0, paddingRight: '21px', overflow: 'auto' }}
                      >
                        <div className="row_idcard crs_declare_location_form_row_idcard">
                          <div>ประเทศถิ่นที่อยู่ทางภาษี</div>
                          <Select2
                            name="select_country"
                            options={this.state.country_list}
                            value={this.state.select_country}
                            onChange={this.handleChange}
                            placeholder="ค้นหา"
                            className="select_two crs_declare_location_form_select_two"
                          // isClearable={true}
                          />
                          <span className="span_err_country">
                            กรุณาระบุประเทศ
                          </span>
                        </div>
                        <div className="row_idcard crs_declare_location_form_row_idcard">
                          <div>หมายเลขประจำตัวผู้เสียภาษี</div>
                          <IMaskInput
                            mask={/^[A-Za-z0-9./\s\-]+$/}
                            value={this.state.input_tax_no}
                            unmask={true}
                            placeholder="หมายเลขประจำตัวผู้เสียภาษี"
                            className={this.state.input_tax_no_disable ? "crs_declare_location_form_input_disable" : "input_idcard"}
                            name="input_tax_no"
                            onAccept={this.handleChangeTaxNo}
                            maxLength={20}
                            readOnly={this.state.input_tax_no_disable}
                          />
                          <span className="span_err_tax_no">
                            กรุณาระบุหมายเลขประจำตัวผู้เสียภาษี
                          </span>
                        </div>
                        {!this.state.is_select_thai_country && (
                          <div className="row_idcard">
                            <label className="container_border crs_declare_location_form_checkbox">
                              ไม่มีหมายเลขประจำตัวผู้เสียภาษี
                              <input
                                type="checkbox"
                                checked={this.state.input_no_tax_checkbox}
                                onChange={this.handleChangeCheckbox}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        )}
                        {!this.state.is_select_thai_country && this.state.input_no_tax_checkbox && (
                          <div>
                            <div className="crs_declare_location_form_text">โปรดระบุเหตุผล</div>
                            <RadioGroup
                              aria-labelledby="radio-buttons-group-label"
                              name="radio-buttons-group"
                              sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "15px", alignItems: "center"}}
                              value={this.state.input_no_tax_reason_radio}
                              onChange={this.handleRadioChange}
                            >
                              <FormControlLabel 
                                label={
                                  <div style={{marginTop: '12px', marginBottom: '12px', paddingRight: '16px'}}>
                                    <Typography sx={{fontFamily: 'DB Heavent', fontSize: '24px', color: '#013b66'}}>ประเทศที่ระบุข้างต้น ไม่ได้มีการออกเลขประจำตัวผู้เสียภาษี</Typography>
                                  </div>
                                }
                                value={'1'} 
                                control={
                                  <Radio 
                                    sx={{marginTop: '8px'}}
                                    icon={<RadioButtonUncheckedIcon sx={this.state.input_no_tax_reason_radio == '1' ? {color: "#013b66"} : {color: "#A7A9AC"}} />}
                                    checkedIcon={<CheckCircleIcon sx={{color: "#013b66"}} />}
                                  />
                                }
                                sx={{border: 1, borderRadius: '10px', width: '100%', minHeight: '60px', marginLeft: '0px', marginRight: '0px', paddingLeft: '12px', alignItems: 'flex-start', borderColor: this.state.input_no_tax_reason_radio == '1' ? "#013b66" : "#A7A9AC"}}
                              />
                              <FormControlLabel 
                                label={
                                  <div style={{marginTop: '12px', marginBottom: '12px', paddingRight: '16px'}}>
                                    <Typography sx={{fontFamily: 'DB Heavent', fontSize: '24px', color: '#013b66'}}>ยังไม่ได้รับหมายเลขประจำตัวผู้เสียภาษี</Typography>
                                    <Typography sx={{fontFamily: 'DB Heavent', fontSize: '16px', color: '#A7A9AC'}}>(หมายเหตุ: โปรดอธิบายเหตุผลที่ ท่านไม่สามารถขอหมายเลขประจำตัวผู้เสียภาษีได้)</Typography>
                                    {this.state.input_no_tax_reason_radio == 2 && (
                                      <div className="row_idcard">
                                        <div style={{fontSize: '24px', fontFamily: 'DB Heavent'}}>อธิบายเหตุผลเพิ่มเติม</div>
                                        <IMaskInput
                                          mask={/^[A-Za-z0-9./\s\-]+$/}
                                          value={this.state.input_reason_choice_2}
                                          unmask={true}
                                          placeholder="โปรดระบุเหตุผลเป็นภาษาอังกฤษ"
                                          className="input_idcard"
                                          name="input_reason_choice_2"
                                          onAccept={this.handleChangeReasonChoice2}
                                          maxLength={400}
                                        />
                                        <span className="span_err_tax_no">
                                          กรุณาระบุเหตุผลเพิ่มเติม
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                }
                                value={'2'} 
                                control={
                                  <Radio 
                                    sx={{marginTop: '8px'}}
                                    icon={<RadioButtonUncheckedIcon sx={this.state.input_no_tax_reason_radio == '2' ? {color: "#013b66"} : {color: "#A7A9AC"}} />}
                                    checkedIcon={<CheckCircleIcon sx={{color: "#013b66"}} />}
                                  />
                                }
                                sx={{border: 1, borderRadius: '10px', width: '100%', minHeight: '80px', marginLeft: '0px', marginRight: '0px', marginTop: '16px', paddingLeft: '12px', alignItems: 'flex-start', borderColor: this.state.input_no_tax_reason_radio == '2' ? "#013b66" : "#A7A9AC"}}
                              />
                              <FormControlLabel 
                                label={
                                  <div style={{marginTop: '12px', marginBottom: '12px', paddingRight: '16px'}}>
                                    <Typography sx={{fontFamily: 'DB Heavent', fontSize: '24px', color: '#013b66'}}>ไม่จำเป็นต้องให้หรือเปิดเผยเลขประจําตัวผู้เสียภาษี</Typography>
                                    <Typography sx={{fontFamily: 'DB Heavent', fontSize: '16px', color: '#A7A9AC'}}>(หมายเหตุ: เลือกเหตุผลนี้เฉพาะในกรณีที่ กฎหมายภายในประเทศนั้นไม่ได้บังคับจัดเก็บเลขประจําตัวผู้เสียภาษี)</Typography>
                                  </div>
                                }
                                value={'3'} 
                                control={
                                  <Radio 
                                    sx={{marginTop: '8px'}}
                                    icon={<RadioButtonUncheckedIcon sx={this.state.input_no_tax_reason_radio == '3' ? {color: "#013b66"} : {color: "#A7A9AC"}} />}
                                    checkedIcon={<CheckCircleIcon sx={{color: "#013b66"}} />}
                                  />
                                }
                                sx={{border: 1, borderRadius: '10px', width: '100%', minHeight: '100px', marginLeft: '0px', marginRight: '0px', marginTop: '16px', paddingLeft: '12px', alignItems: 'flex-start', borderColor: this.state.input_no_tax_reason_radio == '3' ? "#013b66" : "#A7A9AC"}}
                              />
                              
                            </RadioGroup>
                          </div>
                        )}
                      </div>
                      <div
                        className="under_fix_btn"
                        style={{ background: "#fff" }}
                      >
                        {this.state.is_edit ? (
                        <div>
                          <button 
                            className="crs_declare_location_form_del_btn"
                            onClick={this.handleEditDelete}
                          ><DeleteForeverIcon />&nbsp;ลบทิ้ง</button>
                          <button 
                            className="crs_declare_location_form_save_btn"
                            onClick={this.handleEditSubmit}
                            style={!(this.is_edit_valid() && this.is_form_valid()) ? {backgroundColor: '#e0e1e2', color: '#b0b0b0'} : {backgroundColor: '#003b66', color: 'white'}}
                            disabled={!(this.is_edit_valid() && this.is_form_valid())}
                          ><SaveIcon />&nbsp;&nbsp;บันทึกข้อมูล</button>
                        </div>
                      ) : (
                        <div>
                          <button 
                            className="crs_declare_location_form_next_btn" 
                            onClick={this.handleAddSubmit}
                            style={!this.is_form_valid() ? {backgroundColor: '#e0e1e2', color: '#b0b0b0'} : {backgroundColor: '#003b66', color: 'white'}} 
                            disabled={!this.is_form_valid()}
                          >ยืนยัน</button>
                        </div>
                      )}
                      </div>
                    </div>
                  </div>
                  <Loading status={this.state.loading}></Loading>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
          
        </section>
      </div>
    )
  }
}
export default CrsDeclareLocationForm;