const workAddress = {
  country_name: '',
  district_name: '',
  f2_workplace_address: '',
  f2_workplace_building: '',
  f2_workplace_country: '',
  f2_workplace_district: '',
  f2_workplace_floor: '',
  f2_workplace_moo: '',
  f2_workplace_no: '',
  f2_workplace_province: '',
  f2_workplace_road: '',
  f2_workplace_subdistrict: '',
  f2_workplace_zipcode: '',
  province_name: '',
  sub_district_name: '',
};

export default workAddress;
