import React, { Component } from 'react';
import './CheckAmerican.css';
import ButtonCustom from '../../components/button/ButtonCustom';
import swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
class CheckAmerican extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color_long_btn_chk_american_no: 'active',
      color_long_btn_chk_american_yes: 'unactive',
      CheckAmerican: false,
    };

    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.push('termandcon');
  }

  //   MySwal = withReactContent(swal);
  Click_no = async () => {
    if (this.state.color_long_btn_chk_american_no === 'unactive') {
      await this.setState({
        color_long_btn_chk_american_no: 'active',
        CheckAmerican: false,
      });
      if (this.state.color_long_btn_chk_american_yes === 'active') {
        await this.setState({
          color_long_btn_chk_american_yes: 'unactive',
        });
      }
    }
    //console.log(this.state.CheckAmerican);
  };
  Click_yes = async () => {
    if (this.state.color_long_btn_chk_american_yes === 'unactive') {
      await this.setState({
        color_long_btn_chk_american_yes: 'active',
        CheckAmerican: true,
      });
      if (this.state.color_long_btn_chk_american_no === 'active') {
        await this.setState({
          color_long_btn_chk_american_no: 'unactive',
        });
      }
    }
    //console.log(this.state.CheckAmerican);
  };
  Send_family_status = (e) => {
    const MySwal = withReactContent(swal);
    e.preventDefault();
    if (this.state.color_long_btn_chk_american_yes === 'unactive') {
      window.location = 'fatca';
      // console.log("fatca");
    } else {
      // console.log('no fatca');
      const MySwal = withReactContent(swal);
      MySwal.fire({
        customClass: {
          confirmButton: 'long_btn_fix',
          cancelButton: 'long_btn_fix',
        },
        // icon: 'warning',
        imageUrl: '/images/ico_alert.svg',
        imageWidth: 100,
        imageHeight: 100,
        confirmButtonColor: '#01375f',
        confirmButtonText: 'กลับ',
        title:
          '<span style="color:#00000;font-size:24px;"> ขออภัย <br>ทางบริษัทฯ ไม่มีนโยบายการให้บริการ<br> ลูกค้าที่เป็นพลเมืองอเมริกัน</span>',
        html: '<div style="font-size: 20px;">หากมีข้อสงสัยเพิ่มเติมกรุณาติดต่อ <br>DAOL Contact Center 0 2351 1800</div>',
      }).then(function () {
        // window.location = "summaryshow";
      });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const MySwal = withReactContent(swal);
    if (this.state.CheckAmerican) {
      MySwal.fire({
        customClass: {
          confirmButton: 'long_btn_fix',
          cancelButton: 'long_btn_fix',
        },
        imageUrl: '/images/ico_alert.svg',
        imageWidth: 100,
        imageHeight: 100,
        confirmButtonColor: '#003b66',
        confirmButtonText: 'กลับ',
        title: '<div>ขออภัย ทางบริษัทฯ ไม่มีนโยบายการให้บริการลูกค้าที่เป็นพลเมืองอเมริกัน </div>',
        html: '<div style="font-size: 20px;text-align:center;">หากมีข้อสงสัยเพิ่มเติมกรุณาติดต่อ <br>DAOL Contact Center 0 2351 1800</div>',
      });
      return false;
    }
    this.props.history.push('/fatca');
  };
  render() {
    return (
      <div>
        <div className="content">
          <div className="top_main_back">
            <div className="main_back" onClick={this.goBack}>
              <img
                src="/images/angle-arrow-left.svg"
                alt="forward"
                style={{ marginLeft: '3px' }}
                className="arrow_back"
              />
            </div>
            <div className="text_title">สถานะพลเมืองอเมริกัน</div>
          </div>

          <div className="bg_content_white over_f_hide">
            <div className="in_bg_white default autoscroll">
              <img
                src="/images/ico_usa.png"
                title="name"
                className="logo"
                style={{
                  width: '50%',
                  display: 'block',
                  margin: 'auto',
                  marginTop: 24,
                }}
              ></img>
              <div className="text_title_head_chkamerican" style={{ position: 'relative' }}>
                คุณเป็นพลเมืองอเมริกันใช่หรือไม่ ?
              </div>
              <div style={{ lineHeight: '40px' }}>
                <ButtonCustom
                  label="ไม่ใช่ ฉันไม่ใช่พลเมืองอเมริกัน"
                  handle={this.Click_no}
                  color={this.state.color_long_btn_chk_american_no}
                  btnClass="long_btn_chk_american_no"
                />

                <ButtonCustom
                  label="ใช่ ฉันเป็นพลเมืองอเมริกัน"
                  handle={this.Click_yes}
                  color={this.state.color_long_btn_chk_american_yes}
                  btnClass="long_btn_chk_american_yes"
                />
              </div>
            </div>

            <div className="under_fix_btn">
              <ButtonCustom handle={this.handleSubmit} label="ถัดไป" color="blue" btnClass="long_btn" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CheckAmerican;
