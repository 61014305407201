import React, { Component } from 'react';
import './Switch.css';
class Switch extends Component {
    handleClick(e) {

        // var x = document.getElementsByClassName('switch_item');
        var x = e.currentTarget.parentNode.getElementsByClassName('switch_item');

        var i;
        for (i = 0; i < x.length; i++) {

            x[i].classList.remove("active");
        }
        e.currentTarget.classList.add("active");
    }
    render() {
        const selected = this.props.selected
        if (selected) {
            return (

                <div>

                    <div className="switch_wrapper" onClick={this.props.onClick}>
                        <div className={`switch_item red ${selected == "0" ? "" : "active"}`} onClick={((e) => this.handleClick(e))}>{this.props.labelL}</div>
                        <div className={`switch_item ${selected == "1" ? "" : "active"}`} onClick={((e) => this.handleClick(e))}>{this.props.labelR}</div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="switch_wrapper" onClick={this.props.onClick}>
                        <div className="switch_item red" onClick={((e) => this.handleClick(e))}>{this.props.labelL}</div>
                        <div className="switch_item active" onClick={((e) => this.handleClick(e))}>{this.props.labelR}</div>
                    </div>
                </div>
            );
        }
    }
}

export default Switch;