import React, { Component } from "react";
import "./KtbstATSChoice.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { browserName, browserVersion } from "react-device-detect";
import to from "await-to-js";
import { config } from "../../constants";
import Lottie from "react-lottie";
import * as animationData from "../../components/loading/loading_idp.json";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
var intervalId;
var atsPopUp;

const _url = config.baseAPIURL03;

class KtbstATSChoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_interval: false,
      btns: [
        // {
        //   id: '1',
        //   label: 'ธนาคารไทยพาณิชย์',
        //   bg: '#4e3086',
        //   src: '/images/scb.svg',
        // },
        {
          id: "2",
          label: "ธนาคารกสิกรไทย",
          bg: "#2f963b",
          src: "/images/KBank_logo.svg",
        },
      ],
      registerRequestData: [],
      intervalATSData: [],
      bankCode: "",
      isDisconnected: false,
    };
    this.goBack = this.goBack.bind(this);
    this.signOk = this.signOk.bind(this);
    this.clickOtherBank = this.clickOtherBank.bind(this);
  }

  goBack() {
    if (this.state.is_interval) {
      window.location = "ats_choice";
    } else {
      clearInterval(intervalId);
      this.props.history.push("summary_risk");
    }

    // window.location = 'summary_risk';
  }
  async componentDidMount() {
    // console.log(config);
    // download_ico_alert();
  }
  signOk = async (e) => { 
    // if (checkInternet()) {
    const bankID = e.currentTarget.dataset.id;
    const f0 = JSON.parse(localStorage.getItem("f0"));
    const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
    const token = HeaderManp.token;
    // Change domain 29/05/2023
    const requestBankMapping = {
      1: {
        url: `${config.baseAPIURL07}/ats_registration/scb/v1/register_request`,
        param: {
          ats_card_id: f0.f0_national_id,
          token: token,
          backurl: `${config.baseURL}/ats_callback.php`,
        },
        bankCode: "scb",
      },
      2: {
        url: `${config.baseAPIURL07}/ats_registration/kbank/v1/register_request`,
        param: {
          ats_card_id: f0.f0_national_id,
          token: token,
        },
        bankCode: "kbank",
      },
    };

    if (!requestBankMapping[bankID]) {
      return;
    }

    const { url, param, bankCode } = requestBankMapping[bankID];
    const [error, result] = await to(axios.post(url, param));
    if (error || !result.data) {
      clearInterval(intervalId);
      this.popupError(
        `เกิดข้อผิดพลาด ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง`,
        "สมัครบริการหักบัญชีอัตโนมัติ<br>( ATS ) ไม่สำเร็จ"
      );
      return;
    }
    let bankURL = result.data.ats_link;
    this.setState({ registerRequestData: result.data, bankCode: bankCode });
   
    try {
      window.open(bankURL, '_blank').focus();
    } catch (e) {
        clearInterval(intervalId);
        if(browserName == "Mobile Safari"){
           this.popupError(
             'กรุณาตั้งค่าอนุญาตการเข้าถึง Pop-ups ที่เมนู ตั้งค่า (Settings) > Safari > ปิดกั้นหน้าต่างที่แสดงขึ้น (Block Pop-ups) เลื่อนปุ่มเพื่อเปลี่ยนจากสีเขียวเป็นสีเทา',
               `ไม่สามารถดำเนินการต่อได้`
           );
           return;
        }else if(browserName == "Safari"){
          this.popupError(
            'กรุณาตั้งค่าอนุญาตการเข้าถึง Pop-ups ที่ Safari เลือก Preferences > Websites > Pop-ups และคลิก Allow',
               `ไม่สามารถดำเนินการต่อได้`
          );
          return;
        }
        return;
    }
    //window.open(bankURL);
    // if (!isMobile) {
    //   window.open(bankURL, "", "height=700,width=500,toolbar=no");
    // } else {
    //   window.open(bankURL);
    // }
    this.signupOK();
    this.intervalATS();
    // } else {
    //   this.setState({
    //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
    //   })
    // }
  };

  testPopUp() {
    let bankURL = "/ats_callback.html";
    if (!isMobile) {
      window.open(bankURL, "atsPopUp", "height=700,width=500,toolbar=no");
    } else {
      window.open(bankURL, "atsPopUp");
    }
  }

  intervalATS() {
    intervalId = setInterval(() => {
      let ats_ref = this.state.registerRequestData.ats_ref;
      let bank_code = this.state.bankCode;
      let HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
      let head_token = HeaderManp.head_token;

      axios
        .get(
          _url +
          `/form/ats/selbyhead?head_token=${head_token}&bank_code=${bank_code}&ats_ref=${ats_ref}`
        )
        .then((res) => {
          //#################################################//#################################################
          // ++ Mock ###################################################################
          // const mockup = {
          //   ats_bank: 'KBANK',
          //   ats_ref: '51f54aadf8',
          //   ats_account_name: '',
          //   ats_account_no: '2432009259',
          //   ats_status: 'success',
          //   ats_card_id: '8101872722550',
          //   ats_message: 'Your Online Direct Debit Registration is successful.',
          // };
          // const atsDataUser = {
          //   bankCode: 'KBANK',
          //   bank_id: '10',
          //   bank_branch: { value: '5623', label: 'AIA CAPITAL CENTER (ถนนรัชดาภิเษก)' },
          //   bank_account_no: '9662588064',
          //   bank_account_name: 'ABD DEF',
          // };
          // localStorage.setItem('atsDataUser', JSON.stringify(atsDataUser));
          // localStorage.setItem('atsData', JSON.stringify(mockup));
          // this.setState({ intervalATSData: mockup });
          // -- Mock ###################################################################
          //#################################################//#################################################

          this.setState({ intervalATSData: res.data.Data[0] });
          localStorage.setItem("atsData", JSON.stringify(res.data.Data[0]));
          // console.log(res.data.Data[0]);
        })
        .catch((error) => {
          clearInterval(intervalId);
          this.popupError(
            `เกิดข้อผิดพลาด ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง`,
            "สมัครบริการหักบัญชีอัตโนมัติ<br>( ATS ) ไม่สำเร็จ"
          );
        });

      this.Tick();
    }, 5000);
  }

  Tick() {
    const status = [
      "success",
      "reject",
      "cancel",
      "error",
      "timeout",
      "created",
      "",
    ];
    const statusLoading = ["created", ""];
    const statusSucess = ["success"];
    const statusError = ["reject", "cancel", "error", "timeout"];
    if (this.state.intervalATSData.ats_status) {
      if (statusLoading.includes(this.state.intervalATSData.ats_status)) {
        // clearInterval(intervalId);
        // window.location = 'ats_form';
      }
      if (statusSucess.includes(this.state.intervalATSData.ats_status)) {
        clearInterval(intervalId);
        window.location = "ats_form";
      }
      if (statusError.includes(this.state.intervalATSData.ats_status)) {
        clearInterval(intervalId);
        // window.location = 'ats_choice?reload=1';
        this.popupError(
          `โปรดติดต่อฝ่ายบริการลูกค้า 0 2351 1800 เพื่อทำการสมัครฯ ผ่านช่องทางอื่น`,
          "สมัครบริการหักบัญชีอัตโนมัติ <br>( ATS ) ไม่สำเร็จ"
        );
      }
    }
  }
  signupOK() {
    this.setState({ is_interval: true });
    // document.getElementById(
    //   "mainContent"
    // ).innerHTML = `<div style="text-align:center;">ระบบ กำลังดำเนินการ....</div>`;
    //
    // const MySwal = withReactContent(swal);
    // MySwal.fire({
    //   imageUrl: '/images/ico_alert.svg',
    //   imageWidth: 100,
    //   imageHeight: 100,
    //   allowOutsideClick: false,
    //   showCancelButton: false,
    //   showConfirmButton: false,
    //   title: '<div class="title_popup">สมัครบริการหักบัญชีอัตโนมัติ</div>',
    //   html: '<div class="content_popup">ระบบ กำลังดำเนินการ....</div>',
    // }).then(function () {
    //   // window.location = "summaryshow";
    // });
  }
  clickOtherBank = () => {
    this.props.history.push("ats_choice_other");
    // window.location = 'ats_choice_other';
  };

  popupOtherBank = () => {
    const MySwal = withReactContent(swal);
    MySwal.fire({
      customClass: {
        confirmButton: "long_btn_fix",
        cancelButton: "long_btn_fix",
      },
      // imageUrl: '/images/ico_approve.svg',
      // imageWidth: 100,
      // imageHeight: 100,
      confirmButtonColor: "#003b66",
      confirmButtonText: "ถัดไป",
      title: `<span class="title_popup">กรุณาดำเนินการสมัครบริการหักบัญชีอัตโนมัติ (ATS) เพื่อความสะดวกในการทำธุรกรรม</span>`,
      html: `<div class="content_popup">ท่านจะได้รับขั้นตอนการสมัคร หลังจากยืนยันข้อมูลการเปิดบัญชีในขั้นตอนถัดไป ผ่านอีเมลที่ท่านให้ไว้</div>`,
    }).then(function () {
      clearInterval(intervalId);
      localStorage.setItem(
        "atsData",
        JSON.stringify({
          ats_bank: "",
          ats_ref: "",
          ats_account_name: "",
          ats_account_no: "",
          ats_status: "",
          ats_card_id: "",
          ats_message: "",
        })
      );
      localStorage.setItem(
        "atsDataUser",
        JSON.stringify({
          bankCode: "Other",
          bank_id: "",
          bank_branch: "",
          bank_account_no: "",
          bank_account_name: "",
        })
      );
      localStorage.setItem("currentStep", "summaryshow");
      window.location = "summaryshow";
      // vm.props.history.push('/summaryshow');
    });
  };

  popupError = (msg, title = "") => {
    const MySwal = withReactContent(swal);
    MySwal.fire({
      customClass: {
        confirmButton: "long_btn_fix",
        cancelButton: "long_btn_fix",
      },
      // icon: 'warning',
      imageUrl: "/images/ico_alert.svg",
      imageWidth: 100,
      imageHeight: 100,
      confirmButtonColor: "#003b66",
      confirmButtonText: "กลับ",
      title: `<span class="title_popup">${title}</span>`,
      html: `<div class="content_popup">${msg}</div>`,
    }).then(function () {
      clearInterval(intervalId);
      window.location = "ats_choice?reload=1";
    });
  };

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" alt="logo" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          alt="angle-arrow"
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">
                        สมัครบริการหักบัญชีอัตโนมัติ
                      </div>
                    </div>

                    <div className="bg_content_white" style={{ padding: "0" }}>
                      <div
                        style={{
                          color: "#003b66",
                          fontSize: 24,
                          fontWeight: "bold",
                          padding: "21px",
                          display: this.state.is_interval ? "none" : "block",
                        }}
                      >
                        การเปิดบัญชีออนไลน์กับ DAOL INVESTMENT
                        ท่านยินยอมให้สมัครบริการหักบัญชีอัตโนมัติ (ATS)
                        สำหรับหักเงินค่าซื้อ รับเงินปันผล รับเงินค่าขาย
                        และถอนเงินจากบัญชีลงทุน ของทุกผลิตภัณฑ์ของบริษัท
                        ตามธนาคารที่ท่านเลือก
                      </div>

                      <div
                        id="mainContent"
                        style={{
                          display: this.state.is_interval ? "none" : "block",
                        }}
                      >
                        {this.state.btns.map((item, i) => {
                          return (
                            <div
                              onClick={this.signOk}
                              data-id={item.id}
                              key={i}
                            >
                              <ButtonCustom
                                label={item.label}
                                bg={item.bg}
                                src={item.src}
                                btnClass="btn_default row white-space"
                              />
                            </div>
                          );
                        })}

                        <div onClick={this.clickOtherBank} data-id="3">
                          {
                            //   label: 'ธนาคารไทยพาณิชย์'
                            //   bg: '#4e3086'
                            <ButtonCustom label="ธนาคารอื่นๆ" btnClass="btn_other_bank" src="/images/landmark.svg" />
                          }
                          {/* <ButtonCustom
                            label="ธนาคารไทยพาณิชย์"
                            btnClass="btn_default"
                            bg="#4e3086"
                            src="/images/scb.svg"
                          /> */}
                        </div>
                      </div>

                      <div
                        style={{
                          color: "#003b66",
                          fontSize: 24,
                          fontWeight: "bold",
                          padding: "21px",
                          display: this.state.is_interval ? "none" : "block",
                        }}
                      >
                        หลังจากสมัครบริการสำเร็จแล้ว กรุณากลับมายังระบบเปิดบัญชี DAOL INVESTMENT เพื่อทำรายการต่อ
                      </div>

                      <div
                        style={{
                          marginTop: 24,
                          textAlign: "center",
                          display: this.state.is_interval ? "block" : "none",
                        }}
                      >
                        <Lottie
                          options={defaultOptions}
                          height={70}
                          width={70}
                          isStopped={false}
                          isPaused={false}
                        />
                        <div
                          style={{
                            marginTop: 24,
                            fontSize: "24px",
                            fontWeight: "bold",
                          }}
                        >
                          ระบบกำลังดำเนินการ
                          <br /> อาจใช้เวลาสักครู่
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default KtbstATSChoice;
