import React, { Component } from "react";
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { connect } from "react-redux";
import to from "await-to-js";

import {
  loadCountries,
  loadDistricts,
  loadPostCodes,
  loadProvinces,
  loadSubDistricts,
} from "./components/helpers/address";

import { setInitialAddressData } from "./stores/actions";

import Onboarding from "./pages/onboarding/Onboarding";
import SplashScreen from "./pages/splashscreen/SplashScreen";
import Notfound from "./pages/notfound/NotFound";
import Settings from "./pages/settings/Settings";
import Login from "./pages/login/Login";
import ForgetPassword from "./pages/forgetpassword/ForgetPassword";
import Fatca from "./pages/fatca/Fatca";
import KtbstIDCDetail from "./pages/ktbst_idc_detail/KtbstIDCDetail";
import CheckUserInfo from "./pages/checkuserinfo/CheckUserInfo";
import KtbstOTP from "./pages/ktbst_otp/KtbstOTP";
import SplashScreenOa from "./pages/splashscreenoa/SplashscreenOa";
import PreRegis from "./pages/preregis/PreRegis";
import TermAndCon from "./pages/termandcon/TermAndCon";
import CheckAmerican from "./pages/checkamerican/CheckAmerican";
import SnapIdCard from "./pages/snapidcard/SnapIdCard";
import SnapIdCardOk from "./pages/snapidcard_ok/SnapIdCardOk";
import SnapFrontId from "./pages/snapfrontid/SnapFrontId";
import SnapFrontId2 from "./pages/snapfrontid/SnapFrontId2";
import SnapFrontId3 from "./pages/snapfrontid/SnapFrontId3";
import SnapBackId from "./pages/snapbackid/SnapBackId";
import KtbstTCNDID from "./pages/ktbst_tc_ndid/KtbstTCNDID";
import KtbstNDID from "./pages/ktbst_ndid/KtbstNDID";
import KtbstNDIDChoice from "./pages/ktbst_ndid_choice/KtbstNDIDChoice";
import SummaryShow from "./pages/summaryshow/SummaryShow";
import TcConfirm from "./pages/tcconfirm/TcConfirm";
import Pdpa from "./pages/pdpa/Pdpa";
import FamilyStatus from "./pages/familystatus/FamilyStatus";
import FamilyStatusSingle from "./pages/familystatussingle/FamilyStatusSingle";
import StatusChildren from "./pages/statusChildren/StatusChildren";
import LegelAgeChildren from "./pages/legal_age_children/LegelAgeChildren";
import EmergencyContact from "./pages/emergency_contact/EmergencyContact";
import PoliticianRelation from "./pages/politician_relation/PoliticianRelation";
import FinancialDetail from "./pages/financial_detail/FinancialDetail";
import KtbstIDCAddressChoose from "./pages/ktbst_idc_address_choose/KtbstIDCAddressChoose";
import KtbstIDCAddressChoose2 from "./pages/ktbst_idc_address_choose2/KtbstIDCAddressChoose2";
import KtbstIDCAddressForm from "./pages/ktbst_idc_address_form/KtbstIDCAddressForm";
import KtbstRisk from "./pages/ktbst_risk/KtbstRisk";
import KtbstRiskQuestion from "./pages/ktbst_risk_question/KtbstRiskQuestion";
import KtbstRiskPoll from "./pages/ktbst_risk_poll/KtbstRiskPoll";
import KtbstRiskPoll2 from "./pages/ktbst_risk_poll/KtbstRiskPoll2";
import KtbstRiskPoll3 from "./pages/ktbst_risk_poll/KtbstRiskPoll3";
import KtbstSummaryRisk from "./pages/ktbst_summary_risk/KtbstSummaryRisk";
import KtbstInvestmentObjective from "./pages/ktbst_Investment_objective/KtbstInvestmentObjective";
import Jobs from "./pages/jobs/Jobs";
import KtbstInvestmentInfoIncome from "./pages/ktbst_investment_info_income/KtbstInvestmentInfoIncome";
import KtbstInvestmentInfoList from "./pages/ktbst_investment_info_list/KtbstInvestmentInfoList";
import KtbstInvestmentInfoList2 from "./pages/ktbst_investment_info_list2/KtbstInvestmentInfoList2";
import KtbstValueOfInvester from "./pages/ktbst_value_of_lnvester/KtbstValueOfInvester";
import KtbstATSChoice from "./pages/ktbst_ats_choice/KtbstATSChoice";
import KtbstATSChoiceOther from "./pages/ktbst_ats_choice_other/KtbstATSChoiceOther";
import KtbstSigneEx from "./pages/ktbst_signed_attatched/KtbstSigneEx";
import KtbstSignedAttatched from "./pages/ktbst_signed_attatched/KtbstSignedAttatched";
import KtbstSignedAttatchedOK from "./pages/ktbst_signed_attatched_ok/KtbstSignedAttatchedOK";
import KtbstSignSignatureHorizonScreen from "./pages/ktbst_sign_signature_horizonscreen/KtbstSignSignatureHorizonScreen";
import KtbstNdidIdpRepons from "./pages/ktbst_ndid_idp_repons/KtbstNdidIdpRepons";
import ATS from "./pages/ats/ATS";
import ATS_CALLBACK from "./pages/ats_callback/ATS_CALLBACK";
import Iframe from "./pages/iframe/Iframe";
import ATSForm from "./pages/ats_form/ATSForm";
import Clear_data from "./pages/clear_data/Clear_data";
import LoginDesktop from "./pages/login/LoginDesktop";
import securities from "./pages/ktbst_summary_risk/securities";
import KtbstBrowserSuitable from "./pages/ktbst_browser_suitable/KtbstBrowserSuitable";
import ProductChoice from "./pages/product_choice/Product_choice";
import ProductConsent from "./pages/product_consent/Product_consent";
import Ndid_info from "./pages/ndid_info/Ndid_info";
import NdidOffline from "./pages/ndid_offline/NdidOffline";
import NdidTC from "./pages/ndid_offline/NdidOffineTC";
import NdidList from "./pages/ndid_offline/NdidOfflineList";
import NdidChoice from "./pages/ndid_offline/NdidOfflineChoice";
import CrsBirthAddress from "./pages/crs_birth_address/crs_birth_address";
import CrsDeclareLocation from "./pages/crs_declare_location/crs_declare_location";
import CrsDeclareLocationForm from "./pages/crs_declare_location_form/crs_declare_location_form";
import CrsConsent from "./pages/crs_consent/crs_consent";

class AppRouter extends Component {
  constructor(props) {
    super(props);
    const identifier = localStorage.getItem("identifier");
    this.state = {
      identifier: identifier,
    };
  }

  async componentDidMount() {
    const [error, result] = await to(
      Promise.all([
        loadCountries(),
        loadProvinces(),
        loadDistricts(),
        loadSubDistricts(),
        loadPostCodes(),
      ])
    );
    if (!error && result) {
      const [countries, provinces, districts, subdistricts, postcodes] = result;

      if (countries && provinces && districts && subdistricts && postcodes) {
        this.props.setInitialAddressData({
          countries,
          provinces,
          districts,
          subdistricts,
          postcodes,
        });
      }
    } else {
      console.log(error);
    }

    // Remove splash screen
    const splashScreen = document.querySelector(".app-splash-screen");
    if (splashScreen) {
      splashScreen.style.opacity = 0;
      setTimeout(() => {
        splashScreen && splashScreen.parentNode.removeChild(splashScreen);
      }, 500);
    }
  }

  isLoadAddress() {
    const { countries, provinces, districts, subdistricts, postcodes } =
      this.props.address;
    return (
      countries.length > 0 &&
      provinces.length > 0 &&
      districts.length > 0 &&
      subdistricts.length > 0 &&
      postcodes.length > 0
    );
  }

  render() {
    const { address } = this.props;

    return (
      <Router>
        <Switch>
          {/* <Redirect exact from="/" to="/desktop_ktbst" /> */}
          {/* <Redirect exact from="/" to="/splashscreen" /> */}
          <Redirect exact from="/" to="/index.html" />
          <Route path="/desktop_ktbst" component={LoginDesktop} />
          <Route path="/splashscreen" component={SplashScreen} />
          <Route path="/index.html" component={SplashScreenOa} />
          <Route path="/preregis" component={PreRegis} />
          <Route path="/termandcon" component={TermAndCon} />
          <Route path="/checkamerican" component={CheckAmerican} />
          <Route path="/summaryshow" component={SummaryShow} />
          <Route path="/tcconfirm" component={TcConfirm} />
          <Route path="/pdpa" component={Pdpa} />
          <Route path="/onboarding" component={Onboarding} />
          <Route path="/settings" component={Settings} />
          <Route path="/login" component={Login} />
          <Route path="/forgetpassword" component={ForgetPassword} />
          <Route path="/fatca" component={Fatca} />
          <Route path="/idc_detail" component={KtbstIDCDetail} />
          <Route path="/otp" component={KtbstOTP} />

          <Route
            path="/ndid_choice/:BankID"
            render={(props) =>
              this.state.identifier ? (
                <NdidChoice {...props} address={address} />
              ) : (
                <KtbstNDIDChoice {...props} address={address} />
              )
            }
          />
          <Route
            path="/idc_address_choose"
            render={(props) =>
              this.isLoadAddress() && (
                <KtbstIDCAddressChoose {...props} address={address} />
              )
            }
          />
          <Route
            path="/idc_address_choose2"
            render={(props) =>
              this.isLoadAddress() && (
                <KtbstIDCAddressChoose2 {...props} address={address} />
              )
            }
          />
          <Route
            path="/idc_address_form"
            render={(props) =>
              this.isLoadAddress() && (
                <KtbstIDCAddressForm {...props} address={address} />
              )
            }
          />
          <Route path="/risk" component={KtbstRisk} />
          <Route path="/risk_question" component={KtbstRiskQuestion} />
          <Route path="/risk_poll" component={KtbstRiskPoll} />
          <Route path="/risk_poll2" component={KtbstRiskPoll2} />
          <Route path="/risk_poll3" component={KtbstRiskPoll3} />
          <Route path="/summary_risk" component={KtbstSummaryRisk} />
          <Route path="/ats_choice" component={KtbstATSChoice} />
          <Route path="/ats_choice_other" component={KtbstATSChoiceOther} />
          <Route
            path="/investment_objective"
            component={KtbstInvestmentObjective}
          />
          <Route
            path="/investment_info_income"
            component={KtbstInvestmentInfoIncome}
          />
          <Route
            path="/investment_info_list"
            component={KtbstInvestmentInfoList}
          />
          <Route
            path="/investment_info_list_2"
            component={KtbstInvestmentInfoList2}
          />
          <Route path="/value_of_invester" component={KtbstValueOfInvester} />
          <Route path="/signed_attatched" component={KtbstSignedAttatched} />
          <Route path="/signed_attatched_ex" component={KtbstSigneEx} />
          <Route
            path="/signed_attatched_ok"
            component={KtbstSignedAttatchedOK}
          />
          <Route
            path="/ktbst_sign_signature_horizonscreen"
            component={KtbstSignSignatureHorizonScreen}
          />
          <Route path="/ktbst_ndid_idp_repons" component={KtbstNdidIdpRepons} />
          <Route path="/CheckUserInfo" component={CheckUserInfo} />
          <Route path={"/SnapIdCard"} component={SnapIdCard} />
          <Route path="/SnapIdCard_ok" component={SnapIdCardOk} />
          <Route path="/SnapFrontId" component={SnapFrontId} />
          <Route path="/SnapFrontId2" component={SnapFrontId2} />
          <Route path="/SnapFrontId3" component={SnapFrontId3} />
          <Route path="/SnapBackId" component={SnapBackId} />
          <Route path="/FamilyStatus" component={FamilyStatus} />
          <Route path="/FamilyStatusSingle" component={FamilyStatusSingle} />
          <Route path="/StatusChildren" component={StatusChildren} />
          <Route path="/LegelAgeChildren" component={LegelAgeChildren} />
          <Route path="/EmergencyContact" component={EmergencyContact} />
          <Route path="/PoliticianRelation" component={PoliticianRelation} />
          <Route path="/FinancialDetail" component={FinancialDetail} />
          <Route path="/Jobs" component={Jobs} />
          <Route path="/ats" component={ATS} />
          <Route path="/ats_callback" component={ATS_CALLBACK} />
          <Route path="/ats_form" component={ATSForm} />
          <Route path="/iframe" component={Iframe} />
          <Route path="/clear_data" component={Clear_data} />
          <Route path="/securities" component={securities} />
          <Route
            path="/ktbst_browser_suitable"
            component={KtbstBrowserSuitable}
          />
          <Route path="/product_choice" component={ProductChoice} />
          <Route path="/product_consent" component={ProductConsent} />
          {/* Start NDID */}
          <Route path="/ndid_info" component={Ndid_info} />
          <Route path="/tc_ndid" component={KtbstTCNDID} />
          <Route path="/ndid" component={KtbstNDID} />
          <Route
            path="/ndid_choice/:BankID"
            render={(props) => <NdidChoice {...props} address={address} />}
          />
          {/* END NDID */}
          <Route path="/ndid_tc" component={NdidTC} />
          <Route path="/ndid_offline" component={NdidOffline} />
          <Route path="/ndid_list" component={NdidList} />
          
          {/* Start CRS */}
          <Route path="/crs_birth_address" component={CrsBirthAddress} />
          <Route path="/crs_declare_location" component={CrsDeclareLocation} />
          <Route path="/crs_declare_location_form" component={CrsDeclareLocationForm} />
          <Route path="/crs_consent" component={CrsConsent} />
          
          {/* END CRS */}

          <Route component={Notfound} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  address: state.address,
});

const mapDispatchToProps = (dispatch) => ({
  setInitialAddressData: (value) => dispatch(setInitialAddressData(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
