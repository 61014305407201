import React, { Fragment, useState, useEffect } from 'react';
import DatePicker from 'react-mobile-datepicker';
import ModernDatepicker from 'react-modern-datepicker';

import { isMobile } from 'react-device-detect';
import moment from 'moment';
import 'moment/locale/th';

import { parse, format, isAfter, isBefore, isValid } from 'date-fns';
import th from 'date-fns/locale/th';

import './DatePickerInput.css';

const defaultMinDate = new Date(1970, 0, 1);
const defaultMaxDate = new Date(2050, 0, 1);

const monthMap = {
  1: 'ม.ค.',
  2: 'ก.พ.',
  3: 'มี.ค.',
  4: 'เม.ย.',
  5: 'พ.ค.',
  6: 'มิ.ย.',
  7: 'ก.ค.',
  8: 'ส.ค.',
  9: 'ก.ย.',
  10: 'ต.ค.',
  11: 'พ.ย.',
  12: 'ธ.ค.',
};

const mobileDatepickerConfig = {
  year: {
    format: 'YYYY',
    caption: 'Year',
    step: 1,
  },
  month: {
    format: (value) => monthMap[value.getMonth() + 1],
    caption: 'Mon',
    step: 1,
  },
  date: {
    format: 'DD',
    caption: 'Day',
    step: 1,
  },
};

const DatePickerInput = (props) => {
  const displayFormat = 'dd MMM yyyy';
  const returnDateFormat = 'yyyyMMdd';
  let defaultDate = isMobile ? new Date() : null;

  const [isOpen, setIsOpen] = useState(false);
  const [displayText, setDisplayText] = useState('');
  const [selectedDate, setSelectedDate] = useState(defaultDate);

  useEffect(() => {
    let initValue = isMobile ? new Date() : null;
    let initDisplayDate = '';
    if (props.value && props.value !== '') {
      if (props.value !== '99991231') {
        if (props.minDate || props.maxDate) {
          if (isBefore(defaultDate, props.minDate)) {
            defaultDate = props.minDate;
          } else if (isAfter(defaultDate, props.maxDate)) {
            defaultDate = props.maxDate;
          }
        }

        const tmpInitValue = parse(props.value, 'yyyyMMdd', new Date());
        if (!isValid(tmpInitValue)) {
          initValue = defaultDate;
        } else {
          initDisplayDate = format(tmpInitValue, displayFormat, { locale: th });
          initValue = tmpInitValue;
        }
      }
    } else {
      initValue = defaultDate;
    }

    setDisplayText(initDisplayDate);
    setSelectedDate(initValue);
  }, [props]);

  const updateValue = (value) => {
    let returnValue;
    let newDisplayText = '';
    if (isDateValid) {
      returnValue = value;
      newDisplayText = format(returnValue, displayFormat, { locale: th });
    }

    setSelectedDate(returnValue);
    setDisplayText(newDisplayText);

    if (props.onSelected) {
      props.onSelected(returnValue ? format(returnValue, returnDateFormat) : null);
    }
  };

  const onClickMobileInputHandle = () => {
    setIsOpen(true);
  };

  const onMobileSelectHandle = (value) => {
    updateValue(value);
    setIsOpen(false);
  };

  const onMobileCancelHandle = () => {
    setIsOpen(false);
  };

  const onChangeHandle = (value) => {
    updateValue(moment(value, 'DD MMM YYYY').toDate());
  };

  const isDateValid = () => {
    if (!selectedDate || displayText === '') {
      return false;
    }

    if (!isValid(selectedDate)) {
      return false;
    }

    if (props.minDate && isBefore(selectedDate, props.minDate)) {
      return false;
    }

    if (props.maxDate && isAfter(selectedDate, props.maxDate)) {
      return false;
    }

    return true;
  };

  return isMobile ? (
    <Fragment>
      <input
        className={`date-picker-input${isDateValid() ? ' valid' : ''}`}
        onClick={onClickMobileInputHandle}
        value={displayText}
        disabled={props.disabled}
        placeholder={props.placeholder}
        readOnly
        style={{borderBottomColor:displayText !='' ?'#003b66':'#ccc'}}
      />

      <DatePicker
        dateConfig={props.dateConfig ? props.dateConfig : mobileDatepickerConfig}
        headerFormat="DD-MM-YYYY"
        value={selectedDate}
        isOpen={isOpen}
        showHeader={false}
        confirmText={'ตกลง'}
        cancelText={'ยกเลิก'}
        onSelect={onMobileSelectHandle}
        onCancel={onMobileCancelHandle}
        min={props.minDate ? props.minDate : defaultMinDate}
        max={props.maxDate ? props.maxDate : defaultMaxDate}
      />
    </Fragment>
  ) : props.disabled ? (
    <input type="text" className="date-picker-input" disabled />
  ) : (
    <ModernDatepicker
      date={selectedDate}
      format={'DD MMM YYYY'}
      showBorder
      className={`date-picker-input desktop ${isDateValid() ? ' valid' : ''}`}
      lang="th"
      minDate={props.minDate ? moment(props.minDate) : moment(defaultMinDate)}
      maxDate={props.maxDate ? moment(props.maxDate) : moment(defaultMaxDate)}
      onChange={onChangeHandle}
    />
  );
};

export default DatePickerInput;
