import axios from "axios"
import React, { Component } from "react"
import "./crs_birth_address.css"
import Select2 from "react-select"
import { isMobile } from "react-device-detect"
import { IMaskInput } from "react-imask"
import { config } from "./../../constants"
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft"
import Loading from "../../components/loading/loading"
import { popupErrorApi, popupCrs } from "../../components/helpers/Helpers"

const _url = config.baseAPIURL03


class CrsBirthAddress extends Component {
  constructor(props) {
    super(props)
    let is_from_summary = props.location?.state?.is_from_summary || false

    this.state = {
      prop_is_from_summary: is_from_summary,
      countrys: [],
      provinces: [],
      input_birth_province: null,
      birth_province: null,
      birth_country: null,
      loading: true,
      is_show_province: true,
      next_btn_disable: true,
      prev_data: {},
    }
  }

  componentDidMount = async () => {
    this.loadDataFromLocalStorage()
    await this.setState({ loading: true })
    await this.setState({ next_btn_disable: true })
    await this.getCountrys()
  }

  goBack = () => {
    if (this.state.prop_is_from_summary) {
      if (this.is_data_change()) {
        popupCrs(
          "ยกเลิกการแก้ไข",
          "การแก้ไขยังไม่ได้ถูกบันทึก\nท่านต้องการออกจากหน้านี้หรือไม่",
          "ออกจากหน้านี้",
          "อยู่หน้านี้ต่อ",
          () => {
            this.props.history.push("summaryshow")
          }
        )
      } else {
        this.props.history.push("summaryshow")
      }
    } else {
      this.props.history.push("fatca")
    }  
  }

  loadDataFromLocalStorage = () => {
    let data = localStorage.getItem('crs_birth_address')
    if (data) {
      data = JSON.parse(data)
      this.setState({
        birth_country: data.birth_country,
        birth_province: data.birth_province,
        input_birth_province: data.input_birth_province,
        prev_data: data,
      })

      if (data.input_birth_province) {
        this.setState({is_show_province: false})
      }
    }
  }

  saveDataToLocalStorage = () => {
    let data = {
      birth_country: this.state.birth_country, 
      birth_province: this.state.birth_province, 
      input_birth_province: this.state.input_birth_province,
    }
    localStorage.setItem('crs_birth_address', JSON.stringify(data))
  }

  handleChange = async (selectedOption, e) => {
    if (e.name == 'birth_country' && this.state.birth_country && this.state.birth_country.value != selectedOption.value) {
      this.setState({
        input_birth_province: null,
        birth_province: null,
      })
    }

    await this.setState({ [e.name]: selectedOption })
    if (e.name == 'birth_country') {
      if (selectedOption && selectedOption.label == "ไทย") {
        this.setState({is_show_province: true})
      } else {
        this.setState({is_show_province: false})
      }
    }
  }

  handleChangeInputBirthProvince = async (value, mask) => {
    this.setState({input_birth_province: value})
  }

  getCountrys = async () => {
    await axios.get(_url + `/constant/country`)
      .then(async (res) => {
        let data = res.data.Data
        let thai_country = data.find(country => country.ThName == "ไทย")

        data = data.filter(country => country.ThName != "สหรัฐอเมริกา")
        let country_options = data.map(country => {
          return {
            value: country.Id,
            label: country.ThName
          }
        })
        
        this.setState({countrys: country_options})
        if (!this.state.birth_country) {
          this.setState({
            birth_country: {
              value: thai_country.Id,
              label: thai_country.ThName
            },
            is_show_province: true
          })
        }        

        await axios.get(_url + `/constant/province/${thai_country.Id}`)
          .then((res) => {
            let data = res.data.Data
            let province_options = data.map(provice => {
              return {
                value: provice.Id,
                label: provice.ThName,
                en_label: provice.EnName
              }
            })

            this.setState({provinces: province_options})
          })
          .catch(async (error) => {
            if (!!error.isAxiosError && !error.response) {
              title_mss = "เกิดข้อผิดพลาด"
              mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง"
            } else {
              var mss = ""
              var title_mss = ""
              if (!!error.isAxiosError && !error.response) {
                title_mss = "เกิดข้อผิดพลาด"
                mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง"
              } else {
                title_mss =
                  error.response.data.Data[0][0].MsgDescTitle !== ""
                    ? error.response.data.Data[0][0].MsgDescTitle
                    : "เกิดข้อผิดพลาด"
                mss =
                  error.response.data.Data[0][0].MsgDesc !== ""
                    ? error.response.data.Data[0][0].MsgDesc
                    : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง"
              }
            }
    
            popupErrorApi(mss, title_mss, true)
          })
      })
      .catch(async (error) => {
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด"
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง"
        } else {
          var mss = ""
          var title_mss = ""
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด"
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง"
          } else {
            title_mss =
              error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด"
            mss =
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง"
          }
        }

        popupErrorApi(mss, title_mss, true)
      })
    this.setState({ loading: false })
  }

  save_data = async () => {
    let header_manp = JSON.parse(localStorage.getItem('HeaderManp'))
    let crs_birth_address = JSON.parse(localStorage.getItem('crs_birth_address'))
    let crs_declare_location = JSON.parse(localStorage.getItem('crs_declare_location'))
    let crs_json = crs_declare_location.tax_country_list.map(x => {
      return {
        SAffcc_CNT_TaxResidence: x.country.countryId.toString(),
        SAffcc_TIN: x.tax_no ? x.tax_no : "",
        SAffcc_Mrnt_Id: x.no_tax_choice ? x.no_tax_choice.toString() : "0",
        SAffcc_Explain: x.reason_choice_2 ? x.reason_choice_2 : ""
      }
    })

    let post_data = {
      head_token: header_manp.head_token,
      f0_city_of_birth: crs_birth_address.input_birth_province ? crs_birth_address.input_birth_province : crs_birth_address.birth_province.en_label,
      f0_country_of_birth: crs_birth_address.birth_country.value,
      f0_us_citizen: "false".toString(),
      f0_non_us_citizen: "true".toString(),
      f0_crs_tax_detail: JSON.stringify(crs_json)
    }

    let config = {
      headers: {
          "Content-Type": "application/json",
      },
    }
  
    return await axios.post(_url + `/form/crs/manp`, post_data, config)
      .then(resp => {
        return true
      })
      .catch(async (error) => {
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด"
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง"
        } else {
          var mss = ""
          var title_mss = ""
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด"
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง"
          } else {
            title_mss =
              error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด"
            mss =
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง"
          }
        }

        popupErrorApi(mss, title_mss, true)
        return false
      })
  }

  handleSubmit = async () => {
    this.saveDataToLocalStorage()
    if (this.state.prop_is_from_summary) {
      let saved = await this.save_data()
      if (saved) {
        this.props.history.push("summaryshow")
      }
    } else {
      this.props.history.push("crs_declare_location")
    }
  }

  is_btn_enable = () => {
    if (this.state.prop_is_from_summary) {
      return this.is_data_valid() && this.is_data_change()
    } else {
      return this.is_data_valid()
    }
  }

  is_data_valid = () => {
    return this.state.birth_country && (
      this.state.birth_province || this.state.input_birth_province
    )
  }

  is_data_change = () => {
    return (
      (this.state.prev_data.birth_country?.label != this.state.birth_country?.label) || 
      (this.state.prev_data.birth_province?.label != this.state.birth_province?.label) || 
      (this.state.prev_data.input_birth_province != this.state.input_birth_province)
    )
  }

  render() {
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          alt="forward"
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">สถานะเป็นผู้มีถิ่นที่อยู่ทางภาษี</div>
                    </div>
                    <div className="bg_content_white" style={{ paddingTop: 0 }}>
                      <div
                        className="in_bg_white"
                        style={{ top: 0, bottom: 100, right: 0, paddingRight: '21px', overflow: 'auto' }}
                      >
                        <div className="title_row">สถานที่เกิด</div>
                        <div className="row_idcard crs_birth_address_row_idcard">
                          <div>ประเทศที่เกิด</div>
                          <Select2
                            name="birth_country"
                            options={this.state.countrys}
                            value={this.state.birth_country}
                            onChange={this.handleChange}
                            placeholder="พิมพ์เพื่อค้นหาและเลือก"
                            className="select_two crs_birth_address_select_two"
                            // isClearable={true}
                          />
                          <span className="span_err_country">
                            กรุณาระบุประเทศ
                          </span>
                        </div>

                        {this.state.is_show_province ? (
                          <div className="row_idcard crs_birth_address_row_idcard">
                            <div>จังหวัด</div>
                            <Select2
                              name="birth_province"
                              options={this.state.provinces}
                              value={this.state.birth_province}
                              onChange={this.handleChange}
                              placeholder="ตัวอย่าง กรุงเทพมหานคร"
                              // isClearable={true}
                              className="select_two crs_birth_address_select_two"
                            />
                            <span className="span_err_province">
                              กรุณาระบุจังหวัด
                            </span>
                          </div>
                        ) : (
                          <div className="row_idcard crs_birth_address_row_idcard">
                            <div>เมืองที่เกิด (ภาษาอังกฤษเท่านั้น)</div>
                            <IMaskInput
                              mask={/^[A-Za-z.\s\-]+$/}
                              value={this.state.input_birth_province}
                              unmask={true}
                              placeholder="ตัวอย่าง Seoul"
                              className="input_idcard"
                              name="input_birth_province"
                              onAccept={this.handleChangeInputBirthProvince}
                              maxLength={200}
                            />
                            <span className="span_err_input_province">
                              กรุณาระบุเมืองที่เกิด
                            </span>
                          </div>
                        )}
                      </div>
                      <div
                        className="under_fix_btn"
                        style={{ background: "#fff" }}
                      >
                        <button 
                          className="crs_birth_address_next_btn" 
                          style={!this.is_btn_enable() ? {backgroundColor: '#e0e1e2', color: '#b0b0b0'} : {backgroundColor: '#003b66', color: 'white'}} 
                          onClick={this.handleSubmit}
                          disabled={!this.is_btn_enable()}
                        >{this.state.prop_is_from_summary ? 'บันทึกการแก้ไข' : 'ถัดไป'}</button>
                      </div>
                    </div>
                  </div>
                  <Loading status={this.state.loading}></Loading>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
          
        </section>
      </div>
    )
  }
}

export default CrsBirthAddress