const homeAddress = {
  country_name: '',
  district_name: '',
  f2_home_address: '',
  f2_home_country: '',
  f2_home_province: '',
  f2_home_district: '',
  f2_home_subdistrict: '',
  f2_home_zipcode: '',
  f2_home_no: '',
  f2_home_moo: '',
  f2_home_building: '',
  f2_home_floor: '',
  f2_home_road: '',
  province_name: '',
  sub_district_name: '',
};

export default homeAddress;
