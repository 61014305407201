import React, { Component } from "react";
import "./SplashscreenOa.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import localForage from "localforage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { popupErrorApi } from "../../components/helpers/Helpers";
import { config } from "./../../constants";
import Loading from "../../components/loading/loading";
import Cookies from "universal-cookie";
import queryString from "query-string";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";

const _url = config.baseAPIURL03;
class SplashscreenOa extends Component {
  constructor(props) {
    super(props);
    const url = this.props.location.search;
    const params = queryString.parse(url);
    const aecode = params.aecode ? params.aecode : "";
    this.state = {
      productNames: [],
      productNames1: [],
      content_li: [],
      isDisconnected: false,
      loading: false,
      aecode: aecode,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    await this.setState({
      loading: true,
    });
    this.props.history.push("/preregis");

    // if (checkInternet()) {
    //   this.props.history.push('/preregis');
    // } else {
    //   this.setState({
    //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
    //   });
    //   await this.setState({
    //     loading: false,
    //   });
    // }
  };

  async componentDidMount() {
    const cookies = new Cookies();
    let expires = new Date();
    expires.setTime(expires.getTime() + 10 * 60 * 1000);
    cookies.set("aecode", this.state.aecode, { path: "/", expires });
    // console.log(cookies.get('aecode'));

    await this.setState({
      loading: true,
    });
    await localStorage.clear();
    await localForage
      .clear()
      .then(function () {
        // Run this code once the database has been entirely deleted.
        console.log("Database is now empty.");
      })
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await axios
      .get(_url + `/constant/product_group`)
      .then((res) => {
        this.setState({ productNames: res.data.Data });
        const arr_acc0 = this.state.productNames;
        var unique = arr_acc0.filter(
          (
            (set) => (f) =>
              !set.has(f.SAmpd_ThGroup) && set.add(f.SAmpd_ThGroup)
          )(new Set())
        );
        var obj = unique.sort(this.compare);
        // console.log(obj);
        this.setState({ content_li: obj });
      })
      .catch((error) => {
        var mss = "";
        var title_mss = "";
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss =
            error.response.data.Data !== undefined &&
              error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data !== undefined &&
              error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
        popupErrorApi(
          mss,
          title_mss,
          true
          // function name() {
          //   var myStyle = document.getElementsByClassName("main_popup_loading")[0].style.display;
          //   if(myStyle === 'none'){
          //     document.getElementsByClassName("main_popup_loading")[0].style.display = 'block';
          //   }
          //   window.location = "login";
          // }
        );
      });
    await this.setState({
      loading: false,
    });
  }

  compare = (a, b) => {
    if (a.SAmpd_Active < b.SAmpd_Active) {
      return 1;
    }
    if (a.SAmpd_Active > b.SAmpd_Active) {
      return -1;
    }
    return 0;
  };

  alert_info = (mss, page) => {
    const MySwal = withReactContent(swal);
    if (mss !== "") {
      MySwal.fire({
        // icon: 'warning',
        customClass: {
          confirmButton: "long_btn_fix",
          cancelButton: "long_btn_fix",
        },
        confirmButtonColor: "#01375f",
        confirmButtonText: "กลับ",
        title:
          '<div class="title_popup">เปิดบัญชีครั้งเดียวลงทุนได้ทุกผลิตภัณฑ์</div>',
        html: '<div class="content_popup">ปัจจุบันบริษัทอนุมัติการเปิดบัญชีผ่านออนไลน์เฉพาะบริการซื้อขายหลักทรัพย์ และกองทุนรวม สำหรับบริการประเภทอื่น โปรดติดต่อผู้แนะนำการลงทุนของท่าน <br>หรือ DAOL Contact Center 0 2351 1800</div>',
      }).then(function () {
        if (page) {
          // window.location = page;
          // this.props.history.push(page);
        }
        // window.location = "summaryshow";
      });
    }
  };

  render() {
    library.add(fas);
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" alt="logo" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>

          {/* <div
            className="everest-nav-container"
            style={{ display: isMobile ? "none" : "block" }}
          >
            <div className="site-branding">
              <img src="../../../images/logo.png" />
              <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
            </div>
          </div> */}
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                {/* <div className={"banner-left"}>
                  เปิดบัญชีออนไลน์ ยืนยันตัวตนด้วย National
                  <br />
                  Digital ID (NDID)
                </div>
                <p className={"banner-left-p"}>
                  เปิดบัญชีเพื่อใช้บริการของ KTBST SEC ผ่านช่องทาง
                  <br />
                  ออนไลน์ ยืนยันตัวตนผ่านระบบ NDID สะดวก รวดเร็ว
                  <br />
                  ปลอดภัย ไม่ต้องจัดส่งเอกสาร เปิดบัญชีและเทรด
                  <br />
                  ได้ทันที
                </p>
                <p className={"banner-left-tel"}>
                  ฝ่ายบริการลูกค้า 02 351 1800
                </p> */}
                <BannerContentLeft />
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  {/* <NetworkChecker isDisconnected={this.state.isDisconnected} /> */}
                  <Loading status={this.state.loading}></Loading>
                  <div className="content">
                    <div className="bg_content_white over_f_hide">
                      <div
                        className="in_bg_white " //autoscroll"
                        style={{
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 100,
                          // padding: "24px 21px 0px",
                        }}
                      >
                        <img
                          src={
                            isMobile
                              ? "/images/NDID-02.png"
                              : "/images/NDID-01.png"
                          }
                          alt=""
                          className="img_openaccMax"
                        />
                        {/*
                      }
                        <img
                          src="/images/open_account.svg"
                          alt=""
                          className="img_openacc"
                        />
                        <div className="title_openacc">
                          เปิดบัญชีครั้งเดียวลงทุนได้ทุกผลิตภัณฑ์
                          <div onClick={this.alert_info}>
                            <FontAwesomeIcon icon="info-circle" />
                          </div>
                        </div>
                        {/* SAmpd_CanJoin: 0
                        SAmpd_DerivativeLicense: 0
                        SAmpd_EnGroup: "Securities Trading Account"
                        SAmpd_EnName: "Cash"
                        SAmpd_Id: 1
                        SAmpd_SAmivt_Id: 1
                        SAmpd_Seq: 1
                        SAmpd_SingleLicense: 1
                        SAmpd_ThGroup: "บัญชีซื้อขายหลักทรัพย์"
                        SAmpd_ThName: "Cash" */}
                        {/*
                      }
                        {this.state.content_li.map((item, i) => {
                          if (item.SAmpd_Active === 1) {
                            return (
                              <div key={i} style={{ marginTop: 12 }}>
                                <div className="oa_margin">
                                  • <span>{item.SAmpd_ThGroup}</span>
                                </div>
                                <div
                                  className="sub_list"
                                  dangerouslySetInnerHTML={{
                                    __html: item.SAmpd_OnlineDescription,
                                  }}
                                />
                              </div>
                            );
                          }
                        })}
                        <div className="title_oa_non">
                          บริการดังนี้ไม่สามารถใช้กับบัญชีออนไลน์ได้
                        </div>
                        {this.state.content_li.map((item, i) => {
                          if (item.SAmpd_Active === 0) {
                            return (
                              <div
                                key={i}
                                className="oa_margin"
                                style={{ color: "#58595b", opacity: 0.4 }}
                              >
                                • <span>{item.SAmpd_ThGroup}</span>
                              </div>
                            );
                          }
                        })}
                      */}
                      </div>
                      <div className="under_fix_btn">
                        <ButtonCustom
                          handle={this.handleSubmit}
                          label="ถัดไป"
                          color="blue"
                          btnClass="long_btn"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default SplashscreenOa;
