import React, { Component } from "react";
import "./NdidOfflineChoice.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { css } from "@emotion/core";
import Lottie from "react-lottie";
import * as animationData from "../../components/loading/loading_idp.json";
import { config } from "./../../constants";
import Loading from "../../components/loading/loading";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
const _url = config.baseAPIURL03;
var CheckStatusIdPVerify;
const override = css`
  display: block;
  margin: 0 auto;
  border: 5px rgb(54, 215, 183) solid;
  border-bottom-color: #ffffff;
`;

class NdidOfflineChoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BankID: localStorage.getItem("BankID"),
      NDID_Status: "LOADING",
      NDID_Status_Display: "LOADING",
      NDIDRequestId: "",
      ReferenceId: "",
      RequestMessage: "",
      isStopped: false,
      isPaused: false,
      loading: true,
      isCancel: false,
      cancelled_by_user: false,
      countCancel: 0,
      ErrorMessage: "",
      ApplicationName: "",
    };
    this.goBack = this.goBack.bind(this);
    // localStorage.removeItem('HeaderManp');
  }
  allStorage() {
    var values = [],
      keys = Object.keys(localStorage),
      i = keys.length;
    while (i--) {
      if (keys[i].indexOf("debug") !== -1) {
      } else {
        values.push({
          key: keys[i],
          value: localStorage.getItem(keys[i]),
        });
      }
    }
    return values;
  }
  async componentDidMount() {
    const BankID = await localStorage.getItem("BankID");
    const BankName = await localStorage.getItem("BankName");
    const countCancel = await localStorage.getItem("countCancel");
    let ApplicationName = this.state.ApplicationName;
    if (BankName.indexOf("ธนาคารกสิกร") !== -1) {
      ApplicationName = "K PLUS Application";
    } else if (BankName.indexOf("ธนาคารไทยพาณิชย์") !== -1) {
      ApplicationName = "SCB Easy Application";
    } else if (BankName.indexOf("ธนาคารกรุงศรี") !== -1) {
      ApplicationName = "KMA Application";
    } else if (BankName.indexOf("ธนาคารกรุงเทพ") !== -1) {
      ApplicationName = "BualuangM Banking Application";
    } else if (BankName.indexOf("ธนาคารกรุงไทย") !== -1) {
      ApplicationName = "NEXT Application";
    }
    await this.setState({
      BankID: BankID,
      BankName: BankName,
      countCancel: countCancel || 0,
      ApplicationName: ApplicationName,
    });
    const ListIdP = this.state.BankID;
    const Identifier = localStorage.getItem("identifier");
    const token = localStorage.getItem("token");
    const vm = this;
    const debug = JSON.parse(localStorage.getItem("debug"));
    if (debug && Identifier) {
      const NDIDRequestId =
        "e1cad2bbab2edd43dffeed2c0a987880b535d9a0c06668a0cbca94ad4e8a06a3";
      const ReferenceId = "FA368742-5713-4321-8B5B-009F5C94CF2D";
      const RequestMessage =
        "ท่านกำลังยืนยันตัวตนเพื่อใช้ตามวัตถุประสงค์ของบริษัทหลักทรัพย์ เคทีบีเอสที จำกัด (มหาชน) และประสงค์ให้ส่งข้อมูลจาก ทดสอบ 2 (Transaction Ref:dabde571)";
      this.setState({
        NDIDRequestId: NDIDRequestId,
        ReferenceId: ReferenceId,
        RequestMessage: RequestMessage,
        loading: false,
      });
      localStorage.setItem("NDIDRequestId", NDIDRequestId);
      localStorage.setItem("ReferenceId", ReferenceId.toUpperCase());
      localStorage.setItem("RequestMessage", RequestMessage);
      this.check_status(ReferenceId.toUpperCase());
      return;
    }
    const axiosConfig = {
      headers: {
        Accept: "application/json",
      },
    };
    await axios
      .post(
        `${config.baseAPIURL06}/ndid/VerifyIdentityRequest`,
        {
          Identifier: Identifier,
          ListIdP: ListIdP,
          token: token,
          consent_ndid: true,
        },
        axiosConfig
      )
      .then(async (res) => {
        const data = res.data.data[0];
        const NDIDRequestId = data.NDIDRequestId;
        const ReferenceId = data.ReferenceId;
        const RequestMessage = data.RequestMessage;
        await this.setState({
          NDIDRequestId: NDIDRequestId,
          ReferenceId: ReferenceId,
          RequestMessage: RequestMessage,
          loading: false,
        });
        await localStorage.setItem("NDIDRequestId", NDIDRequestId);
        await localStorage.setItem("ReferenceId", ReferenceId.toUpperCase());
        await localStorage.setItem("RequestMessage", RequestMessage);
        if (res.data.IsSuccess) {
          await vm.check_status(ReferenceId.toUpperCase());
        } else {
          vm.notPassNDID(
            "สอบถามข้อมูลเพิ่มเติมกรุณาติดต่อ DAOL Contact Center<br>0 2351 1800"
          );
        }
      })
      .catch((error) => {
        this.setState({ loading: false });

        if (
          error.response &&
          error.response.data &&
          error.response.data.MsgDesc &&
          error.response.data.MsgDesc.message
        ) {
          vm.notPassNDID(error.response.data.MsgDesc.message);
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.MsgDesc
        ) {
          vm.notPassNDID(error.response.data.MsgDesc);
        } else {
          vm.notPassNDID(
            "สอบถามข้อมูลเพิ่มเติมกรุณาติดต่อ DAOL Contact Center<br>0 2351 1800",
            "เกิดข้อผิดพลาด"
          );
        }
        return clearInterval(CheckStatusIdPVerify);
      });
  }
  componentWillUnmount() {
    return clearInterval(CheckStatusIdPVerify);
  }
  async check_status(ReferenceId) {
    const vm = this;
    CheckStatusIdPVerify = setInterval(async function () {
      await axios
        .post(`${config.baseAPIURL06}/ndid/CheckStatusIdPVerify`, {
          ReferenceId: ReferenceId,
        })
        .then(async (res) => {
          const NDID_Status = res.data.data[0].Status.toUpperCase();
          const ErrorMessage = res.data.data[0].ErrorMessage
            ? res.data.data[0].ErrorMessage
            : "";
          const statusArr = {
            LOADING: ["LOADING", "LOADING"],
            CREATED: [
              "รอผลการยืนยันจากผู้ให้บริการ NDID",
              "รอผลการยืนยันจากผู้ให้บริการ NDID",
            ],
            PENDING: [
              "รอผลการยืนยันจากผู้ให้บริการ NDID",
              "รอผลการยืนยันจากผู้ให้บริการ NDID",
            ],
            ACCEPTED: [
              "การยืนยันตัวตนสำเร็จ",
              "กรุณารอการ Activate บัญชี ซึ่งบริษัทจะแจ้งผลให้ทราบทางอีเมล สอบถามข้อมูลเพิ่มเติมกรุณาติดต่อผู้แนะนำการลงทุนของท่าน หรือ DAOL Group Contact Center 0 2351 1800",
            ],
            IDP_OR_AS_ERROR: [
              "ขออภัย<br> การยืนยัน NDID ของท่าน ไม่สำเร็จ",
              ErrorMessage,
            ],
            REJECTED: [
              "ขออภัย<br> การยืนยัน NDID ของท่าน ไม่สำเร็จ",
              "ท่านได้<ins>ปฏิเสธ</ins>การยืนยันตัวตนของผู้ให้บริการยืนยันตัวตนที่คุณเลือก กรุณาทำรายการใหม่",
            ],
            CANCELLED_USER: [
              "",
              "คุณได้ปฏิเสธการยืนยันตัวตนของผู้ให้บริการยืนยันตัวตนที่คุณเลือก กรุณาทำรายการใหม่",
            ],
            CANCELLED: [
              "ขออภัย<br> การยืนยัน NDID ของท่าน ไม่สำเร็จ",
              "ท่านได้<ins>ปฏิเสธ</ins>การยืนยันตัวตนของผู้ให้บริการยืนยันตัวตนที่คุณเลือก กรุณาทำรายการใหม่",
            ],
            REQUESTED_ERROR: [
              "",
              "ท่านไม่สามารถทำรายการได้ในขณะนี้ กรุณาทำรายการใหม่<ins>หรือเลือกผู้ให้บริการยืนยันตัวตนรายอื่น</ins>",
            ],
            TIMEOUT: [
              "",
              "ท่านไม่สามารถทำรายการได้ในขณะนี้ กรุณาทำรายการใหม่<ins>หรือเลือกผู้ให้บริการยืนยันตัวตนรายอื่น</ins>",
            ],
          };

          if (NDID_Status === "CANCELLED") {
            if (res.data.data[0].cancelled_by_user) {
              await vm.setState({
                NDID_Status: NDID_Status,
                NDID_Status_Display: statusArr["CANCELLED_USER"][1],
                cancelled_by_user: true,
                ErrorMessage: ErrorMessage,
              });
            } else {
              await vm.setState({
                NDID_Status: NDID_Status,
                NDID_Status_Display: statusArr["CANCELLED"][1],
                cancelled_by_user: false,
                ErrorMessage: ErrorMessage,
              });
            }
          } else {
            await vm.setState({
              NDID_Status: NDID_Status,
              NDID_Status_Display: statusArr[NDID_Status][1],
              cancelled_by_user: false,
              ErrorMessage: ErrorMessage,
            });
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.MsgDesc &&
            error.response.data.MsgDesc.message
          ) {
            vm.notPassNDID(error.response.data.MsgDesc.message);
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.MsgDesc
          ) {
            vm.notPassNDID(error.response.data.MsgDesc);
          } else {
            vm.notPassNDID(
              "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง",
              "เกิดข้อผิดพลาด"
            );
          }
          return clearInterval(CheckStatusIdPVerify);
        });
      vm.Tick();
    }, 5000);
  }
  goBack() {
    clearInterval(CheckStatusIdPVerify);
    window.clearInterval(CheckStatusIdPVerify);
    this.props.history.goBack();
  }
  async Tick() {
    const NDID_Status = this.state.NDID_Status.toUpperCase();
    const ErrorMessage = this.state.ErrorMessage;
    const statusArr = {
      LOADING: ["LOADING", "LOADING"],
      CREATED: [
        "รอผลการยืนยันจากผู้ให้บริการ NDID",
        "รอผลการยืนยันจากผู้ให้บริการ NDID",
      ],
      PENDING: [
        "รอผลการยืนยันจากผู้ให้บริการ NDID",
        "รอผลการยืนยันจากผู้ให้บริการ NDID",
      ],
      ACCEPTED: [
        "การยืนยันตัวตนสำเร็จ",
        "กรุณารอการ Activate บัญชี ซึ่งบริษัทจะแจ้งผลให้ทราบทางอีเมล สอบถามข้อมูลเพิ่มเติมกรุณาติดต่อผู้แนะนำการลงทุนของท่าน หรือ DAOL Group Contact Center 0 2351 1800",
      ],
      IDP_OR_AS_ERROR: [
        "ขออภัย<br> การยืนยัน NDID ของท่าน ไม่สำเร็จ",
        ErrorMessage,
      ],
      REJECTED: [
        "ขออภัย<br> การยืนยัน NDID ของท่าน ไม่สำเร็จ",
        "ท่านได้<ins>ปฏิเสธ</ins>การยืนยันตัวตนของผู้ให้บริการยืนยันตัวตนที่คุณเลือก กรุณาทำรายการใหม่",
      ],
      CANCELLED_USER: [
        "",
        "คุณได้ปฏิเสธการยืนยันตัวตนของผู้ให้บริการยืนยันตัวตนที่คุณเลือก กรุณาทำรายการใหม่",
      ],
      CANCELLED: [
        "ขออภัย<br> การยืนยัน NDID ของท่าน ไม่สำเร็จ",
        "ท่านได้<ins>ปฏิเสธ</ins>การยืนยันตัวตนของผู้ให้บริการยืนยันตัวตนที่คุณเลือก กรุณาทำรายการใหม่",
      ],
      REQUESTED_ERROR: [
        "",
        "ท่านไม่สามารถทำรายการได้ในขณะนี้ กรุณาทำรายการใหม่<ins>หรือเลือกผู้ให้บริการยืนยันตัวตนรายอื่น</ins>",
      ],
      TIMEOUT: [
        "",
        "ท่านไม่สามารถทำรายการได้ในขณะนี้ กรุณาทำรายการใหม่<ins>หรือเลือกผู้ให้บริการยืนยันตัวตนรายอื่น</ins>",
      ],
    };

    if (NDID_Status === "ACCEPTED") {
      window.clearInterval(CheckStatusIdPVerify);
      await this.setState({ loading: false });
      // await localStorage.setItem("passNDID", true);
      await this.passNDID(statusArr[NDID_Status][0], statusArr[NDID_Status][1]);
      return clearInterval(CheckStatusIdPVerify);
    }

    if (NDID_Status === "CANCELLED") {
      window.clearInterval(CheckStatusIdPVerify);
      this.setState({ loading: false });
      if (this.state.cancelled_by_user) {
        this.notPassNDID(
          statusArr["CANCELLED_USER"][1],
          statusArr["CANCELLED_USER"][0]
        );
      } else {
        this.notPassNDID(statusArr[NDID_Status][1], statusArr[NDID_Status][0]);
      }
      return clearInterval(CheckStatusIdPVerify);
    }

    if (
      NDID_Status === "IDP_OR_AS_ERROR" ||
      NDID_Status === "REJECTED" ||
      NDID_Status === "REQUESTED_ERROR" ||
      NDID_Status === "TIMEOUT"
    ) {
      window.clearInterval(CheckStatusIdPVerify);
      this.setState({ loading: false });
      this.notPassNDID(statusArr[NDID_Status][1], statusArr[NDID_Status][0]);
      return clearInterval(CheckStatusIdPVerify);
    }
  }
  notPassNDID = (msg, title = "") => {
    const MySwal = withReactContent(swal);
    const vm = this;
    MySwal.fire({
      customClass: {
        confirmButton: "long_btn_fix",
        cancelButton: "long_btn_fix",
      },
      imageUrl: "/images/ico_alert.svg",
      imageWidth: 100,
      imageHeight: 100,
      confirmButtonColor: "#003b66",
      confirmButtonText: "กลับ",
      title: `<span class="title_popup">${title}</span>`,
      html: `<div class="content_popup">${msg}</div>`,
    }).then(function () {
      vm.props.history.push("/ndid_list");
    });
  };
  passNDID = async (msg, title = "") => {
    const MySwal = withReactContent(swal);
    const vm = this;
    MySwal.fire({
      customClass: {
        confirmButton: "long_btn_fix",
        cancelButton: "long_btn_fix",
      },
      imageUrl: "/images/ico_approve.svg",
      imageWidth: 100,
      imageHeight: 100,
      confirmButtonColor: "#003b66",
      confirmButtonText: "ถัดไป",
      title: `<span class="title_popup">${msg}</span>`,
      html: `<div class="content_popup">${title}</div>`,
    }).then(function () {
      vm.setState({ loading: false });
      window.location = 'https://www.daolinvestment.co.th/';
      return
      vm.setState({ loading: true });
      var last_stamp = JSON.stringify(vm.allStorage());
      const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
      const head_token = HeaderManp.head_token;
      const token = HeaderManp.head_token;
      const ops = {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
        data: {
          last_stamp: last_stamp,
          head_token: head_token,
          stamp_page: "NDID",
        },
        url: _url + "/form/comeback/manp",
      };
      axios(ops)
        .then(async (res) => {
          vm.setState({ loading: false });
          vm.props.history.push("/ndid_list");
        })
        .catch((error) => {
          vm.setState({ loading: false });
          vm.notPassNDID(
            "สอบถามข้อมูลเพิ่มเติมกรุณาติดต่อ DAOL Contact Center<br>0 2351 1800",
            "ไม่สามารถทำรายการได้"
          );
        });
    });
  };
  sendCancel = async () => {
    // clearInterval(CheckStatusIdPVerify);
    const MySwal = withReactContent(swal);
    let countCancel = parseInt(this.state.countCancel);
    MySwal.fire({
      customClass: {
        confirmButton: "btn_ndid_white",
        cancelButton: "btn_ndid_cancel",
      },
      imageUrl: "/images/ico_alert.svg",
      imageWidth: 100,
      imageHeight: 100,
      showCancelButton: true,
      confirmButtonText: "ไม่ต้องการยกเลิกเชื่อมต่อ",
      cancelButtonText: "ยกเลิกการเชื่อมต่อ",
      allowOutsideClick: false,
      allowEscapeKey: false,
      title: `<span class="title_popup">ยืนยันการยกเลิก</span>`,
      html:
        countCancel === 3
          ? `<div class="content_popup">ท่านดำเนินการยกเลิก 3 ครั้ง กรุณาตรวจสอบที่แอปพลิเคชั่นของธนาคารก่อนคลิกยกเลิกการเชื่อมต่อ</div>`
          : `<div class="content_popup">ท่านสามารถดำเนินการได้ 3 ครั้ง กรุณาตรวจสอบที่แอปพลิเคชั่นของธนาคารก่อนคลิกยกเลิกการเชื่อมต่อ</div>`,
    }).then(async (result) => {
      if (result.isConfirmed) {
      } else {
        this.setState({ isCancel: true, loading: true });
        const ops = {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
          },
          data: { ReferenceId: this.state.ReferenceId },
          url: `${config.baseAPIURL06}/ndid/CloseRequest`,
        };
        axios(ops)
          .then(async (res) => {})
          .catch((error) => {});
      }
    });
  };
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  render() {
    const { ApplicationName, loading } = this.state;
    // const defaultOptions = {
    //   loop: true,
    //   autoplay: true,
    //   animationData: animationData.default,
    //   rendererSettings: {
    //     preserveAspectRatio: "xMidYMid slice",
    //   },
    // };
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <Loading status={loading} />
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back"></div>
                      <div className="text_title">ยืนยันตัวตนรูปแบบดิจิทัล</div>
                    </div>

                    <div
                      className="bg_content_white over_f_hide"
                      style={{ padding: "0" }}
                    >
                      <div
                        className="in_bg_white autoscroll"
                        style={{ top: 0, left: 0, right: 0 }}
                      >
                        <div className="botton_text_ndid">
                          กรุณาทำรายการยืนยันตัวตนที่
                          <br />
                          {ApplicationName !== ""
                            ? ApplicationName
                            : "APPLICATION"}
                          <br />
                          {ApplicationName !== ""
                            ? ""
                            : "ของผู้ให้บริการยืนยันตัวตนที่ท่านเลือก"}
                        </div>
                        {/* <div
                          className="sweet-loading"
                          style={{ margin: "24px 0" }}
                        >
                          <Lottie
                            options={defaultOptions}
                            height={70}
                            width={70}
                            isStopped={!this.state.loading}
                            isPaused={this.state.isPaused}
                          />
                        </div> */}

                        <div className="main_circle_detail_idp_ndid">
                          <div className="circle_detail_idp_row">
                            <div className="title_idp">NDID Status</div>
                            <div
                              className="detail_idp"
                              dangerouslySetInnerHTML={{
                                __html: this.state.NDID_Status_Display,
                              }}
                            ></div>
                          </div>
                          <div className="circle_detail_idp_row">
                            <div className="title_idp">NDID Request ID</div>
                            <div className="detail_idp">
                              {this.state.NDIDRequestId}
                            </div>
                          </div>
                          <div className="circle_detail_idp_row">
                            <div className="title_idp">Reference ID</div>
                            <div className="detail_idp">
                              {this.state.ReferenceId}
                            </div>
                          </div>
                          <div className="circle_detail_idp_row">
                            <div className="title_idp">Request Message</div>
                            <div className="detail_idp">
                              {this.state.RequestMessage}
                            </div>
                          </div>
                        </div>
                      </div>

                      {this.state.ReferenceId &&
                        this.state.NDID_Status != "" &&
                        this.state.NDID_Status != "ACCEPTED" &&
                        //  this.state.NDID_Status != 'LOADING' &&
                        this.state.NDID_Status != "CREATED" && (
                          <div
                            id="sendCancel"
                            className="under_fix_btn"
                            onClick={
                              this.state.isCancel ? null : this.sendCancel
                            }
                          >
                            <ButtonCustom
                              label="ยกเลิกการเชื่อมต่อ"
                              color={this.state.isCancel ? "gray" : "red"}
                              btnClass="long_btn"
                            ></ButtonCustom>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default NdidOfflineChoice;
