import React, { Component } from 'react';
import ButtonCustom from '../../components/button/ButtonCustom';
import './LegelAgeChildren.css';

class LegelAgeChildren extends Component {

    
    constructor(props){
        super(props);
        this.state = {
            color_long_btn_chk_american_no: "",
            color_long_btn_chk_american_yes: "",
            CheckAmerican: true
        }
        this.goBack = this.goBack.bind(this); 
     }
     
     goBack(){
         this.props.history.goBack();
     }
     Click_no = () => {
        if (this.state.color_long_btn_chk_american_no === "") {
            this.setState({
                color_long_btn_chk_american_no: "green-l",
                CheckAmerican: false
            });
            if (this.state.color_long_btn_chk_american_yes === "green-l") {
                this.setState({
                    color_long_btn_chk_american_yes: "",
                });
            }
        }
    }
    Click_yes = () => {
        if (this.state.color_long_btn_chk_american_yes === "") {
            this.setState({
                color_long_btn_chk_american_yes: "green-l",
                CheckAmerican: true
            });
            if (this.state.color_long_btn_chk_american_no === "green-l") {
                this.setState({
                    color_long_btn_chk_american_no: "",
                })
            }
        }
    }
    nextpage = (e) =>{
        e.preventDefault();
        if (this.state.color_long_btn_chk_american_yes === "" && this.state.color_long_btn_chk_american_no === "") {
            return false;
        }
        if (this.state.CheckAmerican) {
            this.props.history.push('/EmergencyContact');
        }else{
            this.props.history.push('/StatusChildren');
        }
    }
    render() {
        return (
            <div className="Page">
                <div className='top_main_back'>
                    <div className="main_back" onClick={this.goBack}>
                        <img src='/images/angle-arrow-left.svg'  alt="forward" style={{ marginLeft: '3px' }} className='arrow_back' />
                    </div>
                    <div className='text_title'>สถานภาพทางครอบครัว</div>
                </div>
            
            <div className='DivBodyUserInfo'>
                <div className='DBHeaventMed' style={{ color: '#000', fontSize: 24, marginBottom:24, fontWeight: 'bold', marginTop: 8 }}>คุณมีบุตรที่ยังไม่บรรลุนิติภาวะหรือไม่ ?</div>
                    <ButtonCustom handle={this.Click_no} label='มี' color={this.state.color_long_btn_chk_american_no} btnClass='BtnChoice_' />
                    <ButtonCustom handle={this.Click_yes} label='ไม่มี' color={this.state.color_long_btn_chk_american_yes} btnClass='BtnChoice_' />
                <div className="under_fix_btn">
                    <ButtonCustom handle={this.nextpage} label="ถัดไป" color="blue" btnClass="btn_investment" iconDirection="R" icon="angle-right" ></ButtonCustom>
                </div>
               
              
            </div>
        </div>
        );
    }
}

export default LegelAgeChildren;