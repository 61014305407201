import React, { Component } from 'react';
import './PopupPhoto.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class PopupPhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btn0: true,
      btn1: false,
      btn2: false,
      state_show: false,
    };
  }

  render() {
    const { showSigned, showTakePhoto, showUpload } = this.props;
    // console.log(showSigned, showTakePhoto, showUpload);

    library.add(fas);
    return (
      <div className="main_popup" style={{ display: this.props.status ? 'block' : 'none' }}>
        <div className="background_opacity" onClick={this.props.onClick}></div>

        <div className="contant_pop_up">
          {showSigned && (
            <div className="btn_popup_photo active" onClick={this.props.onClick_1}>
              <div style={{ display: 'flex' }}>
                <div className="icon_popup">
                  <FontAwesomeIcon icon="file-signature"></FontAwesomeIcon>
                </div>
                เซ็นด้วยตัวเอง
              </div>
            </div>
          )}

          {showUpload && (
            <div className="btn_popup_photo" onClick={this.props.onClick_2}>
              <div style={{ display: 'flex' }}>
                <div className="icon_popup">
                  <FontAwesomeIcon icon="file-image"></FontAwesomeIcon>
                </div>
                อัปโหลดภาพ
              </div>
              <div className="last_taxt_photo">ไฟล์ไม่เกิน 2 MB</div>
            </div>
          )}

          {showTakePhoto && (
            <div className="btn_popup_photo" onClick={this.props.onClick_3}>
              <div style={{ display: 'flex' }}>
                <div className="icon_popup">
                  <FontAwesomeIcon icon="camera"></FontAwesomeIcon>
                </div>
                ถ่ายภาพ
              </div>
              <div className="last_taxt_photo"></div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
// PopupPhoto.defaultProps = {
//     status: false
//  }
export default PopupPhoto;
