import React, { Component } from 'react';
import swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { config } from './../../constants';
import axios from 'axios';
const _url = config.baseAPIURL03;
class Helpers extends Component {
  handleplus = (a, b) => {
    return a + b;
  };
  render() {
    // function rejectClick(e) {
    //   return e;

    // call_modal();
    // }
    return <div></div>;
  }
}

export default Helpers;

export function checkIdcard(a) {
  // if(a.length != 13) return false;
  if (!a) {
    return false;
  }
  var chk_a = a.length !== 13 ? false : true;
  if (chk_a) {
    var id = a;
    var sum, i;
    for (i = 0, sum = 0; i < 12; i++)
      sum += parseFloat(id.charAt(i)) * (13 - i);
    if ((11 - (sum % 11)) % 10 !== parseFloat(id.charAt(12)))
      return (chk_a = false);
    return (chk_a = true);
  } else {
    return chk_a;
  }
}

export function checkEmail(a) {
  var re = /\S+@\S+\.\S+/;
  return re.test(a);
}

export function convertToISODates(date) {
  let dateObj = date;
  let month = parseFloat(dateObj.substr(4, 2));
  let day = dateObj.substr(6, 2);
  let year = dateObj.substr(0, 4);
  var date = new Date(year + '-' + month + '-' + day);
  return date;
}

export function checkEditPage() {
  var str = window.location.href;
  let re = /edit/;
  const back_to_edit = re.test(str);
  return back_to_edit;
}

export function checkInternet() {
  var condition = navigator.onLine ? 'online' : 'offline';
  if (condition === 'online') {
    // console.log('ONLINE');
    fetch('../../../images/1pixel.png')
      .then(() => {
        // console.log('CONNECTED TO INTERNET');
        // return true;
      })
      .catch(() => {
        //  console.log('INTERNET CONNECTIVITY ISSUE');
        //  return true;
      });
  } else {
    //  console.log('OFFLINE')
    //  return false;
  }
  return condition === 'online' ? true : false;
}

var errorTitle = new Array(
  'splashscreenoa',
  'checkuserinfo',
  'risk_poll',
  'summaryshow',
  'tcconfirm',
  'idc_address_choose'
);
var errorTitle2 = new Array(
  'otp?page=fatca',
  'fatca',
  'investment_info_income',
  'investment_objective',
  'value_of_invester',
  'edit'
);
var errorTitle3 = new Array('tc_ndid', 'ndid_choice', 'ndid');

export function popupNetworkLost() {
  const MySwal = withReactContent(swal);
  const checkOnlineStatus = async () => {
    try {
      const online = await fetch('../../../images/1pixel.png');
      return online.status >= 200 && online.status < 300; // either true or false
    } catch (err) {
      return false; // definitely offline
    }
  };
  let timerInterval;
  let title = 'เกิดข้อผิดพลาด';
  let sub_title = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
  var page = window.location.href.substring(
    window.location.href.lastIndexOf('/') + 1
  );
  if (errorTitle.indexOf(page) != -1) {
    title = 'เกิดข้อผิดพลาด';
    sub_title = 'กรุณาทำรายการใหม่อีกครั้ง';
  } else if (errorTitle2.indexOf(page) != -1) {
    title = 'เกิดข้อผิดพลาด';
    sub_title = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
  } else if (errorTitle3.indexOf(page) != -1) {
    title = 'เกิดข้อผิดพลาด';
    sub_title = 'สอบถามข้อมูลเพิ่มเติมกรุณาติดต่อ ฝ่ายบริการลูกค้า 0 2351 1800';
  } else if (page.indexOf('ats_choice') != -1) {
    title = 'สมัครบริการหักบัญชีอัตโนมัติ (ATS) ไม่สำเร็จ';
    sub_title = 'เกิดข้อผิดพลาด ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
  } else if (page.indexOf('ndid_choice') != -1) {
    title = 'เกิดข้อผิดพลาด';
    sub_title = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
  }
  // console.log(page, title);
  MySwal.fire({
    customClass: {
      confirmButton: 'long_btn_fix',
      cancelButton: 'long_btn_fix',
    },
    imageUrl: '../../../images/ico_alert.svg',
    allowOutsideClick: false,
    showCancelButton: false,
    showConfirmButton: false,
    imageWidth: 100,
    imageHeight: 100,
    title: '<div class="title_popup">' + title + '</div>',
    html: '<div class="content_popup">' + sub_title + '</div>',
    timer: timerInterval,
    onBeforeOpen: () => {
      timerInterval = setInterval(async () => {
        const result = await checkOnlineStatus();
        if (result) {
          MySwal.close();
          clearInterval(timerInterval);
        }
      }, 3000); // probably too often, try 30000 for every 30 seconds
    },
    onClose: () => {
      clearInterval(timerInterval);
    },
  }).then(function (result) {
    window.location = 'login';
    // if (
    //   result.dismiss === MySwal.DismissReason.timerInterval
    // ) {
    //   console.log('I was closed by the timer')
    // }
  });
}

export function download_ico_alert() {
  var condition = navigator.onLine ? 'online' : 'offline';
  if (condition === 'online') {
    fetch('../../../images/1pixel.png')
      .then(() => {
        var ckc_ico_alert = document.querySelector('.ico_alert');
        var page = window.location.href.substring(
          window.location.href.lastIndexOf('/') + 1
        );
        if (ckc_ico_alert === null) {
          var url = '/images/ico_alert.svg';
          var image = new Image();
          image.src = url;
          image.style.display = 'none';
          image.className = 'ico_alert';
          var element;
          if (page.indexOf('login') != -1) {
            element = document.querySelector('.page_login');
          } else {
            element = document.querySelector('.content');
          }
          element.appendChild(image);
        }
        // Your font goes here
        const bangersFont = new FontFace(
          'DBHeavent',
          'url(/css/DBHeavent.woff)'
        );
        // Function which loads the font and applies it to the H1
        function loadFont() {
          console.log('Loading font...');
          bangersFont
            .load()
            .then(function (loadedFont) {
              document.fonts.add(loadedFont);
            })
            .catch(function (error) {
              console.log('Failed to load font: ' + error);
            });
        }
        loadFont();
      })
      .catch(() => {
        //  console.log('INTERNET CONNECTIVITY ISSUE');
        //  return true;
      });
  } else {
    //  console.log('OFFLINE')
    popupNetworkLost();
  }
}

export function popupError(msg, title = '', callback = null) {
  var element =
    document.querySelector('.main_popup_loading') &&
      document.querySelector('.main_popup_loading').style
      ? document.querySelector('.main_popup_loading').style.display
      : null;
  if (element === 'block') {
    document.querySelector('.main_popup_loading').style.display = 'none';
  }
  const MySwal = withReactContent(swal);
  const vm = this;
  MySwal.fire({
    customClass: {
      confirmButton: 'long_btn_fix',
      cancelButton: 'long_btn_fix',
    },
    // icon: 'warning',
    imageUrl: '/images/ico_alert.svg',
    imageWidth: 100,
    imageHeight: 100,
    confirmButtonColor: '#003b66',
    confirmButtonText: 'กลับ',
    title: `<span class="title_popup">${title}</span>`,
    html: `<div class="content_popup">${msg}</div>`,
  }).then(function () {
    if (callback !== null) {
      callback();
    }
  });
}

export function popupCrs(title, msg, confirm_text, cancel_text, callback=null, is_image=false, image_path='/images/ico_alert.svg') {
  var element =
    document.querySelector('.main_popup_loading') &&
      document.querySelector('.main_popup_loading').style
      ? document.querySelector('.main_popup_loading').style.display
      : null;
  if (element === 'block') {
    document.querySelector('.main_popup_loading').style.display = 'none';
  }
  const MySwal = withReactContent(swal);
  const vm = this;

  if (is_image) {
    MySwal.fire({
      customClass: {
        confirmButton: 'crs_declare_location_popup_confirm_btn',
        cancelButton: 'crs_declare_location_popup_cancel_btn',
      },
      buttonsStyling: false,
      imageUrl: '/images/ico_alert.svg',
      imageWidth: 100,
      imageHeight: 100,
      
      showCancelButton: true,
      cancelButtonText: cancel_text,
      confirmButtonText: confirm_text,
      title: `<span class="title_popup">${title}</span>`,
      html: `<div class="content_popup">${msg}</div>`,
    }).then((result) => {
      if (result.isConfirmed && callback !== null) {
        callback();
      }
    });
  } else {
    MySwal.fire({
      customClass: {
        confirmButton: 'crs_declare_location_popup_confirm_btn',
        cancelButton: 'crs_declare_location_popup_cancel_btn',
      },
      buttonsStyling: false,

      showCancelButton: true,
      cancelButtonText: cancel_text,
      confirmButtonText: confirm_text,
      title: `<span class="title_popup">${title}</span>`,
      html: `<div class="content_popup">${msg}</div>`,
    }).then((result) => {
      if (result.isConfirmed && callback !== null) {
        callback();
      }
    });
  }
}

export function popupErrorApi(
  msg,
  title = '',
  hide_bt = true,
  callback = null,
  confirmText= 'กลับ'
) {
  const MySwal = withReactContent(swal);
  var element =
    document.querySelector('.main_popup_loading') &&
      document.querySelector('.main_popup_loading').style
      ? document.querySelector('.main_popup_loading').style.display
      : null;
  if (element === 'block') {
    document.querySelector('.main_popup_loading').style.display = 'none';
  }
  const vm = this;
  MySwal.fire({
    imageUrl: '/images/ico_alert.svg',
    imageWidth: 100,
    imageHeight: 100,
    customClass: {
      confirmButton: 'long_btn_fix',
    },
    showConfirmButton: hide_bt,
    confirmButtonColor: '#003b66',
    confirmButtonText: confirmText,
    title: '<div class="title_popup">' + title + '</div>',
    html: '<div class="content_popup">' + msg + '</div>',
  });
}

export function saveDataToServer() {
  document.querySelector('.main_popup_loading').style.display = 'block';
  const HeaderManp = JSON.parse(localStorage.getItem('HeaderManp'));
  const head_token = HeaderManp.head_token;
  var title_mss = '';
  var mss = '';
  var last_stamp = JSON.stringify(allStorage());
  const ops = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Accept: 'application/json',
    },
    data: {
      last_stamp: last_stamp,
      head_token: head_token,
    },
    url: _url + '/form/comeback/manp',
  };
  axios(ops)
    .then(async (res) => {
      document.querySelector('.main_popup_loading').style.display = 'none';
      var keys = Object.keys(localStorage),
        i = keys.length;
      while (i--) {
        console.log(i, keys[i]);
      }
      // vm.props.history.push('/ats_choice');
    })
    .catch((error) => {
      if (!!error.isAxiosError && !error.response) {
        title_mss = 'เกิดข้อผิดพลาด';
        mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
      } else {
        title_mss =
          error.response.data.Data[0][0].MsgDescTitle !== ''
            ? error.response.data.Data[0][0].MsgDescTitle
            : 'เกิดข้อผิดพลาด';
        mss =
          error.response.data.Data[0][0].MsgDesc !== ''
            ? error.response.data.Data[0][0].MsgDesc
            : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
      }
      document.querySelector('.main_popup_loading').style.display = 'none';
      return;
    });
}

function allStorage() {
  var values = [],
    keys = Object.keys(localStorage),
    i = keys.length;
  while (i--) {
    values.push({
      key: keys[i],
      value: localStorage.getItem(keys[i]),
    });
  }
  return values;
}

export const update_tracking = async (data) => {
  var json = {};
  const _url = config.baseAPIURL03;
  var url = _url + '/form/header/update_tracking';
  if (data) {
    return axios
      .post(`${url}`, data)
      .then((res) => {
        res.data.Data[0][0].success = true;
        return res.data.Data[0][0];
      })
      .catch((error) => {
        json.success = false;
        if (!!error.isAxiosError && !error.response) {
          popupError('กรุณาทำรายการใหม่อีกครั้ง', 'เกิดข้อผิดพลาด');
          return json;
        } else {
          let errorMessage = error.response ? error.response.data.message : '';
          popupError(`axios or api error ${errorMessage}`, 'เกิดข้อผิดพลาด');
          return json;
        }
      });
  } else {
    console.log('no have data');
    json.success = false;
    popupError('กรุณาทำรายการใหม่อีกครั้ง', 'เกิดข้อผิดพลาด');
    return json;
  }
};

export const checkFlag = async (key) => {
  var value = false;
  const _url = config.baseAPIURL03;
  if (key === 'f3_career') {
    await axios
      .get(_url + `/constant/career`)
      .then((res) => {
        res.data.Data.forEach(function (item, i) {
          if (item.Other === 1) {
            value = item.Id;
            return;
          }
        });
      })
      .catch(async (error) => {
        if (!!error.isAxiosError && !error.response) {
          title_mss = 'เกิดข้อผิดพลาด';
          mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
        } else {
          var mss = '';
          var title_mss = '';
          if (!!error.isAxiosError && !error.response) {
            title_mss = 'เกิดข้อผิดพลาด';
            mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
          } else {
            title_mss = error.response.data.Data
              ? error.response.data.Data[0][0].MsgDescTitle !== ''
                ? error.response.data.Data[0][0].MsgDescTitle
                : 'เกิดข้อผิดพลาด'
              : 'เกิดข้อผิดพลาด';
            mss = error.response.data.Data
              ? error.response.data.Data[0][0].MsgDesc !== ''
                ? error.response.data.Data[0][0].MsgDesc
                : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง'
              : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
          }
        }
        popupErrorApi(mss, title_mss, true);
      });
  }
  if (key === 'f3_business') {
    await axios
      .get(_url + `/constant/business_type`)
      .then((res) => {
        res.data.Data.forEach(function (item, i) {
          if (item.Other === 1) {
            value = item.Id;
            return;
          }
        });
      })
      .catch(async (error) => {
        if (!!error.isAxiosError && !error.response) {
          title_mss = 'เกิดข้อผิดพลาด';
          mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
        } else {
          var mss = '';
          var title_mss = '';
          if (!!error.isAxiosError && !error.response) {
            title_mss = 'เกิดข้อผิดพลาด';
            mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
          } else {
            title_mss = error.response.data.Data
              ? error.response.data.Data[0][0].MsgDescTitle !== ''
                ? error.response.data.Data[0][0].MsgDescTitle
                : 'เกิดข้อผิดพลาด'
              : 'เกิดข้อผิดพลาด';
            mss = error.response.data.Data
              ? error.response.data.Data[0][0].MsgDesc !== ''
                ? error.response.data.Data[0][0].MsgDesc
                : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง'
              : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
          }
        }
        popupErrorApi(mss, title_mss, true);
      });
  }
  if (key === 'f3_source') {
    await axios
      .get(_url + `/constant/source_income`)
      .then((res) => {
        res.data.Data.forEach(function (item, i) {
          if (item.Other === 1) {
            value = item.Mfnt_Id;
            return;
          }
        });
      })
      .catch(async (error) => {
        if (!!error.isAxiosError && !error.response) {
          title_mss = 'เกิดข้อผิดพลาด';
          mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
        } else {
          var mss = '';
          var title_mss = '';
          if (!!error.isAxiosError && !error.response) {
            title_mss = 'เกิดข้อผิดพลาด';
            mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
          } else {
            title_mss = error.response.data.Data
              ? error.response.data.Data[0][0].MsgDescTitle !== ''
                ? error.response.data.Data[0][0].MsgDescTitle
                : 'เกิดข้อผิดพลาด'
              : 'เกิดข้อผิดพลาด';
            mss = error.response.data.Data
              ? error.response.data.Data[0][0].MsgDesc !== ''
                ? error.response.data.Data[0][0].MsgDesc
                : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง'
              : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
          }
        }
        popupErrorApi(mss, title_mss, true);
      });
  }
  return value;
};

export const checkKey = async (array) => {
  var arr = [
    'head_token',
    'f3_source',
    'f3_source_text',
    'f3_by_private_business',
    'f3_by_invest',
    'f3_by_employee',
    'f3_by_heritage',
    'f3_by_borrowing',
    'f3_by_divided',
    'f3_by_retired',
    'f3_by_saving',
    'f3_by_other',
    'f3_by_other_text',
    'f3_income',
    'f3_outcome',
    'f3_invest',
    'f3_career',
    'f3_career_text',
    'f3_business',
    'f3_business_text',
    'f3_position',
    'f3_officename',
    'f3_valuable',
    'f3_own',
    'purpose_json',
  ];
  var arr_require = [
    'f3_income',
    'f3_outcome',
    'f3_invest',
    'f3_valuable',
    'f3_career',
    'f3_career_text',
    'f3_business',
    'f3_business_text',
    'f3_source',
    'f3_source_text',
    'f3_position',
    'f3_officename',
  ];
  var data_array = [];
  var f3_career_flag = await checkFlag('f3_career');
  var f3_business_flag = await checkFlag('f3_business');
  var f3_source_flag = await checkFlag('f3_source');
  for (var key in array) {
    data_array.push(key);
  }
  var i, chk_key;
  for (i = 0; i < arr.length; i++) {
    var item = arr[i];
    var r = arr_require.includes(item);
    if (typeof array[arr[i]] === 'undefined') {
      if (r) {
        console.log("ไม่มีคีย์ require", arr[i]);
        if (item === 'f3_career_text' || item === 'f3_business_text' || item === 'f3_source_text') {
          if (
            arr[i] === 'f3_source_text' &&
            (parseInt(array['f3_source']) === f3_source_flag || array['f3_source'] === f3_source_flag)
          ) {
            chk_key = arr[i];
          }
          if (
            item === 'f3_career_text' &&
            array['f3_career'] === f3_career_flag
          ) {
            chk_key = arr[i];
          }
          if (
            item === 'f3_business_text' &&
            array['f3_business'] === f3_business_flag
          ) {
            chk_key = arr[i];
          }
        } else {
          return (chk_key = arr[i]);
        }
      }
    } else {
      if (array[arr[i]] === "") {
        if (r) {
          if (item === 'f3_career_text' || item === 'f3_business_text' || item === 'f3_source_text') {
            if (
              item === 'f3_career_text' &&
              array['f3_career'] === f3_career_flag
            ) {
              chk_key = item;
            }
            if (
              item === 'f3_business_text' &&
              array['f3_business'] === f3_business_flag
            ) {
              chk_key = item;
            }
            if (
              item === 'f3_source_text' &&
              (parseInt(array['f3_source']) === f3_source_flag || array['f3_source'] === f3_source_flag)
            ) {
              chk_key = item;
            }
          } else {
            console.log(item + ' is require');
            return (chk_key = item);
          }
        } else {
          if (
            item === 'f3_by_other_text' &&
            array['f3_by_other'] === "1"
          ) {
            chk_key = item;
          }
        }
      }
    }
  }
  var f3_num = 0;
  var arritemisCheck = [
    "f3_by_employee",
    "f3_by_heritage",
    "f3_by_invest",
    "f3_by_other",
    "f3_by_private_business",
    "f3_by_retired",
    "f3_by_saving",
  ]
  arritemisCheck.forEach(element => {
    if (array[element] == 1) {
      f3_num++
    }
    if (array["f3_by_other"] === "1" && (array["f3_by_other_text"] === "" || array["f3_by_other_text"] === undefined)) {
      f3_num = f3_num - 1
    }
  });
  if (f3_num < 1) {
    console.log("f3_by ไม่มี");
    chk_key = "f3_by"
  } else {
  }
  return chk_key !== undefined ? chk_key : array;
};

export const getStampPage = async () => {
  var values = [];
  var page = window.location.href.substring(
    window.location.href.lastIndexOf('/') + 1
  );
  var currentStep = localStorage.getItem('currentStep');
  if (checkEditPage()) {
    values = 'summaryshow';
  } else {
    if (page.indexOf('idc_address_choose') != -1) {
      if (currentStep) {
        console.log(currentStep);
        if (currentStep.indexOf('summaryshow') != -1) {
          values = 'summaryshow';
        } else {
          values = 'jobs';
        }
      } else {
        values = 'jobs';
      }
    }
  }
  return values;
};

const checkFieldValues = (fields, values) => {
  return Object.keys(fields).filter((fieldname) => {
    if (typeof values[fieldname] === 'undefined') {
      return true;
    }

    if (fields[fieldname] === 'boolean') {
      return values[fieldname] === null;
    } else if (fields[fieldname] === 'number') {
      return values[fieldname] === null || isNaN(values[fieldname]);
    } else {
      return String(values[fieldname]).trim() === '';
    }
  });
};

export const checkPurpose = (values) => {
  const result = [];
  if (typeof values.purpose_json === 'undefined' || !values.purpose_json) {
    result.push('purpose_json');
    return result;
  }

  let requiredPurposeFields = {
    SAfpp_SAmpp_Id: 'number',
  };

  if (values.purpose_json.length > 0) {
    values.purpose_json.forEach((purpose) => {
      if (purpose.SAfpp_SAmpp_Id === 10) {
        requiredPurposeFields.SAfpp_Text = 'string';
      }

      const purposeResult = checkFieldValues(requiredPurposeFields, purpose);
      if (purposeResult.length > 0) {
        result.push('purpose_json');
      }
    });
  } else {
    result.push('purpose_json');
  }

  return result;
};

export const checkFamilyStatusRequired = (values) => {
  let requiredFields = { head_token: 'string', f1_marital_status: 'string' };
  if (values.f1_marital_status === '2') {
    requiredFields.f1_spouse_id_card = 'string';
    requiredFields.f1_spouse_is_th = 'boolean';
    requiredFields.f1_spouse_firstname_en = 'string';
    requiredFields.f1_spouse_lastname_en = 'string';
    requiredFields.f1_spouse_title = 'string';

    if (values.f1_spouse_is_th) {
      requiredFields.f1_spouse_firstname = 'string';
      requiredFields.f1_spouse_lastname = 'string';
    }

    if (values.f1_spouse_title === '205') {
      requiredFields.f1_spouse_title_text = 'string';
    }
  }

  return checkFieldValues(requiredFields, values);
};

export const checkChildStatusRequired = (values) => {
  let requiredFields = { head_token: 'string' };
  const checkRequiredFields = checkFieldValues(requiredFields, values);
  if (checkRequiredFields.length > 0) {
    return checkRequiredFields;
  }

  let requiredChildField = {
    SAfc_CardID: 'string',
    SAfc_THName: 'string',
    SAfc_THSurname: 'string',
    // SAfc_ENName: 'string',
    // SAfc_ENSurname: 'string',
  };

  const result = [];
  if (typeof values.children_json === 'undefined' || !values.children_json) {
    result.push('children_json');
    return result;
  }

  if (values.children_json.length > 0) {
    values.children_json.forEach((child) => {
      const childResult = checkFieldValues(requiredChildField, child);
      if (childResult.length > 0) {
        result.push('children_json');
      }
    });
  }

  return result;
};

export const checkPoliticianRelationRequired = (values) => {
  let requiredFields = {
    head_token: 'string',
    f5_relationship_have_a_position: 'string',
  };
  if (String(values.f5_relationship_have_a_position) === '1') {
    requiredFields.f5_relationship_have_a_position_text = 'string';
  }
  return checkFieldValues(requiredFields, values);
};

export const checkEditData = async () => {
  const page = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const oldData_f0 = JSON.parse(localStorage.getItem("oldData_f0"));
  const oldData_photo1 = localStorage.getItem("oldData_photo1");
  const oldData_photo2 = localStorage.getItem("oldData_photo2");
  const oldData_checkuserinfo = JSON.parse(localStorage.getItem("oldData_checkuserinfo"));
  let flag_idc_detail = false;
  let flag_SnapIdCard = false;
  let flag_checkuserinfo = false;
  if (page.indexOf('idc_detail') !== -1) {
    let f0 = JSON.parse(localStorage.getItem("f0"));
    const changes = Object.keys(oldData_f0).filter(key => oldData_f0[key] !== f0[key] && key !== "f0_nationality");
    if (oldData_f0["f0_nationality"].value !== f0["f0_nationality"].value) {
      changes.push("f0_nationality")
    }
    flag_idc_detail = changes.length > 0 ? true : false;
    if (flag_idc_detail) {
      localStorage.setItem("flag_idc_detail", flag_idc_detail);
    }
    console.log("flag_idc_detail :", flag_idc_detail);
    if (flag_idc_detail) {
      console.log("changes idc_detail data:", changes);
    }
  } else if (page.indexOf('SnapIdCard') !== -1) {
    let photo1 = localStorage.getItem("photo1");
    let photo2 = localStorage.getItem("photo2");
    if (oldData_photo1 !== photo1 || oldData_photo2 !== photo2) {
      flag_SnapIdCard = true;
    }
    console.log("flag_SnapIdCard :", flag_SnapIdCard);
    if (flag_SnapIdCard) {
      console.log("changes SnapIdCard data:", oldData_photo1 !== photo1 ? "photo1 " : " ", oldData_photo2 !== photo2 ? " photo2" : " ");
    }
  } else if (page.indexOf('checkuserinfo') !== -1) {
    const checkuserinfo = await JSON.parse(localStorage.getItem("checkuserinfo"));
    const changes = Object.keys(oldData_checkuserinfo).filter(key => oldData_checkuserinfo[key] !== checkuserinfo[key]);
    flag_checkuserinfo = changes.length > 0 ? true : false;
    if (flag_checkuserinfo) {
      localStorage.setItem("flag_checkuserinfo", flag_checkuserinfo);
    }
    console.log("flag_checkuserinfo :", flag_checkuserinfo);
    if (flag_checkuserinfo) {
      console.log("changes checkuserinfo data:", changes);
    }
  }
  // return flag_idc_detail;
};

