import React, { Component } from "react";
import "./Login.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import axios from "axios";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";

class LoginDesktop extends Component {
  render() {
    return (
      <div
        className="body"
        style={{ backgroundImage: "url(images/bg_main.png)" }}
      >
        <header className="header-area" id="header-area">
          <div className="everest-nav-container">
            <div className="site-branding">
              <img src="../../../images/logo.png" />
              <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
            </div>
          </div>
        </header>
        <section className="desktop_content section-gap-full">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className={"head-left"}>
                  เปิดบัญชีออนไลน์ ยืนยันตัวตนด้วย National Digital ID (NDID)
                </div>
                <p className={"banner-left-p"}>
                  เปิดบัญชีเพื่อใช้บริการของ DAOL INVESTMENT ผ่านช่องทางออนไลน์
                  ยืนยันตัวตนผ่านระบบ NDID สะดวก รวดเร็ว ปลอดภัย
                  ไม่ต้องจัดส่งเอกสาร เปิดบัญชีและเทรดได้ทันที
                </p>
                <p className={"banner-left-tel"}>
                  DAOL Contact Center 0 2351 1800
                </p>
              </div>
              <div className="col-md-6">
                {/* content mobile */}
                <div className="page_login">
                  <div className="title_welcome">
                    เข้าใช้งานระบบ LIFE หรรือ สมัครเพื่อเปิดบัญชี
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: 140,
                    }}
                  >
                    <ButtonCustom
                      handle={this.handleSubmit}
                      label="สมัครเปิดบัญชีใหม่"
                      color="white"
                      btnClass="bt_open_acc"
                    ></ButtonCustom>
                    <ButtonCustom
                      label="เข้าสู่ระบบ"
                      color="blue"
                      btnClass="bt_login"
                    ></ButtonCustom>
                  </div>
                  <div className="footer_app">
                    <div className="text_app">available with application</div>
                    <div className="img_app">
                      <img src="../../../images/gg_app.png" />
                      <img src="../../../images/ios_app.png" />
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default LoginDesktop;
