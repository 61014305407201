import React, { Component } from "react";
import "./KtbstValueOfInvester.css";
import { IMaskInput } from "react-imask";
import ButtonCustom from "../../components/button/ButtonCustom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import validator from "validator";
import $ from "jquery";
import {
  checkEditPage,
  popupErrorApi,
} from "../../components/helpers/Helpers";
import Loading from "../../components/loading/loading";
import to from "await-to-js";
import ktbsApi from "./../../api";
import { config } from "./../../constants";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
const _url = config.baseAPIURL03;

class KtbstValueOfInvester extends Component {
  constructor(props) {
    super(props);
    const f3_invester = JSON.parse(localStorage.getItem("f3_invester"));
    this.state = {
      select_1: "0",
      isSubmit: false,
      f3_valuable: f3_invester
        ? f3_invester.f3_valuable !== undefined
          ? f3_invester.f3_valuable
          : ""
        : "",
      f3_outcome: f3_invester ? f3_invester.f3_outcome : "",
      f3_invest: f3_invester ? f3_invester.f3_invest : "",
      invest_option: [],
      outcome_option: [],
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    if (checkEditPage()) {
      return;
    }
    this.props.history.push("/investment_objective");
  }
  handleChangeNum = async (val, name) => {
    //console.log(e, name);
    await this.setState({
      [name]: val,
    });
    if (this.state.isSubmit) {
      await this.validate();
    }
    // console.log(name, this.state[name]);
  };
  handleChange = async (e) => {
    e.preventDefault();
    const isCheckbox = e.target.type === "checkbox";
    const value =
      e.target.name === "f3_valuable"
        ? e.target.value.replace(/[\D]+/g, "")
        : e.target.value;
    await this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : value,
    });
    if (this.state.isSubmit) {
      await this.validate();
    }
  };

  validate = () => {
    $("span[class^=span_f3]").hide();
    let countError = 0;
    if (validator.isEmpty(this.state.f3_outcome)) {
      $(".span_f3_outcome").css("display", "flex");
      countError++;
    }

    if (validator.isEmpty(this.state.f3_valuable)) {
      $(".span_f3_valuable").css("display", "flex");
      countError++;
    }
    if (validator.isEmpty(this.state.f3_invest)) {
      $(".span_f3_invest").css("display", "flex");
      countError++;
    }
    return countError > 0 ? false : true;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isSubmit: true });
    const isValid = await this.validate();
    if (isValid) {
      var f3_invester = [];
      f3_invester = {
        f3_valuable: this.state.f3_valuable,
        f3_outcome: this.state.f3_outcome,
        f3_invest: this.state.f3_invest,
      };
      await localStorage.setItem("f3_invester", JSON.stringify(f3_invester));
      const f3 = JSON.parse(localStorage.getItem("f3"));
      let f3_ = {
        f3_source: f3 ? f3.f3_source : "",
        f3_source_text: f3 ? f3.f3_source_text : "",
        f3_by_private_business: f3 ? f3.f3_by_private_business : "",
        f3_by_employee: f3 ? f3.f3_by_employee : "",
        f3_by_heritage: f3 ? f3.f3_by_heritage : "",
        f3_by_borrowing: f3 ? f3.f3_by_borrowing : "",
        f3_by_divided: f3 ? f3.f3_by_divided : "",
        f3_by_retired: f3 ? f3.f3_by_retired : "",
        f3_by_saving: f3 ? f3.f3_by_saving : "",
        f3_by_other: f3 ? f3.f3_by_other : "",
        f3_by_other_text: f3 ? f3.f3_by_other_text : "",
        f3_income: f3 ? f3.f3_income : "",
        f3_outcome: this.state.f3_outcome,
        f3_invest: this.state.f3_invest,
        f3_by_invest: f3 ? f3.f3_by_invest : "0",
        f3_valuable: this.state.f3_valuable,
        purpose_json: f3 ? f3.purpose_json : [],
        f3_own: f3 ? f3.f3_own : "1",
      };
      await localStorage.setItem("f3", JSON.stringify(f3_));
      var str = window.location.href;
      let re = /edit/;
      const back_to_edit = re.test(str);
      if (back_to_edit) {
        let error;
        let result;
        let mss = "";
        let title_mss = "";
        const MySwal = withReactContent(swal);
        const HeaderManp = await JSON.parse(localStorage.getItem("HeaderManp"));
        const head_token = HeaderManp.head_token;
        const f3 = JSON.parse(localStorage.getItem("f3"))
          ? JSON.parse(localStorage.getItem("f3"))
          : {};
        const arr_jobs_data = JSON.parse(localStorage.getItem("arr_jobs_data"))
          ? JSON.parse(localStorage.getItem("arr_jobs_data"))
          : {};
        const {
          f3_source,
          f3_source_text,
          f3_by_private_business,
          f3_by_employee,
          f3_by_heritage,
          f3_by_borrowing,
          f3_by_divided,
          f3_by_retired,
          f3_by_saving,
          f3_by_other,
          f3_by_other_text,
          f3_income,
          f3_outcome,
          f3_invest,
          f3_valuable,
          purpose_json,
          f3_by_invest,
          f3_own,
        } = f3;
        const {
          f1_business_other,
          f1_business_type,
          f1_career,
          f1_career_other,
          f1_career_position,
          f3_officename,
        } = arr_jobs_data;
        const careerAndFinancial = {
          head_token,
          f3_source: f3_source,
          f3_source_text: f3_source_text,
          f3_by_private_business: f3_by_private_business,
          f3_by_employee: f3_by_employee,
          f3_by_heritage: f3_by_heritage,
          f3_by_borrowing: f3_by_borrowing,
          f3_by_divided: f3_by_divided,
          f3_by_retired: f3_by_retired,
          f3_by_saving: f3_by_saving,
          f3_by_other: f3_by_other,
          f3_by_other_text: f3_by_other_text,
          f3_income: f3_income,
          f3_outcome: f3_outcome,
          f3_invest: f3_invest,
          f3_valuable: f3_valuable,
          purpose_json: purpose_json,
          f3_career: f1_career,
          f3_career_text: f1_career_other,
          f3_business: f1_business_type,
          f3_business_text: f1_business_other,
          f3_position: f1_career_position,
          f3_by_invest: f3_by_invest,
          f3_officename: f3_officename,
          f3_own: f3_own,
        };
        await this.setState({ loading: true });
        [error, result] = await to(
          ktbsApi.post("/form/career_and_financial/manp", careerAndFinancial)
        );
        if (
          error ||
          !result ||
          !result.Data ||
          !result.Data[0] ||
          !result.Data[0][0] ||
          result.Data[0][0].IsSuccess !== 1
        ) {
          if (result) {
            title_mss =
              result.Data[0][0].MsgDescTitle !== ""
                ? result.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              result.Data[0][0].MsgDesc !== ""
                ? result.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else if (error) {
            if (!!error.isAxiosError && !error.response) {
              title_mss = "เกิดข้อผิดพลาด";
              mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            } else {
              title_mss =
                error.response.data.Data[0][0].MsgDescTitle !== ""
                  ? error.response.data.Data[0][0].MsgDescTitle
                  : "เกิดข้อผิดพลาด";
              mss =
                error.response.data.Data[0][0].MsgDesc !== ""
                  ? error.response.data.Data[0][0].MsgDesc
                  : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            }
          } else {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
          MySwal.fire({
            imageUrl: "/images/ico_alert.svg",
            imageWidth: 100,
            imageHeight: 100,
            customClass: {
              confirmButton: "long_btn_fix",
            },
            confirmButtonColor: "#003b66",
            confirmButtonText: "กลับ",
            title: '<div class="title_popup">' + title_mss + "</div>",
            html: '<div class="content_popup">' + mss + "</div>",
          });
          await this.setState({ loading: false });
          return;
        } else {
          // console.log(result.Data[0][0].MsgDesc !== "");
        }
        await this.setState({ loading: false });
        this.props.history.push("/summaryshow");
        localStorage.setItem("editPage", "value_of_invester");
      } else {
        this.setState({ loading: false });
        const BackToSummaryRisk = JSON.parse(localStorage.getItem("BackToSummaryRisk"));
        if (BackToSummaryRisk) {
          localStorage.removeItem('BackToSummaryRisk');
          window.location = 'summary_risk';
        } else {
          this.props.history.push("/risk");
        }
      }
    }
  };

  async componentDidMount() {
    await this.setState({ loading: true });
    // await download_ico_alert();
    // if (checkInternet()) {
    await axios
      .get(_url + `/constant/invest`)
      .then((res) => {
        let invest_option = res.data.Data.map((item, i) => {
          return (
            <option value={item.Id} key={i} style={{ color: "#003b66" }}>
              {item.ThName}
            </option>
          );
        });
        this.setState({
          invest_option: invest_option,
        });
      })
      .catch(async (error) => {
        let mss = "";
        let title_mss = "";
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            title_mss =
              error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
        }
        popupErrorApi(
          mss,
          title_mss,
          true
          // function name() {
          //   var myStyle = document.getElementsByClassName("main_popup_loading")[0].style.display;
          //   if(myStyle === 'none'){
          //     document.getElementsByClassName("main_popup_loading")[0].style.display = 'block';
          //   }
          //   window.location = "login";
          // }
        );
        this.setState({ loading: false });
      });

    await axios
      .get(_url + `/constant/outcome`)
      .then((res) => {
        let outcome_option = res.data.Data.map((item, i) => {
          return (
            <option value={item.Id} key={i} style={{ color: "#003b66" }}>
              {item.ThName}
            </option>
          );
        });

        this.setState({
          outcome_option: outcome_option,
        });
      })
      .catch(async (error) => {
        let mss = "";
        let title_mss = "";
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            title_mss =
              error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
        }
        popupErrorApi(
          mss,
          title_mss,
          true
          // function name() {
          //   var myStyle = document.getElementsByClassName("main_popup_loading")[0].style.display;
          //   if(myStyle === 'none'){
          //     document.getElementsByClassName("main_popup_loading")[0].style.display = 'block';
          //   }
          //   window.location = "login";
          // }
        );
        this.setState({ loading: false });
      });
    await this.setState({ loading: false });
    // } else {
    //   await this.setState({ loading: false });
    //   this.setState({
    //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
    //   });
    // }
    await this.setState({ loading: false });
  }
  popup = (e) => {
    const MySwal = withReactContent(swal);
    MySwal.fire({
      // icon: 'warning',
      customClass: {
        confirmButton: "long_btn_fix",
        cancelButton: "long_btn_fix",
      },
      confirmButtonColor: "#003b66",
      confirmButtonText: "กลับ",
      title: '<span class="title_popup">มูลค่าทรัพย์สิน</span>',
      html:
        '<div class="content_popup">ทรัพย์สิน หมายถึง เงินฝาก เงินลงทุนโดยตรงในหลักทรัพย์หรือสัญญาซื้อขายล่วงหน้า และสินทรัพย์สุทธิ <br>(ไม่รวมอสังหาริมทรัพย์ที่เป็นที่อยู่อาศัย)</div>',
    }).then(function () { });
  };

  render() {
    library.add(fas);
    const invest_option = this.state.invest_option;
    const outcome_option = this.state.outcome_option;

    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <Loading status={this.state.loading} />
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          alt="forward"
                          style={{
                            display: checkEditPage() ? "none" : "block",
                            marginLeft: "3px",
                          }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">ข้อมูลการลงทุน</div>
                    </div>

                    <div className="bg_content_white over_f_hide">
                      <div
                        className="in_bg_white autoscroll"
                        style={{ top: 6 }}
                      >
                        <div
                          className="row_idcard"
                          style={{
                            // display: "none",
                            borderColor:
                              this.state.f3_valuable !== ""
                                ? "#003b66"
                                : "#ccc",
                          }}
                        >
                          <div style={{ fontSize: 20 }}>
                            มูลค่าทรัพย์สิน (บาท) *{" "}
                            <span
                              style={{
                                color: "#fdd836",
                                marginLeft: 6,
                                fontSize: 14,
                              }}
                              onClick={this.popup}
                            >
                              <FontAwesomeIcon icon="info-circle" />
                            </span>
                          </div>
                          <IMaskInput
                            mask={Number}
                            thousandsSeparator={","}
                            value={this.state.f3_valuable || ""}
                            unmask={true}
                            className="input_idcard"
                            name="f3_valuable"
                            style={{
                              borderColor:
                                this.state.f3_valuable !== ""
                                  ? "#003b66"
                                  : "#ccc",
                            }}
                            onAccept={(value, mask) =>
                              this.handleChangeNum(value, "f3_valuable")
                            }
                          />
                          {/* <input
                            className="input_idcard"
                            name="f3_valuable"
                            value={this.state.f3_valuable || ""}
                            onChange={this.handleChange}
                            type="text"
                          /> */}
                          <span className="span_f3_valuable">
                            กรุณาระบุมูลค่าทรัพย์สิน (บาท)
                          </span>
                        </div>

                        <div
                          className="row_idcard"
                          style={{
                            borderColor:
                              this.state.f3_outcome !== "" ? "#003b66" : "#ccc",
                          }}
                        >
                          <div style={{ fontSize: 20 }}>
                            ภาระการเงินต่อปี (บาท) *
                          </div>
                          <div className={"main_select"}>
                            <select
                              name="f3_outcome"
                              className={"select_style"}
                              value={this.state.f3_outcome}
                              onChange={this.handleChange}
                              style={{
                                color:
                                  this.state.f3_outcome == ""
                                    ? "#003b6694"
                                    : "#003b66",
                              }}
                            >
                              <option
                                value=""
                                style={{ color: "#003b6694" }}
                                disabled
                                hidden
                              >
                                กรุณาเลือก
                              </option>
                              {outcome_option}
                            </select>
                            <FontAwesomeIcon icon="angle-down" />
                          </div>
                          <span className="span_f3_outcome">
                            กรุณาระบุภาระการเงินต่อปี (บาท)
                          </span>
                        </div>

                        <div
                          className="row_idcard"
                          style={{
                            borderColor:
                              this.state.f3_invest !== "" ? "#003b66" : "#ccc",
                          }}
                        >
                          <div style={{ fontSize: 20 }}>
                            จำนวนเงินที่คาดว่าจะนำมาลงทุนแต่ละครั้ง (บาท) *
                          </div>
                          <div className={"main_select"}>
                            <select
                              name="f3_invest"
                              className="select_style"
                              value={this.state.f3_invest}
                              onChange={this.handleChange}
                              style={{
                                color:
                                  this.state.f3_invest == ""
                                    ? "#003b6694"
                                    : "#003b66",
                              }}
                            >
                              <option
                                value=""
                                style={{ color: "#003b6694" }}
                                disabled
                                hidden
                              >
                                กรุณาเลือก
                              </option>
                              {invest_option}
                            </select>
                            <FontAwesomeIcon icon="angle-down" />
                          </div>
                          <span className="span_f3_invest">
                            กรุณาระบุจำนวนเงินที่คาดว่าจะนำมาลงทุนแต่ละครั้ง
                            (บาท)
                          </span>
                        </div>
                      </div>
                      <div className="under_fix_btn">
                        <ButtonCustom
                          handle={this.handleSubmit}
                          label={checkEditPage() ? "บันทึกการแก้ไข" : "ถัดไป"}
                          color="blue"
                          btnClass="long_btn_fix"
                        ></ButtonCustom>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default KtbstValueOfInvester;
