/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import Loading from "../../components/loading/loading";
import { IMaskInput } from "react-imask";
import ButtonCustom from "../../components/button/ButtonCustom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { popupErrorApi } from "../../components/helpers/Helpers";
import to from "await-to-js";
import ktbsApi from "./../../api";
import Select2 from "react-select";
import img from "./snapidcard_trans.png";
import { isMobile } from "react-device-detect";
import Popup from "../../components/popup_photo/PopupPhoto";
import Webcam from "react-webcam";
import { createCanvas, loadImage } from "canvas";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import $ from "jquery";
import { config } from "./../../constants";
import validator from "validator";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
import localForage from "localforage";

const videoConstraints = {
  // width: 400,
  // height: 700,
  facingMode: "environment",
};

class KtbstATSChoiceOther extends Component {
  constructor(props) {
    super(props);

    let otherBank = JSON.parse(localStorage.getItem("otherBank"));
    let f0 = JSON.parse(localStorage.getItem("f0"));
    let f0_bank_account_name =
      f0 && f0.f0_firstname
        ? f0.f0_firstname +
          "" +
          (f0?.f0_middlename !== "" ? " " + f0.f0_middlename + " " : " ") +
          f0.f0_lastname
        : "";

    this.state = {
      status_popup: false,
      showCameraPopup: false,
      loading: false,
      CheckbankUploadImg: false,
      bankUploadImg: null,
      bankCode: "",
      bank: otherBank ? otherBank.bank : "",
      bank_branch: otherBank ? otherBank.bank_branch : "",
      bank_account_no: otherBank ? otherBank.bank_account_no : "",
      bank_account_name: otherBank
        ? otherBank.bank_account_name
        : f0_bank_account_name,
      bank_list: [],
      bank_branch_list: [],
    };

    this.goBack = this.goBack.bind(this);
    this.fileuploadRef = React.createRef();
    this.webcamRef = React.createRef();
    this.handleChangeOption = this.handleChangeOption.bind(this);
    this.handlesubmit = this.handlesubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  async componentDidMount() {
    const vm = this;
    await axios
      .get(`${config.baseAPIURL03}/constant/bank`)
      .then((res) => {
        const bank_list = res.data.Data.map((item, i) => {
          return { value: item.Id, label: item.ThName };
        });
        this.setState({ bank_list: bank_list });
      })
      .catch((error) => {
        var mss = "";
        var title_mss = "";
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss =
            error.response.data.Data !== undefined &&
            error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data !== undefined &&
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
        popupErrorApi(mss, title_mss, true);
        return;
      });

    if (this.state.bank.value) {
      await this.getBankBranch(this.state.bank.value, false);
    }

    // let bankUploadImg = localStorage.getItem("bankUploadImg");
    // this.setState({ bankUploadImg: bankUploadImg });
    // // this.setState({ loading: true });

    // if (bankUploadImg) {
    //   await this.drawImg1();
    //   // await this.sleep(1000);
    //   // this.setState({ loading: false });
    // } else {
    //   this.setState({ bankUploadImg: null });
    //   localStorage.removeItem("bankUploadImg");
    //   // await this.sleep(1000);
    //   // this.setState({ loading: false });
    // }
    localStorage.removeItem("bankUploadImg");

    await localForage
      .getItem("bankUploadImg")
      .then(async function (value) {
        vm.setState({ bankUploadImg: value, CheckbankUploadImg: true });
        await vm.drawImg1();
      })
      .catch(function (err) {
        vm.setState({ bankUploadImg: null, CheckbankUploadImg: false });
        //console.log(err);
      });

    this.checkScreen();
  }
  handlesubmit = async (e) => {
    e.preventDefault();

    if (
      (!this.state.bankUploadImg || this.state.bankUploadImg == null) &&
      !this.state.CheckbankUploadImg
    ) {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        customClass: {
          confirmButton: "long_btn_fix",
        },
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title: '<div class="title_popup">ไม่สามารถดำเนินการต่อได้</div>',
        html: '<div class="content_popup">กรุณาอัปโหลดหน้าสมุดบัญชีธนาคาร</div>',
      });
      this.setState({ loading: false });
      return;
    }

    const isValid = await this.validate();
    if (isValid) {
      const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
      const { head_token } = HeaderManp;
      const bankAccountParam = {
        head_token,
        f7_isother: "1",
        add_account_json: [
          {
            SAfba_Id: "0",
            SAfba_SAmbk_Id: this.state.bank.value,
            SAfba_SAmbkb_Id: this.state.bank_branch.value,
            SAfba_SAmbkt_Id: "",
            SAfba_AccountNo: this.state.bank_account_no,
            SAfba_AccountName: this.state.bank_account_name,
            SAfba_UseATS: "1",
            SAfba_UseDividend: "0",
          },
        ],
      };

      let error;
      let result;
      this.setState({ loading: true });
      [error, result] = await to(
        ktbsApi.post("form/bank_account/manp", bankAccountParam)
      );
      if (
        error ||
        !result ||
        !result.Data ||
        !result.Data[0] ||
        !result.Data[0][0] ||
        result.Data[0][0].IsSuccess !== 1
      ) {
        const mss = result
          ? result.Data[0].MsgDesc
          : error.response && error.response.data
          ? error.response.data.Data[0][0].MsgDesc
          : "";
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          imageUrl: "/images/ico_alert.svg",
          imageWidth: 100,
          imageHeight: 100,
          customClass: {
            confirmButton: "long_btn_fix",
          },
          confirmButtonColor: "#003b66",
          confirmButtonText: "กลับ",
          title: '<div class="title_popup">เกิดข้อผิดพลาด</div>',
          html: mss
            ? `<div class="content_popup">${mss}</div>`
            : '<div class="content_popup">ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง</div>',
        });
        this.setState({ loading: false });
        return;
      }

      const UploadBookBank = {
        head_token,
        id: "0",
        image_base64: this.state.bankUploadImg,
      };
      [error, result] = await to(
        ktbsApi.post("form/document/upload/bookbank", UploadBookBank)
      );
      if (
        error ||
        !result ||
        !result.Data ||
        !result.Data[0] ||
        !result.Data[0][0] ||
        result.Data[0][0].IsSuccess !== 1
      ) {
        const mss = result
          ? result.Data[0].MsgDesc
          : error.response && error.response.data
          ? error.response.data.Data[0][0].MsgDesc
          : "";
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          imageUrl: "/images/ico_alert.svg",
          imageWidth: 100,
          imageHeight: 100,
          customClass: {
            confirmButton: "long_btn_fix",
          },
          confirmButtonColor: "#003b66",
          confirmButtonText: "กลับ",
          title: '<div class="title_popup">เกิดข้อผิดพลาด</div>',
          html: mss
            ? `<div class="content_popup">${mss}</div>`
            : '<div class="content_popup">ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง</div>',
        });
        this.setState({ loading: false });
        return;
      }

      this.setState({ loading: false });
      localStorage.setItem("currentStep", "summaryshow");
      localStorage.setItem(
        "otherBank",
        JSON.stringify({
          bank: this.state.bank,
          bank_branch: this.state.bank_branch,
          bank_account_no: this.state.bank_account_no,
          bank_account_name: this.state.bank_account_name,
        })
      );

      localStorage.setItem(
        "atsData",
        JSON.stringify({
          ats_bank: "",
          ats_ref: "",
          ats_account_name: "",
          ats_account_no: "",
          ats_status: "",
          ats_card_id: "",
          ats_message: "",
        })
      );
      localStorage.setItem(
        "atsDataUser",
        JSON.stringify({
          bankCode: "Other",
          bankCodeOther: this.state.bank.label,
          bank_id: this.state.bank.value,
          bank_branch: this.state.bank_branch,
          bank_account_no: this.state.bank_account_no,
          bank_account_name: this.state.bank_account_name,
        })
      );

      this.props.history.push("/summaryshow");
    }
  };
  validate = async () => {
    $("span[class^=span_f0]").hide();
    let countError = 0;

    // console.log(this.state);

    if (!this.state.bank_account_name) {
      $(".span_f0_bank_account_name").css("display", "flex");
      countError++;
    }
    if (!this.state.bank_account_no || this.state.bank_account_no.length < 10) {
      $(".span_f0_bank_account_no").css("display", "flex");
      countError++;
    }
    if (!this.state.bank_branch.value) {
      $(".span_f0_bank_branch").css("display", "flex");
      countError++;
    }
    if (!this.state.bank.value) {
      $(".span_f0_bank").css("display", "flex");
      countError++;
    }

    if (!this.state.CheckbankUploadImg) {
      $(".span_f0_bankUploadImg").css("display", "flex");
      countError++;
    }

    return countError > 0 ? false : true;
  };
  handleIChange = async (v, ref) => {
    await this.setState({ [ref.el.input.name]: v });
  };

  handleChange = async (e) => {
    e.preventDefault();
    const isCheckbox = e.target.type === "checkbox";
    const value =
      e.target.name === "bank_account_no"
        ? e.target.value.replace(/[\D]+/g, "")
        : validator.trim(e.target.value);
    await this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : value,
    });
  };

  handleChangeOption = async (selectedOption, e) => {
    if (e.name === "bank") {
      this.getBankBranch(selectedOption.value, true);
    }
    await this.setState({ [e.name]: selectedOption });
    $("span[class=span_f0_" + e.name + "]").hide();
  };

  goBack() {
    this.props.history.push("ats_choice");
  }

  getBankBranch = async (bank_id, getnew) => {
    this.setState({ loading: true });
    await axios
      .get(`${config.baseAPIURL03}/constant/bank_branch/${bank_id}`)
      .then(async (res) => {
        const bank_branch_list = res.data.Data.map((item, i) => {
          return { value: item.Id, label: item.THName };
        });
        this.setState({ bank_branch_list: bank_branch_list });
        if (getnew) {
          this.setState({ bank_branch: "" });
        }
        await this.sleep(500);
        this.setState({ loading: false });
      })
      .catch((error) => {
        var mss = "";
        var title_mss = "";
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss =
            error.response.data.Data !== undefined &&
            error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data !== undefined &&
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
        popupErrorApi(mss, title_mss, true);
        return;
      });
    this.setState({ loading: false });
  };

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  image = (e) => {
    this.toggle_(e);
    this.fileuploadRef.current.click();
  };

  camera = async (e) => {
    this.toggle_(e);
    // this.setState({ loading: true });
    this.setState({ showCameraPopup: true, status_popup: false });
    // await this.sleep(1000);
    // this.setState({ loading: false });
  };

  handleSelectedFile = async (e) => {
    this.setState({ status_popup: false });

    const file = this.fileuploadRef.current.files[0];
    if (!file) {
      return;
    }
    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 10) {
      Swal.fire({
        type: "error",
        customClass: {
          confirmButton: "long_btn_fix",
          cancelButton: "long_btn_fix",
        },
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title:
          '<div class="title_popup">ไฟล์อัปโหลดมีขนาดเกินขนาดที่กำหนด</div>',
        html: '<div class="content_popup">ไฟล์ที่อัปโหลดจะต้องมีขนาดไม่เกิน 10 MB</div>',
      });
      return;
    }

    const reader = new FileReader();
    const vm = this;

    reader.addEventListener(
      "load",
      async function () {
        const imgSrc = reader.result;

        const canvas = createCanvas(800, 509);
        const ctx = canvas.getContext("2d");
        await loadImage(imgSrc).then(async (image) => {
          let maxWidth = 800;
          let maxHeight = 509;
          let width = image.width;
          let height = image.height;
          let ratio = 0;
          if (width > maxWidth) {
            ratio = maxWidth / width;
            width = Math.floor(maxWidth);
            height = Math.floor(height * ratio);
          }
          if (height > maxHeight) {
            ratio = maxHeight / height;
            width = Math.floor(width * ratio);
            height = Math.floor(maxHeight);
          }
          let x = (canvas.width - width) * 0.5;
          let y = (canvas.height - height) * 0.5;
          // console.log(width, height);
          await ctx.drawImage(image, x, y, width, height);

          // await vm.setState({
          //   bankUploadImg: canvas.toDataURL(),
          // });

          await localForage
            .setItem("bankUploadImgRow", canvas.toDataURL())
            .then(async function (value) {
              await vm.setState({ bankUploadImg: canvas.toDataURL() });
            })
            .catch(function (err) {
              //console.log(err);
            });
        });

        // localStorage.setItem('bankUploadImg', imgSrc);
        // vm.setState({ bankUploadImg: imgSrc });
        vm.drawImg1();
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  status_hide = (e) => {
    if (e.target.className === "background_opacity") {
      this.setState({ status_popup: false });
    }
  };
  handleClickCloseTakePhotoPopup = (e) => {
    e.preventDefault();
    this.setState({ showCameraPopup: false });
  };

  handleClickCapture = async () => {
    const vm = this;
    this.setState({ loading: true });
    const captureImage = this.webcamRef.current.getScreenshot();
    const canvas = createCanvas(800, 509);
    const ctx = canvas.getContext("2d");
    await loadImage(captureImage).then(async (image) => {
      let maxWidth = 800;
      let maxHeight = 800;
      let width = image.width;
      let height = image.height;
      let ratio = maxWidth / width;
      let imgWidth = Math.floor(width * ratio);
      let imgHeight = Math.floor(height * ratio);
      let top = Math.floor(height / 2);

      let x = (canvas.width - imgWidth) * 0.5;
      let y = (canvas.height - imgHeight) * 0.5;
      await ctx.drawImage(image, x, y, imgWidth, imgHeight);

      await localForage
        .setItem("bankUploadImgRow", canvas.toDataURL())
        .then(async function (value) {
          await vm.setState({ bankUploadImg: canvas.toDataURL() });
        })
        .catch(function (err) {
          //console.log(err);
        });

      // await vm.setState({
      //   bankUploadImg: canvas.toDataURL(),
      // });
      // await localStorage.setItem('bankUploadImg', canvas.toDataURL());
    });

    await this.sleep(200);

    await this.setState({
      showCameraPopup: false,
      loading: false,
    });

    await this.drawImg1();
    // this.sleep(1000);
  };

  async drawImg1() {
    const vm = this;
    const canvas = createCanvas(800, 508);
    // const canvas = document.getElementById("CursorLayer");
    const ctx = canvas.getContext("2d");
    const imgMark = new Image();
    imgMark.src = "/images/waterMark2.png?v=1";

    await loadImage(this.state.bankUploadImg).then(async (image) => {
      await loadImage("/images/waterMark2.png?v=1");

      await ctx.drawImage(image, 0, 0, 800, 508);
      if (image.width !== 800 || image.height !== 508) {
        await ctx.drawImage(imgMark, 0, 0, 800, 508);
      }
      $("#idPhoto1").html(
        "<img style='width:100%;display:block;border-radius:15px' src='" +
          canvas.toDataURL() +
          "'/>"
      );

      // await localStorage.setItem("bankUploadImg", canvas.toDataURL());
      await localForage
        .setItem("bankUploadImg", canvas.toDataURL())
        .then(async function (value) {
          await vm.setState({
            bankUploadImg: canvas.toDataURL(),
            CheckbankUploadImg: true,
          });
        })
        .catch(async function (err) {
          await vm.setState({ bankUploadImg: null, CheckbankUploadImg: false });
          //console.log(err);
        });
    });

    // console.log(localStorage.getItem('bankUploadImg'));
    return;
  }

  toggle_ = (e) => {
    var x =
      e.currentTarget.parentNode.getElementsByClassName("btn_popup_photo");
    var i;
    for (i = 0; i < x.length; i++) {
      x[i].classList.remove("active");
    }
    e.currentTarget.classList.add("active");
  };

  popup = () => {
    this.setState({ status_popup: true });
  };
  checkScreen = () => {
    if (isMobile) {
      // console.log('checkScreen');
      if (window.orientation !== undefined) {
        let checkScreen = window.orientation !== 0 ? false : true;
        if (!checkScreen) {
          document.getElementById("contentText").style.display = "block";
          document.getElementById("contentPhoto").style.display = "none";
          document.getElementById("under_fix_btn").style.display = "none";
        } else {
          document.getElementById("contentText").style.display = "none";
          document.getElementById("contentPhoto").style.display = "flex";
          document.getElementById("under_fix_btn").style.display = "block";
        }
      }
    }
  };
  render() {
    library.add(fas);
    const { showCameraPopup, loading } = this.state;
    const bankUploadImg = this.state.bankUploadImg;
    window.addEventListener(
      "orientationchange",
      (event) => {
        this.checkScreen();
      },
      false
    );
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          alt="forward"
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">
                        สมัครบริการหักบัญชีอัตโนมัติ
                      </div>
                    </div>

                    <div className="bg_content_white over_f_hide">
                      <div
                        className={"in_bg_white autoscroll"}
                        style={{
                          top: 0,
                          left: 0,
                          right: 0,
                          padding: "0px 21px 0px",
                        }}
                      >
                        <div className={"title_row"}>ข้อมูลบัญชีธนาคาร</div>
                        <div className={"row_idcard active"}>
                          <div>ชื่อเจ้าของบัญชี *</div>
                          <input
                            type="text"
                            onMouseOut={this.handleMouse}
                            disabled
                            className="input_idcard input--textfield"
                            name="bank_account_name"
                            value={this.state.bank_account_name}
                            onChange={this.handleChange}
                            maxLength="10"
                            style={{
                              borderColor:
                                this.state.bank_account_name !== ""
                                  ? "#003b66"
                                  : "#ccc",
                            }}
                          />

                          <span className="span_f0_bank_account_name">
                            กรุณาระบุชื่อเจ้าของบัญชี
                          </span>
                        </div>
                        <div className={"row_idcard"}>
                          <div>หมายเลขบัญชีธนาคาร *</div>
                          <input
                            type="text"
                            onMouseOut={this.handleMouse}
                            className="input_idcard input--textfield"
                            name="bank_account_no"
                            maxLength="10"
                            minLength="10"
                            pattern="[0-9]*"
                            value={this.state.bank_account_no}
                            onChange={this.handleChange}
                            style={{
                              borderColor:
                                this.state.bank_account_no !== ""
                                  ? "#003b66"
                                  : "#ccc",
                            }}
                          />
                          <span className="span_f0_bank_account_no">
                            กรุณาระบุหมายเลขบัญชีธนาคารให้ครบ 10 หลัก
                          </span>
                        </div>
                        <div className="row_idcard">
                          <div>ธนาคาร *</div>
                          <Select2
                            name="bank"
                            options={this.state.bank_list}
                            value={this.state.bank}
                            onChange={this.handleChangeOption}
                            placeholder="กรุณาเลือก"
                            className={
                              this.state.bank === ""
                                ? "select_two"
                                : "select_two active"
                            }
                          />

                          <span className="span_f0_bank">
                            กรุณาเลือก ธนาคาร
                          </span>
                        </div>
                        <div className="row_idcard">
                          <div>สาขาธนาคาร *</div>
                          <Select2
                            name="bank_branch"
                            options={this.state.bank_branch_list}
                            value={this.state.bank_branch}
                            onChange={this.handleChangeOption}
                            placeholder="กรุณาเลือก"
                            className={
                              this.state.bank_branch === ""
                                ? "select_two"
                                : "select_two active"
                            }
                          />
                          <span className="span_f0_bank_branch">
                            กรุณาเลือก สาขาธนาคาร
                          </span>
                        </div>
                        <div
                          className="row_idcard"
                          style={{ display: "block", marginTop: 20 }}
                        >
                          <div
                            style={{ display: "block" }}
                            onClick={this.image}
                          >
                            <div
                              style={{
                                width: "100%",
                                margin: "0 auto",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div className="DivFrameSnap" id="idPhoto1">
                                <img style={{ width: "100%" }} src={img} />
                                <div className="Div_ico_image_add">
                                  <div className="main_camera">
                                    <div className="row_add_camera">
                                      <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                                      <FontAwesomeIcon icon="camera"></FontAwesomeIcon>
                                    </div>
                                    <span>
                                      อัปโหลด/ถ่ายภาพหน้าสมุดธนาคาร * <br />
                                      หน้าแรกที่ปรากฎหมายเลขบัญชีและชื่อเจ้าของบัญชี
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <span
                            className="span_f0_bankUploadImg"
                            style={{ justifyContent: "center" }}
                          >
                            กรุณา อัปโหลดภาพ หรือ ถ่ายภาพหน้าสมุดธนาคาร
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="under_fix_btn">
                      <ButtonCustom
                        handle={this.handlesubmit}
                        label={"ถัดไป"}
                        color={"blue"}
                        btnClass="long_btn"
                      />
                    </div>

                    <input
                      type="file"
                      ref={this.fileuploadRef}
                      style={{ display: "none" }}
                      accept="image/png,image/jpeg"
                      onChange={this.handleSelectedFile}
                    />
                  </div>

                  <Loading status={this.state.loading}></Loading>

                  <div
                    className={`camera-popup ${showCameraPopup ? "show" : ""}`}
                  >
                    <div className="top_main_back">
                      <div
                        onClick={this.handleClickCloseTakePhotoPopup}
                        className="main_back"
                      >
                        <img
                          src="/images/angle-arrow-left.svg"
                          style={{ marginLeft: "15px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">
                        อัปโหลด/ถ่ายภาพหน้าสมุดธนาคาร
                      </div>
                    </div>
                    <div className="bg_content_blue">
                      <div className="in_bg_blue">
                        <div
                          id="contentText"
                          style={{ display: "none" }}
                          className="text_top_video"
                        >
                          กรุณาหมุนอุปกรณ์ให้เป็นแนวตั้งเพื่ออัปโหลด/ถ่ายภาพ
                        </div>
                        <div
                          id="contentPhoto"
                          className="content_photo"
                          style={{ maxHeight: "none" }}
                        >
                          <img
                            style={{ width: "99%", position: "absolute" }}
                            src={"/images/signed_frame.svg"}
                          />
                          {showCameraPopup && (
                            <Webcam
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: "#003b66",
                                top: "100px",
                              }}
                              audio={false}
                              ref={this.webcamRef}
                              screenshotFormat="image/png"
                              videoConstraints={videoConstraints}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="under_fix_btn" id="under_fix_btn">
                      <button
                        onClick={this.handleClickCapture}
                        className={"button-custom green long_btn_fix"}
                      >
                        ถ่ายภาพ
                      </button>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>

          <Popup
            showTakePhoto={false}
            showUpload={true}
            onClick={this.status_hide}
            onClick_2={this.image}
            onClick_3={this.camera}
            status={this.state.status_popup}
          ></Popup>
        </section>
      </div>
    );
  }
}

export default KtbstATSChoiceOther;
