import React, { Component } from "react";
import "./Pdpa.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import axios from "axios";
import iScroll from "iscroll";
import ReactIScroll from "react-iscroll";
import {
  checkInternet,
  popupNetworkLost,
  download_ico_alert,
  popupErrorApi,
} from "../../components/helpers/Helpers";
import Loading from "../../components/loading/loading";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
import { config } from "./../../constants";
class Pdpa extends Component {
  constructor(props) {
    super(props);
    this.myMain = React.createRef();
    this.myRef = React.createRef();
    this.state = {
      scrollTop: 0,
      color_bt: "gray",
      _posit: "",
      titleText: "",
      termConText: "",
      checkboxText: "",
      set_checkbox: false,
      isDisabled: true,
      itemstatus: true,
      read: false,
      iScrollOptions: {
        bounce: false,
        probeType: 2,
        mouseWheel: true,
        scrollbars: true,
        interactiveScrollbars: true,
        fadeScrollbars: true,
        scrollY: true,
      },
      loading: false,
      isDisconnected: false,
    };
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    clearInterval(this.interval);
    this.props.history.push("termandcon");
  }
  allowClick = (e) => {
    e.preventDefault();

    // if (checkInternet()) {
    const page_agree_old = JSON.parse(localStorage.getItem("page_agree"));
    let page_agree = {
      termandcon: page_agree_old.termandcon,
      pdpa: 1,
    };
    localStorage.setItem("page_agree", JSON.stringify(page_agree));
    localStorage.removeItem("checkuserinfo");
    this.props.history.push("checkuserinfo");
    // } else {
    //   this.setState({
    //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
    //   })
    // }
  };
  SubmitCheckbox = async (e) => {
    await this.setState({ set_checkbox: e.target.checked });
  };
  onScroll = async (e) => {
    //e.preventDefault();
    // const h_scroll_out = document.getElementById('height_scroll_out').offsetHeight;
    // const h_scroll_in = document.getElementById('height_scroll_in').offsetHeight;
    // const scrollTop = Math.ceil(this.myMain.current.scrollTop);
    // const posit = (h_scroll_in - h_scroll_out) - 5;
    // await this.setState({
    //   scrollTop: scrollTop,
    //   _posit: posit,
    // });
    // if (this.state._posit < this.state.scrollTop) {
    //   await this.setState({
    //     color_bt: 'blue',
    //     isDisabled: false,
    //   });
    //   this.setState({ read: true })
    // } else {
    //   await this.setState({
    //     color_bt: 'gray',
    //     isDisabled: true,
    //   });
    // }p
  };
  async componentDidMount() {
    await this.setState({ loading: true });
    // await download_ico_alert();
    const header = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios
      .get(config.baseURL + "/api/consent?id=pdpa", header) //https://ws03.ktbst.co.th/ktbst-life/single-application/constant/termcondition/pdpa
      .then((res) => {
        const data = res.data.data;
        data.forEach((row) => {
          this.setState({
            loading: false,
            checkboxText: row.checkBox,
            termConText: row.detail,
            titleText: row.title,
            buttonok: row.okBtn,
            buttoncancel: row.cancelBtn,
          });
        });

        // this.setState({ termConText: res.data.Data[0].detail });
        // this.setState({ button:res.data.Data[0].button[0].label_th });
        const h_scroll =
          document.getElementById("height_scroll_out").offsetHeight;
        const h_scroll2 =
          document.getElementById("height_scroll_in").offsetHeight;
        if (h_scroll > h_scroll2) {
          this.setState({
            color_bt: "green",
            isDisabled: false,
            read: true,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
        var mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        var title_mss = "เกิดข้อผิดพลาด";
        popupErrorApi(mss, title_mss, true);
      });
  }
  onScrollEnd = async (iScrollInstance) => {
    if ((await iScrollInstance.y) <= iScrollInstance.maxScrollY) {
      this.setState({
        color_bt: "blue",
        isDisabled: false,
      });
      this.setState({ read: true });
    }
  };
  onScroll_ = async (iScrollInstance) => {
    if ((await iScrollInstance.y) === iScrollInstance.maxScrollY) {
      this.setState({
        color_bt: "blue",
        isDisabled: false,
      });
      this.setState({ read: true });
    }
  };
  render() {
    const iScroll = require("iscroll/build/iscroll-probe");
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          alt="forward"
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">การคุ้มครองข้อมูลผู้ใช้</div>
                    </div>

                    <div className="bg_content_white over_f_hide">
                      <div
                        className="in_bg_white default"
                        id="height_scroll_out"
                        style={{ bottom: 154 }}
                      >
                        <ReactIScroll
                          id="my_scroll3"
                          iScroll={iScroll}
                          options={this.state.iScrollOptions}
                          onScrollEnd={this.onScrollEnd}
                          onScroll={this.onScroll_}
                        >
                          <div id="height_scroll_in">
                            <img
                              src="/images/pdpa.svg"
                              alt="pdpa"
                              className="img_pdpa"
                            />
                            <div className="text_title_head_termandcon">
                              {this.state.titleText}
                            </div>
                            <div
                              className="text_color_gray"
                              dangerouslySetInnerHTML={{
                                __html: this.state.termConText,
                              }}
                            />
                          </div>
                        </ReactIScroll>
                        {/* <div id='height_scroll_in'>
                        <img src='/images/pdpa.svg' alt="pdpa" className='img_pdpa' />
                        <div className='text_title_head_termandcon' style={{ position: "relative",paddingTop:0 }}>
                            แจ้งเตือนการปกป้องข้อมูลผู้ใช้งาน
                        </div>
                        <div className='text_color_gray' dangerouslySetInnerHTML={{__html:this.state.termConText}} />

                    </div> */}
                      </div>

                      <div
                        className="under_fix_btn"
                        style={{ background: "#fff" }}
                      >
                        <br />
                        {config.READ_CONSENT && (
                          <center
                            style={{
                              display: this.state.read ? "none" : "block",
                            }}
                          >
                            {config.READ_CONSENT}
                          </center>
                        )}
                        <br />
                        <label
                          className="container_border"
                          style={{
                            color: this.state.read ? "#003b66" : "#ccc",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.checkboxText,
                            }}
                          ></div>
                          <input
                            type="checkbox"
                            disabled={!this.state.read}
                            checked={this.state.set_checkbox}
                            onChange={(e) => this.SubmitCheckbox(e)}
                          />
                          <span className="checkmark"></span>
                        </label>

                        <ButtonCustom
                          handle={
                            this.state.set_checkbox ? this.allowClick : null
                          }
                          label={this.state.buttonok}
                          color={this.state.set_checkbox ? "blue" : "gray"}
                          btnClass="long_btn_fix"
                        />
                      </div>
                    </div>
                  </div>

                  {/* <SweetAlert
                    show={this.state.show}
                    title="Demo"
                    text="SweetAlert in React"
                    onConfirm={() => this.setState({ show: false })}
                /> */}

                  <Loading status={this.state.loading}></Loading>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Pdpa;
