import React, { useEffect, useState } from "react";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
import Loading from "../../components/loading/loading";
import { isMobile } from "react-device-detect";
import swal from "sweetalert2";
import "./Product_consent.css";
import axios from "axios";
import { config } from "./../../constants";
import ButtonCustom from "../../components/button/ButtonCustom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
  popupError,
  popupErrorApi,
  update_tracking,
} from "../../components/helpers/Helpers";
import ReactIScroll from "react-iscroll";
import withReactContent from "sweetalert2-react-content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const SUCCESS = 200;
const iScrollOptions = {
  bounce: false,
  probeType: 2,
  mouseWheelSpeed: 20,
  mouseWheel: true,
  scrollbars: true,
  interactiveScrollbars: true,
  fadeScrollbars: true,
  scrollY: true,
};
const _url = config.baseAPIURL03;
const Product_consent = (props) => {
  const iScroll = require("iscroll/build/iscroll-probe");
  const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadFinisged, setIsLoadFinisged] = useState(false);
  const [dataProduct, setDataProduct] = useState([]);
  const [productSelected, setProductSelected] = useState([]);
  const [productSelectedDf, setProductSelectedDf] = useState([]);
  const [checkPost, setCheckPost] = useState(false);
  const [valueOption, setValueOption] = useState("");
  const [strategic, setStrategic] = useState([]);
  const [nameAngle, setNameAngle] = useState("angle-down");
  const [state, setState] = useState({
    scrollTop: 0,
    _posit: "",
    set_checkbox: false,
    termCondition: [],
    termConText: "",
    checkboxText: "",
    termTitle: [],
    isDisconnected: false,
    loading: false,
    btnOk: "ยอมรับ",
    btnCancel: "ปฏิเสธ",
    id: "",
  });
  const [read, setRead] = useState({
    color_bt: "gray",
    read: false,
    isDisabled: true,
  });
  const goBack = () => {
    props.history.push("/tcconfirm");
  };
  const onScrollEnd = async (iScrollInstance) => {
    if ((await iScrollInstance.y) <= iScrollInstance.maxScrollY) {
      setRead({
        ...read,
        color_bt: "blue",
        isDisabled: false,
        read: true,
      });
    }
  };
  const onScroll_ = async (iScrollInstance) => {
    if ((await iScrollInstance.y) <= iScrollInstance.maxScrollY) {
      setRead({
        ...read,
        color_bt: "blue",
        isDisabled: false,
        read: true,
      });
    }
  };
  const SubmitCheckbox = async (e) => {
    if (
      strategic?.find((op) => op.id === state.id)?.data.length > 0 &&
      valueOption
    ) {
      await setState({ ...state, set_checkbox: e.target.checked });
    } else if (strategic?.find((op) => op.id === state.id)?.data.length === 0) {
      await setState({ ...state, set_checkbox: e.target.checked });
    } else {
      popupStrategic();
    }
  };
  const CellPost = async (selected) => {
    setIsLoading(true);
    let body = {
      head_token: HeaderManp.head_token,
      product_id: selected?.id,
      comfrimation: selected?.confirm,
      code: "PROD",
    };
    const product = [...productSelected];
    const productList = [];
    if (product.length > 0) {
      setProductSelectedDf([...product]);
    }
    product.forEach((pr) => {
      if (pr.id !== selected?.id) {
        productList.push(pr);
      }
    });
    try {
      await axios
        .post(`${config.baseAPIURL03}/form/signed_tnc/manp`, body)
        .then(async (res) => {
          if (res.request.status === SUCCESS) {
            if (
              res?.data?.Data?.[0]?.[0]?.IsSuccess === 1 &&
              selected?.confirm !== 0
            ) {
              setState({
                ...state,
                scrollTop: 0,
                _posit: "",
                set_checkbox: false,
                isDisconnected: false,
                loading: false,
              });
              setRead({
                ...read,
                color_bt: "gray",
                read: false,
                isDisabled: true,
              });
              setCheckPost(true);
              setProductSelected(productList);
              //
              if (valueOption) {
                setIsLoading(true);
                body = {
                  head_token: HeaderManp.head_token,
                  product_id: selected?.id,
                  strategic_id: valueOption,
                };
                try {
                  axios
                    .post(`${config.baseAPIURL03}/form/strategic/manp`, body)
                    .then((res) => {
                      if (res.request.status === SUCCESS) {
                        if (res?.data?.Data?.[0]?.[0]?.IsSuccess === 1) {
                          if (productList.length > 0) {
                            setValueOption("");
                          }
                        } else {
                          popupError(
                            res?.data?.Data?.[0]?.[0]?.MsgDesc,
                            res?.data?.Data?.[0]?.[0]?.MsgDescTitle
                          );
                        }
                      }
                      setIsLoading(false);
                    })
                    .catch((e) => {
                      if (!e.response) {
                        popupError(
                          "กรุณาทำรายการใหม่อีกครั้ง",
                          "เกิดข้อผิดพลาด"
                        );
                      } else {
                        popupError(
                          e.response.data?.Data?.[0]?.[0]?.MsgDesc,
                          e.response.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
                        );
                      }
                      setIsLoading(false);
                    });
                } catch (e) {
                  if (!e.response) {
                    popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
                  } else {
                    popupError(
                      e.response.data?.Data?.[0]?.[0]?.MsgDesc,
                      e.response.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
                    );
                  }
                  setIsLoading(false);
                }
              }

              //
            } else if (selected?.confirm !== 0) {
              popupError(
                res?.data?.Data?.[0]?.[0]?.MsgDesc,
                res?.data?.Data?.[0]?.[0]?.MsgDescTitle
              );
            }
          }
          setIsLoading(false);
        })
        .catch((e) => {
          if (!e.response) {
            popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
          } else {
            popupError(
              e.response.data?.Data?.[0]?.[0]?.MsgDesc,
              e.response.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
            );
          }
          setIsLoading(false);
        });
    } catch (e) {
      if (!e.response) {
        popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
      } else {
        popupError(
          e.response.data?.Data?.[0]?.[0]?.MsgDesc,
          e.response.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
        );
      }
      setIsLoading(false);
    }
  };
  const allowClick = (e) => {
    let selected = productSelected.find((e) => e.confirm === 0);
    if (selected) {
      selected = {
        ...selected,
        confirm: 1,
      };
    } else {
      selected = productSelectedDf.find((e) => e.confirm === 0);
      selected = {
        ...selected,
        confirm: 1,
      };
    }
    if (strategic?.find((op) => op.id === selected?.id)?.data.length === 0) {
      CellPost(selected);
    } else if (
      strategic?.find((op) => op.id === selected?.id)?.data.length > 0 &&
      valueOption
    ) {
      CellPost(selected);
    } else {
      popupStrategic();
    }
  };
  const popupStrategic = () => {
    const MySwal = withReactContent(swal);
    MySwal.fire({
      customClass: {
        confirmButton: "long_btn_fix",
        cancelButton: "long_btn_fix",
      },
      allowOutsideClick: false,
      imageUrl: "/images/ico_alert.svg",
      imageWidth: 100,
      imageHeight: 100,
      confirmButtonColor: "#003b66",
      confirmButtonText: "กลับ",
      title: '<div class="title_popup">กรุณาเลือกกลยุทธ์</div>',
      html: '<div class="content_popup">กรุณาเลือกกลยุทธ์</div>',
    }).then(function () {
      // window.location = "summaryshow";
    });
  };
  const popup = async (e) => {
    let selected = productSelected.find((e) => e.confirm === 0);
    if (selected) {
      selected = {
        ...selected,
        confirm: 0,
      };
    } else {
      selected = productSelectedDf.find((e) => e.confirm === 0);
      selected = {
        ...selected,
        confirm: 0,
      };
    }
    CellPost(selected);
    var data = {};
    data.head_token = "";
    data.card_id = "";
    data.stamp_page = "1";
    data.error_code = "1001";
    const MySwal = withReactContent(swal);
    MySwal.fire({
      customClass: {
        confirmButton: "long_btn_fix",
        cancelButton: "long_btn_fix",
      },
      allowOutsideClick: false,
      imageUrl: "/images/ico_alert.svg",
      imageWidth: 100,
      imageHeight: 100,
      // icon: 'warning',
      confirmButtonColor: "#003b66",
      confirmButtonText: "กลับ",
      title:
        '<div class="title_popup">ขออภัย ท่านไม่สามารถเปิดบัญชีออนไลน์ได้</div>',
      html: '<div class="content_popup"> กรุณาติดต่อ DAOL Contact Center<br> 0 2351 1800 หากต้องการดำเนินการต่อ กรุณาคลิกยอมรับ</div>',
    }).then(function () {
      // window.location = "summaryshow";
    });

    return;
  };
  const setConsent = async (product) => {
 
    const header = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios
      .get(config.baseURL + "/api/consent?id=" + product?.id, header)
      .then(async (res) => {
        setState({
          scrollTop: 0,
          _posit: "",
          set_checkbox: false,
          termCondition: [],
          termConText: "",
          checkboxText: "",
          termTitle: [],
          isDisconnected: false,
          loading: false,
          btnOk: "ยอมรับ",
          btnCancel: "ปฏิเสธ",
          id: "",
        });
        const data = res.data.data;
        data.forEach((row) => {
          setState({
            ...state,
            loading: false,
            checkboxText: row?.checkBox ?? "",
            termConText: row?.detail ?? "",
            titleText: row?.title ?? "",
            btnOk: row?.okBtn ?? "",
            btnCancel: row?.cancelBtn ?? "",
            id: product?.id,
          });
        });

        setRead({
          ...read,
          color_bt: "green",
          isDisabled: false,
        });
      })
      .catch((error) => {
        var mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        var title_mss = "เกิดข้อผิดพลาด";
        popupErrorApi(mss, title_mss, true);
      });
  };
  const handleChange = (value) => {
    setState({
      ...state,
      scroll: true,
    });
    setValueOption(value);
  };
  useEffect(() => {
    if (productSelected.length > 0) {
      setConsent(productSelected.find((e) => e.confirm === 0));
    } else if (productSelected.length === 0 && checkPost) {
      const con = JSON.parse(localStorage.getItem("tcconfirm")).confirm;
      if (con === 1) {
        props.history.push("tcconfirm");
      } else {
        setIsLoadFinisged(true);
        const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
        const head_token = HeaderManp.head_token;
        const atsDataUser = JSON.parse(localStorage.getItem("atsDataUser"))
          ? JSON.parse(localStorage.getItem("atsDataUser"))
          : {};
        const { bankCode } = atsDataUser;
        const httpClient = axios.create();
        httpClient.defaults.timeout = 1000 * 60 * 10;
        httpClient
          .post(
            _url + `/form/finished`,
            JSON.stringify({
              head_token: head_token,
              comfrimation: 1,
              use_other_bank: bankCode === "Other" ? 1 : 0,
            }),
            { headers: { "content-type": "application/json" } },
            { timeout: 1000 * 1 * 1 }
          )
          .then((res) => {
            setIsLoadFinisged(false);
            if (!res.data) {
              popup();
            }
            const MySwal = withReactContent(swal);
            MySwal.fire({
              customClass: {
                confirmButton: "long_btn_fix",
                cancelButton: "long_btn_fix",
              },
              imageUrl: "/images/ico_approve.svg",
              imageWidth: 100,
              imageHeight: 100,
              confirmButtonColor: "#003b66",
              confirmButtonText: "ตกลง",
              allowOutsideClick: false,
              allowEscapeKey: false,
              title: '<div class="title_popup">การเปิดบัญชีของท่านสำเร็จ</div>',
              html:
                '<div class="content_popup">' + config.CONSENT_POPUP + "</div>",
            }).then(function () {
              // vm.props.history.push('login');
              window.location.href = config.WEB_LOCATION;
            });
          })
          .catch((error) => {
            if (!!error.isAxiosError && !error.response) {
              popupError(
                "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง",
                "เกิดข้อผิดพลาด"
              );
            } else if (error.response.data.Data[0][0].IsSuccess === 0) {
              let errorMessage =
                error.response.data.Data[0][0].MsgDesc !== ""
                  ? error.response.data.Data[0][0].MsgDesc
                  : "เกิดข้อผิดพลาด";
              popupError(`${errorMessage}`, "เกิดข้อผิดพลาด");
            } else {
              popupError(
                "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง",
                "เกิดข้อผิดพลาด"
              );
            }
            setIsLoadFinisged(false);
          });
      }
    }
  }, [productSelected]);
  const CellStrategic = async (id, data_strate) => {
    setIsLoading(true);
    try {
      await axios
        .get(
          `${config.baseAPIURL03}/form/strategic/selbyhead?head_token=${HeaderManp.head_token}&product_id=${id}`
        )
        .then(async (res) => {
          if (res.request.status !== SUCCESS) {
            popupError(
              res.data?.Data?.[0]?.[0]?.MsgDesc,
              res.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
            );
          }
          setIsLoading(false);
          data_strate.push({ id: id, data: res?.data?.Data });
          setStrategic([...data_strate]);
        })
        .catch((e) => {
          if (!e.response) {
            popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
          } else {
            popupError(
              e.response.data?.Data?.[0]?.[0]?.MsgDesc,
              e.response.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
            );
          }
          setIsLoading(false);
        });
    } catch (e) {
      if (!e.response) {
        popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
      } else {
        popupError(
          e.response.data?.Data?.[0]?.[0]?.MsgDesc,
          e.response.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
        );
      }
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (read.read) {
      if (strategic?.find((op) => op.id === state.id)?.data.length === 0) {
      } else if (
        strategic?.find((op) => op.id === state.id)?.data.length > 0 &&
        valueOption !== ""
      ) {
      } else {
        setRead({
          ...read,
          color_bt: "blue",
          isDisabled: false,
          read: false,
        });
      }
    }
  }, [read]);
  useEffect(() => {
    if (dataProduct.length > 0) {
      const productItem = [];
      const data_strate = [];
      dataProduct.forEach((product) => {
        product.items.forEach((item) => {
          if (item.selected === 1) {
            if (
              item.id === 3 ||
              item.id === 4 ||
              item.id === 23 ||
              item.id === 12 ||
              item.id === 37 ||
              item.id === 5 ||
              item.id === 13 ||
              item.id === 35 ||
              item.id === 44 ||
              item.id === 43
            ) {
              CellStrategic(item?.id, data_strate);
              productItem.push({
                ...item,
                confirm: 0,
              });
            }
            setCheckPost(true);
          }
        });
      });
      if (productItem.length > 0) {
        localStorage.setItem("tcconfirm", JSON.stringify({ confirm: 0 }));
      } else {
        localStorage.setItem("tcconfirm", JSON.stringify({ confirm: 1 }));
      }
      setProductSelected(productItem);
    }
  }, [dataProduct]);
  useEffect(() => {
    setIsLoading(true);
    try {
      axios
        .get(
          `${config.baseAPIURL03}/form/product/selbyhead?head_token=${HeaderManp.head_token}`
        )
        .then((res) => {
          if (res.request.status !== SUCCESS) {
            popupError(
              res.data?.Data?.[0]?.[0]?.MsgDesc,
              res.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
            );
          }
          setDataProduct(res?.data?.Data || []);
          setIsLoading(false);
        })
        .catch((e) => {
          if (!e.response) {
            popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
          } else {
            popupError(
              e.response.data?.Data?.[0]?.[0]?.MsgDesc,
              e.response.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
            );
          }
          setIsLoading(false);
        });
    } catch (e) {
      if (!e.response) {
        popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
      } else {
        popupError(
          e.response.data?.Data?.[0]?.[0]?.MsgDesc,
          e.response.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
        );
      }
      setIsLoading(false);
    }
    setRead({
      color_bt: "gray",
      read: false,
      isDisabled: true,
    });
  }, []);
  return (
    <div className={isMobile ? "" : "body"}>
      <header
        className={isMobile ? "" : "header-area"}
        style={{ display: isMobile ? "none" : "block" }}
      >
        <div className={"container everest-nav-container"}>
          <div className={"row"}>
            <div className={"col-md-6"}>
              <img src="../../../images/logo.png" />
            </div>
            <div className={"col-md-6 flex_end"}>
              <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
            </div>
          </div>
        </div>
      </header>
      <section
        className={isMobile ? "" : "desktop_content section-gap-full"}
        style={{
          backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
        }}
      >
        <div className={isMobile ? "" : "container"}>
          <div className={isMobile ? "" : "row"}>
            <div
              className={"col-md-6"}
              style={{ display: isMobile ? "none" : "block" }}
            >
              <BannerContentLeft></BannerContentLeft>
            </div>
            <div className={isMobile ? "" : "col-md-6"}>
              {/* content mobile */}
              <div>
                <Loading status={isLoadFinisged} textshow={true} />
                <Loading status={isLoading} />
                <div className="content">
                  <div className="top_main_back">
                    <div className="main_back" onClick={goBack}>
                      <img
                        src={
                          isMobile
                            ? "/images/angle-arrow-left.svg"
                            : "/images/angle-arrow-left-blue.svg"
                        }
                        style={{ marginLeft: "3px" }}
                        className="arrow_back"
                      />
                    </div>
                    <div className="text_title">เงื่อนไขและข้อตกลง</div>
                  </div>

                  <div className="bg_content_white over_f_hide" id="my_scroll">
                    <div
                      style={{ bottom: isMobile ? 185 : 150 }}
                      id="my_scroll2"
                      className="in_bg_white default"
                    >
                      <ReactIScroll
                        id="my_scroll3"
                        iScroll={iScroll}
                        options={iScrollOptions}
                        onScrollEnd={onScrollEnd}
                        onScroll={onScroll_}
                      >
                        <div id="height_scroll_in">
                          <div
                            className="text_title_head_termandcon"
                            style={{ position: "relative" }}
                          >
                            {state.titleText}
                          </div>
                          {strategic?.find((op) => op.id === state.id)?.data
                            .length > 0 && (
                            <div
                              className="text_color_gray"
                              style={{ paddingTop: "0", paddingBottom: "0" }}
                            >
                              <div
                                style={{
                                  fontSize: "24px",
                                  fontWeight: "400",
                                }}
                              >
                                กรุณาเลือกประเภทบริการ KTBST Smart Algorithmic
                                Trading แบบใช้กลยุทธ์ลงทุนสำเร็จ
                              </div>
                              <div className="main_select">
                                {strategic?.find((op) => op.id === state.id)
                                  ?.data.length > 0 && (
                                  <>
                                    <select
                                      name="f0_outcome"
                                      className="select_style"
                                      value={valueOption}
                                      onChange={(e) =>
                                        handleChange(e.target.value)
                                      }
                                      style={{
                                        color:
                                          valueOption === ""
                                            ? "#003b6694"
                                            : "#003b66",
                                      }}
                                      onSelect={() => console.log("ok")}
                                      onClick={(e) => {
                                        if (nameAngle === "angle-up") {
                                          setNameAngle("angle-down");
                                        } else {
                                          setNameAngle("angle-up");
                                        }
                                      }}
                                    >
                                      <option
                                        value=""
                                        style={{ color: "#003b6694" }}
                                        disabled
                                        hidden
                                      >
                                        กรุณาเลือกกลยุทธ์
                                      </option>

                                      {strategic
                                        ?.find((op) => op.id === state.id)
                                        .data.map((opt, index) => (
                                          <option key={index} value={opt.id}>
                                            {opt.name}
                                          </option>
                                        ))}
                                    </select>
                                    <FontAwesomeIcon icon={nameAngle} />
                                  </>
                                )}
                              </div>
                              <label
                                style={{
                                  color:
                                    valueOption === ""
                                      ? "#003b6694"
                                      : "#003b66",
                                }}
                              >
                                {
                                  strategic
                                    ?.find((op) => op.id === state.id)
                                    ?.data?.find(
                                      (opt) => opt.id === valueOption
                                    )?.detail
                                }
                              </label>
                            </div>
                          )}
                          {strategic?.find((op) => op.id === state.id)?.data
                            .length === 0 && (
                            <div
                              className="text_color_grayPro"
                              dangerouslySetInnerHTML={{
                                __html: state.termConText,
                              }}
                            />
                          )}
                          {strategic?.find((op) => op.id === state.id)?.data
                            .length > 0 &&
                            valueOption && (
                              <div
                                className="text_color_grayPro"
                                dangerouslySetInnerHTML={{
                                  __html: state.termConText,
                                }}
                              />
                            )}
                        </div>
                      </ReactIScroll>
                    </div>

                    <div
                      style={{
                        background: "#fff",
                        minHeight: isMobile ? "185px" : "150px",
                        bottom: 0,
                        position: "absolute",
                        justifyContent: "flex-end",
                        left: "21px",
                        right: "21px",
                      }}
                    >
                      <div style={{ paddingTop: "2%" }}>
                        {config.READ_CONSENT && (
                          <center
                            style={{ display: read.read ? "none" : "block" }}
                          >
                            {config.READ_CONSENT}
                          </center>
                        )}
                        <label
                          className="container_border"
                          style={{
                            color: read.read ? "#333333" : "#ccc",
                            fontSize: "18px",
                          }}
                        >
                          <div
                            style={{
                              color: read.read ? "#333333" : "#ccc",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: state.checkboxText,
                            }}
                          />
                          <input
                            type="checkbox"
                            disabled={!read.read}
                            checked={state.set_checkbox}
                            onChange={(e) => SubmitCheckbox(e)}
                          />
                          <span className="checkmark"></span>
                        </label>
                        <div>
                          {state.btnCancel && (
                            <ButtonCustom
                              handle={state.set_checkbox ? popup : null}
                              label={state.btnCancel}
                              color={state.set_checkbox ? "red" : "gray"}
                              btnClass="btn_small_left"
                            />
                          )}
                          <ButtonCustom
                            handle={state.set_checkbox ? allowClick : null}
                            label={state.btnOk}
                            color={state.set_checkbox ? "green" : "gray"}
                            btnClass={
                              state.btnCancel
                                ? "btn_small_right"
                                : "long_btn_fix"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end content mobile */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Product_consent;
