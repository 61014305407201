/* eslint-disable jsx-a11y/alt-text */

import React, { Component, useState } from "react";
import "./KtbstIDCDetail.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert2";
import axios from "axios";
import qs from "qs";
import validator from "validator";
import Select2 from "react-select";
import moment from "moment";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../components/loading/loading";
import { checkEditPage } from "../../components/helpers/Helpers";
import DatePickerInput from "./../../components/date_picker_input/DatePickerInput";
import { sub, parse, format } from "date-fns";
import $ from "jquery";
import {
  popupError,
  popupErrorApi,
  checkEditData,
} from "../../components/helpers/Helpers";
import { IMaskInput } from "react-imask";
import { el } from "date-fns/locale";
import { isNull } from "lodash";
import { config } from "./../../constants";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
const _url = config.baseAPIURL03;
class KtbstIDCDetail extends Component {
  constructor(props) {
    super(props);
    const checkuserinfo = JSON.parse(localStorage.getItem("checkuserinfo"));
    const fillDetail = JSON.parse(localStorage.getItem("fillDetail"));
    const f0 = fillDetail ? JSON.parse(localStorage.getItem("f0")) : "";
    const comebackWithOtp = JSON.parse(localStorage.getItem("comebackWithOtp"));
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate();
    this.state = {
      comebackWithOtp: comebackWithOtp ? true : false,
      to_day: new Date(year, month, day),
      min_year_create: new Date(year - 20, month, day),
      max_year_old: new Date(year - 120, month, day),
      isSubmit: false,
      isDisconnected: false,
      // f0_title: 43,
      // f0_firstname: 'เมธินี',
      // f0_lastname: 'ผ่องแผ้ว',
      f0_firstname_eng: "ศุภฤกษ์",
      f0_middlename_eng: "",
      f0_lastname_eng: "จิตติวนาวรรณ",
      f0_national_id: "1509901433502",
      f0_lazer_card: "ME0-1161604-31",
      f0_date_birth: "06051994",
      // f0_email: 'luiapi.sys@gmail.com',
      // f0_mobile: '0889050896',
      // f0_expired_date: "29112021",
      f0_title: fillDetail ? f0.f0_title : "",
      f0_title_text: fillDetail ? f0.f0_title_text : "",
      f0_firstname: fillDetail ? f0.f0_firstname : "",
      f0_lastname: fillDetail ? f0.f0_lastname : "",
      f0_middlename: fillDetail ? f0.f0_middlename : "",
      f0_title_eng: fillDetail ? f0.f0_title_eng : "",
      f0_title_text_eng: fillDetail ? f0.f0_title_text_eng : "",
      f0_firstname_eng: fillDetail ? f0.f0_firstname_eng : "",
      f0_middlename_eng: fillDetail ? f0.f0_middlename_eng : "",
      f0_lastname_eng: fillDetail ? f0.f0_lastname_eng : "",
      f0_national_id: checkuserinfo ? checkuserinfo.cardId : "",
      f0_lazer_card: fillDetail ? f0.f0_lazer_card : "",
      f0_date_birth: fillDetail ? f0.f0_date_birth : "",
      // f0_nationality: checkuserinfo && checkuserinfo.cardId ? 212 : { label: "ไทย", value: "212" },
      f0_nationality: {
        label: fillDetail ? f0.f0_nationality.label : "ไทย",
        value: fillDetail ? f0.f0_nationality.value : "212",
      },
      f0_email: checkuserinfo ? checkuserinfo.f0_email : "",
      f0_mobile: checkuserinfo ? checkuserinfo.f0_mobile_phone : "",
      f0_expired_date: fillDetail ? f0.f0_expired_date : "",
      // f0_expired_date: '',
      time: new Date(),
      isOpen: false,
      time2: new Date(year - 20, month, day),
      isOpen2: false,
      index_title_other: "",
      title: [],
      title_eng: [],
      title_arr: [],
      nationality: [],
      item: [],
      IdCardError: "",
      Title_otherError: "",
      Title_other_engError: "",
      LazerIdError: "",
      PhoneNoError: "",
      BirthError: "",
      EmailError: "",
      nationalityError: "",
      title_other: false,
      loading: true,
      unlimit_idcard: f0.f0_expired_date === "99991231",
      mms: "",
      f0_title_eng_value: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeOption = this.handleChangeOption.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.goBack = this.goBack.bind(this);
    // localStorage.removeItem('f0');
  }

  goBack() {
    if (checkEditPage()) {
      return;
    }
    var path = window.location.href.indexOf("edit");
    if (path === -1) {
      const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
      if (HeaderManp === null) {
        localStorage.removeItem("photo1");
        localStorage.removeItem("photo2");
      }
      if (this.state.comebackWithOtp) {
        const isValid = this.validate();
        if (isValid) {
          let f0 = {
            f0_title: this.state.f0_title,
            f0_title_text: this.state.f0_title_text,
            f0_firstname: this.state.f0_firstname,
            f0_middlename: this.state.f0_middlename,
            f0_lastname: this.state.f0_lastname,
            f0_firstname_eng: this.state.f0_firstname_eng,
            f0_middlename_eng: this.state.f0_middlename_eng,
            f0_lastname_eng: this.state.f0_lastname_eng,
            f0_national_id: this.state.f0_national_id,
            f0_lazer_card: this.state.f0_lazer_card,
            f0_date_birth: this.state.f0_date_birth,
            f0_nationality: this.state.f0_nationality,
            f0_email: this.state.f0_email,
            f0_mobile: this.state.f0_mobile,
            f0_expired_date: this.state.f0_expired_date,
            f0_title_eng: this.state.f0_title_eng,
            f0_title_text_eng: this.state.f0_title_text_eng,
          };
          localStorage.setItem("f0", JSON.stringify(f0));
          checkEditData();
        }
      }
      this.props.history.push("/SnapIdCard");
    } else {
      this.props.history.push("/summaryshow");
      console.log(2);
    }
  }

  handleChangeDate = (date) => {
    let dateObj = date;
    let month = dateObj.getMonth();
    let day = String(dateObj.getDate()).padStart(2, "0");
    let year = dateObj.getFullYear();
    let output = year + "" + month + "" + day;
    // console.log(output);
    this.setState({ f1_date_birth: output });
    let thaidate = this.handleFormatDate(date);
    this.setState({ thaidate: thaidate });
  };

  handleFormatDate = (date) => {
    let dateObj = date;
    const monthNames = [
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];
    let month = monthNames[dateObj.getMonth()];
    let day = String(dateObj.getDate()).padStart(2, "0");
    let year = dateObj.getFullYear();
    let output = day + " " + month + " " + year;
    return output;
  };

  async check_Dopa(data) {
    return await axios
      .post(
        _url + "/form/header/verify"
        , qs.stringify(data)
      )
      .then(async (res) => {
        if (res.data.Data[0][0].MsgDesc) {
          this.setState({ mms: res.data.Data[0][0].MsgDesc });
        }
        return res.data.Data[0][0].IsSuccess;
      })
      .catch((error) => {
        const isDev = process.env.REACT_APP_ENVIRONMENT === "development";
        if (isDev) {
          return 1;
        }
        else {
          var mss = "";
          var title_mss = "";
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            title_mss =
              error.response.data.Data !== undefined &&
                error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              error.response.data.Data !== undefined &&
                error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
          popupErrorApi(mss, title_mss, true);
        }
      });

  }

  async componentDidMount() {
    await this.setState({ loading: true });
    let vm = this;
    await axios
      .get(_url + "/constant/title")
      .then((res) => {
        const title = res.data.Data.map((item, i) => {
          if (item.Other === 1) {
            const fillDetail = JSON.parse(localStorage.getItem("fillDetail"));
            const f0 = fillDetail ? JSON.parse(localStorage.getItem("f0")) : "";
            this.setState({
              title_other: f0.f0_title === item.Id ? true : false,
              index_title_other: item.Id,
            });
          }
          return (
            <option value={item.Id} key={i} style={{ color: "#003b66" }}>
              {item.NameTH}
            </option>
          );
        });
        this.setState({ title: title });

        const title_eng = res.data.Data.map((item, i) => {
          //console.log(res.data.Data)
          return (
            <option value={item.Id} key={i} style={{ color: "#003b66" }}>
              {item.NameEN}
            </option>
          );
        });
        this.setState({ title_eng: title_eng });
        const title_arr = res.data.Data.map((item, i) => {
          return { value: item.Id, text_th: item.NameTH, text_en: item.NameEN };
        });
        this.setState({
          title_arr: title_arr,
          f0_title_eng_value:
            this.state.f0_title !== ""
              ? title_arr.find(({ value }) => value === this.state.f0_title)
              : "",
        });
        // }).catch(function (error) {
      })
      .catch((error) => {
        vm.setState({ loading: false });
        if (!!error.isAxiosError && !error.response) {
          popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
        } else {
          let errorMessage = error.response ? error.response.data.message : "";
          popupError(`axios or api error ${errorMessage}`, "เกิดข้อผิดพลาด");
        }
      });

    await axios
      .get(_url + "/constant/country")
      .then((res) => {
        const nationality = res.data.Data.map((item, i) => {
          return { value: item.Id, label: item.ThName };
        });
        this.setState({
          nationality: nationality,
          loading: false,
        });
      })
      .catch(function (error) {
        vm.setState({ loading: false });
        if (!!error.isAxiosError && !error.response) {
          popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
        } else {
          let errorMessage = error.response ? error.response.data.message : "";
          popupError(`axios or api error ${errorMessage}`, "เกิดข้อผิดพลาด");
        }
      });
  }

  validate = async () => {
    $("span[class^=span_f0]").hide();
    let countError = 0;

    if (!validator.isLength(this.state.f0_lazer_card, { min: 12, max: 12 })) {
      $(".span_f0_lazer_card").css("display", "flex");
      countError++;
    }
    if (validator.isEmpty(this.state.f0_date_birth)) {
      $(".span_f0_date_birth").css("display", "flex");
      countError++;
    }
    if (validator.isEmpty(this.state.f0_expired_date)) {
      $(".span_f0_expired_date").css("display", "flex");
      countError++;
    }
    if (validator.isEmpty(this.state.f0_title)) {
      $(".span_f0_title").css("display", "flex");
      countError++;
    }
    if (
      this.state.f0_title === this.state.index_title_other &&
      validator.isEmpty(this.state.f0_title_text)
    ) {
      $(".span_f0_title_text").css("display", "flex");
      countError++;
    }
    if (validator.isEmpty(this.state.f0_firstname)) {
      $(".span_f0_firstname").css("display", "flex");
      countError++;
    }
    if (validator.isEmpty(this.state.f0_lastname)) {
      $(".span_f0_lastname").css("display", "flex");
      countError++;
    }
    if (validator.isEmpty(this.state.f0_firstname_eng)) {
      $(".span_f0_firstname_eng").css("display", "flex");
      countError++;
    }
    if (validator.isEmpty(this.state.f0_lastname_eng)) {
      $(".span_f0_lastname_eng").css("display", "flex");
      countError++;
    }
    return countError > 0 ? false : true;
  };

  handleChange = async (e) => {
    e.preventDefault();
    const isCheckbox = e.target.type === "checkbox";
    if (e.target.name === "f0_title") {
      const title_choose = this.state.title_arr.find(
        ({ value }) => value === e.target.value
      );
      this.setState({
        f0_title_eng: e.target.value,
        f0_title_eng_value: title_choose ? title_choose : "",
        f0_title_text:
          title_choose && title_choose.text_en.toLowerCase() !== "other"
            ? title_choose.text_th
            : "",
        f0_title_text_eng:
          title_choose && title_choose.text_en.toLowerCase() !== "other"
            ? title_choose.text_en
            : "",
      });
      if (e.target.value === this.state.index_title_other) {
        this.setState({ title_other: true });
      } else {
        this.setState({
          title_other: false,
          Title_otherError: "",
          f0_title_text: "",
        });
      }
    }
    if (e.target.name === "f0_lazer_card") {
      //   console.log(phoneMask.value);
    }

    await this.setState({
      [e.target.name]: isCheckbox
        ? e.target.checked
        : e.target.name === "f0_firstname" ||
          e.target.name === "f0_middlename" ||
          e.target.name === "f0_lastname" ||
          e.target.name === "f0_firstname_eng" ||
          e.target.name === "f0_middlename_eng" ||
          e.target.name === "f0_lastname_eng" ||
          e.target.name === "f0_title_text"
          ? e.target.value
          : validator.trim(e.target.value),
    });
    if (this.state.isSubmit) {
      await this.validate();
    }
  };

  handleIChange = async (v, ref) => {
    await this.setState({
      [ref.el.input.name]:
        ref.el.input.name === "f0_firstname" ||
          ref.el.input.name === "f0_middlename" ||
          ref.el.input.name === "f0_lastname" ||
          ref.el.input.name === "f0_firstname_eng" ||
          ref.el.input.name === "f0_middlename_eng" ||
          ref.el.input.name === "f0_lastname_eng" ||
          ref.el.input.name === "f0_title_text"
          ? v
          : validator.trim(v),
    });
    if (this.state.isSubmit) {
      await this.validate();
    }
  };

  back_idcard = (v) => {
    v = v.toUpperCase();
    var regex = /[^a-zA-Z0-9]/g;
    let t = v.replace(regex, "");
    this.setState({ f0_lazer_card: validator.trim(t) });
    if (this.state.isSubmit) {
      this.validate();
    }
  };

  handleChangeOption = async (selectedOption, e) => {
    await this.setState({ [e.name]: selectedOption });
  };

  async handleSubmit(e) {
    const MySwal = withReactContent(swal);
    this.setState({ isSubmit: true });
    e.preventDefault();
    const isValid = await this.validate();
    await this.setState({
      f0_title_text: validator.trim(this.state.f0_title_text),
      f0_firstname: validator.trim(this.state.f0_firstname),
      f0_lastname: validator.trim(this.state.f0_lastname),
      f0_firstname_eng: validator.trim(this.state.f0_firstname_eng),
      f0_lastname_eng: validator.trim(this.state.f0_lastname_eng),
      f0_title_text_eng: validator.trim(this.state.f0_title_text_eng),
    });
    if (isValid) {
      // if (checkInternet()) {
      this.setState({ loading: true });
      const birthdateObject = parse(
        this.state.f0_date_birth,
        "yyyyMMdd",
        new Date()
      );
      const birthday =
        parseInt(format(birthdateObject, "yyyy")) +
        543 +
        format(birthdateObject, "MMdd");

      // let birthdayYear = Number(this.state.f0_date_birth.substring(4)) + 543;
      // let birthday =
      //   birthdayYear +
      //   this.state.f0_date_birth.substring(2, 4) +
      //   this.state.f0_date_birth.substring(0, 2);

      let verifyData = {
        card_id: this.state.f0_national_id,
        firstname_th: this.state.f0_firstname,
        lastname_th: this.state.f0_lastname,
        birthday: birthday,
        lazer_card: this.state.f0_lazer_card.toUpperCase(),
      };

      let checkDopaStatus = await this.check_Dopa(verifyData);
      var str = window.location.href;
      let re = /edit/;
      const back_to_edit = re.test(str);
      if (back_to_edit) {
        if (checkDopaStatus === 1) {
          let f0 = {
            f0_title: this.state.f0_title,
            f0_title_text: this.state.f0_title_text,
            f0_firstname: this.state.f0_firstname,
            f0_middlename: this.state.f0_middlename,
            f0_lastname: this.state.f0_lastname,
            f0_firstname_eng: this.state.f0_firstname_eng,
            f0_middlename_eng: this.state.f0_middlename_eng,
            f0_lastname_eng: this.state.f0_lastname_eng,
            f0_national_id: this.state.f0_national_id,
            f0_lazer_card: this.state.f0_lazer_card,
            f0_date_birth: this.state.f0_date_birth,
            f0_nationality: this.state.f0_nationality,
            f0_email: this.state.f0_email,
            f0_mobile: this.state.f0_mobile,
            f0_expired_date: this.state.f0_expired_date,
            f0_title_eng: this.state.f0_title_eng,
            f0_title_text_eng: this.state.f0_title_text_eng,
          };
          // await localStorage.setItem('passDopa',true);
          await localStorage.setItem("f0", JSON.stringify(f0));

          await this.props.history.push(
            // '/otp?phone_number=' + this.state.f0_mobile
            "/summaryshow"
          );
          return;
        } else {
          var mms =
            this.state.mms !== ""
              ? this.state.mms
              : "กรุณาตรวจสอบข้อมูลอีกครั้ง";
          MySwal.fire({
            imageUrl: "/images/ico_alert.svg",
            imageWidth: 100,
            imageHeight: 100,
            customClass: {
              confirmButton: "long_btn_fix",
            },
            confirmButtonColor: "#003b66",
            confirmButtonText: "กลับ",
            title:
              '<div class="title_popup">ขออภัยข้อมูลของท่านไม่ถูกต้อง</div>',
            html: '<div class="content_popup">' + mms + "</div>",
          });
          this.setState({ loading: false });
          return;
        }
      }

      if (checkDopaStatus === 1) {
        let f0 = {
          f0_title: this.state.f0_title,
          f0_title_text: this.state.f0_title_text,
          f0_firstname: this.state.f0_firstname,
          f0_middlename: this.state.f0_middlename,
          f0_lastname: this.state.f0_lastname,
          f0_firstname_eng: this.state.f0_firstname_eng,
          f0_middlename_eng: this.state.f0_middlename_eng,
          f0_lastname_eng: this.state.f0_lastname_eng,
          f0_national_id: this.state.f0_national_id,
          f0_lazer_card: this.state.f0_lazer_card,
          f0_date_birth: this.state.f0_date_birth,
          f0_nationality: this.state.f0_nationality,
          f0_email: this.state.f0_email,
          f0_mobile: this.state.f0_mobile,
          f0_expired_date: this.state.f0_expired_date,
          f0_title_eng: this.state.f0_title_eng,
          f0_title_text_eng: this.state.f0_title_text_eng,
        };
        // await localStorage.setItem('passDopa',true);
        await localStorage.setItem("f0", JSON.stringify(f0));
        if (this.state.comebackWithOtp) {
          checkEditData();
        }
        await localStorage.setItem("fillDetail", true);

        const ops2 = {
          method: "POST",
          headers: { "content-type": "application/json" },
          data: JSON.stringify({
            // f0_title: 43,
            // f0_title_text: "test",
            // f0_national_country_id: 212,
            // f0_firstname: "พัทธกานต์",
            // f0_lastname: "จิรายุสกุล",
            // f0_firstname_eng: "phatthkarn",
            // f0_lastname_eng: "jirayusakul",
            // f0_national_id: "1729900351764",
            // f0_lazer_card: "ME1-1250785-19",
            // f0_date_birth: "19951006",
            // f0_email: "phatthakarn.ji@gmail.com",
            // f0_mobile: "0806585052",
            // f0_investment_consultant_id: "THKR",
            // f0_consent_fatca: true,
            // f0_consent_openaccount: true,
            f0_title: f0.f0_title,
            f0_title_text: f0.f0_title_text,
            f0_title_eng: f0.f0_title_eng,
            f0_title_text_eng: f0.f0_title_text_eng,
            f0_national_country_id: 212, //ประเทศไทย
            f0_firstname: f0.f0_firstname,
            f0_middlename: f0.f0_middlename,
            f0_lastname: f0.f0_lastname,
            f0_firstname_eng: f0.f0_firstname_eng,
            f0_middlename_eng: f0.f0_middlename_eng,
            f0_lastname_eng: f0.f0_lastname_eng,
            f0_national_id: f0.f0_national_id,
            f0_lazer_card: f0.f0_lazer_card,
            f0_date_birth: this.state.f0_date_birth,
            f0_expire_date: f0.f0_expired_date,
            f0_email: f0.f0_email,
            f0_mobile: f0.f0_mobile,
            f0_investment_consultant_id: "",
            f0_consent_fatca: false,
            f0_consent_openaccount: true,
          }),
          url: "form/header/manp",
        };

        let error;
        let result;
        this.setState({ loading: false });
        this.props.history.push("/fatca");

        return;
      } else {
        this.setState({ loading: false });
        await localStorage.setItem("passDopa", false);
        this.notPassNDID(checkDopaStatus);
      }
      // } else {
      //   this.setState({
      //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
      //   })
      // }
    } else {
      var items = document.querySelectorAll("[class*=span_f0]");
      for (var i = 0; i < items.length; i++) {
        if (items[i].style.display === "flex") {
          return items[i].parentNode.scrollIntoView({ behavior: "smooth" });
        }
      }
    }

    return false;
  }

  SetBirthDay = () => {
    // console.log(this.state.f0_date_birth);
  };

  notPassNDID = (msg) => {
    const MySwal = withReactContent(swal);
    var mms =
      this.state.mms !== ""
        ? this.state.mms
        : "กรุณาตรวจสอบข้อมูลในหน้าตรวจสอบตัวตนอีกครั้ง";
    var h_mms =
      this.state.mms !== ""
        ? "เกิดข้อผิดพลาด"
        : "ขออภัยข้อมูลของท่านไม่ถูกต้อง";
    // msg = msg ? msg : 'กรุณาตรวจสอบข้อมูลในหน้าตรวจสอบตัวตนอีกครั้ง';
    MySwal.fire({
      customClass: {
        confirmButton: "long_btn_fix",
        cancelButton: "long_btn_fix",
      },
      imageUrl: "/images/ico_alert.svg",
      imageWidth: 100,
      imageHeight: 100,
      confirmButtonColor: "#003b66",
      confirmButtonText: "กลับ",
      title: '<span class="title_popup">' + h_mms + "</span>",
      html: '<div class="content_popup">' + mms + "</div>",
    });
  };

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleSelect = async (time) => {
    let newTime = moment(time).format("DDMMYYYY");
    // console.log(newTime);
    await this.setState({ time, isOpen: false, f0_expired_date: newTime });
    // await this.validate();
  };

  handleCancel = () => {
    this.setState({ isOpen: false });
  };
  //#############################################//
  handleClick2 = () => {
    this.setState({ isOpen2: true });
  };

  handleSelect2 = async (time2) => {
    let newTime = moment(time2).format("DDMMYYYY");
    await this.setState({ time2, isOpen2: false, f0_date_birth: newTime });
    // await this.validate();
  };

  handleCancel2 = () => {
    this.setState({ isOpen2: false });
  };

  check_unlimit_idcard = (e) => {
    let setValue = "";
    if (e.target.checked) {
      setValue = "99991231";
    }
    this.setState({
      f0_expired_date: setValue,
      unlimit_idcard: e.target.checked,
    });
  };

  converToTextDate = (date) => {
    let output = " ";
    if (date) {
      let dateObj = date;
      const monthNames = [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ];
      let month = monthNames[parseFloat(dateObj.substr(2, 2) - 1)];
      let day = dateObj.substr(0, 2);
      let year = dateObj.substr(4);
      output = day + " " + month + " " + year;
    }
    return output;
  };

  monthMap = {
    1: "ม.ค.",
    2: "ก.พ.",
    3: "มี.ค.",
    4: "เม.ย.",
    5: "พ.ค.",
    6: "มิ.ย.",
    7: "ก.ค.",
    8: "ส.ค.",
    9: "ก.ย.",
    10: "ต.ค.",
    11: "พ.ย.",
    12: "ธ.ค.",
  };

  dateConfig = {
    year: {
      format: "YYYY",
      caption: "Year",
      step: 1,
    },
    month: {
      format: (value) => this.monthMap[value.getMonth() + 1],
      // format: 'MM',
      caption: "Mon",
      step: 1,
    },
    date: {
      format: "DD",
      caption: "Day",
      step: 1,
    },
  };

  onSelectedCardExpiredHandle = async (value) => {
    await this.setState({ f0_expired_date: value });
    if (this.state.isSubmit) {
      this.validate();
    }
    // setTimeout(() => {
    //   this.validate();
    // }, 200);
  };

  onSelectedBirthDateHandle = async (value) => {
    await this.setState({ f0_date_birth: value });
    if (this.state.isSubmit) {
      this.validate();
    }
    // setTimeout(() => {
    //   this.validate();
    // }, 200);
  };
  render() {
    library.add(fas);
    const title = this.state.title;
    const title_eng = this.state.title_eng;
    const title_arr = this.state.title_arr;
    const nationality = this.state.nationality;
    const f0_title_eng_value = this.state.f0_title_eng_value.text_en
      ? this.state.f0_title_eng_value.text_en
      : "";

    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <Loading status={this.state.loading} />
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          style={{
                            display: checkEditPage() ? "none" : "block",
                            marginLeft: "3px",
                          }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">
                        ข้อมูลตามบัตรประจำตัวประชาชน
                      </div>
                    </div>

                    <div className="bg_content_white">
                      <div className="first_title">
                        ข้อมูลตามบัตรประจำตัวประชาชน
                      </div>

                      {/* <form id="myForm" name="myForm" > */}
                      <div className="row_idcard active">
                        <div className="label_idcard">
                          เลขที่บัตรประจำตัวประชาชน <span>*</span>
                        </div>
                        <input
                          mask="1111111111111"
                          size="13"
                          type="tel"
                          className="input_idcard"
                          name="f0_national_id"
                          value={this.state.f0_national_id}
                          onChange={this.handleChange}
                          style={{
                            textDecorationLine: "none",
                            borderColor:
                              this.state.f0_national_id !== ""
                                ? "#003b66"
                                : "#ccc",
                          }}
                          required
                          readOnly
                        />
                      </div>
                      <div className="errorMessage">
                        {this.state.IdCardError}
                      </div>

                      <div className="row_idcard">
                        <div>
                          หมายเลขเลเซอร์การ์ดหลังบัตร <span>*</span>
                        </div>
                        <IMaskInput
                          mask={"aa0-0000000-00"}
                          value={this.state.f0_lazer_card}
                          unmask={true}
                          name="f0_lazer_card"
                          // className="input_idcard"
                          placeholder="ABX-XXXXXXX-XX"
                          // inputRef={el => this.input = el}
                          className="input_idcard"
                          style={{
                            textDecorationLine: "none",
                            borderColor:
                              this.state.f0_lazer_card !== ""
                                ? "#003b66"
                                : "#ccc",
                          }}
                          onAccept={(value) => this.back_idcard(value)}
                        />
                        <span className="span_f0_lazer_card">
                          กรุณาระบุหมายเลขเลเซอร์การ์ดหลังบัตร
                        </span>
                      </div>
                      <div className="errorMessage">
                        {this.state.LazerIdError}
                      </div>

                      <div
                        className={
                          this.state.unlimit_idcard
                            ? "row_idcard disable"
                            : "row_idcard"
                        }
                        style={{
                          //  display: this.state.unlimit_idcard ? "none" : "block",
                          position: "relative",
                        }}
                      >
                        <div className="label_idcard">
                          วันหมดอายุ (ค.ศ.) <span>*</span>
                        </div>
                        <div style={{ position: "relative" }}>
                          <DatePickerInput
                            value={this.state.f0_expired_date}
                            minDate={this.state.to_day}
                            disabled={this.state.unlimit_idcard}
                            onSelected={this.onSelectedCardExpiredHandle}
                          />
                          <FontAwesomeIcon icon="calendar"></FontAwesomeIcon>
                        </div>

                        <span className="span_f0_expired_date">
                          กรุณาระบุวันหมดอายุ (ค.ศ.)
                        </span>
                      </div>
                      <div
                        style={{
                          //  display: this.state.unlimit_idcard ? "none" : "block",
                          position: "relative",
                        }}
                      >
                        <div className="row_idcard">
                          <label className="container_border">
                            บัตรประจำตัวประชาชนประเภทตลอดชีพ
                            <input
                              type="checkbox"
                              checked={this.state.unlimit_idcard}
                              onChange={(e) => {
                                this.setState({
                                  unlimit_idcard: e.target.checked,
                                });
                                if (e.target.checked) {
                                  this.setState({
                                    f0_expired_date: "99991231",
                                  });
                                } else {
                                  this.setState({ f0_expired_date: "" });
                                }
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div className="row_idcard">
                        <div>
                          คำนำหน้าชื่อ (ภาษาไทย) <span>*</span>
                        </div>

                        <div className="main_select">
                          <select
                            className="select_style"
                            style={{
                              backgroundColor: "#fff",
                              color:
                                this.state.f0_title != ""
                                  ? "#003b66"
                                  : "#003b6694",
                              borderColor:
                                this.state.f0_title !== "" ? "#003b66" : "#ccc",
                            }}
                            name="f0_title"
                            value={this.state.f0_title}
                            onChange={this.handleChange}
                            required
                          >
                            <option
                              value=""
                              style={{ color: "#003b6694" }}
                              disabled
                              hidden
                            >
                              กรุณาเลือกคำนำหน้าชื่อ
                            </option>
                            {title}
                          </select>
                          <FontAwesomeIcon icon="angle-down" />
                        </div>
                        <span className="span_f0_title">
                          กรุณาระบุคำนำหน้าชื่อ (ภาษาไทย)
                        </span>
                      </div>

                      <div
                        className="row_idcard"
                        style={{
                          display: this.state.title_other ? "block" : "none",
                        }}
                      >
                        <div>
                          คำนำหน้าชื่อ <span>*</span>
                        </div>
                        <IMaskInput
                          mask={
                            /^[A-Za-zๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝูฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ.\s\-]+$/
                          }
                          value={this.state.f0_title_text}
                          unmask={true}
                          className="input_idcard"
                          name="f0_title_text"
                          style={{
                            textDecorationLine: "none",
                            borderColor:
                              this.state.f0_title_text !== ""
                                ? "#003b66"
                                : "#ccc",
                          }}
                          onAccept={this.handleIChange}
                        />
                        <span className="span_f0_title_text">
                          กรุณาระบุคำนำหน้าชื่ออื่นๆ
                        </span>
                      </div>
                      <div className="errorMessage">
                        {this.state.Title_otherError}
                      </div>

                      <div className="row_idcard">
                        <div>
                          ชื่อ (ภาษาไทย) <span>*</span>
                        </div>
                        <IMaskInput
                          mask={
                            /^[ๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝูฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ.\s\-]+$/
                          }
                          value={this.state.f0_firstname}
                          unmask={true}
                          className="input_idcard"
                          name="f0_firstname"
                          style={{
                            textDecorationLine: "none",
                            borderColor:
                              this.state.f0_firstname !== ""
                                ? "#003b66"
                                : "#ccc",
                          }}
                          onAccept={this.handleIChange}
                        />
                        <span className="span_f0_firstname">
                          กรุณาระบุชื่อ (ภาษาไทย)
                        </span>
                      </div>

                      <div className="row_idcard">
                        <div>ชื่อกลาง (ภาษาไทย)</div>
                        <IMaskInput
                          mask={
                            /^[ๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝูฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ.\s\-]+$/
                          }
                          value={this.state.f0_middlename}
                          unmask={true}
                          className="input_idcard"
                          name="f0_middlename"
                          style={{
                            textDecorationLine: "none",
                            borderColor:
                              this.state.f0_middlename !== ""
                                ? "#003b66"
                                : "#ccc",
                          }}
                          onAccept={this.handleIChange}
                        />
                      </div>

                      <div className="row_idcard">
                        <div>
                          นามสกุล (ภาษาไทย) <span>*</span>
                        </div>
                        <IMaskInput
                          mask={
                            /^[ๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝูฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ.\s\-]+$/
                          }
                          value={this.state.f0_lastname}
                          unmask={true}
                          className="input_idcard"
                          name="f0_lastname"
                          style={{
                            textDecorationLine: "none",
                            borderColor:
                              this.state.f0_lastname !== ""
                                ? "#003b66"
                                : "#ccc",
                          }}
                          onAccept={this.handleIChange}
                        />
                        <span className="span_f0_lastname">
                          กรุณาระบุนามสกุล (ภาษาไทย)
                        </span>
                      </div>

                      <div className="row_idcard">
                        <div>
                          คำนำหน้าชื่อ (ภาษาอังกฤษ) <span>*</span>
                        </div>
                        <input
                          className="input_idcard"
                          value={f0_title_eng_value}
                          style={{
                            textDecorationLine: "none",
                            borderColor:
                              this.state.f0_title_eng_value !== ""
                                ? "#003b66"
                                : "#ccc",
                          }}
                          required
                          readOnly
                        />
                        <div className="main_select" style={{ border: "none" }}>
                          <select
                            className="select_style"
                            hidden
                            name="f0_title_eng"
                            value={this.state.f0_title_eng}
                            required
                            disabled
                          >
                            <option value="" disabled hidden>
                              -
                            </option>
                            {title_eng}
                          </select>
                          {/* <FontAwesomeIcon icon="angle-down" /> */}
                        </div>
                      </div>
                      {/* <div
                className="row_idcard"
                style={{
                  display: this.state.title_other ? "block" : "none",
                  borderColor:
                    this.state.f0_title_text_eng !== "" ? "#003b66" : "#ccc",
                }}
              >
                <div>
                  คำนำหน้าชื่อ (ภาษาอังกฤษ) <span>*</span>
                </div>
                <input
                  className="input_idcard"
                  name="f0_title_text_eng"
                  value={this.state.f0_title_text_eng}
                  onChange={this.handleChange}
                />
              </div> */}
                      <div className="errorMessage">
                        {this.state.Title_other_engError}
                      </div>
                      <div className="row_idcard">
                        <div>
                          ชื่อ (ภาษาอังกฤษ) <span>*</span>
                        </div>
                        <IMaskInput
                          mask={/^[A-Za-z.\s\-]+$/}
                          value={this.state.f0_firstname_eng}
                          unmask={true}
                          className="input_idcard"
                          name="f0_firstname_eng"
                          style={{
                            textDecorationLine: "none",
                            borderColor:
                              this.state.f0_firstname_eng !== ""
                                ? "#003b66"
                                : "#ccc",
                          }}
                          onAccept={this.handleIChange}
                        />
                        <span className="span_f0_firstname_eng">
                          กรุณาระบุชื่อ (ภาษาอังกฤษ)
                        </span>
                      </div>

                      <div className="row_idcard">
                        <div>ชื่อกลาง (ภาษาอังกฤษ)</div>
                        <IMaskInput
                          mask={/^[A-Za-z.\s\-]+$/}
                          value={this.state.f0_middlename_eng}
                          unmask={true}
                          className="input_idcard"
                          name="f0_middlename_eng"
                          style={{
                            textDecorationLine: "none",
                            borderColor:
                              this.state.f0_middlename_eng !== ""
                                ? "#003b66"
                                : "#ccc",
                          }}
                          onAccept={this.handleIChange}
                        />
                      </div>

                      <div className="row_idcard">
                        <div>
                          นามสกุล (ภาษาอังกฤษ) <span>*</span>
                        </div>
                        <IMaskInput
                          mask={/^[A-Za-z.\s\-]+$/}
                          value={this.state.f0_lastname_eng}
                          unmask={true}
                          className="input_idcard"
                          name="f0_lastname_eng"
                          style={{
                            textDecorationLine: "none",
                            borderColor:
                              this.state.f0_lastname_eng !== ""
                                ? "#003b66"
                                : "#ccc",
                          }}
                          onAccept={this.handleIChange}
                        />
                        <span className="span_f0_lastname_eng">
                          กรุณาระบุนามสกุล (ภาษาอังกฤษ)
                        </span>
                      </div>

                      <div className="row_idcard">
                        <div>
                          ประเทศเจ้าของสัญชาติ <span>*</span>
                        </div>
                        <Select2
                          name="f0_nationality"
                          options={this.state.nationality}
                          value={this.state.f0_nationality}
                          onChange={this.handleChangeOption}
                          placeholder="กรุณาเลือก"
                          className={
                            this.state.f0_nationality == ""
                              ? "select_two"
                              : "select_two active"
                          }
                        />
                      </div>

                      <div className="errorMessage">
                        {this.state.nationalityError}
                      </div>

                      <div
                        className="row_idcard"
                        style={{
                          position: "relative",
                        }}
                      >
                        <div>
                          วันเดือนปี (ค.ศ.) เกิด <span>*</span>
                        </div>
                        {/* <input
                  name="f0_date_birth1"
                  value={this.converToTextDate(this.state.f0_date_birth)}
                  className="input_idcard"
                  onClick={this.handleClick2}
                  readOnly
                  style={{ borderColor:this.state.f0_date_birth !== "" ? "#003b66" : "#ccc"}}
                />
                <input
                  hidden
                  name="f0_date_birth"
                  value={this.state.f0_date_birth}
                  className="input_idcard"
                  onClick={this.handleClick2}
                  required
                  readOnly
                />
                <DatePicker
                  dateConfig={this.dateConfig}
                  headerFormat="DD-MM-YYYY"
                  value={this.state.time2}
                  isOpen={this.state.isOpen2}
                  showHeader={false}
                  confirmText={"ตกลง"}
                  cancelText={"ยกเลิก"}
                  onSelect={this.handleSelect2}
                  onCancel={this.handleCancel2}
                  min={this.state.max_year_old}
                  max={this.state.min_year_create}
                /> */}
                        <div style={{ position: "relative" }}>
                          <DatePickerInput
                            value={this.state.f0_date_birth}
                            minDate={sub(new Date(), { years: 120 })}
                            maxDate={sub(new Date(), { years: 20 })}
                            onSelected={this.onSelectedBirthDateHandle}
                          />
                          <FontAwesomeIcon icon="calendar"></FontAwesomeIcon>
                        </div>
                        <span className="span_f0_date_birth">
                          กรุณาระบุวันเดือนปี (ค.ศ.) เกิด
                        </span>
                      </div>
                      <div className="errorMessage">
                        {this.state.BirthError}
                      </div>

                      <div className="row_idcard active">
                        <div>เบอร์โทรศัพท์มือถือ *</div>
                        <input
                          mask="1111111111"
                          className="input_idcard"
                          name="f0_mobile"
                          value={this.state.f0_mobile}
                          style={{
                            textDecorationLine: "none",
                            borderColor:
                              this.state.f0_mobile !== "" ? "#003b66" : "#ccc",
                          }}
                          onChange={this.handleChange}
                          type="tel"
                          required
                          readOnly
                        />
                      </div>
                      <div className="errorMessage">
                        {this.state.PhoneNoError}
                      </div>
                      <div className="row_idcard active">
                        <div>อีเมล *</div>
                        <input
                          className="input_idcard"
                          name="f0_email"
                          value={this.state.f0_email}
                          onChange={this.handleChange}
                          required
                          readOnly
                          style={{
                            borderColor:
                              this.state.f0_email !== "" ? "#003b66" : "#ccc",
                          }}
                        />
                      </div>
                      <div className="errorMessage">
                        {this.state.EmailError}
                      </div>
                      <div className="BtnFooter" style={{ marginTop: 25 }}>
                        <ButtonCustom
                          handle={this.handleSubmit}
                          label={checkEditPage() ? "บันทึกการแก้ไข" : "ถัดไป"}
                          color="blue"
                        ></ButtonCustom>
                      </div>
                      {/* </form> */}
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default KtbstIDCDetail;
