/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-loop-func */
/* eslint-disable default-case */
import React, { Component } from "react";
import ButtonCustom from "../../components/button/ButtonCustom";
import "./KtbstRiskPoll.css";
import axios from "axios";
import { el, id, tr } from "date-fns/locale";
import { isNull } from "lodash";
import _ from "lodash";
import Loading from "../../components/loading/loading";
import DatePickerInput from "../../components/date_picker_input/DatePickerInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert2";
import { config } from "../../constants";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
const _url = config.baseAPIURL03;

class KtbstRiskPoll3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listChooce: [],
      listQ14: [],
      listQ14_delete: [],
      loading_status: false,
      show_btn: true,
      yearOption: [],
      status_btn:
        localStorage.getItem("RiskQuestionPoll") === null ? false : true,
      renderHtml: "",
    };
    this.goBack = this.goBack.bind(this);
    this.addQ14 = this.addQ14.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  goBack() {
    this.props.history.push("/risk_question");
  }

  addQ14 = async (e) => {
    e.preventDefault();
    var oldQ = this.state.listQ14;
    if (oldQ.length >= 3) {
      return false;
    }
    oldQ.push({
      New_Item: true,
      SAfsa14_Id: Date.now(),
      SAfsa14_Position: "",
      SAfsa14_Company: "",
      SAfsa14_FromYear: "",
      SAfsa14_ToYear: "",
    });
    await this.setState({ listQ14: oldQ });
    await localStorage.setItem("listQ14", JSON.stringify(oldQ));
    await this.renderHtml();
  };

  handleChangeNew = async (e) => {
    let inputName = e.target.name;
    let inputValue = e.target.value;
    let inputType = document.getElementsByName(inputName)[0].type;
    let listChooce = this.state.listChooce;
    let ele = inputName.split("_");
    let SAmsa_Id = ele[ele.length - 1];

    // await this.setState((prevState) => ({
    //   listChooce: prevState.listChooce.map((el) => {
    //     if (oldValue) {
    //       var dataValue = oldValue.suit_json.find((a) => {
    //         return el.SAmsa_Id == a.SAfsac_SAmsa_Id;
    //       });
    //       if (dataValue) {
    //         return {
    //           ...el,
    //           SAfsac_SubAnswer: dataValue.SAfsac_SubAnswer,
    //           SAfsac_Answer: dataValue.SAfsac_Answer,
    //           SAfsac_Remark: dataValue.SAfsac_Remark,
    //           SAfsac_Remark2: dataValue.SAfsac_Remark2,
    //         };
    //       }
    //     }
    //     return el;
    //   }),
    // }));
    if (inputName.startsWith("SAfsac_Answer_radio")) {
      let ele = inputName.split("_");
      let SAfsa14_Id = ele[ele.length - 1];
      if (SAfsa14_Id == 29 && inputValue == 2) {
        // console.log(SAfsa14_Id, inputValue);
        await localStorage.setItem("listQ14", JSON.stringify([]));
        await this.setState({ listQ14: [] });
        await this.renderHtml();
        this.handleChange(e);
      }
    }

    if (
      inputName.startsWith("SAfsa14_FromYear_") ||
      inputName.startsWith("New_SAfsa14_FromYear_")
    ) {
      let ele = inputName.split("_");
      let SAfsa14_Id = ele[ele.length - 1];
      let New_Item = inputName.startsWith("New_SAfsa14_FromYear")
        ? true
        : false;
      let prefix = inputName.startsWith("New_SAfsa14_FromYear") ? "New_" : "";
      let suffix = inputName.startsWith("New_SAfsa14_FromYear") ? "0_" : "";
      let SAfsa14_ToYear = document.getElementsByName(
        `${prefix}SAfsa14_ToYear_${suffix}${SAfsa14_Id}`
      )[0].value;
      if (inputValue > SAfsa14_ToYear && SAfsa14_ToYear != "") {
        // console.log(inputValue, SAfsa14_ToYear);
        // document.getElementsByName(`${prefix}SAfsa14_FromYear_${suffix}${SAfsa14_Id}`)[0].value = SAfsa14_ToYear;
        document.getElementsByName(
          `${prefix}SAfsa14_ToYear_${suffix}${SAfsa14_Id}`
        )[0].value = inputValue;
        return false;
      }
    }

    if (
      inputName.startsWith("SAfsa14_ToYear_") ||
      inputName.startsWith("New_SAfsa14_ToYear_")
    ) {
      let ele = inputName.split("_");
      let SAfsa14_Id = ele[ele.length - 1];
      let New_Item = inputName.startsWith("New_SAfsa14_ToYear") ? true : false;
      let prefix = inputName.startsWith("New_SAfsa14_ToYear") ? "New_" : "";
      let suffix = inputName.startsWith("New_SAfsa14_ToYear") ? "0_" : "";
      let SAfsa14_FromYear = document.getElementsByName(
        `${prefix}SAfsa14_FromYear_${suffix}${SAfsa14_Id}`
      )[0].value;
      if (SAfsa14_FromYear > inputValue && SAfsa14_FromYear != "") {
        // console.log(SAfsa14_FromYear, inputValue);
        document.getElementsByName(
          `${prefix}SAfsa14_ToYear_${suffix}${SAfsa14_Id}`
        )[0].value = SAfsa14_FromYear;
        return false;
      }
    }

    // document.getElementsByClassName('input_poll_q14').forEach((e, i) => {
    //   console.log(i, e);
    // });
    // document.getElementsByName(inputName).forEach((e, i) => {
    //   console.log(i, e.className);
    // });

    let suit_json = {
      SAfsac_SAmsa_Id: 0,
      SAfsac_Id: 0,
      SAfsac_SubId: "",
      SAfsac_SubAnswer: "",
      SAfsac_Remark: "",
      SAfsac_Remark2: "",
      SAfsac_Delflag: 0,
      SAfsac_Answer: "",
      SAfsac_Score: "",
    };

    let listQ14 = [];
    for (const [key, value] of Object.entries(
      document.getElementsByClassName("input_poll_q14")
    )) {
      if (
        value.name.startsWith("SAfsa14_Position") ||
        value.name.startsWith("New_SAfsa14_Position")
      ) {
        let ele = value.name.split("_");
        let SAfsa14_Id = ele[ele.length - 1];
        let New_Item = value.name.startsWith("New_SAfsa14_Position")
          ? true
          : false;
        let prefix = value.name.startsWith("New_SAfsa14_Position")
          ? "New_"
          : "";
        let suffix = value.name.startsWith("New_SAfsa14_Position") ? "0_" : "";

        // await this.setState((prevState) => ({
        //   listChooce: prevState.listChooce.map((el) => {
        //     if (oldValue) {
        //       var dataValue = oldValue.suit_json.find((a) => {
        //         return el.SAmsa_Id == a.SAfsac_SAmsa_Id;
        //       });
        //       if (dataValue) {
        //         return {
        //           ...el,
        //           SAfsac_SubAnswer: dataValue.SAfsac_SubAnswer,
        //           SAfsac_Answer: dataValue.SAfsac_Answer,
        //           SAfsac_Remark: dataValue.SAfsac_Remark,
        //           SAfsac_Remark2: dataValue.SAfsac_Remark2,
        //         };
        //       }
        //     }
        //     return el;
        //   }),
        // }));

        let DataSAfsa14 = {
          SAfsa14_Id: SAfsa14_Id,
          positionName: document.getElementsByName(
            `${prefix}SAfsa14_Position_${suffix}${SAfsa14_Id}`
          )[0].value,
          companyName: document.getElementsByName(
            `${prefix}SAfsa14_Company_${suffix}${SAfsa14_Id}`
          )[0].value,
          expFrom: document.getElementsByName(
            `${prefix}SAfsa14_FromYear_${suffix}${SAfsa14_Id}`
          )[0].value,
          expTo: document.getElementsByName(
            `${prefix}SAfsa14_ToYear_${suffix}${SAfsa14_Id}`
          )[0].value,
        };
        let datalistQ14 = {
          New_Item: New_Item,
          SAfsa14_Id: SAfsa14_Id,
          SAfsa14_Position: document.getElementsByName(
            `${prefix}SAfsa14_Position_${suffix}${SAfsa14_Id}`
          )[0].value,
          SAfsa14_Company: document.getElementsByName(
            `${prefix}SAfsa14_Company_${suffix}${SAfsa14_Id}`
          )[0].value,
          SAfsa14_FromYear: document.getElementsByName(
            `${prefix}SAfsa14_FromYear_${suffix}${SAfsa14_Id}`
          )[0].value,
          SAfsa14_ToYear: document.getElementsByName(
            `${prefix}SAfsa14_ToYear_${suffix}${SAfsa14_Id}`
          )[0].value,
        };
        listQ14.push(Object.assign({}, datalistQ14));
      }
    }

    // await this.setState({ listQ14: [] });
    // await this.renderHtml();
    await this.setState({ listQ14: listQ14 });
    await localStorage.setItem("listQ14", JSON.stringify(listQ14));
    await this.renderHtml();
  };

  // handleChangeObj = ({ target: { id, name, value } }) => this.setState({ [id]: { ...this.state[id], [name]: value } });

  handleChange = async (e) => {
    var formData = new FormData(document.querySelector("form"));
    var data = [];
    var chooces = this.state.listChooce;

    var data14 = [];
    var listQ14 = [];
    var datalistQ14 = [];
    for (var pair of formData.entries()) {
      if (pair[0] == "SAfsac_SAmsa_Id[]") {
        if (typeof data[pair[1]] == "undefined") data[pair[1]] = [];
        data[pair[1]]["SAfsac_SAmsa_Id"] = pair[1];
        // console.log(pair[1])
        var index = chooces.find((d) => {
          return d.SAmsa_Id + "" == pair[1] + "";
        });
        // console.log(index)
        if (typeof index != "undefined") {
          data[pair[1]]["SAfsac_Id"] = index.SAfsac_Id + "";
          data[pair[1]]["SAfsac_SubId"] = "";
          data[pair[1]]["SAfsac_SubAnswer"] = "";
          data[pair[1]]["SAfsac_Remark"] = "";
          data[pair[1]]["SAfsac_Remark2"] = "";
          data[pair[1]]["SAfsac_Delflag"] = 0;
        }
      }

      if (pair[0].startsWith("SAfsac_Answer")) {
        // console.log(pair[0], pair[1]);
        var ele = pair[0].split("_");
        if (ele.length == 4) {
          if (typeof data[ele[3]] == "undefined") data[ele[3]] = [];
          if (ele[2] == "checkbox") {
            data[ele[3]]["SAfsac_Answer"] =
              typeof data[ele[3]]["SAfsac_Answer"] == "undefined"
                ? `${pair[1]}_`
                : data[ele[3]]["SAfsac_Answer"] + `${pair[1]}_`;
          } else if (ele[2] == "radio") {
            data[ele[3]]["SAfsac_Answer"] = pair[1];
          }
          data[ele[3]]["SAfsac_Score"] = pair[1];
        }
      }
      if (pair[0].startsWith("SAfsac_SubAnswer_checkbox_")) {
        var eleId = pair[0].replace("SAfsac_SubAnswer_checkbox_", "");
        data[eleId]["SAfsac_SubAnswer"] += `${pair[1]}_`;
      }

      if (pair[0].startsWith("SAfsac_Remark_")) {
        var ele = pair[0].split("_");
        if (ele.length == 3) {
          data[ele[2]]["SAfsac_Remark"] = pair[1];
        }
      }
      if (pair[0].startsWith("SAfsac_Remark2_")) {
        var ele = pair[0].split("_");
        if (ele.length == 3) {
          data[ele[2]]["SAfsac_Remark2"] = pair[1];
        }
      }

      if (pair[0].startsWith("SAfsa14_Position_")) {
        var eleId = pair[0].replace("SAfsa14_Position_", "");
        data14[eleId] =
          typeof data14[eleId] == "undefined" ? [] : data14[eleId];
        data14[eleId]["SAfsa14_Id"] = eleId;
        data14[eleId]["positionName"] = pair[1];
        datalistQ14[eleId] =
          typeof datalistQ14[eleId] == "undefined" ? [] : datalistQ14[eleId];
        datalistQ14[eleId]["SAfsa14_Id"] = eleId;
        datalistQ14[eleId]["SAfsa14_Position"] = pair[1];
      }
      if (pair[0].startsWith("SAfsa14_Company_")) {
        var eleId = pair[0].replace("SAfsa14_Company_", "");
        data14[eleId] =
          typeof data14[eleId] == "undefined" ? [] : data14[eleId];
        data14[eleId]["SAfsa14_Id"] = eleId;
        data14[eleId]["companyName"] = pair[1];
        datalistQ14[eleId] =
          typeof datalistQ14[eleId] == "undefined" ? [] : datalistQ14[eleId];
        datalistQ14[eleId]["SAfsa14_Company"] = pair[1];
      }
      if (pair[0].startsWith("SAfsa14_FromYear_")) {
        var eleId = pair[0].replace("SAfsa14_FromYear_", "");
        data14[eleId] =
          typeof data14[eleId] == "undefined" ? [] : data14[eleId];
        data14[eleId]["SAfsa14_Id"] = eleId;
        data14[eleId]["expFrom"] = pair[1];
        datalistQ14[eleId] =
          typeof datalistQ14[eleId] == "undefined" ? [] : datalistQ14[eleId];
        datalistQ14[eleId]["SAfsa14_FromYear"] = pair[1];
      }
      if (pair[0].startsWith("SAfsa14_ToYear_")) {
        var eleId = pair[0].replace("SAfsa14_ToYear_", "");
        data14[eleId] =
          typeof data14[eleId] == "undefined" ? [] : data14[eleId];
        data14[eleId]["SAfsa14_Id"] = eleId;
        data14[eleId]["expTo"] = pair[1];
        datalistQ14[eleId] =
          typeof datalistQ14[eleId] == "undefined" ? [] : datalistQ14[eleId];
        datalistQ14[eleId]["SAfsa14_ToYear"] = pair[1];
      }

      if (pair[0].startsWith("New_SAfsa14_Position_")) {
        var eleId = pair[0].replace("New_SAfsa14_Position_0_", "");
        data14[eleId] =
          typeof data14[eleId] == "undefined" ? [] : data14[eleId];
        data14[eleId]["SAfsa14_Id"] = -1;
        data14[eleId]["positionName"] = pair[1];
        datalistQ14[eleId] =
          typeof datalistQ14[eleId] == "undefined" ? [] : datalistQ14[eleId];
        datalistQ14[eleId]["New_Item"] = true;
        datalistQ14[eleId]["SAfsa14_Id"] =
          parseInt(eleId) < 100000 ? Date.now() : eleId;
        datalistQ14[eleId]["SAfsa14_Position"] = pair[1];
      }
      if (pair[0].startsWith("New_SAfsa14_Company_")) {
        // console.log(pair);
        var eleId = pair[0].replace("New_SAfsa14_Company_0_", "");
        data14[eleId] =
          typeof data14[eleId] == "undefined" ? [] : data14[eleId];
        data14[eleId]["SAfsa14_Id"] = -1;
        data14[eleId]["companyName"] = pair[1];
        datalistQ14[eleId] =
          typeof datalistQ14[eleId] == "undefined" ? [] : datalistQ14[eleId];
        datalistQ14[eleId]["SAfsa14_Company"] = pair[1];
      }
      if (pair[0].startsWith("New_SAfsa14_FromYear_")) {
        var eleId = pair[0].replace("New_SAfsa14_FromYear_0_", "");
        data14[eleId] =
          typeof data14[eleId] == "undefined" ? [] : data14[eleId];
        data14[eleId]["SAfsa14_Id"] = -1;
        data14[eleId]["expFrom"] = pair[1];
        datalistQ14[eleId] =
          typeof datalistQ14[eleId] == "undefined" ? [] : datalistQ14[eleId];
        datalistQ14[eleId]["SAfsa14_FromYear"] = pair[1];
      }
      if (pair[0].startsWith("New_SAfsa14_ToYear_")) {
        var eleId = pair[0].replace("New_SAfsa14_ToYear_0_", "");
        data14[eleId] =
          typeof data14[eleId] == "undefined" ? [] : data14[eleId];
        data14[eleId]["SAfsa14_Id"] = -1;
        data14[eleId]["expTo"] = pair[1];
        datalistQ14[eleId] =
          typeof datalistQ14[eleId] == "undefined" ? [] : datalistQ14[eleId];
        datalistQ14[eleId]["SAfsa14_ToYear"] = pair[1];
      }
    }

    // for (const [key, value] of Object.entries(datalistQ14)) {
    //   listQ14.push(Object.assign({}, value));
    // }
    // await this.setState({ listQ14: listQ14 });
    // await localStorage.setItem('listQ14', JSON.stringify(listQ14));

    var localRiskQuestion = localStorage.getItem("RiskQuestion");
    var arrRiskQuestion = JSON.parse(localRiskQuestion);
    var answerjson14Arr = [];
    arrRiskQuestion.answerjson14 = [];

    for (const [key, value] of Object.entries(data)) {
      arrRiskQuestion.suit_json.push(Object.assign({}, value));
    }
    for (const [key, value] of Object.entries(data14)) {
      arrRiskQuestion.answerjson14.push(Object.assign({}, value));
    }

    arrRiskQuestion.suit_json.forEach((item, i) => {
      if (item.SAfsac_Id == "null") {
        item.SAfsac_Id = -1;
      }
      if (item.SAfsac_SAmsa_Id) {
        item.SAfsac_SAmsa_Id = parseInt(item.SAfsac_SAmsa_Id);
      }
      if (item.SAfsac_Id) {
        item.SAfsac_Id = parseInt(item.SAfsac_Id);
      }
      if (item.SAfsac_Delflag) {
        item.SAfsac_Delflag = parseInt(item.SAfsac_Delflag);
      }

      if (item.SAfsac_Answer == "2") {
        item.SAfsac_SubAnswer = "";
        item.SAfsac_Remark = "";
        item.SAfsac_Remark2 = "";
        // if (item.SAfsac_SAmsa_Id == '26') {
        //   console.log(item.SAfsac_SAmsa_Id, item.SAfsac_Remark);
        // }
      }
    });

    localStorage.setItem("RiskQuestionPoll", JSON.stringify(arrRiskQuestion));

    if (arrRiskQuestion) {
      var status_btn_arr = [];
      var status_btn = true;
      for (let i = 10; i < arrRiskQuestion.suit_json.length; i++) {
        if (i < 12) {
          if (arrRiskQuestion.suit_json[i].SAfsac_Answer) {
            status_btn_arr[i] = true;
          } else {
            status_btn_arr[i] = false;
          }
        } else {
          if (arrRiskQuestion.suit_json[i].SAfsac_Answer) {
            status_btn_arr[i] = true;
          } else {
            status_btn_arr[i] = false;
          }
        }
      }
      status_btn_arr.forEach((val, i) => {
        if (!val) {
          status_btn = false;
          return;
        }
      });
      this.setState({ status_btn: status_btn });
    }
    this.renderHtml();
  };

  save_data = async (e) => {
    await this.handleChange(e);
    var RiskQuestionPoll = localStorage.getItem("RiskQuestionPoll");
    var arrRiskQuestion = JSON.parse(RiskQuestionPoll);
    // let listQ14_delete = await this.state.listQ14_delete;
    // if (listQ14_delete.length !== undefined) {
    //   for (let i = 0; i < listQ14_delete.length; i++) {
    //     var id = listQ14_delete[i].SAfsa14_Id.toString();
    //     arrRiskQuestion.answerjson14.push({
    //       SAfsa14_Id: id,
    //       positionName: listQ14_delete[i].positionName,
    //       companyName: listQ14_delete[i].companyName,
    //       expFrom: listQ14_delete[i].expFrom,
    //       expTo: listQ14_delete[i].expTo,
    //       Delflag: listQ14_delete[i].Delflag,
    //     });
    //   }
    // }
    this.setState({ loading_status: true });
    axios
      .post(_url + "/form/suitability/manp", arrRiskQuestion)
      .then(async (res) => {
        await localStorage.setItem(
          "summary_risk",
          JSON.stringify(res.data.Data[1])
        );
        await this.setState({ loading_status: false });
        await localStorage.setItem("currentStep", "summary_risk");
        this.props.history.push("/summary_risk");
      })
      .catch((err) => {
        // console.log(err);
        this.setState({ loading_status: false });
        this.popUp(
          "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง",
          "เกิดข้อผิดพลาด"
        );
      });
  };

  popUp = (msg, title = "") => {
    const MySwal = withReactContent(swal);
    const vm = this;
    MySwal.fire({
      customClass: {
        confirmButton: "long_btn_fix",
        cancelButton: "long_btn_fix",
      },
      // icon: 'warning',
      imageUrl: "/images/ico_alert.svg",
      imageWidth: 100,
      imageHeight: 100,
      confirmButtonColor: "#003b66",
      confirmButtonText: "กลับ",
      title: `<span class="title_popup">${title}</span>`,
      html: `<div class="content_popup">${msg}</div>`,
    }).then(function () {
      // vm.props.history.push('/ndid');
    });
  };

  toggle_radio = (e, ele_toggle) => {
    let inputName = e.target.name;
    let ele = inputName.split("_");
    let SAmsa_Id = ele[ele.length - 1];

    var a = document.getElementsByClassName(ele_toggle)[0].firstChild
      .childNodes[0].childNodes;
    if (e.target.value == 1) {
      a.forEach((e, i) => {
        if (i != 0 && e.nodeName != "#text") {
          e.style.display = "block";
        }
      });
    } else if (e.target.value == 2) {
      a.forEach((e, i) => {
        if (i != 0 && e.nodeName != "#text") {
          e.style.display = "none";
        }
      });
      for (const [key, value] of Object.entries(
        document.getElementsByName(`SAfsac_SubAnswer_checkbox_${SAmsa_Id}`)
      )) {
        value.checked = false;
      }
    }
    this.handleChange(e);
  };

  input_other = (e) => {
    if (e.target.value !== "") {
      e.target.style.borderColor = "#003b66";
    } else {
      e.target.style.borderColor = "#ccc";
    }
  };

  componentDidMount = async () => {
    const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
    const head_token = HeaderManp.head_token;
    await axios
      .get(_url + `/form/suitability/selbyhead?head_token=${head_token}`)
      .then((res) => {
        var listQ14 = res.data.Data[2];
        this.setState({ listChooce: res.data.Data[1] });
        this.setState({ listQ14: listQ14 });
        this.setState({ lengthQ14: res.data.Data[3][0].MinAnswer14 });
        localStorage.setItem("listQ14", JSON.stringify(listQ14));
      })
      .catch((error) => {});

    const d = new Date();
    const year = d.getFullYear();
    const yearOption = [];
    for (var i = year; i > year - 100; i--) {
      yearOption.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    this.setState({ yearOption: yearOption });

    await this.renderHtml();

    var oldValue = JSON.parse(localStorage.getItem("RiskQuestionPoll"));
    if (oldValue) {
      var status_btn_arr = [];
      var status_btn = true;
      for (let i = 10; i < oldValue.suit_json.length; i++) {
        if (i < 12) {
          if (oldValue.suit_json[i].SAfsac_Answer) {
            status_btn_arr[i] = true;
          } else {
            status_btn_arr[i] = false;
          }
        } else {
          if (oldValue.suit_json[i].SAfsac_Answer) {
            status_btn_arr[i] = true;
          } else {
            status_btn_arr[i] = false;
          }
        }
      }
      status_btn_arr.forEach((val, i) => {
        if (!val) {
          status_btn = false;
          return;
        }
      });

      this.setState({ status_btn: status_btn });
    }
  };
  show_type1 = async (id, e) => {
    let idD = `type1_${id}`;
    let idT = `text1_${id}`;

    if (id) {
      if (e.target.checked) {
        document.getElementById(idD).style.display = "flex";
        document.getElementById(idT).value = "";
      } else {
        document.getElementById(idD).style.display = "none";
        document.getElementById(idT).value = "";
      }
    }
    // await this.renderHtml();
  };

  removeSubRemark = async (delID, e) => {
    let listQ14 = await _.filter(this.state.listQ14, function (o) {
      return o.SAfsa14_Id !== delID;
    });
    let listQ14_delete_id = this.state.listQ14.find(
      (o) => o.SAfsa14_Id === delID
    );
    let listQ14_delete = this.state.listQ14_delete;
    listQ14_delete.push({
      SAfsa14_Id: delID,
      positionName: listQ14_delete_id.positionName
        ? listQ14_delete_id.positionName
        : "",
      companyName: listQ14_delete_id.companyName
        ? listQ14_delete_id.companyName
        : "",
      expFrom: listQ14_delete_id.expFrom ? listQ14_delete_id.expFrom : "",
      expTo: listQ14_delete_id.expTo ? listQ14_delete_id.expTo : "",
      Delflag: "1",
    });
    await localStorage.setItem("listQ14", JSON.stringify(listQ14));
    await this.setState({ listQ14: [] });
    await this.renderHtml();
    await this.setState({ listQ14: listQ14 });
    await this.renderHtml();
  };

  cal_width(lag) {
    if (lag == 1) {
      return 100 / lag;
    } else {
      return 100 / lag - 5;
    }
  }

  renderHtml = async () => {
    let dataAnwser = JSON.parse(localStorage.getItem("question"));
    let listQ14 = this.state.listQ14;
    let chooceByDefault = this.state.listChooce;
    var status_btn_arr = [];
    var status_btn = true;
    this.setState({ show_btn: listQ14.length < 3 });

    for (var i = 0; i < chooceByDefault.length; i++) {
      status_btn_arr[i] = chooceByDefault[i].SAfsac_Answer ? true : false;
      // console.log(i, chooceByDefault[i].SAfsac_Answer);
      var d = dataAnwser.find((a) => {
        return a.SAmsa_Id === chooceByDefault[i].SAmsa_Id;
      });
      if (d) {
        chooceByDefault[i].SAfsac_Answer = d.SAfsac_Answer;
      }
      if (chooceByDefault[i].SAfsac_Answer == "2") {
        chooceByDefault[i].SAfsac_SubAnswer = "";
        chooceByDefault[i].SAfsac_Remark = "";
        chooceByDefault[i].SAfsac_Remark2 = "";
      }
    }

    var oldValue = JSON.parse(localStorage.getItem("RiskQuestionPoll"));
    if (oldValue) {
      for (var i = 10; i < chooceByDefault.length; i++) {
        var d = oldValue.suit_json.find((a) => {
          return a.SAfsac_SAmsa_Id == chooceByDefault[i].SAmsa_Id + "";
        });
        if (d) {
          chooceByDefault[i].SAfsac_SubAnswer = d.SAfsac_SubAnswer;
          chooceByDefault[i].SAfsac_Answer = d.SAfsac_Answer;
          chooceByDefault[i].SAfsac_Remark = d.SAfsac_Remark;
          chooceByDefault[i].SAfsac_Remark2 = d.SAfsac_Remark2;
        }
        if (chooceByDefault[i].SAfsac_Answer == "2") {
          chooceByDefault[i].SAfsac_SubAnswer = "";
          chooceByDefault[i].SAfsac_Remark = "";
          chooceByDefault[i].SAfsac_Remark2 = "";
        }
      }
    } else {
      status_btn_arr.forEach((val, i) => {
        if (!val && i > 10) {
          status_btn = false;
          return;
        }
      });

      this.setState({ status_btn: status_btn });
    }

    // await this.setState({ listChooce: chooceByDefault });

    // arrRiskQuestion.suit_json.forEach((item, i) => {
    //   if (item.SAfsac_Id == 'null') {
    //     item.SAfsac_Id = -1;
    //   }
    //   if (item.SAfsac_SAmsa_Id) {
    //     item.SAfsac_SAmsa_Id = parseInt(item.SAfsac_SAmsa_Id);
    //   }
    //   if (item.SAfsac_Id) {
    //     item.SAfsac_Id = parseInt(item.SAfsac_Id);
    //   }
    //   if (item.SAfsac_Delflag) {
    //     item.SAfsac_Delflag = parseInt(item.SAfsac_Delflag);
    //   }

    //   if (item.SAfsac_Answer == '2') {
    //     item.SAfsac_SubAnswer = '';
    //     item.SAfsac_Remark = '';
    //     item.SAfsac_Remark2 = '';
    //   }
    // });

    // await this.setState((prevState) => ({
    //   listChooce: prevState.listChooce.map((el) => {
    //     if (oldValue) {
    //       var dataValue = oldValue.suit_json.find((a) => {
    //         return el.SAmsa_Id == a.SAfsac_SAmsa_Id;
    //       });
    //       if (dataValue) {
    //         return {
    //           ...el,
    //           SAfsac_SubAnswer: dataValue.SAfsac_SubAnswer,
    //           SAfsac_Answer: dataValue.SAfsac_Answer,
    //           SAfsac_Remark: dataValue.SAfsac_Remark,
    //           SAfsac_Remark2: dataValue.SAfsac_Remark2,
    //         };
    //       }
    //     }
    //     return el;
    //   }),
    // }));

    const renderHtml = chooceByDefault.map((item, i) => {
      const arr = JSON.parse(item.SAmsa_Answer);
      if (i > 9 && i <= 15) {
        return (
          <div className="content_risk_question" key={i + "topic"}>
            <input
              type="hidden"
              name="SAfsac_SAmsa_Id[]"
              value={item.SAmsa_Id}
            />
            <div className="title_quest" id={"q_" + (i + 1)}>
              <div
                dangerouslySetInnerHTML={{ __html: item.SAmsa_Question }}
              ></div>
              <div
                className={i < 12 ? "flex_poll q_" + (i + 1) : "q_" + (i + 1)}
              >
                {arr.map((itemSub, iSub) => {
                  var id_new = 0;

                  return (
                    <div
                      key={i + "_" + iSub}
                      style={{
                        width:
                          i <= 11 ? this.cal_width(arr.length) + "%" : "100%",
                      }}
                    >
                      {item.SAmsa_IsMultiChoice == 0 ? (
                        i <= 11 ? (
                          <div key={"iSub" + iSub}>
                            <label className="labl" key={iSub}>
                              <input
                                type="radio"
                                defaultChecked={
                                  item.SAfsac_Answer == null
                                    ? ""
                                    : item.SAfsac_Answer == itemSub.ChoiceID
                                }
                                name={`SAfsac_Answer_radio_${item.SAmsa_Id}`}
                                value={itemSub.ChoiceID}
                                onChange={(e) => {
                                  e.persist();
                                  this.handleChangeNew(e);
                                }}
                              />
                              <div>{itemSub.Text}</div>
                            </label>
                          </div>
                        ) : (
                          <div key={"iSubR" + iSub}>
                            <label className="container_border">
                              {itemSub.Text}
                              <input
                                type="radio"
                                defaultChecked={
                                  item.SAfsac_Answer == null
                                    ? ""
                                    : item.SAfsac_Answer == itemSub.ChoiceID
                                }
                                name={`SAfsac_Answer_radio_${item.SAmsa_Id}`}
                                value={itemSub.ChoiceID}
                                onChange={(e) => {
                                  e.persist();
                                  this.toggle_radio(e, "q_" + (i + 1));
                                  this.handleChangeNew(e);
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>

                            <div
                              style={{
                                display:
                                  item.SAfsac_Answer == itemSub.ChoiceID
                                    ? "block"
                                    : "none",
                              }}
                            >
                              {typeof itemSub.Answer != "undefined"
                                ? itemSub.Answer.map((itemSub2, iSub2) => {
                                    if (itemSub2.Text.indexOf("<#1#>") < 0) {
                                      return (
                                        <div
                                          key={"type0" + iSub2}
                                          //  style={{ display: item.SAfsac_Answer == itemSub.ChoiceID ? 'block' : 'none' }}
                                        >
                                          <label
                                            className="container_none_border"
                                            style={{ marginLeft: 32 }}
                                          >
                                            <input
                                              type="checkbox"
                                              defaultChecked={
                                                item.SAfsac_SubAnswer == null
                                                  ? ""
                                                  : item.SAfsac_SubAnswer.split(
                                                      "_"
                                                    ).includes(
                                                      itemSub2.AnswerID + ""
                                                    )
                                              }
                                              value={itemSub2.AnswerID}
                                              name={`SAfsac_SubAnswer_checkbox_${item.SAmsa_Id}`}
                                              onChange={(e) => {
                                                e.persist();
                                                this.handleChange(e);
                                              }}
                                            />
                                            <span className="text_checkmark">
                                              {itemSub2.Text}
                                            </span>
                                            <span className="checkmark"></span>
                                          </label>
                                        </div>
                                      );
                                    } else {
                                      if (itemSub2.Text.indexOf("<#1#>") > 0) {
                                        // console.log(
                                        //   item.SAfsac_SubAnswer.split('_').includes(itemSub2.AnswerID.toString())
                                        // );
                                        var SAfsac_SubAnswer = item.SAfsac_SubAnswer
                                          ? item.SAfsac_SubAnswer
                                          : "";
                                        return (
                                          <div key={"type00" + iSub2}>
                                            <div key={"type0" + iSub2}>
                                              <label
                                                className="container_none_border"
                                                style={{ marginLeft: 32 }}
                                              >
                                                <input
                                                  type="checkbox"
                                                  defaultChecked={
                                                    item.SAfsac_SubAnswer ==
                                                    null
                                                      ? ""
                                                      : item.SAfsac_SubAnswer.split(
                                                          "_"
                                                        ).includes(
                                                          itemSub2.AnswerID.toString()
                                                        )
                                                  }
                                                  value={itemSub2.AnswerID}
                                                  name={`SAfsac_SubAnswer_checkbox_${item.SAmsa_Id}`}
                                                  onChange={async (e) => {
                                                    e.persist();
                                                    await this.show_type1(
                                                      iSub2,
                                                      e
                                                    );
                                                    this.handleChange(e);
                                                  }}
                                                />
                                                <span className="text_checkmark">
                                                  {itemSub2.Text.replace(
                                                    "<#1#>",
                                                    ""
                                                  )}
                                                </span>
                                                <span className="checkmark"></span>
                                              </label>
                                            </div>
                                            <div
                                              id={"type1_" + iSub2}
                                              key={"type1_" + iSub2}
                                              style={{
                                                display: SAfsac_SubAnswer.split(
                                                  "_"
                                                ).includes(
                                                  itemSub2.AnswerID.toString()
                                                )
                                                  ? "flex"
                                                  : "none",
                                              }}
                                            >
                                              <input
                                                type="text"
                                                style={{ marginLeft: 32 }}
                                                className="input_poll"
                                                style={{
                                                  borderColor:
                                                    item.SAfsac_Remark == null
                                                      ? "#ccc"
                                                      : "#003b66",
                                                  marginLeft: 32,
                                                }}
                                                id={"text1_" + iSub2}
                                                name={
                                                  "SAfsac_Remark_" +
                                                  item.SAmsa_Id
                                                }
                                                defaultValue={
                                                  SAfsac_SubAnswer.split(
                                                    "_"
                                                  ).includes(
                                                    itemSub2.AnswerID.toString()
                                                  )
                                                    ? item.SAfsac_Remark
                                                    : ""
                                                }
                                                placeholder={itemSub2.Text.replace(
                                                  "<#1#>",
                                                  ""
                                                )}
                                                onChange={(e) => {
                                                  e.persist();
                                                  this.input_other(e);
                                                  this.handleChange(e);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        );
                                      }
                                      if (itemSub2.Text.indexOf("<#2#>") > 0) {
                                        return (
                                          <div
                                            key={"type2" + iSub2}
                                            style={{
                                              display: "flex", // display: item.SAfsac_Answer == itemSub.ChoiceID ? 'block' : 'none',
                                            }}
                                          >
                                            <input
                                              type="text"
                                              style={{ marginLeft: 32 }}
                                              className="input_poll"
                                              style={{
                                                borderColor:
                                                  item.SAfsac_Remark2 == null
                                                    ? "#ccc"
                                                    : "#003b66",
                                                marginLeft: 32,
                                              }}
                                              name={
                                                "SAfsac_Remark2_" +
                                                item.SAmsa_Id
                                              }
                                              defaultValue={
                                                item.SAfsac_Remark2 == null
                                                  ? ""
                                                  : item.SAfsac_Remark2
                                              }
                                              placeholder={itemSub2.Text.replace(
                                                "<#2#>",
                                                ""
                                              )}
                                              onChange={(e) => {
                                                e.persist();
                                                this.input_other(e);
                                                this.handleChange(e);
                                              }}
                                            />
                                          </div>
                                        );
                                      }
                                    }
                                  })
                                : ""}
                              {typeof itemSub.Answer2 != "undefined"
                                ? itemSub.Answer2.map((itemSub2, iSub2) => {
                                    if (itemSub2.Text.indexOf("<#1#>") > 0) {
                                      return (
                                        <div
                                          key={"type1_" + iSub2}
                                          style={{
                                            display: "flex", //display: item.SAfsac_Answer == itemSub.ChoiceID ? 'block' : 'none'
                                          }}
                                        >
                                          <input
                                            type="text"
                                            className="input_poll"
                                            style={{
                                              borderColor:
                                                item.SAfsac_Remark == null
                                                  ? "#ccc"
                                                  : "#003b66",
                                              marginLeft: 32,
                                            }}
                                            name={
                                              "SAfsac_Remark_" + item.SAmsa_Id
                                            }
                                            defaultValue={
                                              item.SAfsac_Remark == null
                                                ? ""
                                                : item.SAfsac_Remark
                                            }
                                            placeholder={itemSub2.Text.replace(
                                              "<#1#>",
                                              ""
                                            )}
                                            onChange={(e) => {
                                              e.persist();
                                              this.input_other(e);
                                              this.handleChange(e);
                                            }}
                                          />
                                        </div>
                                      );
                                    }

                                    if (itemSub2.Text.indexOf("<#2#>") > 0) {
                                      var inputItem;
                                      if (item.SAmsa_Id === 25) {
                                        inputItem = (
                                          <div
                                            className={"main_select"}
                                            style={{
                                              marginLeft: 32,
                                              width: "auto",
                                            }}
                                          >
                                            <select
                                              defaultValue={
                                                item.SAfsac_Remark2 == null
                                                  ? ""
                                                  : item.SAfsac_Remark2
                                              }
                                              name={
                                                "SAfsac_Remark2_" +
                                                item.SAmsa_Id
                                              }
                                              className="select_style"
                                              onChange={(e) => {
                                                e.persist();
                                                this.handleChange(e);
                                              }}
                                            >
                                              <option value="">
                                                ปีที่สำเร็จการศึกษา
                                              </option>
                                              {this.state.yearOption}
                                            </select>
                                            <FontAwesomeIcon icon="angle-down" />
                                          </div>
                                        );
                                      } else {
                                        inputItem = (
                                          <input
                                            type="text"
                                            className="input_poll"
                                            style={{
                                              borderColor:
                                                item.SAfsac_Remark2 == null
                                                  ? "#ccc"
                                                  : "#003b66",
                                              marginLeft: 32,
                                            }}
                                            name={
                                              "SAfsac_Remark2_" + item.SAmsa_Id
                                            }
                                            defaultValue={
                                              item.SAfsac_Remark2 == null
                                                ? ""
                                                : item.SAfsac_Remark2
                                            }
                                            placeholder={itemSub2.Text.replace(
                                              "<#2#>",
                                              ""
                                            )}
                                            onChange={(e) => {
                                              e.persist();
                                              this.input_other(e);
                                              this.handleChange(e);
                                            }}
                                          />
                                        );
                                      }
                                      return (
                                        <div
                                          key={"type2" + iSub2}
                                          // style={{ display: item.SAfsac_Answer == itemSub.ChoiceID ? 'block' : 'none' }}
                                        >
                                          {inputItem}
                                          <br></br>
                                          <br></br>
                                        </div>
                                      );
                                    }
                                  })
                                : ""}
                              {typeof itemSub.SubAnswer != "undefined" ? (
                                <div
                                  style={{
                                    color: "#003d66",
                                    fontSize: 26,
                                    margin: "24px 0 24px 0",
                                    fontWeight: "bold",
                                    // display: item.SAfsac_Answer == itemSub.ChoiceID ? 'block' : 'none',
                                  }}
                                >
                                  {itemSub.SubAnswer.Text}
                                </div>
                              ) : (
                                ""
                              )}

                              {i == 14
                                ? listQ14.map((q, i14) => {
                                    if (i14 < 3) {
                                      id_new++;
                                      // var nowID = q.New_Item ? q.SAfsa14_Id : Date.now();
                                      return (
                                        <div
                                          key={"q14" + i14}
                                          className={"subRemark"}
                                          // style={{ display: item.SAfsac_Answer == itemSub.ChoiceID ? 'block' : 'none' }}
                                        >
                                          {typeof itemSub.SubAnswer !=
                                          "undefined" ? (
                                            <div
                                              className={"title_subRemark"}
                                              style={{ color: "#003d66" }}
                                            >
                                              ความรู้ประสบการณ์ {i14 + 1}
                                              <div
                                                style={{
                                                  height: 24,
                                                }}
                                                onClick={(e) => {
                                                  e.persist();
                                                  this.removeSubRemark(
                                                    q.SAfsa14_Id,
                                                    e
                                                  );
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  style={{ color: "red" }}
                                                  icon="times-circle"
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          {typeof itemSub.SubAnswer !=
                                          "undefined"
                                            ? itemSub.SubAnswer.Answer.map(
                                                (itemSub3, iSub3) => {
                                                  var value = "";
                                                  var eleName = "";
                                                  // console.log(q);
                                                  if (
                                                    !q.New_Item &&
                                                    q.SAfsa14_Id
                                                  ) {
                                                    switch (iSub3) {
                                                      case 0:
                                                        value =
                                                          q.SAfsa14_Position;
                                                        eleName =
                                                          "SAfsa14_Position_" +
                                                          q.SAfsa14_Id;
                                                        break;
                                                      case 1:
                                                        value =
                                                          q.SAfsa14_Company;
                                                        eleName =
                                                          "SAfsa14_Company_" +
                                                          q.SAfsa14_Id;
                                                        break;
                                                      case 2:
                                                        value =
                                                          q.SAfsa14_FromYear;
                                                        eleName =
                                                          "SAfsa14_FromYear_" +
                                                          q.SAfsa14_Id;
                                                        break;
                                                      case 3:
                                                        value =
                                                          q.SAfsa14_ToYear;
                                                        eleName =
                                                          "SAfsa14_ToYear_" +
                                                          q.SAfsa14_Id;
                                                        break;
                                                    }
                                                  } else {
                                                    switch (iSub3) {
                                                      case 0:
                                                        value =
                                                          q.SAfsa14_Position;
                                                        eleName =
                                                          "SAfsa14_Position_0";
                                                        break;
                                                      case 1:
                                                        value =
                                                          q.SAfsa14_Company;
                                                        eleName =
                                                          "SAfsa14_Company_0";
                                                        break;
                                                      case 2:
                                                        value =
                                                          q.SAfsa14_FromYear;
                                                        eleName =
                                                          "SAfsa14_FromYear_0";
                                                        break;
                                                      case 3:
                                                        value =
                                                          q.SAfsa14_ToYear;
                                                        eleName =
                                                          "SAfsa14_ToYear_0";
                                                        break;
                                                    }

                                                    if (!q.SAfsa14_Id) {
                                                      eleName =
                                                        `New_` +
                                                        eleName +
                                                        "_" +
                                                        id_new;
                                                    } else {
                                                      // อาจจะมีบัคถ้า id ไม่เรียง
                                                      eleName =
                                                        `New_` +
                                                        eleName +
                                                        "_" +
                                                        q.SAfsa14_Id;
                                                      id_new = parseInt(
                                                        q.SAfsa14_Id
                                                      );
                                                    }
                                                  }

                                                  return (
                                                    <div key={"text" + iSub3}>
                                                      {itemSub3.Text.indexOf(
                                                        "<#1#>"
                                                      ) > 0 ? (
                                                        <div key={"#1" + iSub3}>
                                                          <input
                                                            type="text"
                                                            name={eleName}
                                                            className="input_poll_q14"
                                                            style={{
                                                              borderColor:
                                                                value == null
                                                                  ? "#ccc"
                                                                  : "#003b66",
                                                            }}
                                                            defaultValue={value}
                                                            placeholder={itemSub3.Text.replace(
                                                              "<#1#>",
                                                              ""
                                                            )}
                                                            onChange={(e) => {
                                                              e.persist();
                                                              this.input_other(
                                                                e
                                                              );
                                                              this.handleChangeNew(
                                                                e
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      ) : null}

                                                      {itemSub3.Text.indexOf(
                                                        "<#2#>"
                                                      ) > 0 &&
                                                      !itemSub3.Text.indexOf(
                                                        "ค.ศ."
                                                      ) ? (
                                                        <div key={"#2" + iSub3}>
                                                          <input
                                                            type="text"
                                                            name={eleName}
                                                            className="input_poll_q14"
                                                            style={{
                                                              borderColor:
                                                                value == null
                                                                  ? "#ccc"
                                                                  : "#003b66",
                                                            }}
                                                            defaultValue={value}
                                                            placeholder={itemSub3.Text.replace(
                                                              "<#2#>",
                                                              ""
                                                            )}
                                                            onChange={(e) => {
                                                              e.persist();
                                                              this.input_other(
                                                                e
                                                              );
                                                              this.handleChangeNew(
                                                                e
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      ) : null}

                                                      {itemSub3.Text.indexOf(
                                                        "<#2#>"
                                                      ) > 0 &&
                                                      itemSub3.Text.indexOf(
                                                        "ค.ศ."
                                                      ) ? (
                                                        <div
                                                          key={
                                                            "#select" + iSub3
                                                          }
                                                          className={
                                                            "main_select"
                                                          }
                                                        >
                                                          <select
                                                            defaultValue={value}
                                                            name={eleName}
                                                            className="select_style input_poll_q14"
                                                            onChange={(e) => {
                                                              e.persist();
                                                              this.handleChangeNew(
                                                                e
                                                              );
                                                            }}
                                                          >
                                                            <option value="">
                                                              {itemSub3.Text.replace(
                                                                "<#2#>",
                                                                ""
                                                              )}
                                                            </option>
                                                            {
                                                              this.state
                                                                .yearOption
                                                            }
                                                          </select>
                                                          <FontAwesomeIcon icon="angle-down" />
                                                          <br></br>
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  );
                                                }
                                              )
                                            : ""}
                                        </div>
                                      );
                                    }
                                  })
                                : ""}
                            </div>
                            {i === 14 && this.state.show_btn ? (
                              typeof itemSub.SubAnswer != "undefined" ? (
                                <div
                                  style={{
                                    display:
                                      item.SAfsac_Answer == itemSub.ChoiceID
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  <div
                                    className="content_address active add_poll"
                                    onClick={this.addQ14}
                                  >
                                    <div className="text_top_address">
                                      เพิ่มประสบการณ์อื่นๆ
                                    </div>
                                  </div>
                                </div>
                              ) : null
                            ) : null}
                          </div>
                        )
                      ) : item.SAmsa_IsMultiChoice == 1 ? (
                        <div key={i + "_" + iSub}>
                          <label className="container_none_border">
                            <input
                              type="checkbox"
                              required
                              value={itemSub.ChoiceID}
                              data-question={item.SAmsa_Id}
                              name={`SAfsac_Answer[${item.SAmsa_Id}]`}
                              onChange={(e) => {
                                e.persist();
                                this.handleChange(e);
                              }}
                            />
                            <span className="checkmark"></span>
                            <span className="text_checkmark">
                              {itemSub.Text}
                            </span>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      }
    });
    await this.setState({ renderHtml: renderHtml });
    // return renderHtml;
  };

  render() {
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          alt="forward"
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">
                        แบบประเมินความเหมาะสมในการลงทุน
                      </div>
                    </div>

                    <div className="bg_content_white">
                      <div
                        style={{
                          color: "#003b66",
                          fontSize: 24,
                          marginBottom: 0,
                          fontWeight: "bold",
                          display: "none",
                        }}
                      >
                        คำถาม (สำหรับลูกค้า)
                      </div>
                      <form>{this.state.renderHtml}</form>
                      <ButtonCustom
                        handle={this.state.status_btn ? this.save_data : null}
                        label="ถัดไป"
                        color={this.state.status_btn ? "blue" : "gray"}
                        btnClass="long_btn"
                      ></ButtonCustom>
                    </div>
                  </div>
                  <Loading status={this.state.loading_status} />
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default KtbstRiskPoll3;
