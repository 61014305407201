import React, { Component } from 'react';
import './KtbstInvestmentObjective.css';
import ButtonCustom from '../../components/button/ButtonCustom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import validator from 'validator';
import $ from 'jquery';
import {
  checkEditPage,
  update_tracking,
  popupErrorApi,
} from '../../components/helpers/Helpers';
import { IMaskInput } from 'react-imask';
import Loading from '../../components/loading/loading';
import swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import to from 'await-to-js';
import ktbsApi from './../../api';
import { config } from './../../constants';
import { isMobile } from 'react-device-detect';
import BannerContentLeft from '../../components/BannerContentLeft/BannerContentLeft';

const _url = config.baseAPIURL03;
class KtbstInvestmentObjective extends Component {
  constructor(props) {
    super(props);
    const f3 = JSON.parse(localStorage.getItem('f3'));
    if (f3 === null) {
      const f3 = {
        f3_source: '',
        f3_source_text: '',
        f3_by_private_business: '',
        f3_by_employee: '',
        f3_by_heritage: '',
        f3_by_borrowing: '',
        f3_by_divided: '',
        f3_by_retired: '',
        f3_by_saving: '',
        f3_by_other: '',
        f3_by_other_text: '',
        f3_income: '',
        f3_outcome: '',
        f3_invest: '',
        f3_valuable: '',
        f3_own: '',
        purpose_json: [],
        isSubmit: false,
      };
    }
    const purpose_json = f3 ? f3.purpose_json : '';
    this.state = {
      purpose_lists: [],
      purpose_other_require: false,
      loading: false,
      f3_purpose_other_text: '',
      btns: [
        { id: '1', text: 'เพื่อการลงทุนระยะสั้น', isCheck: false },
        { id: '2', text: 'เพื่อการลงทุนระยะยาว', isCheck: false },
        { id: '3', text: 'เพื่อการเกษียณ', isCheck: false },
        { id: '4', text: 'การลงทุน', isCheck: false },
        { id: '5', text: 'เงินเกษียณ', isCheck: false },
        { id: '6', text: 'ประกอบธุรกิจ', isCheck: false },
      ],
      btn_user_choose: true,
      f3_own: f3 !== null ? f3.f3_own : '1',
    };
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    if (checkEditPage()) {
      return;
    }
    this.props.history.push('/investment_info_income');
  }

  async componentDidMount() {
    this.setState({ loading: true });

    // await download_ico_alert();
    await axios
      .get(_url + '/constant/purpose')
      .then((res) => {
        const f3 = JSON.parse(localStorage.getItem('f3'));
        const { purpose_json } = f3;
        const purpose_json_check =
          purpose_json && purpose_json !== '' ? f3.purpose_json : [];
        let purpose_other_require_ = false;
        let purpose_other_text_ = '';
        const purpose_lists = res.data.Data.map((item, i) => {
          let isCheck = false;
          let item_SAmpp_Id = item.SAmpp_Id;
          let item_SAmpp_Other = item.Other;

          if (f3 !== null) {
            purpose_json_check.forEach((item) => {
              if (item.SAfpp_SAmpp_Id === item_SAmpp_Id) {
                isCheck = true;
                if (item_SAmpp_Other === 1) {
                  purpose_other_require_ = true;
                  purpose_other_text_ = item.SAfpp_Text;
                }
              }
            });
          }

          return {
            id: item.SAmpp_Id,
            text: item.SAmpp_Name,
            isCheck: isCheck,
            Other: item.Other,
            SAmpp_EnName: item.SAmpp_EnName,
          };
        });
        this.setState({
          loading: false,
          purpose_lists: purpose_lists,
          purpose_other_require: purpose_other_require_,
          f3_purpose_other_text: purpose_other_text_,
          btn_user_choose: f3 && f3.f3_own === '1' ? true : false,
        });
      })
      .catch((error) => {
        var mss = '';
        var title_mss = '';
        if (error.isAxiosError || !error.response || !error.response?.data) {
          title_mss = 'เกิดข้อผิดพลาด';
          mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
        } else {
          const { Data: errorData } = error.response.data;

          title_mss =
            errorData && errorData[0][0].MsgDescTitle !== ''
              ? errorData[0][0].MsgDescTitle
              : 'เกิดข้อผิดพลาด';
          mss =
            errorData && errorData[0][0].MsgDesc !== ''
              ? errorData[0][0].MsgDesc
              : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
        }
        popupErrorApi(
          mss,
          title_mss,
          true
          // function name() {
          //   var myStyle = document.getElementsByClassName("main_popup_loading")[0].style.display;
          //   if(myStyle === 'none'){
          //     document.getElementsByClassName("main_popup_loading")[0].style.display = 'block';
          //   }
          //   window.location = "login";
          // }
        );
        this.setState({ loading: false });
      });
  }

  handleCheckbox = (i, Other, SAmpp_EnName) => {
    // console.log(i, Other, SAmpp_EnName);
    let purpose_lists = this.state.purpose_lists;
    purpose_lists.forEach((chkbox, val) => {
      if (val === i) {
        if (chkbox.isCheck) {
          // return false;
        }
        chkbox.isCheck = !chkbox.isCheck;
        if (Other === 1) {
          console.log('Other');
          if (chkbox.isCheck) {
            this.setState({ purpose_other_require: true });
          } else {
            this.setState({
              purpose_other_require: false,
              f3_purpose_other_text: '',
            });
          }
        }
        if (chkbox.isCheck) {
          $('.span_f3_purpose').hide();
        }
      } else {
      }
    });
    this.setState({ purpose_lists: purpose_lists });
    // console.log(purpose_lists);
  };
  Click_user_choose = async (e) => {
    var value = e.currentTarget.dataset.id === 'true' ? true : false;
    await this.setState({ btn_user_choose: value });
    if (!value) {
      const HeaderManp = JSON.parse(localStorage.getItem('HeaderManp'));
      const checkuserinfo = JSON.parse(localStorage.getItem('checkuserinfo'));
      const head_token = HeaderManp.head_token;
      var data = {};
      data.head_token = head_token;
      data.card_id = checkuserinfo ? checkuserinfo.cardId : '';
      data.stamp_page = '3';
      data.error_code = '3003';
      var update_tracking_send = await update_tracking(data);
      if (update_tracking_send.success) {
        popupErrorApi(
          'ขออภัยท่านไม่สามารถเปิดบัญชีออนไลน์ได้ กรุณาติดต่อ DAOL Contact Center 0 2351 1800',
          'เกิดข้อผิดพลาด',
          true
        );
      } else {
        // api error
      }
    } else {
    }
  };

  validate = () => {
    $('.span_f3_purpose_other_text').hide();
    $('.span_f3_purpose').hide();
    let countError = 0;
    if (
      this.state.purpose_other_require &&
      validator.isEmpty(this.state.f3_purpose_other_text)
    ) {
      $('.span_f3_purpose_other_text').css('display', 'flex');
      countError++;
    }
    let countChkList = $('.button_checkbox.active').length;
    if (countChkList === 0) {
      $('.span_f3_purpose').css('display', 'flex');
      countError++;
    } else {
      $('.span_f3_purpose').hide();
    }
    return countError > 0 ? false : true;
  };

  handleChange = async (e) => {
    e.preventDefault();
    const isCheckbox = e.target.type === 'checkbox';
    const value = e.target.value;
    await this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : value,
    });
    await this.validate();
  };

  handleIChange = async (v, ref) => {
    await this.setState({
      [ref.el.input.name]: v,
    });
    if (this.state.isSubmit) {
      await this.validate();
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (!this.state.btn_user_choose) {
      popupErrorApi(
        'ขออภัยท่านไม่สามารถเปิดบัญชีออนไลน์ได้ กรุณาติดต่อ DAOL Contact Center 0 2351 1800',
        'เกิดข้อผิดพลาด',
        true
      );
    } else {
      const isValid = this.validate();
      this.setState({ isSubmit: true });
      if (isValid) {
        let purpose_lists = this.state.purpose_lists;
        let purpose_json = [];
        purpose_lists.forEach((chkbox, val) => {
          if (chkbox.isCheck) {
            var obj = {};
            obj['SAfpp_SAmpp_Id'] = chkbox.id;
            obj['SAfpp_Text'] =
              chkbox.Other === 1
                ? validator.trim(this.state.f3_purpose_other_text)
                : '';
            purpose_json.push(obj);
          }
        });
        const f3 = JSON.parse(localStorage.getItem('f3'));
        let f3_ = {
          f3_source: f3 ? f3.f3_source : '',
          f3_source_text: f3 ? f3.f3_source_text : '',
          f3_by_private_business: f3 ? f3.f3_by_private_business : '',
          f3_by_employee: f3 ? f3.f3_by_employee : '',
          f3_by_heritage: f3 ? f3.f3_by_heritage : '',
          f3_by_borrowing: f3 ? f3.f3_by_borrowing : '',
          f3_by_divided: f3 ? f3.f3_by_divided : '',
          f3_by_retired: f3 ? f3.f3_by_retired : '',
          f3_by_saving: f3 ? f3.f3_by_saving : '',
          f3_by_other: f3 ? f3.f3_by_other : '',
          f3_by_other_text: f3 ? f3.f3_by_other_text : '',
          f3_income: f3 ? f3.f3_income : '',
          f3_outcome: f3 ? f3.f3_outcome : '',
          f3_invest: f3 ? f3.f3_invest : '',
          f3_valuable: f3 ? f3.f3_valuable : '',
          f3_by_invest: f3 ? f3.f3_by_invest : '0',
          f3_own: this.state.btn_user_choose ? '1' : '0',
          purpose_json: purpose_json,
        };
        await localStorage.setItem('f3', JSON.stringify(f3_));
        var str = window.location.href;
        let re = /edit/;
        const back_to_edit = re.test(str);
        // if (checkInternet()) {
        if (back_to_edit) {
          let error;
          let result;
          let mss = '';
          let title_mss = '';
          const MySwal = withReactContent(swal);
          const HeaderManp = JSON.parse(localStorage.getItem('HeaderManp'));
          const head_token = HeaderManp.head_token;
          const f3 = JSON.parse(localStorage.getItem('f3'))
            ? JSON.parse(localStorage.getItem('f3'))
            : {};
          const arr_jobs_data = JSON.parse(
            localStorage.getItem('arr_jobs_data')
          )
            ? JSON.parse(localStorage.getItem('arr_jobs_data'))
            : {};
          const {
            f3_source,
            f3_source_text,
            f3_by_private_business,
            f3_by_employee,
            f3_by_heritage,
            f3_by_borrowing,
            f3_by_divided,
            f3_by_retired,
            f3_by_saving,
            f3_by_other,
            f3_by_other_text,
            f3_income,
            f3_outcome,
            f3_invest,
            f3_valuable,
            purpose_json,
            f3_by_invest,
            f3_own,
          } = f3;
          const {
            f1_business_other,
            f1_business_type,
            f1_career,
            f3_officename,
            f1_career_other,
            f1_career_position,
          } = arr_jobs_data;
          const careerAndFinancial = {
            head_token,
            f3_source: f3_source,
            f3_source_text: f3_source_text,
            f3_by_private_business: f3_by_private_business,
            f3_by_employee: f3_by_employee,
            f3_by_heritage: f3_by_heritage,
            f3_by_borrowing: f3_by_borrowing,
            f3_by_divided: f3_by_divided,
            f3_by_retired: f3_by_retired,
            f3_by_saving: f3_by_saving,
            f3_by_other: f3_by_other,
            f3_by_other_text: f3_by_other_text,
            f3_income: f3_income,
            f3_outcome: f3_outcome,
            f3_invest: f3_invest,
            f3_valuable: f3_valuable,
            purpose_json: purpose_json,
            f3_career: f1_career,
            f3_career_text: f1_career_other,
            f3_business: f1_business_type,
            f3_business_text: f1_business_other,
            f3_position: f1_career_position,
            f3_officename: f3_officename,
            f3_by_invest: f3_by_invest,
            f3_own: f3_own,
          };
          await this.setState({ loading: true });
          [error, result] = await to(
            ktbsApi.post('/form/career_and_financial/manp', careerAndFinancial)
          );
          if (
            error ||
            !result ||
            !result.Data ||
            !result.Data[0] ||
            !result.Data[0][0] ||
            result.Data[0][0].IsSuccess !== 1
          ) {
            if (result) {
              title_mss =
                result.Data[0][0].MsgDescTitle !== ''
                  ? result.Data[0][0].MsgDescTitle
                  : 'เกิดข้อผิดพลาด';
              mss =
                result.Data[0][0].MsgDesc !== ''
                  ? result.Data[0][0].MsgDesc
                  : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
            } else if (error) {
              if (!!error.isAxiosError && !error.response) {
                title_mss = 'เกิดข้อผิดพลาด';
                mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
              } else {
                title_mss =
                  error.response.data.Data[0][0].MsgDescTitle !== ''
                    ? error.response.data.Data[0][0].MsgDescTitle
                    : 'เกิดข้อผิดพลาด';
                mss =
                  error.response.data.Data[0][0].MsgDesc !== ''
                    ? error.response.data.Data[0][0].MsgDesc
                    : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
              }
            } else {
              title_mss = 'เกิดข้อผิดพลาด';
              mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
            }
            MySwal.fire({
              imageUrl: '/images/ico_alert.svg',
              imageWidth: 100,
              imageHeight: 100,
              customClass: {
                confirmButton: 'long_btn_fix',
              },
              confirmButtonColor: '#003b66',
              confirmButtonText: 'กลับ',
              title: '<div class="title_popup">' + title_mss + '</div>',
              html: '<div class="content_popup">' + mss + '</div>',
            });
            await this.setState({ statusLoading: false });
            await this.setState({ loading: false });
            return;
          } else {
            // console.log(result.Data[0][0].MsgDesc !== "");
          }
          await this.setState({ loading: false });
          this.props.history.push('/summaryshow');
          localStorage.setItem('editPage', 'investment_objective');
        } else {
          const BackToSummaryRisk = JSON.parse(
            localStorage.getItem('BackToSummaryRisk')
          );
          if (BackToSummaryRisk) {
            localStorage.removeItem('BackToSummaryRisk');
            window.location = 'summary_risk';
            return;
          }
          this.props.history.push('/value_of_invester');
        }
        // } else {
        //   this.setState({
        //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
        //   })
        // }
        return;
      } else {
      }
    }
  };
  render() {
    library.add(fas);
    return (
      <div className={isMobile ? '' : 'body'}>
        <header
          className={isMobile ? '' : 'header-area'}
          style={{ display: isMobile ? 'none' : 'block' }}
        >
          <div className={'container everest-nav-container'}>
            <div className={'row'}>
              <div className={'col-md-6'}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={'col-md-6 flex_end'}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? '' : 'desktop_content section-gap-full'}
          style={{
            backgroundImage: isMobile ? 'none' : 'url(/images/bg.jpg)',
          }}
        >
          <div className={isMobile ? '' : 'container'}>
            <div className={isMobile ? '' : 'row'}>
              <div
                className={'col-md-6'}
                style={{ display: isMobile ? 'none' : 'block' }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? '' : 'col-md-6'}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? '/images/angle-arrow-left.svg'
                              : '/images/angle-arrow-left-blue.svg'
                          }
                          alt="forward"
                          style={{
                            display: checkEditPage() ? 'none' : 'block',
                            marginLeft: '3px',
                          }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">ข้อมูลการลงทุน</div>
                    </div>

                    <div className="bg_content_white over_f_hide">
                      <div
                        style={{
                          color: '#003b66',
                          fontSize: 24,
                          fontWeight: 'bold',
                          padding: '0px 0 21px 0',
                        }}
                      >
                        วัตถุประสงค์การลงทุน (เลือกได้มากกว่า 1 ข้อ)
                      </div>
                      <div
                        className="in_bg_white autoscroll"
                        style={{
                          top: 80,
                          left: 0,
                          right: 0,
                          padding: '0 21px',
                        }}
                      >
                        {this.state.purpose_lists.map((item, i) => {
                          // console.log(item)
                          return (
                            <div
                              className={
                                item.isCheck
                                  ? 'button_checkbox active'
                                  : 'button_checkbox'
                              }
                              key={i}
                              onClick={() =>
                                this.handleCheckbox(
                                  i,
                                  item.Other,
                                  item.SAmpp_EnName
                                )
                              }
                            >
                              {item.text}{' '}
                              {/* <FontAwesomeIcon icon="check"></FontAwesomeIcon> */}
                            </div>
                            // <div
                            //   className={
                            //     item.isCheck ? "flex_radio_2 active" : "flex_radio_2"
                            //   }
                            //   key={i}
                            // >
                            //   <input
                            //     type="radio"
                            //     className="radio_question"
                            //     id={"radio_" + i}
                            //     checked={item.isCheck}
                            //     name={`radio`}
                            //     onChange={() =>
                            //       this.handleCheckbox(i, item.Other, item.SAmpp_EnName)
                            //     }
                            //   />
                            //   <label htmlFor={"radio_" + i} className="text_checkmark">
                            //     {item.text}
                            //   </label>
                            // </div>
                          );
                        })}
                        <div
                          className={'row_idcard'}
                          style={{
                            display: this.state.purpose_other_require
                              ? 'block'
                              : 'none',
                          }}
                        >
                          <div style={{ fontSize: 18 }}>
                            ขอข้อมูลเพิ่มเติม *
                          </div>
                          {/* <input
                  type="text"
                  className="input_idcard"
                  name="f3_purpose_other_text"
                  value={this.state.f3_purpose_other_text}
                  style={{

                    borderBottomColor:
                      this.state.f3_purpose_other_text === ""
                        ? "#ccc"
                        : "#003b66",
                  }}
                  placeholder="กรุณาระบุวัตถุประสงค์การลงทุนอื่นๆ"
                  onFocus={this.min_heigth}
                  onChange={this.handleChange}
                /> */}
                          <IMaskInput
                            mask={/^[A-Za-zก-๙.\s\-]+$/}
                            value={this.state.f3_purpose_other_text}
                            unmask={true}
                            className="input_idcard"
                            name="f3_purpose_other_text"
                            placeholder="กรุณาระบุวัตถุประสงค์การลงทุนอื่นๆ"
                            style={{
                              textDecorationLine: 'none',
                              borderColor:
                                this.state.f3_purpose_other_text !== ''
                                  ? '#003b66'
                                  : '#ccc',
                            }}
                            onAccept={this.handleIChange}
                          />
                        </div>

                        {this.state.purpose_other_require ? (
                          <span
                            className="span_f3_purpose_other_text"
                            style={{
                              color: 'red',
                              display: 'none',
                              justifyContent: 'flex-end',
                            }}
                          >
                            กรุณาระบุวัตถุประสงค์การลงทุนอื่นๆ
                          </span>
                        ) : (
                          ''
                        )}
                        <span
                          className="span_f3_purpose"
                          style={{
                            color: 'red',
                            display: 'none',
                            justifyContent: 'flex-end',
                          }}
                        >
                          กรุณาระบุวัตถุประสงค์การลงทุน
                        </span>
                        <div
                          style={{
                            fontSize: 24,
                            fontWeight: 'normal',
                            paddingTop: 14,
                          }}
                        >
                          ท่านเป็นผู้รับผลประโยชน์ที่แท้จริงของบัญชี ใช่หรือไม่
                        </div>
                        <div className="row_idcard" style={{ border: 'none' }}>
                          <a
                            className=""
                            onClick={this.Click_user_choose}
                            data-id="true"
                          >
                            <ButtonCustom
                              label="ใช่ ฉันเป็นผู้รับผลประโยชน์ที่แท้จริงของบัญชี"
                              color={
                                this.state.btn_user_choose
                                  ? 'active'
                                  : 'unactive'
                              }
                              btnClass="long_btn_status_user font_small"
                            />
                          </a>
                          <a
                            className=""
                            onClick={this.Click_user_choose}
                            data-id="false"
                          >
                            <ButtonCustom
                              label="ไม่ใช่ ฉันไม่ได้เป็นผู้รับผลประโยชน์ที่แท้จริงของบัญชี"
                              color={
                                this.state.btn_user_choose
                                  ? 'unactive'
                                  : 'active'
                              }
                              btnClass="long_btn_status_user font_small"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="under_fix_btn">
                        <ButtonCustom
                          handle={this.handleSubmit}
                          label={checkEditPage() ? 'บันทึกการแก้ไข' : 'ถัดไป'}
                          color={this.state.btn_user_choose ? 'blue' : 'gray'}
                          btnClass="long_btn_fix"
                        ></ButtonCustom>
                      </div>
                    </div>
                  </div>
                  <Loading status={this.state.loading} />
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default KtbstInvestmentObjective;
