const contactAddress = {
  country_name: '',
  district_name: '',
  f2_current_address: '',
  f2_current_building: '',
  f2_current_country: '',
  f2_current_district: '',
  f2_current_floor: '',
  f2_current_moo: '',
  f2_current_no: '',
  f2_current_province: '',
  f2_current_road: '',
  f2_current_subdistrict: '',
  f2_current_zipcode: '',
  province_name: '',
  sub_district_name: '',
};

export default contactAddress;
