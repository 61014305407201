import axios from 'axios';
import { config } from './constants';

const kbstApi = axios.create({
  baseURL: config.baseAPIURL03,
  headers: { 'content-type': 'application/json' },
});

// Response Interceptor
const responseInterceptor = (response) => {
  return response.data ? response.data : response;
};

const errorResponseInterceptor = (error) => {
  return Promise.reject(error);
};

kbstApi.interceptors.response.use(responseInterceptor, errorResponseInterceptor);

export default kbstApi;
