import React, { Component } from "react";
import { Grid, Box, Typography, Avatar, Button } from "@mui/material";
import { add, isDate, format } from "date-fns";
import "./KtbstNDIDChoice.css";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { Wrapper } from "../../components/NDID2";
import { address, config } from "./../../constants";
import {
  findCountryByCode,
  findProvinceByName,
  findDistrictByName,
  findSubDistrictByName,
  setThaiAddressText,
} from "./../../components/helpers/address";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";

const _url = config.baseAPIURL03;

var CheckStatusIdPVerify;
class KtbstNDIDChoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BankID: localStorage.getItem("BankID"),
      BankLogo: localStorage.getItem("BankImage"),
      ApplicationName: localStorage.getItem("BankName"),
      NDID_Status: "LOADING",
      NDID_Status_Display: "LOADING",
      NDIDRequestId: "",
      ReferenceId: "",
      TransactionRef: "",
      RequestMessage: "",
      isStopped: false,
      isPaused: false,
      loading: true,
      isCancel: false,
      cancelled_by_user: false,
      ErrorMessage: "",
      countDown: null,
    };
    this.goBack = this.goBack.bind(this);
    // localStorage.removeItem('HeaderManp');
  }

  goBack() {
    clearInterval(CheckStatusIdPVerify);
    window.clearInterval(CheckStatusIdPVerify);
    this.props.history.goBack();
  }

  allStorage() {
    var values = [],
      keys = Object.keys(localStorage),
      i = keys.length;
    while (i--) {
      if (keys[i].indexOf("debug") !== -1) {
      } else {
        values.push({
          key: keys[i],
          value: localStorage.getItem(keys[i]),
        });
      }
    }
    return values;
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async check_status(ReferenceId) {
    const vm = this;
    CheckStatusIdPVerify = setInterval(async function () {
      await axios
        .get(`${config.baseAPIURL03}/form/ndid2/check`, {
            params: { referenceId: ReferenceId },
        })
        .then(async (res) => {
          const responseData = res.data.data.data;         
          vm.setState({ NDID_Status: responseData.Status.toUpperCase() });

          // set status
          const NDID_Status = responseData.Status.toUpperCase();
          const ErrorMessage = responseData.ErrorMessage
            ? responseData.ErrorMessage
            : "";
          const statusArr = {
            LOADING: ["LOADING", "LOADING"],
            CREATED: [
              "รอผลการยืนยันจากผู้ให้บริการ NDID",
              "รอผลการยืนยันจากผู้ให้บริการ NDID",
            ],
            PENDING: [
              "รอผลการยืนยันจากผู้ให้บริการ NDID",
              "รอผลการยืนยันจากผู้ให้บริการ NDID",
            ],
            ACCEPTED: ["การยืนยันตัวตนสำเร็จ", "การยืนยันตัวตนสำเร็จ"],
            IDP_OR_AS_ERROR: [
              "เกิดข้อผิดพลาด",
              "ไม่สามารถทำรายการได้ในขณะนี้<br/>กรุณาทำรายการใหม่ สอบถามข้อมูลเพิ่มเติม<br/>กรุณาติดต่อ DAOL Contact Center<br/>0 2351 1800",
            ],
            REJECTED: [
              "แจ้งเตือน", //ทำรายการใหม่อีกครั้ง
              "ท่านยกเลิกรายการ หรือไม่ทำรายการ<br>ในเวลาที่กำหนด กรุณาทำรายการใหม่",
            ],
            CANCELLED_USER: [
              "แจ้งเตือน",
              "ท่านยกเลิกรายการ หรือไม่ทำรายการ<br>ในเวลาที่กำหนด กรุณาทำรายการใหม่",
            ],
            CANCELLED: [
              //ทำรายการใหม่อีกครั้ง
              "แจ้งเตือน",
              "ท่านยกเลิกรายการ หรือไม่ทำรายการ<br>ในเวลาที่กำหนด กรุณาทำรายการใหม่",
            ],
            REQUESTED_ERROR: [
              "เกิดข้อผิดพลาด",
              "ไม่สามารถทำรายการได้ในขณะนี้<br/>กรุณาทำรายการใหม่ สอบถามข้อมูลเพิ่มเติม<br/>กรุณาติดต่อ DAOL Contact Center<br/>0 2351 1800",
            ],
            TIMEOUT: [
              "แจ้งเตือน",
              "ท่านยกเลิกรายการ หรือไม่ทำรายการ<br>ในเวลาที่กำหนด กรุณาทำรายการใหม่",
            ],
          };

          if (NDID_Status === "CANCELLED") {
            if (res.data.data[0].cancelled_by_user) {
              await vm.setState({
                NDID_Status: NDID_Status,
                NDID_Status_Display: statusArr["CANCELLED_USER"][1],
                cancelled_by_user: true,
                ErrorMessage: ErrorMessage,
              });
            } else {
              await vm.setState({
                NDID_Status: NDID_Status,
                NDID_Status_Display: statusArr["CANCELLED"][1],
                cancelled_by_user: false,
                ErrorMessage: ErrorMessage,
              });
            }
          } else {
            await vm.setState({
              NDID_Status: NDID_Status,
              NDID_Status_Display: statusArr[NDID_Status][1],
              cancelled_by_user: false,
              ErrorMessage: ErrorMessage,
            });
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.MsgDesc &&
            error.response.data.MsgDesc.message
          ) {
            vm.notPassNDID(error.response.data.MsgDesc.message);
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.MsgDesc
          ) {
            vm.notPassNDID(error.response.data.MsgDesc);
          } else {
            vm.notPassNDID(
              "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง",
              "เกิดข้อผิดพลาด"
            );
          }
          return clearInterval(CheckStatusIdPVerify);
        });
      vm.Tick();
    }, 5000);
  }

  async Tick() {
    const NDID_Status = this.state.NDID_Status.toUpperCase(); 
    const ErrorMessage = this.state.ErrorMessage;
    const statusArr = {
      LOADING: ["LOADING", "LOADING"],
      CREATED: [
        "รอผลการยืนยันจากผู้ให้บริการ NDID",
        "รอผลการยืนยันจากผู้ให้บริการ NDID",
      ],
      PENDING: [
        "รอผลการยืนยันจากผู้ให้บริการ NDID",
        "รอผลการยืนยันจากผู้ให้บริการ NDID",
      ],
      ACCEPTED: ["การยืนยันตัวตนสำเร็จ", "การยืนยันตัวตนสำเร็จ"],
      IDP_OR_AS_ERROR: [
        "เกิดข้อผิดพลาด",
        "ไม่สามารถทำรายการได้ในขณะนี้<br/>กรุณาทำรายการใหม่ สอบถามข้อมูลเพิ่มเติม<br/>กรุณาติดต่อ DAOL Contact Center<br/>0 2351 1800",
      ],
      REJECTED: [
        "แจ้งเตือน", //ทำรายการใหม่อีกครั้ง
        "ท่านยกเลิกรายการ หรือไม่ทำรายการ<br>ในเวลาที่กำหนด กรุณาทำรายการใหม่",
      ],
      CANCELLED_USER: [
        "แจ้งเตือน",
        "ท่านยกเลิกรายการ หรือไม่ทำรายการ<br>ในเวลาที่กำหนด กรุณาทำรายการใหม่",
      ],
      CANCELLED: [
        //ทำรายการใหม่อีกครั้ง
        "แจ้งเตือน",
        "ท่านยกเลิกรายการ หรือไม่ทำรายการ<br>ในเวลาที่กำหนด กรุณาทำรายการใหม่",
      ],
      REQUESTED_ERROR: [
        "เกิดข้อผิดพลาด",
        "ไม่สามารถทำรายการได้ในขณะนี้<br/>กรุณาทำรายการใหม่ สอบถามข้อมูลเพิ่มเติม<br/>กรุณาติดต่อ DAOL Contact Center<br/>0 2351 1800",
      ],
      TIMEOUT: [
        "แจ้งเตือน",
        "ท่านยกเลิกรายการ หรือไม่ทำรายการ<br>ในเวลาที่กำหนด กรุณาทำรายการใหม่",
      ],
    };

    if (NDID_Status === "ACCEPTED") {
      window.clearInterval(CheckStatusIdPVerify);
      await this.setState({ loading: false });
      await localStorage.setItem("passNDID", true);
      await this.passNDID(statusArr[NDID_Status][1]);
      localStorage.removeItem("BankName");
      return clearInterval(CheckStatusIdPVerify);
    }

    if (NDID_Status === "CANCELLED") {
      window.clearInterval(CheckStatusIdPVerify);
      this.setState({ loading: false });
      if (this.state.cancelled_by_user) {
        this.notPassNDID(
          statusArr["CANCELLED_USER"][1],
          statusArr["CANCELLED_USER"][0],
          null,
          "ทำรายการใหม่อีกครั้ง"
        );
      } else {
        this.notPassNDID(statusArr[NDID_Status][1], statusArr[NDID_Status][0]);
      }
      return clearInterval(CheckStatusIdPVerify);
    }

    if (
      NDID_Status === "IDP_OR_AS_ERROR" ||
      NDID_Status === "REJECTED" ||
      NDID_Status === "REQUESTED_ERROR" ||
      NDID_Status === "TIMEOUT"
    ) {
      window.clearInterval(CheckStatusIdPVerify);
      this.setState({ loading: false });
      if(NDID_Status === "TIMEOUT" || NDID_Status === "REJECTED")
      {
        this.notPassNDID(statusArr[NDID_Status][1], statusArr[NDID_Status][0]);
      }else{
        this.notPassNDID(statusArr[NDID_Status][1], statusArr[NDID_Status][0]);
      }
      return clearInterval(CheckStatusIdPVerify);
    }
  }

  async componentDidMount() {
    const { BankID } = this.state;
    const f0 = JSON.parse(localStorage.getItem("f0"));
    const vm = this;
    const NDIDRequestId = localStorage.getItem("NDIDRequestId");
    const ReferenceId = localStorage.getItem("ReferenceId");
    const TransactionRef = localStorage.getItem("TransactionRef");
    const RequestMessage = localStorage.getItem("RequestMessage");
    const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
    const head_token = HeaderManp?.head_token;
    const token = HeaderManp?.token;
    const CountDown = localStorage.getItem("CountDown") ? new Date(localStorage.getItem("CountDown")) : "";
    //console.log(localStorage.getItem("CountDown"));
    //console.log("CountDown: "+CountDown);
    if (localStorage.getItem("IDAddress")) {
      localStorage.removeItem("IDAddress");
    }
    if (localStorage.getItem("WorkAddress")) {
      localStorage.removeItem("WorkAddress");
    }
    if (localStorage.getItem("ContactAddress")) {
      localStorage.removeItem("ContactAddress");
    }
    if (localStorage.getItem("HomeAddress")) {
      localStorage.removeItem("HomeAddress");
    }
    // Check ReferenceId
    if (ReferenceId && TransactionRef && CountDown) {
      // Will call this case when user refresh page
      this.setState({
        NDIDRequestId: NDIDRequestId,
        ReferenceId: ReferenceId,
        TransactionRef: TransactionRef,
        RequestMessage: RequestMessage,
        countDown: CountDown,
      });
      // Call API Check Status
      await vm.check_status(ReferenceId.toUpperCase());
    } else {
      await axios
        .post(`${config.baseAPIURL03}/form/ndid2/verify`, undefined, {
            params: {
            identifier: f0.f0_national_id,
            listIdp: BankID,
            consentNdid: true,
            token: token,
            },
        })
        .then(async (res) => {
          const data = res.data;
          if (data.code === "200") {
            const initCountDown = add(new Date(), { hours: 1 });
            const { NDIDRequestId, ReferenceId, TransactionRef, RequestMessage } =
              data.data.data[0];
            await this.setState({
              NDIDRequestId,
              ReferenceId,
              TransactionRef,
              RequestMessage,
              countDown: initCountDown,
            });
            await localStorage.setItem(
                "CountDown",
                initCountDown
            );
            await localStorage.setItem("NDIDRequestId", NDIDRequestId);
            await localStorage.setItem(
              "ReferenceId",
              ReferenceId.toUpperCase()
            );
            await localStorage.setItem(
              "TransactionRef",
              TransactionRef
            );
            await localStorage.setItem("RequestMessage", RequestMessage);
            // Call API Check Status
            await vm.check_status(ReferenceId.toUpperCase());
          }

          if (data.data === null) {
            if (data.code === "error limit") {
              this.setState({ NDID_Status: 'error limit' });
              // Handle reach limit to change bank to do NDID
              vm.notPassNDID(
                "ไม่สามารถทำรายการได้ในขณะนี้<br/>ท่านทำรายการเกินกว่าจำนวนที่บริษัทกำหนด<br/>กรุณาติดต่อ DAOL Contact Center<br/>0 2351 1800",
                "ไม่สามารถดำเนินการต่อได้"
              );
            } else {
              // Handle another error
              vm.notPassNDID(data.message, "เกิดข้อผิดพลาด");
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.setState({ loading: false });

          if (
            error.response &&
            error.response.data &&
            error.response.data.MsgDesc &&
            error.response.data.MsgDesc.message
          ) {
            vm.notPassNDID(error.response.data.MsgDesc.message);
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.MsgDesc
          ) {
            vm.notPassNDID(error.response.data.MsgDesc);
          } else {
            vm.notPassNDID(
              "สอบถามข้อมูลเพิ่มเติมกรุณาติดต่อ DAOL Contact Center<br>0 2351 1800",
              "เกิดข้อผิดพลาด"
            );
          }
          return clearInterval(CheckStatusIdPVerify);
        });
    }
  }
  componentWillUnmount() {
    this.handleCancelNDID();
    return clearInterval(CheckStatusIdPVerify);
  }

  sendCancel = async () => {
    const MySwal = withReactContent(swal);
    MySwal.fire({
      customClass: {
        confirmButton: "long_btn",
        cancelButton: "btn_ndid_white",
      },
      imageUrl: "/images/ico_alert.svg",
      imageWidth: 100,
      imageHeight: 100,
      showCancelButton: true,
      confirmButtonColor: "#003b66",
      confirmButtonText: `ดำเนินการต่อที่ ${this.state.ApplicationName}`,
      cancelButtonText: "ยืนยันการเปลี่ยนธนาคาร",
      allowOutsideClick: false,
      allowEscapeKey: false,
      title: `<span class="title_popup">ท่านสามารถเปลี่ยนธนาคาร<br>ได้ 2 ครั้ง</span>`,
      html: `<div class="content_popup">กรุณาตรวจสอบที่แอปพลิเคชันของธนาคาร<br>ก่อนยืนยันการเปลี่ยนธนาคาร</div>`,
    }).then(async (result) => {
      if (result.isConfirmed) {
      } else {
        this.handleCheckTotalOfNDID("CHANGE_BANK");
      }
    });
  };

  handleCancelNDID = async () => {
    this.setState({ isCancel: true, loading: true });
    const ops = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
      },
      url: `${config.baseAPIURL03}/form/ndid2/cancel?referenceId=${this.state.ReferenceId}`,
    };
    axios(ops)
      .then(async (res) => {
        const data = res.data;
        if (data.code === "ERROR FROM API") {
          // TODO: Handle error from cancel API
          console.error(data.message);
        }
      })
      .catch((error) => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  passNDID = async (msg) => {
    const MySwal = withReactContent(swal);
    const vm = this;
    MySwal.fire({
      customClass: {
        confirmButton: "long_btn_fix",
        cancelButton: "long_btn_fix",
      },
      imageUrl: "/images/ico_approve.svg",
      imageWidth: 100,
      imageHeight: 100,
      confirmButtonColor: "#003b66",
      confirmButtonText: "ถัดไป",
      title: `<span class="title_popup">${msg}</span>`,
    }).then(async function () {
      vm.setState({ loading: true });
      var last_stamp = await JSON.stringify(vm.allStorage());
      const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
      const head_token = HeaderManp.head_token;
      const token = HeaderManp.head_token;
      const ops = {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
        data: {
          last_stamp: last_stamp,
          head_token: head_token,
          stamp_page: "NDID",
        },
        url: _url + "/form/comeback/manp",
      };
      axios(ops)
        .then(async (res) => {
          vm.setState({ loading: false });
          vm.props.history.push("/idc_address_choose");
        })
        .catch((error) => {
          vm.setState({ loading: false });
          vm.notPassNDID(
            "สอบถามข้อมูลเพิ่มเติมกรุณาติดต่อ DAOL Contact Center<br>0 2351 1800",
            "ไม่สามารถทำรายการได้"
          );
        });
    });
  };

  

  notPassNDID = (msg, title = "เกิดข้อผิดพลาด", func, buttonText="ตกลง") => {

    const MySwal = withReactContent(swal);
    const vm = this;
    MySwal.fire({
      customClass: {
        confirmButton: "long_btn_fix",
        cancelButton: "long_btn_fix",
      },
      // icon: 'warning',
      imageUrl: "/images/ico_alert.svg",
      imageWidth: 100,
      imageHeight: 100,
      confirmButtonColor: "#003b66",
      confirmButtonText: buttonText,
      title: `<span class="title_popup">${title}</span>`,
      html: `<div class="content_popup">${msg}</div>`,
    }).then(
      func ||
         function () {
          vm.handleCheckTotalOfNDID('');         
        }
    );
  };

  handleCheckTotalOfNDID = async (action) => {
    this.setState({ loading: true });
    const f0 = JSON.parse(localStorage.getItem("f0"));
    const vm = this;
    await axios
      .get(
        `${config.baseAPIURL03}/form/ndid2/quota?identifier=${f0.f0_national_id}`
      )
      .then((res) => {
        vm.setState({ loading: false });
        const { limit, total } = res.data.data;

        if (total < limit) {
          vm.props.history.push("/ndid");
        }
        else if(total === limit){
          vm.notPassNDID(
            "ท่านทำรายการเกินกว่าจำนวนที่บริษัทกำหนด<br/>กรุณาติดต่อ DAOL Contact Center<br/>0 2351 1800",
            "ไม่สามารถดำเนินการต่อได้",
            function () {
              vm.props.history.push("/ndid_info");
            }
          );
        }
         else {
          if (action === "CHANGE_BANK") {
            vm.notPassNDID(
              "ท่านทำรายการเกินกว่าจำนวนที่บริษัทกำหนด<br/>กรุณาติดต่อ DAOL Contact Center<br/>0 2351 1800",
              "ไม่สามารถดำเนินการต่อได้",
              function () {
                vm.props.history.push("/ndid_info");
              }
            );
          } else {
            vm.props.history.push("/ndid_info");
          }
        }
      })
      .catch((er) => {
        vm.setState({ loading: false });
        vm.notPassNDID(
          "ไม่สามารถทำรายการได้ในขณะนี<br/>กรุณาทำรายการใหม่ สอบถามข้อมูลเพิ่มเติม<br/>กรุณาติดต่อ DAOL Contact Center<br/>0 2351 1800",
          "เกิดข้อผิดพลาด"
        );
      });
  };

  async setBaseAddressData({
    country,
    province,
    district,
    subDistrict,
    zipcode,
    streetAddress,
    addressFull,
  }) {
    let result = address;
    if (country === "") {
      return result;
    }

    const { countries, provinces, districts, subdistricts } =
      this.props.address;

    const findCountry = findCountryByCode(countries, country);
    if (!findCountry) {
      result = {
        ...result,
        countryName: "ไทย",
        countryID: "212",
      };
    } else {
      if (country !== "TH") {
        result = {
          ...result,
          countryName: findCountry ? findCountry.ThName : "",
          countryID: findCountry ? findCountry.Id : "",
          addressFull: findCountry ? addressFull : "",
        };
      } else {
        const findProvince = findProvinceByName(provinces, province);
        let findDistrict;
        let findSubDistrict;

        if (findProvince) {
          findDistrict = findDistrictByName(
            districts,
            district,
            findProvince.Id
          );
        }

        if (findDistrict) {
          findSubDistrict = findSubDistrictByName(
            subdistricts,
            subDistrict,
            findDistrict.Id
          );
        }

        result = {
          ...result,
          countryName: findCountry ? findCountry.ThName : "ไทย",
          countryID: findCountry ? findCountry.Id : "212",
          provinceName: findProvince ? findProvince.ThName : "",
          provinceID: findProvince ? findProvince.Id : "",
          districtName: findDistrict ? findDistrict.ThName : "",
          districtID: findDistrict ? findDistrict.Id : "",
          subDistrictName: findSubDistrict ? findSubDistrict.ThName : "",
          subDistrictID: findSubDistrict ? findSubDistrict.Id : "",
          homeNo: streetAddress,
          zipcode: zipcode,
        };

        result.addressFull = setThaiAddressText({
          houseNo: result.homeNo,
          moo: "",
          building: "",
          floor: "",
          road: "",
          province: result.provinceName,
          district: result.districtName,
          subDistrict: result.subDistrictName,
          zipcode: zipcode,
          country: result.countryName,
        });
      }
    }
    return result;
  }

  render() {
    const { ApplicationName, BankLogo } = this.state;
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back">
                        {/* <img
                src="/images/angle-arrow-left.svg"
                alt="forward"
                style={{ marginLeft: "3px" }}
                className="arrow_back"
              /> */}
                      </div>
                      <div className="text_title">
                        ยืนยันตัวตนทางดิจิทัล (NDID)
                      </div>
                    </div>

                    <div
                      className="bg_content_white over_f_hide"
                      style={{ padding: "0" }}
                    >
                      <div
                        className="in_bg_white"
                        style={{
                          top: 0,
                          left: 0,
                          right: 0,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          className="botton_text_ndid"
                          style={{
                            fontSize: "22px",
                            marginBottom: "12px",
                            lineHeight: "26px",
                            marginTop: !isMobile && "0",
                          }}
                        >
                          กรุณายืนยันตัวตนทางดิจิทัล NDID ภายใน
                        </div>
                        {isDate(this.state.countDown) && (
                          <Grid
                            sx={{
                              padding: "9px",
                              borderRadius: "10px",
                              backgroundColor: "#F7F8FB",
                              margin: "10px 24px 6px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "DB Heavent",
                                color: "#003b66",
                                fontSize: "26px",
                                lineHeight: "31px",
                                textAlign: "center",
                              }}
                            >
                              เวลา {format(this.state.countDown, "HH:mm")} น.
                              <br />
                              วันที่{" "}
                              {format(this.state.countDown, "dd/MM/yyyy")}
                            </Typography>
                          </Grid>
                        )}
                        {this.state.TransactionRef && (
                          <Grid>
                            <Typography
                              sx={{
                                fontFamily: "DB Heavent",
                                fontSize: "16px",
                                lineHeight: "19px",
                                color: "#003b66",
                                textAlign: "center",
                              }}
                            >
                              รหัสอ้างอิง : {this.state.TransactionRef}
                            </Typography>
                          </Grid>
                        )}
                        <Grid
                          sx={{
                            height: "100%",
                            padding: "16px 24px",
                          }}
                        >
                          <Wrapper
                            content={
                              <Grid id="ndid-steps" container>
                                <Grid
                                  sx={{ marginBottom: "16px", width: "100%" }}
                                >
                                  <Typography
                                    className="text-primary"
                                    sx={{
                                      fontFamily: "DB Heavent Med !important",
                                      fontSize: "22px",
                                      lineHeight: "26px",
                                      textAlign: "center",
                                    }}
                                  >
                                    ขั้นตอนการยืนยันตัวตน
                                  </Typography>
                                </Grid>
                                {/* ------- NDID Step 1 -------  */}
                                <Box
                                  id="ndid-step-1"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    width: "100%",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Box
                                    id="step-img"
                                    sx={{
                                      marginRight: "16px",
                                      padding: "11px",
                                      backgroundColor: "#F7F8FB",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <Avatar
                                      alt={ApplicationName}
                                      src={BankLogo}
                                      variant="rounded"
                                      sx={{ width: 48, height: 48 }}
                                    />
                                  </Box>
                                  <Box id="step-description">
                                    <Box>
                                      <Typography
                                        sx={{
                                          fontFamily: "DB Heavent",
                                          fontSize: "18px",
                                          lineHeight: "22px",
                                          color: "#003b66",
                                        }}
                                      >
                                        ขั้นตอนที่ 1
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography
                                        sx={{
                                          fontFamily: "DB Heavent",
                                          fontSize: "20px",
                                          lineHeight: "24px",
                                        }}
                                      >
                                        กรุณาเปิดแอปพลิเคชัน{" "}
                                        <Typography
                                          sx={{
                                            fontFamily: "DB Heavent",
                                            fontSize: "20px",
                                            color: "#AA875E",
                                            lineHeight: "24px",
                                          }}
                                          variant="span"
                                        >
                                          {ApplicationName}
                                        </Typography>{" "}
                                        เพื่อยืนยันตัวตน
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                                {/* ------- NDID Step 2 -------  */}
                                <Box
                                  id="ndid-step-2"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    width: "100%",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Box
                                    id="step-img"
                                    sx={{
                                      marginRight: "16px",
                                      padding: "11px",
                                      backgroundColor: "#F7F8FB",
                                      borderRadius: "10px",
                                      width: "48px",
                                      height: "48px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Avatar
                                      alt="ndid-logo"
                                      src="/images/ndid_icon.png"
                                      variant="rounded"
                                      sx={{ width: 48, height: 26 }}
                                    />
                                  </Box>
                                  <Box id="step-description">
                                    <Box>
                                      <Typography
                                        sx={{
                                          fontFamily: "DB Heavent",
                                          fontSize: "18px",
                                          lineHeight: "22px",
                                          color: "#003b66",
                                        }}
                                      >
                                        ขั้นตอนที่ 2
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography
                                        sx={{
                                          fontFamily: "DB Heavent",
                                          fontSize: "20px",
                                          lineHeight: "24px",
                                        }}
                                      >
                                        เลือก{" "}
                                        <Typography
                                          sx={{
                                            fontFamily: "DB Heavent",
                                            fontSize: "20px",
                                            color: "#AA875E",
                                            lineHeight: "24px",
                                          }}
                                          variant="span"
                                        >
                                          “บริการ NDID”
                                        </Typography>{" "}
                                        เพื่อยืนยันตัวตน
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                                {/* ------- NDID Step 3 -------  */}
                                <Box
                                  id="ndid-step-3"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    width: "100%",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Box
                                    id="step-img"
                                    sx={{
                                      marginRight: "16px",
                                      padding: "11px",
                                      backgroundColor: "#F7F8FB",
                                      borderRadius: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Avatar
                                      alt="bank-icon"
                                      src="/images/bank_app_icon.png"
                                      variant="rounded"
                                      sx={{ width: 48, height: 48 }}
                                    />
                                  </Box>
                                  <Box id="step-description">
                                    <Box>
                                      <Typography
                                        sx={{
                                          fontFamily: "DB Heavent",
                                          fontSize: "18px",
                                          lineHeight: "22px",
                                          color: "#003b66",
                                        }}
                                      >
                                        ขั้นตอนที่ 3
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography
                                        sx={{
                                          fontFamily: "DB Heavent",
                                          fontSize: "20px",
                                          lineHeight: "24px",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontFamily: "DB Heavent",
                                            fontSize: "20px",
                                            color: "#AA875E",
                                            lineHeight: "24px",
                                          }}
                                          variant="span"
                                        >
                                          ทำตามขั้นตอน
                                        </Typography>{" "}
                                        ในแอปพลิเคชันของธนาคารจนเสร็จสิ้น
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                                {/* ------- NDID Step 4 -------  */}
                                <Box
                                  id="ndid-step-4"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Box
                                    id="step-img"
                                    sx={{
                                      marginRight: "16px",
                                      padding: "11px",
                                      backgroundColor: "#F7F8FB",
                                      borderRadius: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                      width: "48px",
                                      height: "48px",
                                    }}
                                  >
                                    <Avatar
                                      alt="daolife-icon"
                                      src="/images/daol_icon.png"
                                      variant="rounded"
                                      sx={{ width: 48, height: 12 }}
                                    />
                                  </Box>
                                  <Box id="step-description">
                                    <Box>
                                      <Typography
                                        sx={{
                                          fontFamily: "DB Heavent",
                                          fontSize: "18px",
                                          lineHeight: "22px",
                                          color: "#003b66",
                                        }}
                                      >
                                        ขั้นตอนที่ 4
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography
                                        sx={{
                                          fontFamily: "DB Heavent",
                                          fontSize: "20px",
                                          lineHeight: "24px",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontFamily: "DB Heavent",
                                            fontSize: "20px",
                                            color: "#AA875E",
                                            lineHeight: "24px",
                                          }}
                                          variant="span"
                                        >
                                          กลับมายัง
                                        </Typography>{" "}
                                        ระบบเปิดบัญชี DAOL INVESTMENT
                                        เพื่อดำเนินการต่อ
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            }
                          />
                        </Grid>
                      </div>

                      {this.state.ReferenceId &&
                        this.state.NDID_Status != "" &&
                        this.state.NDID_Status != "ACCEPTED" &&
                        //  && this.state.NDID_Status != 'LOADING'
                        this.state.NDID_Status != "CREATED" && (
                          <div
                            id="sendCancel"
                            className="under_fix_btn"
                            onClick={
                              this.state.isCancel ? null : this.sendCancel
                            }
                          >
                            <Button
                              fullWidth
                              sx={{
                                height: "50px",
                                fontFamily: "DB Heavent Med",
                                fontSize: "20px",
                                color: "#003b66",
                                lineHeight: "24px",
                                border: "1px solid !important",
                                borderColor: "#003b66 !important",
                                backgroundColor: "white !important",
                              }}
                              disableRipple
                            >
                              เปลี่ยนธนาคารที่ต้องการยืนยันตัวตน
                            </Button>
                            {/* <ButtonCustom
                              label="ยกเลิกการเชื่อมต่อ"
                              color={this.state.isCancel ? "gray" : "red"}
                              btnClass="long_btn"
                            ></ButtonCustom> */}
                          </div>
                        )}

                      {/* <a
              className='a_ndid'
              href='/splashscreenoa'
              style={{ display: 'block' }}
              onClick={this.notPassNDID}
            >
              <ButtonCustom
                label='NDID ไม่ผ่าน'
                color=''
                btnClass='btn_ndid_red'
              ></ButtonCustom>
            </a>
            <a
              className='text_title a_ndid'
              href='/splashscreenoa'
              style={{ display: 'block' }}
              onClick={this.passNDID}
            >
              <ButtonCustom
                label='NDID ผ่าน'
                color=''
                btnClass='btn_ndid_green'
              ></ButtonCustom>
            </a> */}
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default KtbstNDIDChoice;
