/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import "./KtbstSignedAttatched.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import SignaturePad from "react-signature-canvas";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import $ from "jquery";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popup from "../../components/popup_photo/PopupPhoto";
import Loading from "../../components/loading/loading";
// import { fireEvent } from '@testing-library/react';
import Swal from "sweetalert2";
import Webcam from "react-webcam";
import to from "await-to-js";
import kbstApi from "./../../api";
import { createCanvas, loadImage } from "canvas";
import { checkEditPage } from "../../components/helpers/Helpers";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
import localForage from "localforage";

const videoConstraints = {
  // width: 400,
  // height: 700,
  facingMode: "environment",
};

class KtbstSignedAttatched extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trimmedDataURL: null,
      status_popup: false,
      loading: false,
      showCameraPopup: false,
      isDisconnected: false,
      isMobilePortrait: false,
    };
    this.goBack = this.goBack.bind(this);
    this.fileuploadRef = React.createRef();
    this.webcamRef = React.createRef();
  }

  sigPad = {};

  clear = () => {
    this.sigPad.clear();
  };

  trim = async () => {
    const imageSrc = await this.sigPad.toDataURL("image/png");
    const imageSrc2 = await this.sigPad
      .getTrimmedCanvas()
      .toDataURL("image/png");
    await loadImage(imageSrc2).then(async (image) => {
      if (image.width * image.height === 1) {
        // console.log(image.width, image.height);
      } else {
        await this.setState({ trimmedDataURL: imageSrc });
        await localStorage.setItem("trimmedDataURL", imageSrc);
      }
    });
  };

  goBack() {
    if (checkEditPage()) {
      return;
    }
    this.props.history.push("/signed_attatched_ex");
  }
  status_hide = (e) => {
    if (e.target.className === "background_opacity") {
      this.setState({ status_popup: false });
    }
  };
  fn_add_photo = async () => {
    const vm = this;
    const MySwal = withReactContent(swal);
    await MySwal.fire({
      cancelButtonText: "ล้าง",
      confirmButtonText: "บันทึกลายเซ็น",
      showCancelButton: true,
      customClass: {
        content: "sign-content-class",
        cancelButton: "btn_small_left button-custom red",
        confirmButton: "btn_small_right button-custom green",
        actions: "two_btn",
      },
      title: '<div class="title_popup"> วาดภาพลายเซ็นของคุณ</div>',
      html: (
        <div style={{ display: "block" }}>
          <SignaturePad
            penColor="#003b66"
            ref={(ref) => {
              this.sigPad = ref;
            }}
            canvasProps={{ className: "sigCanvas" }}
          />
        </div>
      ),
    }).then(async (result) => {
      this.setState({ loading: true });
      if (result.isConfirmed) {
        await vm.trim();
        if (this.state.trimmedDataURL) {
          // $('#SignaturePhoto').html(
          //   "<img style='width:100%; height: auto;' src='" +
          //     localStorage.getItem('trimmedDataURL') +
          //     "'/>"
          // );
          await this.drawImg1();
          this.setState({ status_popup: false });
        } else {
          await this.setState({ trimmedDataURL: null });
          await localStorage.removeItem("trimmedDataURL");
          $("#SignaturePhoto").html(
            "<div class='main_border_singned'><img style='width: 100%;' src='/images/signEx2.png'/><div/>"
          );
          //<div class='singned_flex'><img src='/images/ico_image_add.png' style='margin: 0 auto' /><span style='margin: 10px auto;'>เพิ่มลายเซ็น</span><div>
        }
        this.setState({ loading: false });
      } else {
        if (this.state.trimmedDataURL) {
          // $('#SignaturePhoto').html(
          //   "<img style='width:100%; height: auto;' src='" +
          //     localStorage.getItem('trimmedDataURL') +
          //     "'/>"
          // );
          await this.drawImg1();
        } else {
          $("#SignaturePhoto").html(
            "<div class='main_border_singned'><img style='width: 100%;' src='/images/signEx2.png'/><div/>"
          );
          //<div class='singned_flex'><img src='/images/ico_image_add.png' style='margin: 0 auto' /><span style='margin: 10px auto;'>เพิ่มลายเซ็น</span><div>
        }
        // await this.setState({ trimmedDataURL: null });
        // await localStorage.removeItem("trimmedDataURL");
        this.setState({ loading: false });
      }
    });
  };

  async componentDidMount() {
    this.setState({ loading: true });
    const trimmedDataURL = await localStorage.getItem("trimmedDataURL");
    await this.setState({ trimmedDataURL: trimmedDataURL });
    // console.log(trimmedDataURL);

    if (trimmedDataURL) {
      await this.drawImg1();
      this.setState({ loading: false });
    } else {
      this.setState({ trimmedDataURL: null });
      localStorage.removeItem("trimmedDataURL");
      // $('#SignaturePhoto').html(
      //   "<div class='main_border_singned'><img style='width: 100%;' src='/images/signEx2.png'/><div/>"
      // );

      //<div class='singned_flex'><img src='/images/ico_image_add.png' style='margin: 0 auto' /><span style='margin: 10px auto;'>เพิ่มลายเซ็น</span><div>
      this.setState({ loading: false });
    }
    // await download_ico_alert();
    this.checkScreen();
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    if (!this.state.trimmedDataURL) {
      return;
    }
    this.setState({ loading: true });
    const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
    const head_token = HeaderManp.head_token;
    const { trimmedDataURL } = this.state;
    const tmp = trimmedDataURL.split(";");
    const base64 = tmp[1].replace("base64,", "");

    // console.log(this.state.trimmedDataURL);

    const formData = new FormData();
    formData.append("eSignature", base64);
    formData.append("head_token", head_token);
    formData.append("f10_docf_id", "4");
    formData.append("f10_doct_id", "4");
    // if (checkInternet()) {
    const [error, result] = await to(
      kbstApi.post("/form/document/upload", formData)
    );
    if (
      error ||
      !result ||
      !result.Data ||
      !result.Data[0] ||
      !result.Data[0][0]
    ) {
      this.setState({ loading: false });
      Swal.fire({
        type: "error",
        imageUrl: "../../../images/ico_alert.svg",
        customClass: {
          confirmButton: "long_btn_fix",
          cancelButton: "long_btn_fix",
        },
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title: '<div class="title_popup">เกิดข้อผิดพลาด</div>',
        html:
          '<div class="content_popup">ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง</div>',
      });
      return;
    }

    const { IsSuccess } = result.Data[0][0];
    if (IsSuccess !== 1) {
      this.setState({ loading: false });
      Swal.fire({
        type: "error",
        imageUrl: "../../../images/ico_alert.svg",
        customClass: {
          confirmButton: "long_btn_fix",
          cancelButton: "long_btn_fix",
        },
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title: '<div class="title_popup">เกิดข้อผิดพลาด</div>',
        html:
          '<div class="content_popup">ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง</div>',
      });
      return;
    }
    var link = window.location.href.indexOf("edit");
    if (link === -1) {
      this.setState({ loading: false });
      this.props.history.push("/investment_info_income");
    } else {
      this.setState({ loading: false });
      this.props.history.push("/summaryshow");
      localStorage.setItem("editPage", "signed_attatched");
    }
    // } else {
    //   this.setState({ loading: false });
    //   this.setState({
    //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
    //   });
    // }
    // this.props.history.push("/investment_info_income");
  };
  toggle_ = (e) => {
    var x = e.currentTarget.parentNode.getElementsByClassName(
      "btn_popup_photo"
    );
    var i;
    for (i = 0; i < x.length; i++) {
      x[i].classList.remove("active");
    }
    e.currentTarget.classList.add("active");
  };
  popup = () => {
    this.setState({ status_popup: true });
  };
  signature = async (e) => {
    this.toggle_(e);
    this.fn_add_photo();
    //this.props.history.push('/sign_signature_horizonscreen');
  };

  async drawImg1() {
    const canvas = createCanvas(800, 508);
    // const canvas = document.getElementById("CursorLayer");
    const ctx = canvas.getContext("2d");
    const imgMark = new Image();
    imgMark.src = "/images/waterMark2.png?v=3";
    await loadImage(this.state.trimmedDataURL).then(async (image) => {
      await loadImage("/images/waterMark2.png?v=3");

      ctx.fillStyle = "#eeeeee";
      await ctx.fillRect(0, 0, 800, 508);

      await ctx.drawImage(image, 0, 0, 800, 508);
      if (image.width !== 800 || image.height !== 508) {
        await ctx.drawImage(imgMark, 0, 0, 800, 508);
      }
      $("#SignaturePhoto").html(
        "<img style='width:100%;border-radius:15px;display:block;' src='" +
          canvas.toDataURL() +
          "'/>"
      );
    });
    await localStorage.setItem("trimmedDataURL", canvas.toDataURL());
    await this.setState({ trimmedDataURL: canvas.toDataURL() });
    return;
  }

  image = (e) => {
    this.toggle_(e);
    this.fileuploadRef.current.click();
  };

  camera = (e) => {
    this.toggle_(e);
    this.setState({ showCameraPopup: true, status_popup: false });
  };
  handleSelectedFile = async (e) => {
    this.setState({ status_popup: false });

    const file = this.fileuploadRef.current.files[0];
    if (!file) {
      return;
    }
    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 10) {
      Swal.fire({
        type: "error",
        customClass: {
          confirmButton: "long_btn_fix",
          cancelButton: "long_btn_fix",
        },
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title:
          '<div class="title_popup">ไฟล์อัปโหลดมีขนาดเกินขนาดที่กำหนด</div>',
        html:
          '<div class="content_popup">ไฟล์ที่อัปโหลดจะต้องมีขนาดไม่เกิน 10 MB</div>',
      });
      return;
    }

    const reader = new FileReader();
    const vm = this;

    reader.addEventListener(
      "load",
      async function () {
        const imgSrc = reader.result;

        const canvas = createCanvas(670, 800);
        const ctx = canvas.getContext("2d");
        await loadImage(imgSrc).then(async (image) => {
          let maxWidth = 670;
          let maxHeight = 800;
          let width = image.width;
          let height = image.height;
          let ratio = 0;
          if (width > maxWidth) {
            ratio = maxWidth / width;
            width = Math.floor(maxWidth);
            height = Math.floor(height * ratio);
          }
          if (height > maxHeight) {
            ratio = maxHeight / height;
            width = Math.floor(width * ratio);
            height = Math.floor(maxHeight);
          }
          let x = (canvas.width - width) * 0.5;
          let y = (canvas.height - height) * 0.5;
          // console.log(width, height);
          await ctx.drawImage(image, x, y, width, height);
          await vm.setState({
            trimmedDataURL: canvas.toDataURL(),
          });
        });

        // localStorage.setItem('trimmedDataURL', imgSrc);
        // vm.setState({ trimmedDataURL: imgSrc });

        vm.drawImg1();
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  handleClickCloseTakePhotoPopup = (e) => {
    e.preventDefault();
    this.setState({ showCameraPopup: false });
  };

  checkScreen = () => {
    if (isMobile) {
      // console.log('checkScreen');
      if (window.orientation !== undefined) {
        let checkScreen = window.orientation !== 0 ? false : true;
        if (!checkScreen) {
          document.getElementById("contentText").style.display = "block";
          document.getElementById("contentPhoto").style.display = "none";
          document.getElementById("under_fix_btn").style.display = "none";
        } else {
          document.getElementById("contentText").style.display = "none";
          document.getElementById("contentPhoto").style.display = "flex";
          document.getElementById("under_fix_btn").style.display = "block";
        }
      }
    }
  };

  handleClickCapture = async () => {
    this.setState({ loading: true });
    const captureImage = this.webcamRef.current.getScreenshot();
    // const captureImage = this.webcamRef.current.getCanvas().toDataURL();
    const canvas = createCanvas(670, 800);
    const ctx = canvas.getContext("2d");
    await loadImage(captureImage).then(async (image) => {
      let maxWidth = 670;
      let maxHeight = 800;
      let width = image.width;
      let height = image.height;
      let ratio = maxWidth / width;
      let imgWidth = Math.floor(width * ratio);
      let imgHeight = Math.floor(height * ratio);
      let top = Math.floor(height / 2);

      let x = (canvas.width - imgWidth) * 0.5;
      let y = (canvas.height - imgHeight) * 0.5;
      await ctx.drawImage(image, x, y, imgWidth, imgHeight);

      // await localStorage.setItem('trimmedDataURL', canvas.toDataURL());
      await this.setState({
        trimmedDataURL: canvas.toDataURL(),
        showCameraPopup: false,
      });
    });

    await this.drawImg1();
    this.setState({ loading: false });
    // $('#SignaturePhoto').html(
    //   "<img style='width:100%; height: auto;' src='" + captureImage + "'/></div>"
    // );
  };

  render() {
    library.add(fas);
    const { showCameraPopup } = this.state;
    const trimmedDataURL = this.state.trimmedDataURL;

    window.addEventListener(
      "orientationchange",
      (event) => {
        // console.log('111111111111');
        this.checkScreen();
      },
      false
    );
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          style={{
                            display: checkEditPage() ? "none" : "block",
                            marginLeft: "3px",
                          }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">หลักฐานลายเซ็น</div>
                    </div>

                    <div className="bg_content_white" style={{ padding: 0 }}>
                      <div
                        className="in_bg_white photo"
                        style={{ top: 0, left: 0, right: 0 }}
                      >
                        <div
                          className="DivDetailTitleSnapId"
                          style={{ textAlign: "center", marginBottom: "20px" }}
                        >
                          {/* <span style={{ textDecoration: "Underline" }}>
                            หลักฐานลายเซ็น
                          </span>{" "}
                          * */}
                          <div className={"singned_title_1"}>
                            โปรดตรวจสอบความชัดเจนของภาพถ่ายหาก <br />
                            ไม่ชัดเจน กรุณาถ่ายใหม่อีกครั้ง
                          </div>
                          {/*<div className={'singned_title_2'}>
                            โปรดตรวจสอบความชัดเจน ของภาพถ่ายหากไม่ชัดเจน กรุณาถ่ายใหม่อีกครั้ง
                        </div>*/}
                        </div>

                        <div
                          className="btn_add_photo"
                          id="SignaturePhoto"
                          style={{ margin: "0 21px" }}
                          onClick={this.image}
                        >
                          <div className="main_border_singned">
                            <img
                              style={{ width: "50%" }}
                              src={"images/snapidcard_trans.png"}
                            />
                            <div className="singned_flex">
                              <img
                                src="/images/ico_image_add.png"
                                style={{ margin: "0 auto" }}
                              />
                              <span style={{ margin: "10px auto" }}>
                                เพิ่มลายเซ็น
                              </span>
                            </div>
                          </div>
                        </div>
                        <div style={{ height: "10px" }}>&nbsp;</div>
                      </div>
                      <div className="under_fix_btn">
                        {/* <ButtonCustom
                          handle={isMobile ? this.camera : this.image}
                          label={isMobile ? "ถ่ายภาพ" : "อัปโหลดภาพ"}
                          btnClass={`btn_small_left white active border_blue`}
                        ></ButtonCustom>
                        <ButtonCustom
                          // isDisabled={!trimmedDataURL}
                          handle={!trimmedDataURL ? null : this.handleSubmit}
                          label={checkEditPage() ? "บันทึกการแก้ไข" : "ถัดไป"}
                          btnClass={`btn_small_right ${!trimmedDataURL ? "gray" : "blue"
                            }`}
                        ></ButtonCustom> */}

                        <ButtonCustom
                          handle={this.handleSubmit}
                          label={checkEditPage() ? "บันทึกการแก้ไข" : "ถัดไป"}
                          color="blue"
                          btnClass="long_btn"
                        ></ButtonCustom>
                      </div>
                    </div>
                  </div>
                  <Popup
                    showSigned={false}
                    showTakePhoto={isMobile}
                    showUpload={!isMobile}
                    onClick={this.status_hide}
                    onClick_1={this.signature}
                    onClick_2={this.image}
                    onClick_3={this.camera}
                    status={this.state.status_popup}
                  ></Popup>

                  <input
                    type="file"
                    ref={this.fileuploadRef}
                    style={{ display: "none" }}
                    accept=".jpg, .png"
                    onChange={this.handleSelectedFile}
                  />

                  <div
                    className={`camera-popup ${showCameraPopup ? "show" : ""}`}
                  >
                    <div className="top_main_back">
                      <div
                        onClick={this.handleClickCloseTakePhotoPopup}
                        className="main_back"
                      >
                        <img
                          src="/images/angle-arrow-left.svg"
                          style={{ marginLeft: "15px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">ถ่ายรูปลายเซ็นของท่าน</div>
                    </div>
                    <div className="bg_content_blue">
                      <div className="in_bg_blue">
                        {/* <div className="text_top_video"></div> */}
                        <div
                          id="contentText"
                          style={{ display: "none" }}
                          className="text_top_video"
                        >
                          กรุณาหมุนอุปกรณ์ให้เป็นแนวตั้งเพื่อถ่ายภาพ
                        </div>
                        <div
                          id="contentPhoto"
                          className="content_photo"
                          style={{ maxHeight: "none" }}
                        >
                          {/* <img style={{ width: '99%', position: 'absolute' }} src={'/images/signed_frame.svg'} /> */}
                          {showCameraPopup && (
                            <Webcam
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: "#003b66",
                                top: "100px",
                              }}
                              audio={false}
                              ref={this.webcamRef}
                              screenshotFormat="image/png"
                              videoConstraints={videoConstraints}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="under_fix_btn" id="under_fix_btn">
                      <button
                        onClick={this.handleClickCapture}
                        className={"button-custom green long_btn_fix"}
                      >
                        ถ่ายภาพ
                      </button>
                    </div>
                  </div>
                  <Loading status={this.state.loading}></Loading>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default KtbstSignedAttatched;
