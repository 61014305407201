import React, { Component } from 'react';
import './Iframe.css'
import ButtonCustom from '../../components/button/ButtonCustom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
class Iframe extends Component {
    constructor(props) {
        super(props);
        this.state={
            
        }
        
    }
   
    goback = ()=>{
        this.props.history.push('/ats_choice');
    }
    handlesubmit =() =>{
        this.props.history.push('/ats_form');
    }
    render() {
        library.add(fas);
        return (
            <div>
            <div className='content'>
            <div className='top_main_back'>
            <div className='main_back' onClick={this.goback}>
                <img
                src='/images/angle-arrow-left.svg'
                style={{ marginLeft: '3px' }}
                className='arrow_back'
                />
            </div>
            <div className='text_title'>สมัครบริการหักบัญชีอัตโนมัติ</div>
            </div>
            
            <div className='bg_content_white over_f_hide'>
            <div className='in_bg_white default autoscroll' style={{bottom:0}}>
               
               
                <iframe src="https://nsips-test.scb.co.th/NSIPSWeb/DDRegisterRequestAction.do?profile_id=1000013890&terminal_id=315120436&command=DPDDRR&enctext=OUQ2QUU4OTdDMUI3ODI1Rjg0RENFODk3RTU3MUVCN0JBMkM5NDI3MjNFRDlGNDc5NzUxNzZDNzQ3MzYxQkRBM0VCNkIzMTBFMTJGRkE1NzY0QUU2ODY1RDBFNjE0MjY5MDMxMTE1QzIzNjM4OUYzQ0MwMUUxNkUyRjQxNzVEMkNBRTNFN0Y2ODdERUJCOUEzQUJEMDdCODUzMzQyNjM2MUNFQjhGMUZDQjQ3NDlDRjRCMzYyQ0NDODlDQzQ2RjEzREUyRkVENURDNDExQTBDMEZBMDNDODM4MDMxM0NCOUE2QkE5MUE3NjRCNDVDNEY3QkNGQUEzMjc0MzRCQUUwNSsxMTg2QzFGNDZFNDc4MzI0QThEOTZCQzEyRUM4MDUwRkMxQjgxNTRCRjE4NjJBRjZEQzBGNUNCNDhBRDNCMzJENzUwMzA4RTg4MDVDNDIwMzAzMENDODA1QjE3NjE2MjY2NkMyNjQ3RUE0MEQ2MTcwREM1RTlFQzcwQUIzMjEwMDRDMTZDRTRFRjBFQUM2N0E1Qzc0NEQwMTUzQzA3OTI2NEY3RkIyQjJDMTdFMDFDQTcyMjAwRDZEQzcxRkIxMzU4MzI0RjJBMjlGRkQ4RDI4QkNGMTlBRUQ1NTk3MjhFOEZCQjNFOTc0RjZBNUI5RjY2QTM3Qzc5NjFCOTBBODA4K0U3OUExNjAyQ0VERjIwNDBEMDZCMTE2QjAxNTU5QTMwOTRGNUVEMkM5OEFDQUExMEJBQjA1MUVEOTBGRTlDMkU0QjhDMjY5MDRBQ0Y5NTA1NDE4QUY1NDQxQkJENTNBRDY2NjY2NzFGOEJGQ0IwNDE3QTk2NTE4ODA5RDc4NTM5NDQyRjA2RjcyOEI2REU5MA==&backURL=http://localhost:3000#no-back-button" title="description" className='content_iframe'></iframe>
                
               
                
            </div>
        

                {/* <div className='under_fix_btn'>
                <ButtonCustom
                    handle={this.handlesubmit}
                    label={'ถัดไป'}
                    color={'blue'}
                    btnClass='long_btn'
                />
                </div> */}
            </div>
            </div>
        </div>
        );
    }
}

export default Iframe;