import React, { Component } from "react";
import "./ButtonCustom.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class ButtonCustom extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    const bank = this.props.label?.indexOf("ธนาคาร");
    library.add(fas);
    if (this.props.type === "submit") {
      return (
        <input
          type="submit"
          className={
            "button-custom " + this.props.color + " " + this.props.btnClass
          }
          value={this.props.label}
        />
      );
    }

    if (this.props.iconDirection === "L") {
      return (
        <div
          onClick={this.props.handle}
          disabled={this.props.isDisabled}
          className={
            "button-custom " + this.props.color + " " + this.props.btnClass
          }
          style={{ background: this.props.bg }}
        >
          <img
            alt="logo"
            src={this.props.src}
            style={{ display: this.props.src === undefined ? "none" : "block" }}
          />{" "}
          <FontAwesomeIcon icon={["fas", this.props.icon]} /> {this.props.label}
        </div>
      );
    } else if (this.props.iconDirection === "R") {
      return (
        <div
          onClick={this.props.handle}
          disabled={this.props.isDisabled}
          className={
            "button-custom " + this.props.color + " " + this.props.btnClass
          }
          style={{ background: this.props.bg }}
        >
          <img
            src={this.props.src}
            alt=""
            style={{ display: this.props.src === undefined ? "none" : "block" }}
          />{" "}
          {this.props.label} <FontAwesomeIcon icon={["fas", this.props.icon]} />
        </div>
      );
    } else {
      return (
        <div
          onClick={this.props.handle}
          disabled={this.props.isDisabled}
          className={
            "button-custom " + this.props.color + " " + this.props.btnClass
          }
          style={{
            background: this.props.bg,
          }}
        >
          <div
            className={"button-in"}
            style={{
              justifyContent: bank !== -1 ? "start" : "center",
            }}
          >
            <img
              alt=""
              src={this.props.src}
              style={{
                display: this.props.src === undefined ? "none" : "block",
              }}
            />
            {this.props.label}
          </div>
          <div
            style={{
              display:
                bank !== -1 && this.props.label === "ธนาคารกสิกรไทย"
                  ? "flex"
                  : "none",
              width: "100%",
              paddingLeft: 56,
              lineHeight: "16px",
              flexDirection: "column",
              alignItems: "flex-start",
              fontSize: "20px",
            }}
          >
            <span style={{ width: "100%" }}>ขั้นตอนผ่าน K PLUS</span>
            <span style={{ margin: "5px 0px", width: "100%" }}>
              1. เข้าแอปพลิเคชัน K PLUS
            </span>
            <span style={{ margin: "5px 0px", width: "100%" }}>
              2. กรุณาเปิดอนุญาตรายการแจ้งเตือน (notification) ก่อนทำรายการ
            </span>
            <span style={{ margin: "5px 0px", width: "100%" }}>
              3. กดปุ่ม ยินยอม ให้หักบัญชีอัตโนมัติ (ATS)
            </span>
            <span style={{ margin: "5px 0px 16px 0px", width: "100%" }}>
              4. สมัครบริการสำเร็จ
            </span>
          </div>
        </div>
      );
    }
  }
}

export default ButtonCustom;
