import ktbstApi from './../../api';
import to from 'await-to-js';
import Axios from 'axios';

function setThaiAddressText({
  houseNo,
  moo,
  building,
  floor,
  road,
  province,
  district,
  subDistrict,
  zipcode,
  country,
}) {
  const result = [];
  if (floor && floor !== '') {
    result.push(`ชั้นที่ ${floor}`);
  }
  if (building && building !== '') {
    result.push(`หมู่บ้าน/อาคาร ${building}`);
  }
  if (houseNo && houseNo !== '') {
    result.push(`เลขที่ ${houseNo}`);
  }
  if (moo && moo !== '') {
    result.push(`หมู่ ${moo}`);
  }
  if (road && road !== '') {
    result.push(`ถนน ${road}`);
  }
  if (subDistrict && subDistrict !== '') {
    result.push(`แขวง/ตำบล ${subDistrict}`);
  }

  if (district && district !== '') {
    result.push(`เขต/อำเภอ ${district}`);
  }

  if (province && province !== '') {
    result.push(`จังหวัด ${province}`);
  }

  if (zipcode && zipcode !== '' && String(zipcode).trim() !== '-') {
    result.push(`${zipcode}`);
  }

  if (country && country !== '') {
    result.push(`ประเทศ ${country}`);
  }

  return result.join(' ');
}

function setNonThaiAddressText(address, country) {
  return `${address} ${country}`;
}

async function getCountries() {
  const [error, result] = await to(ktbstApi.get('constant/country'));
  if (error) {
    console.log(error);
    return [];
  }

  return result.Data;
}

async function loadCountries() {
  const [error, result] = await to(
    Axios.get(`${window.location.origin}/json/address/response_country.json`)
  );

  if (error || !result || !result.data || !result.data.Data) {
    if (error) {
      return Promise.reject(error.message);
    } else {
      return Promise.reject('cannot_load_country_json_data');
    }
  }

  return Promise.resolve(result.data.Data);
}

async function loadProvinces() {
  const [error, result] = await to(
    Axios.get(`${window.location.origin}/json/address/response_province.json`)
  );

  if (error || !result || !result.data || !result.data.Data) {
    if (error) {
      return Promise.reject(error.message);
    } else {
      return Promise.reject('cannot_load_province_json_data');
    }
  }

  return Promise.resolve(result.data.Data);
}

async function loadDistricts() {
  const [error, result] = await to(
    Axios.get(`${window.location.origin}/json/address/response_district.json`)
  );

  if (error || !result || !result.data || !result.data.Data) {
    if (error) {
      return Promise.reject(error.message);
    } else {
      return Promise.reject('cannot_load_district_json_data');
    }
  }

  return Promise.resolve(result.data.Data);
}

async function loadSubDistricts() {
  const [error, result] = await to(
    Axios.get(
      `${window.location.origin}/json/address/response_subdistrict.json`
    )
  );

  if (error || !result || !result.data || !result.data.Data) {
    if (error) {
      return Promise.reject(error.message);
    } else {
      return Promise.reject('cannot_load_subdistrict_json_data');
    }
  }

  return Promise.resolve(result.data.Data);
}

async function loadPostCodes() {
  const [error, result] = await to(
    Axios.get(`${window.location.origin}/json/address/response_postcode.json`)
  );

  if (error || !result || !result.data || !result.data.Data) {
    if (error) {
      return Promise.reject(error.message);
    } else {
      return Promise.reject('cannot_load_subdistrict_json_data');
    }
  }

  return Promise.resolve(result.data.Data);
}

async function getProvinces() {
  const [error, result] = await to(ktbstApi.get('constant/province/212'));
  if (error) {
    console.log(error);
    return [];
  }

  return result.Data;
}

async function getDistricts(provinceID) {
  const [error, result] = await to(
    ktbstApi.get(`constant/district/${provinceID}`)
  );
  if (error) {
    console.log(error);
    return [];
  }

  return result.Data;
}

async function getSubDistricts(districtID) {
  const [error, result] = await to(
    ktbstApi.get(`constant/subdistrict/${districtID}`)
  );
  if (error) {
    console.log(error);
    return [];
  }

  return result.Data;
}

async function getPostcode(provinceID, districtID, subDistrictID) {
  if (!provinceID || !districtID || !subDistrictID) {
    return '';
  }

  const [error, result] = await to(
    ktbstApi.get(
      `constant/postcode/212/${provinceID}/${districtID}/${subDistrictID}`
    )
  );
  if (error) {
    console.log(error);
    return '';
  }

  if (result.Data.length === 0) {
    return '';
  }

  return result.Data[0].PostCode;
}

function findCountryByID(countries, countryID) {
  if (!countries || !countryID) {
    return null;
  }

  return countries
    ? countries.find((country) => country.Id === countryID)
    : null;
}

function findCountryByCode(countries, countryCode) {
  if (!countries || !countryCode) {
    return null;
  }

  return countries
    ? countries.find((country) =>
        country.Code.toLowerCase().startsWith(String(countryCode).toLowerCase())
      )
    : null;
}

function findCountryByName(countries, countryName) {
  if (!countries || !countryName) {
    return null;
  }

  return countries
    ? countries.find((country) => country.ThName.startsWith(countryName))
    : null;
}

function findProvinceByID(provinces, provinceID) {
  if (!provinces || !provinceID) {
    return null;
  }

  return provinces
    ? provinces.find((province) => province.Id === provinceID)
    : null;
}

function findProvinceByName(provinces, provinceName) {
  if (!provinces || !provinceName) {
    return null;
  }

  if (
    provinceName.startsWith('กรุงเทพ') ||
    provinceName.startsWith('กทม') ||
    provinceName.startsWith('ก.ท.ม')
  ) {
    return provinces.find((province) =>
      province.ThName.startsWith('กรุงเทพมหานคร')
    );
  }

  return provinces
    ? provinces.find((province) => province.ThName.startsWith(provinceName))
    : null;
}

function findDistrictByID(districts, districtID) {
  if (!districts || !districtID) {
    return null;
  }

  return districts
    ? districts.find((district) => district.Id === districtID)
    : null;
}

function findDistrictByName(districts, districtName, provinceID) {
  if (!districts || !districtName || !provinceID) {
    return null;
  }

  return districts
    ? districts.find(
        (district) =>
          district.pvn_Id === String(provinceID) &&
          district.ThName.startsWith(districtName)
      )
    : null;
}

function findSubDistrictByID(subDistricts, subDistrictID) {
  if (!subDistricts || !subDistrictID) {
    return null;
  }

  return subDistricts
    ? subDistricts.find((subDistrict) => subDistrict.Id === subDistrictID)
    : null;
}

function findSubDistrictByName(subDistricts, subDistrictName, districtID) {
  if (!subDistricts || !subDistrictName || !districtID) {
    return null;
  }

  return subDistricts
    ? subDistricts.find(
        (subDistrict) =>
          subDistrict.dst_Id === String(districtID) &&
          subDistrict.ThName.startsWith(subDistrictName)
      )
    : null;
}

function findPostcode(postcodes, { provinceID, districtID, subdistrictID }) {
  if (!provinceID || !districtID || !subdistrictID) {
    return null;
  }

  const data = postcodes.find(
    (data) =>
      String(data.cnt_Id) === '212' &&
      String(data.pvn_Id) === provinceID &&
      String(data.dst_Id) === districtID &&
      String(data.sdst_Id) === subdistrictID
  );

  return data ? data.PostCode : '';
}

export {
  setThaiAddressText,
  setNonThaiAddressText,
  loadCountries,
  loadProvinces,
  loadDistricts,
  loadSubDistricts,
  loadPostCodes,
  getCountries,
  getProvinces,
  getDistricts,
  getSubDistricts,
  getPostcode,
  findCountryByID,
  findCountryByCode,
  findCountryByName,
  findProvinceByID,
  findProvinceByName,
  findDistrictByID,
  findDistrictByName,
  findSubDistrictByID,
  findSubDistrictByName,
  findPostcode,
};
