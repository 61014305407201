// import React, { Component } from 'react';
// import ButtonCustom from '../../components/button/ButtonCustom';
// import './SnapFrontId.css';
// import Camera, {FACING_MODES} from 'react-html5-camera-photo';
// import 'react-html5-camera-photo/build/css/index.css';
// import $ from 'jquery';
// class SnapFrontId extends Component {
//   constructor(props) {
//     super(props);
//     this.goBack = this.goBack.bind(this);
//   }

//   goBack() {
//     this.props.history.goBack();
//   }
//   render() {
//     function handleTakePhoto(dataUri) {
//       // Do stuff with the photo...
//       console.log('takePhoto');
//       localStorage.setItem('photo1', dataUri);
//       window.location = '/snapidcard';
//     }
//     return (
//       <div className='PageSnapFront'>
//         <div
//           onClick={this.goBack}
//           className="main_back"
//         >
//           <img
//             src='/images/angle-arrow-left.svg'
//             style={{ marginLeft: '15px' }}
//             className='arrow_back'
//           />
//         </div>

//         <div
//           style={{
//             textAlign: 'center',
//             color: '#ffffff',
//             position: 'relative',
//             top: '110px',
//             fontSize: '24px',
//             fontWeight: 'bolder',
//           }}
//         >
//           ถ่ายรูปบัตรประจำตัวประชาชน{' '}
//           <span style={{ textDecoration: 'underline' }}>ด้านหน้า</span>
//         </div>
//         <Camera
//           style={{
//             width: '100%',
//             height: '250px',
//             backgroundColor: '#000000',
//             position: 'relative',
//             top: '140px',
//           }}
//           idealFacingMode = {FACING_MODES.ENVIRONMENT}
//           onTakePhoto={(dataUri) => {
//             handleTakePhoto(dataUri);
//           }}
//         />

//         <div
//           style={{
//             textAlign: 'center',
//             color: '#ffffff',
//             position: 'relative',
//             fontSize: '24px',
//             fontWeight: 'bolder',
//           }}
//         >
//           กรุณาวางบัตรประชาชนให้อยู่ในกรอบที่กำหนด
//         </div>

//         <ButtonCustom
//           label='ถ่ายภาพ'
//           color='green'
//           btnClass='BtnSanpFrontId'
//         ></ButtonCustom>
//       </div>
//     );
//   }
// }

import React, { useRef, useHistory } from 'react';
import ReactDOM from 'react-dom';
import Webcam from 'react-webcam';
import './SnapFrontId.css';
import { createCanvas, loadImage } from 'canvas';
// import { checkEditPage } from '../../components/helpers/Helpers';
import { isMobile } from 'react-device-detect';

const videoConstraints = {
  // width: 400,
  // height: 700,
  facingMode: 'environment',
};

function goBack() {
  var link = window.location.href.indexOf('edit');
  if (link === -1) {
    window.location = '/snapidcard';
  } else {
    // window.location = '/summaryshow';
    window.location = '/snapidcard/edit';
  }
}

function checkScreen() {
  if (isMobile) {
    let Width = window.innerWidth;
    let Height = window.innerHeight;

    // var orientation = window.screen.orientation.angle;
    if (window.orientation !== undefined) {
      let checkScreen = window.orientation !== 0 ? false : true;
      if (!checkScreen) {
        document.getElementById('contentText').style.display = 'block';
        document.getElementById('contentPhoto').style.display = 'none';
        document.getElementById('under_fix_btn').style.display = 'none';
      } else {
        document.getElementById('contentText').style.display = 'none';
        document.getElementById('contentPhoto').style.display = 'flex';
        document.getElementById('under_fix_btn').style.display = 'block';
      }
    }

    // var orientation = window.screen;
    // console.log(orientation);
    // console.log(window.orientation);
  }
}

const SnapFrontId = () => {
  const webcamRef = useRef(null);
  var link = window.location.href.indexOf('edit');

  window.addEventListener(
    'orientationchange',
    (event) => {
      checkScreen();
    },
    false
  );
  window.addEventListener('load', (event) => {
    checkScreen();
  });

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const canvas = createCanvas(800, 509);
    const ctx = canvas.getContext('2d');

    loadImage(imageSrc).then(async (image) => {
      let maxWidth = 800;
      let maxHeight = 509;
      let width = image.width;
      let height = image.height;
      let ratio = maxWidth / width;
      let imgWidth = Math.floor(width * ratio);
      let imgHeight = Math.floor(height * ratio);
      let top = Math.floor(height / 2);

      let x = (canvas.width - imgWidth) * 0.5;
      let y = (canvas.height - imgHeight) * 0.5;
      await ctx.drawImage(image, x, y, imgWidth, imgHeight);
      await localStorage.setItem('photo1', canvas.toDataURL());

      if (link === -1) {
        window.location = '/snapidcard';
      } else {
        // window.location = '/summaryshow';
        window.location = '/snapidcard/edit';
      }
    });
  }, [webcamRef]);

  return (
    <div>
      <div className="content">
        <div className="top_main_back">
          <div onClick={goBack} className="main_back">
            <img src="/images/angle-arrow-left.svg" alt="arrow_back" className="arrow_back" />
          </div>
        </div>
        <div className="bg_content_blue">
          <div className="in_bg_blue">
            <div id="contentText" style={{ display: 'none' }} className="text_top_video">
              กรุณาหมุนอุปกรณ์ให้เป็นแนวตั้งเพื่อถ่ายภาพ
            </div>

            <div id="contentPhoto" className="content_photo" style={{ maxHeight: 'none' }}>
              <Webcam
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: '#003b66',
                  top: '140px',
                }}
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/png"
                videoConstraints={videoConstraints}
              />
              <img className="border_photo" alt="card_front" src={'/images/card_front.png'} />
            </div>

            <div className="text_under_video">{/* "กรุณาวางบัตรประชาชนให้อยู่ในกรอบที่กำหนด" */}&nbsp;</div>
          </div>
        </div>
        <div id="under_fix_btn" className="under_fix_btn">
          <button onClick={capture} className={'button-custom white long_btn_fix'}>
            ถ่ายภาพ
          </button>
        </div>
      </div>
    </div>
  );
};

export default SnapFrontId;
