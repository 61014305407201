import React, { Component } from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.min';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SlideItem from './SlideItem';
import "./Slideshow.css";
// import ButtonCustom from '../button/ButtonCustom';
class Slideshow extends Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        const {ArrsLide} = this.props;
        return (
            <div className="slide-container">
                <Slider {...settings}>
                    {ArrsLide.map((key,i) => {
                        return (
                        <SlideItem 
                            key={i}
                            picture={key.picture}
                            title={key.title} 
                            content={key.content}
                            button={key.button}
                            icon={key.icon}
                        >
                        </SlideItem>
                        );
                    })}
                </Slider>
                
          </div> 
        );
    }
}

export default Slideshow;