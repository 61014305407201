/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import "./NdidOfflineList.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import qs from "qs";
import axios from "axios";
import Loading from "../../components/loading/loading";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { config } from "./../../constants";
import { popupErrorApi } from "../../components/helpers/Helpers";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
import _ from "lodash";
class NdidOfflineList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bank: [],
      Bank: [],
      bank_list: [],
      BankID: "",
      countCancel: 0,
      loading: true,
      isDisconnected: false,
    };
    localStorage.removeItem("BankID");
    localStorage.removeItem("ReferenceId");
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.push("/ndid_tc");
  }

  click_bank = (e) => {
    e.preventDefault();
    const BankID = e.currentTarget.dataset.id;
    this.setState({ BankID: BankID });
    localStorage.setItem("BankID", BankID);
    if (BankID !== "") {
      this.props.history.push("/ndid_choice/" + BankID);
    }
  };

  sendData = (e) => {
    e.preventDefault();
    const BankID = this.state.BankID;
    if (BankID !== "") {
      this.props.history.push("/ndid_choice/" + BankID);
    }
  };

  componentDidMount() {
    this.setState({ loading: true });
    const Identifier = localStorage.getItem("identifier");
    const countCancel = localStorage.getItem("countCancel") || 0;
    this.setState({ countCancel: parseInt(countCancel) });
    axios
      .post(`${config.baseAPIURL06}/ndid/ListIdentityProviders`, {
        Identifier: Identifier,
      })
      .then((res) => {
        const ListIdPs = res.data.data[0].ListIdPs;
        this.setState({
          bank_list: ListIdPs,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        var mss = "";
        var title_mss = "";
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss =
            error.response.data.Data !== undefined &&
            error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data !== undefined &&
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
        popupErrorApi(mss, title_mss, true);
      });
  }

  handleChange = async (e) => {
    e.preventDefault();
    const vm = this;
    if (e.target.name === "BankID") {
      await vm.setState({ BankID: e.target.value });
      let BankName = await _.filter(vm.state.bank_list, function (o) {
        return o.id === vm.state.BankID && o.display_name_th;
      });
      await localStorage.setItem("BankName", BankName[0].display_name_th);
      await localStorage.setItem("BankID", vm.state.BankID);
    }
  };

  render() {
    library.add(fas);
    const bank_list = this.state.bank_list.map((item, i) => {
      return (
        <option value={item.id} key={i} style={{ color: "#003b66" }}>
          {item.display_name_th}
        </option>
      );
    });
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <Loading status={this.state.loading} />
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">
                        พิสูจน์การยืนยันตัวตนทางดิจิทัล (NDID)
                      </div>
                    </div>

                    <div
                      className="bg_content_white over_f_hide"
                      style={{ padding: "0" }}
                    >
                      <div
                        className="in_bg_white autoscroll"
                        style={{ top: 0, left: 0, right: 0 }}
                      >
                        <div className="text_top_ndid">
                          กรุณาเลือกผู้ให้บริการยืนยันตัวตน เช่น ธนาคาร
                          ที่ท่านเคยถ่ายภาพใบหน้าเพื่อยืนยันตัวตน
                          และมีโมบายแอปพลิเคชันของธนาคารดังกล่าว
                          หรือสมัครใช้บริการ NDID แล้ว
                        </div>
                        <div
                          style={{
                            color: "#000",
                            fontSize: 24,
                            fontWeight: "bold",
                            padding: "21px",
                            color: "#003b66",
                          }}
                        >
                          โปรดเลือกผู้ให้บริการ NDID
                          เพื่อดำเนินการพิสูจน์การยืนยันตัวตน
                        </div>
                        <div
                          className="row_idcard active"
                          style={{ margin: "0 24px", paddingTop: 0 }}
                        >
                          <div className="label_idcard">ผู้ให้บริการ *</div>
                          <div className="main_select">
                            <select
                              className="select_style"
                              style={{
                                backgroundColor: "#fff",
                                color:
                                  this.state.BankID != ""
                                    ? "#003b66"
                                    : "#003b6694",
                                borderColor:
                                  this.state.BankID != "" ? "#003b66" : "#ccc",
                              }}
                              name="BankID"
                              value={this.state.BankID}
                              onChange={this.handleChange}
                              required
                            >
                              <option
                                value=""
                                style={{ color: "#003b6694" }}
                                disabled
                                hidden
                              >
                                --เลือกผู้ให้บริการ--
                              </option>
                              {bank_list}
                            </select>
                            <FontAwesomeIcon icon="angle-down" />
                          </div>
                        </div>
                      </div>

                      <div className="under_fix_btn">
                        <ButtonCustom
                          handle={
                            this.state.BankID === "" ? null : this.sendData
                          }
                          label="ถัดไป"
                          color={this.state.BankID === "" ? "gray" : "blue"}
                          btnClass="long_btn"
                        />
                      </div>

                      {/* <div
              onClick={this.click_bank}
              data-id='D1F1532B-19AF-4A88-86AC-79693EC158C1'
              style={{ display: 'block' }}
            >
              <ButtonCustom
                label='ทดสอบ 1'
                btnClass='btn_default'
                iconDirection='R'
                src='/images/scb.png'
                bg='#4e3086'
                icon='angle-double-right'
              />
            </div>

            <div
              onClick={this.click_bank}
              data-id='0F5A378A-FF19-4C00-A549-EA208A1C120A'
              style={{ display: 'block' }}
            >
              <ButtonCustom
                label='ธนาคารไทยพาณิชย์'
                icon='angle-double-right'
                iconDirection='R'
                btnClass='btn_scb btn_default'
              />
            </div>
            <div
              onClick={this.click_bank}
              data-id='E8AF34C5-A948-4F5C-AD71-998D097FFAAD'
              style={{ display: 'block' }}
            >
              <ButtonCustom
                label='ธนาคารกสิกร'
                icon='angle-double-right'
                iconDirection='R'
                btnClass='btn_kbank btn_default'
              />
            </div>
            <div
              onClick={this.click_bank}
              data-id='41D9EF13-115D-47A2-81AA-E1DE1FFD654D'
              style={{ display: 'block' }}
            >
              <ButtonCustom
                label='ธนาคารกรุงเทพ'
                icon='angle-double-right'
                iconDirection='R'
                btnClass='btn_bbl btn_default'
              />
            </div>
            <div
              onClick={this.click_bank}
              data-id='B6586FFF-1D30-4C50-AA9C-D1C7565C2B5A'
              style={{ display: 'block' }}
            >
              <ButtonCustom
                label='ธนาคารกรุงศรี'
                icon='angle-double-right'
                iconDirection='R'
                btnClass='btn_bay btn_default'
              />
            </div> */}
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default NdidOfflineList;
