import React, { Component } from 'react';
import './KtbstNdidIdpRepons.css';
import ButtonCustom from '../../components/button/ButtonCustom';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class KtbstNdidIdpRepons extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      percent: 0,
      btnShow: false,
    };
    this.intervalId = setInterval(() => {
      if (this.state.percent > 90) {
        // load 100%
        // console.log(this.state.percent)

        this.setState({ btnShow: (this.state.btnShow = true) });
      }
      this.Tick();
    }, 1000);
  }
  Tick() {
    if (this.state.percent > 90) {
      return clearInterval(this.intervalId);
    }
    this.setState({ percent: this.state.percent + 10 });
  }
  successLoad() {
    clearInterval(this.intervalId);
  }
  goBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <div>
        <div className="content">
          <div className="top_main_back">
            <div className="main_back" onClick={this.goBack}>
              <img
                src="/images/angle-arrow-left.svg"
                alt="forward"
                style={{ marginLeft: '3px' }}
                className="arrow_back"
              />
            </div>
            <div className="text_title">ข้อมูลการลงทุน</div>
          </div>

          <div className="bg_content_white over_f_hide">
            <div className="in_bg_white flex autoscroll" style={{ top: 0 }}>
              <img
                className="success_img"
                style={{ display: this.state.btnShow ? 'block' : 'none', marginTop: 32, width: '64%' }}
                src="/images/ico_bankcircle.svg"
              ></img>

              <div className="main_circle" style={{ display: this.state.btnShow ? 'none' : 'block' }}>
                <CircularProgressbarWithChildren
                  value={this.state.percent}
                  text={``}
                  styles={{
                    // Customize the root svg element
                    root: {},
                    // Customize the path, i.e. the "completed progress"
                    path: {
                      // Path color
                      stroke: `rgba(0, 59, 102,1)`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: 'round',
                      // Customize transition animation
                      transition: 'stroke-dashoffset 0.5s ease 0s',
                      // Rotate the path
                      transform: 'rotate(0.0turn)',
                      transformOrigin: 'center center',
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                      // Trail color
                      stroke: '#4a7392',
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: 'butt',
                      // Rotate the trail
                      transform: 'rotate(0.25turn)',
                      transformOrigin: 'center center',
                    },
                    // Customize the text
                    text: {
                      // Text color
                      fill: '#f88',
                      // Text size
                      fontSize: '16px',
                    },
                    // Customize background - only used when the `background` prop is true
                    background: {
                      fill: '#3e98c7',
                    },
                  }}
                >
                  <img src="/images/ico_bankcircle.svg"></img>
                </CircularProgressbarWithChildren>
              </div>
              <div className="title_under_circle" style={{ color: this.state.btnShow ? '#a6ce4e' : '' }}>
                {' '}
                {this.state.btnShow ? 'เชื่อมต่อกับธนาคาร ผู้ให้บริการสำเร็จ' : 'กำลังประมวลผลกับ ธนาคารผู้ให้บริการ'}
              </div>
              <div className="main_circle_detail_idp">
                <div className="circle_detail_idp_row">
                  <div className="title_idp">NDID Status</div>
                  <div className="detail_idp">Pending</div>
                </div>
                <div className="circle_detail_idp_row">
                  <div className="title_idp">NDID Request ID</div>
                  <div className="detail_idp">86e27546e0c4b77ca66a59c7229be0f6ddb90cf67ee821ca15c90afaf97759b1</div>
                </div>
                <div className="circle_detail_idp_row">
                  <div className="title_idp">Reference ID</div>
                  <div className="detail_idp">b7f33f38-4c00-47d2-ae40-fe823f8e23cf</div>
                </div>
                <div className="circle_detail_idp_row">
                  <div className="title_idp">Request Message</div>
                  <div className="detail_idp">951cb761acad4e3bb3237a19a915b5a6</div>
                </div>
              </div>
            </div>
            <div className="under_fix_btn">
              <ButtonCustom
                label={this.state.btnShow ? 'ทำรายการต่อ' : 'ยกเลิกการเชื่อมต่อ'}
                color={this.state.btnShow ? 'blue' : 'red'}
                btnClass={this.state.btnShow ? 'btn_investment' : 'long_btn'}
                iconDirection={this.state.btnShow ? 'R' : ''}
                icon={this.state.btnShow ? 'angle-right' : ''}
              ></ButtonCustom>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default KtbstNdidIdpRepons;
