import React, { useEffect, useState } from "react";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
import Loading from "../../components/loading/loading";
import { isMobile } from "react-device-detect";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "./Product_choice.css";
import axios from "axios";
import { config } from "./../../constants";
import ButtonCustom from "../../components/button/ButtonCustom";
import { popupError } from "../../components/helpers/Helpers";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert2";
const _url = config.baseAPIURL03;
const SUCCESS = 200;
const Product_choice = (props) => {
  const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
  const [isLoading, setIsLoading] = useState(false);
  const [dataProduct, setDataProduct] = useState([]);
  const [productJson, setProductJson] = useState([]);
  const [dataCard, setDataCard] = useState([]);
  const goBack = () => {
    props.history.push("/crs_consent");
  };
  var str = window.location.href;
  let re = /edit/;
  const back_to_edit = re.test(str);
  const addProductJson = (id, selectedId, name) => {
    let dataJson = [...productJson];

    if (dataJson.find((json) => json.id === id)) {
      let dataJs = [...dataJson];
      dataJson = dataJs.filter((e) => e.id !== id);
      if (name === "Cash") {
        dataJs = [...dataJson];
        dataCard.forEach((json) => {
          if (json.name === "Credit Balance") {
            dataJson = dataJs.filter((e) => e.id !== json.id);
          }
        });
      }
    } else {
      if (name === "Credit Balance") {
        dataCard.forEach((json) => {
          if (json.name === "Cash") {
            if (!dataJson.find((e) => e.id === json.id)) {
              dataJson.push({ id: json.id, selected_id: json.selected_id });
            }
          }
        });
      }
      dataJson.push({ id: id, selected_id: selectedId });
    }
    setProductJson(dataJson);
  };
  const handleNext = () => {
    if (productJson.length > 0) {
      setIsLoading(true);
      const body = {
        head_token: HeaderManp.head_token,
        product_json: productJson,
      };
      try {
        axios
          .post(`${config.baseAPIURL03}/form/product/manp`, body)
          .then((res) => {
            if (res.request.status === SUCCESS) {
              if (res?.data?.Data?.[0]?.[0]?.IsSuccess === 1) {
                if (back_to_edit) {
                  props.history.push("/summaryshow");
                } else {
                  comBack();
                }
              } else {
                popupError(
                  res?.data?.Data?.[0]?.[0]?.MsgDesc,
                  res?.data?.Data?.[0]?.[0]?.MsgDescTitle
                );
              }
            }
            setIsLoading(false);
          })
          .catch((e) => {
            if (!e.response) {
              popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
            } else {
              popupError(
                e.response.data?.Data?.[0]?.[0]?.MsgDesc,
                e.response.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
              );
            }
            setIsLoading(false);
          });
      } catch (e) {
        if (!e.response) {
          popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
        } else {
          popupError(
            e.response.data?.Data?.[0]?.[0]?.MsgDesc,
            e.response.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
          );
        }
        setIsLoading(false);
      }
    } else {
      const MySwal = withReactContent(swal);
      MySwal.fire({
        customClass: {
          confirmButton: "long_btn_fix",
          cancelButton: "long_btn_fix",
        },
        allowOutsideClick: false,
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title: '<div class="title_popup">กรุณาเลือกประเภทบัญชี</div>',
        html: '<div class="content_popup">กรุณาเลือกประเภทบัญชี</div>',
      }).then(function () {
        // window.location = "summaryshow";
      });
    }
  };
  const allStorage = () => {
    let values = [],
      keys = Object.keys(localStorage),
      i = keys.length;
    while (i--) {
      if (keys[i].indexOf("debug") !== -1) {
      } else {
        values.push({
          key: keys[i],
          value:
            keys[i] === "currentStep"
              ? "tc_ndid"
              : localStorage.getItem(keys[i]),
        });
      }
    }
    return values;
  };
  const comBack = async () => {
    setIsLoading(true);
    const head_token = HeaderManp.head_token;
    var last_stamp = JSON.stringify(allStorage());
    var title_mss = "";
    var mss = "";
    const ops = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
      },
      data: {
        last_stamp: last_stamp,
        head_token: head_token,
        stamp_page: "NDID",
      },
      url: _url + "/form/comeback/manp",
    };
    localStorage.setItem("currentStep", "product_choice");
    await axios(ops)
      .then(async (res) => {
        setIsLoading(false);
        let keys = Object.keys(localStorage),
          i = keys.length;
        while (i--) {
          // console.log(i, keys[i]);
        }
        props.history.push("/ndid_info");
      })
      .catch((error) => {
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss =
            error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
        popupError(mss, title_mss);
        setIsLoading(false);
        return;
      });
  };
  useEffect(() => {
    let dataJson = [...productJson];
    let dataCard = [];
    dataProduct.forEach((product) => {
      product.items.forEach((item) => {
        if (item.name === "Cash" || item.name === "Credit Balance") {
          dataCard.push({
            name: item.name,
            id: item.id,
            selected_id: item.selected_id,
          });
        }
        if (item.selected === 1 && item.active === 1) {
          dataJson.push({ id: item.id, selected_id: item.selected_id });
        }
      });
    });
    setDataCard(dataCard);
    setProductJson(dataJson);
  }, [dataProduct]);
  useEffect(() => {
    setIsLoading(true);
    localStorage.setItem("back_from_productchoice", true);
    try {
      axios
        .get(
          `${config.baseAPIURL03}/form/product/selbyhead?head_token=${HeaderManp.head_token}`
        )
        .then((res) => {
          if (res.request.status !== SUCCESS) {
            throw new Error("error");
          }
          setDataProduct(res?.data?.Data || []);
          setIsLoading(false);
        })
        .catch((e) => {
          if (!e.response) {
            popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
          } else {
            popupError(
              e.response.data?.Data?.[0]?.[0]?.MsgDesc,
              e.response.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
            );
          }
          setIsLoading(false);
        });
    } catch (e) {
      if (!e.response) {
        popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
      } else {
        popupError(
          e.response.data?.Data?.[0]?.[0]?.MsgDesc,
          e.response.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
        );
      }
      setIsLoading(false);
    }
  }, []);
  return (
    <div className={isMobile ? "" : "body"}>
      <header
        className={isMobile ? "" : "header-area"}
        style={{ display: isMobile ? "none" : "block" }}
      >
        <div className={"container everest-nav-container"}>
          <div className={"row"}>
            <div className={"col-md-6"}>
              <img src="../../../images/logo.png" />
            </div>
            <div className={"col-md-6 flex_end"}>
              <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
            </div>
          </div>
        </div>
      </header>
      <section
        className={isMobile ? "" : "desktop_content section-gap-full"}
        style={{
          backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
        }}
      >
        <div className={isMobile ? "" : "container"}>
          <div className={isMobile ? "" : "row"}>
            <div
              className={"col-md-6"}
              style={{ display: isMobile ? "none" : "block" }}
            >
              <BannerContentLeft></BannerContentLeft>
            </div>
            <div className={isMobile ? "" : "col-md-6"}>
              {/* content mobile */}
              <div>
                <Loading status={isLoading} />
                <div className="content">
                  <div className="top_main_back">
                    <div
                      className="main_back"
                      onClick={!back_to_edit && goBack}
                    >
                      <img
                        src={
                          isMobile
                            ? "/images/angle-arrow-left.svg"
                            : "/images/angle-arrow-left-blue.svg"
                        }
                        style={{
                          marginLeft: "3px",
                          display: back_to_edit ? "none" : "",
                        }}
                        className="arrow_back"
                      />
                    </div>
                    <div className="text_title">เลือกประเภทบัญชี</div>
                  </div>

                  <div className="bg_content_white">
                    <Accordion allowZeroExpanded={true} expanded="true">
                      {dataProduct?.map((product, index) => (
                        <AccordionItem key={index} className="accordionBg">
                          <AccordionItemHeading>
                            <AccordionItemButton
                              style={{
                                color: "#333333",
                                fontSize: "21px",
                                fontWeight: "bold",
                              }}
                            >
                              {product?.group ?? ""}
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="accordionDe">
                            <div>
                              {product?.items?.map((item, i) => (
                                <div key={i}>
                                  <label className="containerPro">
                                    <input
                                      type="checkbox"
                                      checked={
                                        productJson.find(
                                          (e) => e.id === item.id
                                        )
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        addProductJson(
                                          item.id,
                                          item.selected_id,
                                          item.name,
                                          e.target.checked
                                        )
                                      }
                                      disabled={
                                        item.active === 1 ? false : true
                                      }
                                    />
                                    <span className="text_checkmarkPro">
                                      {item?.name ?? ""}
                                    </span>
                                    <span
                                      className={
                                        item.active === 0
                                          ? "disabledPro"
                                          : "checkmarkPro"
                                      }
                                    ></span>
                                  </label>
                                </div>
                              ))}
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                    <ButtonCustom
                      handle={handleNext}
                      label="ถัดไป"
                      color="blue"
                      btnClass="long_btn"
                    />
                  </div>
                </div>
              </div>
              {/* end content mobile */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Product_choice;
