/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { Grid, RadioGroup, FormControl } from "@mui/material";
import _ from "lodash";
import "./KtbstNDID.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import axios from "axios";
import Loading from "../../components/loading/loading";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { config } from "./../../constants";
import { popupErrorApi } from "../../components/helpers/Helpers";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
import { CardList, Wrapper } from "../../components/NDID2";
import { BANK_LIST } from "../../components/NDID2/MockData";

class KtbstNDID extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bank: [],
      Bank: [],
      bank_list: [],
      BankID: "",
      loading: true,
      isDisconnected: false,
      value: undefined,
    };
    localStorage.removeItem("BankID");
    localStorage.removeItem("ReferenceId");
    // localStorage.removeItem('RetrieveData');
    // localStorage.removeItem('HeaderManp');
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.push("/tc_ndid");
  }

  click_bank = (e) => {
    e.preventDefault();
    const BankID = e.currentTarget.dataset.id;
    this.setState({ BankID: BankID });
    localStorage.setItem("BankID", BankID);
    if (BankID !== "") {
      this.props.history.push("/ndid_choice/" + BankID);
    }
  };

  componentDidMount() {
    this.setState({ loading: true });
    localStorage.removeItem("BankName");
    // const Identifier = localStorage.getItem("IdCard");
    const f0 = JSON.parse(localStorage.getItem("f0"));
    const passNDID = JSON.parse(localStorage.getItem("passNDID"));
    if (passNDID) {
      this.props.history.push("/idc_address_choose");
    } else {
      axios
        .get(
          `${config.baseAPIURL03}/form/ndid2/listall?identifier=${f0.f0_national_id}`
        )
        .then((res) => {
          const ListIdPs = this.setSort(res.data.data);
          this.setState({
            bank_list: ListIdPs,
            loading: false,
          });

          // check empty data
          if(res.data.data?.enroll?.length === 0 && res.data.data?.unenroll?.length === 0){
            this.setState({ loading: false });
            popupErrorApi("ไม่สามารถทำรายการได้ในขณะนี้<br/>กรุณาทำรายการใหม่ สอบถามข้อมูลเพิ่มเติม<br/>กรุณาติดต่อ DAOL Contact Center<br/>0 2351 1800","เกิดข้อผิดพลาด", true,null,"ตกลง");           
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          var mss = "";
          var title_mss = "";
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            title_mss =
              error.response.data.Data !== undefined &&
              error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              error.response.data.Data !== undefined &&
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
          popupErrorApi(mss, title_mss, true);
        });
    }
  }

  handleChange = async (e) => {
    e.preventDefault();
    const vm = this;
    if (e.target.name === "BankID") {
      await vm.setState({ BankID: e.target.value });
      let BankName = await _.filter(vm.state.bank_list, function (o) {
        return o.id === vm.state.BankID && o.display_name_th;
      });
      await localStorage.setItem("BankName", BankName[0].display_name_th);
      await localStorage.setItem("BankID", vm.state.BankID);
    }
  };

  sendData = async () => {
    const { bank_list, value } = this.state;
    const { enroll, unenroll } = bank_list;
    const allBank = [...enroll, ...unenroll];
    const position = _.findKey([...enroll, ...unenroll], {
      value: value,
    });
    const current = allBank[position];

    await localStorage.setItem("BankName", current?.appName);
    await localStorage.setItem("BankImage", current?.logo);
    await localStorage.setItem("BankID", value);

    if (value !== "" && value !== undefined) {
      this.props.history.push("/ndid_choice/" + value);
    }
  };

  setSort = (data) => {
    const { enroll, unenroll } = data;
    const transformWithKey = this.transformBankData("display_name_th");
    return {
      enroll: transformWithKey(enroll),
      unenroll: transformWithKey(unenroll),
    };
  };

  transformBankData = (key) => (banks) => {
    const sortedData = _.sortBy(banks, key);
    return sortedData.map((bank) => ({
      value: bank.id,
      logo: bank.banking_application,
      name: bank[key],
      appName: bank.app_name,
    }));
  };

  render() {
    library.add(fas);
    const isThereEnroll = this.state.bank_list?.enroll?.length > 0;
    const isThereUnEnroll = this.state.bank_list?.unenroll?.length > 0;


    /*
    isThereEnroll === false && isThereUnEnroll === false 
    will alert error
    */

    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <Loading status={this.state.loading} />
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">
                        พิสูจน์การยืนยันตัวตนทางดิจิทัล (NDID)
                      </div>
                    </div>

                    <div
                      className="bg_content_white over_f_hide"
                      style={{ padding: "0" }}
                    >
                      <div
                        className="in_bg_white"
                        style={{
                          top: 0,
                          left: 0,
                          right: 0,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ flex: "1" }}>
                          <Wrapper
                            content={
                              <Grid
                                id="banks"
                                container
                                sx={{ padding: isMobile ? "21px" : "21px 0px" }}
                              >
                                <FormControl>
                                  <RadioGroup
                                    name="bank-list"
                                    value={this.state.value}
                                    onChange={(e) =>
                                      this.setState({ value: e.target.value })
                                    }
                                  >
                                    {isThereEnroll && (
                                      <CardList
                                        label="เลือกผู้ให้บริการ NDID ที่ท่านได้ลงทะเบียนไว้"
                                        data={this.state.bank_list.enroll}
                                      />
                                    )}
                                    {isThereUnEnroll > 0 && (
                                      <CardList
                                        label={
                                          isThereEnroll
                                            ? "กรณีที่ท่านต้องการยืนยันตัวตนผ่านธนาคาร ที่ท่านยังไม่ได้ลงทะเบียน กรุณาเลือก แอปพลิเคชันธนาคารที่รับลงทะเบียนดังนี้"
                                            : "ท่านไม่ได้ลงทะเบียน NDID กับธนาคารใดๆ กรุณาเลือกธนาคารที่ท่านมี และรับลงทะเบียน ผ่านแอปพลิเคชันธนาคารดังนี้"
                                        }
                                        data={this.state.bank_list.unenroll}
                                        labelStyle={{
                                          marginTop: isThereEnroll
                                            ? "15px"
                                            : "0px",
                                        }}
                                      />
                                    )}
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                            }
                          />
                        </div>
                      </div>

                      <div className="under_fix_btn">
                        <ButtonCustom
                          handle={
                            this.state.value === undefined
                              ? null
                              : this.sendData
                          }
                          label="ถัดไป"
                          color={
                            this.state.value === undefined ? "gray" : "blue"
                          }
                          btnClass="long_btn"
                        />
                      </div>

                      {/* <div
              onClick={this.click_bank}
              data-id='D1F1532B-19AF-4A88-86AC-79693EC158C1'
              style={{ display: 'block' }}
            >
              <ButtonCustom
                label='ทดสอบ 1'
                btnClass='btn_default'
                iconDirection='R'
                src='/images/scb.png'
                bg='#4e3086'
                icon='angle-double-right'
              />
            </div>

            <div
              onClick={this.click_bank}
              data-id='0F5A378A-FF19-4C00-A549-EA208A1C120A'
              style={{ display: 'block' }}
            >
              <ButtonCustom
                label='ธนาคารไทยพาณิชย์'
                icon='angle-double-right'
                iconDirection='R'
                btnClass='btn_scb btn_default'
              />
            </div>
            <div
              onClick={this.click_bank}
              data-id='E8AF34C5-A948-4F5C-AD71-998D097FFAAD'
              style={{ display: 'block' }}
            >
              <ButtonCustom
                label='ธนาคารกสิกร'
                icon='angle-double-right'
                iconDirection='R'
                btnClass='btn_kbank btn_default'
              />
            </div>
            <div
              onClick={this.click_bank}
              data-id='41D9EF13-115D-47A2-81AA-E1DE1FFD654D'
              style={{ display: 'block' }}
            >
              <ButtonCustom
                label='ธนาคารกรุงเทพ'
                icon='angle-double-right'
                iconDirection='R'
                btnClass='btn_bbl btn_default'
              />
            </div>
            <div
              onClick={this.click_bank}
              data-id='B6586FFF-1D30-4C50-AA9C-D1C7565C2B5A'
              style={{ display: 'block' }}
            >
              <ButtonCustom
                label='ธนาคารกรุงศรี'
                icon='angle-double-right'
                iconDirection='R'
                btnClass='btn_bay btn_default'
              />
            </div> */}
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default KtbstNDID;
