import React from "react";
import { isMobile } from "react-device-detect";
import { makeStyles } from "@mui/styles";
import { Scrollbars } from "react-custom-scrollbars";

const useStyle = makeStyles({
  container: {
    maxHeight: "100%",
    width: "100%",
    textAlign: "left",
    flexGrow: "0",
  },
  textContent: {
    fontFamily: "DB Heavent",
  },
  paddingTopBottom: {
    padding: "10px 0",
  },
});

const Wrapper = (props) => {
  const classes = useStyle();
  const { content, isCheck, onChangeCheck } = props;

  const handleUpdate = (e) => {
    if (e.top >= 0.99 && isCheck) {
      onChangeCheck(true);
    }
  };

  return (
    <Scrollbars
      className={classes.container}
      onUpdate={handleUpdate}
      autoHide
      autoHideTimeout={500}
      autoHideDuration={200}
    >
      {content}
    </Scrollbars>
  );
};

export default Wrapper;
