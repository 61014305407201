import { AddressActionTypes } from './../actions';

const initialState = {
  countries: [],
  provinces: [],
  districts: [],
  subdistricts: [],
  postcodes: [],
};

function addressReducer(state = initialState, action) {
  switch (action.type) {
    case AddressActionTypes.setInitialData:
      const {
        countries,
        provinces,
        districts,
        subdistricts,
        postcodes,
      } = action.values;

      return {
        ...state,
        countries,
        provinces,
        districts,
        subdistricts,
        postcodes,
      };
    default:
      return state;
  }
}

export default addressReducer;
