import React, { Component } from 'react';
import './KtbstSignSignatureHorizonScreen.css';
import ButtonCustom from '../../components/button/ButtonCustom';

class KtbstSignSignatureHorizonScreen extends Component {
  render() {
    return (
      <div>
        <div className='header_sign'>Write your Signature</div>
        <div className='content_signater'>
          <div className='border_sign'>
            Digital signature for onboarding confirmation
          </div>
        </div>
        <div className='under_fix_btn' style={{ display: 'flex' }}>
          <ButtonCustom
            label='ล้าง'
            color=''
            btnClass='btn_rub_sign'
            iconDirection='L'
            icon='eraser'
          ></ButtonCustom>

          <ButtonCustom
            label='บันทึกลายเซ็น'
            color=''
            btnClass='btn_save_sign'
            iconDirection='L'
            icon='arrow-alt-circle-down'
          ></ButtonCustom>

        </div>
      </div>
    );
  }
}

export default KtbstSignSignatureHorizonScreen;
