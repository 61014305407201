// import React, { Component, useEffect, useState } from 'react';
import React, { Component } from "react";
import "./TermAndCon.css";
import _ from "lodash";
// import debounce from 'lodash/debounce'
import throttle from "lodash/throttle";
import ButtonCustom from "../../components/button/ButtonCustom";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import iScroll from "iscroll";
import ReactIScroll from "react-iscroll";
import Loading from "../../components/loading/loading";
import {
  update_tracking,
  popupErrorApi,
} from "../../components/helpers/Helpers";
// import NetworkChecker from '../../components/HOC/NetworkChecker';
import PerfectScrollbar from "@opuscapita/react-perfect-scrollbar";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
import { isMobile } from "react-device-detect";
import { config } from "../../constants";

class TermAndCon extends Component {
  constructor(props) {
    super(props);
    this.myMain = React.createRef();
    this.myRef = React.createRef();
    this.state = {
      scrollTop: 0,
      color_bt: "gray",
      _posit: "",
      set_checkbox: false,
      isDisabled: true,
      termCondition: [],
      termConText: "",
      checkboxText: "",
      termTitle: [],
      read: false,
      isDisconnected: false,
      iScrollOptions: {
        bounce: false,
        probeType: 2,
        mouseWheelSpeed: 20,
        mouseWheel: true,
        scrollbars: true,
        interactiveScrollbars: true,
        fadeScrollbars: true,
        scrollY: true,
      },
      options: {
        wheelSpeed: false,
        minScrollbarLength: 30,
      },
      loading: false,
    };
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.push("/preregis");
  }

  async componentDidMount() {
    await this.setState({ loading: true });
    // await download_ico_alert();
    const header = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios
      .get(config.baseURL + "/api/consent?id=termandcon", header) //https://ws03.ktbst.co.th/ktbst-life/single-application/constant/termcondition/consent
      .then((res) => {
        const data = res.data.data;
        data.forEach((row) => {
          this.setState({
            loading: false,
            checkboxText: row.checkBox,
            termConText: row.detail,
            titleText: row.title,
            btnOk: row.okBtn,
            btnCancal: row.cancelBtn,
          });
        });

        // const h_scroll = document.getElementById('height_scroll')
        //   .offsetHeight;
        // const h_scroll2 = document.getElementById('height_scroll2')
        //   .offsetHeight;
        // if (h_scroll < h_scroll2) {
        this.setState({
          color_bt: "green",
          isDisabled: false,
        });
        // }
      })
      .catch((error) => {
        var mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        var title_mss = "เกิดข้อผิดพลาด";
        popupErrorApi(mss, title_mss, true);
      });
    // axios
    //   .get('https://ekkarach.com/api/terms_condition') //reverse-proxy
    //   .then((res) => {
    //     this.setState({ termCondition: res.data });
    //     this.setState({ termConText: res.data.detail });
    //       const h_scroll = document.getElementById('height_scroll')
    //         .offsetHeight;
    //       const h_scroll2 = document.getElementById('height_scroll2')
    //         .offsetHeight;
    //       if (h_scroll < h_scroll2) {
    //         this.setState({
    //           color_bt: 'green',
    //           isDisabled: false,
    //         });
    //       }
    //   }).catch((error)=>{
    //     const h_scroll = document.getElementById('height_scroll')
    //     .offsetHeight;
    //     const h_scroll2 = document.getElementById('height_scroll2')
    //       .offsetHeight;
    //     if (h_scroll < h_scroll2) {
    //       this.setState({
    //         color_bt: 'green',
    //         isDisabled: false,
    //       });

    //     }

    //   });
    //   const h_scroll = document.getElementById('height_scroll')
    //   .offsetHeight;
    // const h_scroll2 = document.getElementById('height_scroll2')
    //   .offsetHeight;
    // if (h_scroll < h_scroll2) {
    //   this.setState({
    //     color_bt: 'green',
    //     isDisabled: false,
    //   });
    // }
  }

  onScroll = async (e) => {
    //e.preventDefault();
    // const h_scroll = document.getElementById('height_scroll').offsetHeight;
    // const h_scroll2 = document.getElementById('height_scroll2').offsetHeight;
    // const scrollTop = Math.ceil(this.myRef.current.scrollTop);
    // const posit = h_scroll - h_scroll2 - 5; //Math.min(0, this.myRef.current.scrollTop / 2 - 40);
    // await this.setState({
    //   scrollTop: scrollTop,
    //   _posit: posit,
    // });
    // if (this.state._posit < this.state.scrollTop) {
    //   await this.setState({
    //     color_bt: 'blue',
    //     isDisabled: false,
    //   });
    //   this.setState({ read: true });
    // } else {
    //   await this.setState({
    //     color_bt: 'gray',
    //     isDisabled: true,
    //   });
    // }
  };

  allowClick = (e) => {
    e.preventDefault();
    // if (checkInternet()) {
    const page_agree_old = JSON.parse(localStorage.getItem("page_agree"));
    let page_agree = {
      termandcon: 1,
    };
    localStorage.setItem("page_agree", JSON.stringify(page_agree));
    this.props.history.push("pdpa");
    // } else {
    //   this.setState({
    //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
    //   })
    // }
  };

  popup = async (e) => {
    var data = {};
    data.head_token = "";
    data.card_id = "";
    data.stamp_page = "1";
    data.error_code = "1001";
    var update_tracking_send = await update_tracking(data);
    if (update_tracking_send.success) {
      const MySwal = withReactContent(swal);
      MySwal.fire({
        customClass: {
          confirmButton: "long_btn_fix",
          cancelButton: "long_btn_fix",
        },
        allowOutsideClick: false,
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        // icon: 'warning',
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title:
          '<div class="title_popup">ขออภัย ท่านไม่สามารถเปิดบัญชีออนไลน์ได้</div>',
        html: '<div class="content_popup"> กรุณาติดต่อ DAOL Contact Center<br>0 2351 1800 หากต้องการดำเนินการต่อ กรุณาคลิกยอมรับ</div>',
      }).then(function () {
        // window.location = "summaryshow";
      });
    } else {
      // api error
    }
    return;
  };

  SubmitCheckbox = async (e) => {
    await this.setState({ set_checkbox: e.target.checked });
    // console.log(this.state.set_checkbox);
  };

  onScrollEnd = async (iScrollInstance) => {
    if ((await iScrollInstance.y) <= iScrollInstance.maxScrollY) {
      this.setState({
        color_bt: "blue",
        isDisabled: false,
      });
      this.setState({ read: true });
    }
  };
  onScroll_ = async (iScrollInstance) => {
    if ((await iScrollInstance.y) <= iScrollInstance.maxScrollY) {
      this.setState({
        color_bt: "blue",
        isDisabled: false,
      });
      this.setState({ read: true });
    }
  };
  render() {
    const { scrollTop } = this.state;
    const iScroll = require("iscroll/build/iscroll-probe");
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          alt="forward"
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">เงื่อนไขและข้อตกลง</div>
                    </div>
                    <div
                      className="bg_content_white over_f_hide"
                      id="my_scroll"
                    >
                      <div
                        style={{ bottom: 154 }}
                        id="my_scroll2"
                        className="in_bg_white default"
                      >
                        <ReactIScroll
                          id="my_scroll3"
                          iScroll={iScroll}
                          options={this.state.iScrollOptions}
                          onScrollEnd={this.onScrollEnd}
                          onScroll={this.onScroll_}
                        >
                          <div id="height_scroll">
                            <div
                              className="text_title_head_termandcon"
                              style={{ position: "relative" }}
                            >
                              {" "}
                              {this.state.titleText}
                            </div>
                            <div
                              className="text_color_gray"
                              dangerouslySetInnerHTML={{
                                __html: this.state.termConText,
                              }}
                            />
                          </div>
                        </ReactIScroll>
                      </div>

                      {/* <div
              id='height_scroll2'
              style={{bottom:154}}
              className='in_bg_white default scroll'
              ref={this.myRef}
              onScroll={this.onScroll}
            >
              <div id='height_scroll'>
                <div
                  className='text_title_head_termandcon'
                  style={{ position: 'relative' }}
                >
                  การให้ความยินยอมในการเปิดเผยข้อมูล กรณีการเปิดบัญชีลงทุนกับ
                  KTBST SEC
                </div>
                <div  className='text_color_gray' dangerouslySetInnerHTML={{__html:this.state.termConText}} />
              </div>
            </div> */}
                      <div
                        className="under_fix_btn"
                        style={{ background: "#fff" }}
                      >
                        <br />
                        <center
                          style={{
                            display: this.state.read ? "none" : "block",
                          }}
                        >
                          เลื่อนลงเพื่ออ่านเพิ่มเติม
                        </center>
                        <br />
                        <div>
                          <label
                            className="container_border"
                            style={{
                              color: this.state.read ? "#003b66" : "#ccc",
                              fontSize: "18px",
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.checkboxText,
                              }}
                            ></div>
                            <input
                              type="checkbox"
                              disabled={!this.state.read}
                              checked={this.state.set_checkbox}
                              onChange={(e) => this.SubmitCheckbox(e)}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <div>
                            <ButtonCustom
                              handle={
                                this.state.set_checkbox ? this.popup : null
                              }
                              label={this.state.btnCancal}
                              color={this.state.set_checkbox ? "red" : "gray"}
                              btnClass="btn_small_left"
                            />
                            <ButtonCustom
                              handle={
                                this.state.set_checkbox ? this.allowClick : null
                              }
                              label={this.state.btnOk}
                              color={this.state.set_checkbox ? "green" : "gray"}
                              btnClass="btn_small_right"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
            className='bg_content_white_termandcon'
            ref={this.myRef}
            onScroll={this.onScroll}
          >
            <div
              className='text_title_head_termandcon'
              style={{ position: 'relative' }}
            >
              การให้ความยินยอมในการเปิดเผยข้อมูล กรณีการเปิดบัญชีลงทุนกับ KTBST
              SEC
            </div>
            <p style={{ lineHeight: '40px' }}>{this.state.termConText}</p>

            <br></br>
            <br></br>



          </div> */}
                  </div>
                  <Loading status={this.state.loading}></Loading>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default TermAndCon;
