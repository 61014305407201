/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import "./Job.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import axios from "axios";
import Select2 from "react-select";
import customData from "../../components/helpers/response.json";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import validator from "validator";
import $ from "jquery";
import { checkEditPage, popupErrorApi, update_tracking } from "../../components/helpers/Helpers";
import { IMaskInput } from "react-imask";
import Loading from "../../components/loading/loading";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import to from "await-to-js";
import ktbsApi from "./../../api";
import { config } from "./../../constants";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
const _url = config.baseAPIURL03;

class Jobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      business_type: [],
      business_type_require: false,
      career_type: [],
      income_type: [],
      RetrieveData: [],
      f3_income: [],
      f1_career: [],
      f1_business_type: [],
      f1_etc: "",
      f1_business_other: "",
      f1_career_other: "",
      f1_career_position: "",
      f3_officename: "",
      require_f3_officename: true,
      loading: true,
      isSubmit: false,
      AutoFlag: false,
      isDisconnected: false,
      check_RiskGroup: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    await this.setState({ loading: true });
    // await download_ico_alert();
    const RetrieveData = await JSON.parse(localStorage.getItem("RetrieveData"));
    const ReferenceId = await localStorage.getItem("ReferenceId");
    // const ReferenceId = '1253c1d8-34ff-49a7-b6b7-2175fe77be11'; //mock

    const arr_jobs_data = await JSON.parse(
      localStorage.getItem("arr_jobs_data")
    );
    await axios
      .get(_url + `/constant/business_type`)
      .then((res) => {
        const business_type = res.data.Data.map((item, i) => {
          if (arr_jobs_data && item.Id === arr_jobs_data.f1_business_type) {
            this.setState({
              f1_business_type: {
                value: item.Id,
                label: item.ThName,
                Other: item.Other,
                RiskGroup: item.RiskGroup,
              },
              f1_business_other: arr_jobs_data.f1_business_other,
            });
          }
          return {
            value: item.Id,
            label: item.ThName,
            Other: item.Other,
            RiskGroup: item.RiskGroup,
          };
        });
        this.setState({ business_type: business_type });
      })
      .catch(async (error) => {
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          var mss = "";
          var title_mss = "";
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            title_mss =
              error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
        }
        popupErrorApi(
          mss,
          title_mss,
          true
          // function name() {
          //   var myStyle = document.getElementsByClassName("main_popup_loading")[0].style.display;
          //   if(myStyle === 'none'){
          //     document.getElementsByClassName("main_popup_loading")[0].style.display = 'block';
          //   }
          //   window.location = "login";
          // }
        );
        this.setState({ loading: false });
      });

    await axios
      .get(_url + `/constant/career`)
      .then((res) => {
        const career_type = res.data.Data.map((item, i) => {
          if (arr_jobs_data && item.Id === arr_jobs_data.f1_career) {
            this.setState({
              f1_career: {
                value: item.Id,
                label: item.ThName,
                type: item.SAmcr_Type,
                Other: item.Other,
                RiskGroup: item.RiskGroup,
              },
              AutoFlag: item.AutoFlag === 1 ? true : false,
              // require_f3_officename: item.SAmcr_Type === 1 ? true : false,
              f1_career_other: arr_jobs_data.f1_career_other,
            });
          }
          return {
            value: item.Id,
            label: item.ThName,
            autoflag: item.AutoFlag,
            type: item.SAmcr_Type,
            Other: item.Other,
            RiskGroup: item.RiskGroup,
          };
        });
        // console.log(career_type);
        this.setState({ career_type: career_type });
      })
      .catch(async (error) => {
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          var mss = "";
          var title_mss = "";
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            title_mss =
              error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
        }
        popupErrorApi(
          mss,
          title_mss,
          true
          // function name() {
          //   var myStyle = document.getElementsByClassName("main_popup_loading")[0].style.display;
          //   if(myStyle === 'none'){
          //     document.getElementsByClassName("main_popup_loading")[0].style.display = 'block';
          //   }
          //   window.location = "login";
          // }
        );
        this.setState({ loading: false });
      });

    await axios
      .get(_url + `/constant/income`)
      .then((res) => {
        const income_type = res.data.Data.map((item, i) => {
          if (arr_jobs_data && item.Id === arr_jobs_data.f3_income) {
            this.setState({
              f3_income: { value: item.Id, label: item.ThName },
              f1_career_position: arr_jobs_data.f1_career_position,
              f3_officename: arr_jobs_data.f3_officename,
            });
          }
          if (arr_jobs_data) {
            this.setState({
              f1_career_position: arr_jobs_data.f1_career_position ? arr_jobs_data.f1_career_position : "",
              f3_officename: arr_jobs_data.f3_officename ? arr_jobs_data.f3_officename : "",
            });
          }
          return { value: item.Id, label: item.ThName };
        });
        // console.log(income_type);
        this.setState({ income_type: income_type });
      })
      .catch(async (error) => {
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          var mss = "";
          var title_mss = "";
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            title_mss =
              error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
        }
        popupErrorApi(
          mss,
          title_mss,
          true
          // function name() {
          //   var myStyle = document.getElementsByClassName("main_popup_loading")[0].style.display;
          //   if(myStyle === 'none'){
          //     document.getElementsByClassName("main_popup_loading")[0].style.display = 'block';
          //   }
          //   window.location = "login";
          // }
        );
        this.setState({ loading: false });
      });

    /*if (RetrieveData === null) {
      axios
        .post(
          `${config.baseAPIURL06}/ndid/RetrieveData`,
          JSON.stringify({ ReferenceId: ReferenceId }),
          {
            headers: { "content-type": "application/json" },
          }
        )
        .then(async (res) => {
          await this.setState({ RetrieveData: res.data.data[0] });
          await localStorage.setItem(
            "RetrieveData",
            JSON.stringify(res.data.data[0])
          );
        })
        .catch(async (error) => {
          var mss = "";
          var title_mss = "";
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            if (!!error.isAxiosError && !error.response) {
              title_mss = "เกิดข้อผิดพลาด";
              mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            } else {
              title_mss =
                error.response.data.Data[0][0].MsgDescTitle !== ""
                  ? error.response.data.Data[0][0].MsgDescTitle
                  : "เกิดข้อผิดพลาด";
              mss =
                error.response.data.Data[0][0].MsgDesc !== ""
                  ? error.response.data.Data[0][0].MsgDesc
                  : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            }
          }
          popupErrorApi(
            mss,
            title_mss,
            true
            // function name() {
            //   var myStyle = document.getElementsByClassName("main_popup_loading")[0].style.display;
            //   if(myStyle === 'none'){
            //     document.getElementsByClassName("main_popup_loading")[0].style.display = 'block';
            //   }
            //   window.location = "login";
            // }
          );
          this.setState({ loading: false });
        });
    }*/
    await this.setState({ loading: false });
  }

  findByName(params, data) {
    if (data.length > 0) {
      var index = data.findIndex(function (arr) {
        return arr.ThName.toLowerCase().includes(params.toLowerCase());
      });
      return index !== -1 ? data[index] : { Id: "" };
    } else {
      return { Id: "" };
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = await this.validate();
    this.setState({ isSubmit: true });

    // console.log(this.state.f1_career.RiskGroup);
    // console.log(this.state.f1_business_type.RiskGroup);
    if (
      this.state.f1_career.RiskGroup === 3 ||
      this.state.f1_business_type.RiskGroup === 3
    ) {
      isValid = false;
      const HeaderManp = JSON.parse(localStorage.getItem('HeaderManp'));
      const checkuserinfo = JSON.parse(localStorage.getItem('checkuserinfo'));
      const head_token = HeaderManp.head_token;
      var data = {}
      data.head_token = head_token;
      data.card_id = checkuserinfo ? checkuserinfo.cardId : "";
      data.stamp_page = "3";
      data.error_code = "3002";
      var update_tracking_send = await update_tracking(data);
      if (update_tracking_send.success) {
        popupErrorApi(
          "ขออภัยท่านไม่สามารถเปิดบัญชีออนไลน์ได้ กรุณาติดต่อ DAOL Contact Center 0 2351 1800",
          "เกิดข้อผิดพลาด",
          true
        );
      } else {
        // api error
      }
    }

    if (isValid) {
      var arr_jobs_data = [];
      const f3 = JSON.parse(localStorage.getItem("f3"));
      let f3_ = {
        f3_source: f3 ? f3.f3_source : "",
        f3_source_text: f3 ? f3.f3_source_text : "",
        f3_by_private_business: f3 ? f3.f3_by_private_business : "",
        f3_by_employee: f3 ? f3.f3_by_employee : "",
        f3_by_heritage: f3 ? f3.f3_by_heritage : "",
        f3_by_borrowing: f3 ? f3.f3_by_borrowing : "",
        f3_by_divided: f3 ? f3.f3_by_divided : "",
        f3_by_retired: f3 ? f3.f3_by_retired : "",
        f3_by_saving: f3 ? f3.f3_by_saving : "",
        f3_by_other: f3 ? f3.f3_by_other : "",
        f3_by_other_text: f3 ? f3.f3_by_other_text : "",
        f3_income: this.state.f3_income.value,
        f3_outcome: f3 ? f3.f3_outcome : "",
        f3_invest: f3 ? f3.f3_invest : "",
        f3_valuable: f3 ? f3.f3_valuable : "",
        f3_by_invest: f3 ? f3.f3_by_invest : "0",
        f3_own: f3 ? f3.f3_own : "1",
        purpose_json: f3 ? f3.purpose_json : [],
      };
      localStorage.setItem("f3", JSON.stringify(f3_));
      arr_jobs_data = {
        f1_career: this.state.f1_career.value,
        f1_business_type: this.state.f1_business_type.value,
        f1_business_other:
          this.state.f1_business_type.Other === 1
            ? validator.trim(this.state.f1_business_other)
            : "",
        f1_career_other:
          this.state.f1_career.Other === 1
            ? validator.trim(this.state.f1_career_other)
            : "",
        f3_income: this.state.f3_income.value,
        f1_career_position: validator.trim(this.state.f1_career_position),
        f3_officename: validator.trim(this.state.f3_officename),
      };
      localStorage.setItem("arr_jobs_data", JSON.stringify(arr_jobs_data));
      var str = window.location.href;
      let re = /edit/;
      const back_to_edit = re.test(str);
      // if (checkInternet()) {
      if (back_to_edit) {
        let error;
        let result;
        let mss = "";
        let title_mss = "";
        const MySwal = withReactContent(swal);
        const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
        const head_token = HeaderManp.head_token;
        const f3 = JSON.parse(localStorage.getItem("f3"))
          ? JSON.parse(localStorage.getItem("f3"))
          : {};
        const arr_jobs_data = JSON.parse(localStorage.getItem("arr_jobs_data"))
          ? JSON.parse(localStorage.getItem("arr_jobs_data"))
          : {};
        const {
          f3_source,
          f3_source_text,
          f3_by_private_business,
          f3_by_employee,
          f3_by_heritage,
          f3_by_borrowing,
          f3_by_divided,
          f3_by_retired,
          f3_by_saving,
          f3_by_other,
          f3_by_other_text,
          f3_income,
          f3_outcome,
          f3_invest,
          f3_valuable,
          purpose_json,
          f3_by_invest,
          f3_own,
        } = f3;
        const {
          f1_business_other,
          f1_business_type,
          f1_career,
          f1_career_other,
          f1_career_position,
          f3_officename,
        } = arr_jobs_data;
        const careerAndFinancial = {
          head_token,
          f3_source: f3_source,
          f3_source_text: f3_source_text,
          f3_by_private_business: f3_by_private_business,
          f3_by_employee: f3_by_employee,
          f3_by_heritage: f3_by_heritage,
          f3_by_borrowing: f3_by_borrowing,
          f3_by_divided: f3_by_divided,
          f3_by_retired: f3_by_retired,
          f3_by_saving: f3_by_saving,
          f3_by_other: f3_by_other,
          f3_by_other_text: f3_by_other_text,
          f3_income: f3_income,
          f3_outcome: f3_outcome,
          f3_invest: f3_invest,
          f3_valuable: f3_valuable,
          purpose_json: purpose_json,
          f3_career: f1_career,
          f3_officename: f3_officename,
          f3_career_text: f1_career_other,
          f3_business: f1_business_type,
          f3_business_text: f1_business_other,
          f3_position: f1_career_position,
          f3_by_invest: f3_by_invest,
          f3_own: f3_own,
        };

        // console.log(careerAndFinancial);
        await this.setState({ loading: true });
        [error, result] = await to(
          ktbsApi.post("/form/career_and_financial/manp", careerAndFinancial)
        );
        if (
          error ||
          !result ||
          !result.Data ||
          !result.Data[0] ||
          !result.Data[0][0] ||
          result.Data[0][0].IsSuccess !== 1
        ) {
          if (result) {
            title_mss =
              result.Data[0][0].MsgDescTitle !== ""
                ? result.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              result.Data[0][0].MsgDesc !== ""
                ? result.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else if (error) {
            if (!!error.isAxiosError && !error.response) {
              title_mss = "เกิดข้อผิดพลาด";
              mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            } else {
              title_mss =
                error.response.data.Data[0][0].MsgDescTitle !== ""
                  ? error.response.data.Data[0][0].MsgDescTitle
                  : "เกิดข้อผิดพลาด";
              mss =
                error.response.data.Data[0][0].MsgDesc !== ""
                  ? error.response.data.Data[0][0].MsgDesc
                  : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            }
          } else {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
          MySwal.fire({
            imageUrl: "/images/ico_alert.svg",
            imageWidth: 100,
            imageHeight: 100,
            customClass: {
              confirmButton: "long_btn_fix",
            },
            confirmButtonColor: "#003b66",
            confirmButtonText: "กลับ",
            title: '<div class="title_popup">' + title_mss + "</div>",
            html: '<div class="content_popup">' + mss + "</div>",
          });
          await this.setState({ loading: false });
          return;
        } else {
          // console.log(result.Data[0][0].MsgDesc !== "");
        }
        await this.setState({ loading: false });
        await this.props.history.push("/summaryshow");
        await localStorage.setItem("editPage", "Jobs");
        return;
      }
      const BackToSummaryRisk = JSON.parse(localStorage.getItem("BackToSummaryRisk"));
      if (BackToSummaryRisk) {
        localStorage.removeItem('BackToSummaryRisk');
        window.location = 'summary_risk';
      } else {
        this.props.history.push("/FamilyStatus");
      }

      // } else {
      //   this.setState({
      //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
      //   })
      // }
    }
  };

  handleChange = async (selectedOption, e) => {
    await this.setState({ [e.name]: selectedOption });
    if (selectedOption.Other === 0) {
      this.setState({ f1_etc: "" });
    }
    if (e.name === "f1_career") {
      // console.log("autoflag = " + selectedOption.autoflag);
      await this.setState({
        // check_RiskGroup: selectedOption.RiskGroup === 3 ? false : true,
        business_type_require: selectedOption.type === 2 ? true : false,
        f1_career_position:
          selectedOption.autoflag === 1 ? selectedOption.label : "",
        AutoFlag: selectedOption.autoflag === 1 ? true : false,
        // require_f3_officename: selectedOption.type === 2 ? true : false,
        // f3_officename:selectedOption.autoflag === 1 ? selectedOption.label : "",
      });
      // console.log(selectedOption.type);
    }
    if (e.name === "f1_business_type") {
      await this.setState({
        // check_RiskGroup: selectedOption.RiskGroup === 3 ? false : true,
      });
      // console.log(selectedOption.type);
    }
    if (this.state.isSubmit) {
      await this.validate();
    }
  };

  validate = () => {
    $("span[class^=span_f]").hide();
    let countError = 0;
    if (!this.state.f1_career.value) {
      $(".span_f1_career").css("display", "flex");
      countError++;
    }
    if (
      !this.state.f1_business_other &&
      this.state.f1_business_type.Other === 1
    ) {
      $(".span_f1_etc").css("display", "flex");
      countError++;
    }
    if (!this.state.f1_career_other && this.state.f1_career.Other === 1) {
      $(".span_f1_etc_job").css("display", "flex");
      countError++;
    }

    if (!this.state.f1_career_position) {
      $(".span_f1_career_position").css("display", "flex");
      countError++;
    }
    if (this.state.require_f3_officename) {
      if (!this.state.f3_officename) {
        $(".span_f3_officename").css("display", "flex");
        countError++;
      }
    }

    if (
      // !this.state.f1_business_type.value && this.state.business_type_require
      !this.state.f1_business_type.value
    ) {
      $(".span_f1_business_type").css("display", "flex");
      countError++;
    }
    if (!this.state.f3_income.value) {
      $(".span_f3_income").css("display", "flex");
      countError++;
    }
    return countError > 0 ? false : true;
  };

  handleIChange = async (v, ref) => {
    await this.setState({
      [ref.el.input.name]: v,
    });
    if (this.state.isSubmit) {
      await this.validate();
    }
  };

  goback = () => {
    if (checkEditPage()) {
      return;
    }
    this.props.history.push("/idc_address_choose");
  };

  render() {
    library.add(fas);
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div className="content">
                  <Loading status={this.state.loading} />
                  <div className="top_main_back">
                    <div className="main_back" onClick={this.goback}>
                      <img
                        src={
                          isMobile
                            ? "/images/angle-arrow-left.svg"
                            : "/images/angle-arrow-left-blue.svg"
                        }
                        style={{
                          display: checkEditPage() ? "none" : "block",
                          marginLeft: "3px",
                        }}
                        className="arrow_back"
                      />
                    </div>
                    <div className="text_title">ข้อมูลอาชีพ</div>
                  </div>

                  <div className="bg_content_white over_f_hide">
                    <div
                      className="in_bg_white autoscroll"
                      style={{ top: 0, padding: "0 21px", left: 0, right: 0 }}
                    >
                      <div
                        className="row_idcard"
                        style={{
                          borderColor:
                            this.state.f1_career.value !== undefined
                              ? "#003b66"
                              : "#ccc",
                        }}
                      >
                        <div>อาชีพ *</div>
                        <Select2
                          name="f1_career"
                          options={this.state.career_type}
                          value={this.state.f1_career}
                          onChange={this.handleChange}
                          placeholder="กรุณาระบุ"
                          className="select_two"
                        />
                        <span className="span_f1_career">
                          กรุณาระบุอาชีพ{this.state.f1_career.value}
                          {/* <FontAwesomeIcon icon='info-circle' /> */}
                        </span>
                      </div>
                      <div
                        className="row_idcard"
                        style={{
                          borderColor:
                            this.state.f1_career_other === ""
                              ? "#ccc"
                              : "#003b66",
                          display:
                            this.state.f1_career.Other === 1 ? "block" : "none",
                        }}
                      >
                        <div>อาชีพ อื่นๆ *</div>
                        <IMaskInput
                          mask={/^[A-Za-zก-๙.\s\-]+$/}
                          value={this.state.f1_career_other}
                          unmask={true}
                          className="input_idcard"
                          name="f1_career_other"
                          style={{
                            textDecorationLine: "none",
                            borderColor:
                              this.state.f1_career_other !== ""
                                ? "#003b66"
                                : "#ccc",
                          }}
                          onAccept={this.handleIChange}
                        />
                        <span className="span_f1_etc_job">
                          กรุณาระบุอาชีพอื่นๆ
                        </span>
                      </div>

                      <div
                        className="row_idcard"
                        style={{
                          borderColor:
                            this.state.f1_business_type.value !== undefined
                              ? "#003b66"
                              : "#ccc",
                        }}
                      >
                        <div>ประเภทธุรกิจ *</div>
                        <Select2
                          name="f1_business_type"
                          options={this.state.business_type}
                          value={this.state.f1_business_type}
                          onChange={this.handleChange}
                          placeholder="กรุณาระบุ"
                          className="select_two"
                        />
                        <span className="span_f1_business_type">
                          กรุณาระบุประเภทธุรกิจ
                          {/* <FontAwesomeIcon icon='info-circle' /> */}
                        </span>
                      </div>
                      <div
                        className="row_idcard"
                        style={{
                          borderColor:
                            this.state.f1_business_other === ""
                              ? "#ccc"
                              : "#003b66",
                          display:
                            this.state.f1_business_type.Other === 1
                              ? "block"
                              : "none",
                        }}
                      >
                        <div>ธุรกิจ อื่นๆ *</div>
                        <IMaskInput
                          mask={/^[A-Za-zก-๙.\s\-]+$/}
                          value={this.state.f1_business_other}
                          unmask={true}
                          className="input_idcard"
                          name="f1_business_other"
                          style={{
                            textDecorationLine: "none",
                            borderColor:
                              this.state.f1_business_other !== ""
                                ? "#003b66"
                                : "#ccc",
                          }}
                          onAccept={this.handleIChange}
                        />
                        <span className="span_f1_etc">
                          กรุณาระบุธุรกิจอื่นๆ
                        </span>
                      </div>
                      <div
                        className="row_idcard"
                        style={{
                          borderColor:
                            this.state.f3_income.value !== undefined
                              ? "#003b66"
                              : "#ccc",
                        }}
                      >
                        <div>รายได้ต่อเดือน(บาท) *</div>
                        <Select2
                          name="f3_income"
                          options={this.state.income_type}
                          value={this.state.f3_income}
                          onChange={this.handleChange}
                          isSearchable={false}
                          placeholder="กรุณาระบุ"
                          className="select_two"
                        />
                        <span className="span_f3_income">
                          กรุณาระบุรายได้ต่อเดือน
                          {/* <FontAwesomeIcon icon='info-circle' /> */}
                        </span>
                      </div>

                      <div
                        className="row_idcard"
                        style={{
                          borderColor:
                            this.state.f1_career_position === ""
                              ? "#ccc"
                              : "#003b66",
                        }}
                      >
                        <div>ตำแหน่งงาน *</div>
                        <IMaskInput
                          mask={/^[A-Za-zก-๙.\s\-\/]+$/}
                          value={this.state.f1_career_position}
                          unmask={true}
                          className="input_idcard"
                          disabled={this.state.AutoFlag}
                          name="f1_career_position"
                          style={{
                            textDecorationLine: "none",
                            color: this.state.AutoFlag ? "#ccc" : "#003b66",
                            borderColor:
                              this.state.f1_career_position !== ""
                                ? "#003b66"
                                : "#ccc",
                          }}
                          onAccept={this.handleIChange}
                        />
                        <span className="span_f1_career_position">
                          กรุณาระบุตำแหน่งงาน
                        </span>
                      </div>

                      <div
                        className="row_idcard"
                        style={{
                          borderColor:
                            this.state.f1_career_position === ""
                              ? "#ccc"
                              : "#003b66",
                        }}
                      >
                        <div>
                          ชื่อสถานที่ทำงาน{" "}
                          {this.state.require_f3_officename ? "*" : ""}{" "}
                        </div>
                        <IMaskInput
                          mask={/^[A-Za-zก-๙1-9\\(\\),.@&\s\'\-]+$/}
                          value={this.state.f3_officename}
                          unmask={true}
                          className="input_idcard"
                          name="f3_officename"
                          style={{
                            textDecorationLine: "none",
                            borderColor:
                              this.state.f3_officename !== ""
                                ? "#003b66"
                                : "#ccc",
                          }}
                          onAccept={this.handleIChange}
                        />
                        <span className="span_f3_officename">
                          กรุณาระบุชื่อสถานที่ทำงาน
                        </span>
                      </div>
                    </div>

                    <div className="under_fix_btn">
                      <ButtonCustom
                        handle={this.handleSubmit}
                        label={checkEditPage() ? "บันทึกการแก้ไข" : "ถัดไป"}
                        color="blue"
                        btnClass="long_btn"
                      ></ButtonCustom>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Jobs;
