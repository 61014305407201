/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import "./CheckUserInfo.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import { checkIdcard, popupErrorApi, checkEditData } from "../../components/helpers/Helpers";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import qs from "qs";
import validator from "validator";
import $ from "jquery";
import Loading from "../../components/loading/loading";
import { IMaskInput } from "react-imask";
import Cookies from "universal-cookie";
import { config } from "./../../constants";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
const _url = config.baseAPIURL03;
class CheckUserInfo extends Component {
  constructor(props) {
    super(props);
    const comebackWithOtp = JSON.parse(localStorage.getItem("comebackWithOtp"));
    this.goBack = this.goBack.bind(this);
    this.state = {
      comebackWithOtp: comebackWithOtp ? true : false,
      cardId: "",
      aeCode: "",
      f0_mobile_phone: "",
      f0_email: "",
      f0_email_1: "",
      btn_user_choose: false,
      ischeckIdcard: false,
      ischeckEmail: false,
      ischeckEmail1: false,
      isSubmit: false,
      isDisconnected: false,
      status_popup: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  async componentDidMount() {
    const checkuserinfo = await JSON.parse(
      localStorage.getItem("checkuserinfo")
    );
    if (checkuserinfo !== null) {
      this.setState({ cardId: checkuserinfo.cardId });
      this.setState({ aeCode: checkuserinfo.aeCode });
      this.setState({
        btn_user_choose: !validator.isEmpty(checkuserinfo.aeCode),
      });
      this.setState({ f0_mobile_phone: checkuserinfo.f0_mobile_phone });
      this.setState({ f0_email: checkuserinfo.f0_email });
      this.setState({ f0_email_1: checkuserinfo.f0_email });
      this.setState({ ischeckIdcard: true });
      this.setState({ ischeckEmail: true });
      this.setState({ ischeckEmail1: true });
    }

    const cookies = new Cookies();
    const cookiesAecode = cookies.get("aecode");
    if (cookiesAecode) {
      this.setState({ aeCode: cookiesAecode });
      this.setState({
        btn_user_choose: !validator.isEmpty(cookiesAecode),
      });
    }

    if (this.state.comebackWithOtp) {

    } else {
      this.clrLocalstorage();
    }
  }

  Click_user_choose = async (e) => {
    var value = e.currentTarget.dataset.id === "true" ? true : false;
    await this.setState({ btn_user_choose: value });
    if (value === false) {
      const cookies = new Cookies();
      cookies.remove("aecode");
      await this.setState({ aeCode: "" });
      document.getElementById("aeCode").style.display = "none";
    } else {
      document.getElementById("aeCode").style.display = "block";
    }
  };

  handleAeCodeChange = (event) => {
    this.setState({ aeCode: event.target.value });
  };

  handleTelCodeChange = (event) => {
    const val = event.target.value.replace(/[\D]+/g, "");
    this.setState({ f0_mobile_phone: val });
  };

  handleMouse() {
    // console.log(555);
  }

  call_axios2UpdateCheckinfo = async (comeback) => {
    this.setState({ status_popup: true });
    let vm = this;
    if (this.state.comebackWithOtp) {
      this.props.history.push("/SnapIdCard");
    } else {
      var aecode = this.state.btn_user_choose ? this.state.aeCode : "";
      const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
      const { head_token } = HeaderManp;
      await axios
        .post(
          _url + "/form/header_info/manp",
          qs.stringify({
            head_token,
            f1_mobile: this.state.f0_mobile_phone,
            f1_email: this.state.f0_email,
            f1_investment_consultant_id: this.state.aeCode,
          })
        )
        .then((res) => {
          this.setState({ status_popup: false });
          if (res.data.Data[0][0].IsSuccess === 0) {
            if (res.data.Data[0][0].MsgDesc !== "") {
              popupErrorApi(res.data.Data[0][0].MsgDesc, "", true);
            } else {
              this.alert_fill_data("กรุณา ลองใหม่ / please try again");
            }
            return false
          } else {
            // if (this.state.comebackWithOtp) {
            //   this.props.history.push("/SnapIdCard");
            // } else {
            localStorage.removeItem("HeaderManp");
            this.props.history.push("otp?page=comeback");
            // }
            return true
          }
        })
        .catch((error) => {
          vm.setState({ status_popup: false });
          var mss = "";
          var title_mss = "";
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            if (!!error.isAxiosError && !error.response) {
              title_mss = "เกิดข้อผิดพลาด";
              mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            } else {
              title_mss =
                error.response.data.Data !== undefined &&
                  error.response.data.Data[0][0].MsgDescTitle !== ""
                  ? error.response.data.Data[0][0].MsgDescTitle
                  : "เกิดข้อผิดพลาด";
              mss =
                error.response.data.Data !== undefined &&
                  error.response.data.Data[0][0].MsgDesc !== ""
                  ? error.response.data.Data[0][0].MsgDesc
                  : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            }
          }
          popupErrorApi(mss, title_mss, true);
          return false
        });
    }
  };

  call_axios = async (comeback) => {
    let vm = this;
    var aecode = this.state.btn_user_choose ? this.state.aeCode : "";
    var checkuserinfo = {
      cardId: this.state.cardId,
      f0_mobile_phone: this.state.f0_mobile_phone,
      f0_email: this.state.f0_email,
      aeCode: this.state.aeCode,
    };
    await localStorage.setItem("checkuserinfo", JSON.stringify(checkuserinfo));
    if (comeback) {
      const MySwal = withReactContent(swal);
      let currentStep = "";
      MySwal.fire({
        customClass: {
          confirmButton: "long_btn_fix",
          cancelButton: "long_btn_fix",
        },
        imageUrl: "/images/ico_approve.svg",
        imageWidth: 100,
        imageHeight: 100,
        showCancelButton: true,
        confirmButtonColor: "#003b66",
        confirmButtonText: "กรอกข้อมูลใหม่",
        cancelButtonText: "ดำเนินการต่อ",
        allowOutsideClick: false,
        allowEscapeKey: false,
        title: `<span class="title_popup">ท่านอยู่ระหว่างการเปิดบัญชี</span>`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
          const { head_token } = HeaderManp;
          await axios
            .post(
              _url + "/form/header/removeheader",
              qs.stringify({
                head_token,
              })
            )
            .then((res) => {
              vm.clrLocalstorage();
              vm.call_axios2continoue(true);
            });
        } else {
          var UpdateCheckinfo = await vm.call_axios2UpdateCheckinfo();
          if (UpdateCheckinfo) {
            localStorage.removeItem("HeaderManp");
            vm.props.history.push("otp?page=comeback");
          } else {
            return
          }
          return
        }
      });
    } else {
      vm.call_axios2continoue(false);
    }
  };

  async getUserdata(last_stamp) {
    let currentStep;
    // await localStorage.setItem("last_stamp", last_stamp);
    last_stamp = await JSON.parse(last_stamp);
    // await localStorage.removeItem("last_stamp", last_stamp);
    if (last_stamp !== null && last_stamp.length > 0) {
      var i = last_stamp.length;
      while (i--) {
        if (
          last_stamp[i].key === "fillDetail" ||
          last_stamp[i].key === "photo1" ||
          last_stamp[i].key === "photo2" ||
          last_stamp[i].key === "ReferenceId" ||
          last_stamp[i].key === "NDIDRequestId" ||
          last_stamp[i].key === "passDopa" ||
          last_stamp[i].key === "BankID" ||
          last_stamp[i].key === "RequestMessage" ||
          last_stamp[i].key === "trimmedDataURL" ||
          last_stamp[i].key === "bankUploadImg" ||
          last_stamp[i].key === "passNDID" ||
          last_stamp[i].key === "scoreAll"
        ) {
          localStorage.removeItem(last_stamp[i].key);
          localStorage.setItem(last_stamp[i].key, last_stamp[i].value);
        } else if (last_stamp[i].key === "currentStep") {
          localStorage.removeItem(last_stamp[i].key);
          currentStep = last_stamp[i].value;
          localStorage.setItem(last_stamp[i].key, last_stamp[i].value);
        } else if (
          last_stamp[i].key === "page_agree" ||
          last_stamp[i].key === "checkuserinfo" ||
          last_stamp[i].key === "editPage"
        ) {
          // console.log(last_stamp[i].key,i);
        } else {
          var key_y = JSON.parse(last_stamp[i].value);
          var errorTitle2 = new Array(
            "fatca",
            "f0",
            "WorkAddress",
            "RetrieveDataKTBSC",
            "RetrieveData",
            "IDAddress",
            "ContactAddress",
            "HeaderManp",
            "f3",
            "arr_jobs_data",
            "f1",
            "children_json",
            "f5_relationship",
            "f3_invester",
            "question",
            "RiskQuestion",
            "listQ14",
            "RiskQuestionPoll",
            "summary_risk",
            "atsData",
            "atsDataUser",
            "otherBank"
          );
          if (errorTitle2.indexOf(last_stamp[i].key) != -1) {
            localStorage.removeItem(last_stamp[i].key);
            localStorage.setItem(last_stamp[i].key, JSON.stringify(key_y));
          }
        }
      }
    }
  }

  call_axios2continoue = async (comeback) => {
    this.setState({ status_popup: true });
    let vm = this;
    var aecode = this.state.btn_user_choose ? this.state.aeCode : "";
    await axios
      .post(
        _url + "/form/header/checkcustomer",
        qs.stringify({
          card_id: this.state.cardId,
          aecode: aecode,
          email: this.state.f0_email,
          mobile_phone: this.state.f0_mobile_phone,
          // comeback: comeback,
        })
      )
      .then((res) => {
        this.setState({ status_popup: false });
        if (res.data.Data[0][0].IsSuccess === 0) {
          if (res.data.Data[0][0].MsgDesc !== "") {
            popupErrorApi(res.data.Data[0][0].MsgDesc, "", true);
          } else {
            this.alert_fill_data("กรุณา ลองใหม่ / please try again");
          }
        } else {
          var checkuserinfo = {
            cardId: this.state.cardId,
            f0_mobile_phone: this.state.f0_mobile_phone,
            f0_email: this.state.f0_email,
            aeCode: this.state.aeCode,
          };
          localStorage.setItem("checkuserinfo", JSON.stringify(checkuserinfo));
          vm.props.history.push("/SnapIdCard");
        }
      })
      .catch((error) => {
        vm.setState({ status_popup: false });
        var mss = "";
        var title_mss = "";
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            title_mss =
              error.response.data.Data !== undefined &&
                error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              error.response.data.Data !== undefined &&
                error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
        }
        popupErrorApi(mss, title_mss, true);
      });
  };

  clrLocalstorage() {
    // localStorage.clear();
    var keys = Object.keys(localStorage),
      i = keys.length;
    while (i--) {
      if (keys[i] !== "checkuserinfo" && keys[i] !== "page_agree") {
        if (localStorage.getItem(keys[i])) {
          localStorage.removeItem(keys[i]);
          localStorage.removeItem("editPage");
        }
      }
    }
  }
  alert_fill_data = (mss, page) => {
    const MySwal = withReactContent(swal);
    MySwal.fire({
      customClass: {
        confirmButton: "long_btn_fix",
        cancelButton: "long_btn_fix",
      },
      imageUrl: "/images/ico_alert.svg",
      imageWidth: 100,
      imageHeight: 100,
      confirmButtonColor: "#003b66",
      confirmButtonText: "กลับ",
      title: '<div class="title_popup">' + mss + "</div>",
      // html: '<div class="content_popup">' + mss + '</div>',
    }).then(function () {
      if (page) {
        // window.location = page;
        this.props.history.push(page);
      }
      // window.location = "summaryshow";
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isSubmit: true });
    const isValid = await this.validate();
    if (isValid) {
      this.setState({ status_popup: true });
      if (this.state.comebackWithOtp) {
        var checkuserinfo = {
          cardId: this.state.cardId,
          f0_mobile_phone: this.state.f0_mobile_phone,
          f0_email: this.state.f0_email,
          aeCode: this.state.aeCode,
        };
        const oldData_checkuserinfo = JSON.parse(localStorage.getItem("oldData_checkuserinfo"));
        if (oldData_checkuserinfo) {
          const changes = Object.keys(oldData_checkuserinfo).filter(key => oldData_checkuserinfo[key] !== checkuserinfo[key]);
          if (changes.includes("cardId")) {
            const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
            const { head_token } = HeaderManp;
            await axios
              .post(
                _url + "/form/header/removeheader",
                qs.stringify({
                  head_token,
                })
              )
              .then((res) => {
                localStorage.setItem("checkuserinfo", JSON.stringify(checkuserinfo));
                this.clrLocalstorage();
                this.call_axios2continoue(true);
              });
            // console.log("remove header");
            // localStorage.setItem("checkuserinfo", JSON.stringify(checkuserinfo));
            // this.props.history.push("/SnapIdCard");
          } else if (
            changes.includes("f0_mobile_phone") ||
            changes.includes("f0_email") ||
            changes.includes("aeCode")
          ) {
            localStorage.setItem("checkuserinfo", JSON.stringify(checkuserinfo));
            await checkEditData();
            const flag_checkuserinfo = JSON.parse(localStorage.getItem("flag_checkuserinfo"));
            if (flag_checkuserinfo) {
              var aecode = this.state.btn_user_choose ? this.state.aeCode : "";
              await axios
                .post(
                  _url + "/form/header/checkcustomer",
                  qs.stringify({
                    card_id: this.state.cardId,
                    aecode: aecode,
                    email: this.state.f0_email,
                    mobile_phone: this.state.f0_mobile_phone,
                    // comeback: false,
                  })
                )
                .then((res) => {
                  this.setState({ status_popup: false });
                  if (res.data.Data[0][0].IsSuccess === 0) {
                    if (res.data.Data[0][0].MsgDesc !== "") {
                      popupErrorApi(res.data.Data[0][0].MsgDesc, "", true);
                    } else {
                      this.alert_fill_data("กรุณา ลองใหม่ / please try again");
                    }
                  } else {
                    this.call_axios2UpdateCheckinfo();
                  }
                })
                .catch((error) => {
                  this.setState({ status_popup: false });
                  var mss = "";
                  var title_mss = "";
                  title_mss =
                    error.response.data.Data[0][0].MsgDescTitle !== ''
                      ? error.response.data.Data[0][0].MsgDescTitle
                      : 'เกิดข้อผิดพลาด';
                  mss =
                    error.response.data.Data[0][0].MsgDesc !== ''
                      ? error.response.data.Data[0][0].MsgDesc
                      : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
                  popupErrorApi(mss, title_mss, true);
                });
            }
          } else {
            this.props.history.push("/SnapIdCard");
          }
        } else {
          this.call_axios2continoue(true);
        }
      } else {
        // return
        ///comment comeback flow
        let vm = this;
        var aecode = this.state.btn_user_choose ? this.state.aeCode : "";
        await axios
          .post(
            _url + "/form/comeback/selbyhead",
            qs.stringify({
              card_id: this.state.cardId,
              mobile_number: this.state.f0_mobile_phone,
              aecode: aecode,
              email: this.state.f0_email,
            })
          )
          .then(async (res) => {
            let comeback = res.data.Data[0][0].comeback;
            let last_stamp = res.data.Data[0][0].last_stamp;
            const HeaderManp = res.data.Data[0][0];
            localStorage.setItem("HeaderManp", JSON.stringify(HeaderManp));
            if (comeback !== undefined) {
              if (comeback) {
                await axios
                  .post(
                    _url + "/form/header/checkcustomer",
                    qs.stringify({
                      card_id: this.state.cardId,
                      aecode: aecode,
                      email: this.state.f0_email,
                      mobile_phone: this.state.f0_mobile_phone,
                      // comeback: false,
                    })
                  )
                  .then((res) => {
                    this.setState({ status_popup: false });
                    if (res.data.Data[0][0].IsSuccess === 0) {
                      if (res.data.Data[0][0].MsgDesc !== "") {
                        popupErrorApi(res.data.Data[0][0].MsgDesc, "", true);
                      } else {
                        this.alert_fill_data("กรุณา ลองใหม่ / please try again");
                      }
                    } else {
                      if (!this.state.comebackWithOtp) {
                        // comeback 
                        this.call_axios(true);
                        // this.getUserdata(last_stamp);
                      } else {
                        //back from checkuserinfo
                        console.log("back from checkuserinfo");
                        return
                      }
                    }
                  })
                  .catch((error) => {
                    vm.setState({ status_popup: false });
                    var mss = "";
                    var title_mss = "";
                    title_mss =
                      error.response.data.Data[0][0].MsgDescTitle !== ''
                        ? error.response.data.Data[0][0].MsgDescTitle
                        : 'เกิดข้อผิดพลาด';
                    mss =
                      error.response.data.Data[0][0].MsgDesc !== ''
                        ? error.response.data.Data[0][0].MsgDesc
                        : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
                    popupErrorApi(mss, title_mss, true);
                  });
              } else {
                this.call_axios2continoue(false);
              }
            } else {
              vm.setState({ status_popup: false });
              var mss = "";
              var title_mss = "";
              title_mss = "เกิดข้อผิดพลาด";
              mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
              popupErrorApi(mss, title_mss, true);
            }
          })
          .catch((error) => {
            vm.setState({ status_popup: false });
            var mss = "";
            var title_mss = "";
            if (!!error.isAxiosError && !error.response) {
              title_mss = "เกิดข้อผิดพลาด";
              mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            } else {
              if (!!error.isAxiosError && !error.response) {
                title_mss = "เกิดข้อผิดพลาด";
                mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
              } else {
                title_mss =
                  error.response.data.Data !== undefined &&
                    error.response.data.Data[0][0].MsgDescTitle !== ""
                    ? error.response.data.Data[0][0].MsgDescTitle
                    : "เกิดข้อผิดพลาด";
                mss =
                  error.response.data.Data !== undefined &&
                    error.response.data.Data[0][0].MsgDesc !== ""
                    ? error.response.data.Data[0][0].MsgDesc
                    : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
              }
            }
            popupErrorApi(mss, title_mss, true);
          });
        return;
      }
    } else {
      var items = document.querySelectorAll("[class*=span_f0]");
      for (var i = 0; i < items.length; i++) {
        if (items[i].style.display === "flex") {
          return items[i].parentNode.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  };

  handleChange = async (e) => {
    e.preventDefault();
    const isCheckbox = e.target.type === "checkbox";
    const value =
      e.target.name === "cardId" || e.target.name === "f0_mobile_phone"
        ? e.target.value.replace(/[\D]+/g, "")
        : // : e.target.name === 'f0_email'
        // ? e.target.value.replace(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, '')
        validator.trim(e.target.value);
    await this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : value,
    });
    if (this.state.isSubmit) {
      await this.validate();
    }
  };

  handleIChange = async (v, ref) => {
    if (ref.el.input.name === "aeCode") {
      v = v.toUpperCase();
    }
    await this.setState({
      [ref.el.input.name]: validator.trim(v),
    });
    if (this.state.isSubmit && ref.el.input.name === "aeCode") {
      await this.validate();
    }
  };

  validate = async () => {
    $("span[class^=span_f0]").hide();
    let countError = 0;

    if (!checkIdcard(this.state.cardId)) {
      $(".span_f0_cardId").css("display", "flex");
      countError++;
    }

    if (
      this.state.btn_user_choose &&
      (validator.isEmpty(this.state.aeCode) || this.state.aeCode.length < 4)
    ) {
      $(".span_f0_aeCode").css("display", "flex");
      countError++;
    }

    if (!validator.isMobilePhone(this.state.f0_mobile_phone, "th-TH")) {
      $(".span_f0_mobile_phone").css("display", "flex");
      countError++;
    }

    if (!validator.isEmail(this.state.f0_email)) {
      $(".span_f0_email").css("display", "flex");
      countError++;
    }

    if (!validator.isEmail(this.state.f0_email_1)) {
      $(".span_f0_email_1").css("display", "flex");
      countError++;
    }

    if (
      validator.isEmail(this.state.f0_email) &&
      validator.isEmail(this.state.f0_email_1)
    ) {
      if (this.state.f0_email !== this.state.f0_email_1) {
        $(".span_f0_email_1_1").css("display", "flex");
        countError++;
      }
    }
    return countError > 0 ? false : true;
  };

  goBack() {
    this.props.history.push("pdpa");
  }

  render() {
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          alt="forward"
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">ตรวจสอบข้อมูลส่วนตัว</div>
                    </div>

                    <div className="bg_content_white" style={{ paddingTop: 0 }}>
                      <div className={"title_row"}>ข้อมูลส่วนตัว</div>

                      <div className={"row_idcard"}>
                        <div style={{ fontSize: 18 }}>
                          เลขที่บัตรประจำตัวประชาชน *
                        </div>
                        <input
                          type="tel"
                          onMouseOut={this.handleMouse}
                          className="input_idcard input--textfield"
                          maxLength="13"
                          name="cardId"
                          value={this.state.cardId}
                          onChange={this.handleChange}
                          style={{
                            borderColor:
                              this.state.cardId !== "" ? "#003b66" : "#ccc",
                          }}
                        />

                        <span className="span_f0_cardId">
                          กรุณาระบุเลขที่บัตรประจำตัวประชาชน
                        </span>
                      </div>

                      <div className="title_row">
                        กรุณาระบุรหัสผู้แนะนำการลงทุน
                      </div>

                      <div className="row_idcard" style={{ border: "none" }}>
                        <a
                          className=""
                          onClick={this.Click_user_choose}
                          data-id="false"
                        >
                          <ButtonCustom
                            label="ไม่มี"
                            color={
                              this.state.btn_user_choose ? "unactive" : "active"
                            }
                            btnClass="long_btn_status_user"
                          />
                        </a>
                        <a
                          className=""
                          onClick={this.Click_user_choose}
                          data-id="true"
                        >
                          <ButtonCustom
                            label="มี"
                            color={
                              this.state.btn_user_choose ? "active" : "unactive"
                            }
                            btnClass="long_btn_status_user"
                          />
                        </a>
                      </div>
                      <div
                        className={"row_idcard"}
                        id="aeCode"
                        style={{
                          display: this.state.btn_user_choose ? "" : "none",
                          marginTop: 0,
                          marginBottom: 25,
                          paddingTop: 0,
                        }}
                      >
                        <div
                          style={{
                            fontSize: 18,
                          }}
                        >
                          AE CODE *
                        </div>
                        {/* <input
                  className="input_idcard"
                  name="aeCode"
                  value={this.state.aeCode}
                  onChange={this.handleChange}
                  placeholder=""
                  style={{ marginTop: 0 }}
                  maxLength="4"
                  style={{ borderColor: this.state.aeCode !== '' ? '#003b66' : '#ccc' }}
                ></input> */}
                        <IMaskInput
                          mask={/^[A-Za-z0-9]+$/}
                          value={this.state.aeCode}
                          unmask={true}
                          maxLength={4}
                          className="input_idcard"
                          name="aeCode"
                          style={{
                            textDecorationLine: "none",
                            borderColor:
                              this.state.aeCode !== "" ? "#003b66" : "#ccc",
                          }}
                          onAccept={this.handleIChange}
                        />
                        <span className="span_f0_aeCode">
                          กรุณาระบุ AE Code
                        </span>
                      </div>
                      <div className="title_row">ข้อมูลการติดต่อ</div>

                      <div className="row_idcard">
                        <div style={{ fontSize: 18 }}>
                          เบอร์โทรศัพท์มือถือ *
                        </div>
                        <input
                          type="tel"
                          className="input_idcard"
                          name="f0_mobile_phone"
                          maxLength="10"
                          value={this.state.f0_mobile_phone}
                          onChange={this.handleChange}
                          style={{
                            borderColor:
                              this.state.f0_mobile_phone !== ""
                                ? "#003b66"
                                : "#ccc",
                          }}
                        ></input>
                        <span className="span_f0_mobile_phone">
                          กรุณาระบุเบอร์โทรศัพท์มือถือ
                        </span>
                      </div>
                      <div className="row_idcard">
                        <div style={{ fontSize: 18 }}>อีเมล * (สำคัญ โปรดจดจำ)</div>
                        <input
                          className="input_idcard"
                          type="email"
                          name="f0_email"
                          value={this.state.f0_email}
                          onChange={this.handleChange}
                          style={{
                            borderColor:
                              this.state.f0_email !== "" ? "#003b66" : "#ccc",
                          }}
                        ></input>
                        <span className="span_f0_email">กรุณาระบุอีเมล</span>
                      </div>
                      <div className="row_idcard">
                        <div style={{ fontSize: 18 }}>ยืนยันอีเมล * </div>
                        <input
                          className="input_idcard"
                          type="email"
                          name="f0_email_1"
                          value={this.state.f0_email_1}
                          onChange={this.handleChange}
                          style={{
                            borderColor:
                              this.state.f0_email_1 !== "" ? "#003b66" : "#ccc",
                          }}
                        ></input>

                        <span className="span_f0_email_1">
                          กรุณาระบุยืนยันอีเมล
                        </span>
                        <span className="span_f0_email_1_1">
                          อีเมลไม่ตรงกัน โปรดตรวจสอบอีเมล
                        </span>
                      </div>

                      <ButtonCustom
                        label="ถัดไป"
                        color="blue"
                        btnClass="long_btn"
                        handle={this.handleSubmit}
                      />
                    </div>
                  </div>
                  <Loading status={this.state.status_popup}></Loading>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CheckUserInfo;
