/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import "./NdidOffineTC.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import axios from "axios";
import ReactIScroll from "react-iscroll";
import { popupErrorApi } from "../../components/helpers/Helpers";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loading from "../../components/loading/loading";
import { config } from "./../../constants";
import qs from "qs";
const _url = config.baseAPIURL03;
class NdidOffineTC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollTop: 0,
      color_bt: "gray",
      isDisabled: true,
      _posit: "",
      set_checkbox: false,
      read: false,
      iScrollOptions: {
        bounce: false,
        probeType: 2,
        mouseWheel: true,
        scrollbars: true,
        interactiveScrollbars: true,
        fadeScrollbars: true,
        scrollY: true,
      },
      termCondition: [],
      checkboxText: "",
      isDisconnected: false,
      btnOk: "ยอมรับ",
      btnCancel: "ปฏิเสธ",
      loading: false,
    };
    this.myMain = React.createRef();
    this.goBack = this.goBack.bind(this);
  }

  goBack(e) {
    e.preventDefault();
    this.props.history.push("/ndid_info");
  }

  popup = async (e) => {
    this.setState({ loading: true });
    const head_token = localStorage.getItem("token") || "";
    if (!head_token) {
      const MySwal = withReactContent(swal);
      MySwal.fire({
        customClass: {
          confirmButton: "long_btn_fix",
          cancelButton: "long_btn_fix",
        },
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title: '<div class="title_popup">เกิดข้อผิดพลาด</div>',
      }).then(function () {
        window.location = "/";
      });
      return;
    }
    axios
      .post(
        _url + "/form/signed_tnc/manp",
        qs.stringify({
          head_token,
          product_id: null,
          comfrimation: 0,
          code: "NDID",
        })
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.Data[0][0].IsSuccess === 0) {
          this.setState({ loading: false });
          if (res.data.Data[0][0].MsgDesc !== "") {
            popupErrorApi(res.data.Data[0][0].MsgDesc, "", true);
            return;
          } else {
            popupErrorApi(
              "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง",
              "เกิดข้อผิดพลาด",
              true
            );
            return;
          }
        } else {
          const MySwal = withReactContent(swal);
          MySwal.fire({
            customClass: {
              confirmButton: "long_btn_fix",
              cancelButton: "long_btn_fix",
            },
            imageUrl: "/images/ico_alert.svg",
            allowOutsideClick: false,
            imageWidth: 100,
            imageHeight: 100,
            confirmButtonColor: "#003b66",
            confirmButtonText: "กลับ",
            title:
              '<div class="title_popup">ขออภัย ท่านไม่สามารถเปิดบัญชีออนไลน์ได้</div>',
            html: '<div class="content_popup">กรุณาติดต่อ DAOL Contact Center<br> 0 2351 1800 หากต้องการดำเนินการต่อ กรุณาคลิกยอมรับ</div>',
          }).then(function () {
            // window.location = "summaryshow";
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        var mss = "";
        var title_mss = "";
        title_mss =
          error.response.data.Data[0][0]?.MsgDescTitle !== ""
            ? error.response.data.Data[0][0].MsgDescTitle
            : "เกิดข้อผิดพลาด";
        mss =
          error.response.data.Data[0][0].MsgDesc !== ""
            ? error.response.data.Data[0][0].MsgDesc
            : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        popupErrorApi(mss, title_mss, true);
        return;
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const head_token = localStorage.getItem("token") || "";
    this.setState({ loading: true });
    if (!head_token) {
      this.setState({ loading: false });
      return;
    }
    axios
      .post(
        _url + "/form/signed_tnc/manp",
        qs.stringify({
          head_token: head_token,
          product_id: null,
          comfrimation: 1,
          code: "NDID",
        })
      )
      .then((res) => {
        if (res.data.Data[0][0].IsSuccess === 0) {
          this.setState({ loading: false });
          if (res.data.Data[0][0].MsgDesc !== "") {
            popupErrorApi(res.data.Data[0][0].MsgDesc, "", true);
            return;
          } else {
            popupErrorApi(
              "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง",
              "เกิดข้อผิดพลาด",
              true
            );
            return;
          }
        } else {
          this.setState({ loading: false });
          localStorage.setItem("ndidOffline", true);
          this.props.history.push("/ndid_list");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        var mss = "";
        var title_mss = "";
        title_mss =
          error.response.data.Data[0][0]?.MsgDescTitle !== ""
            ? error.response.data.Data[0][0].MsgDescTitle
            : "เกิดข้อผิดพลาด";
        mss =
          error.response.data.Data[0][0].MsgDesc !== ""
            ? error.response.data.Data[0][0].MsgDesc
            : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        popupErrorApi(mss, title_mss, true);
        return;
      });
  };

  SubmitCheckbox = async (e) => {
    await this.setState({ set_checkbox: e.target.checked });
  };

  onScroll = async (e) => {
    //e.preventDefault();
    const h_scroll_out =
      document.getElementById("height_scroll_out").offsetHeight;
    const h_scroll_in =
      document.getElementById("height_scroll_in").offsetHeight;
    const scrollTop = Math.ceil(this.myMain.current.scrollTop);
    const posit = h_scroll_in - h_scroll_out - 5;
    // console.log(posit,scrollTop)
    await this.setState({
      scrollTop: scrollTop,
      _posit: posit,
    });

    if (this.state._posit < this.state.scrollTop) {
      await this.setState({
        color_bt: "green",
        isDisabled: false,
      });
      this.setState({ read: true });
    } else {
      await this.setState({
        color_bt: "gray",
        isDisabled: true,
      });
    }
  };
  slugify = (string) => {
    return string.replace(/(?:\\[rn])+/g, "");
  };
  componentDidMount() {
    const { btnOk } = this.state;
    this.setState({ loading: true });
    const header = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setTimeout(
      function () {
        //Start the timer
        axios
          .get(config.baseURL + "/api/consent?id=ktbst_tc_ndid", header)
          .then((res) => {
            const data = res.data.data;
            data.forEach((row) => {
              this.setState({
                loading: false,
                checkboxText: row.checkBox,
                termConText: row.detail,
                title_text: row.title,
                btnOk: row.okBtn,
                btnCancel: row.cancelBtn,
              });
            });
            const h_scroll_out =
              document.getElementById("height_scroll_out").offsetHeight;
            const h_scroll_in =
              document.getElementById("height_scroll_in").offsetHeight;

            if (h_scroll_out > h_scroll_in) {
              this.setState({
                color_bt: "green",
                isDisabled: false,
                read: true,
              });
            }
          })
          .catch((error) => {
            const h_scroll_out =
              document.getElementById("height_scroll_out").offsetHeight;
            const h_scroll_in =
              document.getElementById("height_scroll_in").offsetHeight;
            if (h_scroll_out > h_scroll_in) {
              this.setState({
                color_bt: "green",
                isDisabled: false,
              });
            }
            let mss = "";
            let title_mss = "";
            if (!!error.isAxiosError && !error.response) {
              title_mss = "เกิดข้อผิดพลาด";
              mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            } else {
              title_mss =
                error.response.data.Data !== undefined &&
                error.response.data.Data[0][0].MsgDescTitle !== ""
                  ? error.response.data.Data[0][0].MsgDescTitle
                  : "เกิดข้อผิดพลาด";
              mss =
                error.response.data.Data !== undefined &&
                error.response.data.Data[0][0].MsgDesc !== ""
                  ? error.response.data.Data[0][0].MsgDesc
                  : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            }
            popupErrorApi(mss, title_mss, true);
          });
      }.bind(this),
      1000
    );
  }
  onScrollEnd = async (iScrollInstance) => {
    if ((await iScrollInstance.y) <= iScrollInstance.maxScrollY) {
      this.setState({
        color_bt: "blue",
        isDisabled: false,
      });
      this.setState({ read: true });
    }
  };
  onScroll_ = async (iScrollInstance) => {
    if ((await iScrollInstance.y) === iScrollInstance.maxScrollY) {
      this.setState({
        color_bt: "blue",
        isDisabled: false,
      });
      this.setState({ read: true });
    }
  };

  render() {
    const iScroll = require("iscroll/build/iscroll-probe");
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <Loading status={this.state.loading} />
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">เงื่อนไขและข้อตกลง</div>
                    </div>

                    <div className="bg_content_white over_f_hide">
                      <div
                        className="in_bg_white default"
                        id="height_scroll_out"
                        style={{ bottom: isMobile ? 185 : 150 }}
                        // ref={this.myMain}
                        // onScroll={this.onScroll}
                      >
                        <ReactIScroll
                          id="my_scroll3"
                          iScroll={iScroll}
                          options={this.state.iScrollOptions}
                          onScrollEnd={this.onScrollEnd}
                          onScroll={this.onScroll_}
                        >
                          <div id="height_scroll_in">
                            <div className="text_title_head_termandcon">
                              {this.state.title_text}
                            </div>
                            <div
                              className="text_color_gray"
                              dangerouslySetInnerHTML={{
                                __html: this.state.termConText,
                              }}
                            />
                          </div>
                        </ReactIScroll>
                        {/* <div id="height_scroll_in">
                <div>
                  <div className="text_title_head_termandcon">
                    การให้ความยินยอมในการเปิดเผยข้อมูล ของการเชื่อมต่อ NDID
                  </div>
                  <div className="text_color_gray" dangerouslySetInnerHTML={{ __html: this.state.termConText }} />
                </div>
              </div> */}
                      </div>

                      <div
                        //className="under_fix_btn"
                        style={{
                          background: "#fff",
                          minHeight: isMobile ? "180px" : "150px",
                          bottom: 0,
                          position: "absolute",
                          justifyContent: "flex-end",
                          left: "21px",
                          right: "21px",
                        }}
                      >
                        <br />
                        {config.READ_CONSENT && (
                          <center
                            style={{
                              display: this.state.read ? "none" : "block",
                            }}
                          >
                            {config.READ_CONSENT}
                          </center>
                        )}
                        <br />
                        <div style={{ paddingTop: "2%" }}>
                          <label
                            className="container_border"
                            style={{
                              color: this.state.read ? "#003b66" : "#ccc",
                              fontSize: "18px",
                            }}
                          >
                            <div
                              style={{
                                color: this.state.read ? "#003b66" : "#ccc",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: this.state.checkboxText,
                              }}
                            />
                            <input
                              type="checkbox"
                              disabled={!this.state.read}
                              checked={this.state.set_checkbox}
                              onChange={(e) => this.SubmitCheckbox(e)}
                            />
                            <span className="checkmark"></span>
                          </label>
                          {this.state.btnCancel !== "" ? (
                            <ButtonCustom
                              handle={
                                this.state.set_checkbox ? this.popup : null
                              }
                              label={this.state.btnCancel}
                              color={this.state.set_checkbox ? "red" : "gray"}
                              btnClass="btn_small_left"
                            />
                          ) : (
                            <div></div>
                          )}
                          <ButtonCustom
                            handle={
                              this.state.set_checkbox ? this.handleSubmit : null
                            }
                            label={this.state.btnOk}
                            color={this.state.set_checkbox ? "green" : "gray"}
                            btnClass={
                              this.state.btnCancel !== ""
                                ? "btn_small_right"
                                : "long_btn_fix"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default NdidOffineTC;
