import React, { Component } from 'react';
import './SplashScreen.css';
import Slideshow from '../../components/slider/Slideshow';
import ButtonCustom from '../../components/button/ButtonCustom';
import { checkInternet, popupNetworkLost, download_ico_alert } from '../../components/helpers/Helpers';
import { config } from './../../constants';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
// import NetworkChecker from '../../components/HOC/NetworkChecker';
class SplashScreen extends Component {
  constructor(props) {
    super(props);
    const url = this.props.location.search;
    const params = queryString.parse(url);
    const aecode = params.aecode ? params.aecode : '';
    this.state = {
      isDisconnected: false,
      aecode: aecode,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // if(this.validateForm()){

    // }
    if (checkInternet()) {
      this.props.history.push('/login');
    } else {
      this.setState({
        isDisconnected: popupNetworkLost(this.state.isDisconnected),
      });
    }
    // this.props.history.push("/login");
  };

  async componentDidMount() {
    const cookies = new Cookies();
    let expires = new Date();
    expires.setTime(expires.getTime() + 10 * 60 * 1000);
    cookies.set('aecode', this.state.aecode, { path: '/', expires });
    // console.log(cookies.get('aecode'));
  }

  render() {
    const ArrsLide = [
      {
        id: 1,
        picture: '../../../images/splashscreen1.svg',
        title: 'สมัครกองทุนด้วยตัวคุณเอง',
        content: `stry standard dummy text ever since the 1500s,when an unknown printer took a galley of type and `,
        button: ``,
        icon: '',
      },
      {
        id: 2,
        picture: '../../../images/splashscreen2.svg',
        title: 'ติดตามข้อมูลได้หลากหลายช่องทาง',
        content: `Lorem Ipsum has been the industry standard
                dummy text ever since the 1500s,when an unknown printer took a galley of type and scrambled it to make a type specimen book`,
        button: `เริ่มใช้งาน`,
        icon: 'fa-chevron-right',
      },
    ];

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className="content">
        {/* <NetworkChecker isDisconnected={this.state.isDisconnected} /> */}
        <div className="bg_content_white over_f_hide">
          <div style={{ height: '100vh' }}>
            <Slideshow ArrsLide={ArrsLide}></Slideshow>
            <div className="fix_btn_welcome">
              <ButtonCustom handle={this.handleSubmit} label="เริ่มใช้งาน" color="blue" btnClass="bt_welcome" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SplashScreen;
