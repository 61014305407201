import React from "react";
import {
  Box,
  Typography,
  Avatar,
  FormControlLabel,
  Radio,
} from "@mui/material";

const oneLineEllipsis = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  "-webkit-line-clamp": "1",
  "-webkit-box-orient": "vertical",
  lineBreak: "anywhere",
};

const CardItem = ({ value, logo, name, appName }) => {
  return (
    <FormControlLabel
      value={value}
      control={<Radio sx={{ padding: 0 }} />}
      label={
        <Box
          className="bank-item"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box className="bank-logo" sx={{ margin: "0 10px 0 8px" }}>
            <Avatar
              alt={name}
              src={logo}
              variant="rounded"
              sx={{ width: 50, height: 50 }}
            />
          </Box>
          <Box className="bank-content">
            <Box>
              <Typography
                sx={{
                  lineHeight: "29px",
                  fontFamily: "DB Heavent",
                  fontSize: "24px",
                  color: "#003b66",
                  ...oneLineEllipsis,
                }}
              >
                {name}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  lineHeight: "29px",
                  fontFamily: "DB Heavent",
                  fontSize: "24px",
                  color: "#003b66",
                  ...oneLineEllipsis,
                }}
              >
                {appName}
              </Typography>
            </Box>
          </Box>
        </Box>
      }
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gab: "10px",
        padding: "12px 10px",
        border: "1px solid #A7A9AC",
        borderRadius: "10px",
        margin: "0 0 12px 0",
      }}
    />
  );
};

export default CardItem;
