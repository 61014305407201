import React, { Component } from 'react';
import './ATS_CALLBACK.css';
import ButtonCustom from '../../components/button/ButtonCustom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
class ATS_CALLBACK extends Component {
  constructor(props) {
    super(props);
  }

  handlesubmit = () => {
    window.close();
  };

  render() {
    library.add(fas);
    return (
      <div>
        <div className="content">
          <div className="top_main_back">
            <div className="main_back"></div>
            <div className="text_title">สมัครบริการหักบัญชีอัตโนมัติ</div>
          </div>

          <div className="bg_content_white over_f_hide">
            <div className="in_bg_white autoscroll" style={{ top: 0 }}>
              <div id="customHeaderTextForOne" className="content_icon_check">
                <div className="text_under_check" id="customTextForOne">
                  <h3>ระบบดำเนินการเสร็จสิ้น</h3>
                  ระบบได้รับข้อมูลจากธนาคารแล้ว กรุณากลับไปที่การ
                  <br />
                  สมัครเพื่อเปิดบัญชี KTBST ของท่าน
                </div>
              </div>
            </div>

            <div className="under_fix_btn" onClick={this.handlesubmit}>
              <ButtonCustom handle={this.handlesubmit} label={'ปิด'} color={'blue'} btnClass="long_btn" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ATS_CALLBACK;
