const idAddress = {
  country_name: '',
  district_name: '',
  f2_nric_address: '',
  f2_nric_building: '',
  f2_nric_country: '',
  f2_nric_district: '',
  f2_nric_floor: '',
  f2_nric_moo: '',
  f2_nric_no: '',
  f2_nric_province: '',
  f2_nric_road: '',
  f2_nric_subdistrict: '',
  f2_nric_zipcode: '',
  province_name: '',
  sub_district_name: '',
};

export default idAddress;
