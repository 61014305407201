export const AddressActionTypes = {
  setInitialData: 'SET_INITIAL_DATA',
};

export const setInitialAddressData = (values) => {
  return {
    type: AddressActionTypes.setInitialData,
    values,
  };
};
