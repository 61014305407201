import React, { Component } from "react";
import "./TcConfirm.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import iScroll from "iscroll";
import ReactIScroll from "react-iscroll";
import Loading from "../../components/loading/loading";
import {
  update_tracking,
  popupError,
  popupErrorApi,
} from "../../components/helpers/Helpers";
import { config } from "./../../constants";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";

const _url = config.baseAPIURL03;
class TcConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollTop: 0,
      color_bt: "gray",
      isDisabled: true,
      loading: false,
      loadingFinal: false,
      _posit: "",
      set_checkbox: false,
      read: false,
      iScrollOptions: {
        bounce: false,
        probeType: 2,
        mouseWheel: true,
        scrollbars: true,
        interactiveScrollbars: true,
        fadeScrollbars: true,
        scrollY: true,
      },
      html_text: ``,
      title_text: "",
      checkboxText: "",
      btnOk: "ยอมรับ",
      btnCancel: "ปฏิเสธ",
    };
    this.myMain = React.createRef();
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.push("summaryshow");
  }

  SubmitCheckbox = async (e) => {
    await this.setState({ set_checkbox: e.target.checked });
  };

  async componentDidMount() {
    const header = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .get(config.baseURL + "/api/consent?id=tcconfirm", header)
      .then((res) => {
        const data = res.data.data;
        data.forEach((row) => {
          this.setState({
            loading: false,
            checkboxText: row.checkBox,
            html_text: row.detail,
            title_text: row.title,
            btnOk: row.okBtn,
            btnCancel: row.cancelBtn,
          });
        });
        const h_scroll =
          document.getElementById("height_scroll_out").offsetHeight;
        const h_scroll2 =
          document.getElementById("height_scroll_in").offsetHeight;
        if (h_scroll > h_scroll2) {
          this.setState({
            color_bt: "green",
            isDisabled: false,
            read: true,
          });
        }
      })
      .catch((error) => {
        let mss = "";
        let title_mss = "";
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss =
            error.response.data.Data !== undefined &&
            error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data !== undefined &&
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
        popupErrorApi(mss, title_mss, true);
      });
  }
  popup = async (e) => {
    const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
    const head_token = HeaderManp ? HeaderManp.head_token : "";
    const body = {
      head_token: head_token,
      product_id: null,
      comfrimation: 0,
      code: "AGBR",
    };
    this.setState({ loading: true });
    try {
      axios
        .post(`${config.baseAPIURL03}/form/signed_tnc/manp`, body)
        .then(async (res) => {
          if (res.request.status === 200) {
            if (res?.data?.Data?.[0]?.[0]?.IsSuccess === 1) {
              this.setState({ loading: false });
              const MySwal = withReactContent(swal);
              MySwal.fire({
                customClass: {
                  confirmButton: "long_btn_fix",
                  cancelButton: "long_btn_fix",
                },
                imageUrl: "/images/ico_alert.svg",
                allowOutsideClick: false,
                imageWidth: 100,
                imageHeight: 100,
                confirmButtonColor: "#003b66",
                confirmButtonText: "กลับ",
                title:
                  '<div class="title_popup">ขออภัย ท่านไม่สามารถเปิดบัญชีออนไลน์ได้</div>',
                html: '<div class="content_popup">กรุณาติดต่อ DAOL Contact Center<br>0 2351 1800 หากต้องการดำเนินการต่อ กรุณาคลิกยอมรับ</div>',
              }).then(function () {
                // window.location = "summaryshow";
              });
            }
          }
        })
        .catch((e) => {
          if (!e.response) {
            this.setState({ loading: false });
            popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
          } else {
            this.setState({ loading: false });
            popupError(
              e.response.data?.Data?.[0]?.[0]?.MsgDesc,
              e.response.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
            );
          }
        });
    } catch (e) {
      if (!e.response) {
        this.setState({ loading: false });
        popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
      } else {
        this.setState({ loading: false });
        popupError(
          e.response.data?.Data?.[0]?.[0]?.MsgDesc,
          e.response.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
        );
      }
    }
  };

  handleSubmit = async (e) => {
    //to product consent
    const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
    const head_token = HeaderManp ? HeaderManp.head_token : "";
    const body = {
      head_token: head_token,
      product_id: null,
      comfrimation: 1,
      code: "AGBR",
    };
    this.setState({ loading: true });
    try {
      axios
        .post(`${config.baseAPIURL03}/form/signed_tnc/manp`, body)
        .then(async (res) => {
          if (res.request.status === 200) {
            this.setState({ loading: false });
            if (res?.data?.Data?.[0]?.[0]?.IsSuccess === 1) {
              this.props.history.push("/product_consent");
            } else {
              popupError(
                e.response.data?.Data?.[0]?.[0]?.MsgDesc,
                e.response.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
              );
            }
          }
        })
        .catch((e) => {
          if (!e.response) {
            this.setState({ loading: false });
            popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
          } else {
            this.setState({ loading: false });
            popupError(
              e.response.data?.Data?.[0]?.[0]?.MsgDesc,
              e.response.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
            );
          }
        });
    } catch (e) {
      if (!e.response) {
        this.setState({ loading: false });
        popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
      } else {
        this.setState({ loading: false });
        popupError(
          e.response.data?.Data?.[0]?.[0]?.MsgDesc,
          e.response.data?.Data?.[0]?.[0]?.MsgDesc.MsgDescTitle
        );
      }
    }
  };
  onScrollEnd = async (iScrollInstance) => {
    if ((await iScrollInstance.y) <= iScrollInstance.maxScrollY) {
      this.setState({
        color_bt: "blue",
        isDisabled: false,
      });
      this.setState({ read: true });
    }
  };
  onScroll_ = async (iScrollInstance) => {
    if ((await iScrollInstance.y) === iScrollInstance.maxScrollY) {
      this.setState({
        color_bt: "blue",
        isDisabled: false,
      });
      this.setState({ read: true });
    }
  };
  render() {
    const confirm = JSON.parse(localStorage.getItem("tcconfirm"))?.confirm;
    if (confirm === 1) {
      localStorage.setItem("tcconfirm", JSON.stringify({ confirm: 0 }));
      const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
      const head_token = HeaderManp.head_token;
      const atsDataUser = JSON.parse(localStorage.getItem("atsDataUser"))
        ? JSON.parse(localStorage.getItem("atsDataUser"))
        : {};
      const { bankCode } = atsDataUser;
      this.setState({ loadingFinal: true });
      const httpClient = axios.create();
      httpClient.defaults.timeout = 1000 * 60 * 10;
      httpClient
        .post(
          _url + `/form/finished`,
          JSON.stringify({
            head_token: head_token,
            comfrimation: 1,
            use_other_bank: bankCode === "Other" ? 1 : 0,
          }),
          { headers: { "content-type": "application/json" } },
          { timeout: 1000 * 1 * 1 }
        )
        .then((res) => {
          this.setState({ loadingFinal: false });
          this.setState({ loading: false });
          if (!res.data) {
            this.popup();
          }
          const MySwal = withReactContent(swal);
          MySwal.fire({
            customClass: {
              confirmButton: "long_btn_fix",
              cancelButton: "long_btn_fix",
            },
            imageUrl: "/images/ico_approve.svg",
            imageWidth: 100,
            imageHeight: 100,
            confirmButtonColor: "#003b66",
            confirmButtonText: "ตกลง",
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: '<div class="title_popup">การเปิดบัญชีของท่านสำเร็จ</div>',
            html:
              '<div class="content_popup">' + config.CONSENT_POPUP + "</div>",
          }).then(function () {
            // vm.props.history.push('login');
            window.location.href = config.WEB_LOCATION;
          });
        })
        .catch((error) => {
          if (!!error.isAxiosError && !error.response) {
            popupError(
              "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง",
              "เกิดข้อผิดพลาด"
            );
          } else if (error.response.data.Data[0][0].IsSuccess === 0) {
            let errorMessage =
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "เกิดข้อผิดพลาด";
            popupError(`${errorMessage}`, "เกิดข้อผิดพลาด");
          } else {
            popupError(
              "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง",
              "เกิดข้อผิดพลาด"
            );
          }
          this.setState({ loadingFinal: false });
        });
    }
    const iScroll = require("iscroll/build/iscroll-probe");
    return (
      <div
        className={isMobile ? "" : "body"}
        style={{
          backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
        }}
      >
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section className={isMobile ? "" : "desktop_content section-gap-full"}>
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <Loading status={this.state.loading}></Loading>
                  <Loading
                    status={this.state.loadingFinal}
                    textshow={true}
                  ></Loading>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          alt="forward"
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">เงื่อนไขและข้อตกลง</div>
                    </div>

                    <div className="bg_content_white over_f_hide">
                      <div
                        style={{ bottom: isMobile ? 185 : 150 }}
                        className="in_bg_white default"
                        id="height_scroll_out"
                      >
                        <ReactIScroll
                          id="my_scroll3"
                          iScroll={iScroll}
                          options={this.state.iScrollOptions}
                          onScrollEnd={this.onScrollEnd}
                          onScroll={this.onScroll_}
                        >
                          <div id="height_scroll_in">
                            <div
                              className="text_title_head_termandcon"
                              style={{ position: "relative" }}
                            >
                              เงื่อนไขและข้อตกลง
                              <br />
                              ในการเป็นตัวแทนนายหน้า
                            </div>
                            <div
                              className="text_color_gray"
                              dangerouslySetInnerHTML={{
                                __html: this.state.html_text,
                              }}
                            />
                          </div>
                        </ReactIScroll>

                        {/* <div id="height_scroll_in">
                <div className="text_title_head_termandcon" style={{ position: 'relative' }}>
                  เงื่อนไขและข้อตกลง
                  <br />
                  ในการเป็นตัวแทนนายหน้า
                </div>
                <div className="text_color_gray" dangerouslySetInnerHTML={{ __html: this.state.html_text }} />
              </div> */}
                      </div>

                      <div
                        //className="under_fix_btn"
                        style={{
                          background: "#fff",
                          minHeight: isMobile ? "180px" : "150px",
                          bottom: 0,
                          position: "absolute",
                          justifyContent: "flex-end",
                          left: "21px",
                          right: "21px",
                        }}
                      >
                        <div style={{ paddingTop: "2%" }}>
                          {config.READ_CONSENT && (
                            <center
                              style={{
                                display: this.state.read ? "none" : "block",
                              }}
                            >
                              {config.READ_CONSENT}
                            </center>
                          )}
                          <label
                            className="container_border"
                            style={{
                              color: this.state.read ? "#003b66" : "#ccc",
                              fontSize: "18px",
                            }}
                          >
                            <div
                              style={{
                                color: this.state.read ? "#003b66" : "#ccc",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: this.state.checkboxText,
                              }}
                            />
                            <input
                              type="checkbox"
                              disabled={!this.state.read}
                              checked={this.state.set_checkbox}
                              onChange={(e) => this.SubmitCheckbox(e)}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <div>
                            {this.state.btnCancel !== "" ? (
                              <ButtonCustom
                                handle={
                                  this.state.set_checkbox ? this.popup : null
                                }
                                label={this.state.btnCancel}
                                color={this.state.set_checkbox ? "red" : "gray"}
                                btnClass="btn_small_left"
                              />
                            ) : (
                              <div></div>
                            )}
                            <ButtonCustom
                              handle={
                                this.state.set_checkbox
                                  ? this.handleSubmit
                                  : null
                              }
                              label={this.state.btnOk}
                              color={this.state.set_checkbox ? "green" : "gray"}
                              btnClass={
                                this.state.btnCancel !== ""
                                  ? "btn_small_right"
                                  : "long_btn_fix"
                              }
                            />
                          </div>
                        </div>
                        {/* <label className="container_border" >ข้าพเจ้าได้อ่านและเข้าใจเนื้อหาข้างต้นอย่างครบถ้วนแล้ว
                            <input type="checkbox" defaultChecked={this.state.set_checkbox} onChange={ (e)=>this.SubmitCheckbox(e)} />
                            <span className="checkmark"></span>
                        </label>
                          <ButtonCustom handle={!this.state.isDisabled && this.state.set_checkbox ? this.handleSubmit:null} label='ยอมรับเงื่อนไขบริการ' color={!this.state.isDisabled && this.state.set_checkbox ? 'blue':'gray'} btnClass='long_btn' /> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default TcConfirm;
