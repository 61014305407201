import React, { Component } from 'react';
import ButtonCustom from '../button/ButtonCustom';

class SlideItem extends Component {
    render() {
        const {picture, title, content, button,icon} = this.props;
        // const isLoggedIn = button;
        let buttons;
        if (button) {
        buttons = <a href="/login" style={{"display":'block'}}>
            <ButtonCustom 
            label="เริ่มใช้งาน"
            color="blue"
            btnClass="bt_welcome"
         />
         </a>;
        } else {
            buttons = "";
        }
        return (
            <div className="each-slide">
                <div className='main_img_slide'>
                    <img src={picture}  className="item-img"  />   
                </div>
                                
                <h3 className="title_slide" >{title}</h3>
                <div className="content_slide" >{content}</div>                
                {/* {buttons}                */}
            </div>
        );
    }
}

export default SlideItem;