import React, { Component } from 'react';
import axios from 'axios';
import { config } from './../../constants';
import {
  checkEditPage,
  checkInternet,
  popupNetworkLost,
  download_ico_alert,
  popupErrorApi,
} from '../../components/helpers/Helpers';
const _url = config.baseAPIURL03;
class securities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentPopup: '',
    };
  }
  componentDidMount() {
    axios
      .get(_url + '/constant/securities')
      .then((res) => {
        this.setState({ contentPopup: res.data.Data.detail });
      })
      .catch((error) => {
        let mss = '';
        let title_mss = '';
        if (!!error.isAxiosError && !error.response) {
          title_mss = 'เกิดข้อผิดพลาด';
          mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
        } else {
          title_mss =
            error.response.data.Data !== undefined && error.response.data.Data[0][0].MsgDescTitle !== ''
              ? error.response.data.Data[0][0].MsgDescTitle
              : 'เกิดข้อผิดพลาด';
          mss =
            error.response.data.Data !== undefined && error.response.data.Data[0][0].MsgDesc !== ''
              ? error.response.data.Data[0][0].MsgDesc
              : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
        }
        popupErrorApi(mss, title_mss, true);
      });
  }
  render() {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: this.state.contentPopup }} />
      </div>
    );
  }
}

export default securities;
