const config = {
  baseURL: process.env.REACT_APP_API_URL_01,
  baseAPIURL01: process.env.REACT_APP_API_URL_01,
  baseAPIURL03: process.env.REACT_APP_API_URL_03,
  baseAPIURL06: process.env.REACT_APP_API_URL_06,
  baseAPIURL07: process.env.REACT_APP_API_URL_07,
  environment: process.env.REACT_APP_ENVIRONMENT,
  WEB_NAME: "DAOL INVESTMENT",
  WEB_LOCATION: "https://www.daolinvestment.co.th",
  READ_CONSENT: "เลื่อนลงเพื่ออ่านเพิ่มเติม",
  CONSENT_POPUP:
    "ระบบจะส่งข้อมูลการเปิดบัญชีของท่านไปยังอีเมล ที่ท่านได้ลงทะเบียนไว้ภายใน 24 ชั่วโมง หากท่านไม่ได้รับอีเมลยืนยันการเปิดบัญชีโปรดติดต่อ DAOL Contact Center 0 2351 1800",
};

export default config;
