import React, { Component } from 'react';
import ButtonCustom from '../../components/button/ButtonCustom';
class Clear_data extends Component {
  constructor(props) {
    super(props);

    const passDopa = JSON.parse(localStorage.getItem('passDopa'));
    const f0 = JSON.parse(localStorage.getItem('f0'));
    this.state = {
      passDopa: passDopa ? 'ผ่าน Dopa' : 'ไม่ผ่าน Dopa',
      f0: f0 ? f0 : '',
    };
  }
  clear_dopa = () => {
    localStorage.removeItem('passDopa');
    localStorage.removeItem('f0');
    localStorage.removeItem('fillDetail');
    localStorage.removeItem('checkuserinfo');
    this.setState({
      passDopa: 'ไม่ผ่าน Dopa',
      f0: 'เคลียร์ข้อมูลส่วนตัว',
    });
    // console.log(555);
  };
  componentDidMount() {
    // const passDopa = JSON.parse(localStorage.getItem('passDopa'));
  }
  render() {
    const passDopa = this.state.passDopa;
    const f0 = JSON.stringify(this.state.f0);
    // const f0 = this.state.f0;
    return (
      <div className="page_login" style={{ backgroundImage: 'url(../../../images/bg-login.png)' }}>
        <div>
          {passDopa ? passDopa : ''}
          <br></br>
          {f0 ? f0 : 'เคลียร์ข้อมูลส่วนตัว'}
        </div>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            // "bottom": "75px",
          }}
        >
          <ButtonCustom handle={this.clear_dopa} label="clear DOPA" color="blue" btnClass="bt_open_acc"></ButtonCustom>

          <ButtonCustom label="---" color="white" btnClass="bt_login"></ButtonCustom>

          <ButtonCustom
            label="--"
            color="blueSky"
            btnClass="bt_forget"
            iconDirection="L"
            icon="question-circle"
          ></ButtonCustom>
        </div>
      </div>
    );
  }
}

export default Clear_data;
