const address = {
  countryName: '',
  countryID: '',
  provinceName: '',
  provinceID: '',
  districtName: '',
  districtID: '',
  subDistrictName: '',
  subDistrictID: '',
  zipcode: '',
  no: '',
  moo: '',
  building: '',
  floor: '',
  road: '',
  addressFull: '',
};

export default address;
