import React, { Component } from "react";
import "./PreRegis.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
  checkInternet,
  popupNetworkLost,
  download_ico_alert,
} from "../../components/helpers/Helpers";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
class PreRegis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisconnected: false,
    };
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.push("/index.html");
    // splashscreenoa
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // if (checkInternet()) {
    this.props.history.push("/termandcon");
    // } else {
    //   this.setState({
    //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
    //   });
    // }
  };

  async componentDidMount() {
    // await download_ico_alert();
  }

  render() {
    library.add(fas);
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          alt="forward"
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">
                        เตรียมความพร้อมก่อนเปิดบัญชี
                      </div>
                    </div>

                    <div
                      className="bg_content_white over_f_hide"
                      style={{ paddingTop: 0 }}
                    >
                      <div
                        className="in_bg_white autoscroll"
                        style={{
                          bottom: 90,
                          top: 0,
                          left: 0,
                          right: 0,
                          padding: "24px 21px 0 21px",
                        }}
                      >
                        <div
                          className="text_title_head"
                          style={{ position: "relative" }}
                        >
                          สิ่งที่ต้องเตรียมก่อนเปิดบัญชี มีข้อมูลดังนี้
                        </div>
                        <div className="row_prereig">
                          <div>
                            • <span>บัตรประจำตัวประชาชน</span>
                          </div>
                          <div>
                            •{" "}
                            <span>
                              บัญชีธนาคารเพื่อสมัครบริการหักบัญชีอัตโนมัติ (ATS) สำหรับหักเงินค่าซื้อ รับเงินปันผล และรับเงินค่าขายคืนหน่วยลงทุน
                            </span>
                          </div>
                          <div>
                            • <span>มีอีเมลและเบอร์โทรศัพท์มือถือ</span>
                          </div>
                          <div>
                            •{" "}
                            <span>
                              กรุณาใช้อุปกรณ์ที่รองรับการถ่ายภาพได้ อาทิ
                              โทรศัพท์มือถือ แท็บเล็ต
                              และคอมพิวเตอร์ที่มีกล้องถ่ายภาพ
                              เนื่องจากต้องมีการถ่ายภาพในขณะทำการเปิดบัญชี
                            </span>
                          </div>
                        </div>
                        <hr className="hr_prereig" />
                        <div
                          className="text_title_head"
                          style={{ position: "relative" }}
                        >
                          คุณสมบัติผู้ขอรับบริการ
                        </div>
                        <div className="row_prereig">
                          <div>
                            • <span>สัญชาติไทย และอายุ 20 ปีขึ้นไป</span>
                          </div>
                          <div>
                            •{" "}
                            <span>
                              ไม่เป็นบุคคลอเมริกันที่กําหนดภายใต้ FATCA
                            </span>
                          </div>
                          <div>
                            •{" "}
                            <span>
                              ไม่เป็นบุคคลที่กฎหมายห้ามมิให้ทำธุรกรรมกับบริษัท
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="under_fix_btn">
                        <ButtonCustom
                          handle={this.handleSubmit}
                          label="เริ่มสมัคร"
                          color="blue"
                          btnClass="long_btn_fix"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PreRegis;
