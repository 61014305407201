import React, { Component } from "react";
import Lottie from "react-lottie";
import * as animationData from "./loading.json";
import "./loading.css";

class loading extends Component {
  constructor(props) {
    super(props);
    this.state = { isStopped: false, isPaused: false };
  }
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div
        className="main_popup_loading"
        style={{ display: this.props.status ? "block" : "none" }}
      >
        <img
          src="/images/ico_alert.svg"
          style={{ display: "none" }}
          alt=""
          className="ico_alert"
        />
        <div
          className="background_opacity_loading"
          onClick={this.hide_bg}
        ></div>
        <div className="content_loading">
          <Lottie
            options={defaultOptions}
            height={this.props.textshow ? 120 : 70}
            width={this.props.textshow ? 120 : 70}
            isStopped={this.state.isStopped}
            isPaused={this.state.isPaused}
          />
          <div
            style={{
              color: "#fff",
              fontSize: 36,
              fontWeight: "normal",
              display: this.props.textshow ? "block" : "none",
            }}
          >
            ระบบกำลังดำเนินการ
            <br />
            กรุณาอย่าปิดหน้าจอ
          </div>
        </div>
      </div>
    );
  }
}

export default loading;
