import React from "react";
import { Typography } from "@mui/material";
import CardItem from "./CardItem";

const CardList = ({ id, label, data, labelStyle }) => {
  return (
    <>
      <Typography
        className="font-weight-bold"
        sx={{
          fontFamily: "DB Heavent Med",
          lineHeight: "26px",
          marginBottom: "12px",
          fontSize: "22px",
          color: "#003b66",
          ...labelStyle,
        }}
      >
        {label}
      </Typography>
      {data.map((item) => {
        return <CardItem key={item.name} {...item} />;
      })}
    </>
  );
};

export default CardList;
