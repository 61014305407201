import React, { Component } from 'react';

class ForgetPassword extends Component {
    render() {
        return (
            <div>
                <h1>Forget Password</h1>
            </div>
        );
    }
}

export default ForgetPassword;