import React, { Component } from 'react';
import ButtonCustom from '../../components/button/ButtonCustom';
import './SnapIdCardOk.css';
class SanpIdCardOk extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);

    }
    goBack() {
        this.props.history.goBack();
    }
    SubmitPage = (e) => {
        e.preventdefault();
        this.props.history.push('/idc_detail');

    }
    render() {
        return (
            <div className="content">
                <div className='top_main_back'>
                    <div className="main_back" onClick={this.goBack}>

                        <img src='/images/angle-arrow-left.svg' alt="forward" style={{ marginLeft: '3px' }} className='arrow_back' />
                    </div>
                    <div className="text_title">การยืนยันตัวตน</div>
                </div>

                <div className="bg_content_white over_f_hide">
                    <div
                        className='in_bg_white'
                        style={{ top: 0, left: 0, right: 0, padding: '0 21px' }}
                    >
                        <div className="DivDetailTitleSnapIdOk" style={{ marginTop: 24 }}>ถ่ายรูปบัตรประจำตัวประชาชน <span style={{ textDecoration: 'Underline' }}>ด้านหน้า</span></div>
                        <div className="DivFrameSnapOkPic">
                            <div className='DivFrameSnapOkPicContent'>
                                <img src='/images/btn_edit.png' alt="btn_edit" className="BtnEditSnap" />
                                <div className="main_img_idcard">
                                    <img src='/images/id_card_front.png' alt="id_card_front" className="DivFrameSnapOk" />
                                    <img src='/images/watermask.png' alt="watermask" className="DivFrameWaterMask" />
                                </div>
                            </div>

                        </div>

                        <div className="DivDetailTitleSnapIdOk">ถ่ายรูปบัตรประจำตัวประชาชน <span style={{ textDecoration: 'Underline' }}>ด้านหลัง</span></div>
                        <div className="DivFrameSnapOkPic">
                            <div className='DivFrameSnapOkPicContent'>
                                <img src='/images/btn_edit.png' alt="edit" className="BtnEditSnap" />
                                <div className="main_img_idcard">
                                    <img src='/images/id_card_back.png' alt="id_card_back" className="DivFrameSnapOk" />
                                    <img src='/images/watermask.png' alt="watermask" className="DivFrameWaterMask" />
                                </div>
                            </div>
                        </div>

                        <span style={{ color: 'red', fontSize: 16, fontWeight: "normal" }}>ข้อมูลนี้ใช้สำหรับในการสมัครเพื่อเปิดพอร์ตกับ KTBST เท่านั้น</span>
                    </div>

                    <div className='under_fix_btn'>
                        <ButtonCustom handle={this.SubmitPage} label="ถัดไป" color="blue" btnClass="long_btn"></ButtonCustom>
                    </div>


                </div>

            </div>
        );
    }
}

export default SanpIdCardOk;