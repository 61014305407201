import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import "./KtbstBrowserSuitable.css";
import Loading from "../../components/loading/loading";
import ButtonCustom from "../../components/button/ButtonCustom";
class KtbstBrowserSuitable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      content_li: [
        { id: 1, text: "เปิดบัญชีลงทุนง่ายๆ ด้วยช่องทางออนไลน์" },
        { id: 2, text: "เชื่อมต่อบริการมากมายจาก DAOL" },
        { id: 3, text: "ตรวจสอบความเคลื่อนไหวครบจบที่เดียว" },
      ],
    };
  }

  render() {
    return (
      <div
        className={isMobile ? "" : "body"}
        style={{
          backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
        }}
      >
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full browser"}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <div className={"head-left"}>เบราว์เซอร์แนะนำ</div>
                <p className={"banner-left-p"}>
                  พบกับการใช้งานที่ราบรื่น และ
                  เต็มประสิทธิ์ภาพด้วยการใช้เบราว์เซอร์ที่เราแนะนำ
                  สามารถดาวน์โหลดผ่านปุ่ม Download Chrome หรือ Download Firefox
                </p>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  {/* <NetworkChecker isDisconnected={this.state.isDisconnected} /> */}
                  <Loading status={this.state.loading}></Loading>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back"></div>
                      <div className="text_title">การแนะนำเบราว์เซอร์</div>
                    </div>
                    <div className="bg_content_white over_f_hide">
                      <div
                        className="in_bg_white autoscroll"
                        style={{
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 170,
                          padding: "24px 21px 0px",
                        }}
                      >
                        <img
                          src="/images/ico_browser.svg"
                          alt=""
                          className="img_browser"
                        />

                        <div className="title_browser">
                          เพื่อประสบการณ์ที่ดี ในการใช้งาน DAOL Life
                          บนเบราว์เซอร์ขอให้ท่านติดตั้งเบราว์เซอร์ Chrome หรือ
                          Firefox Version ล่าสุด
                        </div>
                        <div className={"flex_browser"}>
                          <div className={"main_list_browser"}>
                            {this.state.content_li.map((item, i) => {
                              return (
                                <div key={i} className="list_browser">
                                  <span>•</span> <span>{item.text}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="browser_fix_btn">
                        {/* <ButtonCustom
                          handle={this.handleSubmit}
                          label="ถัดไป"
                          color="blue"
                          btnClass="long_btn"
                        /> */}
                        <div className={"btn_browser"}>
                          <img src={"/images/icon_chrome.png"} />{" "}
                          <span style={{ marginLeft: 8 }}>
                            ดาวน์โหลด chrome
                          </span>
                        </div>
                        <div className={"btn_browser"}>
                          <img src={"/images/ico_Firefox_mobile.png"} />
                          ดาวน์โหลด Firefox
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default KtbstBrowserSuitable;
