import React, { Component } from "react";
import "./SnapIdCard.css";
import img from "./snapidcard_trans.png";
import ButtonCustom from "../../components/button/ButtonCustom";
import $ from "jquery";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { isMobile } from "react-device-detect";
import { createCanvas, loadImage } from "canvas";
import {
  checkEditPage,
  popupErrorApi,
  checkEditData,
} from "../../components/helpers/Helpers";
import to from "await-to-js";
import ktbsApi from "./../../api";
import withReactContent from "sweetalert2-react-content";
import Loading from "../../components/loading/loading";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
import localForage from "localforage";

class SnapIdCard extends Component {
  constructor(props) {
    super(props);
    const comebackWithOtp = JSON.parse(localStorage.getItem("comebackWithOtp"));
    this.state = {
      comebackWithOtp: comebackWithOtp ? true : false,
      status_popup: false,
      nextButtonColor: "gray",
      isDisconnected: false,
      selectedUploadImage: null,
    };
    this.goBack = this.goBack.bind(this);
    this.fileuploadRef = React.createRef();
  }

  goBack() {
    if (checkEditPage()) {
      return;
    }
    if (this.state.comebackWithOtp) {
      checkEditData();
    }
    this.props.history.push("/checkuserinfo");
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    // console.log(checkInternet());
    // if (checkInternet()) {
    if (
      localStorage.getItem("photo1") === null ||
      localStorage.getItem("photo1") === "null"
      //|| localStorage.getItem("photo2") === null ||
      //  localStorage.getItem("photo2") === "null"
    ) {
      popupErrorApi("กรุณาทำรายการใหม่", "เกิดข้อผิดพลาด", true);
    } else {
      if (this.state.comebackWithOtp) {
        checkEditData();
      }
      var str = window.location.href;
      let re = /edit/;
      const back_to_edit = re.test(str);
      // return
      if (back_to_edit) {
        this.setState({ status_popup: true });
        let error;
        let result;
        let mss = "";
        let title_mss = "";
        const MySwal = withReactContent(Swal);
        const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
        const head_token = HeaderManp.head_token;
        const frontIDCardParam = {
          head_token,
          id: "0",
          image_base64: localStorage.getItem("photo1"),
        };

        [error, result] = await to(
          ktbsApi.post("form/document/upload/idcard/front", frontIDCardParam)
        );
        if (
          error ||
          !result ||
          !result.Data ||
          !result.Data[0] ||
          !result.Data[0][0] ||
          result.Data[0][0].IsSuccess !== 1
        ) {
          this.setState({ status_popup: false });
          if (result.Data) {
            mss =
              result.Data[0][0].MsgDesc !== ""
                ? result.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            title_mss =
              result.Data[0][0].MsgDescTitle !== ""
                ? result.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
          } else if (error) {
            if (!!error.isAxiosError && !error.response) {
              mss = "เกิดข้อผิดพลาดร้ายแรงในการนำส่งข้อมูลเข้าสู่ระบบ";
              title_mss = "ไม่สามารถทำการอัปโหลดได้";
            } else {
              title_mss =
                error.response.data.Data[0][0].MsgDescTitle !== ""
                  ? error.response.data.Data[0][0].MsgDescTitle
                  : "ไม่สามารถทำการอัปโหลดได้";
              mss =
                error.response.data.Data[0][0].MsgDesc !== ""
                  ? error.response.data.Data[0][0].MsgDesc
                  : "เกิดข้อผิดพลาดร้ายแรงในการนำส่งข้อมูลเข้าสู่ระบบ";
            }
          } else {
            mss = "เกิดข้อผิดพลาดร้ายแรงในการนำส่งข้อมูลเข้าสู่ระบบ";
            title_mss = "ไม่สามารถทำการอัปโหลดได้";
          }
          MySwal.fire({
            imageUrl: "/images/ico_alert.svg",
            imageWidth: 100,
            imageHeight: 100,
            customClass: {
              confirmButton: "long_btn_fix",
            },
            confirmButtonColor: "#003b66",
            confirmButtonText: "กลับ",
            title: '<div class="title_popup">' + title_mss + "</div>",
            html: '<div class="content_popup">' + mss + "</div>",
          });
          return;
        }

        /*const backIDCardParam = {
          head_token,
          id: "0",
          image_base64: localStorage.getItem("photo2"),
        };
        [error, result] = await to(
          ktbsApi.post("form/document/upload/idcard/back", backIDCardParam)
        );
        if (
          error ||
          !result ||
          !result.Data ||
          !result.Data[0] ||
          !result.Data[0][0] ||
          result.Data[0][0].IsSuccess !== 1
        ) {
          this.setState({ status_popup: false });
          if (result.Data) {
            mss =
              result.Data[0][0].MsgDesc !== ""
                ? result.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            title_mss =
              result.Data[0][0].MsgDescTitle !== ""
                ? result.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
          } else if (error) {
            if (!!error.isAxiosError && !error.response) {
              mss = "เกิดข้อผิดพลาดร้ายแรงในการนำส่งข้อมูลเข้าสู่ระบบ";
              title_mss = "ไม่สามารถทำการอัปโหลดได้";
            } else {
              title_mss =
                error.response.data.Data[0][0].MsgDescTitle !== ""
                  ? error.response.data.Data[0][0].MsgDescTitle
                  : "ไม่สามารถทำการอัปโหลดได้";
              mss =
                error.response.data.Data[0][0].MsgDesc !== ""
                  ? error.response.data.Data[0][0].MsgDesc
                  : "เกิดข้อผิดพลาดร้ายแรงในการนำส่งข้อมูลเข้าสู่ระบบ";
            }
          } else {
            mss = "เกิดข้อผิดพลาดร้ายแรงในการนำส่งข้อมูลเข้าสู่ระบบ";
            title_mss = "ไม่สามารถทำการอัปโหลดได้";
          }
          MySwal.fire({
            imageUrl: "/images/ico_alert.svg",
            imageWidth: 100,
            imageHeight: 100,
            customClass: {
              confirmButton: "long_btn_fix",
            },
            confirmButtonColor: "#003b66",
            confirmButtonText: "กลับ",
            title: '<div class="title_popup">' + title_mss + "</div>",
            html: '<div class="content_popup">' + mss + "</div>",
          });
          return;
        }*/
        await this.props.history.push("/summaryshow");
        localStorage.setItem("editPage", "SnapIdCard");
      } else {
        await this.props.history.push("/idc_detail");
      }
    }
    // } else {
    //   await this.setState({
    //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
    //   });
    // }
  };
  componentDidMount() {
    // download_ico_alert();
    var x = document.getElementsByClassName("DivDetailTitleSnapId");
    if (
      localStorage.getItem("photo1") === null ||
      localStorage.getItem("photo1") === "null"
    ) {
      // x[0].classList.add('red');
    } else {
      // x[0].classList.remove('red');
      this.drawImg1();
    }
    /*if (
      localStorage.getItem("photo2") === null ||
      localStorage.getItem("photo2") === "null"
    ) {
      // x[1].classList.add('red');
    } else {
      // x[1].classList.remove('red');
      this.drawImg2();
    }*/

    if (
      localStorage.getItem("photo1") === null ||
      localStorage.getItem("photo1") === "null"
      //||localStorage.getItem("photo2") === null ||
      //  localStorage.getItem("photo2") === "null"
    ) {
      this.setState({ nextButtonColor: "gray" });
    } else {
      this.setState({ nextButtonColor: "blue" });
    }
  }

  handleClickOpenSnapCardID(e, imageName) {
    e.preventDefault();
    // if (checkInternet()) {
    // if (isMobile) {
    //   const imageURLMapping = {
    //     photo1: checkEditPage() ? "/SnapFrontId/edit" : "/SnapFrontId",
    //     photo2: checkEditPage() ? "/SnapBackId/edit" : "/SnapBackId",
    //   };

    //   if (!imageURLMapping[imageName]) {
    //     return;
    //   }

    //   window.location = imageURLMapping[imageName];
    //   return;
    // }

    this.fileuploadRef.current.value = null;
    this.setState({ selectedUploadImage: imageName });
    this.fileuploadRef.current.click();
    // } else {
    //   popupNetworkLost(true);
    // }
  }

  handleSelectedFile = () => {
    const file = this.fileuploadRef.current.files[0];
    if (!file) {
      return;
    }
    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 10) {
      Swal.fire({
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        customClass: {
          confirmButton: "long_btn_fix",
          cancelButton: "long_btn_fix",
        },
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title:
          '<div class="title_popup">ไฟล์อัปโหลด<br>มีขนาดเกินขนาดที่กำหนด</div>',
        html: '<div class="content_popup">ไฟล์ที่อัปโหลดจะต้องมีขนาดไม่เกิน 10 MB</div>',
      });
      return;
    }

    const reader = new FileReader();
    const { selectedUploadImage } = this.state;
    const vm = this;
    reader.addEventListener(
      "load",
      async function () {
        const imgSrc = reader.result;

        const canvas = createCanvas(800, 509);
        const ctx = canvas.getContext("2d");
        await loadImage(imgSrc).then(async (image) => {
          let maxWidth = 800;
          let maxHeight = 509;
          let width = image.width;
          let height = image.height;
          let ratio = 0;
          if (width > maxWidth) {
            ratio = maxWidth / width;
            width = Math.floor(maxWidth);
            height = Math.floor(height * ratio);
          }
          if (height > maxHeight) {
            ratio = maxHeight / height;
            width = Math.floor(width * ratio);
            height = Math.floor(maxHeight);
          }
          let x = (canvas.width - width) * 0.5;
          let y = (canvas.height - height) * 0.5;
          // console.log(width, height);
          await ctx.drawImage(image, x, y, width, height);

          // await vm.setState({
          //   bankUploadImg: canvas.toDataURL(),
          // });

          await localForage
            .setItem("selectedUploadImageRow", canvas.toDataURL())
            .then(async function (value) {
              // await vm.setState({ selectedUploadImage: canvas.toDataURL() });
              await localStorage.setItem(
                selectedUploadImage,
                canvas.toDataURL()
              );
              if (selectedUploadImage === "photo1") {
                vm.drawImg1();
              }/* else if (selectedUploadImage === "photo2") {
                vm.drawImg2();
              }*/
            })
            .catch(function (err) {
              //console.log(err);
            });
        });

        // const html =
        //   "<img style='width:100%;display:block;' src='" + reader.result + "'/><div class='bg_signed'></div>";

        // if (selectedUploadImage === "photo1") {
        // $('#idPhoto1').html(html);
        // vm.drawImg1();
        // } else if (selectedUploadImage === "photo2") {
        // $('#idPhoto2').html(html);
        // vm.drawImg2();
        // }

        let nextButtonColor = "gray";

        if (
          localStorage.getItem("photo1") &&
          localStorage.getItem("photo1") !== "null"
          //&&localStorage.getItem("photo2") &&
          // localStorage.getItem("photo2") !== "null"
        ) {
          nextButtonColor = "blue";
        }
        vm.setState({
          selectedUploadImage: null,
          nextButtonColor,
        });
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  drawImg1() {
    const canvas = createCanvas(800, 508);
    const ctx = canvas.getContext("2d");
    const imgMark = new Image();
    imgMark.src = "/images/waterMark2.png?v=1";
    loadImage(localStorage.getItem("photo1")).then(async (image) => {
      await this.setState({ status_popup: true });
      // alert(image.width + 'x' + image.height);
      await loadImage("/images/waterMark2.png?v=1");
      ctx.drawImage(image, 0, 0, 800, 508);
      if (image.width !== 800 || image.height !== 508) {
        ctx.drawImage(imgMark, 0, 0, 800, 508);
      }
      $("#idPhoto1").html(
        "<img style='width:100%;display:block;border-radius:15px;' src='" +
        canvas.toDataURL() +
        "'/>"
      );
      await localStorage.setItem("photo1", canvas.toDataURL());
      await this.setState({ status_popup: false });
    });
  }

  /*drawImg2() {
    const canvas = createCanvas(800, 508);
    const ctx = canvas.getContext("2d");
    const imgMark = new Image();
    imgMark.src = "/images/waterMark2.png";
    loadImage(localStorage.getItem("photo2")).then(async (image) => {
      await this.setState({ status_popup: true });
      // alert(image.width + 'x' + image.height);
      await loadImage("/images/waterMark2.png");
      ctx.drawImage(image, 0, 0, 800, 508);
      if (image.width !== 800 || image.height !== 508) {
        ctx.drawImage(imgMark, 0, 0, 800, 508);
      }

      $("#idPhoto2").html(
        "<img style='width:100%;display:block;border-radius:15px;' src='" +
          canvas.toDataURL() +
          "'/>"
      );
      await localStorage.setItem("photo2", canvas.toDataURL());
      await this.setState({ status_popup: false });
    });
  }*/

  async saveData() { }

  render() {
    library.add(fas);
    const nextButtonColor = this.state.nextButtonColor;
    const photo1 =
      localStorage.getItem("photo1") &&
        localStorage.getItem("photo1") !== "null"
        ? localStorage.getItem("photo1")
        : "";
    /*  const photo2 =
        localStorage.getItem("photo2") &&
        localStorage.getItem("photo2") !== "null"
          ? localStorage.getItem("photo2")
          : "";*/

    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div className="content">
                  <Loading status={this.state.status_popup} />
                  <div className="top_main_back">
                    <div onClick={this.goBack} className="main_back">
                      <img
                        src={
                          isMobile
                            ? "/images/angle-arrow-left.svg"
                            : "/images/angle-arrow-left-blue.svg"
                        }
                        style={{ display: checkEditPage() ? "none" : "block" }}
                        className="arrow_back"
                        alt=""
                      />
                    </div>

                    <div className="text_title">การยืนยันตัวตน</div>
                  </div>

                  <div className="bg_content_white over_f_hide">
                    <div
                      className="in_bg_white autoscroll photo"
                      style={{
                        top: 0,
                        left: 0,
                        right: 0,
                        padding: "21px 34px 0px 21px",
                        textAlign: "center",
                      }}
                    >
                      <div className="DivDetailTitleSnapId">
                        ภาพถ่ายบัตรประจำตัวประชาชน{" "}
                        <span style={{ textDecoration: "Underline" }}>
                          ด้านหน้า{" "}
                        </span>
                        *
                      </div>
                      <a
                        style={{ display: "block" }}
                        onClick={(e) =>
                          this.handleClickOpenSnapCardID(e, "photo1")
                        }
                      >
                        <div className="DivFrameSnap" id="idPhoto1">
                          <img style={{ width: "100%" }} src={img} />
                          <div className="Div_ico_image_add">
                            <div className="main_camera">
                              <div className="row_add_camera">
                                <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                                <FontAwesomeIcon icon="camera"></FontAwesomeIcon>
                              </div>
                              <span>เพิ่มรูป</span>
                            </div>
                          </div>
                        </div>
                      </a>

                      {/*<div className="DivDetailTitleSnapId">
                        ภาพถ่ายบัตรประจำตัวประชาชน{" "}
                        <span style={{ textDecoration: "Underline" }}>
                          ด้านหลัง{" "}
                        </span>
                        *
                      </div>
                      <a
                        style={{ display: "block" }}
                        onClick={(e) =>
                          this.handleClickOpenSnapCardID(e, "photo2")
                        }
                      >
                        <div className="DivFrameSnap" id="idPhoto2">
                          <img style={{ width: "100%" }} src={img} />
                          <div className="Div_ico_image_add">
                            <div className="main_camera">
                              <div className="row_add_camera">
                                <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                                <FontAwesomeIcon icon="camera"></FontAwesomeIcon>
                              </div>
                              <span>เพิ่มรูป</span>
                            </div>
                          </div>
                        </div>
                      </a>*/}
                      <span style={{ color: "red", fontSize: 17 }}>
                        {/* ข้อมูลนี้ใช้สำหรับในการสมัครเพื่อเปิดพอร์ตกับ KTBST เท่านั้น */}
                      </span>
                    </div>
                    <div className="under_fix_btn">
                      <ButtonCustom
                        handle={this.handleSubmit}
                        label={checkEditPage() ? "บันทึกการแก้ไข" : "ถัดไป"}
                        color={nextButtonColor}
                        btnClass="long_btn"
                      ></ButtonCustom>
                    </div>
                  </div>

                  <input
                    type="file"
                    ref={this.fileuploadRef}
                    style={{ display: "none" }}
                    accept=".jpg, .png"
                    onChange={this.handleSelectedFile}
                  />
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default SnapIdCard;
