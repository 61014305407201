/* eslint-disable jsx-a11y/alt-text */
import React, { Component, useState } from "react";
import queryString from "query-string";
import qs from "qs";
import validator from "validator";
import "./KtbstOTP.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import axios from "axios";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { popupError, popupErrorApi } from "../../components/helpers/Helpers";
import to from "await-to-js";
import ktbsApi from "./../../api";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert2";
import Loading from "../../components/loading/loading";
import { config } from "./../../constants";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
const _url = config.baseAPIURL03;
const MySwal = withReactContent(swal);
var setInt;
class KtbstOTP extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    const url = this.props.location.search;
    const params = queryString.parse(url);
    const phone_number = params.phone_number;
    const checkuserinfo = JSON.parse(localStorage.getItem("checkuserinfo"));
    if (checkuserinfo === null) {
      window.location = "/idc_detail";
    }
    this.state = {
      phone_number: checkuserinfo.f0_mobile_phone,
      timeCountDown: "00:00",
      referenceNo: "",
      token_otp: "",
      btn_set_show: false,
      loading: false,
      value_otp: "",
      isDisconnected: false,
    };
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    clearInterval(setInt);
    window.clearInterval(setInt);
    // this.props.history.push("/fatca");
    this.props.history.push("/crs_consent");
  }

  async sendSMS(phone_number) {
    var vm = this;
    vm.setState({ loading: true });
    var now = new Date().getTime();
    var ua = navigator.userAgent;
    // console.log(now + ua);
    let postData = {
      phone_number: phone_number,
      session: now + " : " + ua,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(`${config.baseAPIURL01}/sms/send`, postData, axiosConfig)
      .then((res) => {
         // console.log('RESPONSE RECEIVED: ', res);
        let reference = res.data.reference;
        let token_otp = res.data.token_otp;

        if (this._isMounted) {
          this.startTimer(180);
          vm.setState({
            referenceNo: reference,
            token_otp: token_otp,
            loading: false,
          });
        }
      })
      .catch((error) => {
        vm.setState({ loading: false });
        if (!!error.isAxiosError && !error.response) {
          popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
        } else {
          let errorMessage = error.response ? error.response.data.message : "";
          popupError(`axios or api error ${errorMessage}`, "เกิดข้อผิดพลาด");
        }
      });
  }

  async verifyOTP(otp, token_otp, reference) {
    const debug = JSON.parse(localStorage.getItem("debug"));
    if (debug) {
      return 1;
    }
    var vm = this;
    var postData = {
      phone_number: this.state.phone_number,
      otp: otp,
      token: token_otp,
      reference: reference,
    };
    let axiosConfig = {
      headers: {
        // 'Content-Type': 'application/json;charset=UTF-8',
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        // 'Access-Control-Allow-Origin': '*',
      },
    };

    return await axios
      .post(
        `${config.baseAPIURL01}/api/verifyotp`,
        qs.stringify(postData),
        axiosConfig
      )
      .then(async (res) => {
        let api_status = res.data.api_status;
        return api_status === 1 ? 1 : 0;
      })
      .catch(async (error) => {
        vm.setState({ loading: false });
        if (!!error.isAxiosError && !error.response) {
          popupError("กรุณาทำรายการใหม่อีกครั้ง", "เกิดข้อผิดพลาด");
        } else {
          let errorMessage = error.response ? error.response.data.message : "";
          popupError(`axios or api error ${errorMessage}`, "เกิดข้อผิดพลาด");
        }
        return 0;
      });
  }

  startTimer(duration) {
    var timer = duration,
      minutes,
      seconds;
    var vm = this;
    setInt = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      // vm.setState({ timeCountDown: minutes + ':' + seconds });
      if (document.getElementById("timeCountDown")) {
        document.getElementById("timeCountDown").innerHTML =
          minutes + ":" + seconds;
      }

      if (--timer < 0) {
        // timer = duration;
        document.getElementById("sendbtnOTP").style.display = "none";
        document.getElementById("btnOTP").style.display = "inline";
        clearInterval(setInt);
        window.clearInterval(setInt);
      }
    }, 1000);
  }

  sendSmsBtn = (e) => {
    e.preventDefault();
    let phone_number = this.state.phone_number;
    this.sendSMS(phone_number);
    document.getElementById("sendbtnOTP").style.display = "inline";
    document.getElementById("btnOTP").style.display = "none";
  };

  async saveTC() {
    const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
    const { head_token } = HeaderManp;
    if (!head_token) {
      this.setState({ loading: false });
      return;
    }
    const page_agree = await JSON.parse(localStorage.getItem("page_agree"));
    if (
      page_agree?.pdpa !== undefined &&
      page_agree?.termandcon !== undefined
    ) {
      await axios
        .post(
          _url + "/form/signed_tnc/manp",
          qs.stringify({
            head_token,
            product_id: null,
            comfrimation: page_agree.pdpa,
            code: "PDPA",
          })
        )
        .then((res) => {
          if (res.data.Data[0][0].IsSuccess === 0) {
            this.setState({ loading: false });
            if (res.data.Data[0][0].MsgDesc !== "") {
              popupErrorApi(res.data.Data[0][0].MsgDesc, "", true);
              return;
            } else {
              popupErrorApi(
                "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง",
                "เกิดข้อผิดพลาด",
                true
              );
              return;
            }
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          var mss = "";
          var title_mss = "";
          title_mss =
            error.response.data.Data[0][0]?.MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          popupErrorApi(mss, title_mss, true);
          return;
        });
      await axios
        .post(
          _url + "/form/signed_tnc/manp",
          qs.stringify({
            head_token,
            product_id: null,
            comfrimation: page_agree.termandcon,
            code: "TNC",
          })
        )
        .then((res) => {
          if (res.data.Data[0][0].IsSuccess === 0) {
            this.setState({ loading: false });
            if (res.data.Data[0][0].MsgDesc !== "") {
              popupErrorApi(res.data.Data[0][0].MsgDesc, "", true);
              return;
            } else {
              popupErrorApi(
                "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง",
                "เกิดข้อผิดพลาด",
                true
              );
              return;
            }
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          var mss = "";
          var title_mss = "";
          title_mss =
            error.response.data.Data[0][0]?.MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          popupErrorApi(mss, title_mss, true);
          return;
        });
    } else {
      MySwal.fire({
        customClass: {
          confirmButton: "long_btn_fix",
          cancelButton: "long_btn_fix",
        },
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title: '<div class="title_popup">เกิดข้อผิดพลาด</div>',
      }).then(function () {
        window.location = "/";
      });
      return;
    }
  }

  sendnexeSmsBtn = async (e) => {
    e.preventDefault();
    const otp = document.getElementById("OTP").value;
    const url = this.props.location.search;
    const params = queryString.parse(url);
    const page = params.page;
    let currentStep = localStorage.getItem("currentStep", true);
    const page_agree = await JSON.parse(localStorage.getItem("page_agree"));
    if (page_agree) {
      if (
        page_agree?.pdpa !== undefined &&
        page_agree?.termandcon !== undefined
      ) {
      } else {
        MySwal.fire({
          customClass: {
            confirmButton: "long_btn_fix",
            cancelButton: "long_btn_fix",
          },
          imageUrl: "/images/ico_alert.svg",
          imageWidth: 100,
          imageHeight: 100,
          confirmButtonColor: "#003b66",
          confirmButtonText: "กลับ",
          title: '<div class="title_popup">เกิดข้อผิดพลาด</div>',
        }).then(function () {
          window.location = "/";
        });
        return;
      }
    } else {
      MySwal.fire({
        customClass: {
          confirmButton: "long_btn_fix",
          cancelButton: "long_btn_fix",
        },
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title: '<div class="title_popup">เกิดข้อผิดพลาด</div>',
      }).then(function () {
        window.location = "/";
      });
      return;
    }
    if (otp.length === 6 && validator.isNumeric(otp)) {
      this.setState({ loading: true });
      var verifyOTPStatus = await this.verifyOTP(
        otp,
        this.state.token_otp,
        this.state.referenceNo
      );
      if (verifyOTPStatus === 1) {
        clearInterval(setInt);
        window.clearInterval(setInt);
        let error;
        let result;
        let mss = "";
        const comebackWithOtp = JSON.parse(
          localStorage.getItem("comebackWithOtp")
        );
        if (page === "comeback") {
          if (comebackWithOtp) {
            // not re generate header
            await this.saveTC();
            console.log("saveTC 1");
            await localStorage.setItem("currentStep", "tc_ndid");
            await this.saveData();
          } else {
            // เข้า comeback ครั้งแรก
            await this.getUserdata();
            await this.saveTC();
            console.log("saveTC 2");
          }
        } else if (comebackWithOtp) {
          // not re generate header
          await this.saveTC();
          await localStorage.setItem("currentStep", "tc_ndid");
          await this.saveData();
        } else if (
          //page === "fatca" ||
          page === 'crs_consent' ||
          currentStep === "null" ||
          currentStep === null
        ) {
          // เข้า comeback แต่เลือกกรอกใหม่
          const f0 = await JSON.parse(localStorage.getItem("f0"));
          const checkuserinfo = await JSON.parse(
            localStorage.getItem("checkuserinfo")
          );
          var f0_national_country_id = f0.f0_nationality;

          let crs_birth_address = JSON.parse(localStorage.getItem('crs_birth_address'))
          let crs_declare_location = JSON.parse(localStorage.getItem('crs_declare_location'))
          let crs_json = crs_declare_location.tax_country_list.map(x => {
            return {
              SAffcc_CNT_TaxResidence: x.country.countryId.toString(),
              SAffcc_TIN: x.tax_no ? x.tax_no : "",
              SAffcc_Mrnt_Id: x.no_tax_choice ? x.no_tax_choice.toString() : "0",
              SAffcc_Explain: x.reason_choice_2 ? x.reason_choice_2 : ""
            }
          })

          const ops2 = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: JSON.stringify({
              f0_title: f0.f0_title,
              f0_title_text: f0.f0_title_text,
              f0_title_eng: f0.f0_title_eng,
              f0_title_text_eng: f0.f0_title_text_eng,
              f0_national_country_id: f0_national_country_id.value,
              f0_firstname: f0.f0_firstname,
              f0_middlename: f0.f0_middlename,
              f0_lastname: f0.f0_lastname,
              f0_firstname_eng: f0.f0_firstname_eng,
              f0_middlename_eng: f0.f0_middlename_eng,
              f0_lastname_eng: f0.f0_lastname_eng,
              f0_national_id: f0.f0_national_id,
              f0_lazer_card: f0.f0_lazer_card,
              f0_date_birth: f0.f0_date_birth,
              f0_expire_date: f0.f0_expired_date,
              f0_email: f0.f0_email,
              f0_mobile: f0.f0_mobile,
              f0_investment_consultant_id:
                checkuserinfo && checkuserinfo.aeCode !== ""
                  ? checkuserinfo.aeCode
                  : "",
              f0_consent_fatca: true,
              f0_consent_openaccount: true,
              f0_city_of_birth: crs_birth_address.input_birth_province ? crs_birth_address.input_birth_province : crs_birth_address.birth_province.en_label,
              f0_country_of_birth: crs_birth_address.birth_country.value,
              f0_us_citizen: "false".toString(),
              f0_non_us_citizen: "true".toString(),
              f0_crs_tax_detail: JSON.stringify(crs_json)
            }),
            url: "form/header/manp",
          };
          [error, result] = await to(ktbsApi(ops2));

          if (error || !result) {
            this.setState({ loading: false });
            if (result) {
              mss =
                result.Data[0][0].MsgDesc !== ""
                  ? result.Data[0][0].MsgDesc
                  : "กรุณาทำรายการใหม่อีกครั้ง";
            } else if (error) {
              if (!!error.isAxiosError && !error.response) {
                mss = "กรุณาทำรายการใหม่อีกครั้ง";
              } else {
                mss =
                  error.response.data.Data[0][0].MsgDesc !== ""
                    ? error.response.data.Data[0][0].MsgDesc
                    : "กรุณาทำรายการใหม่อีกครั้ง";
              }
            } else {
              mss = "กรุณาทำรายการใหม่อีกครั้ง";
            }
            MySwal.fire({
              imageUrl: "/images/ico_alert.svg",
              imageWidth: 100,
              imageHeight: 100,
              customClass: {
                confirmButton: "long_btn_fix",
              },
              confirmButtonColor: "#003b66",
              confirmButtonText: "กลับ",
              // title: '<div class="title_popup">ขออภัยข้อมูลของท่านไม่ถูกต้อง</div>  ',
              // html: mss ? mss : '<div class="content_popup">กรุณาตรวจสอบข้อมูลบัตรประจำตัวประชาชนอีกครั้ง</div>',
              title: '<div class="title_popup">เกิดข้อผิดพลาด</div>',
              html: '<div class="content_popup">' + mss + "</div>",
            });
            return;
          }
          if (result.Data[0].IsSuccess !== 1) {
            this.setState({ loading: false });
            mss = result.Data[0].MsgDesc;
            MySwal.fire({
              imageUrl: "/images/ico_alert.svg",
              imageWidth: 100,
              imageHeight: 100,
              customClass: {
                confirmButton: "long_btn_fix",
              },
              confirmButtonColor: "#003b66",
              confirmButtonText: "กลับ",
              title:
                '<div class="title_popup">ขออภัยข้อมูลของท่านไม่ถูกต้อง</div>  ',
              html: mss
                ? mss
                : '<div class="content_popup">กรุณาตรวจสอบข้อมูลบัตรประจำตัวประชาชนอีกครั้ง</div>',
            });
            return;
          }
          const HeaderManp = result.Data[0];
          localStorage.setItem("HeaderManp", JSON.stringify(HeaderManp));
          const { head_token } = HeaderManp;
          if (!head_token) {
            this.setState({ loading: false });
            return;
          }
          console.log("new uer save signed_tnc PDPA && TNC");
          await this.saveTC();
          await localStorage.setItem("passDopa", true);
          await localStorage.setItem("currentStep", "product_choice");
          await this.saveData();
        } else {
          this.props.history.push("/" + currentStep);
          await localStorage.setItem("currentStep", currentStep);
        }
      } else {
        this.setState({ loading: false });
        this.textError("block");
      }
    }
  };

  async saveData() {
    let error;
    let result;
    let mss = "";
    let title_mss = "";
    const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
    const head_token = HeaderManp.head_token;
    const fatca_json = JSON.parse(localStorage.getItem("fatca"));
    const fatCa = {
      head_token,
      fatca_json: fatca_json,
    };
    [error, result] = await to(ktbsApi.post("form/fatca/manp", fatCa));
    if (
      error ||
      !result ||
      !result.Data ||
      !result.Data[0] ||
      !result.Data[0][0] ||
      result.Data[0][0].IsSuccess !== 1
    ) {
      this.setState({ loading: false });
      if (result.Data) {
        mss =
          result.Data[0][0].MsgDesc !== ""
            ? result.Data[0][0].MsgDesc
            : "กรุณาทำรายการใหม่อีกครั้ง";
      } else if (error) {
        if (result) {
          mss =
            result.Data[0][0].MsgDesc !== ""
              ? result.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          title_mss =
            result.Data[0][0].MsgDescTitle !== ""
              ? result.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
        } else if (error) {
          if (!!error.isAxiosError && !error.response) {
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            title_mss = "เกิดข้อผิดพลาด";
          } else {
            title_mss =
              result.Data[0][0].MsgDescTitle !== ""
                ? result.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด ";
            mss =
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
        } else {
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          title_mss = "เกิดข้อผิดพลาด";
        }
      } else {
        mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        title_mss = "เกิดข้อผิดพลาด";
      }
      MySwal.fire({
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        customClass: {
          confirmButton: "long_btn_fix",
        },
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title: '<div class="title_popup">' + title_mss + "</div>",
        html: '<div class="content_popup">' + mss + "</div>",
      });
    }
    const frontIDCardParam = {
      method: "POST",
      headers: { "content-type": "application/json" },
      data: JSON.stringify({
        head_token,
        id: "0",
        image_base64: localStorage.getItem("photo1"),
      }),
      url: "form/document/upload/idcard/front",
    };

    [error, result] = await to(ktbsApi(frontIDCardParam));
    if (
      error ||
      !result ||
      !result.Data ||
      !result.Data[0] ||
      !result.Data[0][0] ||
      result.Data[0][0].IsSuccess !== 1
    ) {
      this.setState({ loading: false });
      if (result.Data) {
        mss =
          result.Data[0][0].MsgDesc !== ""
            ? result.Data[0][0].MsgDesc
            : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        title_mss =
          result.Data[0][0].MsgDescTitle !== ""
            ? result.Data[0][0].MsgDescTitle
            : "เกิดข้อผิดพลาด";
      } else if (error) {
        if (!!error.isAxiosError && !error.response) {
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          title_mss = "เกิดข้อผิดพลาด";
        } else {
          title_mss =
            error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "ไม่สามารถทำการอัพโหลดได้";
          mss =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "เกิดข้อผิดพลาดร้ายแรงในการนำส่งข้อมูลเข้าสู่ระบบ";
        }
      } else {
        mss = "เกิดข้อผิดพลาดร้ายแรงในการนำส่งข้อมูลเข้าสู่ระบบ";
        title_mss = "ไม่สามารถทำการอัพโหลดได้";
      }
      MySwal.fire({
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        customClass: {
          confirmButton: "long_btn_fix",
        },
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title: '<div class="title_popup">' + title_mss + "</div>",
        html: '<div class="content_popup">' + mss + "</div>",
      });
      return;
    }
    /* const backIDCardParam = {
      head_token,
      id: "0",
      image_base64: localStorage.getItem("photo2"),
    };
    [error, result] = await to(
      ktbsApi.post("form/document/upload/idcard/back", backIDCardParam)
    );
    if (
      (error ||
        !result ||
        !result.Data ||
        !result.Data[0] ||
        !result.Data[0][0] ||
        result.Data[0][0].IsSuccess !== 1) &&
      result.status != 200
    ) {
      this.setState({ loading: false });
      if (result.Data) {
        mss =
          result.Data[0][0].MsgDesc !== ""
            ? result.Data[0][0].MsgDesc
            : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        title_mss =
          result.Data[0][0].MsgDescTitle !== ""
            ? result.Data[0][0].MsgDescTitle
            : "เกิดข้อผิดพลาด";
      } else if (error) {
        if (!!error.isAxiosError && !error.response) {
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          title_mss = "เกิดข้อผิดพลาด";
        } else {
          title_mss =
            error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "ไม่สามารถทำการอัพโหลดได้";
          mss =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "เกิดข้อผิดพลาดร้ายแรงในการนำส่งข้อมูลเข้าสู่ระบบ";
        }
      } else {
        mss = "เกิดข้อผิดพลาดร้ายแรงในการนำส่งข้อมูลเข้าสู่ระบบ";
        title_mss = "ไม่สามารถทำการอัพโหลดได้";
      }
      MySwal.fire({
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        customClass: {
          confirmButton: "long_btn_fix",
        },
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title: '<div class="title_popup">' + title_mss + "</div>",
        html: '<div class="content_popup">' + mss + "</div>",
      });
      return;
    }*/
    const flag_idc_detail = JSON.parse(localStorage.getItem("flag_idc_detail"));
    if (flag_idc_detail) {
      const f0 = await JSON.parse(localStorage.getItem("f0"));
      var f0_national_country_id = f0.f0_nationality;
      const idc_detail = {
        head_token,
        f1_lazer_card: f0.f0_lazer_card,
        f1_title: f0.f0_title,
        f1_title_text: f0.f0_title_text,
        f1_firstname: f0.f0_firstname,
        f1_middlename: f0.f0_middlename,
        f1_firstname_eng: f0.f0_firstname_eng,
        f1_middlename_eng: f0.f0_middlename_eng,
        f1_lastname: f0.f0_lastname,
        f1_lastname_eng: f0.f0_lastname_eng,
        f1_date_birth: f0.f0_date_birth,
        f1_expire_date: f0.f0_expired_date,
        f1_national_country_id: f0_national_country_id.value,
      };
      console.log("update flag_idc_detail", idc_detail);
      [error, result] = await to(
        ktbsApi.post("form/customer_info/manp", idc_detail)
      );
      if (
        (error ||
          !result ||
          !result.Data ||
          !result.Data[0] ||
          !result.Data[0][0] ||
          result.Data[0][0].IsSuccess !== 1) &&
        result.status != 200
      ) {
        this.setState({ loading: false });
        if (result.Data) {
          mss =
            result.Data[0][0].MsgDesc !== ""
              ? result.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          title_mss =
            result.Data[0][0].MsgDescTitle !== ""
              ? result.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
        } else if (error) {
          if (!!error.isAxiosError && !error.response) {
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            title_mss = "เกิดข้อผิดพลาด";
          } else {
            title_mss =
              error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "ไม่สามารถทำการอัพโหลดได้";
            mss =
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "เกิดข้อผิดพลาดร้ายแรงในการนำส่งข้อมูลเข้าสู่ระบบ";
          }
        } else {
          mss = "เกิดข้อผิดพลาดร้ายแรงในการนำส่งข้อมูลเข้าสู่ระบบ";
          title_mss = "ไม่สามารถทำการอัพโหลดได้";
        }
        MySwal.fire({
          imageUrl: "/images/ico_alert.svg",
          imageWidth: 100,
          imageHeight: 100,
          customClass: {
            confirmButton: "long_btn_fix",
          },
          confirmButtonColor: "#003b66",
          confirmButtonText: "กลับ",
          title: '<div class="title_popup">' + title_mss + "</div>",
          html: '<div class="content_popup">' + mss + "</div>",
        });
        return;
      }
    }
    const flag_checkuserinfo = JSON.parse(
      localStorage.getItem("flag_checkuserinfo")
    );
    if (flag_checkuserinfo) {
      const checkuserinfo = await JSON.parse(
        localStorage.getItem("checkuserinfo")
      );
      const checkuser_info = {
        head_token,
        f1_mobile: checkuserinfo.f0_mobile_phone,
        f1_email: checkuserinfo.f0_email,
        f1_investment_consultant_id: checkuserinfo.aeCode,
      };
      console.log("update flag_checkuserinfo", checkuser_info);
      [error, result] = await to(
        ktbsApi.post("form/header_info/manp", checkuser_info)
      );
      if (
        (error ||
          !result ||
          !result.Data ||
          !result.Data[0] ||
          !result.Data[0][0] ||
          result.Data[0][0].IsSuccess !== 1) &&
        result.status != 200
      ) {
        this.setState({ loading: false });
        if (result.Data) {
          mss =
            result.Data[0][0].MsgDesc !== ""
              ? result.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          title_mss =
            result.Data[0][0].MsgDescTitle !== ""
              ? result.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
        } else if (error) {
          if (!!error.isAxiosError && !error.response) {
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            title_mss = "เกิดข้อผิดพลาด";
          } else {
            title_mss =
              error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "ไม่สามารถทำการอัพโหลดได้";
            mss =
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "เกิดข้อผิดพลาดร้ายแรงในการนำส่งข้อมูลเข้าสู่ระบบ";
          }
        } else {
          mss = "เกิดข้อผิดพลาดร้ายแรงในการนำส่งข้อมูลเข้าสู่ระบบ";
          title_mss = "ไม่สามารถทำการอัพโหลดได้";
        }
        MySwal.fire({
          imageUrl: "/images/ico_alert.svg",
          imageWidth: 100,
          imageHeight: 100,
          customClass: {
            confirmButton: "long_btn_fix",
          },
          confirmButtonColor: "#003b66",
          confirmButtonText: "กลับ",
          title: '<div class="title_popup">' + title_mss + "</div>",
          html: '<div class="content_popup">' + mss + "</div>",
        });
        return;
      }
    }

    var last_stamp = JSON.stringify(this.allStorage());
    const ops = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
      },
      data: {
        last_stamp: last_stamp,
        head_token: head_token,
        stamp_page: "PROD",
      },
      url: _url + "/form/comeback/manp",
    };
    axios(ops)
      .then(async (res) => {
        this.setState({ loading: false });
        //TODO
        this.props.history.push("/product_choice");
        // this.props.history.push("/tc_ndid");
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.popError(
          "สอบถามข้อมูลเพิ่มเติมกรุณาติดต่อ DAOL Contact Center<br>0 2351 1800",
          "ไม่สามารถทำรายการได้"
        );
      });
  }

  popError = (msg, title = "") => {
    const vm = this;
    MySwal.fire({
      customClass: {
        confirmButton: "long_btn_fix",
        cancelButton: "long_btn_fix",
      },
      // icon: 'warning',
      imageUrl: "/images/ico_alert.svg",
      imageWidth: 100,
      imageHeight: 100,
      confirmButtonColor: "#003b66",
      confirmButtonText: "กลับ",
      title: `<span class="title_popup">${title}</span>`,
      html: `<div class="content_popup">${msg}</div>`,
    }).then(function () {
      vm.props.history.push("/ndid");
    });
  };

  allStorage() {
    var values = [],
      keys = Object.keys(localStorage),
      i = keys.length;
    while (i--) {
      if (
        keys[i].indexOf("oldData_") !== -1 ||
        keys[i].indexOf("flag_") !== -1 ||
        keys[i].indexOf("debug") !== -1 ||
        keys[i].indexOf("comebackWithOtp") !== -1
      ) {
        if (keys[i].indexOf("debug") !== -1) {
        } else {
          console.log("remove ", keys[i]);
          localStorage.removeItem(keys[i]);
        }
      } else {
        values.push({
          key: keys[i],
          value: localStorage.getItem(keys[i]),
        });
      }
    }
    return values;
    // return
  }

  async getUserdata1() {
    let currentStep;
    currentStep = localStorage.getItem("currentStep");
    if (currentStep !== undefined) {
      let oldData_f0 = localStorage.getItem("f0");
      let oldData_photo1 = localStorage.getItem("photo1");
      let oldData_photo2 = localStorage.getItem("photo2");
      let oldData_checkuserinfo = localStorage.getItem("checkuserinfo");
      localStorage.setItem("oldData_f0", oldData_f0);
      localStorage.setItem("oldData_photo1", oldData_photo1);
      localStorage.setItem("oldData_photo2", oldData_photo2);
      localStorage.setItem("oldData_checkuserinfo", oldData_checkuserinfo);
      localStorage.setItem("comebackWithOtp", true);
      this.props.history.push("/" + currentStep);
    } else {
      var title_mss = "เกิดข้อผิดพลาด";
      var mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
      popupErrorApi(
        mss,
        title_mss,
        true
        // function name() {
        //   var myStyle = document.getElementsByClassName("main_popup_loading")[0].style.display;
        //   if(myStyle === 'none'){
        //     document.getElementsByClassName("main_popup_loading")[0].style.display = 'block';
        //   }
        //   window.location = "login";
        // }
      );
    }
    return;
  }

  async getUserdata() {
    let error;
    let result;
    let currentStep;
    const checkuserinfo = await JSON.parse(
      localStorage.getItem("checkuserinfo")
    );
    const { cardId, f0_mobile_phone } = checkuserinfo;
    const ops2 = {
      method: "POST",
      headers: { "content-type": "application/json" },
      data: JSON.stringify({
        card_id: cardId,
        mobile_number: f0_mobile_phone,
      }),
      url: "form/comeback/selbyhead",
    };
    [error, result] = await to(ktbsApi(ops2));
    if (result !== undefined && result.Data[0][0].IsSuccess === 1) {
      var last_stamp = JSON.parse(result.Data[0][0].last_stamp);
      if (last_stamp !== null && last_stamp.length > 0) {
        // localStorage.removeItem(last_stamp[i].key);
        var i = last_stamp.length;
        while (i--) {
          // console.log(last_stamp[i].key, last_stamp[i].value);
          if (
            last_stamp[i].key.indexOf("oldData_") != -1 ||
            last_stamp[i].key.indexOf("comebackWithOtp") != -1 ||
            last_stamp[i].key.indexOf("editPage") != -1 ||
            last_stamp[i].key.indexOf("page_agree") != -1
          ) {
            // localStorage.removeItem(last_stamp[i].key);
          } else {
            localStorage.setItem(last_stamp[i].key, last_stamp[i].value);
          }
        }
      } else {
        this.props.history.push("/SnapIdCard");
      }
      this.setState({ loading: false });
      const page_agree = await JSON.parse(localStorage.getItem("page_agree"));
      if (page_agree) {
        if (
          page_agree?.pdpa !== undefined &&
          page_agree?.termandcon !== undefined
        ) {
        } else {
          MySwal.fire({
            customClass: {
              confirmButton: "long_btn_fix",
              cancelButton: "long_btn_fix",
            },
            imageUrl: "/images/ico_alert.svg",
            imageWidth: 100,
            imageHeight: 100,
            confirmButtonColor: "#003b66",
            confirmButtonText: "กลับ",
            title: '<div class="title_popup">เกิดข้อผิดพลาด</div>',
          }).then(function () {
            window.location = "/";
          });
          return;
        }
      } else {
        MySwal.fire({
          customClass: {
            confirmButton: "long_btn_fix",
            cancelButton: "long_btn_fix",
          },
          imageUrl: "/images/ico_alert.svg",
          imageWidth: 100,
          imageHeight: 100,
          confirmButtonColor: "#003b66",
          confirmButtonText: "กลับ",
          title: '<div class="title_popup">เกิดข้อผิดพลาด2</div>',
        }).then(function () {
          window.location = "/";
        });
        return;
      }
      currentStep = localStorage.getItem("currentStep");
      if (currentStep !== undefined) {
        this.props.history.push("/" + currentStep);
      } else {
        var title_mss = "เกิดข้อผิดพลาด";
        var mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        popupErrorApi(mss, title_mss, true);
      }
      return;
    } else {
      this.setState({ loading: false });
      var mss = "";
      var title_mss = "";
      if (!!error.isAxiosError && !error.response) {
        title_mss = "เกิดข้อผิดพลาด";
        mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
      } else {
        title_mss =
          error.response.data.Data !== undefined &&
            error.response.data.Data[0][0].MsgDescTitle !== ""
            ? error.response.data.Data[0][0].MsgDescTitle
            : "เกิดข้อผิดพลาด";
        mss =
          error.response.data.Data !== undefined &&
            error.response.data.Data[0][0].MsgDesc !== ""
            ? error.response.data.Data[0][0].MsgDesc
            : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
      }
      popupErrorApi(mss, title_mss, true);
    }
  }

  componentDidMount() {
    this._isMounted = true;
    const vm = this;
    const checkuserinfo = JSON.parse(localStorage.getItem("checkuserinfo"));
    if (checkuserinfo === null) {
      window.location = "/idc_detail";
    }
    const phone_number = checkuserinfo.f0_mobile_phone;
    if (phone_number === undefined) {
      this.props.history.push("/");
      return false;
    }
    const page_agree = JSON.parse(localStorage.getItem("page_agree"));
    if (page_agree) {
      if (
        page_agree?.pdpa !== undefined &&
        page_agree?.termandcon !== undefined
      ) {
        if (validator.isMobilePhone(phone_number, "th-TH")) {
          if (this._isMounted) {
            vm.setState({ phone_number: phone_number });
          }
          const debug = JSON.parse(localStorage.getItem("debug"));
          if (debug) {
            console.log("debug");
            return;
          } else {
            this.sendSMS(phone_number); //อย่าลืมเอาออก
          }
        } else {
          this.props.history.push("/");
          return false;
        }
      } else {
        MySwal.fire({
          customClass: {
            confirmButton: "long_btn_fix",
            cancelButton: "long_btn_fix",
          },
          imageUrl: "/images/ico_alert.svg",
          imageWidth: 100,
          imageHeight: 100,
          confirmButtonColor: "#003b66",
          confirmButtonText: "กลับ",
          title: '<div class="title_popup">เกิดข้อผิดพลาด</div>',
        }).then(function () {
          window.location = "/";
        });
        return;
      }
    } else {
      MySwal.fire({
        customClass: {
          confirmButton: "long_btn_fix",
          cancelButton: "long_btn_fix",
        },
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title: '<div class="title_popup">เกิดข้อผิดพลาด</div>',
      }).then(function () {
        window.location = "/";
      });
      return;
    }
  }

  optChange = async (e) => {
    var code = e.which || e.keyCode;
    var key = String.fromCharCode(code);
    this.setState({ value_otp: e.target.value });
    if (!validator.isNumeric(key)) {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
    } else {
      this.checkLength(e);
    }
  };

  checkLength = async (e) => {
    let v = e.target.value.replace(/[^0-9\\]+/g, "");
    if (v.length === 6) {
      this.setState({ btn_set_show: true });
    } else {
      this.setState({ btn_set_show: false });
    }
    await this.setState({ value_otp: v });
  };

  textError(show) {
    if (document.getElementById("text_title_otp_error") !== null) {
      document.getElementById("text_title_otp_error").style.display = show;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.clearInterval(setInt);
    window.clearTimeout(setInt);
  }

  checkComeBack() {
    var str = window.location.href;
    let re = /page=comeback/;
    const back_otp = re.test(str);
    return back_otp;
  }

  render() {
    library.add(fas);
    const phone_number = this.state.phone_number;
    const timeCountDown = this.state.timeCountDown;
    const referenceNo = this.state.referenceNo;
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <Loading status={this.state.loading} />
                  <div
                    className="content"
                    style={{
                      backgroundImage:
                        "./A8CF3483-C1C1-4F4D-9382-1CA52074DC5C.png",
                    }}
                  >
                    <div className="top_main_back">
                      <div
                        className="main_back"
                        onClick={this.goBack}
                        style={{ height: "36px" }}
                      >
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          style={{
                            marginLeft: "3px",
                            display: this.checkComeBack() ? "none" : "block",
                          }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">ยืนยันรหัส OTP</div>
                    </div>

                    <div className="bg_content_white">
                      <div className="in_bg_white " style={{ top: 24 }}>
                        <div className="text_title_otp_1">
                          ระบบได้ส่ง OTP ไปยัง
                        </div>
                        <div
                          className="text_title_otp_1"
                          style={{ marginBottom: 22 }}
                        >
                          <span style={{ color: "#000" }}>
                            {phone_number.substring(0, 6) + "xxxx"}{" "}
                          </span>
                          เรียบร้อยแล้ว
                        </div>
                        {/* <div className='text_title_otp_2'>
              โปรดระบุรหัส OTP เพื่อยืนยันการทำรายการ
            </div> */}

                        <div className={"row_idcard"} style={{ paddingTop: 0 }}>
                          <div className="text_title_otp_2">
                            โปรดระบุรหัส OTP เพื่อยืนยันการทำรายการ
                          </div>
                          <div
                            id="text_title_otp_error"
                            className="text_title_otp_error"
                            style={{ display: "none" }}
                          >
                            <FontAwesomeIcon icon="exclamation-circle"></FontAwesomeIcon>{" "}
                            รหัส OTP ไม่ถูกต้อง
                          </div>
                          <input
                            className="input_idcard"
                            id="OTP"
                            onChange={this.checkLength}
                            onKeyUp={this.checkLength}
                            maxLength="6"
                            pattern="[0-9]*"
                            type="tel"
                            value={this.state.value_otp}
                            style={{
                              borderBottomColor:
                                this.state.value_otp !== ""
                                  ? "#003b66"
                                  : "#ccc",
                            }}
                          ></input>
                        </div>
                        {/* <div className='otp_flex'>
              <input
                className='input_idcard'
                id='OTP'
                onKeyPress={this.optChange}
                maxLength='6'
                pattern='[0-9]*'
                type='text'
              ></input>
            </div> */}

                        <div className="text_title_under alert">
                          <div>เลขอ้างอิง {referenceNo}</div>
                          <div id="timeCountDown">{timeCountDown}</div>
                        </div>
                      </div>

                      <div style={{ clear: "both" }}></div>
                      <div className="under_fix_btn">
                        <span
                          id="sendbtnOTP"
                          onClick={
                            this.state.btn_set_show ? this.sendnexeSmsBtn : null
                          }
                          style={{ display: "", cursor: "pointer" }}
                        >
                          <ButtonCustom
                            label="ถัดไป"
                            color={this.state.btn_set_show ? "blue" : "gray"}
                            btnClass="long_btn_fix"
                          ></ButtonCustom>
                        </span>

                        <span
                          id="btnOTP"
                          onClick={this.sendSmsBtn}
                          style={{ display: "none", cursor: "pointer" }}
                        >
                          <ButtonCustom
                            label="ขอรหัส OTP ใหม่"
                            color="red"
                            btnClass="long_btn_fix"
                          ></ButtonCustom>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default KtbstOTP;
