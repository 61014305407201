import axios from "axios"
import React, { Component } from "react"
import "./crs_declare_location.css"
import { isMobile } from "react-device-detect"
import { config } from "./../../constants"
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft"
import Loading from "../../components/loading/loading"
import { popupErrorApi, popupCrs, update_tracking } from "../../components/helpers/Helpers"
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import AddIcon from "@mui/icons-material/Add"
import Snackbar from '@mui/material/Snackbar'
import {
  FormControlLabel,
  Typography,
  Radio,
  RadioGroup,
} from "@mui/material"

const _url = config.baseAPIURL03


class CrsDeclareLocation extends Component {
    constructor(props) {
      super(props)

      let form_action = props.location?.state?.form_action
      let is_from_summary = props.location?.state?.is_from_summary || false
      let prev_crs_declare_location = props.location?.state?.prev_crs_declare_location

      this.state = {
        prop_form_action: form_action,
        prop_is_from_summary: is_from_summary,
        loading: true,
        is_non_tax_from_usa: null,
        enable_radio_button: is_from_summary ? false : true,
        tax_country_list: [],
        country_list: [],
        is_show_snackbar: false,
        current_crs_declare_location: {},
        prev_crs_declare_location: prev_crs_declare_location,
      }
    }
  
    componentDidMount = async () => {
      await this.setState({ loading: true })
      await this.getCountrys()
      await this.loadDataFromLocalStorage()
      await this.setState({ loading: false })

      if (this.state.prop_form_action) {
        await this.setState({ is_show_snackbar: true })
      }
    }
  
    goBack = () => {
      if (this.state.prop_is_from_summary) {
        if (this.is_data_change()) {
          popupCrs(
            "ยกเลิกการแก้ไข",
            "การแก้ไขยังไม่ได้ถูกบันทึก\nท่านต้องการออกจากหน้านี้หรือไม่",
            "ออกจากหน้านี้",
            "อยู่หน้านี้ต่อ",
            () => {
              localStorage.setItem('crs_declare_location', JSON.stringify(this.state.prev_crs_declare_location))
              this.props.history.push("summaryshow")
            }
          )
        } else {
          this.props.history.push("summaryshow")
        }
      } else {
        this.props.history.push("crs_birth_address")
      }  
    }
      
    loadDataFromLocalStorage = async () => {
      let data = localStorage.getItem('crs_declare_location')
      if (data) {
        data = JSON.parse(data)
        await this.setState({
          is_non_tax_from_usa: data.is_non_tax_from_usa,
          tax_country_list: data.tax_country_list.map(x => x.country),
          current_crs_declare_location: data,
        })
      } else {
        let user_info = localStorage.getItem('checkuserinfo')
        if (user_info) {
          user_info = JSON.parse(user_info)
          let thai_country = this.state.country_list.find(country => country.ThName == "ไทย")
          if (thai_country) {
            let tax_country_list = [{
              country: {countryId: thai_country.Id, country: thai_country.ThName},
              is_thai: true,
              tax_no: user_info.cardId,
              no_tax_choice: null,
              reason_choice_2: null
            }]
            await this.setState({tax_country_list: tax_country_list.map(x => x.country)})
            let data = {
              is_non_tax_from_usa: null,
              tax_country_list: tax_country_list
            }
            localStorage.setItem('crs_declare_location', JSON.stringify(data))
          }
        } else {
          let data = {
            is_non_tax_from_usa: null,
            tax_country_list: []
          }
          localStorage.setItem('crs_declare_location', JSON.stringify(data))
        }
      }
    }

    getCountrys = async () => {
      await axios.get(_url + `/constant/country`)
        .then(async (res) => {
          let data = res.data.Data
          await this.setState({country_list: data})
        })
        .catch(async (error) => {
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด"
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง"
          } else {
            var mss = ""
            var title_mss = ""
            if (!!error.isAxiosError && !error.response) {
              title_mss = "เกิดข้อผิดพลาด"
              mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง"
            } else {
              title_mss =
                error.response.data.Data[0][0].MsgDescTitle !== ""
                  ? error.response.data.Data[0][0].MsgDescTitle
                  : "เกิดข้อผิดพลาด"
              mss =
                error.response.data.Data[0][0].MsgDesc !== ""
                  ? error.response.data.Data[0][0].MsgDesc
                  : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง"
            }
          }
  
          popupErrorApi(mss, title_mss, true)
        })
    }

    handleRadioChange = async (event) => {
      let flag = event.target.value == 'true'
      await this.setState({is_non_tax_from_usa: flag})

      let data = localStorage.getItem('crs_declare_location')
      data = JSON.parse(data)
      data.is_non_tax_from_usa = flag
      localStorage.setItem('crs_declare_location', JSON.stringify(data))
    }

    handleEdit = (item) => {
      this.props.history.push("crs_declare_location_form", {
        is_edit: true, 
        select_country: item, 
        country_list: this.state.country_list,
        is_from_summary: this.state.prop_is_from_summary,
        prev_crs_declare_location: this.state.prev_crs_declare_location,
      })
    }

    handleAdd = () => {
      this.props.history.push("crs_declare_location_form", {
        is_edit: false, 
        select_country: null, 
        country_list: this.state.country_list,
        is_from_summary: this.state.prop_is_from_summary,
        prev_crs_declare_location: this.state.prev_crs_declare_location,
      })
    }

    save_data = async () => {
      let header_manp = JSON.parse(localStorage.getItem('HeaderManp'))
      let crs_birth_address = JSON.parse(localStorage.getItem('crs_birth_address'))
      let crs_declare_location = JSON.parse(localStorage.getItem('crs_declare_location'))
      let crs_json = crs_declare_location.tax_country_list.map(x => {
        return {
          SAffcc_CNT_TaxResidence: x.country.countryId.toString(),
          SAffcc_TIN: x.tax_no ? x.tax_no : "",
          SAffcc_Mrnt_Id: x.no_tax_choice ? x.no_tax_choice.toString() : "0",
          SAffcc_Explain: x.reason_choice_2 ? x.reason_choice_2 : ""
        }
      })
  
      let post_data = {
        head_token: header_manp.head_token,
        f0_city_of_birth: crs_birth_address.input_birth_province ? crs_birth_address.input_birth_province : crs_birth_address.birth_province.en_label,
        f0_country_of_birth: crs_birth_address.birth_country.value,
        f0_us_citizen: "false".toString(),
        f0_non_us_citizen: "true".toString(),
        f0_crs_tax_detail: JSON.stringify(crs_json)
      }
  
      let config = {
        headers: {
            "Content-Type": "application/json",
        },
      }
    
      return await axios.post(_url + `/form/crs/manp`, post_data, config)
        .then(resp => {
          return true
        })
        .catch(async (error) => {
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด"
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง"
          } else {
            var mss = ""
            var title_mss = ""
            if (!!error.isAxiosError && !error.response) {
              title_mss = "เกิดข้อผิดพลาด"
              mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง"
            } else {
              title_mss =
                error.response.data.Data[0][0].MsgDescTitle !== ""
                  ? error.response.data.Data[0][0].MsgDescTitle
                  : "เกิดข้อผิดพลาด"
              mss =
                error.response.data.Data[0][0].MsgDesc !== ""
                  ? error.response.data.Data[0][0].MsgDesc
                  : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง"
            }
          }
  
          popupErrorApi(mss, title_mss, true)
          return false
        })
    }
  
    handleSubmit = async () => {
      if (this.state.is_non_tax_from_usa == false) {
        popupCrs(
          "ไม่สามารถทำรายการต่อได้",
          "บริษัทอยู่ระหว่างพัฒนาระบบ\nเพื่อรองรับการเปิดบัญชีของบุคคลผู้มีสัญชาติอเมริกัน\nกรุณาติดต่อ DAOL Contact Center 0 2351 1800",
          "ตกลง",
          "ตรวจสอบอีกครั้ง",
          async () => { 
            let checkuserinfo = JSON.parse(localStorage.getItem("checkuserinfo"))
            let data = {
              head_token: "",
              card_id: checkuserinfo !== null ? checkuserinfo.cardId : "",
              stamp_page: "1",
              error_code: "3004"
            }
            await update_tracking(data)
            this.props.history.push("index.html")
          },
          true
        )
      } else {
        if (this.state.prop_is_from_summary) {
          let saved = await this.save_data()
          if (saved) {
            this.props.history.push("summaryshow")
          }
        } else {
          this.props.history.push("crs_consent")
        }
      }
    }

    handleCloseSnackBar = async () => {
      await this.setState({is_show_snackbar: false, prop_form_action: null})
    }

    is_btn_enable = () => {
      if (this.state.prop_is_from_summary) {
        return this.is_data_valid() && this.is_data_change()
      } else {
        return this.is_data_valid()
      }
    }
  
    is_data_valid = () => {
      return this.state.is_non_tax_from_usa == false || (this.state.is_non_tax_from_usa == true && this.state.tax_country_list.length > 0)
    }

    is_data_change = () => {
      return (
        (this.state.prev_crs_declare_location?.is_non_tax_from_usa != this.state.is_non_tax_from_usa) || 
        !(this.compareLists(this.state.prev_crs_declare_location?.tax_country_list || [], this.state.current_crs_declare_location?.tax_country_list || []))
      )
    }

    compareLists = (aList, bList) => {
      
      // Check if the lengths of the lists are the same
      if (aList.length !== bList.length) {
        return false;
      }

      // Iterate over each object in the lists and compare them
      for (let i = 0; i < aList.length; i++) {
        const aObj = aList[i];
        const bObj = bList[i];

        // Check if the objects have the same keys
        const aKeys = Object.keys(aObj);
        const bKeys = Object.keys(bObj);
        if (aKeys.length !== bKeys.length || !aKeys.every(key => bKeys.includes(key))) {
          return false;
        }

        // Check if the values of the objects are the same
        for (const key of aKeys) {
          if (key == 'country') {
            if (aObj.country.country != bObj.country.country) {
              return false;
            }
          } else if (aObj[key] !== bObj[key]) {
            return false;
          }
        }
      }
      // If all objects are the same, return true
      return true;
    }
  
    render() {
      return (
        <div className={isMobile ? "" : "body"}>
          <header
            className={isMobile ? "" : "header-area"}
            style={{ display: isMobile ? "none" : "block" }}
          >
            <div className={"container everest-nav-container"}>
              <div className={"row"}>
                <div className={"col-md-6"}>
                  <img src="../../../images/logo.png" />
                </div>
                <div className={"col-md-6 flex_end"}>
                  <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
                </div>
              </div>
            </div>
          </header>
          <section
            className={isMobile ? "" : "desktop_content section-gap-full"}
            style={{
              backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
            }}
          >
            <div className={isMobile ? "" : "container"}>
              <div className={isMobile ? "" : "row"}>
                <div
                  className={"col-md-6"}
                  style={{ display: isMobile ? "none" : "block" }}
                >
                  <BannerContentLeft></BannerContentLeft>
                </div>
                <div className={isMobile ? "" : "col-md-6"}>
                  {/* content mobile */}
                  <div>
                    <div className="content">
                      <div className="top_main_back">
                        <div className="main_back" onClick={this.goBack}>
                          <img
                            src={
                              isMobile
                                ? "/images/angle-arrow-left.svg"
                                : "/images/angle-arrow-left-blue.svg"
                            }
                            alt="forward"
                            style={{ marginLeft: "3px" }}
                            className="arrow_back"
                          />
                        </div>
                        <div className="text_title">การแจ้งผู้มีถิ่นที่อยู่ทางภาษีประเทศอื่น</div>
                      </div>
                      <div className="bg_content_white" style={{ paddingTop: 0 }}>
                        <div
                          className="in_bg_white"
                          style={{ top: 0, bottom: 100, right: 0, paddingRight: '21px', overflow: 'auto' }}
                        >
                          <div className={"title_row"}>ท่านเป็นผู้มีถิ่นที่อยู่ทางภาษีในแต่ละประเทศ นอกจากสหรัฐอเมริกา ใช่หรือไม่</div>
                          <RadioGroup 
                            row
                            aria-labelledby="radio-buttons-group-label"
                            name="radio-buttons-group"
                            sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "15px", alignItems: "center"}}
                            value={this.state.is_non_tax_from_usa}
                            onChange={this.handleRadioChange}
                          >
                            <FormControlLabel
                              label={<Typography sx={{fontFamily: 'DB Heavent', fontSize: '24px', color: this.state.enable_radio_button ? '#013b66' : '#A7A9AC'}}>ใช่</Typography>}
                              value={'true'}
                              control={
                                <Radio
                                  icon={<RadioButtonUncheckedIcon sx={this.state.enable_radio_button ? this.state.is_non_tax_from_usa==true ? {color: "#013b66"} : {color: "#A7A9AC"} : {color: "#A7A9AC"}} />}
                                  checkedIcon={<CheckCircleIcon sx={this.state.enable_radio_button ? {color: "#013b66"} : {color: "#A7A9AC"}} />}
                                  disabled={!this.state.enable_radio_button}
                                />
                              }
                              sx={{border: 1, borderRadius: '10px', width: '42%', height: '60px', marginLeft: '0px', marginRight: '0px', paddingLeft: '12px', borderColor: this.state.enable_radio_button && this.state.is_non_tax_from_usa==true ? '#013b66' : '#A7A9AC'}}
                            />
                            <FormControlLabel
                              label={<Typography sx={{fontFamily: 'DB Heavent', fontSize: '24px', color: this.state.enable_radio_button ? '#013b66' : '#A7A9AC'}}>ไม่ใช่</Typography>}
                              value={'false'}
                              control={
                                <Radio
                                  icon={<RadioButtonUncheckedIcon sx={this.state.enable_radio_button ? this.state.is_non_tax_from_usa==false ? {color: "#013b66"} : {color: "#A7A9AC"} : {color: "#A7A9AC"}} />}
                                  checkedIcon={<CheckCircleIcon sx={this.state.enable_radio_button ? {color: "#013b66"} : {color: "#A7A9AC"}} />}
                                  disabled={!this.state.enable_radio_button}
                                />
                              }
                              sx={{border: 1, borderRadius: '10px', width: '42%', height: '60px', marginLeft: '0px', marginRight: '0px', paddingLeft: '12px', borderColor: this.state.enable_radio_button && this.state.is_non_tax_from_usa==false ? '#013b66' : '#A7A9AC'}}
                            />
                          </RadioGroup>
                          {this.state.is_non_tax_from_usa && 
                            this.state.tax_country_list.map(item => (
                              <button className="crs_declare_location_tax_country_list" key={item.countryId} onClick={() => this.handleEdit(item)}>
                                <div className="crs_declare_location_tax_country_list_icon">
                                  <LocationOnIcon sx={{color: "#013b66"}} />
                                </div>
                                <div className="crs_declare_location_tax_country_list_box">
                                  <Typography className="crs_declare_location_tax_country_list_text_title">ประเทศถิ่นที่อยู่ทางภาษี</Typography>
                                  <Typography className="crs_declare_location_tax_country_list_text">{item.country}</Typography>
                                </div>
                                <div className="crs_declare_location_tax_country_list_icon">
                                  <KeyboardArrowRightIcon sx={{color: "#013b66"}} />
                                </div>
                              </button>
                            ))
                          }
                          {this.state.is_non_tax_from_usa && this.state.tax_country_list.length < 4 && (
                            <button
                              className={this.state.tax_country_list.length == 0 ? "crs_declare_location_add_country_btn_zero" : "crs_declare_location_add_country_btn"}
                              onClick={this.handleAdd}
                            >
                              <div className={this.state.tax_country_list.length == 0 ? "crs_declare_location_add_country_icon_zero" : "crs_declare_location_add_country_icon"}><AddIcon/></div>
                              <div>
                                <Typography className={this.state.tax_country_list.length == 0 ? "crs_declare_location_add_country_text_zero" : "crs_declare_location_add_country_text"}>กรุณาระบุประเทศถิ่นที่อยู่ทางภาษี</Typography>
                              </div>
                            </button>
                          )}
                          {this.state.prop_form_action == 'add' && (
                            <Snackbar
                              open={this.state.is_show_snackbar}
                              onClose={this.handleCloseSnackBar}
                              autoHideDuration={3000}
                              sx={{ bottom: { xs: 120 }, left: { xs: 35 }, right: { xs: 35 } }}
                            >
                              <div style={{ width: '100%', height: '48px', backgroundColor: '#D4E1F0', color: '#003b66', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left', paddingLeft: '20px', borderRadius: '8px'}}>
                                <CheckCircleIcon style={{marginRight: '12px'}} />เพิ่มข้อมูลสำเร็จ
                              </div>
                            </Snackbar>
                          )}
                          {this.state.prop_form_action == 'edit' && (
                            <Snackbar
                              open={this.state.is_show_snackbar}
                              onClose={this.handleCloseSnackBar}
                              autoHideDuration={3000}
                              sx={{ bottom: { xs: 120 }, left: { xs: 35 }, right: { xs: 35 } }}
                            >
                              <div style={{ width: '100%', height: '48px', backgroundColor: '#D4E1F0', color: '#003b66', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left', paddingLeft: '20px', borderRadius: '8px'}}>
                                <CheckCircleIcon style={{marginRight: '12px'}} />แก้ไขข้อมูลสำเร็จ
                              </div>
                            </Snackbar>
                          )}
                          {this.state.prop_form_action == 'delete' && (
                            <Snackbar
                              open={this.state.is_show_snackbar}
                              onClose={this.handleCloseSnackBar}
                              autoHideDuration={3000}
                              sx={{ bottom: { xs: 120 }, left: { xs: 35 }, right: { xs: 35 } }}
                            >
                              <div style={{ width: '100%', height: '48px', backgroundColor: '#333333', color: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left', paddingLeft: '20px', borderRadius: '8px'}}>
                                <CancelRoundedIcon style={{marginRight: '12px'}} />ลบทิ้งแล้ว
                              </div>
                            </Snackbar>
                          )}
                        </div>
                        <div
                          className="under_fix_btn"
                          style={{ background: "#fff" }}
                        >
                          <button 
                            className="crs_declare_location_next_btn" 
                            style={!this.is_btn_enable() ? {backgroundColor: '#e0e1e2', color: '#b0b0b0', cursor: 'default'} : {backgroundColor: '#003b66', color: 'white'}} 
                            onClick={this.handleSubmit}
                            disabled={!this.is_btn_enable()}
                          >{this.state.prop_is_from_summary ? 'บันทึกการแก้ไข' : 'ถัดไป'}
                          </button>
                        </div>
                      </div>
                    </div>
                    <Loading status={this.state.loading}></Loading>
                  </div>
                  {/* end content mobile */}
                </div>
              </div>
            </div>
            
          </section>
        </div>
      )
    }
  }
  
  export default CrsDeclareLocation;