import React, { useEffect, useState } from "react";
import { Grid, Box, CardMedia } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
import Loading from "../../components/loading/loading";
import { isMobile } from "react-device-detect";
import ButtonCustom from "../../components/button/ButtonCustom";
import { popupErrorApi } from "../../components/helpers/Helpers";
import "./Ndid_info.css";
import axios from "axios";
import ReactIScroll from "react-iscroll";
import { config } from "./../../constants";

const useStyle = makeStyles({
  textPhone: {
    paddingRight: "5px",
    color: "var(--text-black)",
  },
  title_row: {
    color: "var(--primary)",
    fontFamily: "DB Heavent",
    marginTop: "25px",
    fontSize: "24px",
  },
  textError: {
    fontSize: "16px !important",
    fontFamily: "DB Heavent !important",
    color: "var(--red) !important",
  },
  ndidTable: {
    display: "flex",
    fontFamily: "DB Heavent",
    fontSize: "18px",
    marginBottom: "28px",
    "& div:first-child": {
      width: "40%",
      minWidth: "110px",
      maxWidth: "110px",
      fontWeight: "bold",
      "& img": {
        width: "80%",
      },
    },
    "& div:last-child": { width: "100%" },
  },
});

const Ndid_info = (props) => {
  const classes = useStyle();
  const iScrollOptions = {
    bounce: true,
    probeType: 2,
    mouseWheelSpeed: 20,
    mouseWheel: true,
    scrollbars: true,
    interactiveScrollbars: true,
    fadeScrollbars: true,
    scrollY: true,
  };
  const [isLoading, setIsLoading] = useState(false);
  const goBack = async () => {
    let token = await localStorage.getItem("token");
    let identifier = await localStorage.getItem("identifier");
    if (token && identifier) {
      identifier = await btoa(identifier);
      props.history.push(
        "/ndid_offline?token=" + token + "&identifier=" + identifier
      );
    } else {
      props.history.push("/product_choice");
    }
  };
  const handleNext = async () => {
    const f0 = JSON.parse(localStorage.getItem("f0"));
    const card_id = f0?.f0_national_id;
    let token = await localStorage.getItem("token");
    let identifier = await localStorage.getItem("identifier");

    if (card_id === "" || card_id === null || card_id === undefined) {
      popupErrorApi("เลขบัตรประชาชนไม่ถูกต้อง", "ไม่สามารถทำรายการได้", true);
    } else {
      await axios
        .get(`${config.baseAPIURL03}/form/ndid2/quota?identifier=${card_id}`)
        .then((res) => {
          setIsLoading(false);
          const { limit, total } = res.data.data;
          if (total < limit) {
            if (token && identifier) {
              props.history.push("/ndid_tc");
            } else {
              props.history.push("/tc_ndid");
            }
          } else {
            popupErrorApi(
              "เนื่องจากท่านทำรายการเปลี่ยนธนาคาร<br/>เกินกว่าจำนวนที่บริษัทกำหนด<br/>กรุณาติดต่อ DAOL Contact Center<br/>0 2351 1800",
              "ไม่สามารถทำรายการได้",
              true,null,'ตกลง'
            );
          }
        })
        .catch((er) => {
          popupErrorApi(
            "กรุณาติดต่อ DAOL Contact Center<br/>0 2351 1800",
            "ไม่สามารถทำรายการได้",
            true
          );
        });
    }
  };
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const onScrollEnd = async (iScrollInstance) => {
    if ((await iScrollInstance.y) <= iScrollInstance.maxScrollY) {
    }
  };
  const onScroll_ = async (iScrollInstance) => {
    if ((await iScrollInstance.y) <= iScrollInstance.maxScrollY) {
    }
  };
  const body = () => {
    return (
      <Box>
        <Grid
          className={clsx(
            "font-title text-center font-DBHeavent font-weight-bold",
            isMobile ? "font-22" : "font-20"
          )}
          dangerouslySetInnerHTML={{
            __html: isMobile
              ? "กรุณาตรวจสอบว่าท่านผ่านการดำเนินการ</br>ด้านล่างเรียบร้อยแล้ว"
              : "กรุณาตรวจสอบว่าท่านผ่านการดำเนินการด้านล่างเรียบร้อยแล้ว",
          }}
        />

        <Grid className="pt-4 pb-4">
          <Box className={clsx(classes.ndidTable, isMobile && "pt-30")}>
            <Box>
              <CardMedia component="img" image="images/Asset1.png" />
            </Box>
            <Box className="font-20 text-primary">
              ผ่านการเสียบบัตรประชาชนและ ถ่ายรูปผ่านช่องทางธนาคาร
            </Box>
          </Box>
          <Box className={clsx(classes.ndidTable, isMobile && "pt-30")}>
            <Box>
              <CardMedia component="img" image="images/Asset3.png" />
            </Box>

            <Box className="font-20 text-primary">
              ลงทะเบียน NDID กับแอปพลิเคชัน K Plus, KMA, Bualuang mBanking,
              MyMo, CIMB TH หรือลงทะเบียนที่สาขาของธนาคารไทยพานิชย์
              เกียรตินาคินภัทร หรือทีเอ็มบีธนชาต
            </Box>
          </Box>
        </Grid>
      </Box>
    );
  };
  useEffect(() => {
    setIsLoading(true);
    sleep(1000);
    setIsLoading(false);
  }, []);
  const iScroll = require("iscroll/build/iscroll-probe");
  return (
    <div className={isMobile ? "" : "body"}>
      <header
        className={isMobile ? "" : "header-area"}
        style={{ display: isMobile ? "none" : "block" }}
      >
        <div className={"container everest-nav-container"}>
          <div className={"row"}>
            <div className={"col-md-6"}>
              <img src="../../../images/logo.png" />
            </div>
            <div className={"col-md-6 flex_end"}>
              <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
            </div>
          </div>
        </div>
      </header>
      <section
        className={isMobile ? "" : "desktop_content section-gap-full"}
        style={{
          backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
        }}
      >
        <div className={isMobile ? "" : "container"}>
          <div className={isMobile ? "" : "row"}>
            <div
              className={"col-md-6"}
              style={{ display: isMobile ? "none" : "block" }}
            >
              <BannerContentLeft></BannerContentLeft>
            </div>
            <div className={isMobile ? "" : "col-md-6"}>
              {/* content mobile */}
              <div>
                <Loading status={isLoading} />
                <div className="content">
                  <div className="top_main_back">
                    <div className="main_back" onClick={goBack}>
                      <img
                        src={
                          isMobile
                            ? "/images/angle-arrow-left.svg"
                            : "/images/angle-arrow-left-blue.svg"
                        }
                        style={{
                          marginLeft: "3px",
                        }}
                        className="arrow_back"
                      />
                    </div>
                    <div className="text_title">
                      พิสูจน์การยืนยันตัวตนทางดิจิทัล (NDID)
                    </div>
                  </div>

                  <div className="bg_content_white over_f_hide" id="my_scroll">
                    <div
                      id="height_scroll_out"
                      className="in_bg_white default"
                      style={{
                        padding: isMobile ? "10px 20px" : "0 20px",
                        bottom: isMobile ? 185 : 100,
                      }}
                    >
                      <ReactIScroll
                        id="my_scroll3"
                        iScroll={iScroll}
                        options={iScrollOptions}
                        onScrollEnd={onScrollEnd}
                        onScroll={onScroll_}
                      >
                        <div
                          id="height_scroll_in"
                          style={{ minHeight: isMobile ? 300 : 500 }}
                        >
                          {body()}
                        </div>
                      </ReactIScroll>
                    </div>

                    <div
                      style={{
                        background: "#fff",
                        bottom: 10,
                        position: "absolute",
                        justifyContent: "flex-end",
                        left: "21px",
                        right: "21px",
                      }}
                    >
                      <div>
                        <div>
                          <ButtonCustom
                            handle={handleNext}
                            label="ถัดไป"
                            color="blue"
                            btnClass="long_btn"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end content mobile */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Ndid_info;
