/* eslint-disable jsx-a11y/alt-text */
import React, { Component, Fragment } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import "./SummaryShow.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
// import Select2 from 'react-select';
import moment from "moment";
import { isMobile } from "react-device-detect";
import Loading from "../../components/loading/loading";
import { popupError } from "../../components/helpers/Helpers";
import { config } from "./../../constants";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
const _url = config.baseAPIURL03;
class SummaryShow extends Component {
  spouseTitleMapping = {
    43: "นาย",
    41: "นาง",
    42: "นางสาว",
  };

  constructor(props) {
    super(props);
    this.state = {
      user_address: JSON.parse(localStorage.getItem("f0")),
      title: [],
      nationality: [],
      purpose_lists: [],
      source_income_choice: [],
      source_income: [],
      source_other_require: false,
      f0_title_other_id: "",
      f1_career: [],
      f3_income: [],
      account_type: [],
      f3_officename: "",
      f1_business_type: [],
      preExpanded: "",
      f1_business_other: "",
      f1_career_other: "",
      f1_career_position: "",
      f1_contact_firstname: "",
      f1_contact_lastname: "",
      f1_contact_mobile: "",
      f1_contact_relate: "",
      bank_name: "",
      loading: true,
      crs_birth_address: {},
      crs_declare_location: {},
    };
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    window.location = "/summary_risk";
    // this.props.history.push("/ats_choice");
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // if(this.validateForm()){

    // }
    this.props.history.push("/tcconfirm");
  };

  path_url_of_alt = (e) => {
    if (isMobile) {
      if (
        e.target.dataset.alt === "SnapFrontId/edit" ||
        e.target.dataset.alt === "SnapBackId/edit"
      ) {
        this.props.history.push("SnapIdCard/edit");
      } else {
        this.props.history.push(e.target.dataset.alt);
      }
    } else if (
      e.target.dataset.alt === "SnapFrontId/edit" ||
      e.target.dataset.alt === "SnapBackId/edit"
    ) {
      // // console.log(e);
      // if (e.target.dataset.alt === 'SnapFrontId/edit') {
      //   console.log(e);
      //   this.props.history.push(e.target.dataset.alt+"");
      // }
      // if (e.target.dataset.alt === 'SnapBackId/edit') {
      //   console.log(e);
      // }
      this.props.history.push("SnapIdCard/edit");
    } else {
      this.props.history.push(e.target.dataset.alt);
    }
  };

  getThaiFormatDate(value) {
    if (!value) {
      return "";
    }

    const date = moment(value, "YYYYMMDD");
    const thaiMonth = {
      "01": "ม.ค.",
      "02": "ก.พ.",
      "03": "มี.ค.",
      "04": "เม.ย.",
      "05": "พ.ค.",
      "06": "มิ.ย.",
      "07": "ก.ค.",
      "08": "ส.ค.",
      "09": "ก.ย.",
      10: "ต.ค.",
      11: "พ.ย.",
      12: "ธ.ค.",
    };

    return `${date.format("DD")} ${thaiMonth[date.format("MM")]} ${date.format(
      "YYYY"
    )}`;
  }

  allStorage() {
    var values = [],
      keys = Object.keys(localStorage),
      i = keys.length;
    while (i--) {
      if (keys[i].indexOf("debug") !== -1) {
      } else {
        values.push({
          key: keys[i],
          value:
            keys[i] === "currentStep"
              ? "summaryshow"
              : localStorage.getItem(keys[i]),
        });
      }
    }
    return values;
  }
  async sendData() {
    const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
    const head_token = HeaderManp.head_token;
    var last_stamp = JSON.stringify(this.allStorage());
    var title_mss = "";
    var mss = "";
    const ops = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
      },
      data: {
        last_stamp: last_stamp,
        head_token: head_token,
      },
      url: _url + "/form/comeback/manp",
    };
    await localStorage.setItem("currentStep", "summaryshow");
    await axios(ops)
      .then(async (res) => {
        this.setState({ loading: false });
        var keys = Object.keys(localStorage),
          i = keys.length;
        while (i--) {
          // console.log(i, keys[i]);
        }
        // this.props.history.push('/ats_choice');
      })
      .catch((error) => {
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss =
            error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
        popupError(mss, title_mss);
        this.setState({ statusLoading: false });
        return;
      });
  }

  async componentDidMount() {
    const vm = this;
    const editPage = await localStorage.getItem("editPage");
    var stamp_page = editPage
      ? editPage === "idc_address_choose"
        ? "JOBS"
        : "SUMMARY"
      : "SUMMARY";
    await vm.setState({ loading: true });
    await localStorage.setItem("currentStep", "summaryshow");
    const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
    const head_token = HeaderManp.head_token;
    var title_mss = "";
    var mss = "";
    var last_stamp = JSON.stringify(vm.allStorage());
    const ops = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
      },
      data: {
        last_stamp: last_stamp,
        head_token: head_token,
        stamp_page: stamp_page,
      },
      url: _url + "/form/comeback/manp",
    };

    await axios(ops)
      .then(async (res) => {
        vm.setState({ loading: false });
        var keys = Object.keys(localStorage),
          i = keys.length;
        while (i--) {
          // console.log(i, keys[i]);
        }
        // console.log('save data to server');
      })
      .catch((error) => {
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss =
            error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
        vm.setState({ statusLoading: false });
        popupError(mss, title_mss);
        return;
      });
    /////////////////////////////////////////////////////////
    this.setState({ loading: true });
    await axios
      .get(_url + "/constant/title")
      .then((res) => {
        const title = res.data.Data.map((item, i) => {
          if (item.Other === 1) {
            this.setState({ f0_title_other_id: item.Id });
          }
          return (
            <option value={item.Id} key={i} style={{ color: "#003b66" }}>
              {item.NameTH}
            </option>
          );
        });
        this.setState({ title: title });
      })
      .catch((error) => {
        vm.setState({ loading: false });
        let title = "เกิดข้อผิดพลาด";
        if (!!error.isAxiosError && !error.response) {
          popupError("กรุณาทำรายการใหม่อีกครั้ง", title);
        } else {
          let errorMessage =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "กรุณาทำรายการใหม่อีกครั้ง";
          popupError(`${errorMessage}`, title);
        }
      });
    await axios
      .get(_url + "/constant/country")
      .then((res) => {
        const nationality = res.data.Data.map((item, i) => {
          return (
            <option value={item.Id} key={i} style={{ color: "#003b66" }}>
              {item.NameTH}
            </option>
          );
        });
        this.setState({
          nationality: nationality,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        let title = "เกิดข้อผิดพลาด";
        if (!!error.isAxiosError && !error.response) {
          popupError("กรุณาทำรายการใหม่อีกครั้ง", title, function name() {
            window.location = "login";
          });
        } else {
          let errorMessage =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "กรุณาทำรายการใหม่อีกครั้ง";
          popupError(`${errorMessage}`, title, function name() {
            window.location = "login";
          });
        }
      });
    const arr_jobs_data = JSON.parse(localStorage.getItem("arr_jobs_data"));
    await axios
      .get(_url + `/constant/business_type`)
      .then((res) => {
        const business_type = res.data.Data.map((item, i) => {
          if (arr_jobs_data && item.Id === arr_jobs_data.f1_business_type) {
            this.setState({
              f1_business_type: {
                value: item.Id,
                label: item.ThName,
                Other: item.Other,
              },
              f1_business_other: arr_jobs_data.f1_business_other,
            });
          }
          return { value: item.Id, label: item.ThName, Other: item.Other };
        });
        // console.log(business_type);
        this.setState({ business_type: business_type });
      })
      .catch((error) => {
        vm.setState({ loading: false });
        let title = "เกิดข้อผิดพลาด";
        if (!!error.isAxiosError && !error.response) {
          popupError("กรุณาทำรายการใหม่อีกครั้ง", title);
        } else {
          let errorMessage =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "กรุณาทำรายการใหม่อีกครั้ง";
          popupError(`${errorMessage}`, title);
        }
      });

    await axios
      .get(_url + `/constant/career`)
      .then((res) => {
        const career_type = res.data.Data.map((item, i) => {
          if (arr_jobs_data && item.Id === arr_jobs_data.f1_career) {
            this.setState({
              f1_career: {
                value: item.Id,
                label: item.ThName,
                type: item.SAmcr_Type,
                Other: item.Other,
              },
              f1_career_other: arr_jobs_data.f1_career_other,
              f1_career_position: arr_jobs_data.f1_career_position,
              f3_officename: arr_jobs_data.f3_officename,
            });
          }
          return {
            value: item.Id,
            label: item.ThName,
            type: item.SAmcr_Type,
            Other: item.Other,
          };
        });
        // console.log(career_type);
        this.setState({ career_type: career_type });
      })
      .catch((error) => {
        vm.setState({ loading: false });
        let title = "เกิดข้อผิดพลาด";
        if (!!error.isAxiosError && !error.response) {
          popupError("กรุณาทำรายการใหม่อีกครั้ง", title);
        } else {
          let errorMessage =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "กรุณาทำรายการใหม่อีกครั้ง";
          popupError(`${errorMessage}`, title);
        }
      });

    await axios
      .get(_url + `/constant/income`)
      .then((res) => {
        const income_type = res.data.Data.map((item, i) => {
          if (arr_jobs_data && item.Id === arr_jobs_data.f3_income) {
            this.setState({
              f3_income: { value: item.Id, label: item.ThName },
            });
          }
          return { value: item.Id, label: item.ThName };
        });
        // console.log(income_type);
        this.setState({ income_type: income_type });
      })
      .catch((error) => {
        vm.setState({ loading: false });
        let title = "เกิดข้อผิดพลาด";
        if (!!error.isAxiosError && !error.response) {
          popupError("กรุณาทำรายการใหม่อีกครั้ง", title);
        } else {
          let errorMessage =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "กรุณาทำรายการใหม่อีกครั้ง";
          popupError(`${errorMessage}`, title);
        }
      });

    await axios
      .get(_url + "/constant/purpose")
      .then(async (res) => {
        const f3 = JSON.parse(localStorage.getItem("f3"));
        const purpose_json_check = f3 !== null ? f3.purpose_json : [];
        // const f3_purpose_other_text = '';
        const purpose_lists = res.data.Data.map((item, i) => {
          const checkUsername = (obj) => obj.SAfpp_SAmpp_Id === item.SAmpp_Id;
          const getPurpose_other_text = (element, index, array) => {
            if (element.SAfpp_SAmpp_Id === item.SAmpp_Id) {
              // console.log(55);
              this.setState({
                f3_purpose_other_text: element.SAfpp_Text,
                purpose_other_require: true,
              });
            }
          };
          if (f3 !== null) {
            if (purpose_json_check.some(checkUsername) && item.Other === 1) {
              var Purpose_other_text = purpose_json_check.some(
                getPurpose_other_text
              );
            }
            return {
              id: item.SAmpp_Id,
              text: item.SAmpp_Name,
              isCheck: purpose_json_check.some(checkUsername),
              Other: item.Other,
              SAmpp_EnName: item.SAmpp_EnName,
            };
          } else {
            return {
              id: item.SAmpp_Id,
              text: item.SAmpp_Name,
              isCheck: false,
              Other: item.Other,
              SAmpp_EnName: item.SAmpp_EnName,
            };
          }
        });
        this.setState({
          purpose_lists: purpose_lists,
        });
      })
      .catch((error) => {
        vm.setState({ loading: false });
        let title = "เกิดข้อผิดพลาด";
        if (!!error.isAxiosError && !error.response) {
          popupError("กรุณาทำรายการใหม่อีกครั้ง", title);
        } else {
          let errorMessage =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "กรุณาทำรายการใหม่อีกครั้ง";
          popupError(`${errorMessage}`, title);
        }
      });

    await axios
      .get(_url + `/constant/source_income`)
      .then((res) => {
        const source_income = res.data.Data.map((item, i) => {
          return (
            <option value={item.Mfnt_Id} key={i} style={{ color: "#003b66" }}>
              {item.Mfnt_NameTh}
            </option>
          );
        });
        this.sourceIncomes = res.data.Data;
        const f3 = JSON.parse(localStorage.getItem("f3"));
        const f3_source = f3 ? f3.f3_source : "";
        let sourceOtherRequired = false;
        if (f3_source) {
          const selectedSourceIncome = this.sourceIncomes.find(
            (item) => String(item.Mfnt_Id) === f3_source
          );
          sourceOtherRequired =
            selectedSourceIncome && selectedSourceIncome.Other === 1;
        }

        this.setState({
          source_income: source_income,
          source_other_require: sourceOtherRequired,
        });
      })
      .catch((error) => {
        vm.setState({ loading: false });
        let title = "เกิดข้อผิดพลาด";
        if (!!error.isAxiosError && !error.response) {
          popupError("กรุณาทำรายการใหม่อีกครั้ง", title);
        } else {
          let errorMessage =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "กรุณาทำรายการใหม่อีกครั้ง";
          popupError(`${errorMessage}`, title);
        }
      });

    await axios
      .get(_url + `/constant/source_income_choice`)
      .then((res) => {
        const f3 = JSON.parse(localStorage.getItem("f3"));
        const source_income_choice = res.data.Data.map((item, i) => {
          let isChk = false;
          if (f3) {
            if (
              item.Mfnc_NameTh.includes("เงินเดือน") &&
              f3.f3_by_employee === "1"
            ) {
              isChk = true;
            }
            if (
              item.Mfnc_NameTh.includes("มรดก") &&
              f3.f3_by_heritage === "1"
            ) {
              isChk = true;
            }
            if (
              item.Mfnc_NameTh.includes("เงินออม") &&
              f3.f3_by_saving === "1"
            ) {
              isChk = true;
            }
            if (
              item.Mfnc_NameTh.includes("การลงทุน") &&
              f3.f3_by_invest === "1"
            ) {
              isChk = true;
            }
            if (
              item.Mfnc_NameTh.includes("เงินเกษียณ") &&
              f3.f3_by_retired === "1"
            ) {
              isChk = true;
            }
            if (
              item.Mfnc_NameTh.includes("ประกอบธุรกิจ") &&
              f3.f3_by_private_business === "1"
            ) {
              isChk = true;
            }
            if (item.Mfnc_NameTh.includes("อื่นๆ") && f3.f3_by_other === "1") {
              isChk = true;
              this.setState({ income_other_require: true });
            }
          } else {
            isChk = false;
          }
          return {
            id: item.Mfnc_Id,
            text: item.Mfnc_NameTh,
            isCheck: isChk,
            Other: item.Other,
            Mfnc_NameEn: item.Mfnc_NameEn,
          };
        });
        // console.log(source_income_choice);
        this.setState({ source_income_choice: source_income_choice });
      })
      .catch((error) => {
        vm.setState({ loading: false });
        let title = "เกิดข้อผิดพลาด";
        if (!!error.isAxiosError && !error.response) {
          popupError("กรุณาทำรายการใหม่อีกครั้ง", title);
        } else {
          let errorMessage =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "กรุณาทำรายการใหม่อีกครั้ง";
          popupError(`${errorMessage}`, title);
        }
      });

    await axios
      .get(_url + `/constant/invest`)
      .then((res) => {
        let invest_option = res.data.Data.map((item, i) => {
          return (
            <option value={item.Id} key={i} style={{ color: "#003b66" }}>
              {item.ThName}
            </option>
          );
        });
        this.setState({
          invest_option: invest_option,
        });
      })
      .catch((error) => {
        vm.setState({ loading: false });
        let title = "เกิดข้อผิดพลาด";
        if (!!error.isAxiosError && !error.response) {
          popupError("กรุณาทำรายการใหม่อีกครั้ง", title);
        } else {
          let errorMessage =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "กรุณาทำรายการใหม่อีกครั้ง";
          popupError(`${errorMessage}`, title);
        }
      });

    await axios
      .get(_url + `/constant/outcome`)
      .then((res) => {
        let outcome_option = res.data.Data.map((item, i) => {
          return (
            <option value={item.Id} key={i} style={{ color: "#003b66" }}>
              {item.ThName}
            </option>
          );
        });
        this.setState({
          outcome_option: outcome_option,
        });
      })
      .catch((error) => {
        vm.setState({ loading: false });
        let title = "เกิดข้อผิดพลาด";
        if (!!error.isAxiosError && !error.response) {
          popupError("กรุณาทำรายการใหม่อีกครั้ง", title);
        } else {
          let errorMessage =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "กรุณาทำรายการใหม่อีกครั้ง";
          popupError(`${errorMessage}`, title);
        }
      });

    await axios
      .get(_url + "/constant/bank")
      .then((res) => {
        const bank = res.data.Data.map((item, i) => {
          const atsDataUser = localStorage.getItem("atsDataUser")
            ? JSON.parse(localStorage.getItem("atsDataUser"))
            : {};
          const bank_id = atsDataUser ? atsDataUser.bank_id : "";
          if (item.Id == bank_id) {
            this.setState({ bank_name: item.ThName });
          } else {
            this.setState({ bank_name: atsDataUser.bank_name });
          }
        });
      })
      .catch((error) => {
        vm.setState({ loading: false });
        let title = "เกิดข้อผิดพลาด";
        if (!!error.isAxiosError && !error.response) {
          popupError("กรุณาทำรายการใหม่อีกครั้ง", title);
        } else {
          let errorMessage =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "กรุณาทำรายการใหม่อีกครั้ง";
          popupError(`${errorMessage}`, title);
        }
      });
    await axios
      .get(
        `${config.baseAPIURL03}/form/product/selbyhead?head_token=${HeaderManp.head_token}`
      )
      .then((res) => {
        if (res.request.status !== 200) {
          throw new Error("error");
        }
        this.setState({ account_type: res?.data?.Data || [] });
      })
      .catch((error) => {
        vm.setState({ loading: false });
        let title = "เกิดข้อผิดพลาด";
        if (!!error.isAxiosError && !error.response) {
          popupError("กรุณาทำรายการใหม่อีกครั้ง", title);
        } else {
          let errorMessage =
            error?.response?.data?.Data[0][0]?.MsgDesc !== ""
              ? error?.response?.data?.Data[0][0]?.MsgDesc
              : "กรุณาทำรายการใหม่อีกครั้ง";
          popupError(`${errorMessage}`, title);
        }
      });
    await this.setState({ preExpanded: editPage !== "" ? editPage : "" });
    await localStorage.setItem("editPage", "");
    const items = document.querySelector("#accordion__heading-" + editPage);
    if (items) {
      await items.parentNode.parentNode.scrollIntoView({ behavior: "smooth" });
      await this.setState({ loading: false });
    } else {
      await this.setState({ loading: false });
    }

    let crs_birth_address_data = localStorage.getItem('crs_birth_address')
    if (crs_birth_address_data) {
      crs_birth_address_data = JSON.parse(crs_birth_address_data)
      await this.setState({crs_birth_address: crs_birth_address_data})
    } else {
      // get from api
    }

    let crs_declare_location_data = localStorage.getItem('crs_declare_location')
    if (crs_declare_location_data) {
      crs_declare_location_data = JSON.parse(crs_declare_location_data)
      await this.setState({crs_declare_location: crs_declare_location_data})
    } else {
      // get from api
    }
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  handleCrsEditBirthAddress = () => {
    this.props.history.push('crs_birth_address', {is_from_summary: true})
  }

  handleCrsEditDeclareLocation = () => {
    this.props.history.push('crs_declare_location', {is_from_summary: true, prev_crs_declare_location: this.state.crs_declare_location})
  }

  render() {
    const trimmedDataURL = localStorage.getItem("trimmedDataURL");
    const title = this.state.title;
    const nationality = this.state.nationality;
    library.add(fas);
    const IDAddress = JSON.parse(localStorage.getItem("IDAddress"));
    const HomeAddress = JSON.parse(localStorage.getItem("HomeAddress"));
    const ContactAddress = JSON.parse(localStorage.getItem("ContactAddress"));
    const WorkAddress = JSON.parse(localStorage.getItem("WorkAddress"));
    const f1_career =
      this.state.f1_career.Other === 1
        ? this.state.f1_career_other
        : this.state.f1_career.label;
    const f1_career_position = this.state.f1_career_position;
    const f1_business_type =
      this.state.f1_business_type.Other === 1
        ? this.state.f1_business_other
        : this.state.f1_business_type.label;
    const f3_income = this.state.f3_income.label;
    const f1_contact = localStorage.getItem("f1_contact")
      ? JSON.parse(localStorage.getItem("f1_contact"))
      : {};
    const f3_officename = this.state.f3_officename;
    // Spouse Data
    const {
      f1_marital_status,
      f1_mobile_phone_1,
      f1_spouse_birth_date,
      f1_spouse_firstname,
      f1_spouse_firstname_en,
      f1_spouse_id_card,
      f1_spouse_lastname,
      f1_spouse_lastname_en,
      f1_spouse_radio,
      f1_spouse_title,
      f1_spouse_title_text,
    } = localStorage.getItem("f1")
        ? JSON.parse(localStorage.getItem("f1"))
        : {};
    // Children Data
    const children_json = localStorage.getItem("children_json")
      ? JSON.parse(localStorage.getItem("children_json"))
      : {};
    const childrenList =
      children_json.length > 0 ? (
        <div>
          {children_json.map((item, i) => (
            <div key={item + i}>
              <div className="title_content_summary">
                ข้อมูลบุตรคนที่ {i + 1}
              </div>

              <div className="row_idcard" style={{ paddingTop: 0 }}>
                <div>เลขที่บัตรประจำตัวประชาชน *</div>
                <div className="input_idcard">{item.SAfc_CardID}</div>
              </div>
              <div className="row_idcard">
                <div>ชื่อ *</div>
                <div className="input_idcard">
                  {item.SAfc_THName !== ""
                    ? item.SAfc_THName
                    : item.SAfc_ENName}
                </div>
              </div>
              <div className="row_idcard">
                <div>นามสกุล *</div>
                <div className="input_idcard">
                  {item.SAfc_THSurname !== ""
                    ? item.SAfc_THSurname
                    : item.SAfc_ENSurname}
                </div>
              </div>
              <div className="row_idcard">
                <div>วันเดือนปี (ค.ศ.) เกิด *</div>
                <div
                  className="input_idcard"
                // value={this.getThaiFormatDate(item.SAfc_Birthdate_show)}
                >
                  {this.getThaiFormatDate(item.SAfc_Birthdate)}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        ""
      );
    const f1_contact_firstname = f1_contact.f1_contact_firstname;
    const f1_contact_lastname = f1_contact.f1_contact_lastname;
    const f1_contact_mobile = f1_contact.f1_contact_mobile;
    const f1_contact_relate = f1_contact.f1_contact_relate;
    const source_income = this.state.source_income;
    const source_other_require = this.state.source_other_require;
    const f3 = JSON.parse(localStorage.getItem("f3"));
    const f3_ = {
      f3_source: f3 ? f3.f3_source : "",
      f3_source_text: f3 ? f3.f3_source_text : "",
      f3_by_private_business: f3 ? f3.f3_by_private_business : "",
      f3_by_employee: f3 ? f3.f3_by_employee : "",
      f3_by_heritage: f3 ? f3.f3_by_heritage : "",
      f3_by_borrowing: f3 ? f3.f3_by_borrowing : "",
      f3_by_divided: f3 ? f3.f3_by_divided : "",
      f3_by_retired: f3 ? f3.f3_by_retired : "",
      f3_by_saving: f3 ? f3.f3_by_saving : "",
      f3_by_other: f3 ? f3.f3_by_other : "",
      f3_by_other_text: f3 ? f3.f3_by_other_text : "",
      f3_income: f3 ? f3.f3_income : "",
      f3_outcome: f3 ? f3.f3_outcome : "",
      f3_invest: f3 ? f3.f3_invest : "",
      f3_valuable: f3 ? f3.f3_valuable : "",
      f3_own: f3 ? f3.f3_own : "",
      purpose_json: f3 ? f3.purpose_json : [],
    };
    const invest_option = this.state.invest_option;
    const outcome_option = this.state.outcome_option;
    const atsDataUser = localStorage.getItem("atsDataUser")
      ? JSON.parse(localStorage.getItem("atsDataUser"))
      : {};
    const f5_relationship = localStorage.getItem("f5_relationship")
      ? JSON.parse(localStorage.getItem("f5_relationship"))
      : {};
    const f5_other_information = localStorage.getItem("f5_other_information")
      ? JSON.parse(localStorage.getItem("f5_other_information"))
      : {};
    const ktbst_summary_risk = localStorage.getItem("summary_risk")
      ? JSON.parse(localStorage.getItem("summary_risk"))
      : [];
    const f0_title_other_id = this.state.f0_title_other_id;
    const bank_name = this.state.bank_name;
    const Example = () => (
      <Accordion
        allowZeroExpanded={true}
        expanded="true"
        preExpanded={[this.state.preExpanded]}
      >
        {/* <AccordionItem>
          <div
            className="accordion__button accordion_button_11"
            aria-disabled="false"
            aria-expanded="false"
            aria-controls="accordion__panel-raa-0"
            role="button"
            data-accordion-component="AccordionItemButton"
          >
            <span style={{ fontWeight: '600', fontSize: '22px' }}>
              สถานะพลเมืองอเมริกัน
            </span>{' '}
            <br></br>
            <span style={{ color: '#023b66' }}>ไม่เป็นพลเมืองอเมริกัน</span>
          </div>
        </AccordionItem> */}

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="Header_ac">ข้อมูลตามบัตรประจำตัวประชาชน</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="row_idcard" style={{ paddingTop: 0 }}>
              <div className="label_idcard">
                เลขที่บัตรประจำตัวประชาชน <span>*</span>
              </div>
              <div className="input_idcard">
                {this.state.user_address.f0_national_id}
              </div>
            </div>
            <div className="row_idcard">
              <div>
                หมายเลขเลเซอร์การ์ดหลังบัตร <span>*</span>
              </div>
              <div className="input_idcard">
                {this.state.user_address.f0_lazer_card}
              </div>
            </div>
            <div className="row_idcard">
              <div>
                วันหมดอายุ (ค.ศ.) <span>*</span>
              </div>

              {this.state.user_address.f0_expired_date === "99991231" ? (
                <input
                  className="input_idcard"
                  value={"บัตรประจำตัวประชาชนประเภทตลอดชีพ"}
                  disabled
                />
              ) : (
                <div className="input_idcard">
                  {this.getThaiFormatDate(
                    this.state.user_address.f0_expired_date
                  )}
                </div>
              )}
            </div>
            <div className="row_idcard">
              <div>
                คำนำหน้าชื่อ <span>*</span>
              </div>
              {/* <input className='input_idcard' value={'นางสาว'}/> */}
              <div className="main_select">
                <select
                  disabled
                  className="select_style"
                  style={{
                    backgroundColor: "#fff",
                    opacity: 1,
                    display:
                      this.state.user_address.f0_title === f0_title_other_id
                        ? "none"
                        : "block",
                  }}
                  name="f0_title"
                  value={this.state.user_address.f0_title}
                >
                  <option
                    value=""
                    style={{ color: "#003b6694" }}
                    disabled
                    hidden
                  >
                    กรุณาเลือก
                  </option>
                  {title}
                </select>
                <select
                  disabled
                  className="select_style"
                  style={{
                    backgroundColor: "#fff",
                    opacity: 1,
                    display:
                      this.state.user_address.f0_title === f0_title_other_id
                        ? "block"
                        : "none",
                  }}
                  name="f0_title"
                  value={this.state.user_address.f0_title}
                >
                  <option value={this.state.user_address.f0_title}>
                    {this.state.user_address.f0_title_text}
                  </option>
                </select>
              </div>
            </div>
            <div className="row_idcard">
              <div>
                ชื่อ (ภาษาไทย) <span>*</span>
              </div>
              <div className="input_idcard">
                {this.state.user_address.f0_firstname}
              </div>
            </div>
            <div className="row_idcard">
              <div>
                ชื่อกลาง (ภาษาไทย)
              </div>
              <div className="input_idcard">
                {this.state.user_address.f0_middlename}
              </div>
            </div>
            <div className="row_idcard">
              <div>
                นามสกุล (ภาษาไทย) <span>*</span>
              </div>
              <div className="input_idcard">
                {this.state.user_address.f0_lastname}
              </div>
            </div>
            <div className="row_idcard">
              <div>
                ชื่อ (ภาษาอังกฤษ) <span>*</span>
              </div>
              <div className="input_idcard">
                {this.state.user_address.f0_firstname_eng}
              </div>
            </div>
            <div className="row_idcard">
              <div>
                ชื่อกลาง (ภาษาอังกฤษ)
              </div>
              <div className="input_idcard">
                {this.state.user_address.f0_middlename_eng}
              </div>
            </div>
            <div className="row_idcard">
              <div>
                นามสกุล (ภาษาอังกฤษ) <span>*</span>
              </div>
              <div className="input_idcard">
                {this.state.user_address.f0_lastname_eng}
              </div>
            </div>
            <div className="row_idcard">
              <div>
                ประเทศเจ้าของสัญชาติ <span>*</span>
              </div>
              <div className="main_select">
                <select
                  disabled
                  className="select_style"
                  style={{
                    backgroundColor: "#fff",
                    opacity: 1,
                  }}
                  name="f0_nationality"
                  value={this.state.user_address.f0_nationality.value}
                >
                  <option value={this.state.user_address.f0_nationality.value}>
                    {this.state.user_address.f0_nationality.label}
                  </option>
                  {/* {nationality} */}
                </select>
              </div>
            </div>
            <div className="row_idcard">
              <div>
                วันเดือนปี (ค.ศ.) เกิด <span>*</span>
              </div>
              <div className="input_idcard">
                {this.getThaiFormatDate(this.state.user_address.f0_date_birth)}
              </div>
            </div>
            <div className="row_idcard">
              <div>
                เบอร์โทรศัพท์มือถือ <span>*</span>
              </div>
              <div className="input_idcard">
                {this.state.user_address.f0_mobile}
              </div>
            </div>
            <div className="row_idcard">
              <div>
                อีเมล <span>*</span>
              </div>
              <div className="input_idcard">
                {this.state.user_address.f0_email}
              </div>

              <div className="main_btn_pen">
                {" "}
                {/* <div className="btn_pen" data-alt="idc_detail/edit" onClick={this.path_url_of_alt}>
                  แก้ไขข้อมูล
                </div> */}
              </div>
              <span style={{ color: "red", fontWeight: "bold", fontSize: 18 }}>
                *หมายเหตุ บริษัทจะจัดส่งเอกสารทางอีเมลนี้
              </span>
            </div>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="Header_ac">สถานที่เกิด</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="row_idcard">
              <div>
                ประเทศที่เกิด <span>*</span>
              </div>
              <div className="input_idcard">
                {this.state.crs_birth_address.birth_country?.label}
              </div>
            </div>
            {this.state.crs_birth_address.input_birth_province ? (
              <div className="row_idcard">
                <div>
                  เมืองที่เกิด <span>*</span>
                </div>
                <div className="input_idcard">
                  {this.state.crs_birth_address.input_birth_province}
                </div>
              </div>
            ) : (
              <div className="row_idcard">
                <div>
                  จังหวัด <span>*</span>
                </div>
                <div className="input_idcard">
                  {this.state.crs_birth_address.birth_province?.label}
                </div>
              </div>
            )}
            <div className="main_btn_pen">
              <button 
                className="btn_pen"
                style={{ marginTop: "20px", border: 0, cursor: 'pointer'}}
                onClick={this.handleCrsEditBirthAddress}
              >แก้ไขข้อมูล</button>
            </div>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="Header_ac">ถิ่นที่อยู่ทางภาษี</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {this.state.crs_declare_location.tax_country_list?.map(x => (
              <div>
                <div className="row_idcard">
                  <div>
                    ประเทศที่อยู่ทางภาษี <span>*</span>
                  </div>
                  <div className="input_idcard" style={{marginBottom: '0px'}}>
                    {x.country.country}
                  </div>
                </div>
                {x.no_tax_choice ? (
                  <div className="row_idcard">
                    <div>
                      ไม่มีหมายเลขประจำตัวผู้เสียภาษี <span>*</span>
                    </div>
                    <div className="input_idcard">
                      {x.no_tax_choice == 1 ? "ประเทศที่ระบุข้างต้น ไม่ได้มีการออกเลขประจำตัวผู้เสียภาษี" : x.no_tax_choice == 2 ? "ยังไม่ได้รับหมายเลขประจำตัวผู้เสียภาษี" : "ไม่จำเป็นต้องให้หรือเปิดเผยเลขประจําตัวผู้เสียภาษี"}
                    </div>
                  </div>
                ) : (
                  <div className="row_idcard">
                    <div>
                      หมายเลขประจำตัวผู้เสียภาษี <span>*</span>
                    </div>
                    <div className="input_idcard">
                      {x.tax_no}
                    </div>
                  </div>
                )}
              </div>
            ))}
            <div className="main_btn_pen">
              <button 
                className="btn_pen"
                style={{ marginTop: "20px", border: 0, cursor: 'pointer'}}
                onClick={this.handleCrsEditDeclareLocation}
              >แก้ไขข้อมูล</button>
            </div>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="SnapIdCard">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="Header_ac">การยืนยันตัวตน</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {/* <p className="front_card_p">
                ถ่ายรูปบัตรประจำตัวประชาชน
                <span style={{ textDecoration: 'underline' }}>ด้านหน้า</span>
                <div style={{ width: '100%', height: '250px',  position: 'relative', top: '0px' }}>
                    <img src='/images/id_card_front.png' style={{ margin: 'auto', display: 'block', paddingTop: '25px' }} />
                </div>
            </p> */}
            <div className="DivDetailTitleSnapIdOk">
              ภาพถ่ายบัตรประจำตัวประชาชน{" "}
              <span style={{ textDecoration: "Underline" }}>ด้านหน้า</span>
            </div>
            <div className="DivFrameSnapOkPic">
              <div className="DivFrameSnapOkPicContent">
                {/* <img
                  src="/images/btn_edit.png"
                  data-alt="SnapFrontId/edit"
                  className="BtnEditSnap"
                  onClick={this.path_url_of_alt}
                /> */}
                <div className="main_img_idcard">
                  <img
                    src={localStorage.getItem("photo1")}
                    alt="id_card_front"
                    className="DivFrameSnapOk"
                  />
                </div>
              </div>
            </div>
            {/*<div className="DivDetailTitleSnapIdOk">
              ภาพถ่ายบัตรประจำตัวประชาชน{" "}
              <span style={{ textDecoration: "Underline" }}>ด้านหลัง</span>
            </div>
            <div className="DivFrameSnapOkPic" style={{ margin: "0 0 12px 0" }}>
              <div className="DivFrameSnapOkPicContent">
                {/* <img
                  src="/images/btn_edit.png"
                  data-alt="SnapBackId/edit"
                  className="BtnEditSnap"
                  onClick={this.path_url_of_alt}
                /> 
                <div className="main_img_idcard">
                  <img
                    src={localStorage.getItem("photo2")}
                    alt="id_card_back"
                    className="DivFrameSnapOk"
                  />
                </div>
              </div>
                </div>*/}
            <div className="main_btn_pen">
              <div
                className="btn_pen"
                data-alt="SnapIdCard/edit"
                onClick={this.path_url_of_alt}
                style={{ marginTop: "20px" }}
              >
                แก้ไขข้อมูล
              </div>
            </div>

            {/* <p className="back_card_p">
                            ถ่ายรูปบัตรประจำตัวประชาชน <span style={{ textDecoration: 'underline' }}>ด้านหลัง</span>
                            <div style={{ width: '100%', height: '250px',  position: 'relative', top: '0px' }}>
                                <img src='/images/id_card_back.png' style={{  width: '100%',margin: 'auto', display: 'block', paddingTop: '25px' }} />
                            </div>
                        </p> */}
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="product_choice">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="Header_ac">ประเภทบัญชี</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {this.state.account_type?.map((e, index) => (
              <div
                className="row_idcard"
                key={index}
                style={{
                  display: e.items?.find((i) => i.selected === 1) ? "" : "none",
                }}
              >
                <div>{e.group}*</div>
                {e.items?.map((item, id) => (
                  <div
                    key={id}
                    style={{
                      display: item.selected === 1 ? "block" : "none",
                      border: "none",
                    }}
                    className="input_idcard"
                  >
                    <FontAwesomeIcon
                      icon="check"
                      style={{
                        fontSize: 14,
                        display: item.selected === 1 ? "inline" : "none",
                        marginRight: 12,
                        color: "#a6ce4e",
                      }}
                    ></FontAwesomeIcon>
                    {item.name}
                  </div>
                ))}
              </div>
            ))}
            <div className="">
              <div className="main_btn_pen">
                <div
                  className="btn_pen"
                  data-alt="product_choice/edit"
                  onClick={this.path_url_of_alt}
                  style={{ marginTop: "20px" }}
                >
                  แก้ไขข้อมูล
                </div>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="ktbst_idc_address_choose">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="Header_ac">รายละเอียดที่อยู่ทั้งหมด</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="summary_title_address" style={{ paddingTop: 0 }}>
              ที่อยู่ตามบัตรประจำตัวประชาชน *
            </div>
            <div className="content_address add">
              {/* <div className='text_top_address'>ใช้ที่อยู่ตามบัตรประชาชน</div> */}
              <div className="text_bottom_address">
                {IDAddress ? IDAddress.f2_nric_address : ""}
                {/* เลขที่ 1052 แชมเบอร​์ คอนโด ชั้น 7 ซอย จตุจักร 89 ถนน จตุจักร แขวงจัทรเกษม เขตจตุจักร กรุงเทพมหานคร 10310 */}
              </div>
            </div>
            {/* <div
              style={{
                color: '#000',
                fontSize: 24,
                fontWeight: 'bold',
                padding: '21px 0 8px 0',
              }}
            >
              ที่อยู่ตามทะเบียนบ้าน*
            </div>
            <div className="content_address add ">
              <div className="text_bottom_address">
                {HomeAddress.f2_home_address}
              </div>
            </div> */}
            <div className="summary_title_address">
              ที่อยู่ปัจจุบัน/ที่อยู่สำหรับจัดส่งเอกสาร *
            </div>
            <div className="content_address add ">
              {/* <div className='text_top_address'>ที่อยู่ตามบัตรประชาชน</div> */}
              <div className="text_bottom_address">
                {ContactAddress ? ContactAddress.f2_current_address : ""}
              </div>
            </div>
            <div className="summary_title_address">
              ที่อยู่ที่ทำงาน/ที่อยู่ที่ติดต่อได้ *
            </div>
            <div className="content_address add ">
              {/* <div className='text_top_address'>ที่อยู่ตามบัตรประชาชน</div> */}
              <div className="text_bottom_address">
                {WorkAddress ? WorkAddress.f2_workplace_address : ""}
              </div>
            </div>

            <div className="">
              <div className="main_btn_pen">
                <div
                  className="btn_pen"
                  data-alt="idc_address_choose/edit"
                  onClick={this.path_url_of_alt}
                  style={{ marginTop: "20px" }}
                >
                  แก้ไขข้อมูล
                </div>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="Jobs">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="Header_ac">ข้อมูลอาชีพ</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="row_idcard" style={{ paddingTop: 0 }}>
              <div>
                อาชีพ <span>*</span>
              </div>
              <div className="input_idcard">{f1_career}</div>
            </div>
            <div
              className="row_idcard"
              style={{ display: f1_business_type == null ? "none" : "block" }}
            >
              <div>ประเภทธุรกิจ </div>
              <div className="input_idcard">{f1_business_type}</div>
            </div>
            <div className="row_idcard">
              <div>
                รายได้ต่อเดือน <span>*</span>
              </div>
              <div className="input_idcard">{f3_income}</div>
            </div>
            <div className="row_idcard">
              <div>
                ตำแหน่งงาน <span>*</span>
              </div>
              <div className="input_idcard">{f1_career_position}</div>
            </div>
            {f3_officename ? (
              <div className="row_idcard">
                <div>
                  ชื่อสถานที่ทำงาน <span>*</span>
                </div>
                <div className="input_idcard">{f3_officename}</div>
              </div>
            ) : (
              ""
            )}
            <div className="">
              <div className="main_btn_pen">
                <div
                  className="btn_pen"
                  data-alt="jobs/edit"
                  onClick={this.path_url_of_alt}
                  style={{ marginTop: "20px" }}
                >
                  แก้ไขข้อมูล
                </div>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="FamilyStatus">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="Header_ac">สถานภาพทางครอบครัว</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="row_idcard" style={{ paddingTop: 0 }}>
              <div>สถานภาพทางครอบครัว </div>
              <div className="input_idcard">
                {f1_marital_status === "2" ? "สมรส" : "โสด"}
              </div>
            </div>

            {f1_marital_status === "2" ? (
              <Fragment>
                <div>ข้อมูลคู่สมรส </div>
                <div className="row_idcard">
                  <div style={{ whiteSpace: "normal" }}>
                    เลขที่บัตรประจำตัวประชาชน / หนังสือเดินทาง(กรณีต่างชาติ) *
                  </div>
                  <div className="input_idcard">{f1_spouse_id_card}</div>
                </div>
                <div className="row_idcard">
                  <div>คำนำหน้าชื่อ *</div>
                  {f1_spouse_title === f0_title_other_id ? (
                    <div className="input_idcard">{f1_spouse_title_text}</div>
                  ) : (
                    <div className="input_idcard">
                      {this.spouseTitleMapping[f1_spouse_title]
                        ? this.spouseTitleMapping[f1_spouse_title]
                        : "-"}
                    </div>
                  )}
                </div>
                <div
                  className="row_idcard"
                  style={{
                    display: f1_spouse_firstname === "" ? "none" : "block",
                  }}
                >
                  <div>ชื่อ (ภาษาไทย) *</div>
                  <div className="input_idcard">{f1_spouse_firstname}</div>
                </div>
                <div
                  className="row_idcard"
                  style={{
                    display: f1_spouse_lastname === "" ? "none" : "block",
                  }}
                >
                  <div>นามสกุล (ภาษาไทย) *</div>
                  <div className="input_idcard">{f1_spouse_lastname}</div>
                </div>
                <div className="row_idcard">
                  <div>ชื่อ (ภาษาอังกฤษ) *</div>
                  <div className="input_idcard">{f1_spouse_firstname_en}</div>
                </div>
                <div className="row_idcard">
                  <div>นามสกุล (ภาษาอังกฤษ) *</div>
                  <div className="input_idcard">{f1_spouse_lastname_en}</div>
                </div>
                {/* <div className="row_idcard">
                  <div>วันเดือนปี (ค.ศ.) เกิด *</div>
                  <div className="input_idcard">{this.getThaiFormatDate(f1_spouse_birth_date)}</div>
                </div>
                <div className="row_idcard">
                  <div>เบอร์โทรศัพท์มือถือ *</div>
                  <div className="input_idcard">{f1_mobile_phone_1}</div>
                </div> */}
              </Fragment>
            ) : (
              ""
            )}

            <div className="">
              <div className="main_btn_pen">
                <div
                  className="btn_pen"
                  data-alt="FamilyStatus/edit"
                  onClick={this.path_url_of_alt}
                  style={{ marginTop: "20px" }}
                >
                  แก้ไขข้อมูล
                </div>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="statusChildren">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="Header_ac">บุตรที่ยังไม่บรรลุนิติภาวะ</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="row_idcard" style={{ paddingTop: 0 }}>
              <div>บุตรที่ยังไม่บรรลุนิติภาวะ </div>
              <div className="input_idcard">
                {children_json.length > 0 ? "มี" : "ไม่มี"}
              </div>
            </div>
            {children_json.length > 0
              ? childrenList
              : // <div className="row_idcard" style={{ paddingTop: 0 }}>
              //   <div>บุตรที่ยังไม่บรรลุนิติภาวะ </div>
              //   <input
              //     className="input_idcard"
              //     value={"ไม่มีบุตร"}
              //     disabled
              //   />
              // </div>
              ""}
            <div className="">
              <div className="main_btn_pen">
                <div
                  className="btn_pen"
                  data-alt="statusChildren/edit"
                  onClick={this.path_url_of_alt}
                  style={{ marginTop: "20px" }}
                >
                  แก้ไขข้อมูล
                </div>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>

        <div style={{ display: "none" }}>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="Header_ac">
                  บุคคลที่สามารถติดต่อได้กรณีฉุกเฉิน
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="row_idcard" style={{ paddingTop: 0 }}>
                <div>ชื่อ (ภาษาไทย)* </div>
                <div className="input_idcard">{f1_contact_firstname}</div>
              </div>
              <div className="row_idcard">
                <div>นามสกุล (ภาษาไทย) * </div>
                <div className="input_idcard">{f1_contact_lastname}</div>
              </div>
              <div className="row_idcard">
                <div>เบอร์โทรศัพท์ * </div>
                <div className="input_idcard">{f1_contact_mobile}</div>
              </div>
              <div className="row_idcard">
                <div>ความสัมพันธ์ * </div>
                <div className="input_idcard">{f1_contact_relate}</div>
              </div>

              <div className="">
                <div className="main_btn_pen">
                  <div
                    className="btn_pen"
                    data-alt="EmergencyContact/edit"
                    onClick={this.path_url_of_alt}
                    style={{ marginTop: "20px" }}
                  >
                    แก้ไขข้อมูล
                  </div>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </div>

        <AccordionItem uuid="PoliticianRelation">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="Header_ac">สัมพันธ์กับนักการเมือง</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {f5_relationship ? (
              <div>
                <div className="row_idcard" style={{ paddingTop: 0 }}>
                  <div>สัมพันธ์กับนักการเมือง *</div>
                  <div className="input_idcard">
                    {f5_relationship.f5_relationship_have_a_position === 1
                      ? "ใช่ ฉันเกี่ยวข้องกับนักการเมือง"
                      : "ไม่ใช่ ฉันไม่เกี่ยวข้องกับนักการเมือง"}
                  </div>
                </div>
                <div
                  className="row_idcard"
                  style={{ paddingTop: 0 }}
                  style={{
                    display:
                      f5_relationship.f5_relationship_have_a_position === 1
                        ? "block"
                        : "none",
                  }}
                >
                  <div>ข้อมูลเพิ่มเติม *</div>
                  <div className="input_idcard">
                    {f5_relationship.f5_relationship_have_a_position_text}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="">
              <div className="main_btn_pen">
                <div
                  className="btn_pen"
                  data-alt="PoliticianRelation/edit"
                  onClick={this.path_url_of_alt}
                  style={{ marginTop: "20px" }}
                >
                  แก้ไขข้อมูล
                </div>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>

        <div style={{ display: "none" }}>
          <AccordionItem uuid="financialdetail">
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="Header_ac">ข้อมูลด้านธุรกรรมการเงิน</span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="DivDetailQestion">
                <span className="TxtQuestionFatca">
                  1. ท่านเคยมีประวัติการกระทำผิดกฎหมายฟอกเงินหรือไม่
                </span>
                <div style={{ fontSize: 24, color: "#58595b" }}>
                  {f5_other_information
                    ? f5_other_information.f5_lauder === "1"
                      ? "ใช่"
                      : "ไม่ใช่"
                    : "ไม่ใช่"}
                </div>
              </div>
              <div className="DivDetailQestion">
                <span className="TxtQuestionFatca">
                  2.
                  ท่านเคยถูกปฏิเสธการรับทำธุรกรรมทางการเงินจากสถาบันทางการเงินอื่นหรือไม่
                </span>
                <div style={{ fontSize: 24, color: "#58595b" }}>
                  {f5_other_information
                    ? f5_other_information.f5_reject_other_bank === "1"
                      ? "ใช่"
                      : "ไม่ใช่"
                    : "ไม่ใช่"}
                </div>
              </div>
              <div className="DivDetailQestion" style={{ margin: 0 }}>
                <span className="TxtQuestionFatca">
                  3. ท่านเคยถูกฟ้องร้อง
                  หรืออยู่ระหว่างถูกฟ้องร้อง/ถูกบังคับคดีทางศาลหรือไม่
                </span>
                <div style={{ fontSize: 24, color: "#58595b" }}>
                  {f5_other_information
                    ? f5_other_information.f5_lawsuit === "1"
                      ? "เคย"
                      : "ไม่เคย"
                    : "ไม่เคย"}
                </div>
              </div>

              <div
                className="row_idcard"
                style={{
                  display: f5_other_information
                    ? f5_other_information.f5_lawsuit === "1"
                      ? "block"
                      : "none"
                    : "none",
                }}
              >
                <div>ขอข้อมูลเพิ่มเติม</div>
                <div className="input_idcard">
                  {f5_other_information
                    ? f5_other_information.f5_lawsuit === "1"
                      ? f5_other_information.f5_lawsuit_text
                      : ""
                    : ""}
                </div>
              </div>

              <div className="">
                <div className="main_btn_pen">
                  <div
                    className="btn_pen"
                    data-alt="financialdetail/edit"
                    onClick={this.path_url_of_alt}
                    style={{ marginTop: "20px" }}
                  >
                    แก้ไขข้อมูล
                  </div>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </div>

        <AccordionItem uuid="ktbst_signed_attatched">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="Header_ac">หลักฐานลายเซ็น</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="DivFrameSnapOkPic">
              <div className="DivFrameSnapOkPicContent" style={{ margin: 0 }}>
                {/* <img
                  src="/images/btn_edit.png"
                  data-alt="ktbst_signed_attatched/edit"
                  className="BtnEditSnap"
                  onClick={this.path_url_of_alt}
                /> */}
                <div className="main_img_idcard">
                  <img
                    src={trimmedDataURL}
                    alt="id_card_front"
                    className="DivFrameSnapOk"
                  />
                </div>
              </div>
            </div>
            <div className="main_btn_pen">
              <div
                className="btn_pen"
                data-alt="signed_attatched/edit"
                onClick={this.path_url_of_alt}
                style={{ marginTop: "20px" }}
              >
                แก้ไขข้อมูล
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="ktbst_investment_info_income">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="Header_ac">ประเทศของแหล่งที่มาของรายได้</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="row_idcard" style={{ paddingTop: 0 }}>
              <div>ชื่อประเทศ *</div>
              {f3 ? "" : "-"}
              <div style={{ display: f3 ? "block" : "none" }}>
                <select
                  name="f3_source"
                  style={{
                    display: source_other_require ? "none" : "block",
                    backgroundColor: "#fff",
                    opacity: 1,
                  }}
                  disabled
                  value={f3_.f3_source}
                  className="select_style"
                >
                  <option
                    value=""
                    style={{ color: "#003b6694" }}
                    disabled
                    hidden
                  >
                    กรุณาเลือก
                  </option>
                  {source_income}
                </select>
                <select
                  name="f3_source"
                  style={{
                    backgroundColor: "#fff",
                    opacity: 1,
                    display: source_other_require ? "block" : "none",
                  }}
                  disabled
                  value={f3_.f3_source}
                  className="select_style"
                >
                  <option value="">{f3_.f3_source_text}</option>
                </select>
              </div>
            </div>
            <div className="row_idcard">
              <div>ประเภทของแหล่งที่มาของรายได้ *</div>
              {f3 ? "" : "-"}
              {this.state.source_income_choice.map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      display: item.isCheck ? "block" : "none",
                      border: "none",
                    }}
                    className="input_idcard"
                  >
                    <FontAwesomeIcon
                      icon="check"
                      style={{
                        fontSize: 14,
                        display: item.isCheck ? "inline" : "none",
                        marginRight: 12,
                        color: "#a6ce4e",
                      }}
                    ></FontAwesomeIcon>{" "}
                    {item.Other === 1 ? f3_.f3_by_other_text : item.text}
                  </div>
                  // <div
                  //     className={
                  //         'check_list_row'
                  //     }
                  //     style={{display: item.isCheck ? 'block' : 'none'}}
                  //     key={i}
                  //     // onClick={() => this.handleCheckbox(i,item.Other,item.Mfnc_NameEn)}
                  // >

                  //     {item.Other === 1 ? f3_.f3_by_other_text : item.text}
                  //     {/* <FontAwesomeIcon icon='check'></FontAwesomeIcon> */}
                  // </div>
                );
              })}
            </div>
            <div className="">
              <div className="main_btn_pen">
                <div
                  className="btn_pen"
                  data-alt="investment_info_income/edit"
                  onClick={this.path_url_of_alt}
                  style={{ marginTop: "20px" }}
                >
                  แก้ไขข้อมูล
                </div>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="ktbst_investment_objective">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="Header_ac">วัตถุประสงค์การลงทุน</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {f3 ? "" : "-"}
            <div
              className="row_idcard"
              style={{ display: f3 ? "block" : "none", paddingTop: 0 }}
            >
              {this.state.purpose_lists.map((item, i) => {
                return (
                  <div
                    className="input_idcard"
                    style={{
                      display: item.isCheck ? "flex" : "none",
                      border: 0,
                    }}
                    key={i}
                  >
                    {item.Other === 1
                      ? this.state.f3_purpose_other_text
                      : item.text}
                  </div>
                );
              })}
            </div>
            <div className="row_idcard">
              <div>ท่านเป็นผู้รับผลประโยชน์ที่แท้จริงของบัญชี ใช่หรือไม่ </div>
              <div className="input_idcard">
                {f3_.f3_own === "1"
                  ? "ใช่ ฉันเป็นผู้รับผลประโยชน์ที่แท้จริงของบัญชี"
                  : "ไม่ใช่ ฉันไม่ได้เป็นผู้รับผลประโยชน์ที่แท้จริงของบัญชี"}
              </div>
              {/* <input
                className="input_idcard"
                style={{
                  backgroundColor: "#fff",
                  opacity: 1,
                }}
                value={
                  f3_.f3_own === "1" ? "ใช่ ฉันเป็นผู้รับผลประโยชน์ที่แท้จริงของบัญชี" : "ไม่ใช่ ฉันไม่ได้เป็นผู้รับผลประโยชน์ที่แท้จริงของบัญชี"
                }
                disabled
              /> */}
            </div>
            <div className="">
              <div className="main_btn_pen">
                <div
                  className="btn_pen"
                  data-alt="investment_objective/edit"
                  onClick={this.path_url_of_alt}
                  style={{ marginTop: "20px" }}
                >
                  แก้ไขข้อมูล
                </div>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="ktbst_value_of_invester">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="Header_ac">ข้อมูลการลงทุน</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="row_idcard">
              <div>มูลค่าทรัพย์สิน *</div>
              {/* <input
                className="input_idcard"
                style={{
                  backgroundColor: "#fff",
                  opacity: 1,
                }}
                value={
                  f3_.f3_valuable ? this.numberWithCommas(f3_.f3_valuable) : "-"
                }
                disabled
              /> */}
              <div className="input_idcard">
                {f3_.f3_valuable ? this.numberWithCommas(f3_.f3_valuable) : "-"}
              </div>
            </div>
            <div className="row_idcard" style={{ paddingTop: 0 }}>
              <div>ภาระการเงินต่อปี (บาท) *</div>
              <div>
                <select
                  name="f3_outcome"
                  className="select_style"
                  style={{ backgroundColor: "#fff", opacity: 1 }}
                  value={f3_.f3_outcome ? f3_.f3_outcome : "-"}
                  // onChange={this.handleChange}
                  disabled
                >
                  <option
                    value=""
                    style={{ color: "#003b6694" }}
                    disabled
                    hidden
                  >
                    กรุณาเลือก
                  </option>
                  {outcome_option}
                </select>
              </div>
            </div>
            <div className="row_idcard">
              <div>จำนวนเงินที่คาดว่าจะนำมาลงทุนแต่ละครั้ง (บาท) *</div>
              <div>
                <select
                  name="f3_invest"
                  style={{ backgroundColor: "#fff", opacity: 1 }}
                  className="select_style"
                  value={f3_.f3_invest ? f3_.f3_invest : "-"}
                  // onChange={this.handleChange}
                  disabled
                >
                  <option
                    value=""
                    style={{ color: "#003b6694" }}
                    disabled
                    hidden
                  >
                    กรุณาเลือก
                  </option>
                  {invest_option}
                </select>
              </div>
            </div>
            <div className="">
              <div className="main_btn_pen">
                <div
                  className="btn_pen"
                  data-alt="value_of_invester/edit"
                  onClick={this.path_url_of_alt}
                  style={{ marginTop: "20px" }}
                >
                  แก้ไขข้อมูล
                </div>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="Header_ac">คะแนนความเสี่ยง</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="row_idcard" style={{ paddingTop: 0 }}>
              <div>คะแนนความเสี่ยงที่รับได้</div>
              <div className="input_idcard">
                {ktbst_summary_risk.length > 0
                  ? ktbst_summary_risk[0].totol_score
                  : "-"}
              </div>
            </div>
            <div className="row_idcard">
              <div>ระดับความเสี่ยงที่ผู้ลงทุนรับได้</div>
              <div className="input_idcard">
                {ktbst_summary_risk.length > 0
                  ? ktbst_summary_risk[0].type
                  : "-"}
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="Header_ac">สมัครบริการหักบัญชีอัตโนมัติ</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="row_idcard" style={{ paddingTop: 0 }}>
              <div>ธนาคาร </div>
              <div className="input_idcard">
                {atsDataUser.bankCode !== "Other"
                  ? bank_name
                  : `ธนาคารอื่น ๆ ${atsDataUser.bankCodeOther
                    ? "(" + atsDataUser.bankCodeOther + ")"
                    : ""
                  }`}
              </div>
            </div>
            <div className="row_idcard" style={{ paddingTop: 0 }}>
              <div>สาขา </div>
              <div className="input_idcard">
                {atsDataUser.bank_branch ? atsDataUser.bank_branch.label : "-"}
              </div>
            </div>
            <div className="row_idcard" style={{ paddingTop: 0 }}>
              <div>ชื่อบัญชี </div>
              <div className="input_idcard">
                {atsDataUser.bank_account_name
                  ? atsDataUser.bank_account_name
                  : "-"}
              </div>
            </div>
            <div className="row_idcard" style={{ paddingTop: 0 }}>
              <div>เลขที่บัญชี </div>
              {/* <div className="input_idcard">{atsDataUser.bank_account_no ? atsDataUser.bank_account_no : '-'}</div> */}
              <div className="input_idcard">
                {atsDataUser.bank_account_no
                  ? atsDataUser.bank_account_no.substring(
                    0,
                    atsDataUser.bank_account_no.length - 4
                  ) + "xxxx"
                  : "-"}
              </div>
            </div>
            <div
              className="bank_other_content"
              style={{
                display: atsDataUser.bankCode === "Other" ? "flex" : "none",
              }}
            >
              <FontAwesomeIcon icon="exclamation-circle" />{" "}
              กรุณาดำเนินการสมัครบริการหักบัญชีอัตโนมัติ (ATS)
              เพื่อความสะดวกในการทำธุรกรรม ท่านจะได้รับขั้นตอนการสมัคร
              หลังจากยืนยันข้อมูลการเปิดบัญชีในขั้นตอนถัดไปผ่านอีเมลที่ท่านให้ไว้
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    );
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <Loading status={this.state.loading} />
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">สรุปข้อมูลการเปิดบัญชี</div>
                    </div>

                    <div className="bg_content_white">
                      {/* <div className='text_title_head_summaryshow'>แบบประเมินความเสี่ยงในการลงทุน</div>
                    <span>เลือกความเสี่ยงของคุณ</span> */}
                      <Example />

                      <ButtonCustom
                        handle={this.handleSubmit}
                        label="ยืนยันการสมัครเปิดบัญชี"
                        color="blue"
                        btnClass="long_btn"
                      />
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default SummaryShow;
