import React, { Component } from "react";
import "./FinancialDetail.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import Switch from "../../components/switch/Switch";
import _ from "lodash";
import validator from "validator";
import $ from "jquery";
import { tr } from "date-fns/esm/locale";
import { checkEditPage } from "../../components/helpers/Helpers";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
class FinancialDetail extends Component {
  constructor(props) {
    super(props);
    const f5_info = JSON.parse(localStorage.getItem("f5_other_information"));
    this.state = {
      f5_lauder: f5_info ? f5_info.f5_lauder : "0",
      f5_reject_other_bank: f5_info ? f5_info.f5_reject_other_bank : "0",
      f5_lawsuit: f5_info ? f5_info.f5_lawsuit : "0",
      f5_lawsuit_text: f5_info ? f5_info.f5_lawsuit_text : "",
      status_toggle: f5_info ? (f5_info.f5_lawsuit == 1 ? true : false) : false,
    };
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    if (checkEditPage()) {
      return;
    }
    this.props.history.push("/PoliticianRelation");
  }
  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.status_toggle) {
      if (this.state.f5_lawsuit_text === "") {
        return;
      }
      // console.log(this.state.f5_lawsuit_text);
    }
    var f5_other_information = [];
    f5_other_information = {
      f5_lauder: this.state.f5_lauder,
      f5_reject_other_bank: this.state.f5_reject_other_bank,
      f5_lawsuit: this.state.f5_lawsuit,
      f5_lawsuit_text:
        this.state.f5_lawsuit === "1" ? this.state.f5_lawsuit_text : "",
    };
    await localStorage.setItem(
      "f5_other_information",
      JSON.stringify(f5_other_information)
    );
    var str = window.location.href;
    let re = /edit/;
    const back_to_edit = re.test(str);
    // return
    if (back_to_edit) {
      await this.props.history.push("/summaryshow");
      await localStorage.setItem("editPage", "financialdetail");
    } else {
      await this.props.history.push("/signed_attatched");
    }
  };
  handleClick = async (e) => {
    e.preventDefault();
    const value =
      e.target.innerText === "ใช่" || e.target.innerText === "เคย" ? "1" : "0";
    await this.setState({
      [e.currentTarget.id]: value,
    });
  };
  handleChange = async (e) => {
    e.preventDefault();
    const isCheckbox = e.target.type === "checkbox";
    const value = e.target.value;
    await this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : value,
    });
  };
  toggle_input = (e) => {
    if (e.target.innerText === "เคย") {
      this.setState({ status_toggle: true });
    } else if (e.target.innerText === "ไม่เคย") {
      this.setState({ status_toggle: false });
    }
  };
  render() {
    return (
      <div
        className={isMobile ? "" : "body"}
        style={{
          backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
        }}
      >
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section className={isMobile ? "" : "desktop_content section-gap-full"}>
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div className="Page">
                  <div className="top_main_back">
                    <div className="main_back" onClick={this.goBack}>
                      <img
                        src={
                          isMobile
                            ? "/images/angle-arrow-left.svg"
                            : "/images/angle-arrow-left-blue.svg"
                        }
                        alt="forward"
                        style={{
                          display: checkEditPage() ? "none" : "block",
                          marginLeft: "3px",
                        }}
                        className="arrow_back"
                      />
                    </div>
                    <div className="text_title">ข้อมูลด้านธุรกรรมการเงิน</div>
                  </div>

                  <div className="bg_content_white">
                    <div className="DivDetailFatca">
                      <div className="DivDetailTitleFatca">
                        ข้อมูลที่เกี่ยวกับธุรกรรมด้านการเงิน และกฎหมาย
                      </div>
                      <div className="DivDetailQestion">
                        <span className="TxtQuestionFatca">
                          1. ท่านเคยมีประวัติการกระทำผิดกฎหมายฟอกเงินหรือไม่
                        </span>
                        <div className="DivDetailBtnFatca">
                          <div
                            id="f5_lauder"
                            onClick={this.handleClick}
                            className="DivHalfBtnFatca"
                            style={{ float: "right" }}
                          >
                            <Switch
                              labelL="ใช่"
                              labelR="ไม่ใช่"
                              selected={this.state.f5_lauder}
                            ></Switch>
                          </div>
                        </div>

                        <hr className="BorderFatca"></hr>
                      </div>

                      <div className="DivDetailQestion">
                        <span className="TxtQuestionFatca">
                          2.
                          ท่านเคยถูกปฏิเสธการรับทำธุรกรรมทางการเงินจากสถาบันทางการเงินอื่นหรือไม่{" "}
                        </span>
                        <div className="DivDetailBtnFatca">
                          <div
                            id="f5_reject_other_bank"
                            onClick={this.handleClick}
                            className="DivHalfBtnFatca"
                            style={{ float: "right" }}
                          >
                            <Switch
                              labelL="ใช่"
                              labelR="ไม่ใช่"
                              selected={this.state.f5_reject_other_bank}
                            ></Switch>
                          </div>
                        </div>

                        <hr className="BorderFatca"></hr>
                      </div>

                      <div className="DivDetailQestion">
                        <span className="TxtQuestionFatca">
                          3. ท่านเคยถูกฟ้องร้อง
                          หรืออยู่ระหว่างถูกฟ้องร้อง/ถูกบังคับคดีทางศาลหรือไม่
                        </span>
                        <div className="DivDetailBtnFatca">
                          <div
                            id="f5_lawsuit"
                            onClick={this.handleClick}
                            className="DivHalfBtnFatca"
                            style={{ float: "right" }}
                          >
                            <Switch
                              labelL="เคย"
                              labelR="ไม่เคย"
                              onClick={this.toggle_input}
                              selected={this.state.f5_lawsuit}
                            ></Switch>
                          </div>
                        </div>
                        <hr className="BorderFatca"></hr>
                      </div>

                      <div
                        className="row_idcard"
                        style={{
                          paddingTop: 0,
                          display: this.state.status_toggle ? "block" : "none",
                        }}
                      >
                        <div>ขอข้อมูลเพิ่มเติม</div>
                        <input
                          className="input_idcard"
                          name="f5_lawsuit_text"
                          id="f5_lawsuit_text"
                          value={this.state.f5_lawsuit_text}
                          onChange={this.handleChange}
                          placeholder="ข้อ 3 กรณีตอบ “เคย” กรุณาระบุรายละเอียด"
                          style={{
                            fontSize: 20,
                            borderBottomColor:
                              this.state.f5_lawsuit_text === ""
                                ? "#ccc"
                                : "#003b66",
                          }}
                        ></input>
                        <span
                          className="span_f5_lawsuit_text"
                          style={{
                            color: "red",
                            display: this.state.status_toggle
                              ? this.state.f5_lawsuit_text === ""
                                ? "flex"
                                : "none"
                              : "none",
                          }}
                        >
                          กรุณาระบุข้อมูลเพิ่มเติม
                        </span>
                      </div>

                      <div>
                        <ButtonCustom
                          handle={this.handleSubmit}
                          label={checkEditPage() ? "บันทึกการแก้ไข" : "ถัดไป"}
                          color="blue"
                          btnClass="long_btn"
                        ></ButtonCustom>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default FinancialDetail;
