import React, { Component } from "react";
import "./KtbstInvestmentInfoIncome.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import validator from "validator";
import { checkEditPage, popupErrorApi } from "../../components/helpers/Helpers";
import { IMaskInput } from "react-imask";
import Loading from "../../components/loading/loading";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import to from "await-to-js";
import ktbsApi from "./../../api";
import { config } from "./../../constants";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
const _url = config.baseAPIURL03;
class KtbstInvestmentInfoIncome extends Component {
  sourceIncomes = [];

  constructor(props) {
    super(props);
    const f3 = JSON.parse(localStorage.getItem("f3"));
    if (f3 === null) {
      const f3 = {
        f3_source: "",
        f3_source_text: "",
        f3_by_private_business: "",
        f3_by_employee: "",
        f3_by_heritage: "",
        f3_by_borrowing: "",
        f3_by_divided: "",
        f3_by_retired: "",
        f3_by_saving: "",
        f3_by_other: "",
        f3_by_other_text: "",
        f3_income: "",
        f3_outcome: "",
        f3_invest: "",
        f3_valuable: "",
        f3_by_invest: "",
        purpose_json: [],
        isSubmit: false,
      };
    }
    const f3_source = f3 ? f3.f3_source : "";
    const f3_source_text = f3 ? (f3.f3_source_text === undefined ? "" : f3.f3_source_text) : "";
    const f3_by_private_business = f3 ? f3.f3_by_private_business : "0";
    const f3_by_employee = f3 ? f3.f3_by_employee : "0";
    const f3_by_heritage = f3 ? f3.f3_by_heritage : "0";
    const f3_by_borrowing = f3 ? f3.f3_by_borrowing : "0";
    const f3_by_divided = f3 ? f3.f3_by_divided : "0";
    const f3_by_retired = f3 ? f3.f3_by_retired : "0";
    const f3_by_saving = f3 ? f3.f3_by_saving : "0";
    const f3_by_other = f3 ? f3.f3_by_other : "0";
    const f3_by_other_text = f3 ? (f3.f3_by_other_text === undefined ? "" : f3.f3_by_other_text) : "";
    const f3_by_invest = f3 ? f3.f3_by_invest : "";

    this.state = {
      topContentHeight: null,
      source_income: [],
      source_income_choice: [],
      default_source_income: "0",
      income_other_require: false,
      source_other_require: false,
      f3_source: f3_source,
      f3_source_text: f3_source_text,
      f3_by_private_business: f3_by_private_business,
      f3_by_employee: f3_by_employee,
      f3_by_heritage: f3_by_heritage,
      f3_by_borrowing: f3_by_borrowing,
      f3_by_divided: f3_by_divided,
      f3_by_retired: f3_by_retired,
      f3_by_saving: f3_by_saving,
      f3_by_other: f3_by_other,
      f3_by_other_text: f3_by_other_text,
      f3_by_invest: f3_by_invest,
      loading: false,
      btns: [
        { id: "1", text: "เงินเดือน", isCheck: false },
        { id: "2", text: "มรดก", isCheck: false },
        { id: "3", text: "เงินออม", isCheck: false },
        { id: "4", text: "การลงทุน", isCheck: false },
        { id: "5", text: "เงินเกษียณ", isCheck: false },
        { id: "6", text: "ประกอบธุรกิจ", isCheck: false },
      ],
      loading: false,
    };

    this.goBack = this.goBack.bind(this);
    this.topContent = React.createRef();
  }

  goBack() {
    if (checkEditPage()) {
      return;
    }
    this.props.history.push("/signed_attatched");
  }

  handleCheckbox = async (i, Other, Mfnc_NameEn) => {
    let source_income_choice = this.state.source_income_choice;
    await source_income_choice.forEach((chkbox, val) => {
      if (val === i) {
        chkbox.isCheck = !chkbox.isCheck;
        if (Other === 1) {
          if (chkbox.isCheck) {
            this.setState({ income_other_require: true });
          } else {
            this.setState({
              income_other_require: false,
              f3_by_other_text: "",
            });
          }
        }
        if (chkbox.isCheck) {
          if (chkbox.text.includes("เงินเดือน")) {
            this.setState({ f3_by_employee: "1" });
          }
          if (chkbox.text.includes("มรดก")) {
            this.setState({ f3_by_heritage: "1" });
          }
          if (chkbox.text.includes("เงินออม")) {
            this.setState({ f3_by_saving: "1" });
          }
          if (chkbox.text.includes("การลงทุน")) {
            this.setState({ f3_by_invest: "1" });
          }
          if (chkbox.text.includes("เงินเกษียณ")) {
            this.setState({ f3_by_retired: "1" });
          }
          if (chkbox.text.includes("ประกอบธุรกิจ")) {
            this.setState({ f3_by_private_business: "1" });
          }
          if (chkbox.text.includes("อื่นๆ")) {
            this.setState({ f3_by_other: "1" });
          }
          $(".span_f3_source_income_choice").hide();
        } else {
          if (chkbox.text.includes("เงินเดือน")) {
            this.setState({ f3_by_employee: "0" });
          }
          if (chkbox.text.includes("มรดก")) {
            this.setState({ f3_by_heritage: "0" });
          }
          if (chkbox.text.includes("เงินออม")) {
            this.setState({ f3_by_saving: "0" });
          }
          if (chkbox.text.includes("การลงทุน")) {
            this.setState({ f3_by_invest: "0" });
          }
          if (chkbox.text.includes("เงินเกษียณ")) {
            this.setState({ f3_by_retired: "0" });
          }
          if (chkbox.text.includes("ประกอบธุรกิจ")) {
            console.log(chkbox.text);
            this.setState({ f3_by_private_business: "0" });
          }
          if (chkbox.text.includes("อื่นๆ")) {
            this.setState({ f3_by_other: "0" });
          }
        }
      }
    });
    this.setState({ source_income_choice: source_income_choice });
  };

  validate = () => {
    $("span[class^=span_f3]").hide();
    $(".span_f3_source_income_choice").hide();
    let countError = 0;
    if (
      this.state.source_other_require &&
      validator.isEmpty(this.state.f3_source_text)
    ) {
      $(".span_f3_source_text").css("display", "flex");
      countError++;
    }

    if (
      this.state.income_other_require &&
      validator.isEmpty(this.state.f3_by_other_text)
    ) {
      $(".span_f3_by_other_text").css("display", "flex");
      countError++;
    }
    if (
      validator.isEmpty(this.state.f3_source) ||
      this.state.f3_source === "" ||
      this.state.f3_source === "0"
    ) {
      $(".span_f3_source").css("display", "flex");
      countError++;
    }
    let countChkList = $(".button_checkbox.active").length;
    if (countChkList === 0) {
      $(".span_f3_source_income_choice").css("display", "flex");
      countError++;
    } else {
      $(".span_f3_source_income_choice").hide();
    }
    return countError > 0 ? false : true;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = this.validate();
    this.setState({ isSubmit: true });
    const { source_income_choice } = this.state;
    const f3_by_invest_check = source_income_choice.filter(function (item) {
      // return item.isCheck;
      return item.isCheck && item.text.indexOf("การลงทุน") !== -1;
    });
    const f3_by_invest = f3_by_invest_check.length > 0 ? "1" : "0";
    if (await isValid) {
      const f3 = JSON.parse(localStorage.getItem("f3"));
      let f3_ = {
        f3_source: this.state.f3_source,
        f3_source_text: validator.trim(this.state.f3_source_text),
        f3_by_private_business: this.state.f3_by_private_business,
        f3_by_employee: this.state.f3_by_employee,
        f3_by_heritage: this.state.f3_by_heritage,
        f3_by_borrowing: this.state.f3_by_borrowing,
        f3_by_divided: this.state.f3_by_divided,
        f3_by_retired: this.state.f3_by_retired,
        f3_by_saving: this.state.f3_by_saving,
        f3_by_other: this.state.f3_by_other,
        f3_by_other_text: validator.trim(this.state.f3_by_other_text),
        f3_income: f3 ? f3.f3_income : "",
        f3_outcome: f3 ? f3.f3_outcome : "",
        f3_invest: f3 ? f3.f3_invest : "",
        f3_by_invest: f3_by_invest,
        f3_valuable: f3 ? f3.f3_valuable : "",
        f3_own: f3 ? f3.f3_own : "1",
        purpose_json: f3 ? f3.purpose_json : [],
      };
      await localStorage.setItem("f3", JSON.stringify(f3_));
      var str = window.location.href;
      let re = /edit/;
      const back_to_edit = re.test(str);
      // return

      // if (checkInternet()) {
      if (back_to_edit) {
        let error;
        let result;
        let mss = "";
        let title_mss = "";
        const MySwal = withReactContent(swal);
        const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
        const head_token = HeaderManp.head_token;
        const f3 = JSON.parse(localStorage.getItem("f3"))
          ? JSON.parse(localStorage.getItem("f3"))
          : {};
        const arr_jobs_data = JSON.parse(localStorage.getItem("arr_jobs_data"))
          ? JSON.parse(localStorage.getItem("arr_jobs_data"))
          : {};
        const {
          f3_source,
          f3_source_text,
          f3_by_private_business,
          f3_by_employee,
          f3_by_heritage,
          f3_by_borrowing,
          f3_by_divided,
          f3_by_retired,
          f3_by_saving,
          f3_by_other,
          f3_by_other_text,
          f3_income,
          f3_outcome,
          f3_invest,
          f3_by_invest,
          f3_valuable,
          purpose_json,
          f3_own,
        } = f3;
        const {
          f1_business_other,
          f1_business_type,
          f1_career,
          f3_officename,
          f1_career_other,
          f1_career_position,
        } = arr_jobs_data;
        const careerAndFinancial = {
          head_token,
          f3_source: f3_source,
          f3_source_text: f3_source_text,
          f3_by_private_business: f3_by_private_business,
          f3_by_employee: f3_by_employee,
          f3_by_heritage: f3_by_heritage,
          f3_by_borrowing: f3_by_borrowing,
          f3_by_divided: f3_by_divided,
          f3_by_retired: f3_by_retired,
          f3_by_saving: f3_by_saving,
          f3_by_other: f3_by_other,
          f3_by_other_text: f3_by_other_text,
          f3_income: f3_income,
          f3_outcome: f3_outcome,
          f3_invest: f3_invest,
          f3_valuable: f3_valuable,
          purpose_json: purpose_json,
          f3_career: f1_career,
          f3_officename: f3_officename,
          f3_career_text: f1_career_other,
          f3_business: f1_business_type,
          f3_business_text: f1_business_other,
          f3_position: f1_career_position,
          f3_by_invest: f3_by_invest,
          f3_own: f3_own,
        };
        await this.setState({ loading: true });
        [error, result] = await to(
          ktbsApi.post("/form/career_and_financial/manp", careerAndFinancial)
        );
        if (
          error ||
          !result ||
          !result.Data ||
          !result.Data[0] ||
          !result.Data[0][0] ||
          result.Data[0][0].IsSuccess !== 1
        ) {
          if (result) {
            title_mss =
              result.Data[0][0].MsgDescTitle !== ""
                ? result.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              result.Data[0][0].MsgDesc !== ""
                ? result.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else if (error) {
            if (!!error.isAxiosError && !error.response) {
              title_mss = "เกิดข้อผิดพลาด";
              mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            } else {
              title_mss =
                error.response.data.Data[0][0].MsgDescTitle !== ""
                  ? error.response.data.Data[0][0].MsgDescTitle
                  : "เกิดข้อผิดพลาด";
              mss =
                error.response.data.Data[0][0].MsgDesc !== ""
                  ? error.response.data.Data[0][0].MsgDesc
                  : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
            }
          } else {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
          MySwal.fire({
            imageUrl: "/images/ico_alert.svg",
            imageWidth: 100,
            imageHeight: 100,
            customClass: {
              confirmButton: "long_btn_fix",
            },
            confirmButtonColor: "#003b66",
            confirmButtonText: "กลับ",
            title: '<div class="title_popup">' + title_mss + "</div>",
            html: '<div class="content_popup">' + mss + "</div>",
          });
          await this.setState({ loading: false });
          return;
        } else {
          // console.log(result.Data[0][0].MsgDesc !== "");
        }
        await this.setState({ loading: false });
        await this.props.history.push("/summaryshow");
        await localStorage.setItem("editPage", "investment_info_income");
      } else {
        await this.setState({ loading: false });
        const BackToSummaryRisk = JSON.parse(
          localStorage.getItem('BackToSummaryRisk')
        );
        if (BackToSummaryRisk) {
          localStorage.removeItem('BackToSummaryRisk');
          window.location = 'summary_risk';
          return;
        }
        await this.props.history.push("/investment_objective");
      }
      // } else {
      //   this.setState({
      //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
      //   })
      // }
    } else {
      var items = document.querySelectorAll("[class*=span_f3]");
      for (var i = 0; i < items.length; i++) {
        if (items[i].style.display === "flex") {
          if (i === 3) {
            return items[i].scrollIntoView({ behavior: "smooth" });
          } else {
            return items[i].parentNode.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    }
    await this.setState({ loading: false });
  };

  async componentDidMount() {
    this.setState({ loading: true });
    // await download_ico_alert();
    await axios
      .get(_url + `/constant/source_income`)
      .then((res) => {
        const source_income = res.data.Data.map((item, i) => {
          if (i === 0) {
            this.setState({
              default_source_income: String(item.Mfnt_Id),
            });
          }
          return (
            <option value={item.Mfnt_Id} key={i} style={{ color: "#003b66" }}>
              {item.Mfnt_NameTh}
            </option>
          );
        });
        this.sourceIncomes = res.data.Data;
        const { f3_source } = this.state;
        let sourceOtherRequired = false;
        if (f3_source) {
          const selectedSourceIncome = this.sourceIncomes.find(
            (item) => String(item.Mfnt_Id) === f3_source
          );
          sourceOtherRequired =
            selectedSourceIncome && selectedSourceIncome.Other === 1;
        } else {
          this.setState({
            f3_source: this.state.default_source_income,
          });
        }

        this.setState({
          source_income: source_income,
          source_other_require: sourceOtherRequired,
        });
      })
      .catch(async (error) => {
        var mss = "";
        var title_mss = "";
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            title_mss =
              error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
        }
        popupErrorApi(
          mss,
          title_mss,
          true
          // function name() {
          //   var myStyle = document.getElementsByClassName("main_popup_loading")[0].style.display;
          //   if(myStyle === 'none'){
          //     document.getElementsByClassName("main_popup_loading")[0].style.display = 'block';
          //   }
          //   window.location = "login";
          // }
        );
        this.setState({ loading: false });
      });
    await axios
      .get(_url + `/constant/source_income_choice`)
      .then((res) => {
        const source_income_choice = res.data.Data.map((item, i) => {
          let isChk = false;
          if (
            item.Mfnc_NameTh.includes("เงินเดือน") &&
            this.state.f3_by_employee === "1"
          ) {
            isChk = true;
          }
          if (
            item.Mfnc_NameTh.includes("มรดก") &&
            this.state.f3_by_heritage === "1"
          ) {
            isChk = true;
          }
          if (
            item.Mfnc_NameTh.includes("เงินออม") &&
            this.state.f3_by_saving === "1"
          ) {
            isChk = true;
          }
          if (
            item.Mfnc_NameTh.includes("การลงทุน") &&
            this.state.f3_by_invest === "1"
          ) {
            isChk = true;
          }
          if (
            item.Mfnc_NameTh.includes("เงินเกษียณ") &&
            this.state.f3_by_retired === "1"
          ) {
            isChk = true;
          }
          if (
            item.Mfnc_NameTh.includes("ประกอบธุรกิจ") &&
            this.state.f3_by_private_business === "1"
          ) {
            isChk = true;
          }
          if (
            item.Mfnc_NameTh.includes("อื่นๆ") &&
            this.state.f3_by_other === "1"
          ) {
            isChk = true;
            this.setState({ income_other_require: true });
          }
          return {
            id: item.Mfnc_Id,
            text: item.Mfnc_NameTh,
            isCheck: isChk,
            Other: item.Other,
            Mfnc_NameEn: item.Mfnc_NameEn,
          };
        });
        this.setState({
          source_income_choice: source_income_choice,
          loading: false,
        });
      })
      .catch(async (error) => {
        let title_mss = "";
        let mss = "";
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            title_mss =
              error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
        }
        popupErrorApi(
          mss,
          title_mss,
          true
          // function name() {
          //   var myStyle = document.getElementsByClassName("main_popup_loading")[0].style.display;
          //   if(myStyle === 'none'){
          //     document.getElementsByClassName("main_popup_loading")[0].style.display = 'block';
          //   }
          //   window.location = "login";
          // }
        );
        this.setState({ loading: false });
      });
  }

  componentDidUpdate() {
    const { topContentHeight } = this.state;
    const newTopContentHeight = this.topContent.current.clientHeight;
    if (topContentHeight !== newTopContentHeight) {
      setTimeout(() => {
        this.setState({ topContentHeight: newTopContentHeight });
      }, 50);
    }
  }

  handleChange = async (e) => {
    e.preventDefault();
    const isCheckbox = e.target.type === "checkbox";
    const value = e.target.value;
    if (e.target.name === "f3_source") {
      this.setState({
        source_other_require: e.target.value === "2",
        f3_source_text: "",
      });
    }

    await this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : value,
    });
    if (this.state.isSubmit) {
      await this.validate();
    }
    // console.log(this.state.source_other_require);
  };

  handleIChange = async (v, ref) => {
    await this.setState({
      [ref.el.input.name]: v,
    });
    if (this.state.isSubmit) {
      await this.validate();
    }
  };

  render() {
    library.add(fas);
    const source_income = this.state.source_income;
    const { source_other_require, topContentHeight } = this.state;
    const in_bg_white = this.state.in_bg_white;
    const default_source_income = this.state.default_source_income;
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <Loading status={this.state.loading} />
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          alt="forward"
                          style={{
                            display: checkEditPage() ? "none" : "block",
                            marginLeft: "3px",
                          }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">ข้อมูลการลงทุน</div>
                    </div>

                    <div className="bg_content_white over_f_hide">
                      <div
                        className="in_bg_white autoscroll"
                        style={{
                          // top: this.state.source_other_require ? 287 : 200,
                          // top: topContentHeight + 81,
                          top: 0,
                          left: 0,
                          right: 0,
                          padding: "0 24px",
                        }}
                      >
                        <div
                          className="top-content"
                          ref={this.topContent}
                          style={{ marginTop: 24 }}
                        >
                          <div
                            style={{
                              color: "#003b66",
                              fontSize: 24,
                              fontWeight: "bold",
                              padding: "0px 0 0px 0",
                            }}
                          >
                            ประเทศของแหล่งที่มาของรายได้/เงินลงทุน *
                          </div>
                          <div
                            className="row_idcard"
                            style={{
                              marginBottom: 24,
                              borderColor:
                                this.state.f3_source != "" ? "#003b66" : "#ccc",
                            }}
                          >
                            {/* <div>ชื่อประเทศ</div> */}
                            <div className="main_select">
                              <select
                                name="f3_source"
                                onChange={this.handleChange}
                                value={
                                  this.state.f3_source == ""
                                    ? default_source_income
                                    : this.state.f3_source
                                }
                                className="select_style"
                                style={{
                                  color:
                                    this.state.f3_source == ""
                                      ? "#003b6694"
                                      : "#003b66",
                                }}
                              >
                                <option value="" disabled hidden>
                                  กรุณาเลือกประเทศ
                                </option>
                                {source_income}
                              </select>
                              <FontAwesomeIcon icon="angle-down" />
                            </div>
                            <span
                              className="span_f3_source"
                              style={{ color: "red", display: "none" }}
                            >
                              กรุณาเลือก ประเทศของแหล่งที่มาของรายได้/เงินลงทุน
                            </span>
                          </div>

                          <div
                            className="row_idcard"
                            style={{
                              display: source_other_require ? "block" : "none",
                              marginBottom: "24px",
                              paddingTop: 0,
                              borderColor:
                                this.state.f3_source_text != ""
                                  ? "#003b66"
                                  : "#ccc",
                            }}
                          >
                            <div
                              style={{
                                color: "#003b66",
                                fontSize: "24px",
                                fontWeight: "bold",
                                whiteSpace: "initial",
                              }}
                            >
                              ประเทศอื่นๆของแหล่งที่มาของรายได้/เงินลงทุน *
                            </div>
                            <IMaskInput
                              mask={/^[A-Za-zก-๙.\s\-]+$/}
                              value={this.state.f3_source_text}
                              unmask={true}
                              className="input_idcard"
                              name="f3_source_text"
                              style={{
                                textDecorationLine: "none",
                                borderColor:
                                  this.state.f3_source_text !== ""
                                    ? "#003b66"
                                    : "#ccc",
                              }}
                              onAccept={this.handleIChange}
                            />
                            <span
                              className="span_f3_source_text"
                              style={{
                                color: "red",
                              }}
                            >
                              กรุณาระบุประเทศอื่นๆ
                              ของแหล่งที่มาของรายได้/เงินลงทุน
                            </span>
                          </div>
                        </div>

                        <div
                          style={{
                            color: "#003b66",
                            fontSize: 24,
                            fontWeight: "bold",
                            padding: "0px 0 21px 0",
                            // whiteSpace: "nowrap",
                          }}
                        >
                          แหล่งที่มาของรายได้ (เลือกได้มากกว่า 1 ข้อ)
                        </div>
                        {this.state.source_income_choice.map((item, i) => {
                          return (
                            <div
                              className={
                                item.isCheck
                                  ? "button_checkbox active" //check_list_row
                                  : "button_checkbox"
                              }
                              key={i}
                              onClick={() =>
                                this.handleCheckbox(
                                  i,
                                  item.Other,
                                  item.Mfnc_NameEn
                                )
                              }
                            >
                              {item.text}{" "}
                              {/* <FontAwesomeIcon icon="check"></FontAwesomeIcon> */}
                            </div>
                          );
                        })}

                        <div
                          className="row_idcard"
                          style={{
                            display: this.state.income_other_require
                              ? "block"
                              : "none",
                            borderBottomColor:
                              this.state.f3_by_other_text === ""
                                ? "#ccc"
                                : "#003b66",
                          }}
                        >
                          <div>ขอข้อมูลเพิ่มเติม *</div>
                          {/* <input
                  className="input_idcard"
                  name="f3_by_other_text"
                  // maxLength='13'
                  value={this.state.f3_by_other_text}
                  onChange={this.handleChange}
                  placeholder={"กรุณาระบุแหล่งที่มาของรายได้อื่นๆ"}
                ></input> */}
                          <IMaskInput
                            mask={/^[A-Za-zก-๙.\s\-]+$/}
                            value={this.state.f3_by_other_text}
                            unmask={true}
                            className="input_idcard"
                            placeholder={"กรุณาระบุแหล่งที่มาของรายได้อื่นๆ"}
                            name="f3_by_other_text"
                            style={{
                              textDecorationLine: "none",
                              borderColor:
                                this.state.f3_by_other_text !== ""
                                  ? "#003b66"
                                  : "#ccc",
                            }}
                            onAccept={this.handleIChange}
                          />
                          <span
                            className="span_f3_by_other_text"
                            style={{
                              color: "red",
                            }}
                          >
                            กรุณาระบุแหล่งที่มาของรายได้อื่นๆ
                          </span>
                        </div>
                        <span
                          className="span_f3_source_income_choice"
                          style={{
                            color: "red",
                            display: "none",
                            justifyContent: "flex-end",
                          }}
                        >
                          กรุณาระบุแหล่งที่มาของรายได้
                        </span>
                      </div>

                      <div className="under_fix_btn">
                        <ButtonCustom
                          handle={this.handleSubmit}
                          label={checkEditPage() ? "บันทึกการแก้ไข" : "ถัดไป"}
                          color="blue"
                          btnClass="long_btn"
                        ></ButtonCustom>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default KtbstInvestmentInfoIncome;
