import React, { Component } from "react";
import ButtonCustom from "../../components/button/ButtonCustom";
import "./KtbstSummaryRisk.css";
import axios from "axios";
import { library, text } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loading from "../../components/loading/loading";
import {
  popupErrorApi,
  checkKey,
  checkFamilyStatusRequired,
  checkChildStatusRequired,
  checkPoliticianRelationRequired,
  checkPurpose,
} from "../../components/helpers/Helpers";
import to from "await-to-js";
import ktbsApi from "./../../api";
import { config } from "./../../constants";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
const _url = config.baseAPIURL03;
const MySwal = withReactContent(swal);
class KtbstSummaryRisk extends Component {
  constructor(props) {
    super(props);
    const obj = localStorage.getItem("summary_risk");
    this.state = {
      popup: false,
      TotalScore: 0,
      f0: [],
      render_risk: [],
      theRiskText: "",
      theRisk: {
        1: "เสี่ยงต่ำ",
        2: "เสี่ยงปานกลางค่อนข้างต่ำ",
        3: "เสี่ยงปานกลางค่อนข้างสูง",
        4: "เสี่ยงสูง",
        5: "เสี่ยงสูงมาก / เสี่ยงสูงมากอย่างมีนัยสำคัญ",
      },
      contentPopup: "",
      statusLoading: false,
      isDisconnected: false,
    };
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.push("/risk_poll2");
  }

  alert_list = () => {
    this.setState({ popup: true });
  };

  componentDidMount() {
    this.setState({ statusLoading: true });
    const set_risk = JSON.parse(localStorage.getItem("summary_risk"));
    const is_recheck_risk = JSON.parse(localStorage.getItem("is_recheck_risk"));
    const is_recheck_risk_MsgDesc = localStorage.getItem(
      "is_recheck_risk_MsgDesc"
    );
    const is_recheck_risk_MsgDescTitle = localStorage.getItem(
      "is_recheck_risk_MsgDescTitle"
    );
    if (is_recheck_risk) {
      this.setState({ statusLoading: false });
      MySwal.fire({
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        customClass: {
          confirmButton: "long_btn_fix",
        },
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonColor: "#003b66",
        confirmButtonText: "ประเมินใหม่",
        title:
          '<div class="title_popup">' + is_recheck_risk_MsgDescTitle + "</div>",
        html:
          '<div class="content_popup">' + is_recheck_risk_MsgDesc + "</div>",
      }).then(() => {
        localStorage.removeItem("is_recheck_risk");
        localStorage.removeItem("is_recheck_risk_MsgDesc");
        localStorage.removeItem("is_recheck_risk_MsgDescTitle");
        localStorage.removeItem("question");
        localStorage.removeItem("RiskQuestion");
        localStorage.removeItem("scoreAll");
        localStorage.removeItem("listQ14");
        localStorage.removeItem("RiskQuestionPoll");
        localStorage.removeItem("summary_risk");
        this.props.history.push("/risk");
      });
      return;
    }
    axios
      .get(_url + "/constant/securities")
      .then((res) => {
        const RiskQuestionPoll = JSON.parse(
          localStorage.getItem("RiskQuestionPoll")
        );
        const listQ14 = [];
        if (RiskQuestionPoll && RiskQuestionPoll.answerjson14 != null) {
          RiskQuestionPoll.answerjson14.forEach((item, i) => {
            listQ14.push({
              SAfsa14_Id: item.SAfsa14_Id,
              SAfsa14_Position: item.positionName,
              SAfsa14_Company: item.companyName,
              SAfsa14_FromYear: item.expFrom,
              SAfsa14_ToYear: item.expTo,
            });
          });
        }
        localStorage.setItem("listQ14", JSON.stringify(listQ14));
        this.setState({ statusLoading: false });
        this.setState({ contentPopup: res.data.Data.detail });
      })
      .catch(async (error) => {
        var mss = "";
        var title_mss = "";
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            title_mss =
              error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
        }
        popupErrorApi(mss, title_mss, true);
        this.setState({ statusLoading: false });
      });
    // console.log(set_risk);
    if (set_risk !== null) {
      const render_risk = set_risk[0].recommend.map((item, i) => {
        return (
          <div key={item.id}>
            • <span style={{ marginLeft: 4 }}>{item.label}</span>
          </div>
        );
      });
      this.setState({ render_risk: render_risk });
      this.setState({ totol_score: set_risk[0].totol_score });
      this.setState({ type: set_risk[0].type });
    }
    var localRiskQuestion = localStorage.getItem("RiskQuestion");
    var arrRiskQuestion = JSON.parse(localRiskQuestion);
    var f0 = JSON.parse(localStorage.getItem("f0"));
    const TotalScore =
      arrRiskQuestion && arrRiskQuestion.TotalScore
        ? arrRiskQuestion.TotalScore
        : 0;
    this.setState({ TotalScore: TotalScore });
    this.setState({ f0: f0 });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    // if (checkInternet()) {
    let error;
    let result;
    let mss = "";
    let title_mss = "";
    const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
    const head_token = HeaderManp.head_token;
    const f3 = JSON.parse(localStorage.getItem("f3"))
      ? JSON.parse(localStorage.getItem("f3"))
      : {};
    const arr_jobs_data = JSON.parse(localStorage.getItem("arr_jobs_data"))
      ? JSON.parse(localStorage.getItem("arr_jobs_data"))
      : {};
    const f3_invester = JSON.parse(localStorage.getItem("f3_invester"))
      ? JSON.parse(localStorage.getItem("f3_invester"))
      : {};
    const {
      f3_source,
      f3_source_text,
      f3_by_private_business,
      f3_by_employee,
      f3_by_heritage,
      f3_by_borrowing,
      f3_by_divided,
      f3_by_retired,
      f3_by_saving,
      f3_by_other,
      f3_by_other_text,
      f3_income,
      f3_outcome,
      f3_invest,
      f3_valuable,
      purpose_json,
      f3_by_invest,
      f3_own,
    } = f3;
    const {
      f1_business_other,
      f3_officename,
      f1_business_type,
      f1_career,
      f1_career_other,
      f1_career_position,
    } = arr_jobs_data;
    const careerAndFinancial = {
      head_token,
      f3_source: f3_source,
      f3_source_text: f3_source_text,
      f3_by_private_business: f3_by_private_business,
      f3_by_employee: f3_by_employee,
      f3_by_heritage: f3_by_heritage,
      f3_by_borrowing: f3_by_borrowing,
      f3_by_divided: f3_by_divided,
      f3_by_retired: f3_by_retired,
      f3_by_saving: f3_by_saving,
      f3_by_other: f3_by_other,
      f3_by_other_text: f3_by_other_text,
      f3_income: f3_income,
      f3_outcome: f3_outcome,
      f3_invest: f3_invest,
      f3_valuable: f3_valuable,
      purpose_json: purpose_json,
      f3_career: f1_career,
      f3_career_text: f1_career_other,
      f3_business: f1_business_type,
      f3_business_text: f1_business_other,
      f3_position: f1_career_position,
      f3_by_invest: f3_by_invest,
      f3_officename: f3_officename,
      f3_own: f3_own,
    };
    const ChekcareerAndFinancial = await checkKey(careerAndFinancial);
    const isValidPurpose = checkPurpose(careerAndFinancial);
    if (
      typeof ChekcareerAndFinancial === "object" &&
      ChekcareerAndFinancial !== null &&
      isValidPurpose.length === 0
    ) {
      // data ok
    } else {
      var pageJobs = [
        "f3_career",
        "f3_career_text",
        "f3_business",
        "f3_business_text",
        "f3_income",
        "f3_position",
        "f3_officename",
      ];
      var page_value_of_invester = ["f3_invest", "f3_outcome", "f3_valuable"];
      var page_investment_info_income = [
        "f3_source",
        "f3_source_text",
        "f3_by_other_text",
        "f3_by",
      ];
      if (pageJobs.includes(ChekcareerAndFinancial)) {
        if (arr_jobs_data[ChekcareerAndFinancial]) {
          arr_jobs_data[ChekcareerAndFinancial] = "";
          localStorage.setItem("arr_jobs_data", JSON.stringify(arr_jobs_data));
        }
      }
      if (page_value_of_invester.includes(ChekcareerAndFinancial)) {
        if (f3_invester[ChekcareerAndFinancial]) {
          f3_invester[ChekcareerAndFinancial] = "";
          localStorage.setItem("f3_invester", JSON.stringify(f3_invester));
        }
      }
      if (page_investment_info_income.includes(ChekcareerAndFinancial)) {
        if (f3[ChekcareerAndFinancial]) {
          f3[ChekcareerAndFinancial] = "";
          localStorage.setItem("f3", JSON.stringify(f3));
        }
      }

      var title = "ข้อมูลไม่ครบถ้วน";
      var msg = "ไม่สามารถทำรายการได้ กรุณากรอกข้อมูลเพิ่มเติม";
      var goTopage = pageJobs.includes(ChekcareerAndFinancial)
        ? "/jobs"
        : page_value_of_invester.includes(ChekcareerAndFinancial)
        ? "/value_of_invester"
        : page_investment_info_income.includes(ChekcareerAndFinancial)
        ? "/investment_info_income"
        : "";
      if (goTopage === "" && isValidPurpose.length > 0) {
        goTopage = "/investment_objective";
      }

      MySwal.fire({
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        customClass: {
          confirmButton: "long_btn_fix",
        },
        showConfirmButton: true,
        confirmButtonColor: "#003b66",
        confirmButtonText: "กรอกข้อมูลเพิ่มเติม",
        title: '<div class="title_popup">' + title + "</div>",
        html: '<div class="content_popup">' + msg + "</div>",
      }).then(() => {
        localStorage.setItem("BackToSummaryRisk", true);
        this.props.history.push(goTopage);
      });
      return;
    }

    const f1 = JSON.parse(localStorage.getItem("f1"))
      ? JSON.parse(localStorage.getItem("f1"))
      : {};
    const {
      f1_marital_status,
      f1_spouse_firstname,
      f1_spouse_firstname_en,
      f1_spouse_id_card,
      f1_spouse_is_th,
      f1_spouse_lastname,
      f1_spouse_lastname_en,
      f1_spouse_title,
      f1_spouse_title_text,
    } = f1;
    const familyStatus = {
      head_token,
      f1_marital_status: f1_marital_status,
      f1_spouse_title: f1_spouse_title,
      f1_spouse_is_th: f1_spouse_is_th,
      f1_spouse_firstname: f1_spouse_firstname,
      f1_spouse_lastname: f1_spouse_lastname,
      f1_spouse_title_text: f1_spouse_title_text,
      f1_spouse_firstname_en: f1_spouse_firstname_en,
      f1_spouse_lastname_en: f1_spouse_lastname_en,
      f1_spouse_id_card: f1_spouse_id_card,
    };
    const invalidFamilyFields = checkFamilyStatusRequired(familyStatus);
    if (invalidFamilyFields.length > 0) {
      let title = "ข้อมูลไม่ครบถ้วน";
      let message = "ไม่สามารถทำรายการได้ กรุณากรอกข้อมูลเพิ่มเติม";
      let url;

      if (invalidFamilyFields[0] === "head_token") {
        url = "/checkuserinfo";
      } else {
        url = "/FamilyStatus";
      }

      MySwal.fire({
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        customClass: {
          confirmButton: "long_btn_fix",
        },
        showConfirmButton: true,
        confirmButtonColor: "#003b66",
        confirmButtonText: "กรอกข้อมูลเพิ่มเติม",
        title: `<div class="title_popup">${title}</div>`,
        html: `<div class="content_popup">${message}</div>`,
      }).then(() => {
        localStorage.setItem("BackToSummaryRisk", true);
        this.props.history.push(url);
      });
      return;
    }

    const children_json = JSON.parse(localStorage.getItem("children_json"))
      ? JSON.parse(localStorage.getItem("children_json"))
      : [];
    const chilDren = {
      head_token,
      children_json,
    };

    const invalidChildStatusFields = checkChildStatusRequired(chilDren);
    if (invalidChildStatusFields.length > 0) {
      let title = "ข้อมูลไม่ครบถ้วน";
      let message = "ไม่สามารถทำรายการได้ กรุณากรอกข้อมูลเพิ่มเติม";
      let url;
      if (invalidChildStatusFields[0] === "head_token") {
        url = "/checkuserinfo";
      } else {
        url = "/statusChildren";
      }
      MySwal.fire({
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        customClass: {
          confirmButton: "long_btn_fix",
        },
        showConfirmButton: true,
        confirmButtonColor: "#003b66",
        confirmButtonText: "กรอกข้อมูลเพิ่มเติม",
        title: `<div class="title_popup">${title}</div>`,
        html: `<div class="content_popup">${message}</div>`,
      }).then(() => {
        localStorage.setItem("BackToSummaryRisk", true);
        this.props.history.push(url);
      });

      return;
    }
    const f5_relationship = JSON.parse(localStorage.getItem("f5_relationship"))
      ? JSON.parse(localStorage.getItem("f5_relationship"))
      : [];
    const {
      f5_relationship_have_a_position,
      f5_relationship_have_a_position_text,
    } = f5_relationship;
    const otherInformation = {
      head_token,
      f5_relationship_have_a_position: f5_relationship_have_a_position,
      f5_relationship_have_a_position_text:
        f5_relationship_have_a_position_text,
    };

    const invalidPoliticianRelationFields =
      checkPoliticianRelationRequired(otherInformation);
    if (invalidPoliticianRelationFields.length > 0) {
      let title = "ข้อมูลไม่ครบถ้วน";
      let message = "ไม่สามารถทำรายการได้ กรุณากรอกข้อมูลเพิ่มเติม";
      let url;
      if (invalidPoliticianRelationFields[0] === "head_token") {
        url = "/checkuserinfo";
      } else {
        url = "/PoliticianRelation";
      }
      MySwal.fire({
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        customClass: {
          confirmButton: "long_btn_fix",
        },
        showConfirmButton: true,
        confirmButtonColor: "#003b66",
        confirmButtonText: "กรอกข้อมูลเพิ่มเติม",
        title: `<div class="title_popup">${title}</div>`,
        html: `<div class="content_popup">${message}</div>`,
      }).then(() => {
        localStorage.setItem("BackToSummaryRisk", true);
        this.props.history.push(url);
      });

      return;
    }

    await this.setState({ statusLoading: true });
    [error, result] = await to(
      ktbsApi.post("/form/career_and_financial/manp", careerAndFinancial)
    );
    if (
      error ||
      !result ||
      !result.Data ||
      !result.Data[0] ||
      !result.Data[0][0] ||
      result.Data[0][0].IsSuccess !== 1
    ) {
      if (result) {
        title_mss =
          result.Data[0][0].MsgDescTitle !== ""
            ? result.Data[0][0].MsgDescTitle
            : "เกิดข้อผิดพลาด";
        mss =
          result.Data[0][0].MsgDesc !== ""
            ? result.Data[0][0].MsgDesc
            : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
      } else if (error) {
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss =
            error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
      } else {
        title_mss = "เกิดข้อผิดพลาด";
        mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
      }
      MySwal.fire({
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        customClass: {
          confirmButton: "long_btn_fix",
        },
        confirmButtonColor: "#003b66",
        confirmButtonText: "กลับ",
        title: '<div class="title_popup">' + title_mss + "</div>",
        html: '<div class="content_popup">' + mss + "</div>",
      });
      await this.setState({ statusLoading: false });
      return;
    } else {
      // console.log(result.Data[0][0].MsgDesc !== "");
    }
    [error, result] = await to(
      ktbsApi.post("/form/family_status/manp", familyStatus)
    );
    if (
      error ||
      !result ||
      !result.Data ||
      !result.Data[0] ||
      !result.Data[0][0] ||
      result.Data[0][0].IsSuccess !== 1
    ) {
      if (result) {
        title_mss =
          result.Data[0][0].MsgDescTitle !== ""
            ? result.Data[0][0].MsgDescTitle
            : "เกิดข้อผิดพลาด";
        mss =
          result.Data[0][0].MsgDesc !== ""
            ? result.Data[0][0].MsgDesc
            : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
      } else if (error) {
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss =
            error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
      } else {
        title_mss = "เกิดข้อผิดพลาด";
        mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
      }
      await this.setState({ statusLoading: false });
      popupErrorApi(mss, title_mss, true);
      return;
    } else {
      // console.log(result.Data[0][0].MsgDesc !== "");
    }
    [error, result] = await to(ktbsApi.post("/form/children/manp", chilDren));
    if (
      error ||
      !result ||
      !result.Data ||
      !result.Data[0] ||
      !result.Data[0][0] ||
      result.Data[0][0].IsSuccess !== 1
    ) {
      if (result) {
        title_mss =
          result.Data[0][0].MsgDescTitle !== ""
            ? result.Data[0][0].MsgDescTitle
            : "เกิดข้อผิดพลาด";
        mss =
          result.Data[0][0].MsgDesc !== ""
            ? result.Data[0][0].MsgDesc
            : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
      } else if (error) {
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss =
            error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
      } else {
        title_mss = "เกิดข้อผิดพลาด";
        mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
      }
      popupErrorApi(mss, title_mss, true);
      await this.setState({ statusLoading: false });
      return;
    }
    [error, result] = await to(
      ktbsApi.post("/form/other_information/manp", otherInformation)
    );
    if (
      error ||
      !result ||
      !result.Data ||
      !result.Data[0] ||
      !result.Data[0][0] ||
      result.Data[0][0].IsSuccess !== 1
    ) {
      if (result) {
        title_mss =
          result.Data[0][0].MsgDescTitle !== ""
            ? result.Data[0][0].MsgDescTitle
            : "เกิดข้อผิดพลาด";
        mss =
          result.Data[0][0].MsgDesc !== ""
            ? result.Data[0][0].MsgDesc
            : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
      } else if (error) {
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          title_mss =
            error.response.data.Data[0][0].MsgDescTitle !== ""
              ? error.response.data.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            error.response.data.Data[0][0].MsgDesc !== ""
              ? error.response.data.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }
      } else {
        title_mss = "เกิดข้อผิดพลาด";
        mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
      }
      await this.setState({ statusLoading: false });
      popupErrorApi(mss, title_mss, true);
      return;
    } else {
      // console.log(result.Data[0][0].MsgDesc !== "");
    }
    var stamp_page = "ATS";
    if (
      !this.isEmpty(localStorage.atsDataUser) &&
      !this.isEmpty(localStorage.atsData)
    ) {
      stamp_page = "SUMMARY";
    }
    await localStorage.setItem("currentStep", "ats_choice");
    var last_stamp = await JSON.stringify(this.allStorage());
    const ops = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
      },
      data: {
        last_stamp: last_stamp,
        head_token: HeaderManp.head_token,
        stamp_page: stamp_page,
      },
      url: _url + "/form/comeback/manp",
    };
    axios(ops)
      .then(async (res) => {
        this.setState({ loading: false });
        var keys = Object.keys(localStorage),
          i = keys.length;
        while (i--) {
          // console.log(i, keys[i]);
        }
        await this.setState({ statusLoading: false });
        if (
          !this.isEmpty(localStorage.atsDataUser) &&
          !this.isEmpty(localStorage.atsData)
        ) {
          window.location = "summaryshow";
        } else {
          window.location = "ats_choice";
        }
        // this.props.history.push('/ats_choice');
      })
      .catch((error) => {
        if (result) {
          title_mss =
            result.Data[0][0].MsgDescTitle !== ""
              ? result.Data[0][0].MsgDescTitle
              : "เกิดข้อผิดพลาด";
          mss =
            result.Data[0][0].MsgDesc !== ""
              ? result.Data[0][0].MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else if (error) {
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            title_mss =
              error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
        } else {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        }

        this.setState({ statusLoading: false });
        popupErrorApi(mss, title_mss, true);
        return;
      });

    // await this.setState({ statusLoading: false });
    // this.props.history.push('/ats_choice');

    // return
    // } else {
    //   this.setState({
    //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
    //   });
    // }
  };

  isEmpty(val) {
    return typeof val === "number" ? isNaN(val) : !Boolean(val);
  }

  allStorage() {
    var values = [],
      keys = Object.keys(localStorage),
      i = keys.length;
    while (i--) {
      if (
        keys[i].indexOf("debug") !== -1 
       //|| keys[i].indexOf("is_recheck_risk") !== -1 ||
        //keys[i].indexOf("is_recheck_risk_MsgDesc") !== -1 ||
        //keys[i].indexOf("is_recheck_risk_MsgDescTitle") !== -1
      ) {
      } else {
        values.push({
          key: keys[i],
          value:
            keys[i] === "currentStep"
              ? "ats_choice"
              : localStorage.getItem(keys[i]),
        });
      }
    }
    return values;
  }

  clickPopup = () => {
    this.setState({ popup: false });
  };
  alert_hide = () => {
    this.setState({ popup: false });
  };
  render() {
    library.add(fas);
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" alt="" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          alt="forward"
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">
                        ผลแบบประเมินความเสี่ยงของคุณคือ
                      </div>
                    </div>

                    <div className="bg_content_white " style={{ padding: "0" }}>
                      <div className="text_top_summary_risk">
                        <div>คะแนนความเสี่ยงที่รับได้</div>
                        <div className="circle">
                          <div className="totol_circle">
                            <div>{this.state.totol_score}</div>
                            <div style={{ lineHeight: "15px" }}>คะแนน</div>
                          </div>
                        </div>
                      </div>
                      <div className="content_summary_risk ">
                        <div className="row_idcard" style={{ border: 0 }}>
                          <div>ชื่อผู้เปิดบัญชี</div>
                          <div className="input_idcard">
                            {this.state.f0.f0_firstname}
                            {this?.state?.f0?.f0_middlename
                              ? " " + this?.state?.f0?.f0_middlename + " "
                              : " "}
                            {this.state.f0.f0_lastname}
                          </div>
                        </div>
                        <div
                          className="row_idcard"
                          style={{ padding: "8px 0 16px 0" }}
                        >
                          <div>ระดับความเสี่ยงที่ผู้ลงทุนรับได้</div>
                          <div className="input_idcard">{this.state.type}</div>
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: 24,
                          fontWeight: "bold",
                          padding: "18px 24px",
                          color: "#003b66",
                        }}
                      >
                        สัดส่วนการลงทุนแนะนำ{" "}
                        <span
                          onClick={() => this.alert_list()}
                          style={{ color: "#fdd836", fontSize: 22 }}
                        >
                          <FontAwesomeIcon icon={"info-circle"} />
                        </span>
                      </div>
                      <div className="main_list_bullet">
                        {this.state.render_risk}
                      </div>

                      {/* <ButtonCustom
              label='ตราสารอนุพันธ์และหุ้นกู้อนุพันธ์'
              color=''
              btnClass='btn_white'
              iconDirection='R'
              icon='info-circle'
            />
            <ButtonCustom
              label='หลักทรัพย์ต่างประเทศ'
              color=''
              btnClass='btn_white'
              iconDirection='R'
              icon='info-circle'
            />
            <ButtonCustom
              label='ตราสารหนี้'
              color=''
              btnClass='btn_white'
              iconDirection='R'
              icon='info-circle'
            />
            <ButtonCustom
              label='ตราสารทุน'
              color=''
              btnClass='btn_white'
              iconDirection='R'
              icon='info-circle'
            /> */}

                      <ButtonCustom
                        handle={this.handleSubmit}
                        label="ถัดไป"
                        color="blue"
                        btnClass="btn_summary_risk"
                      />
                    </div>
                  </div>
                  <Loading status={this.state.statusLoading}></Loading>

                  <div
                    className={"summary-risk-info-popup-container "}
                    style={{
                      pointerEvents: this.state.popup ? "auto" : "none",
                      backgroundColor: this.state.popup
                        ? "rgba(0, 0, 0, 0.5)"
                        : "transparent",
                      //display: this.state.popup ? "flex" : "none",
                    }}
                  >
                    <div
                      className="popup_opacity"
                      onClick={this.alert_hide}
                    ></div>
                    <div
                      className={
                        this.state.popup
                          ? "summary-risk-info-popup swal2-show"
                          : "summary-risk-info-popup swal2-hide"
                      }
                    >
                      <div className="summary-risk-info-popup-content">
                        <div className="title_popup_scroll">
                          รายละเอียดหลักทรัพย์
                        </div>
                        <ul
                          dangerouslySetInnerHTML={{
                            __html: this.state.contentPopup,
                          }}
                        />
                      </div>
                      <div
                        className="button-custom blue"
                        onClick={this.clickPopup}
                      >
                        กลับ
                      </div>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default KtbstSummaryRisk;
