import React, { Component } from "react";
import "./Fatca.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import Switch from "../../components/switch/Switch";
import axios from "axios";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { popupError, update_tracking } from "../../components/helpers/Helpers";
import Loading from "../../components/loading/loading";
import { config } from "./../../constants";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
const _url = config.baseAPIURL03;
class Fatca extends Component {
  constructor(props) {
    super(props);
    this.myRefChoice = React.createRef(); // ref div
    this.state = {
      fatca: [],
      fatcaQuestion: [],
      bt_next_hide: false,
      isAmerica: true,
      isDisconnected: false,
      loading: false,
    };

    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.push("/idc_detail");
  }

  async componentDidMount() {
    await this.setState({ loading: true });
    await axios
      .get(_url + `/constant/fatca`)
      .then((res) => {
        if (res.data.Data === null) {
          this.setState({ bt_next_hide: true });
          popupError(
            "กรุณาทำรายการใหม่อีกครั้ง",
            "เกิดข้อผิดพลาด",
            function name() {
              window.location = "login";
            }
          );
        } else {
          this.setState({ fatca: res.data });
          const conDataFatca = Object.entries(
            this.state.fatca
          ).map(([key, value]) => ({ key, value }));
          this.setState({
            fatcaQuestion: conDataFatca[0].value,
            isAmerica: false,
          });
          // download_ico_alert();
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (!!error.isAxiosError && !error.response) {
          popupError(
            "กรุณาทำรายการใหม่อีกครั้ง",
            "เกิดข้อผิดพลาด",
            function name() {
              window.location = "login";
            }
          );
        } else {
          if (error.response.data.Data[0][0].IsSuccess === 0) {
            let title = error.response.data.Data[0][0].MsgDescTitle;
            title = title && title !== "" ? title : "เกิดข้อผิดพลาด";
            popupError(
              error.response.data.Data[0][0].MsgDesc,
              title,
              function name() {
                window.location = "login";
              }
            );
          } else {
            popupError(
              "กรุณาทำรายการใหม่อีกครั้ง",
              "เกิดข้อผิดพลาด",
              function name() {
                window.location = "login";
              }
            );
          }
        }
      });
    const back_from_productchoice = JSON.parse(localStorage.getItem("back_from_productchoice"));
    if (back_from_productchoice) {
      let oldData_f0 = localStorage.getItem("f0");
      let oldData_photo1 = localStorage.getItem("photo1");
      let oldData_photo2 = localStorage.getItem("photo2");
      let oldData_checkuserinfo = localStorage.getItem("checkuserinfo");
      localStorage.setItem("oldData_f0", oldData_f0);
      localStorage.setItem("oldData_photo1", oldData_photo1);
      localStorage.setItem("oldData_photo2", oldData_photo2);
      localStorage.setItem("oldData_checkuserinfo", oldData_checkuserinfo);
      localStorage.setItem("comebackWithOtp", true);
      localStorage.removeItem("back_from_productchoice");
    }
    await this.setState({ loading: false });
  }

  alert_info = (mss, page) => {
    // console.log(page);
    const MySwal = withReactContent(swal);
    if (mss !== "") {
      MySwal.fire({
        // icon: 'warning',
        customClass: {
          confirmButton: "long_btn_fix",
          cancelButton: "long_btn_fix",
          header: "title_left",
        },
        imageUrl: "/images/ico_alert.svg",
        imageWidth: 100,
        imageHeight: 100,
        confirmButtonColor: "#01375f",
        confirmButtonText: "กลับ",
        title:
          '<span class="title_popup" style="white-space: pre-line; ">' +
          page +
          "</span>",
        html:
          '<div class="content_popup" style="text-align: left;">' +
          mss +
          "</div>",
      }).then(function () {
        if (page) {
          // window.location = page;
          // this.props.history.push(page);
        }
        // window.location = "summaryshow";
      });
    }
  };

  Send_fatca = async (e) => {
    e.preventDefault();
    const MySwal = withReactContent(swal);
    var choiceList = document.querySelector(".DivChoiceFatca").children;
    var arr_choice = [];
    var i = 0;
    var y = 0;
    for (i = 0; i < choiceList.length; i++) {
      var _children =
        choiceList[i].children[1].children[1].children[0].children[0].children;
      var c = 0;
      for (c = 0; c < _children.length; c++) {
        if (_children[c].className.includes("active")) {
          if (_children[c].innerText === "ใช่") {
            y = y + 1;
            arr_choice.push({
              SAffcc_SAmfc_Id: choiceList[i].children[1].dataset.id,
              SAffcc_Answer: "1",
            });
          } else {
            arr_choice.push({
              SAffcc_SAmfc_Id: choiceList[i].children[1].dataset.id,
              SAffcc_Answer: "2",
            });
          }
        }
      }
    }

    const count_yes = y;
    if (count_yes > 0) {
      const checkuserinfo = await JSON.parse(
        localStorage.getItem("checkuserinfo")
      );
      var data = {}
      data.head_token = "";
      data.card_id = checkuserinfo !== null ? checkuserinfo.cardId : "";
      data.stamp_page = "1";
      data.error_code = "3001";
      var update_tracking_send = await update_tracking(data);
      if (update_tracking_send.success) {
        MySwal.fire({
          customClass: {
            confirmButton: "long_btn_fix",
            cancelButton: "long_btn_fix",
          },
          imageUrl: "/images/ico_alert.svg",
          imageWidth: 100,
          imageHeight: 100,
          confirmButtonColor: "#01375f",
          confirmButtonText: "กลับ",
          title:
            '<div class="title_popup">ขออภัย ทางบริษัทฯ <br>ไม่มีนโยบายการให้บริการ <br>ลูกค้าที่เป็นพลเมืองอเมริกัน </div>',
          html:
            '<div class="content_popup"> สอบถามข้อมูลเพิ่มเติมกรุณาติดต่อ <br> DAOL Contact Center 0 2351 1800</div>',
        });
      } else {
        // api error
      }
    } else {
      // if (checkInternet()) {
      // const checkuserinfo = JSON.parse(localStorage.getItem('checkuserinfo'))
      localStorage.setItem("fatca", JSON.stringify(arr_choice));
      // this.props.history.push("/otp?page=fatca");
      this.props.history.push("crs_birth_address");
      // } else {
      //   this.setState({
      //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
      //   })
      // }
    }
  };

  render() {
    const ArrQ =
      typeof this.state.fatcaQuestion !== "undefined" &&
        typeof this.state.fatcaQuestion !== "null"
        ? this.state.fatcaQuestion.length - 1
        : [];
    let HrBottom;
    if (ArrQ) {
      HrBottom = <hr className="BorderFatca"></hr>;
    } else {
      HrBottom = "";
    }
    const items =
      typeof this.state.fatcaQuestion === "undefined" &&
        typeof this.state.fatcaQuestion === ""
        ? []
        : this.state.fatcaQuestion.map((item, index) => {
          const _question = item.SAmfc_ThQuestion;
          var _new_question = "";
          var _new_info = "";
          var PREFIX = "";
          var array_question = [];

          if (
            index === 0 ||
            index === 1 ||
            index === 2 ||
            index === 4 ||
            index === 7 ||
            index === 8
          ) {
            PREFIX = "ใช่หรือไม่";
            array_question = _question.split(PREFIX);
            array_question.pop();
            _new_question = array_question.join();
            _new_question = _new_question + " " + PREFIX;
          } else if (index === 5) {
            PREFIX = "หรือไม่";
            array_question = _question.split(PREFIX);
            array_question.pop();
            _new_question = array_question.join();
            _new_question = _new_question + " " + PREFIX;
          } else if (index === 6) {
            PREFIX = "หรือไม่";
            array_question = _question.split(PREFIX);
            array_question.pop();
            _new_question = array_question.join();
            _new_question = _new_question + "" + PREFIX;
          } else {
            _new_question = item.SAmfc_ThQuestion;
          }
          var _question_split_newline = _question.split("\n");
          for (
            let index_line_info = 1;
            index_line_info < _question_split_newline.length;
            index_line_info++
          ) {
            // var english = /^[A-Za-z0-9]*$/;
            const element = _question_split_newline[index_line_info];
            var res = element.slice(0, 1);
            if (/[A-Za-z0-9]/.test(res)) {
            } else {
              if (index === 0) {
                _new_info =
                  _new_info +
                  '<div>• <span style="margin-left: 4px;">' +
                  element +
                  "</span></div>";
              } else if (index === 1) {
                var array_info = [];
                var PREFIX_ = "แบบฟอร์มนี้";
                array_info = element.split("แบบฟอร์มนี้");
                // if (array_info[0].includes('โปรดตอบ')) {
                //   _new_info = _new_info + '<li>' + element;
                // } else if (array_info[0].includes('ควรตอบ')) {
                //   // _new_info = _new_info + PREFIX_ + '</li><li>' + element + '</li>';
                // } else {
                //   _new_info = _new_info + element;
                // }
                _new_info =
                  '<div>• <span style="margin-left: 4px;">' +
                  array_info[0] +
                  PREFIX_ +
                  '</span></div><div>• <span style="margin-left: 4px;">' +
                  array_info[1] +
                  PREFIX_ +
                  "</span></div>";
              } else {
                _new_info = _new_info + element;
              }
            }
          }

          var bt_info_show_hide = "block";
          if (_new_info === "") {
            bt_info_show_hide = "none";
          }
          const new_question = _new_question;
          var _choiceL = item.SAmfc_ThEnAnswer[0].Text;
          var _choiceR = item.SAmfc_ThEnAnswer[1].Text;
          var array_choiceL = _choiceL.split("/");
          var array_choiceR = _choiceR.split("/");
          array_choiceL.pop();
          array_choiceR.pop();
          const new_choiceL = array_choiceL.join();
          const new_choiceR = array_choiceR.join();
          if (index === ArrQ) {
            HrBottom = "";
          } else {
            HrBottom = <hr className="BorderFatca"></hr>;
          }
          return (
            <div className="DivDetailQestion" key={index}>
              <span
                className="TxtQuestionFatca"
                style={{ whiteSpace: "pre-line" }}
              >
                {new_question}
              </span>
              <div className="DivDetailBtnFatca" data-id={item.SAmfc_Id}>
                <div className="DivHalfBtnFatcaL">
                  <div
                    style={{ display: bt_info_show_hide }}
                    onClick={() => {
                      this.alert_info(_new_info, new_question);
                    }}
                  >
                    <ButtonCustom
                      label="รายละเอียด"
                      color="white"
                      btnClass="BtnInfoFatca"
                      iconDirection="L"
                      icon="info-circle"
                    ></ButtonCustom>
                  </div>
                </div>
                <div className="DivHalfBtnFatca">
                  <Switch labelL={new_choiceL} labelR={new_choiceR}></Switch>
                </div>
              </div>
              {HrBottom}
            </div>
          );
        });
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" alt="logo" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div className="Page">
                  <Loading status={this.state.status_popup}></Loading>
                  <div className="top_main_back">
                    <div className="main_back" onClick={this.goBack}>
                      <img
                        src={
                          isMobile
                            ? "/images/angle-arrow-left.svg"
                            : "/images/angle-arrow-left-blue.svg"
                        }
                        alt="forward"
                        style={{ marginLeft: "3px" }}
                        className="arrow_back"
                      />
                    </div>
                    <div className="text_title">สถานะพลเมืองอเมริกัน</div>
                  </div>

                  <div className="bg_content_white">
                    <div className="DivDetailFatca">
                      <div className="DivDetailTitleFatca">
                        ข้อมูลเกี่ยวกับ FATCA
                        ตามที่กฏหมายที่ออกโดยประเทศสหรัฐอเมริกา
                      </div>
                      <div ref={this.myRefChoice} className="DivChoiceFatca">
                        {items}
                      </div>

                      <div
                        className="BtnFooter"
                        style={{
                          display: this.state.bt_next_hide ? "none" : "block",
                        }}
                      >
                        <ButtonCustom
                          handle={this.Send_fatca}
                          label="ถัดไป"
                          color="blue"
                        ></ButtonCustom>
                      </div>
                    </div>
                  </div>
                  <Loading status={this.state.loading} />
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Fatca;
