import React, { Component } from "react";
import "./NdidOffline.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/loading/loading";
import { IMaskInput } from "react-imask";
import validator from "validator";
import { checkIdcard, popupErrorApi } from "../../components/helpers/Helpers";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import $ from "jquery";
import qs from "qs";
import { isMobile, browserName, isIOS, isAndroid } from "react-device-detect";
import { config } from "./../../constants";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
const _url = config.baseAPIURL03;
class NdidOfline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisconnected: false,
      isLoading: false,
      isSupport: false,
      isSubmit: false,
      cardId: "",
      f0_lazer_card: "",
    };
    this.goBack = this.goBack.bind(this);
    this.checkDopa = this.checkDopa.bind(this);
  }

  back_idcard = (v) => {
    v = v.toUpperCase();
    var regex = /[^a-zA-Z0-9]/g;
    let t = v.replace(regex, "");
    this.setState({ f0_lazer_card: validator.trim(t) });
    // if (this.state.isSubmit) {
    this.validate();
    // }
  };

  checkDopa = async (identifier, f0_lazer_card, token) => {
    let vm = this;
    let url = "https://test-ws06.ktbst.co.th/ktbst/v1/services/ndid/dopa";
    // let url = `${config.baseAPIURL06}/ktbst/v1/services/ndid/dopa`;
    return await axios
      .post(url, {
        identifier: identifier,
        laser: f0_lazer_card,
        token: token,
      })
      .then(async (res) => {
        let checkDopa = res.data.IsSuccess;
        if (checkDopa) {
          this.setState({ isLoading: false });
          return true;
        } else {
          this.setState({ isLoading: false });
          var mss = "";
          var title_mss = "";
          title_mss =
            res.data.MsgDesc !== undefined && res.data.MsgDesc !== ""
              ? "ขออภัย"
              : "เกิดข้อผิดพลาด";
          mss =
            res.data.MsgDesc !== undefined && res.data.MsgDesc !== ""
              ? res.data.MsgDesc
              : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          const MySwal = withReactContent(swal);
          MySwal.fire({
            customClass: {
              confirmButton: "long_btn_fix",
              cancelButton: "long_btn_fix",
            },
            imageUrl: "/images/ico_alert.svg",
            imageWidth: 100,
            imageHeight: 100,
            confirmButtonColor: "#003b66",
            confirmButtonText: "กลับ",
            title: `<span class="title_popup">${title_mss}</span>`,
            html: `<div class="content_popup">${mss}</div>`,
          }).then(function () {
            // window.location = '/index.html';
            var items = document.querySelector(".input_idcard");
            items.parentNode.scrollIntoView({ behavior: "smooth" });
            return;
          });
          // popupErrorApi(mss, title_mss, true);
          return false;
        }
      })
      .catch((error) => {
        vm.setState({ isLoading: false });
        var mss = "";
        var title_mss = "";
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            console.log(error);
            title_mss =
              error.response?.data.Data !== undefined &&
              error.response?.data.Data[0][0].MsgDescTitle !== ""
                ? error.response?.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              error.response?.data.Data !== undefined &&
              error.response?.data.Data[0][0].MsgDesc !== ""
                ? error.response?.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
        }
        popupErrorApi(mss, title_mss, true);
        return false;
      });
  };

  async componentDidMount() {
    const debug = JSON.parse(localStorage.getItem("debug"));
    if (debug) {
      this.setState({
        cardId: "1729900351764",
        f0_lazer_card: "ME1125078519",
      });
    }
    localStorage.clear();
    this.setState({
      isLoading: true,
    });
    const splashScreen = document.querySelector(".app-splash-screen-ndid");
    splashScreen.style.display = "block";
    splashScreen.style.opacity = 1;
    setTimeout(
      function () {
        // console.log(JSON.stringify({
        //   isAndroid: /Android/.test(navigator.userAgent),
        //   isCordova: !!window.cordova,
        //   isEdge: /Edge/.test(navigator.userAgent),
        //   isFirefox: /Firefox/.test(navigator.userAgent),
        //   isChrome: /Google Inc/.test(navigator.vendor),
        //   isChromeIOS: /CriOS/.test(navigator.userAgent),
        //   isChromiumBased: !!window.chrome && !/Edge/.test(navigator.userAgent),
        //   isIE: /Trident/.test(navigator.userAgent),
        //   isIOS: /(iPhone|iPad|iPod)/.test(navigator.platform),
        //   isOpera: /OPR/.test(navigator.userAgent),
        //   isSafari: /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent),
        // }, null, '  '));
        let isChrome = /Google Inc/.test(navigator.vendor);
        let isIOS = /(iPhone|iPad|iPod)/.test(navigator.platform);
        let isFirefox = /Firefox/.test(navigator.userAgent);
        let isSafari =
          /Safari/.test(navigator.userAgent) &&
          !/Chrome/.test(navigator.userAgent);
        let isSupport = false;
        if (isIOS) {
          if (isSafari || browserName === "Mobile Safari") {
            isSupport = true;
          }
          if (
            browserName === "Edge" ||
            browserName === "Firefox" ||
            browserName === "Chrome"
          ) {
            isSupport = false;
          }
        } else {
          isSupport = isChrome || isFirefox || isSafari;
        }
        console.log("isSupport ", isSupport);
        let query = this.getQueryParams(document.location.search);
        let token = query.token;
        let identifier = query.identifier;
        if (!token || !identifier) {
          this.props.history.push("/index.html");
        }
        if (!checkIdcard(identifier)) {
          // console.log(identifier);
          //   const vm = this;
          //   let title_mss = "เกิดข้อผิดพลาด";
          //   let mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          //   const MySwal = withReactContent(swal);
          //   MySwal.fire({
          //     customClass: {
          //       confirmButton: "long_btn_fix",
          //       cancelButton: "long_btn_fix",
          //     },
          //     imageUrl: "/images/ico_approve.svg",
          //     imageWidth: 100,
          //     imageHeight: 100,
          //     confirmButtonColor: "#003b66",
          //     confirmButtonText: "ถัดไป",
          //     title: `<span class="title_popup">${title_mss}</span>`,
          //   }).then(function () {
          //     vm.props.history.push("/index.html");
          //   });
        }
        splashScreen.style.display = "none";
        splashScreen.style.opacity = 0;
        this.setState({
          isSupport: isSupport,
          isLoading: false,
        });
      }.bind(this),
      1000
    );
  }

  getQueryParams(qs) {
    qs = qs.split("+").join(" ");
    var params = {},
      tokens,
      re = /[?&]?([^=]+)=([^&]*)/g;
    while ((tokens = re.exec(qs))) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }
    return params;
  }

  goBack() {
    this.props.history.push("/index.html");
  }

  handleChange = async (e) => {
    e.preventDefault();
    const isCheckbox = e.target.type === "checkbox";
    const value =
      e.target.name === "cardId" || e.target.name === "f0_mobile_phone"
        ? e.target.value.replace(/[\D]+/g, "")
        : // : e.target.name === 'f0_email'
          validator.trim(e.target.value);
    await this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : value,
    });
    // if (this.state.isSubmit) {
    await this.validate();
    // }
  };

  handleSubmit = async () => {
    const { cardId, f0_lazer_card } = this.state;
    let query = this.getQueryParams(document.location.search);
    let token = await query.token;
    let identifier = await query.identifier;
    identifier = atob(identifier);
    // console.log("encode cardId", btoa(cardId));
    // console.log("encode token", btoa(token));
    // console.log("decode token from url = ", token);
    // console.log("decode identifier from url = ", identifier);
    let checkDopa = false;
    this.setState({ isLoading: true, isSubmit: true });
    const isValid = await this.validate();
    if (isValid) {
      if (token && identifier && cardId && f0_lazer_card) {
        if (identifier === cardId) {
          checkDopa = await this.checkDopa(identifier, f0_lazer_card, token);
          if (checkDopa) {
            localStorage.setItem("identifier", identifier);
            localStorage.setItem("token", token);
            this.props.history.push("/ndid_info");
          }
        } else {
          this.setState({ isLoading: false });
          $(".span_f0_cardId2").css("display", "flex");
        }
      } else {
        this.setState({ isLoading: false });
        let title_mss = "เกิดข้อผิดพลาด";
        let mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        popupErrorApi(mss, title_mss, true);
      }
    } else {
      this.setState({ isLoading: false });
    }
  };

  validate = async () => {
    $("span[class^=span_f0]").hide();
    let countError = 0;
    if (!checkIdcard(this.state.cardId)) {
      $(".span_f0_cardId").css("display", "flex");
      countError++;
    }
    if (!validator.isLength(this.state.f0_lazer_card, { min: 12, max: 12 })) {
      $(".span_f0_lazer_card").css("display", "flex");
      countError++;
    }
    return countError > 0 ? false : true;
  };

  render() {
    const { isLoading, isSupport, cardId, f0_lazer_card } = this.state;
    library.add(fas);
    return (
      <div className={isMobile ? "" : "body"}>
        <div
          className="app-splash-screen-ndid"
          style={{
            background:
              "#fff url('/images/logo.png') no-repeat scroll center center",
          }}
        ></div>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <Loading status={isLoading} />
                  <div className="content">
                    <div className="top_main_back">
                      <div
                        className="main_back"
                        onClick={() => {
                          this.goBack();
                        }}
                      >
                        {/* <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          style={{
                            marginLeft: "3px",
                          }}
                          className="arrow_back"
                        /> */}
                      </div>
                      <div className="text_title">
                        พิสูจน์การยืนยันตัวตนทางดิจิทัล (NDID)
                      </div>
                    </div>

                    <div
                      className="bg_content_white over_f_hide"
                      id="my_scroll"
                    >
                      {/* show if browser */}
                      <div
                        className="in_bg_white autoscroll"
                        style={{
                          bottom: 90,
                          top: 0,
                          left: 0,
                          right: 0,
                          padding: "24px 21px 0 21px",
                          display: isSupport ? "none" : "block",
                        }}
                      >
                        <img
                          src={"/images/logo.png"}
                          alt=""
                          style={{
                            display: "block",
                            margin: "auto",
                            width: "60%",
                            marginBottom: isMobile ? "30px" : "40px",
                          }}
                        />
                        <hr className="BorderFatca"></hr>
                        <div
                          className="grid-container"
                          style={{ marginBottom: "5px" }}
                        >
                          <div style={{ width: "60px" }}>
                            <img
                              src="/images/chrome.png"
                              width="50"
                              alt="chrome"
                            />
                          </div>
                          <div style={{ width: isMobile ? "200px" : "300px" }}>
                            <span style={{ fontWeight: "bold" }}>Chrome</span>
                            <br></br>
                            <span style={{ margin: "auto" }}>
                              สำหรับระบบปฏิบัติการ Windows และ Android
                            </span>
                          </div>
                        </div>
                        <div
                          className="grid-container"
                          style={{ marginBottom: "5px" }}
                        >
                          <div style={{ width: "60px" }}>
                            <img
                              src="/images/safari.png"
                              width="50"
                              alt="safari"
                            />
                          </div>
                          <div style={{ width: isMobile ? "200px" : "300px" }}>
                            <span style={{ fontWeight: "bold" }}>Safari</span>
                            <br></br>
                            <span style={{ margin: "auto" }}>
                              สำหรับระบบปฏิบัติการ iOS
                            </span>
                          </div>
                        </div>
                        <div
                          style={{ marginBottom: "26px", textAlign: "center" }}
                        >
                          <span>
                            กรุณาเปิดใช้งานจากเว็บเบราว์เซอร์ที่รองรับ
                            หากยังไม่ได้ ติดตั้งแอปพลิเคชัน
                            กรุณาดำเนินการก่อนใช้บริการ
                          </span>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <span>สอบถามข้อมูลเพิ่มเติม ติดต่อ DAOL</span>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <img
                            src="/images/call.svg"
                            width="20"
                            alt="call"
                            style={{ verticalAlign: "middle" }}
                          />
                          <span
                            style={{ fontWeight: "bold", marginLeft: "3px" }}
                          >
                            Contact Center
                          </span>
                        </div>
                        <div
                          style={{ marginBottom: "26px", textAlign: "center" }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "30px" }}
                          >
                            0 2351 1800
                          </span>
                        </div>
                      </div>
                      {/* end show if browser */}

                      <div
                        className="in_bg_white autoscroll"
                        style={{
                          bottom: 90,
                          top: 0,
                          left: 0,
                          right: 0,
                          padding: "24px 21px 0 21px",
                          display: !isSupport ? "none" : "block",
                        }}
                      >
                        <img
                          src={"/images/loading_check.svg"}
                          alt=""
                          style={{
                            display: "block",
                            margin: "auto",
                            height: "100px",
                            marginBottom: isMobile ? "20px" : "30px",
                          }}
                        />
                        <div
                          style={{ marginBottom: "26px", textAlign: "left" }}
                        >
                          ขณะนี้บัญชีที่ท่านเปิดผ่านผู้แนะนำการลงทุน
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            อยู่ระหว่าง รอการ Activate
                            กรุณาดำเนินการยืนยันตัวตนทาง ดิจิทัลด้วย NDID{" "}
                          </span>
                          เพื่อเริ่มลงทุนและใช้บริการของ {config.WEB_NAME}
                        </div>
                        <div
                          style={{ marginBottom: "26px", textAlign: "left" }}
                        >
                          กรุณากรอกเลขที่บัตรประจำตัวประชาชน และหมายเลข
                          เลเซอร์การ์ดหลังบัตร เพื่อดำเนินการต่อ
                        </div>
                        <div className={"row_idcard"}>
                          <div style={{ fontSize: 18 }}>
                            เลขที่บัตรประจำตัวประชาชน *
                          </div>
                          <input
                            type="tel"
                            onMouseOut={this.handleMouse}
                            className="input_idcard input--textfield"
                            maxLength="13"
                            name="cardId"
                            value={cardId}
                            onChange={this.handleChange}
                            style={{
                              borderColor: cardId !== "" ? "#003b66" : "#ccc",
                            }}
                          />

                          <span className="span_f0_cardId">
                            กรุณาระบุเลขที่บัตรประจำตัวประชาชน
                          </span>
                          <span className="span_f0_cardId2">
                            กรุณาระบุเลขที่บัตรประจำตัวประชาชนให้ถูกต้อง
                          </span>
                        </div>
                        <div className="row_idcard">
                          <div style={{ fontSize: 18 }}>
                            หมายเลขเลเซอร์การ์ดหลังบัตร <span>*</span>
                          </div>
                          <IMaskInput
                            mask={"aa0-0000000-00"}
                            value={f0_lazer_card}
                            unmask={true}
                            name="f0_lazer_card"
                            className="input_idcard"
                            style={{
                              textDecorationLine: "none",
                              borderColor:
                                f0_lazer_card !== "" ? "#003b66" : "#ccc",
                            }}
                            onAccept={(value) => this.back_idcard(value)}
                          />
                          <span className="span_f0_lazer_card">
                            กรุณาระบุหมายเลขเลเซอร์การ์ดหลังบัตร
                          </span>
                        </div>
                      </div>

                      <div
                        style={{
                          background: "#fff",
                          bottom: 10,
                          position: "absolute",
                          justifyContent: "flex-end",
                          left: "21px",
                          right: "21px",
                        }}
                      >
                        <div>
                          <div
                            style={{ display: !isSupport ? "none" : "block" }}
                          >
                            <ButtonCustom
                              handle={() => {
                                this.handleSubmit();
                              }}
                              label="ถัดไป"
                              color="blue"
                              btnClass="long_btn"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default NdidOfline;
