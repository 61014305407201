/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component, useState } from 'react';
import {
  checkIdcard,
  checkEditPage,
  checkInternet,
  popupNetworkLost,
  download_ico_alert,
  popupErrorApi,
} from '../../components/helpers/Helpers';

import ButtonCustom from '../../components/button/ButtonCustom';
import './FamilyStatus.css';
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';
import 'react-day-picker/lib/style.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import validator from 'validator';
import { IMaskInput } from 'react-imask';
import DatePickerInput from './../../components/date_picker_input/DatePickerInput';
import Loading from '../../components/loading/loading';
import to from 'await-to-js';
import ktbsApi from './../../api';
import { config } from './../../constants';
import { isMobile } from 'react-device-detect';
import BannerContentLeft from '../../components/BannerContentLeft/BannerContentLeft';
const _url = config.baseAPIURL03;
class FamilyStatus extends Component {
  constructor(props) {
    super(props);
    const RetrieveData = localStorage.getItem('RetrieveData')
      ? JSON.parse(localStorage.getItem('RetrieveData'))
      : {};
    const f1 = localStorage.getItem('f1')
      ? JSON.parse(localStorage.getItem('f1'))
      : {};
    // var marital_status_value =
    //   f1 && f1.f1_marital_status !== undefined
    //     ? f1.f1_marital_status
    //     : RetrieveData
    //     ? this.convertMaritalStatusToInt(RetrieveData.marital_status)
    //     : '1';
    var marital_status_value =
      f1 && f1.f1_marital_status !== undefined ? f1.f1_marital_status : '1';
    // console.log(RetrieveData.marital_status);
    // console.log(marital_status_value);

    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate();
    this.state = {
      to_day: new Date(year, month, day),
      max_year_old: new Date(year - 120, month, day),
      marital_status_arr: [],
      // marital_status: f1 ? f1.f1_marital_status : '1',
      marital_status: marital_status_value,
      statusMarry: [],
      statusMarry1: [],
      title: [],
      color_single_btn: 'active',
      color_marry_btn: 'unactive',
      CheckAmerican: true,
      isSubmit: false,
      isDisconnected: false,
      ischeckIdcard: false,
      thaidate: '',
      time: new Date(),
      isOpen: false,
      loading: true,
      f1_title_Oher:
        f1 && f1.f1_spouse_title_text ? f1.f1_spouse_title_text : '',
      f1_spouse_id_card_exist: false,
      f1_spouse_tel_exist: false,
      f1_spouse_tel_isEmpty: false,
      radio_0: f1.f1_spouse_radio !== undefined ? f1.f1_spouse_radio : true,
      radio_1: f1.f1_spouse_radio !== undefined ? !f1.f1_spouse_radio : false,
      f1_spouse_is_th:
        f1.f1_spouse_is_th !== undefined
          ? f1.f1_spouse_is_th
          : f1.f1_spouse_radio !== undefined
          ? f1.f1_spouse_radio
          : true,
      f1_marital_status:
        f1 && f1.f1_marital_status
          ? f1.f1_marital_status
          : marital_status_value,
      f1_spouse_id_card: f1 && f1.f1_spouse_id_card ? f1.f1_spouse_id_card : '',
      f1_spouse_firstname:
        f1 && f1.f1_spouse_firstname ? f1.f1_spouse_firstname : '',
      f1_spouse_lastname:
        f1 && f1.f1_spouse_lastname ? f1.f1_spouse_lastname : '',
      f1_spouse_firstname_en:
        f1 && f1.f1_spouse_firstname_en ? f1.f1_spouse_firstname_en : '',
      f1_spouse_lastname_en:
        f1 && f1.f1_spouse_lastname_en ? f1.f1_spouse_lastname_en : '',
      f1_spouse_birth_date:
        f1 && f1.f1_spouse_birth_date ? f1.f1_spouse_birth_date : '',
      f1_mobile_phone_1: f1 && f1.f1_mobile_phone_1 ? f1.f1_mobile_phone_1 : '',
      f1_spouse_title: f1 && f1.f1_spouse_title ? f1.f1_spouse_title : '',
    };
    this.changeMaritalStatus = this.changeMaritalStatus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  convertMaritalStatusToInt(value) {
    const val = value.toLowerCase();
    if (val.includes('s')) {
      return '1';
    } else {
      return '2';
    }
  }

  goBack = (e) => {
    if (checkEditPage()) {
      return;
    }
    e.preventDefault();
    this.props.history.push('/jobs');
  };

  chkIdcard(a) {
    const tt = checkIdcard(a);
    this.setState({
      ischeckIdcard: tt,
    });
  }

  async componentDidMount() {
    await this.setState({ loading: true });
    // await download_ico_alert();
    const RetrieveData = JSON.parse(localStorage.getItem('RetrieveData'));
    var pattern = /\d{5}(-\d{4})?/;

    await axios
      .get(_url + `/constant/marital_status`)
      .then((res) => {
        const marital_status_arr = _.filter(res.data.Data, function (o) {
          return o.ThName;
        });
        this.setState({ marital_status_arr: marital_status_arr });
      })
      .catch((error) => {
        var mss = '';
        var title_mss = '';
        if (!!error.isAxiosError && !error.response) {
          title_mss = 'เกิดข้อผิดพลาด';
          mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
        } else {
          if (!!error.isAxiosError && !error.response) {
            title_mss = 'เกิดข้อผิดพลาด';
            mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
          } else {
            title_mss =
              error.response.data.Data !== undefined &&
              error.response.data.Data[0][0].MsgDescTitle !== ''
                ? error.response.data.Data[0][0].MsgDescTitle
                : 'เกิดข้อผิดพลาด';
            mss =
              error.response.data.Data !== undefined &&
              error.response.data.Data[0][0].MsgDesc !== ''
                ? error.response.data.Data[0][0].MsgDesc
                : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
          }
        }
        popupErrorApi(mss, title_mss, true);
      });

    const f1 = localStorage.getItem('f1')
      ? JSON.parse(localStorage.getItem('f1'))
      : {};
    await axios
      .get(_url + `/constant/title`)
      .then((res) => {
        this.setState({ Data_title: res.data.Data });
        // console.log(this.state.Data_title);
        const title = res.data.Data.map((item, i) => {
          if (f1 && f1.f1_spouse_title) {
            if (f1.f1_spouse_title === item.Id) {
              this.setState({ this_title: item });
            }
          }
          return (
            <option value={item.Id} key={i} style={{ color: '#003b66' }}>
              {item.NameTH}
            </option>
          );
        });
        this.setState({ title: title });
      })
      .catch((error) => {
        var mss = '';
        var title_mss = '';
        if (!!error.isAxiosError && !error.response) {
          title_mss = 'เกิดข้อผิดพลาด';
          mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
        } else {
          if (!!error.isAxiosError && !error.response) {
            title_mss = 'เกิดข้อผิดพลาด';
            mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
          } else {
            title_mss =
              error.response.data.Data !== undefined &&
              error.response.data.Data[0][0].MsgDescTitle !== ''
                ? error.response.data.Data[0][0].MsgDescTitle
                : 'เกิดข้อผิดพลาด';
            mss =
              error.response.data.Data !== undefined &&
              error.response.data.Data[0][0].MsgDesc !== ''
                ? error.response.data.Data[0][0].MsgDesc
                : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
          }
        }
        popupErrorApi(mss, title_mss, true);
      });
    await this.setState({ loading: false });
  }

  changeMaritalStatus = async (e) => {
    e.preventDefault();
    const marital_status = e.currentTarget.dataset.id;
    await this.setState({
      marital_status: marital_status,
      f1_marital_status: marital_status,
    });
    if (marital_status === '1') {
      await this.setState({
        f1_spouse_id_card: '',
        f1_spouse_firstname: '',
        f1_spouse_lastname: '',
        f1_spouse_firstname_en: '',
        f1_spouse_lastname_en: '',
        f1_spouse_birth_date: '',
        f1_mobile_phone_1: '',
        f1_spouse_title: '',
      });
    } else {
      await this.setState({
        radio_0: true,
        radio_1: false,
      });
      var a = document.getElementsByName('group_0');
      await a.forEach((item, i) => {
        if (item.id === 'radio_0') {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
    }
  };

  handleChange = async (e) => {
    e.preventDefault();
    if (e.target.name === 'f1_spouse_title') {
      this.state.Data_title.forEach((item, i) => {
        if (item.Id == e.target.value) {
          this.setState({ this_title: item });
        }
        if (item.Other === 1) {
          // console.log(this.state.f1_title_Oher);
        } else {
          // console.log(this.state.f1_spouse_title);
          this.setState({ f1_title_Oher: '' });
        }
      });
      if (this.state.isSubmit) {
        this.validate();
      }
    }

    const isCheckbox = e.target.type === 'checkbox';
    const value =
      e.target.name === 'f1_mobile_phone_1' ||
      (e.target.name === 'f1_spouse_firstname_en' && this.state.radio_0)
        ? e.target.value.replace(/[\D]+/g, '')
        : e.target.name === 'f1_spouse_firstname' ||
          e.target.name === 'f1_spouse_lastname' ||
          e.target.name === 'f1_spouse_firstname_en' ||
          e.target.name === 'f1_spouse_lastname_en'
        ? e.target.value
        : validator.trim(e.target.value);
    await this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : value,
    });
    if (
      (this.state.marital_status === '2' || this.state.marital_status === 2) &&
      this.state.isSubmit
    ) {
      await this.validate();
    }

    // console.log(this.state[e.target.name]);
    // console.log(e.target.value);
  };

  handleIChange = async (v, ref) => {
    await this.setState({
      [ref.el.input.name]:
        ref.el.input.name === 'f1_spouse_firstname' ||
        ref.el.input.name === 'f1_spouse_firstname_en' ||
        ref.el.input.name === 'f1_spouse_lastname' ||
        ref.el.input.name === 'f1_spouse_lastname_en' ||
        ref.el.input.name === 'f1_title_Oher'
          ? v
          : validator.trim(v),
    });
    if (this.state.isSubmit) {
      await this.validate();
    }
  };

  handldCardIdChange = (e) => {
    const val = e.target.value.replace(/[\D]+/g, '');
    this.setState({ f1_spouse_id_card: val });
    const tt = checkIdcard(val);
    this.setState({
      ischeckIdcard: tt,
    });
  };

  handleTelCodeChange = (e) => {
    const val = e.target.value.replace(/[\D]+/g, '');
    this.setState({ f1_mobile_phone_1: val });
  };

  validate = () => {
    $('span[class^=span_f1_]').hide();
    let countError = 0;
    if (this.state.f1_marital_status == '1') {
      return true;
    }
    this.setState({ f1_spouse_id_card_exist: false });
    this.setState({ f1_spouse_tel_exist: false });
    this.setState({ f1_spouse_tel_isEmpty: false });

    if (!checkIdcard(this.state.f1_spouse_id_card) && this.state.radio_0) {
      $('.span_f1_spouse_id_card').css('display', 'flex');
      countError++;
    } else {
      if (
        !validator.isLength(validator.trim(this.state.f1_spouse_id_card), {
          min: 7,
        })
      ) {
        $('.span_f1_spouse_id_card').css('display', 'flex');
        countError++;
      }
      // if (!validator.isLength(validator.trim(this.state.f1_spouse_id_card), {min: 7}) && this.state.radio_1) {
    }

    if (this.state.radio_1) {
      if (this.state.f1_spouse_id_card === '') {
        $('.span_f1_spouse_id_card').css('display', 'flex');
        countError++;
      } else {
        const f0 = JSON.parse(localStorage.getItem('f0'));
        const children_json = localStorage.getItem('children_json')
          ? JSON.parse(localStorage.getItem('children_json'))
          : {};
        let children_exist = false;
        if (children_json.length > 0) {
          var i;
          for (i = 0; i < children_json.length; i++) {
            if (children_json[i].SAfc_CardID === this.state.f1_spouse_id_card) {
              children_exist = true;
              break;
            }
          }
        }
        if (
          f0.f0_national_id === this.state.f1_spouse_id_card ||
          children_exist
        ) {
          this.setState({ f1_spouse_id_card_exist: true });
          $('.span_f1_spouse_id_card').css('display', 'flex');
          countError++;
        } else {
          this.setState({ f1_spouse_id_card_exist: false });
        }
      }
    }
    if (checkIdcard(this.state.f1_spouse_id_card) && this.state.radio_0) {
      const f0 = JSON.parse(localStorage.getItem('f0'));
      const children_json = localStorage.getItem('children_json')
        ? JSON.parse(localStorage.getItem('children_json'))
        : {};
      let children_exist = false;
      if (children_json.length > 0) {
        var i;
        for (i = 0; i < children_json.length; i++) {
          if (children_json[i].SAfc_CardID === this.state.f1_spouse_id_card) {
            children_exist = true;
            break;
          }
        }
      }
      if (
        f0.f0_national_id === this.state.f1_spouse_id_card ||
        children_exist
      ) {
        this.setState({ f1_spouse_id_card_exist: true });
        $('.span_f1_spouse_id_card').css('display', 'flex');
        countError++;
      } else {
        this.setState({ f1_spouse_id_card_exist: false });
      }
    }

    if (validator.isEmpty(validator.trim(this.state.f1_spouse_title))) {
      $('.span_f1_title').css('display', 'flex');
      countError++;
    }

    if (this.state.radio_0) {
      if (validator.isEmpty(this.state.f1_spouse_firstname)) {
        $('.span_f1_spouse_firstname').css('display', 'flex');
        countError++;
      }

      if (validator.isEmpty(this.state.f1_spouse_lastname)) {
        $('.span_f1_spouse_lastname').css('display', 'flex');
        countError++;
      }
      if (validator.isEmpty(this.state.f1_spouse_firstname_en)) {
        $('.span_f1_spouse_firstname_en').css('display', 'flex');
        countError++;
      }

      if (validator.isEmpty(this.state.f1_spouse_lastname_en)) {
        $('.span_f1_spouse_lastname_en').css('display', 'flex');
        countError++;
      }
    }
    if (
      this.state.this_title
        ? this.state.this_title.Other === 1
          ? true
          : false
        : false
    ) {
      if (validator.isEmpty(this.state.f1_title_Oher)) {
        $('.span_f1_title_Oher').css('display', 'flex');
        countError++;
      }
    }
    if (this.state.radio_1) {
      if (validator.isEmpty(this.state.f1_spouse_firstname_en)) {
        $('.span_f1_spouse_firstname_en').css('display', 'flex');
        countError++;
      }

      if (validator.isEmpty(this.state.f1_spouse_lastname_en)) {
        $('.span_f1_spouse_lastname_en').css('display', 'flex');
        countError++;
      }
    }
    if (validator.isEmpty(this.state.f1_spouse_birth_date)) {
      // $('.span_f1_spouse_birth_date').css('display', 'flex');
      // countError++;
    }

    if (!validator.isMobilePhone(this.state.f1_mobile_phone_1, 'th-TH')) {
      // if (validator.isEmpty(this.state.f1_mobile_phone_1) || this.state.f1_mobile_phone_1.length < 10) {
      //   this.setState({ f1_spouse_tel_isEmpty: true });
      // } else {
      //   this.setState({ f1_spouse_tel_isEmpty: false });
      // }
      // $('.span_f1_mobile_phone_1').css('display', 'flex');
      // countError++;
    } else {
      // const f0 = JSON.parse(localStorage.getItem('f0'));
      // if (f0.f0_mobile === this.state.f1_mobile_phone_1) {
      //   this.setState({ f1_spouse_tel_exist: true });
      //   $('.span_f1_mobile_phone_1').css('display', 'flex');
      //   countError++;
      // } else {
      //   this.setState({ f1_spouse_tel_exist: false });
      // }
    }
    // if (!validator.isEmail(this.state.f0_email)) {
    //   EmailError = 'Invalid Email';
    // }

    return countError > 0 ? false : true;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isSubmit: true });
    if (!this.validate()) {
      var items = document.querySelectorAll('[class*=span_f1]');
      for (var i = 0; i < items.length; i++) {
        if (items[i].style.display === 'flex') {
          return items[i].parentNode.scrollIntoView({ behavior: 'smooth' });
        }
      }
      return;
    }

    let nextUrl = '';
    if (this.state.marital_status === '2') {
      var arr_family_data = [];

      if (this.state.this_title.Other === 1) {
        // console.log(this.state.f1_title_Oher);
      } else {
        // console.log(this.state.f1_spouse_title);
        await this.setState({ f1_title_Oher: '' });
      }
      arr_family_data = {
        f1_marital_status: this.state.marital_status,
        f1_spouse_id_card: this.state.f1_spouse_id_card,
        f1_spouse_firstname: this.state.radio_1
          ? ''
          : validator.trim(this.state.f1_spouse_firstname),
        f1_spouse_lastname: this.state.radio_1
          ? ''
          : validator.trim(this.state.f1_spouse_lastname),
        f1_spouse_firstname_en: validator.trim(
          this.state.f1_spouse_firstname_en
        ),
        f1_spouse_lastname_en: validator.trim(this.state.f1_spouse_lastname_en),
        f1_spouse_birth_date: this.state.f1_spouse_birth_date,
        f1_mobile_phone_1: this.state.f1_mobile_phone_1,
        f1_spouse_title: this.state.f1_spouse_title,
        f1_spouse_radio: this.state.radio_0,
        f1_spouse_is_th: this.state.radio_0,
        f1_spouse_title_text: validator.trim(this.state.f1_title_Oher),
      };

      await localStorage.setItem('f1', JSON.stringify(arr_family_data));
      nextUrl = '/statusChildren';
    } else {
      // localStorage.removeItem('f1');
      var arr_family_data = [];
      arr_family_data = {
        f1_marital_status: '1',
        f1_spouse_id_card: '',
        f1_spouse_firstname: '',
        f1_spouse_lastname: '',
        f1_spouse_firstname_en: '',
        f1_spouse_lastname_en: '',
        f1_spouse_birth_date: '',
        f1_mobile_phone_1: '',
        f1_spouse_title: '',
        f1_spouse_title_text: '',
        f1_spouse_radio: true,
        f1_spouse_is_th: true,
      };
      localStorage.setItem('f1', JSON.stringify(arr_family_data));
      nextUrl = '/statusChildren';
    }

    const str = window.location.href;
    const re = /edit/;
    const back_to_edit = re.test(str);
    // if (checkInternet()) {
    if (back_to_edit) {
      let error;
      let result;
      let mss = '';
      const MySwal = withReactContent(swal);
      const HeaderManp = JSON.parse(localStorage.getItem('HeaderManp'));
      const head_token = HeaderManp.head_token;
      const familyStatus = {
        head_token,
        f1_marital_status: this.state.marital_status,
        f1_spouse_title: this.state.f1_spouse_title,
        f1_spouse_is_th: this.state.radio_0,
        f1_spouse_firstname: this.state.radio_0
          ? validator.trim(this.state.f1_spouse_firstname)
          : '',
        f1_spouse_lastname: this.state.radio_0
          ? validator.trim(this.state.f1_spouse_lastname)
          : '',
        f1_spouse_title_text:
          this.state.marital_status === '2' &&
          validator.trim(this.state.f1_title_Oher)
            ? validator.trim(this.state.f1_title_Oher)
            : '',
        f1_spouse_firstname_en: validator.trim(
          this.state.f1_spouse_firstname_en
        ),
        f1_spouse_lastname_en: validator.trim(this.state.f1_spouse_lastname_en),
        f1_spouse_id_card: this.state.f1_spouse_id_card,
      };
      await this.setState({ loading: true });
      [error, result] = await to(
        ktbsApi.post('/form/family_status/manp', familyStatus)
      );
      if (
        error ||
        !result ||
        !result.Data ||
        !result.Data[0] ||
        !result.Data[0][0] ||
        result.Data[0][0].IsSuccess !== 1
      ) {
        if (result) {
          mss =
            result.Data[0][0].MsgDesc !== ''
              ? result.Data[0][0].MsgDesc
              : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
        } else if (error) {
          if (!!error.isAxiosError && !error.response) {
            mss = 'network error';
          } else {
            mss =
              error.response.data.Data[0][0].MsgDesc !== ''
                ? error.response.data.Data[0][0].MsgDesc
                : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
          }
        } else {
          mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
        }
        MySwal.fire({
          imageUrl: '/images/ico_alert.svg',
          imageWidth: 100,
          imageHeight: 100,
          customClass: {
            confirmButton: 'long_btn_fix',
          },
          confirmButtonColor: '#003b66',
          confirmButtonText: 'กลับ',
          title: '<div class="title_popup">เกิดข้อผิดพลาด</div>',
          html: '<div class="content_popup">' + mss + '</div>',
        });
        await this.setState({ loading: false });
        return;
      } else {
        // console.log(result.Data[0][0].MsgDesc !== "");
      }
      await this.setState({ loading: false });
      this.props.history.push('/summaryshow');
      localStorage.setItem('editPage', 'FamilyStatus');
    } else {
      const BackToSummaryRisk = JSON.parse(
        localStorage.getItem('BackToSummaryRisk')
      );
      if (BackToSummaryRisk) {
        localStorage.removeItem('BackToSummaryRisk');
        window.location = 'summary_risk';
        return;
      }

      this.props.history.push(nextUrl);
    }
    // } else {
    //   this.setState({
    //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
    //   })
    // }
  };

  converToTextDate = (date) => {
    let output = ' ';
    if (date) {
      let dateObj = date;
      const monthNames = [
        'ม.ค.',
        'ก.พ.',
        'มี.ค.',
        'เม.ย.',
        'พ.ค.',
        'มิ.ย.',
        'ก.ค.',
        'ส.ค.',
        'ก.ย.',
        'ต.ค.',
        'พ.ย.',
        'ธ.ค.',
      ];
      let month = monthNames[parseFloat(dateObj.substr(2, 2) - 1)];
      let day = dateObj.substr(0, 2);
      let year = dateObj.substr(4);
      output = day + ' ' + month + ' ' + year;
    }
    return output;
  };

  convertToISODates(date) {
    let dateObj = date;
    let month = parseFloat(dateObj.substr(2, 2));
    let day = dateObj.substr(0, 2);
    let year = dateObj.substr(4);
    var date = new Date(year + '-' + month + '-' + day);
    // console.log(day);
    // console.log(year + "-" + month + "-" + day);
    // console.log(date);
    return date;
  }

  handleClick = () => {
    var date =
      this.state.f1_spouse_birth_date !== ''
        ? this.convertToISODates(this.state.f1_spouse_birth_date)
        : new Date();
    // date = f1_spouse_birth_date this.convertToISODates(SAfc_Birthdate);
    this.setState({
      time: date,
      isOpen: true,
    });
  };

  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  radio_toggle = async (e) => {
    $('.span_f1_spouse_id_card').hide();
    var a = document.getElementsByName('group_0');
    await a.forEach((item, i) => {
      if (item.checked) {
        this.setState({ [item.id]: true });
        if (item.id === 'radio_0') {
          var str = this.state.f1_spouse_id_card;
          var res = str.substring(0, 13);
          this.setState({ f1_spouse_id_card: res });
        }
      } else {
        this.setState({ [item.id]: false });
      }
    });
    if (this.state.isSubmit) {
      await this.validate();
    }
  };

  handleSelect = async (time) => {
    let newTime = moment(time).format('DDMMYYYY');
    await this.setState({ time, isOpen: false, f1_spouse_birth_date: newTime });
    if (this.state.isSubmit) {
      await this.validate();
    }
  };
  monthMap = {
    1: 'ม.ค.',
    2: 'ก.พ.',
    3: 'มี.ค.',
    4: 'เม.ย.',
    5: 'พ.ค.',
    6: 'มิ.ย.',
    7: 'ก.ค.',
    8: 'ส.ค.',
    9: 'ก.ย.',
    10: 'ต.ค.',
    11: 'พ.ย.',
    12: 'ธ.ค.',
  };

  dateConfig = {
    date: {
      format: 'DD',
      caption: 'Day',
      step: 1,
    },
    month: {
      format: (value) => this.monthMap[value.getMonth() + 1],
      // format: 'MM',
      caption: 'Mon',
      step: 1,
    },
    year: {
      format: 'YYYY',
      caption: 'Year',
      step: 1,
    },
  };

  onSelectedSpouseBirthdateHandle = (value) => {
    this.setState({ f1_spouse_birth_date: value });
  };

  handleother = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
  };

  back_idcard = (v) => {
    this.setState({ f1_spouse_id_card: v });
    if (this.state.isSubmit) {
      this.validate();
    }
  };

  render() {
    library.add(fas);
    const title = this.state.title;
    const btnStatusFamily = this.state.marital_status_arr.map((item, index) => {
      return (
        <div data-id={item.Id} onClick={this.changeMaritalStatus} key={index}>
          <ButtonCustom
            label={item.ThName}
            color={
              this.state.marital_status === item.Id
                ? this.state.color_single_btn
                : this.state.color_marry_btn
            }
            btnClass="long_btn_status_user"
          />
        </div>
      );
    });

    return (
      <div className={isMobile ? '' : 'body'}>
        <header
          className={isMobile ? '' : 'header-area'}
          style={{ display: isMobile ? 'none' : 'block' }}
        >
          <div className={'container everest-nav-container'}>
            <div className={'row'}>
              <div className={'col-md-6'}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={'col-md-6 flex_end'}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? '' : 'desktop_content section-gap-full'}
          style={{
            backgroundImage: isMobile ? 'none' : 'url(/images/bg.jpg)',
          }}
        >
          <div className={isMobile ? '' : 'container'}>
            <div className={isMobile ? '' : 'row'}>
              <div
                className={'col-md-6'}
                style={{ display: isMobile ? 'none' : 'block' }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? '' : 'col-md-6'}>
                {/* content mobile */}
                <div className="Page">
                  <Loading status={this.state.loading} />
                  <div className="top_main_back">
                    <div className="main_back" onClick={this.goBack}>
                      <img
                        src={
                          isMobile
                            ? '/images/angle-arrow-left.svg'
                            : '/images/angle-arrow-left-blue.svg'
                        }
                        style={{
                          marginLeft: '3px',
                          display: checkEditPage() ? 'none' : 'block',
                        }}
                        className="arrow_back"
                      />
                    </div>
                    <div className="text_title">สถานภาพทางครอบครัว</div>
                  </div>

                  <div className="bg_content_white ">
                    <div className="title_in_content">สถานภาพทางครอบครัว</div>
                    <div
                      className="in_bg_white"
                      style={{
                        left: 0,
                        right: 0,
                        bottom: this.state.marital_status === '2' ? 'auto' : 0,
                        padding: '0 24px',
                      }}
                    >
                      <div style={{ border: 'none', paddingTop: 20 }}>
                        {btnStatusFamily}
                      </div>

                      <div
                        id="maritalDetail"
                        style={{
                          display:
                            this.state.marital_status === '2'
                              ? 'block'
                              : 'none',
                          paddingTop: 6,
                        }}
                      >
                        <div
                          style={{
                            color: '#000',
                            fontSize: 24,
                            marginBottom: 5,
                            fontWeight: 'bold',
                            color: '#003b66',
                          }}
                        >
                          ข้อมูลคู่สมรส
                        </div>
                        <div className="flex_radio" style={{ marginTop: 20 }}>
                          <input
                            type="radio"
                            required
                            className="radio_question"
                            name="group_0"
                            id="radio_0"
                            onChange={this.radio_toggle}
                            value={this.state.radio_0}
                            defaultChecked={this.state.radio_0}
                          />
                          <label
                            htmlFor={'radio_0'}
                            className="text_checkmark"
                            style={{ whiteSpace: 'pre-wrap' }}
                          >
                            สัญชาติไทย
                          </label>
                        </div>
                        <div className="flex_radio" style={{ marginBottom: 0 }}>
                          <input
                            type="radio"
                            required
                            className="radio_question"
                            name="group_0"
                            id="radio_1"
                            onChange={this.radio_toggle}
                            value={this.state.radio_1}
                            defaultChecked={!this.state.radio_0}
                          />
                          <label
                            htmlFor={'radio_1'}
                            className="text_checkmark"
                            style={{ whiteSpace: 'pre-wrap' }}
                          >
                            ต่างชาติ
                          </label>
                        </div>
                        <div className="row_idcard">
                          <div>
                            <span
                              style={{
                                display: this.state.radio_0 ? 'block' : 'none',
                              }}
                            >
                              เลขที่บัตรประจำตัวประชาชน *
                            </span>{' '}
                            <span
                              style={{
                                display: this.state.radio_0 ? 'none' : 'block',
                              }}
                            >
                              หนังสือเดินทาง(กรณีต่างชาติ) *
                            </span>
                          </div>
                          <IMaskInput
                            mask={
                              this.state.radio_0
                                ? '0000000000000'
                                : /^[a-zA-Z0-9]+$/
                            }
                            value={this.state.f1_spouse_id_card}
                            type={'text'}
                            unmask={true}
                            name="f1_spouse_id_card"
                            className="input_idcard"
                            style={{
                              textDecorationLine: 'none',
                              borderColor:
                                this.state.f1_spouse_id_card !== ''
                                  ? '#003b66'
                                  : '#ccc',
                            }}
                            onAccept={(value) => this.back_idcard(value)}
                          />
                          <span className="span_f1_spouse_id_card">
                            {/* {(this.state.radio_0 && !this.state.f1_spouse_id_card_exist) ? 'รูปแบบเลขที่บัตรประจำตัวประชาชนไม่ถูกต้อง/ไม่ครบถ้วน' : ''} */}
                            {this.state.radio_0 &&
                            !this.state.f1_spouse_id_card_exist
                              ? 'กรุณาระบุเลขที่บัตรประจำตัวประชาชน'
                              : ''}
                            {this.state.radio_1 &&
                            !this.state.f1_spouse_id_card_exist
                              ? 'กรุณาระบุหนังสือเดินทาง(กรณีต่างชาติ)'
                              : ''}
                            {this.state.f1_spouse_id_card_exist
                              ? 'กรุณาระบุใหม่'
                              : ''}
                            {/* <FontAwesomeIcon icon='info-circle' /> */}
                          </span>
                        </div>

                        <div className="row_idcard">
                          <div>คำนำหน้าชื่อ *</div>
                          <div className="main_select">
                            <select
                              className="select_style"
                              name="f1_spouse_title"
                              value={this.state.f1_spouse_title}
                              onChange={this.handleChange}
                              style={{
                                color:
                                  this.state.f1_spouse_title == ''
                                    ? '#003b6694'
                                    : '#003b66',
                                borderColor:
                                  this.state.f1_spouse_title !== ''
                                    ? '#003b66'
                                    : '#ccc',
                              }}
                            >
                              <option
                                value=""
                                style={{ color: '#003b6694' }}
                                disabled
                                hidden
                              >
                                กรุณาเลือก{' '}
                              </option>
                              {title}
                            </select>
                            <FontAwesomeIcon icon="angle-down" />
                          </div>
                          <span className="span_f1_title">
                            กรุณาระบุคำนำหน้าชื่อ
                            {/* <FontAwesomeIcon icon='info-circle' /> */}
                          </span>
                        </div>
                        <div
                          className="row_idcard"
                          style={{
                            display: this.state.this_title
                              ? this.state.this_title.Other === 1 &&
                                this.state.f1_spouse_title !== ''
                                ? 'block'
                                : 'none'
                              : 'none',
                          }}
                        >
                          <div>คำนำหน้า อื่นๆ *</div>
                          <IMaskInput
                            mask={
                              /^[A-Za-zๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝูฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ\s\.\-]+$/
                            }
                            value={this.state.f1_title_Oher}
                            unmask={true}
                            className="input_idcard"
                            name="f1_title_Oher"
                            style={{
                              textDecorationLine: 'none',
                              borderColor:
                                this.state.f1_title_Oher !== ''
                                  ? '#003b66'
                                  : '#ccc',
                            }}
                            onAccept={this.handleIChange}
                          />
                          <span className="span_f1_title_Oher">
                            กรุณาระบุคำนำหน้าอื่นๆ
                          </span>
                        </div>
                        <div
                          className="row_idcard"
                          style={{
                            display: this.state.radio_1 ? 'none' : 'block',
                          }}
                        >
                          <div>ชื่อ (ภาษาไทย)* </div>
                          <IMaskInput
                            // mask={/^[ก-๙\s\.\-]+$/}
                            mask={
                              /^[ๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝูฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ\s\.\-]+$/
                            }
                            value={this.state.f1_spouse_firstname}
                            unmask={true}
                            className="input_idcard"
                            name="f1_spouse_firstname"
                            style={{
                              textDecorationLine: 'none',
                              borderColor:
                                this.state.f1_spouse_firstname !== ''
                                  ? '#003b66'
                                  : '#ccc',
                            }}
                            onAccept={this.handleIChange}
                          />
                          <span className="span_f1_spouse_firstname">
                            กรุณาระบุชื่อ (ภาษาไทย)
                            {/* <FontAwesomeIcon icon='info-circle' /> */}
                          </span>
                        </div>

                        <div
                          className="row_idcard"
                          style={{
                            display: this.state.radio_1 ? 'none' : 'block',
                          }}
                        >
                          <div>นามสกุล (ภาษาไทย) * </div>
                          <IMaskInput
                            mask={
                              /^[ๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝูฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ\s\.\-]+$/
                            }
                            value={this.state.f1_spouse_lastname}
                            unmask={true}
                            className="input_idcard"
                            name="f1_spouse_lastname"
                            style={{
                              textDecorationLine: 'none',
                              borderColor:
                                this.state.f1_spouse_lastname !== ''
                                  ? '#003b66'
                                  : '#ccc',
                            }}
                            onAccept={this.handleIChange}
                          />
                          <span className="span_f1_spouse_lastname">
                            กรุณาระบุนามสกุล (ภาษาไทย)
                            {/* <FontAwesomeIcon icon='info-circle' /> */}
                          </span>
                        </div>

                        <div className="row_idcard">
                          <div>
                            ชื่อ (ภาษาอังกฤษ) <span>*</span>
                          </div>
                          <IMaskInput
                            mask={/^[A-Za-z\s\.\-]+$/}
                            value={this.state.f1_spouse_firstname_en}
                            unmask={true}
                            className="input_idcard"
                            name="f1_spouse_firstname_en"
                            style={{
                              textDecorationLine: 'none',
                              borderColor:
                                this.state.f1_spouse_firstname_en !== ''
                                  ? '#003b66'
                                  : '#ccc',
                            }}
                            onAccept={this.handleIChange}
                          />
                          <span className="span_f1_spouse_firstname_en">
                            กรุณาระบุชื่อ (ภาษาอังกฤษ)
                            {/* <FontAwesomeIcon icon='info-circle' /> */}
                          </span>
                        </div>

                        <div className="row_idcard">
                          <div>
                            นามสกุล (ภาษาอังกฤษ) <span>*</span>
                          </div>
                          <IMaskInput
                            mask={/^[A-Za-z\s\.\-]+$/}
                            value={this.state.f1_spouse_lastname_en}
                            unmask={true}
                            className="input_idcard"
                            name="f1_spouse_lastname_en"
                            style={{
                              textDecorationLine: 'none',
                              borderColor:
                                this.state.f1_spouse_lastname_en !== ''
                                  ? '#003b66'
                                  : '#ccc',
                            }}
                            onAccept={this.handleIChange}
                          />
                          <span className="span_f1_spouse_lastname_en">
                            กรุณาระบุนามสกุล (ภาษาอังกฤษ)
                            {/* <FontAwesomeIcon icon='info-circle' /> */}
                          </span>
                        </div>

                        <div
                          className="row_idcard"
                          style={{
                            position: 'relative',
                            display: 'none',
                          }}
                        >
                          <div>
                            วันเดือนปี (ค.ศ.) เกิด <span>*</span>
                          </div>
                          {/* <input
                  name="f1_spouse_birth_date1"
                  value={this.converToTextDate(this.state.f1_spouse_birth_date)}
                  className="input_idcard"
                  onClick={this.handleClick}
                  readOnly
                  style={{borderColor: this.state.f1_spouse_birth_date !== '' ? '#003b66' : '#ccc'}}
                />

                <input
                  hidden
                  name="f1_spouse_birth_date"
                  value={this.state.f1_spouse_birth_date}
                  className="input_idcard"
                  onClick={this.handleClick}
                  readOnly
                  style={{borderColor: this.state.f1_spouse_birth_date !== '' ? '#003b66' : '#ccc'}}
                />
                <DatePicker
                  dateConfig={this.dateConfig}
                  headerFormat="DD-MM-YYYY"
                  value={this.state.time}
                  isOpen={this.state.isOpen}
                  showHeader={false}
                  confirmText={'ตกลง'}
                  cancelText={'ยกเลิก'}
                  //theme='ios'
                  onSelect={this.handleSelect}
                  onCancel={this.handleCancel}
                  min={this.state.max_year_old}
                  max={this.state.to_day}
                /> */}
                          <div style={{ position: 'relative' }}>
                            <DatePickerInput
                              value={this.state.f1_spouse_birth_date}
                              maxDate={new Date()}
                              onSelected={this.onSelectedSpouseBirthdateHandle}
                            />

                            <FontAwesomeIcon icon="calendar"></FontAwesomeIcon>
                          </div>
                          <span className="span_f1_spouse_birth_date">
                            กรุณาระบุวันเดือนปี (ค.ศ.) เกิด
                          </span>
                        </div>

                        <div
                          className="row_idcard"
                          style={{
                            marginBottom: '20px',
                            display: 'none',
                          }}
                        >
                          <div>
                            เบอร์โทรศัพท์มือถือ <span>*</span>
                          </div>
                          <input
                            className="input_idcard"
                            name="f1_mobile_phone_1"
                            value={this.state.f1_mobile_phone_1}
                            onChange={this.handleChange}
                            maxLength="10"
                            type="tel"
                            style={{
                              borderColor:
                                this.state.f1_mobile_phone_1 !== ''
                                  ? '#003b66'
                                  : '#ccc',
                            }}
                          ></input>
                          <span className="span_f1_mobile_phone_1">
                            {this.state.f1_spouse_tel_exist
                              ? 'กรุณาระบุใหม่'
                              : this.state.f1_spouse_tel_isEmpty
                              ? 'กรุณาระบุเบอร์โทรศัพท์มือถือ'
                              : 'รูปแบบเบอร์มือถือไม่ถูกต้อง'}
                            {/* <FontAwesomeIcon icon='info-circle' /> */}
                          </span>
                        </div>
                      </div>
                      <div
                        className={
                          this.state.marital_status === '2'
                            ? 'under_fix_btn active'
                            : 'under_fix_btn '
                        }
                      >
                        <ButtonCustom
                          label={checkEditPage() ? 'บันทึกการแก้ไข' : 'ถัดไป'}
                          color="blue"
                          btnClass={
                            this.state.marital_status === '2'
                              ? 'long_btn'
                              : 'long_btn_fix'
                          }
                          handle={this.handleSubmit}
                        ></ButtonCustom>
                      </div>
                    </div>

                    <div style={{ clear: 'both' }}></div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default FamilyStatus;
