import React, { useRef } from 'react';
import Webcam from 'react-webcam';
import './SnapFrontId.css';
import { createCanvas, loadImage } from 'canvas';

const videoConstraints = {
  // width: 400,
  // height: 700,
  // facingMode: { exact: 'environment' },
  facingMode: 'environment',
};

// function goBack() {
//   var link = window.location.href.indexOf('edit');
//   if (link === -1) {
//     window.location = '/snapidcard';
//   } else {
//     window.location = '/snapidcard/edit';
//   }
// }

const SnapFrontId2 = () => {
  const webcamRef = useRef(null);
  var link = window.location.href.indexOf('edit');
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const canvas = createCanvas(800, 509);
    const ctx = canvas.getContext('2d');

    loadImage(imageSrc).then(async (image) => {
      let maxWidth = 800;
      let maxHeight = 800;
      let width = image.width;
      let height = image.height;
      let ratio = maxWidth / width;
      let imgWidth = Math.floor(width * ratio);
      let imgHeight = Math.floor(height * ratio);
      let top = Math.floor(height / 2);
      await ctx.drawImage(image, 0, -top, imgWidth, imgHeight);
      await localStorage.setItem('photo1', canvas.toDataURL());

      // alert(imgWidth + 'x' + imgHeight);
      // console.log(imgWidth);
      // console.log(imgHeight);

      if (link === -1) {
        window.location = '/snapidcard';
      } else {
        window.location = '/snapidcard/edit';
      }
    });
  }, [webcamRef]);

  return (
    <div>
      <Webcam
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '#FFF',
        }}
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/png"
        videoConstraints={videoConstraints}
      />
    </div>
  );
};

export default SnapFrontId2;
