import React, { Component } from "react";
import "./Login.css";
import ButtonCustom from "../../components/button/ButtonCustom";
import axios from "axios";
import swal from "sweetalert2";
import {
  download_ico_alert,
  popupNetworkLost,
  checkInternet,
} from "../../components/helpers/Helpers";
import withReactContent from "sweetalert2-react-content";
class Login extends Component {
  //
  constructor(props) {
    super(props);

    this.state = {
      productNames: [],
      productNames1: [],
      content_li: [],
    };
  }

  async componentDidMount() {
    localStorage.clear();
    // await download_ico_alert();
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const passDopa = JSON.parse(localStorage.getItem("passDopa"));
    const fatca = JSON.parse(localStorage.getItem("fatca"));
    const MySwal = withReactContent(swal);
    var that = this;
    if (checkInternet()) {
      that.props.history.push("/splashscreenoa");
    } else {
      popupNetworkLost(true);
    }
  };
  render() {
    return (
      <div
        className="page_login"
        style={{ backgroundImage: "url(../../../images/bg-login.png)" }}
      >
        <img src="../../../images/logo.png" title="name" className="logo"></img>
        <div
          style={{
            width: "100%",
            position: "absolute",
            bottom: 30,
            height: 280,
          }}
        >
          <ButtonCustom
            handle={this.handleSubmit}
            label="สมัครเปิดบัญชีใหม่"
            color="blue"
            btnClass="bt_open_acc"
          ></ButtonCustom>

          <ButtonCustom
            label="เข้าสู่ระบบ"
            color="white"
            btnClass="bt_login"
          ></ButtonCustom>

          <ButtonCustom
            label="ลืมรหัส"
            color="blueSky"
            btnClass="bt_forget"
            iconDirection="L"
            icon="question-circle"
          ></ButtonCustom>
        </div>
      </div>
    );
  }
}

export default Login;
