import React, { Component } from "react";
import ButtonCustom from "../../components/button/ButtonCustom";
import "./EmergencyContact.css";
import validator from "validator";
import $ from "jquery";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
class EmergencyContact extends Component {
  constructor(props) {
    super(props);
    const f1_contact = JSON.parse(localStorage.getItem("f1_contact"));
    const f1 = JSON.parse(localStorage.getItem("f1"));
    if (f1_contact === null) {
      // console.log(f1 !== null);
      if (f1 !== null) {
        if (f1.f1_marital_status === "2") {
          // console.log(f1.f1_spouse_firstname === "");
          this.state = {
            f1_contact_firstname: f1
              ? f1.f1_spouse_firstname === ""
                ? f1.f1_spouse_firstname_en
                : f1.f1_spouse_firstname
              : "sss",
            f1_contact_lastname: f1
              ? f1.f1_spouse_lastname === ""
                ? f1.f1_spouse_lastname_en
                : f1.f1_spouse_lastname
              : "sss",
            f1_contact_mobile: f1 ? f1.f1_mobile_phone_1 : "",
            f1_contact_relate: "คู่สมรส",
          };
        } else {
          this.state = {
            f1_contact_firstname: "",
            f1_contact_lastname: "",
            f1_contact_mobile: "",
            f1_contact_relate: "",
          };
        }
      }
    } else {
      this.state = {
        f1_contact_firstname:
          f1_contact && f1_contact.f1_contact_firstname
            ? f1_contact.f1_contact_firstname
            : "",
        f1_contact_lastname:
          f1_contact && f1_contact.f1_contact_lastname
            ? f1_contact.f1_contact_lastname
            : "",
        f1_contact_mobile:
          f1_contact && f1_contact.f1_contact_mobile
            ? f1_contact.f1_contact_mobile
            : "",
        f1_contact_relate:
          f1_contact && f1_contact.f1_contact_relate
            ? f1_contact.f1_contact_relate
            : "",
      };
    }
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  async componentDidMount() {
    const f1_contact = JSON.parse(localStorage.getItem("f1_contact"));
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await this.validate();
    if (isValid) {
      var f1_contact = [];
      f1_contact = {
        f1_contact_firstname: this.state.f1_contact_firstname,
        f1_contact_lastname: this.state.f1_contact_lastname,
        f1_contact_mobile: this.state.f1_contact_mobile,
        f1_contact_relate: this.state.f1_contact_relate,
      };
      localStorage.setItem("f1_contact", JSON.stringify(f1_contact));
      var str = window.location.href;
      let re = /edit/;
      const back_to_edit = re.test(str);
      if (back_to_edit) {
        this.props.history.push("/summaryshow");
      } else {
        this.props.history.push("/PoliticianRelation");
      }
    }
  };

  textButtonSave() {
    var str = window.location.href;
    let re = /edit/;
    const back_to_edit = re.test(str);
    const textButtonSave = back_to_edit ? "บันทึกการแก้ไข" : "ถัดไป";
    return textButtonSave;
  }

  handleChange = async (e) => {
    e.preventDefault();
    const isCheckbox = e.target.type === "checkbox";
    const value =
      e.target.name === "f1_contact_mobile"
        ? e.target.value.replace(/[\D]+/g, "")
        : validator.trim(e.target.value);

    await this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : value,
    });
    await this.validate();
  };
  validate = () => {
    $("span[class^=span_f1]").hide();
    let countError = 0;

    if (validator.isEmpty(this.state.f1_contact_firstname)) {
      $(".span_f1_contact_firstname").show();
      countError++;
    }
    if (validator.isEmpty(this.state.f1_contact_lastname)) {
      $(".span_f1_contact_lastname").show();
      countError++;
    }

    if (!validator.isMobilePhone(this.state.f1_contact_mobile, "th-TH")) {
      $(".span_f1_contact_mobile").show();
      countError++;
    }

    if (validator.isEmpty(this.state.f1_contact_relate)) {
      $(".span_f1_contact_relate").show();
      countError++;
    }

    return countError > 0 ? false : true;
  };
  render() {
    return (
      <div
        className={isMobile ? "" : "body"}
        style={{
          backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
        }}
      >
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section className={isMobile ? "" : "desktop_content section-gap-full"}>
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div className="content">
                  <div className="top_main_back">
                    <div className="main_back" onClick={this.goBack}>
                      <img
                        src={
                          isMobile
                            ? "/images/angle-arrow-left.svg"
                            : "/images/angle-arrow-left-blue.svg"
                        }
                        alt="forward"
                        style={{ marginLeft: "3px" }}
                        className="arrow_back"
                      />
                    </div>
                    <div className="text_title">
                      บุคคลที่สามารถติดต่อได้กรณีฉุกเฉิน
                    </div>
                  </div>

                  <div
                    className="bg_content_white over_f_hide"
                    style={{ padding: "0 24px" }}
                  >
                    <div className="title_row">รายละเอียดผู้ติดต่อ</div>
                    <div
                      className="in_bg_white autoscroll"
                      style={{ left: 0, right: 0, padding: "0 21px" }}
                    >
                      <div
                        className="row_idcard"
                        style={{
                          borderColor:
                            this.state.f1_contact_firstname !== ""
                              ? "#003b66"
                              : "#ccc",
                        }}
                      >
                        <div style={{ fontSize: 18 }}>
                          ชื่อ (ภาษาไทย) <span>*</span>
                        </div>
                        <input
                          className="input_idcard"
                          onChange={this.handleChange}
                          name="f1_contact_firstname"
                          value={this.state.f1_contact_firstname}
                        ></input>
                        <span className="span_f1_contact_firstname">
                          กรุณาระบุชื่อ (ภาษาไทย)
                          {/* <FontAwesomeIcon icon='info-circle' /> */}
                        </span>
                      </div>
                      <div
                        className="row_idcard"
                        style={{
                          borderColor:
                            this.state.f1_contact_lastname !== ""
                              ? "#003b66"
                              : "#ccc",
                        }}
                      >
                        <div style={{ fontSize: 18 }}>
                          นามสกุล (ภาษาไทย) <span>*</span>
                        </div>
                        <input
                          className="input_idcard"
                          onChange={this.handleChange}
                          name="f1_contact_lastname"
                          value={this.state.f1_contact_lastname}
                        ></input>
                        <span className="span_f1_contact_lastname">
                          กรุณาระบุนามสกุล (ภาษาไทย)
                          {/* <FontAwesomeIcon icon='info-circle' /> */}
                        </span>
                      </div>

                      <div
                        className="row_idcard"
                        style={{
                          borderColor:
                            this.state.f1_contact_mobile !== ""
                              ? "#003b66"
                              : "#ccc",
                        }}
                      >
                        <div style={{ fontSize: 18 }}>
                          เบอร์โทรศัพท์มือถือ <span>*</span>
                        </div>
                        <input
                          className="input_idcard"
                          onChange={this.handleChange}
                          name="f1_contact_mobile"
                          value={this.state.f1_contact_mobile}
                          maxLength="10"
                        ></input>
                        <span className="span_f1_contact_mobile">
                          กรุณาระบุเบอร์โทรศัพท์มือถือ
                          {/* <FontAwesomeIcon icon='info-circle' /> */}
                        </span>
                      </div>

                      <div
                        className="row_idcard"
                        style={{
                          borderColor:
                            this.state.f1_contact_relate !== ""
                              ? "#003b66"
                              : "#ccc",
                        }}
                      >
                        <div style={{ fontSize: 18 }}>
                          ความสัมพันธ์ <span>*</span>
                        </div>
                        <input
                          className="input_idcard"
                          onChange={this.handleChange}
                          name="f1_contact_relate"
                          value={this.state.f1_contact_relate}
                        ></input>
                        <span className="span_f1_contact_relate">
                          กรุณาระบุความสัมพันธ์
                          {/* <FontAwesomeIcon icon='info-circle' /> */}
                        </span>
                      </div>
                    </div>

                    <div className="under_fix_btn">
                      <ButtonCustom
                        handle={this.handleSubmit}
                        label={this.textButtonSave()}
                        color="blue"
                        btnClass="long_btn"
                      ></ButtonCustom>
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default EmergencyContact;
