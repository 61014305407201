import React, { Component } from "react";
import "./KtbstRiskQuestion.css";
import { fieldset, Radio } from "react-custom-radio";
import ButtonCustom from "../../components/button/ButtonCustom";
import axios from "axios";
import { set } from "date-fns";
import Loading from "../../components/loading/loading";
import {
  checkEditPage,
  checkInternet,
  popupNetworkLost,
  download_ico_alert,
  popupError,
  popupErrorApi,
} from "../../components/helpers/Helpers";
import { config } from "./../../constants";
import { isMobile } from "react-device-detect";
import BannerContentLeft from "../../components/BannerContentLeft/BannerContentLeft";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
const _url = config.baseAPIURL03;
class KtbstRiskQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      listChooce: [],
      status_btn: true,
      // chkboxs: [
      //     {id: 1, text: "เงินฝากธนาคาร",value:'1', isChecked: false},
      //     {id: 2, text: "พันธบัตรรัฐบาล หรือกองทุนรวม พันธบัตรรัฐบาล",value:'2', isChecked: false},
      //     {id: 3, text: "หุ้นกู้ หรือกองทุนรวมตราสารหนี้",value:'3', isChecked: false},
      //     {id: 4, text: "หุ้นสามัญ หรือ กองทุนรวมหุ้น หรือ สินทรัพย์อื่นที่มีความเสี่ยงสูง",value:'4', isChecked: false}
      //   ],
    };

    this.goBack = this.goBack.bind(this);
  }
  boldText = (e) => {
    if (e.target.checked) {
      e.target.parentNode.style.fontWeight = "500";
    } else {
      e.target.parentNode.style.fontWeight = "300";
    }
    this.handleCheckbox();
  };
  async componentDidMount() {
    this.setState({ loading: true });
    // await download_ico_alert();
    var dataAnwser = await JSON.parse(localStorage.getItem("question"));
    const HeaderManp = await JSON.parse(localStorage.getItem("HeaderManp"));
    var head_token = HeaderManp.head_token;
    // var head_token = "IQcw6lmY5M+q9dnt9GzOjMBv9ejg5rP0xD3dm911Y/U=";
    await axios
      .get(_url + `/form/suitability/selbyhead?head_token=${head_token}`)
      .then((res) => {
        this.setState({ loading: false });
        this.setState({ listChooce: res.data.Data[1] });
        let chooceByDefault = res.data.Data[1];
        for (var i = 0; i < chooceByDefault.length; i++) {
          var d = dataAnwser.find((a) => {
            return a.SAmsa_Id === chooceByDefault[i].SAmsa_Id;
          });
          if (d) {
            chooceByDefault[i].SAfsac_Answer = d.SAfsac_Answer;
          }
          //console.log(chooceByDefault[i]);
        }

        var oldValue = JSON.parse(localStorage.getItem("RiskQuestion"));
        // console.log(oldValue)
        if (oldValue != undefined) {
          for (var i = 0; i < chooceByDefault.length; i++) {
            var d = oldValue.suit_json.find((a) => {
              return a.SAfsac_SAmsa_Id == chooceByDefault[i].SAmsa_Id + "";
            });
            if (d) {
              chooceByDefault[i].SAfsac_Answer = d.SAfsac_Answer;
            }

            //console.log(chooceByDefault[i]);
          }
        }
        var btn_submit = true;
        // console.log(this.state.listChooce);
        for (let i_ = 0; i_ < this.state.listChooce.length; i_++) {
          if (
            i_ < 10 &&
            this.state.listChooce[i_].SAmsa_IsMultiChoice === 1 &&
            this.state.listChooce[i_].SAfsac_Answer === ""
          ) {
            btn_submit = false;
            break;
          }
        }
        this.setState({ status_btn: btn_submit });
        const chooce = res.data.Data[1].map((item, i) => {
          if (i >= 0 && i <= 9) {
            let arr = JSON.parse(item.SAmsa_Answer);
            return (
              <div className="content_risk_question" key={i + "topic"}>
                <input
                  type="hidden"
                  name="SAfsac_SAmsa_Id[]"
                  value={item.SAmsa_Id}
                />
                <div
                  className="title_quest"
                  id={"q_" + (i + 1)}
                  dangerouslySetInnerHTML={{ __html: item.SAmsa_Question }}
                />
                <div className={"q_" + (i + 1)}>
                  {arr.map((itemSub, iSub) => {
                    return item.SAmsa_IsMultiChoice == 0 ? (
                      <div className="flex_radio" key={i + "_" + iSub}>
                        <input
                          type="radio"
                          required
                          value={itemSub.ChoiceID}
                          defaultChecked={
                            item.SAfsac_Answer == null
                              ? ""
                              : item.SAfsac_Answer == itemSub.ChoiceID
                          }
                          className="radio_question"
                          id={"radio_" + item.SAmsa_Id + iSub}
                          data-question={item.SAmsa_Id}
                          name={`SAfsac_Answer_radio_${item.SAmsa_Id}`}
                        />
                        <label
                          htmlFor={"radio_" + item.SAmsa_Id + iSub}
                          className="text_checkmark"
                        >
                          {itemSub.Text}
                        </label>
                      </div>
                    ) : item.SAmsa_IsMultiChoice == 1 ? (
                      <div key={i + "_" + iSub}>
                        <label className="container_none_border">
                          <input
                            type="checkbox"
                            id={"checkbox_" + item.SAmsa_Id + iSub}
                            required
                            value={itemSub.ChoiceID}
                            onChange={this.boldText}
                            defaultChecked={
                              item.SAfsac_Answer == null
                                ? ""
                                : item.SAfsac_Answer.split("_").includes(
                                    itemSub.ChoiceID + ""
                                  )
                            }
                            data-question={item.SAmsa_Id}
                            name={`SAfsac_Answer_checkbox_${item.SAmsa_Id}`}
                          />
                          <span className="text_checkmark">{itemSub.Text}</span>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    ) : (
                      ""
                    );
                  })}
                </div>
              </div>
            );
          }
        });
        this.setState({ chooces: chooce });

        //console.log(this.state.listChooce);
      })
      .catch(async (error) => {
        var mss = "";
        var title_mss = "";
        if (!!error.isAxiosError && !error.response) {
          title_mss = "เกิดข้อผิดพลาด";
          mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
        } else {
          if (!!error.isAxiosError && !error.response) {
            title_mss = "เกิดข้อผิดพลาด";
            mss = "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          } else {
            title_mss =
              error.response.data.Data[0][0].MsgDescTitle !== ""
                ? error.response.data.Data[0][0].MsgDescTitle
                : "เกิดข้อผิดพลาด";
            mss =
              error.response.data.Data[0][0].MsgDesc !== ""
                ? error.response.data.Data[0][0].MsgDesc
                : "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง";
          }
        }
        this.setState({ loading: false });
        popupErrorApi(
          mss,
          title_mss,
          true
          // function name() {
          //   var myStyle = document.getElementsByClassName("main_popup_loading")[0].style.display;
          //   if(myStyle === 'none'){
          //     document.getElementsByClassName("main_popup_loading")[0].style.display = 'block';
          //   }
          //   window.location = "login";
          // }
        );
      });
    //console.log(JSON.parse(localStorage.getItem('question')))
  }

  goBack() {
    localStorage.removeItem("RiskQuestion");
    this.props.history.push("/risk");
  }
  handleCheckbox = () => {
    var formData = new FormData(document.querySelector("form"));

    var data = [];
    var chooces = this.state.listChooce;
    for (var pair of formData.entries()) {
      if (pair[0] == "SAfsac_SAmsa_Id[]") {
        // console.log(pair);
        if (typeof data[pair[1]] == "undefined") data[pair[1]] = [];
        data[pair[1]]["SAfsac_SAmsa_Id"] = pair[1];
        var index = chooces.find((d) => {
          return d.SAmsa_Id + "" == pair[1];
        });
        if (typeof index != "undefined") {
          data[pair[1]]["SAfsac_Id"] = index.SAfsac_Id + "";
          data[pair[1]]["SAfsac_SubId"] = "";
          data[pair[1]]["SAfsac_SubAnswer"] = "";
          data[pair[1]]["SAfsac_Remark"] = "";
          data[pair[1]]["SAfsac_Remark2"] = "";
          data[pair[1]]["SAfsac_Delflag"] = 0;
        }
      }

      if (pair[0].startsWith("SAfsac_Answer")) {
        var ele = pair[0].split("_");
        if (ele.length == 4) {
          if (typeof data[ele[3]] == "undefined") data[ele[3]] = [];
          if (ele[2] == "checkbox") {
            data[ele[3]]["SAfsac_Answer"] =
              typeof data[ele[3]]["SAfsac_Answer"] == "undefined"
                ? `${pair[1]}_`
                : data[ele[3]]["SAfsac_Answer"] + `${pair[1]}_`;
          } else if (ele[2] == "radio") {
            data[ele[3]]["SAfsac_Answer"] = pair[1];
          }
          data[ele[3]]["SAfsac_Score"] = pair[1];
        }
      }
    }

    const HeaderManp = JSON.parse(localStorage.getItem("HeaderManp"));
    const head_token = HeaderManp.head_token;
    var mainData = {
      head_token: head_token,
      TotalScore: 0,
      suit_json: [],
    };
    var jsonData = [];

    data.forEach((d) => {
      jsonData.push(Object.assign({}, d));
    });
    var btn_submit = true;
    // console.log(jsonData);
    for (let i_ = 0; i_ < jsonData.length; i_++) {
      if (i_ < 10 && jsonData[i_].SAfsac_Answer == undefined) {
        btn_submit = false;
        break;
      }
    }
    this.setState({ status_btn: btn_submit });
    mainData.suit_json = jsonData;
    var scroll_ = 0;
    mainData.suit_json.forEach((ele, i) => {
      scroll_ += parseInt(ele.SAfsac_Score) ? parseInt(ele.SAfsac_Score) : 0;
    });
    // console.log(scroll_);
    mainData.TotalScore = scroll_;
    localStorage.setItem("scoreAll", scroll_);
    var MainJson = JSON.stringify(mainData);
    localStorage.setItem("RiskQuestion", MainJson);

    var arrRiskQuestion = JSON.parse(MainJson);
    delete arrRiskQuestion["TotalScore"];
    arrRiskQuestion.answerjson14 = [];
    if (arrRiskQuestion.answerjson14.length !== undefined) {
      for (let i = 0; i < arrRiskQuestion.answerjson14.length; i++) {
        if (typeof arrRiskQuestion.answerjson14[i].Delflag == "undefined") {
          arrRiskQuestion.answerjson14[i].Delflag = 0;
        } else {
          arrRiskQuestion.answerjson14[i].Delflag =
            arrRiskQuestion.answerjson14[i].Delflag === 1 ? 1 : 0;
        }
      }
    }
    for (let i = 0; i < arrRiskQuestion.suit_json.length; i++) {
      if (arrRiskQuestion.suit_json.length > 12) {
        arrRiskQuestion.suit_json.splice(
          arrRiskQuestion.suit_json.length - 1,
          1
        );
      }
    }
    axios
      .post(_url + "/form/suitability/manp", arrRiskQuestion)
      .then(async (res) => {
        const message = res?.data?.Data?.[2]?.[0] || {};
        if (message.MsgDesc) {
          MySwal.fire({
            imageUrl: "/images/ico_alert.svg",
            imageWidth: 100,
            imageHeight: 100,
            customClass: {
              confirmButton: "long_btn_fix",
            },
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonColor: "#003b66",
            confirmButtonText: "ประเมินใหม่",
            title:
              '<div class="title_popup">' + message?.MsgDescTitle + "</div>",
            html: '<div class="content_popup">' + message?.MsgDesc + "</div>",
          }).then(() => {
            localStorage.removeItem("is_recheck_risk");
            localStorage.removeItem("is_recheck_risk_MsgDesc");
            localStorage.removeItem("is_recheck_risk_MsgDescTitle");
            localStorage.removeItem("question");
            localStorage.removeItem("RiskQuestion");
            localStorage.removeItem("scoreAll");
            localStorage.removeItem("listQ14");
            localStorage.removeItem("RiskQuestionPoll");
            localStorage.removeItem("summary_risk");
            this.props.history.push("/risk");
          });
        } else {
          this.props.history.push("/risk_poll");
        }
      })
      .catch((err) => {
        // console.log(err);
        this.setState({ loading_status: false });
        this.popUp(
          "ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง",
          "เกิดข้อผิดพลาด"
        );
        return;
      });
  };
  handleSubmit = (e) => {
    this.handleCheckbox();
  };
  render() {
    const chooces = this.state.chooces;
    return (
      <div className={isMobile ? "" : "body"}>
        <header
          className={isMobile ? "" : "header-area"}
          style={{ display: isMobile ? "none" : "block" }}
        >
          <div className={"container everest-nav-container"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={"col-md-6 flex_end"}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? "" : "desktop_content section-gap-full"}
          style={{
            backgroundImage: isMobile ? "none" : "url(/images/bg.jpg)",
          }}
        >
          <div className={isMobile ? "" : "container"}>
            <div className={isMobile ? "" : "row"}>
              <div
                className={"col-md-6"}
                style={{ display: isMobile ? "none" : "block" }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? "" : "col-md-6"}>
                {/* content mobile */}
                <div>
                  <div className="content">
                    <div className="top_main_back">
                      <div className="main_back" onClick={this.goBack}>
                        <img
                          src={
                            isMobile
                              ? "/images/angle-arrow-left.svg"
                              : "/images/angle-arrow-left-blue.svg"
                          }
                          style={{ marginLeft: "3px" }}
                          className="arrow_back"
                        />
                      </div>
                      <div className="text_title">
                        แบบประเมินความเหมาะสมในการลงทุน
                      </div>
                    </div>

                    <div className="bg_content_white">
                      <div className="title_quest">
                        คำถามข้อ 1-10
                        ใช้เพื่อประเมินความเหมาะสมในการลงทุนของท่าน
                      </div>
                      <div
                        style={{
                          color: "#ef534f",
                          fontSize: 24,
                          marginBottom: 24,
                          fontWeight: "normal",
                        }}
                      >
                        กรุณาทำแบบประเมินความเสี่ยง
                        เพื่อทำความรู้จักและเข้าใจรูปแบบการลงทุนที่เหมาะสมกับตัวคุณเองมากที่สุด
                        ทั้งนี้ คุณสามารถเปลี่ยนคำตอบได้
                        โดยระบบจะทำการคำนวณคะแนนให้ใหม่
                      </div>
                      <form>{chooces}</form>

                      <ButtonCustom
                        handle={
                          this.state.status_btn ? this.handleSubmit : null
                        }
                        label="ถัดไป"
                        color={this.state.status_btn ? "blue" : "gray"}
                        btnClass="long_btn"
                      ></ButtonCustom>
                    </div>
                  </div>
                  <Loading status={this.state.loading} />
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default KtbstRiskQuestion;
