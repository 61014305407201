import React, { Component } from 'react';
import ButtonCustom from '../../components/button/ButtonCustom';
import './FamilyStatusSingle.css';

class FamilyStatusSingle extends Component {
  goBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };
  render() {
    return (
      <div className="Page">
        <div className="main_back" onClick={this.goBack}>
          <img src="/images/angle-arrow-left.svg" alt="forward" style={{ marginLeft: '3px' }} className="arrow_back" />
        </div>
        <div className="TitleNameUserInfo">สถานภาพทางครอบครัว</div>
        <div className="bg_content_white">
          <div style={{ color: '#000', fontSize: 24, marginBottom: 16, fontWeight: 'bold', marginTop: 8 }}>
            สถานภาพทางครอบครัว
          </div>

          <div className="BtnChoice active">โสด</div>
          <div className="BtnChoice" style={{ fontWeight: 'normal' }}>
            สมรส
          </div>

          <ButtonCustom
            label="ถัดไป"
            color="blue"
            btnClass="next_btn"
            iconDirection="R"
            icon="angle-right"
          ></ButtonCustom>
        </div>
      </div>
    );
  }
}

export default FamilyStatusSingle;
