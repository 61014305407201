import React, { Component } from 'react';
import ButtonCustom from '../../components/button/ButtonCustom';
import {
  checkIdcard,
  checkEditPage,
  checkInternet,
  popupNetworkLost,
  download_ico_alert,
  popupErrorApi,
} from '../../components/helpers/Helpers';
import './StatusChildren.css';
import validator from 'validator';
import axios from 'axios';
import swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DatePicker from 'react-mobile-datepicker';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../../components/loading/loading';
import DatePickerInput from './../../components/date_picker_input/DatePickerInput';
import { IMaskInput } from 'react-imask';
import moment from 'moment';
import to from 'await-to-js';
import ktbsApi from './../../api';
import { config } from './../../constants';
import { isMobile } from 'react-device-detect';
import BannerContentLeft from '../../components/BannerContentLeft/BannerContentLeft';
const _url = config.baseAPIURL03;
class StatusChildren extends Component {
  constructor(props) {
    super(props);
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate();
    this.goBack = this.goBack.bind(this);
    this.state = {
      to_day: new Date(year, month, day),
      max_year_old: new Date(year - 120, month, day),
      back_status: '',
      btn_have_child: true,
      time: new Date(),
      title: [],
      TiTle: [],
      selectedDay: null,
      color_long_btn_chk_american_yes: '',
      ischeckIdcard: false,
      children_json: [],
      status_show: false,
      id_card_exist: false,
      isDisconnected: false,
      isSubmit: false,
      loading: true,
      arr_id_card_exist: [],
      num_of_child: '',
      inputList: [
        {
          SAfc_ENName: '',
          SAfc_ENSurname: '',
          SAfc_THName: '',
          SAfc_THSurname: '',
          SAfc_CardID: '',
          SAfc_title: '',
          SAfc_Birthdate: '',
        },
      ],
      setInputList: [
        {
          SAfc_ENName: '',
          SAfc_ENSurname: '',
          SAfc_THName: '',
          SAfc_THSurname: '',
          SAfc_CardID: '',
          SAfc_title: '',
          SAfc_Birthdate: '',
        },
      ],
    };

    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
      // const list = [...inputList];
      const list = this.state.inputList;
      list.splice(index, 1);
      this.state.setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
      this.state.setInputList([
        this.state.inputList,
        {
          SAfc_ENName: '',
          SAfc_ENSurname: '',
          SAfc_THName: '',
          SAfc_THSurname: '',
          SAfc_CardID: '',
          SAfc_title: '',
          SAfc_Birthdate: '',
        },
      ]);
    };
  }

  popup = (e) => {
    const MySwal = withReactContent(swal);

    MySwal.fire({
      // icon: 'warning',
      customClass: {
        confirmButton: 'long_btn_fix',
        cancelButton: 'long_btn_fix',
      },
      confirmButtonColor: '#003b66',
      confirmButtonText: 'กลับ',
      title: '<div class="title_popup">บุตรอายุต่ำกว่า 20 ปีบริบูรณ์</div>',
    }).then(function () {});
  };
  // handle input change
  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = this.state.inputList;
    list[index][name] = value;
    this.setState({
      setInputList: list,
    });
  };

  // handle click event of the Add button
  handleAddClick = () => {
    var status_have_child = this.state.btn_have_child;
    if (status_have_child) {
      const list = this.state.inputList;
      list.push({
        SAfc_ENName: '',
        SAfc_ENSurname: '',
        SAfc_THName: '',
        SAfc_THSurname: '',
        SAfc_CardID: '',
        SAfc_Birthdate: '',
      });
      this.setState({
        setInputList: list,
      });
    } else {
      const list = {
        SAfc_ENName: '',
        SAfc_ENSurname: '',
        SAfc_THName: '',
        SAfc_THSurname: '',
        SAfc_CardID: '',
        SAfc_title: '',
        SAfc_Birthdate: '',
      };
      this.setState({
        setInputList: list,
      });
    }
    // console.log(this.state.setInputList);
  };

  // handle click event of the Remove button
  handleRemoveClick = (index) => {
    const list = this.state.inputList;
    list.splice(index, 1);
    this.setState({
      setInputList: list,
    });
  };

  handleSelect = async (time) => {
    let newTime = moment(time).format('DDMMYYYY');
    // let birthdayYear = Number(newTime.substring(4)) + 543;
    let birthdayYear = Number(newTime.substring(4));
    let birthday =
      birthdayYear + newTime.substring(2, 4) + newTime.substring(0, 2);
    await this.setState({ isOpen: false });
    var i = this.state.num_of_child;
    var status_have_child = this.state.btn_have_child;
    if (status_have_child) {
      const name = 'SAfc_Birthdate';
      const list = this.state.inputList;
      list[i]['SAfc_Birthdate'] = birthday;
      // list[i]["SAfc_Birthdate_e"] = e;
      list[i]['SAfc_Birthdate_show'] = this.converToTextDate(newTime);
      this.setState({
        setInputList: list,
        isOpen: false,
      });
    } else {
      return false;
    }
  };

  converToTextDate = (date) => {
    let output = ' ';
    if (date) {
      let dateObj = date;
      const monthNames = [
        'ม.ค.',
        'ก.พ.',
        'มี.ค.',
        'เม.ย.',
        'พ.ค.',
        'มิ.ย.',
        'ก.ค.',
        'ส.ค.',
        'ก.ย.',
        'ต.ค.',
        'พ.ย.',
        'ธ.ค.',
      ];
      let month = monthNames[parseFloat(dateObj.substr(2, 2) - 1)];
      let day = dateObj.substr(0, 2);
      let year = dateObj.substr(4);
      output = day + ' ' + month + ' ' + year;
    }
    return output;
  };

  handleFormatDate = (date) => {
    let dateObj = date;
    const monthNames = [
      'ม.ค.',
      'ก.พ.',
      'มี.ค.',
      'เม.ย.',
      'พ.ค.',
      'มิ.ย.',
      'ก.ค.',
      'ส.ค.',
      'ก.ย.',
      'ต.ค.',
      'พ.ย.',
      'ธ.ค.',
    ];
    let month = monthNames[dateObj.getMonth()];
    let day = String(dateObj.getDate()).padStart(2, '0');
    let year = dateObj.getFullYear();
    let output = day + ' ' + month + ' ' + year;
    return output;
    // console.log(output);
    // Put the object into storage
  };

  convertToISODates(date) {
    let dateObj = date;
    let month = parseFloat(dateObj.substr(4, 2));
    let day = dateObj.substr(6, 2);
    let year = dateObj.substr(0, 4);
    var date = new Date(year + '-' + month + '-' + day);
    return date;
  }

  handleInputDateClick = (e, i) => {
    // console.log(i);
    var date = new Date();
    var status_have_child = this.state.btn_have_child;
    if (status_have_child) {
      date =
        this.state.inputList[i].SAfc_Birthdate !== ''
          ? this.convertToISODates(this.state.inputList[i].SAfc_Birthdate)
          : new Date();
      this.setState({
        isOpen: true,
        num_of_child: i,
        time: date,
      });
    } else {
      this.setState({ isOpen: false, num_of_child: '' });
    }
  };

  handleInputDateCancel = () => {
    this.setState({ isOpen: false });
  };

  onChangeChildBirthDate = (value, index) => {
    const updateInputList = [...this.state.inputList];
    updateInputList[index]['SAfc_Birthdate'] = value;
    this.setState({
      inputList: updateInputList,
    });
  };

  handleIChange = async (v, ref, i) => {
    // console.log(v, ref,i);
    const list = this.state.inputList;
    list[i][ref] = v;
    await this.setState({
      setInputList: list,
    });
    // if (this.state.isSubmit) {
    //   await this.validate();
    // }
  };

  getForms = () => {
    const TiTle = this.state.TiTle;
    const { selectedDay, isDisabled, isEmpty } = this.state;
    const monthMap = {
      1: 'ม.ค.',
      2: 'ก.พ.',
      3: 'มี.ค.',
      4: 'เม.ย.',
      5: 'พ.ค.',
      6: 'มิ.ย.',
      7: 'ก.ค.',
      8: 'ส.ค.',
      9: 'ก.ย.',
      10: 'ต.ค.',
      11: 'พ.ย.',
      12: 'ธ.ค.',
    };

    const dateConfig = {
      date: {
        format: 'DD',
        caption: 'Day',
        step: 1,
      },
      month: {
        format: (value) => monthMap[value.getMonth() + 1],
        caption: 'Mon',
        step: 1,
      },
      year: {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
      },
    };

    return (
      <div
        className=""
        style={{ display: this.state.status_show ? 'block' : 'none' }}
      >
        {this.state.inputList.map((x, i) => {
          const showBtAdd = this.state.inputList.length - 1 === i && i < 3;
          return (
            <div className="box" key={i}>
              <div
                className="DBHeaventMed"
                style={{
                  fontSize: 24,
                  marginBottom: 5,
                  marginTop: 20,
                  justifyContent: 'space-between',
                }}
              >
                ข้อมูลบุตรคนที่ {i + 1}
                {this.state.inputList.length !== 1 && (
                  <div
                    className="mr10"
                    style={{
                      float: 'right',
                      fontSize: '21px',
                      color: '#ef534f',
                      display: i > 0 ? 'block' : 'none',
                    }}
                    onClick={() => this.handleRemoveClick(i)}
                  >
                    <FontAwesomeIcon icon="times-circle" />
                  </div>
                )}
              </div>
              <div className="row_idcard">
                <div style={{ fontSize: 18 }}>
                  เลขที่บัตรประจำตัวประชาชน <span>*</span>
                </div>
                <input
                  className="input_idcard"
                  value={x.SAfc_CardID}
                  data-index={i}
                  name="SAfc_CardID"
                  onChange={(e) => this.handleChange(e, i)}
                  maxLength="13"
                  style={{
                    borderColor: x.SAfc_CardID !== '' ? '#003b66' : '#ccc',
                  }}
                ></input>
                <span className="span_SAfc_CardID" data-index={i}>
                  {/* {this.state.radio_1 ? 'รูปแบบเลขที่หนังสือเดินทางไม่ถูกต้อง/ไม่ครบถ้วน' : ''} */}
                  {this.state.arr_id_card_exist[i]
                    ? 'กรุณาระบุใหม่'
                    : 'กรุณาระบุเลขที่บัตรประจำตัวประชาชน'}
                  {/* <FontAwesomeIcon icon="exclamation-circle" /> */}
                </span>
              </div>
              {/*
                <div className='row_idcard' style={{ borderColor:x.SAfc_title !== ''?'#003b66':'#ccc' }}>
                  <div >คำนำหน้าชื่อ <span>*</span></div>
                  <div className='main_select'>
                      <select
                          style={{
                              height: '40px', width: '100%', border: 'none',

                              backgroundColor: '#ffffff', fontFamily: 'DB Heavent', fontSize: "22px"
                          }}
                          value={x.SAfc_title || ""}
                          name="SAfc_title"
                          onChange={e => this.handleChange(e, i)}
                      >
                          <option value=''>-</option>
                          {TiTle}
                      </select>
                      <FontAwesomeIcon icon="angle-down" />
                  </div>
                  <span className="span_SAfc_title" data-index={i}>กรุณาเลือก คำนำหน้าชื่อ*
                      <FontAwesomeIcon icon="exclamation-circle" />
                  </span>
                </div>
              */}
              <div className="row_idcard">
                <div style={{ fontSize: 18 }}>ชื่อ * </div>
                <IMaskInput
                  mask={
                    /^[A-Za-zๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝูฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ.\s\-]+$/
                  }
                  value={x.SAfc_THName}
                  unmask={true}
                  className="input_idcard"
                  name="SAfc_THName"
                  style={{
                    borderColor: x.SAfc_THName !== '' ? '#003b66' : '#ccc',
                  }}
                  onAccept={(value, mask) =>
                    this.handleIChange(value, 'SAfc_THName', i)
                  }
                />
                <span className="span_SAfc_THName" data-index={i}>
                  กรุณาระบุชื่อ
                  {/* <FontAwesomeIcon icon="exclamation-circle" /> */}
                </span>
              </div>
              <div className="row_idcard">
                <div style={{ fontSize: 18 }}>นามสกุล * </div>
                <IMaskInput
                  mask={
                    /^[A-Za-zๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝูฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ.\s\-]+$/
                  }
                  value={x.SAfc_THSurname}
                  unmask={true}
                  className="input_idcard"
                  name="SAfc_THSurname"
                  style={{
                    borderColor: x.SAfc_THSurname !== '' ? '#003b66' : '#ccc',
                  }}
                  onAccept={(value, mask) =>
                    this.handleIChange(value, 'SAfc_THSurname', i)
                  }
                />
                <span className="span_SAfc_THSurname" data-index={i}>
                  กรุณาระบุนามสกุล
                  {/* <FontAwesomeIcon icon="exclamation-circle" /> */}
                </span>
              </div>
              {/* <div className='row_idcard' style={{borderColor:x.SAfc_ENName !== ''?'#003b66':'#ccc' }}>
                                <div style={{ fontSize: 18 }}>ชื่อ (ภาษาอังกฤษ) <span>*</span></div>
                                <input className="input_idcard"
                                    value={x.SAfc_ENName}
                                    name="SAfc_ENName"
                                    onChange={e => this.handleChange(e, i)}
                                >
                                </input>
                                <span className="span_SAfc_THSurname" data-index={i}>กรุณาระบุชื่อ (ภาษาอังกฤษ)*
                                    <FontAwesomeIcon icon="exclamation-circle" />
                                </span>
                            </div> */}
              {/* <div className='row_idcard' style={{ borderColor:x.SAfc_ENSurname !== ''?'#003b66':'#ccc' }}>
                                <div style={{ fontSize: 18 }}>นามสกุล (ภาษาอังกฤษ) <span>*</span></div>
                                <input className="input_idcard"
                                    value={x.SAfc_ENSurname}
                                    name="SAfc_ENSurname"
                                    onChange={e => this.handleChange(e, i)}
                                >
                                </input>
                                <span className="span_SAfc_THSurname" data-index={i}>กรุณาระบุนามสกุล (ภาษาอังกฤษ)*
                                    <FontAwesomeIcon icon="exclamation-circle" />
                                </span>
                            </div> */}
              <div className="row_idcard">
                <div style={{ fontSize: 18 }}>
                  วันเดือนปี (ค.ศ.) เกิด <span>*</span>
                </div>

                <div style={{ position: 'relative' }}>
                  <DatePickerInput
                    value={x.SAfc_Birthdate}
                    maxDate={new Date()}
                    onSelected={(e) => this.onChangeChildBirthDate(e, i)}
                  />

                  {/* <input type="hidden" style={{ pointerEvents: 'none' }} name="SAfc_Birthdate" value={x.SAfc_Birthdate}></input>
                                    <input
                                        className="react-datepicker__input"
                                        onClick={e => this.handleInputDateClick(e, i)}
                                        readOnly
                                        defaultValue={x.SAfc_Birthdate_show || ''}
                                        style={{ borderColor: x.SAfc_Birthdate !== '' ? '#003b66' : '#ccc' }}
                                        /> */}

                  <FontAwesomeIcon icon="calendar"></FontAwesomeIcon>
                  {/* <img src='/images/calendar.png' style={{ position: 'absolute', right: '2px', top: "6px", zIndex: "1",pointerEvents: 'none' }} /> */}
                </div>
                <span className="span_SAfc_Birthdate" data-index={i}>
                  กรุณาระบุวันเดือนปี (ค.ศ.) เกิด
                  {/* <FontAwesomeIcon icon="exclamation-circle" /> */}
                </span>
              </div>
              {showBtAdd && (
                <div className="BtnAddMoreChild" onClick={this.handleAddClick}>
                  <span
                    style={{ color: 'green', display: 'flex', margin: '0 8px' }}
                  >
                    <FontAwesomeIcon icon="plus-circle" />
                  </span>
                  เพิ่มข้อมูลบุตร
                </div>
              )}
            </div>
          );
        })}
        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(this.state.inputList)}</div> */}
        <DatePicker
          dateConfig={dateConfig}
          headerFormat="DD-MM-YYYY"
          value={this.state.time}
          showHeader={false}
          isOpen={this.state.isOpen}
          onSelect={(e) => this.handleSelect(e)}
          confirmText={'ตกลง'}
          cancelText={'ยกเลิก'}
          onCancel={this.handleInputDateCancel}
          min={this.state.max_year_old}
          max={this.state.to_day}
        />
      </div>
    );
  };

  goBack() {
    if (checkEditPage()) {
      return;
    }
    this.props.history.push('/FamilyStatus');
  }

  validate = async (i) => {
    const list = this.state.inputList;
    const arr_id_card_exist = [];
    await this.setState({ id_card_exist: false });
    var SAfc_CardID = document.getElementsByName('SAfc_CardID');
    var items_SAfc_CardID = document.querySelectorAll(
      '[class*=span_SAfc_CardID]'
    );
    items_SAfc_CardID[i].style.display = 'none';
    var SAfc_Birthdate = document.getElementsByName('SAfc_Birthdate');
    var items_SAfc_Birthdate = document.querySelectorAll(
      '[class*=span_SAfc_Birthdate]'
    );
    items_SAfc_Birthdate[i].style.display = 'none';
    var SAfc_THName = document.getElementsByName('SAfc_THName');
    var items_SAfc_THName = document.querySelectorAll(
      '[class*=span_SAfc_THName]'
    );
    items_SAfc_THName[i].style.display = 'none';
    var SAfc_THName = document.getElementsByName('SAfc_THName');
    var items_SAfc_THSurname = document.querySelectorAll(
      '[class*=span_SAfc_THSurname]'
    );
    items_SAfc_THSurname[i].style.display = 'none';
    if (list[i].SAfc_CardID === '') {
      items_SAfc_CardID[i].style.display = 'flex';
      await this.setState({ id_card_exist: false });
    } else {
      if (list[i].SAfc_CardID.length < 13) {
        await this.setState({ id_card_exist: false });
        items_SAfc_CardID[i].style.display = 'flex';
      } else {
        var ischeckIdcardvalue = this.chkIdcard(list[i].SAfc_CardID);
        if (ischeckIdcardvalue) {
          await this.setState({ id_card_exist: true });
          const f0 = JSON.parse(localStorage.getItem('f0'));
          const f1 = localStorage.getItem('f1')
            ? JSON.parse(localStorage.getItem('f1'))
            : {};
          var j;
          for (j = 0; j < list.length; j++) {
            if (i === j) {
              if (
                f0.f0_national_id === list[i].SAfc_CardID ||
                f1.f1_spouse_id_card === list[i].SAfc_CardID
              ) {
                items_SAfc_CardID[i].style.display = 'flex';
              } else {
                await this.setState({ id_card_exist: false });
                items_SAfc_CardID[i].style.display = 'none';
              }
              break;
            } else {
              if (
                list[j].SAfc_CardID === list[i].SAfc_CardID ||
                f0.f0_national_id === list[i].SAfc_CardID ||
                f1.f1_spouse_id_card === list[i].SAfc_CardID
              ) {
                items_SAfc_CardID[i].style.display = 'flex';
              } else {
                await this.setState({ id_card_exist: false });
                items_SAfc_CardID[i].style.display = 'none';
              }
              break;
            }
          }
        } else {
          await this.setState({ id_card_exist: false });
          items_SAfc_CardID[i].style.display = 'flex';
        }
      }
    }
    arr_id_card_exist[i] = this.state.id_card_exist;
    await this.setState({ arr_id_card_exist: arr_id_card_exist });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.state.btn_have_child) {
      this.setState({ isSubmit: true });
      var status_have_child = this.state.btn_have_child;
      var value_check = true;
      const list = this.state.inputList;
      const arr_id_card_exist = [];
      await this.setState({ id_card_exist: false });
      for (var i = 0; i < list.length; i += 1) {
        var SAfc_CardID = document.getElementsByName('SAfc_CardID');
        var items_SAfc_CardID = document.querySelectorAll(
          '[class*=span_SAfc_CardID]'
        );
        items_SAfc_CardID[i].style.display = 'none';
        var SAfc_Birthdate = document.getElementsByName('SAfc_Birthdate');
        var items_SAfc_Birthdate = document.querySelectorAll(
          '[class*=span_SAfc_Birthdate]'
        );
        items_SAfc_Birthdate[i].style.display = 'none';
        var SAfc_THName = document.getElementsByName('SAfc_THName');
        var items_SAfc_THName = document.querySelectorAll(
          '[class*=span_SAfc_THName]'
        );
        items_SAfc_THName[i].style.display = 'none';
        var SAfc_THName = document.getElementsByName('SAfc_THName');
        var items_SAfc_THSurname = document.querySelectorAll(
          '[class*=span_SAfc_THSurname]'
        );
        items_SAfc_THSurname[i].style.display = 'none';
        if (list[i].SAfc_CardID === '') {
          items_SAfc_CardID[i].style.display = 'flex';
          await this.setState({ id_card_exist: false });
          value_check = false;
        } else {
          if (list[i].SAfc_CardID.length < 13) {
            await this.setState({ id_card_exist: false });
            items_SAfc_CardID[i].style.display = 'flex';
            value_check = false;
          } else {
            var ischeckIdcardvalue = this.chkIdcard(list[i].SAfc_CardID);
            if (ischeckIdcardvalue) {
              await this.setState({ id_card_exist: true });
              const f0 = JSON.parse(localStorage.getItem('f0'));
              const f1 = localStorage.getItem('f1')
                ? JSON.parse(localStorage.getItem('f1'))
                : {};
              var j;
              for (j = 0; j < list.length; j++) {
                if (i === j) {
                  if (
                    f0.f0_national_id === list[i].SAfc_CardID ||
                    f1.f1_spouse_id_card === list[i].SAfc_CardID
                  ) {
                    items_SAfc_CardID[i].style.display = 'flex';
                    value_check = false;
                  } else {
                    await this.setState({ id_card_exist: false });
                    items_SAfc_CardID[i].style.display = 'none';
                  }
                  break;
                } else {
                  if (
                    list[j].SAfc_CardID === list[i].SAfc_CardID ||
                    f0.f0_national_id === list[i].SAfc_CardID ||
                    f1.f1_spouse_id_card === list[i].SAfc_CardID
                  ) {
                    items_SAfc_CardID[i].style.display = 'flex';
                    value_check = false;
                  } else {
                    await this.setState({ id_card_exist: false });
                    items_SAfc_CardID[i].style.display = 'none';
                  }
                  break;
                }
              }
            } else {
              await this.setState({ id_card_exist: false });
              items_SAfc_CardID[i].style.display = 'flex';
              value_check = false;
            }
          }
        }
        arr_id_card_exist[i] = this.state.id_card_exist;
        await this.setState({ arr_id_card_exist: arr_id_card_exist });
        if (list[i].SAfc_THName === '') {
          items_SAfc_THName[i].style.display = 'flex';
          value_check = false;
        } else {
          list[i].SAfc_THName = validator.trim(list[i].SAfc_THName);
        }
        if (list[i].SAfc_THSurname === '') {
          items_SAfc_THSurname[i].style.display = 'flex';
          value_check = false;
        } else {
          list[i].SAfc_THSurname = validator.trim(list[i].SAfc_THSurname);
        }
        if (list[i].SAfc_Birthdate === '') {
          items_SAfc_Birthdate[i].style.display = 'flex';
          value_check = false;
        }
        await this.setState({
          setInputList: list,
        });
      }

      if (value_check) {
        const list = this.state.inputList;
        var children_json = list;
        localStorage.setItem('children_json', JSON.stringify(children_json));
        var str = window.location.href;
        let re = /edit/;
        const back_to_edit = re.test(str);
        // if (checkInternet()) {
        if (back_to_edit) {
          let error;
          let result;
          let mss = '';
          let title_mss = '';
          const MySwal = withReactContent(swal);
          const HeaderManp = JSON.parse(localStorage.getItem('HeaderManp'));
          const head_token = HeaderManp.head_token;
          const children_json = JSON.parse(
            localStorage.getItem('children_json')
          )
            ? JSON.parse(localStorage.getItem('children_json'))
            : [];
          const chilDren = {
            head_token,
            children_json,
          };
          await this.setState({ loading: false });
          [error, result] = await to(
            ktbsApi.post('/form/children/manp', chilDren)
          );
          if (
            error ||
            !result ||
            !result.Data ||
            !result.Data[0] ||
            !result.Data[0][0] ||
            result.Data[0][0].IsSuccess !== 1
          ) {
            if (result) {
              mss =
                result.Data[0][0].MsgDesc !== ''
                  ? result.Data[0][0].MsgDesc
                  : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
              title_mss =
                result.Data[0][0].MsgDescTitle !== ''
                  ? result.Data[0][0].MsgDescTitle
                  : 'เกิดข้อผิดพลาด';
            } else if (error) {
              if (!!error.isAxiosError && !error.response) {
                mss = 'กรุณาทำรายการใหม่อีกครั้ง';
                title_mss = 'เกิดข้อผิดพลาด';
              } else {
                mss =
                  error.response.data.Data[0][0].MsgDesc !== ''
                    ? error.response.data.Data[0][0].MsgDesc
                    : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
                title_mss =
                  error.response.data.Data[0][0].MsgDescTitle !== ''
                    ? error.response.data.Data[0][0].MsgDescTitle
                    : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
              }
            } else {
              mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
              title_mss = 'เกิดข้อผิดพลาด';
            }
            MySwal.fire({
              imageUrl: '/images/ico_alert.svg',
              imageWidth: 100,
              imageHeight: 100,
              customClass: {
                confirmButton: 'long_btn_fix',
              },
              confirmButtonColor: '#003b66',
              confirmButtonText: 'กลับ',
              title: '<div class="title_popup">' + title_mss + '</div>',
              html: '<div class="content_popup">' + mss + '</div>',
            });
            await this.setState({ loading: false });
            return;
          } else {
            // console.log(result.Data[0][0].MsgDesc !== "");
          }
          await this.setState({ loading: false });
          this.props.history.push('/summaryshow');
          localStorage.setItem('editPage', 'statusChildren');
          return;
        }

        const BackToSummaryRisk = JSON.parse(
          localStorage.getItem('BackToSummaryRisk')
        );
        if (BackToSummaryRisk) {
          localStorage.removeItem('BackToSummaryRisk');
          window.location = 'summary_risk';
          return;
        }
        // this.props.history.push("/EmergencyContact");
        this.props.history.push('/PoliticianRelation');
        // } else {
        //   this.setState({
        //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
        //   })
        // }
      } else {
        var items = document.querySelectorAll('[class*=span_SAfc]');
        for (var i = 0; i < items.length; i++) {
          if (items[i].style.display === 'flex') {
            return items[i].parentNode.scrollIntoView({ behavior: 'smooth' });
          }
        }
        return;
        // console.log(66666)
      }
      return;
    } else {
      // ไม่มีบุตร
      localStorage.removeItem('children_json');
      var str = window.location.href;
      let re = /edit/;
      const back_to_edit = re.test(str);
      // if (checkInternet()) {

      if (back_to_edit) {
        this.props.history.push('/summaryshow');
        return;
      }

      const BackToSummaryRisk = JSON.parse(
        localStorage.getItem('BackToSummaryRisk')
      );
      if (BackToSummaryRisk) {
        localStorage.removeItem('BackToSummaryRisk');
        window.location = 'summary_risk';
        return;
      }

      this.props.history.push('/PoliticianRelation');
      // } else {
      //   this.setState({
      //     isDisconnected: popupNetworkLost(this.state.isDisconnected),
      //   })
      // }
      // this.props.history.push("/EmergencyContact");
    }
  };

  handleChange = async (e, index) => {
    const { name, value } = e.target;
    const list = this.state.inputList;
    if (name === 'SAfc_CardID') {
      let v = value.replace(/[^0-9\\.]+/g, '');
      list[index][name] = v;
    } else {
      list[index][name] = value;
    }

    this.setState({
      setInputList: list,
    });
    if (this.state.isSubmit) {
      this.validate(index);
    }
  };

  chkIdcard(a) {
    const tt = checkIdcard(a);
    // console.log(tt);
    // await this.setState({
    //     ischeckIdcard: tt
    // });
    return tt;
  }

  Click_have_child = async (e) => {
    var value = e.currentTarget.dataset.id === 'true' ? true : false;
    await this.setState({ btn_have_child: value });
    document.getElementById('no_have_child').classList.remove('active');
    document.getElementById('have_child').classList.remove('active');
    if (value === false) {
      document.getElementById('no_have_child').classList.add('active');
      await this.setState({
        status_show: false,
        inputList: [
          {
            SAfc_ENName: '',
            SAfc_ENSurname: '',
            SAfc_THName: '',
            SAfc_THSurname: '',
            SAfc_CardID: '',
            SAfc_title: '',
            SAfc_Birthdate: '',
          },
        ],
        setInputList: [
          {
            SAfc_ENName: '',
            SAfc_ENSurname: '',
            SAfc_THName: '',
            SAfc_THSurname: '',
            SAfc_CardID: '',
            SAfc_title: '',
            SAfc_Birthdate: '',
          },
        ],
      });
    } else {
      document.getElementById('have_child').classList.add('active');
      this.setState({ status_show: true });
    }
    var status_have_child = this.state.btn_have_child;
    var row_idcard_div = document.getElementsByClassName('row_idcard');
    for (let index = 0; index < row_idcard_div.length; index++) {
      const element = row_idcard_div[index];
      if (element.childNodes.length > 0) {
        for (
          let index_childnode = 0;
          index_childnode < element.childNodes.length;
          index_childnode++
        ) {
          const element_node = element.childNodes[index_childnode];
          if (index == 1) {
            // if (element_node.childNodes[0].nodeName == "SELECT") {
            //     if (status_have_child) {
            //         element_node.childNodes[0].removeAttribute("disabled");
            //     } else {
            //         element_node.childNodes[0].setAttribute("disabled", "disabled");
            //     }
            // }
          }

          if (element_node.nodeName == 'INPUT') {
            // console.log(element_node);
            if (status_have_child) {
              element_node.removeAttribute('disabled');
            } else {
              if (element_node.name === 'SAfc_CardID') {
                element.childNodes[2].style.display = 'none';
              } else if (
                element_node.name === 'SAfc_THName' ||
                element_node.name === 'SAfc_THSurname' ||
                element_node.name === 'SAfc_ENName' ||
                element_node.name === 'SAfc_ENSurname' ||
                element_node.name === 'SAfc_CardID'
              ) {
                element.childNodes[2].style.display = 'none';
              } else {
              }
              element_node.setAttribute('disabled', 'disabled');
            }
          } else {
          }
          if (index == 6) {
            var input_x = document.getElementsByClassName(
              'react-datepicker__input'
            );
            for (
              let index_input_x = 0;
              index_input_x < input_x.length;
              index_input_x++
            ) {
              const element_input_x = input_x[index_input_x];
              element_input_x.removeAttribute('disabled');
              if (status_have_child) {
                element_input_x.removeAttribute('disabled');
              } else {
                element_input_x.setAttribute('disabled', 'disabled');
              }
            }
          }
        }
      }
    }
  };

  async componentDidMount() {
    // await download_ico_alert();
    await this.setState({ loading: true });
    await axios
      .get(_url + '/constant/title')
      .then((res) => {
        const title = res.data.Data.map((item, i) => {
          return (
            <option value={item.Id} key={i}>
              {item.NameTH}
            </option>
          );
        });
        this.setState({ TiTle: title });
      })
      .catch((error) => {
        let mss = '';
        let title_mss = '';
        if (!!error.isAxiosError && !error.response) {
          title_mss = 'เกิดข้อผิดพลาด';
          mss = 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
        } else {
          title_mss =
            error.response.data.Data !== undefined &&
            error.response.data.Data[0][0].MsgDescTitle !== ''
              ? error.response.data.Data[0][0].MsgDescTitle
              : 'เกิดข้อผิดพลาด';
          mss =
            error.response.data.Data !== undefined &&
            error.response.data.Data[0][0].MsgDesc !== ''
              ? error.response.data.Data[0][0].MsgDesc
              : 'ไม่สามารถทำรายการได้ กรุณาทำรายการใหม่อีกครั้ง';
        }
        popupErrorApi(mss, title_mss, true);
      });

    if (localStorage.getItem('children_json')) {
      const children_json = JSON.parse(localStorage.getItem('children_json'));
      await this.setState({
        setInputList: children_json,
        inputList: children_json,
      });
      var value = true;
      await this.setState({ btn_have_child: value });
      document.getElementById('no_have_child').classList.remove('active');
      document.getElementById('have_child').classList.remove('active');
      if (value === false) {
        document.getElementById('no_have_child').classList.add('active');
      } else {
        document.getElementById('have_child').classList.add('active');
        await this.setState({
          status_show: true,
        });
      }
      var status_have_child = this.state.btn_have_child;
    } else {
      var value = false;
      await this.setState({ btn_have_child: value });
      document.getElementById('no_have_child').classList.remove('active');
      document.getElementById('have_child').classList.remove('active');
      if (value === false) {
        document.getElementById('no_have_child').classList.add('active');
      } else {
        document.getElementById('have_child').classList.add('active');
      }
      var status_have_child = this.state.btn_have_child;
      var row_idcard_div = document.getElementsByClassName('row_idcard');
      for (let index = 0; index < row_idcard_div.length; index++) {
        const element = row_idcard_div[index];
        if (element.childNodes.length > 0) {
          for (
            let index_childnode = 0;
            index_childnode < element.childNodes.length;
            index_childnode++
          ) {
            const element_node = element.childNodes[index_childnode];
            if (index == 1) {
              // if (element_node.childNodes[0].nodeName == "SELECT") {
              //     if (status_have_child) {
              //         element_node.childNodes[0].removeAttribute("disabled");
              //     } else {
              //         element_node.childNodes[0].setAttribute("disabled", "disabled");
              //     }
              // }
            }

            if (element_node.nodeName == 'INPUT') {
              // console.log(element_node);
              if (status_have_child) {
                element_node.removeAttribute('disabled');
              } else {
                if (element_node.name === 'SAfc_CardID') {
                  element.childNodes[2].style.display = 'none';
                } else if (
                  element_node.name === 'SAfc_THName' ||
                  element_node.name === 'SAfc_THSurname' ||
                  element_node.name === 'SAfc_ENName' ||
                  element_node.name === 'SAfc_ENSurname' ||
                  element_node.name === 'SAfc_CardID'
                ) {
                  element.childNodes[2].style.display = 'none';
                } else {
                }
                element_node.setAttribute('disabled', 'disabled');
              }
            } else {
            }
            if (index == 6) {
              var input_x = document.getElementsByClassName(
                'react-datepicker__input'
              );
              for (
                let index_input_x = 0;
                index_input_x < input_x.length;
                index_input_x++
              ) {
                const element_input_x = input_x[index_input_x];
                // console.log(element_input_x, index_input_x);
                element_input_x.removeAttribute('disabled');
                if (status_have_child) {
                  element_input_x.removeAttribute('disabled');
                } else {
                  element_input_x.setAttribute('disabled', 'disabled');
                }
              }
            }
          }
        }
      }
    }
    await this.setState({ loading: false });
    // console.log(children_json);
  }

  handleChangeDate = (date, i) => {
    let dateObj = date;
    let month = dateObj.getMonth();
    let day = String(dateObj.getDate()).padStart(2, '0');
    let year = dateObj.getFullYear();
    let output = year + '' + month + '' + day;
    // console.log(output);
    this.setState({ f1_date_birth: output });
    let thaidate = this.handleFormatDate(date);
    const list = this.state.inputList;
    list[i]['SAfc_Birthdate'] = output;
    list[i]['SAfc_Birthdate_show'] = thaidate;
    this.setState({
      setInputList: list,
    });
    // console.log(list);
    return;
    // Put the object into storage
    // localStorage.setItem('arr_family_data', JSON.stringify(arr_family_data));
  };

  handleFormatDate = (date) => {
    let dateObj = date;
    const monthNames = [
      'ม.ค.',
      'ก.พ.',
      'มี.ค.',
      'เม.ย.',
      'พ.ค.',
      'มิ.ย.',
      'ก.ค.',
      'ส.ค.',
      'ก.ย.',
      'ต.ค.',
      'พ.ย.',
      'ธ.ค.',
    ];
    let month = monthNames[dateObj.getMonth()];
    let day = String(dateObj.getDate()).padStart(2, '0');
    let year = dateObj.getFullYear();
    let output = day + ' ' + month + ' ' + year;
    return output;
    // console.log(output);
    // Put the object into storage
  };

  render() {
    library.add(fas);
    const form_childs = this.getForms();

    return (
      <div className={isMobile ? '' : 'body'}>
        <header
          className={isMobile ? '' : 'header-area'}
          style={{ display: isMobile ? 'none' : 'block' }}
        >
          <div className={'container everest-nav-container'}>
            <div className={'row'}>
              <div className={'col-md-6'}>
                <img src="../../../images/logo.png" />
              </div>
              <div className={'col-md-6 flex_end'}>
                <div className="text-branding">บริการเปิดบัญชีออนไลน์</div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={isMobile ? '' : 'desktop_content section-gap-full'}
          style={{
            backgroundImage: isMobile ? 'none' : 'url(/images/bg.jpg)',
          }}
        >
          <div className={isMobile ? '' : 'container'}>
            <div className={isMobile ? '' : 'row'}>
              <div
                className={'col-md-6'}
                style={{ display: isMobile ? 'none' : 'block' }}
              >
                <BannerContentLeft></BannerContentLeft>
              </div>
              <div className={isMobile ? '' : 'col-md-6'}>
                {/* content mobile */}
                <div className="Page">
                  <Loading status={this.state.loading} />
                  <div className="top_main_back">
                    <div className="main_back" onClick={this.goBack}>
                      <img
                        src={
                          isMobile
                            ? '/images/angle-arrow-left.svg'
                            : '/images/angle-arrow-left-blue.svg'
                        }
                        alt="forward"
                        style={{
                          display: checkEditPage() ? 'none' : 'block',
                          marginLeft: '3px',
                        }}
                        className="arrow_back"
                      />
                    </div>
                    <div className="text_title">สถานภาพทางครอบครัว</div>
                  </div>
                  <div className="bg_content_white ">
                    <div
                      className="title_in_content one_line"
                      style={{ fontSize: 24, marginBottom: 30, marginTop: 8 }}
                    >
                      คุณมีบุตรที่ยังไม่บรรลุนิติภาวะหรือไม่ ?{' '}
                      <div onClick={this.popup}>
                        <FontAwesomeIcon icon="info-circle" />
                      </div>
                    </div>
                    <div
                      className="BtnChoice long_btn_status_user"
                      id="no_have_child"
                      onClick={this.Click_have_child}
                      data-id="false"
                    >
                      ไม่มี
                    </div>
                    <div
                      className="BtnChoice long_btn_status_user"
                      id="have_child"
                      onClick={this.Click_have_child}
                      data-id="true"
                    >
                      มี
                    </div>
                    {form_childs}
                    <div
                      className={
                        this.state.status_show
                          ? 'under_fix_btn active'
                          : 'under_fix_btn'
                      }
                    >
                      <ButtonCustom
                        handle={this.handleSubmit}
                        label={checkEditPage() ? 'บันทึกการแก้ไข' : 'ถัดไป'}
                        color="blue"
                        btnClass={
                          this.state.status_show ? 'long_btn' : 'long_btn_fix'
                        }
                      />
                    </div>
                  </div>
                </div>
                {/* end content mobile */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default StatusChildren;
